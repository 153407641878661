import {
  GET_INPROGRESS_JOBS,
  GET_INPROGRESS_JOBS_SUCCESS,
  GET_INPROGRESS_JOBS_FAIL,
  GET_INPROGRESS_JOBS_COUNT,
  GET_INPROGRESS_JOBS_COUNT_SUCCESS,
  GET_INPROGRESS_JOBS_COUNT_FAIL,
} from "./actionTypes";

const initialState = {
  loadingLogins: false,
  message: "",
  error: "",
  jobData: null,
  jobCount: 0,
};

const inProgressJobsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INPROGRESS_JOBS:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_INPROGRESS_JOBS_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        jobData: action.payload,
      };
      break;
    case GET_INPROGRESS_JOBS_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        jobData: null,
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_INPROGRESS_JOBS_COUNT:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_INPROGRESS_JOBS_COUNT_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        jobCount: action.payload,
      };
      break;
    case GET_INPROGRESS_JOBS_COUNT_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        jobCount: 0,
        error: {
          message: action.payload,
        },
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default inProgressJobsReducer;
