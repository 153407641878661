import * as ACTIONS from "./actionType";
const initialState = {
  invoiceCreate: null,
  loading: false,
};
export const invoiceDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_DETAIL_FOR_CREATE_INVOICE:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS.GET_DETAIL_FOR_CREATE_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceCreate: action.payload,
        invoice_id: null,
      };
    case ACTIONS.GET_INVOICE_REST_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceCreate: {
          ...state.invoiceCreate,
          ...action.payload,
          restLoaded:true
        },
        invoice_id: null,
      };
    case ACTIONS.GET_DETAIL_FOR_CREATE_INVOICE_FAIL:
      return {
        ...state,
        loading: false,
        invoice_id: null,
        invoiceCreate:{
          redirect:true,
          location: "/invoice",
        }
      };
    case ACTIONS.CREATE_INVOICE:
      return {
        ...state,
        creating: true,
      };
    case ACTIONS.CREATE_INVOICE_SUCCESS:
      return {
        ...state,
        creating: false,
        invoice_id: action.payload,
      };
    case ACTIONS.CREATE_INVOICE_FAIL:
      return {
        ...state,
        creating: false,
        invoice_id: null,
      };

    case ACTIONS.GET_DETAIL_FOR_EDIT_INVOICE:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS.GET_DETAIL_FOR_EDIT_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceEdit: action.payload,
      };
    case ACTIONS.GET_DETAIL_FOR_EDIT_INVOICE_FAIL:
      return {
        ...state,
        loading: false,
      };
    case ACTIONS.SEND_EMAIL:
      return {
        ...state,
        emailSent: "processing",
      };
    case ACTIONS.SEND_EMAIL_SUCCESS:
      return {
        ...state,
        emailSent: "completed",
      };
    case ACTIONS.SEND_EMAIL_FAIL:
      return {
        ...state,
        emailSent: "failed",
      };
    case ACTIONS.GET_INVOICE_CREDIT_INFO:
      return {
        ...state,
        loading: true,
        invoiceCredit: null,
      };
    case ACTIONS.GET_INVOICE_CREDIT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceCredit: action.payload,
      };
    case ACTIONS.GET_INVOICE_CREDIT_INFO_FAIL:
      return {
        ...state,
        loading: false,
        invoiceCredit: null,
        invoiceCreditError: action.payload,
      };
    case ACTIONS.ADD_INVOICE_CREDIT:
      return {
        ...state,
        invoiceCreditCreating: "processing",
      };
    case ACTIONS.ADD_INVOICE_CREDIT_SUCCESS:
      return {
        ...state,
        invoiceCreditCreating: "success",
      };
    case ACTIONS.ADD_INVOICE_CREDIT_FAIL:
      return {
        ...state,
        invoiceCreditCreating: "failed",
      };
    case ACTIONS.GET_INVOCE_CREDIT_VIEW:
      return {
        ...state,
        loading: true,
        invoiceCreditView: null,
      };
    case ACTIONS.GET_INVOCE_CREDIT_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceCreditView: action.payload,
      };
    case ACTIONS.GET_INVOCE_CREDIT_VIEW_FAIL:
      return {
        ...state,
        loading: false,
        invoiceCreditView: null,
        invoiceCreditViewError: action.payload,
      };
    case ACTIONS.GET_INSURANCE_DETAILS:
      return {
        ...state,
        invoiceCreate: {
          ...state.invoiceCreate,
          insurance_detail: null,
        },
      };
    case ACTIONS.GET_INSURANCE_DETAILS_SUCCESS:
      return {
        ...state,
        invoiceCreate: {
          ...state.invoiceCreate,
          insurance_detail: action.payload,
        },
      };
    case ACTIONS.GET_INSURANCE_DETAILS_FAIL:
      return {
        ...state,
        invoiceCreate: {
          ...state.invoiceCreate,
          insurance_detail: null,
        },
      };
    case ACTIONS.GET_INVOICE_RECEIPT:
      return {
        ...state,
        loading: true,
        invoiceReceipt: null,
      };
    case ACTIONS.GET_INVOICE_RECEIPT_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceReceipt: action.payload,
      };
    case ACTIONS.GET_INVOICE_RECEIPT_FAIL:
      return {
        ...state,
        loading: false,
        invoiceReceipt: null,
      };
    case ACTIONS.GET_INVOICE_RECEIPT_EMAIL:
      return {
        ...state,
        loading: true,
        invoiceReceiptEmail: null,
      };
    case ACTIONS.GET_INVOICE_RECEIPT_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceReceiptEmail: action.payload,
      };
    case ACTIONS.GET_INVOICE_RECEIPT_EMAIL_FAIL:
      return {
        ...state,
        loading: false,
        invoiceReceiptEmail: null,
      };
    case ACTIONS.GET_INVOICE_CREDIT_EMAIL:
      return {
        ...state,
        loading: true,
        invoiceCreditEmail: null,
      };
    case ACTIONS.GET_INVOICE_CREDIT_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceCreditEmail: action.payload,
      };
    case ACTIONS.GET_INVOICE_CREDIT_EMAIL_FAIL:
      return {
        ...state,
        loading: false,
        invoiceCreditEmail: null,
      };
    default:
      return state;
  }
};
