import React, { useState } from "react";
// import ReactTooltip from "react-tooltip";

const Scroller = () => {
  const [scroll, setscroll] = useState(100);

  // SCROLLER
  window.onscroll = () => {
    scrollFunction();
  };

  const scrollTopFunction = () => {
    setscroll(0);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const scrollShortUpFunction = () => {
    setscroll(scroll - 100);
    window.scrollTo({ top: scroll, behavior: "smooth" });
  };
  const scrollShortBottomFunction = () => {
    setscroll(scroll + 120);
    window.scrollTo({ top: scroll, behavior: "smooth" });
  };
  const scrollBottomFunction = () => {
    setscroll(0);
    window.scrollTo({ top: 10000, behavior: "smooth" });
  };

  const scrollFunction = () => {
    var mybutton = document.getElementById("topBtn");
  };

  // SCROLLER
  return (
    <>
      {/* scroller */}
      <div className="scroller">
        <span onClick={scrollTopFunction}>
          <i
            class="fa fa-angle-up"
            aria-hidden="true"
            id="topBtn"
            title="Go to top top"
          ></i>
        </span>
        <span onClick={scrollShortUpFunction}>
          <i
            class="fa fa-angle-double-up"
            aria-hidden="true"
            title="Scroll Top"
          ></i>
        </span>
        <span onClick={scrollShortBottomFunction}>
          <i
            class="fa fa-angle-double-down"
            aria-hidden="true"
            title="Scroll Down"
          ></i>
        </span>
        <span onClick={scrollBottomFunction}>
          <i
            class="fa fa-angle-down"
            aria-hidden="true"
            id="toptn2"
            title="Go to Bottom"
          ></i>
        </span>
      </div>
      {/* scroller */}
    </>
  );
};

export default Scroller;
