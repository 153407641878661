import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import {loadStripe} from '@stripe/stripe-js';
import {CardNumberElement, CardCvcElement, CardExpiryElement, Elements} from "@stripe/react-stripe-js";
import { GET_SUBSCRIPTION_PLANS } from "../../../store/subscription/actionTypes";
import { GET_WORKSHOP_DETAIL } from "../../../store/companyDetails/actionTypes";
import { unserializeArr } from "../../../utils/unserialize";
import StripeLogo from "../../../assets/images/logo-stripe.svg";
import LoaderImage from "../../../assets/images/page-loader.svg";
import "../subscription-plan.scss";

const stripePromise = loadStripe('pk_test_6pRNASCoBOKtIshFeQd4XMUh');

const ELEMENT_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      fontSize: '16px',
      color: '#000000',
      padding: '5px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

const SubscriptionPlan = () => {
  const dispatch = useDispatch();
  const [subscriptionCycle, setSubscriptionCycle] = useState("monthly");
  const [userPackageArr, setUserPackageArr]  = useState([]);
  const [smsPackagesArr, setSmsPackagesArr] = useState([]);
  const [inspection, setInspection] = useState([]);
  const [form, setForm] = useState([]);
  const [applicationConfiguration, setApplicationConfiguration] = useState("");
  const [vinLookUpArr, setVinLookUpArr] = useState([]);
  const [addUsrDesktop, setAddUsrDesktop] = useState(0);
  const [addUsrMobile, setAddUsrMobile] = useState(0);

  const [userPlan, setUserPlan] = useState("");
  const [appConfigPlan, setAppConfigPlan] = useState("");
  const [smsPlan, setSmsPlan] = useState("");
  const [vinPlan, setVinPlan] = useState("");


  const { subscriptionPlans, loadingData } = useSelector(state => state.SubscriptionReducer);
  const { workShopDetails, loading } = useSelector(state => state.CompanyDetailReducer);

  useEffect(() => {
    dispatch({
      type: GET_WORKSHOP_DETAIL,
      payload: {
        id: JSON.parse(localStorage.getItem("data")).workshop_id,
      },
    });

    dispatch({
      type: GET_SUBSCRIPTION_PLANS,
      payload: {
        get: "all",
        conditions: {
          is_active: 1
        }
      }
    });
  }, []);

  useEffect(() => {
    setUserPackageArr([]);
    setSmsPackagesArr([]);
    setVinLookUpArr([]);
    if (subscriptionPlans.length > 0) {
      subscriptionPlans.forEach(element => {
        if (element.plan_type === "user")
        {
          if (element.select_default_plan !== "" && typeof element.select_default_plan === "string") {
            element.select_default_plan = unserializeArr(element.select_default_plan);
          }
          setUserPackageArr(prevArray => [...prevArray, element]);
        }
        else if (element.plan_type === "sms") {
          setSmsPackagesArr(prevArray => [...prevArray, element]);
        }
        else if (element.plan_type === "form") {
          setForm(element);
        }
        else if (element.plan_type === "inspection") {
          setInspection(element);
        }
        else if (element.plan_type === "application_configuration") {
          setApplicationConfiguration(element);
        }
        else if (element.plan_type === "vin_lookup") {
          setVinLookUpArr(prevArray => [...prevArray, element]);
        }
      });
    }
  }, [subscriptionPlans]);

  const handleDecrement = (type) => {
    if (type === "desktop") {
      if (addUsrDesktop > 0) {
        setAddUsrDesktop(addUsrDesktop - 1);
      }
    }
    else {
      if (addUsrMobile > 0) {
        setAddUsrMobile(addUsrMobile - 1);
      }
    }
  }
  const handleIncrement = (type) => {
    if (type === "desktop") {
      setAddUsrDesktop(addUsrDesktop + 1);
    }
    else {
      setAddUsrMobile(addUsrMobile + 1);
    }
  }

  const monthlySubscriptionSubTotal = (userPlan ? (subscriptionCycle === "monthly" ? userPlan.monthly_charge : userPlan.yearly_charge) : 0) + 
    ((addUsrDesktop * (workShopDetails ? workShopDetails.country.extra_desktop_user_price : 0)) + (addUsrMobile * (workShopDetails ? workShopDetails.country.extra_mobile_user_price : 0))) +
    (smsPlan ? smsPlan.monthly_charge : 0) + 
    (vinPlan ? vinPlan.monthly_charge : 0);

  const addonSubscriptionSubTotal = appConfigPlan ? (subscriptionCycle === "monthly" ? appConfigPlan.monthly_charge : appConfigPlan.yearly_charge) : 0;

  const totalCharge = monthlySubscriptionSubTotal + addonSubscriptionSubTotal;

  const processingFee = parseFloat((totalCharge * 1.95) / 100).toFixed(2);
  if (loadingData || loading) {
    return (
      <div className="page-loader d-flex align-center justify-center">
        <img src={LoaderImage} alt="Loading"></img>
      </div>
    );
  }
  return (
    <>
      <div className="right-section-wrapper">
        <div className="card">
          <div className="card-body">
            <div className="text-center">
              <div className="d-inline-flex align-items-center">
                <p className="mb-0 me-2">Select the BEST plan for your business</p>
                <ToggleButtonGroup
                  type="radio"
                  name="subscription-cycle"
                  value={subscriptionCycle}
                >
                  <ToggleButton
                    value="monthly"
                    id="subscription-cycle-1"
                    variant="outline-primary"
                    className="btn-rounded mb-0 font-size-12"
                    checked={subscriptionCycle === "monthly"}
                    onChange={e => setSubscriptionCycle(e.target.value)}
                  >
                    Monthly
                  </ToggleButton>
                  <ToggleButton
                    value="anually"
                    id="subscription-cycle-2"
                    variant="outline-primary"
                    className="btn-rounded mb-0 font-size-12"
                    checked={subscriptionCycle === "anually"}
                    onChange={e => setSubscriptionCycle(e.target.value)}
                  >
                    Annually
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 mb-3">
                <div className="card-header px-0">
                  <div className="d-flex">
                    <h6 className="background-gradient rounded mb-0">Subscription</h6>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row mb-4">
                    {userPackageArr.map(plan => (  
                      <div className="col-md-6" key={plan.id}>
                        <div className={userPlan.id !== plan.id ? "pricingTable mx-auto" : "pricingTable active mx-auto"}>
                          <h3>{plan.name}</h3>
                          <div className="price-value">
                            {subscriptionCycle === "monthly" ? (
                              <>
                                <span >{`$${plan.monthly_charge}`}</span> 
                                <span className="month"> Monthly</span>
                              </>
                            ) : (
                              <>
                                <span >{`$${plan.yearly_charge}`}</span> 
                                <span className="month"> Annualy</span>
                              </>
                            )}
                          </div>
                          <div className="pricing-content">
                            <ul>
                              <li>
                                Incl. {Object.keys(plan.select_default_plan).length > 0 ? plan.select_default_plan.user_limit : 0}* user | 
                                <span>{subscriptionCycle === "monthly" ? `$${plan.yearly_charge} Annually` : `$${plan.monthly_charge} Monthly`}</span>
                              </li>
                            </ul>
                            {userPlan.id !== plan.id ? (
                              <div className="pricingTable-signup">
                                <button type="button" onClick={() => setUserPlan(plan)}>Select plan</button>
                              </div>
                            ) : (
                              <div className="pricingTable-signup">
                                <button type="button" onClick={() => setUserPlan("")}>Unselect plan</button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-lg-10">
                      <div className="card">
                        <div className="card-header py-2 font-size-13">
                          Add additional users for plan:
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="d-flex px-0 px-xl-4 flex-nowrap justify-content-center align-items-start">
                                <h4 className="mb-0 me-2 font-size-15 text-nowrap pt-2">Desktop</h4>
                                <div className="d-flex flex-nowrap align-items-start">
                                  <button 
                                    className="btn btn-icon btn-danger rounded-circle me-2 flex-shrink-0"
                                    onClick={() => handleDecrement("desktop")}
                                  >
                                    <i className="fa fa-minus"></i>
                                  </button>
                                  <div>
                                    <input type="text" value={addUsrDesktop} className="form-control text-center" readOnly />
                                    <div className="font-size-14">$ {workShopDetails ? workShopDetails.country.extra_desktop_user_price : 0} per user</div>
                                  </div>
                                  <button 
                                    className="btn btn-icon btn-success rounded-circle ms-2 flex-shrink-0"
                                    onClick={() => handleIncrement("desktop")}
                                  >
                                    <i className="fa fa-plus"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="d-flex px-0 px-xl-4 flex-nowrap justify-content-center align-items-start">
                                <h4 className="mb-0 me-2 font-size-15 text-nowrap pt-2">Mobile App</h4>
                                <div className="d-flex flex-nowrap align-items-start">
                                  <button 
                                    className="btn btn-icon btn-danger rounded-circle me-2 flex-shrink-0"
                                    onClick={() => handleDecrement("mobile")}
                                  >
                                    <i className="fa fa-minus"></i>
                                  </button>
                                  <div>
                                    <input type="text" value={addUsrMobile} className="form-control text-center" readOnly />
                                    <div className="font-size-14">$ {workShopDetails ? workShopDetails.country.extra_mobile_user_price : 0} per user</div>
                                  </div>
                                  <button 
                                    className="btn btn-icon btn-success rounded-circle ms-2 flex-shrink-0"
                                    onClick={() => handleIncrement("mobile")}
                                  >
                                    <i className="fa fa-plus"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <div className="card-header px-0">
                  <div className="d-flex">
                    <h6 className="background-gradient rounded mb-0">BRANDED APP</h6>
                  </div>
                </div>
                <div className="card-body">
                  <div className={appConfigPlan.id !== applicationConfiguration.id ? "pricingTable mx-auto" : "pricingTable active mx-auto"}>
                    <h3>{applicationConfiguration.name}</h3>
                    <div className="price-value">
                      {subscriptionCycle === "monthly" ? (
                        <>
                          <span >{`$${applicationConfiguration.monthly_charge}`}</span> 
                          <span className="month"> Monthly</span>
                        </>
                      ) : (
                        <>
                          <span >{`$${applicationConfiguration.yearly_charge}`}</span> 
                          <span className="month"> Annually</span>
                        </>
                      )}
                    </div>
                    <div className="pricing-content">
                      <ul>
                        {subscriptionCycle === "monthly" ? (
                          <li>{`$${applicationConfiguration.yearly_charge}`} Annually</li>
                        ) : (
                          <li>{`$${applicationConfiguration.monthly_charge}`} Monthly</li>
                        )}
                      </ul>
                      {appConfigPlan.id !== applicationConfiguration.id ? (
                        <div className="pricingTable-signup">
                          <button type="button" onClick={() => setAppConfigPlan(applicationConfiguration)}>Select plan</button>
                        </div>
                      ) : (
                        <div className="pricingTable-signup">
                          <button type="button" onClick={() => setAppConfigPlan("")}>Unselect plan</button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="pt-4">
                    <div className="col-md-6 mx-auto text-center">
                      <i>This module will be added/integrated into your workshop application and the change will be added to your subscription charge.</i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 mb-3">
                <div className="card-header px-0">
                  <div className="d-flex">
                    <h6 className="background-gradient rounded mb-0">SMS</h6>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    {smsPackagesArr && smsPackagesArr.length > 0 && (
                      <>
                        {smsPackagesArr.map(elmnt => (
                          <div className="col-md-6 mb-3" key={elmnt.id}>
                            <div className={smsPlan.id !== elmnt.id ? "pricingTable mx-auto" : "pricingTable active mx-auto"}>
                              <h3>{elmnt.name}</h3>
                              <div className="price-value">
                                <span >{`$${elmnt.monthly_charge}`}</span> <span className="month font-size-14">Unused sms will rollover to next month</span>
                              </div>
                              <div className="pricing-content">
                                <ul>
                                  <li>Incl. Approx** {elmnt.sms_limit} sms</li>
                                </ul>
                                {smsPlan.id !== elmnt.id ? (
                                  <div className="pricingTable-signup">
                                    <button type="button" onClick={() => setSmsPlan(elmnt)}>Select plan</button>
                                  </div>
                                ) : (
                                  <div className="pricingTable-signup">
                                    <button type="button" onClick={() => setSmsPlan("")}>Unselect plan</button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                  <div className="pt-4">
                    <div className="text-center">
                      <p className="mb-3">The SMS package will be added to your monthly billing and will Auto Top Up once you reach the minimum 20% of your package remaining.</p>
                      <p className="mb-3">Note : The SMS Packages do not expiry monthly and the remaining credit will rollover to the next month.</p>
                      <p className="mb-0">* Pricing includes Tax Exclusive</p>
                      <p>** Based on variable Teclo charges</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="card-header px-0">
                  <div className="d-flex">
                    <h6 className="background-gradient rounded mb-0">REGO LOOKUP</h6>
                  </div>
                </div>
                <div className="card-body">
                  {vinLookUpArr && vinLookUpArr.length > 0 && (
                    <>
                      {vinLookUpArr.map(elmnt => (
                        <div className={vinPlan.id !== elmnt.id ? "pricingTable mx-auto mb-3" : "pricingTable active mx-auto mb-3"} key={elmnt.id}>
                          <h3>{elmnt.name}</h3>
                          <div className="price-value">
                            <span >{`$${elmnt.monthly_charge}`}</span> <span className="month font-size-14">Unused request will rollover to next month</span>
                          </div>
                          <div className="pricing-content">
                            <ul>
                              <li>Incl. Approx** {elmnt.vin_request} request</li>
                            </ul>
                            {vinPlan.id !== elmnt.id ? (
                              <div className="pricingTable-signup">
                                <button type="button" onClick={() => setVinPlan(elmnt)}>Select plan</button>
                              </div>
                            ) : (
                              <div className="pricingTable-signup">
                                <button type="button" onClick={() => setVinPlan("")}>Unselect plan</button>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                  <div className="pt-4">
                    <div className="text-center">
                      <p className="mb-3">The VIN LOOK package will be added to your monthly billing and will Auto Top Up once you reach the minimum 20% of your package remaining.</p>
                      <p className="mb-3">Note : The VIN LOOK Packages do not expiry monthly and the remaining credit will rollover to the next month.</p>
                      <p>* Pricing includes Tax Exclusive</p>
                      <p>** Based on variable Teclo charges</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card-header px-0">
                  <div className="d-flex">
                    <h6 className="background-gradient rounded mb-0">SUMMARY</h6>
                  </div>
                </div>
                <div className="card-body pb-0 pt-0">
                  <div className="row">
                    <div className="col-md-4 price-summary">
                      <table>
                        <tbody>
                          <tr>
                            <td className="price-summary-title">Monthly Subscription</td>
                          </tr>
                          <tr>
                            <td> Subscription Plan</td>
                            <td>${userPlan ? (subscriptionCycle === "monthly" ? userPlan.monthly_charge : userPlan.yearly_charge) : 0}</td>
                          </tr>
                          <tr>
                            <td>Sub Additional Users</td>
                            <td>${(addUsrDesktop * workShopDetails.country.extra_desktop_user_price) + (addUsrMobile * workShopDetails.country.extra_mobile_user_price)}</td>
                          </tr>
                          <tr>
                            <td>SMS Plan</td>
                            <td>${smsPlan ? smsPlan.monthly_charge : 0}</td>
                          </tr>
                          <tr>
                            <td>Rego Lookup Plan</td>
                            <td>${vinPlan ? vinPlan.monthly_charge : 0}</td>
                          </tr>
                          <tr>
                            <td>Sub Total</td>
                            <td>${monthlySubscriptionSubTotal}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-4 price-summary">
                      <table>
                        <tbody>
                          <tr>
                            <td className="price-summary-title">Addon Subscription</td>
                          </tr>
                          <tr>
                            <td>Customised Cust App</td>
                            <td>${appConfigPlan ? (subscriptionCycle === "monthly" ? appConfigPlan.monthly_charge : appConfigPlan.yearly_charge) : 0}</td>
                          </tr>
                          <tr>
                            <td>Digital Forms</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Dig Form Additional Users</td>
                            <td>$0</td>
                          </tr>
                          <tr>
                            <td>Sub Total</td>
                            <td>${addonSubscriptionSubTotal}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-4 price-summary">
                      <table>
                        <tbody>
                          <tr>
                            <td className="price-summary-title">Summary</td>
                          </tr>
                          <tr>
                            <td>Monthly Subscription</td>
                            <td>${monthlySubscriptionSubTotal}</td>
                          </tr>
                          <tr>
                            <td>Addon Subscription</td>
                            <td>${addonSubscriptionSubTotal}</td>
                          </tr>
                          <tr className="bg-inverse">
                            <td>Total Charge</td>
                            <td><h3>${monthlySubscriptionSubTotal + addonSubscriptionSubTotal}</h3></td>
                          </tr>
                          <tr>
                            <td>Payment Process fee 1.95%</td>
                            <td><h3>${processingFee}</h3></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row border-top pt-4">
              <div className="col-md-3">
                <h4 className="mb-3">Payment Details</h4>
                <p>(Subscription activated,billed today and on this anniversary date each month)</p>
              </div>
              <div className="col-md-5">
                <div className="card shadow">
                  <div className="card-body">
                    <Elements stripe={stripePromise}>
                        <div className="pb-0 pt-0 stripe-form">
                          <div className="form-group mb-3">
                            <label htmlFor="name">Name</label>
                            <div className="iconic-input">
                              <i className="fa fa-user"></i>
                              <input name="name" id="name" className="form-control" />
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label htmlFor="cardNumber">Card Number</label>
                            <CardNumberElement 
                              id="cardNumber"
                              className="form-control"
                              options={ELEMENT_OPTIONS}
                              showIcon={true}
                              onChange={() => console.log("change")}
                            />
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group mb-3">
                                <label htmlFor="expiry">Card Expiration</label>
                                <CardExpiryElement
                                  id="expiry"
                                  className="form-control"
                                  options={ELEMENT_OPTIONS}
                                  onChange={() => console.log("expiry")}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-3">
                                  <label htmlFor="cvc">CVC</label>
                                  <CardCvcElement
                                    id="cvc"
                                    className="form-control"
                                    options={ELEMENT_OPTIONS}
                                    onChange={() => console.log("cvc")}
                                  />
                                </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-8">
                              <div className="form-group">
                                <div className="powered-by">
                                  <label htmlFor="">powered by</label>
                                  <img src={StripeLogo} height="30" alt="" />
                                </div>
                              </div>
                            </div>
                            <div className="col-4 text-end">
                              <button className="btn btn-primary btn-rounded">Confirm</button>
                            </div>
                          </div>
                        </div>
                    </Elements>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubscriptionPlan;