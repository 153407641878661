import {
  SAVE_QUOTE_PART,
  SAVE_QUOTE_PART_SUCCESS,
  SAVE_QUOTE_PART_FAIL,
  GET_QUOTE_PART_LIST,
  GET_QUOTE_PART_LIST_SUCCESS,
  GET_QUOTE_PART_LIST_FAIL,
  DELETE_QUOTE_PART,
  DELETE_QUOTE_PART_SUCCESS,
  DELETE_QUOTE_PART_FAIL,
  UPDATE_QUOTE_PART,
  UPDATE_QUOTE_PART_SUCCESS,
  UPDATE_QUOTE_PART_FAIL,
  INLINE_UPDATE_QUOTE_PART,
  INLINE_UPDATE_QUOTE_PART_SUCCESS,
  INLINE_UPDATE_QUOTE_PART_FAIL,
  EDIT_QUOTE_PART_MANY,
  EDIT_QUOTE_PART_MANY_SUCCESS,
  EDIT_QUOTE_PART_MANY_FAIL,
  GET_QUOTE_KIT_PART,
  GET_QUOTE_KIT_PART_SUCCESS,
  GET_QUOTE_KIT_PART_FAIL,
  GET_QUOTE_KIT_LABOUR,
  GET_QUOTE_KIT_LABOUR_SUCCESS,
  GET_QUOTE_KIT_LABOUR_FAIL,
  SAVE_QUOTE_KIT_PART,
  SAVE_QUOTE_KIT_PART_SUCCESS,
  SAVE_QUOTE_KIT_PART_FAIL,
  DELETE_QUOTE_KIT_PART,
  DELETE_QUOTE_KIT_PART_SUCCESS,
  DELETE_QUOTE_KIT_PART_FAIL,
  INLINE_UPDATE_QUOTE_KIT_PART,
  INLINE_UPDATE_QUOTE_KIT_PART_SUCCESS,
  INLINE_UPDATE_QUOTE_KIT_PART_FAIL,
  EDIT_QUOTE_KIT_PART_MANY,
  EDIT_QUOTE_KIT_PART_MANY_SUCCESS,
  EDIT_QUOTE_KIT_PART_MANY_FAIL,
  EDIT_QUOTE_KIT_LABOUR_MANY,
  EDIT_QUOTE_KIT_LABOUR_MANY_SUCCESS,
  EDIT_QUOTE_KIT_LABOUR_MANY_FAIL,
  INLINE_UPDATE_QUOTE_KIT_LABOUR,
  INLINE_UPDATE_QUOTE_KIT_LABOUR_SUCCESS,
  INLINE_UPDATE_QUOTE_KIT_LABOUR_FAIL,

} from "./actionTypes";

export const saveQuotePart = () => {
  return {
    type: SAVE_QUOTE_PART,
  };
};

export const saveQuotePartSuccess = (data) => {
  return {
    type: SAVE_QUOTE_PART_SUCCESS,
    payload: data,
  };
};

export const saveQuotePartFail = (error) => {
  return {
    type: SAVE_QUOTE_PART_FAIL,
    payload: error,
  };
};

// Get Quote part START
export const getQuotePartList = () => {
  return {
    type: GET_QUOTE_PART_LIST,
  };
};

export const getQuotePartListSuccess = (data) => {
  return {
    type: GET_QUOTE_PART_LIST_SUCCESS,
    payload: data,
  };
};

export const getQuotePartListFail = (error) => {
  return {
    type: GET_QUOTE_PART_LIST_FAIL,
    payload: error,
  };
};

// Get Quote part END


// delete Quote part start
export const deleteQuotePart = () => {
  return {
    type: DELETE_QUOTE_PART,
  };
};

export const deleteQuotePartSuccess = (data) => {
  return {
    type: DELETE_QUOTE_PART_SUCCESS,
    payload: data,
  };
};

export const deleteQuotePartFail = (error) => {
  return {
    type: DELETE_QUOTE_PART_FAIL,
    payload: error,
  };
};
// delete Quote part end


export const updateQuotePart = () => {
  return {
    type: UPDATE_QUOTE_PART,
  };
};

export const updateQuotePartSuccess = (data) => {
  return {
    type: UPDATE_QUOTE_PART_SUCCESS,
    payload: data,
  };
};

export const updateQuotePartFail = (error) => {
  return {
    type: UPDATE_QUOTE_PART_FAIL,
    payload: error,
  };
};

export const inlineUpdateQuotePart = () => {
  return {
    type: INLINE_UPDATE_QUOTE_PART,
  };
};

export const inlineUpdateQuotePartSuccess = (data) => {
  return {
    type: INLINE_UPDATE_QUOTE_PART_SUCCESS,
    payload: data,
  };
};

export const inlineUpdateQuotePartFail = (error) => {
  return {
    type: INLINE_UPDATE_QUOTE_PART_FAIL,
    payload: error,
  };
};

export const editQuotePartMany = () => {
  return {
    type: EDIT_QUOTE_PART_MANY,
  };
};

export const editQuotePartManySuccess = (data) => {
  return {
    type: EDIT_QUOTE_PART_MANY_SUCCESS,
    payload: data,
  };
};

export const editQuotePartManyFail = (error) => {
  return {
    type: EDIT_QUOTE_PART_MANY_FAIL,
    payload: error,
  };
};


// Get Quote kit part START
export const getQuoteKitPart = () => {
  return {
    type: GET_QUOTE_KIT_PART,
  };
};

export const getQuoteKitPartSuccess = (data) => {
  return {
    type: GET_QUOTE_KIT_PART_SUCCESS,
    payload: data,
  };
};

export const getQuoteKitPartFail = (error) => {
  return {
    type: GET_QUOTE_KIT_PART_FAIL,
    payload: error,
  };
};

// Get Quote kit part END

// Get Quote kit labour START
export const getQuoteKitLabour = () => {
  return {
    type: GET_QUOTE_KIT_LABOUR,
  };
};

export const getQuoteKitLabourSuccess = (data) => {
  return {
    type: GET_QUOTE_KIT_LABOUR_SUCCESS,
    payload: data,
  };
};

export const getQuoteKitLabourFail = (error) => {
  return {
    type: GET_QUOTE_KIT_LABOUR_FAIL,
    payload: error,
  };
};

// Get Quote kit labour END


// Save Quote kit part START
export const saveQuoteKitPart = () => {
  return {
    type: SAVE_QUOTE_KIT_PART,
  };
};

export const saveQuoteKitPartSuccess = (data) => {
  return {
    type: SAVE_QUOTE_KIT_PART_SUCCESS,
    payload: data,
  };
};

export const saveQuoteKitPartFail = (error) => {
  return {
    type: SAVE_QUOTE_KIT_PART_FAIL,
    payload: error,
  };
};
// Save Quote kit labour END

// Delete Quote kit part START
export const deleteQuoteKitPart = () => {
  return {
    type: DELETE_QUOTE_KIT_PART,
  };
};

export const deleteQuoteKitPartSuccess = (data) => {
  return {
    type: DELETE_QUOTE_KIT_PART_SUCCESS,
    payload: data,
  };
};

export const deleteQuoteKitPartFail = (error) => {
  return {
    type: DELETE_QUOTE_KIT_PART_FAIL,
    payload: error,
  };
};
// Delete Quote kit labour END


// Inline Update Quote kit labour START
export const inlineUpdateQuoteKitPart = () => {
  return {
    type: INLINE_UPDATE_QUOTE_KIT_PART,
  };
};

export const inlineUpdateQuoteKitPartSuccess = (data) => {
  return {
    type: INLINE_UPDATE_QUOTE_KIT_PART_SUCCESS,
    payload: data,
  };
};

export const inlineUpdateQuoteKitPartFail = (error) => {
  return {
    type: INLINE_UPDATE_QUOTE_KIT_PART_FAIL,
    payload: error,
  };
};
// Inline Update Quote kit labour END

export const editQuoteKitPartMany = () => {
  return {
    type: EDIT_QUOTE_KIT_PART_MANY,
  };
};

export const editQuoteKitPartManySuccess = (data) => {
  return {
    type: EDIT_QUOTE_KIT_PART_MANY_SUCCESS,
    payload: data,
  };
};

export const editQuoteKitPartManyFail = (error) => {
  return {
    type: EDIT_QUOTE_KIT_PART_MANY_FAIL,
    payload: error,
  };
};

export const editQuoteKitLabourMany = () => {
  return {
    type: EDIT_QUOTE_KIT_LABOUR_MANY,
  };
};

export const editQuoteKitLabourManySuccess = (data) => {
  return {
    type: EDIT_QUOTE_KIT_LABOUR_MANY_SUCCESS,
    payload: data,
  };
};

export const editQuoteKitLabourManyFail = (error) => {
  return {
    type: EDIT_QUOTE_KIT_LABOUR_MANY_FAIL,
    payload: error,
  };
};

// Inline Update Quote kit labour START
export const inlineUpdateQuoteKitLabour = () => {
  return {
    type: INLINE_UPDATE_QUOTE_KIT_LABOUR,
  };
};

export const inlineUpdateQuoteKitLabourSuccess = (data) => {
  return {
    type: INLINE_UPDATE_QUOTE_KIT_LABOUR_SUCCESS,
    payload: data,
  };
};

export const inlineUpdateQuoteKitLabourFail = (error) => {
  return {
    type: INLINE_UPDATE_QUOTE_KIT_LABOUR_FAIL,
    payload: error,
  };
};
// Inline Update Quote kit labour END