export const SAVE_QUOTE_LABOUR = "SAVE_QUOTE_LABOUR";
export const SAVE_QUOTE_LABOUR_SUCCESS = "SAVE_QUOTE_LABOUR_SUCCESS";
export const SAVE_QUOTE_LABOUR_FAIL = "SAVE_QUOTE_LABOUR_FAIL";

export const GET_QUOTE_LABOUR_LIST = "GET_QUOTE_LABOUR_LIST";
export const GET_QUOTE_LABOUR_LIST_SUCCESS = "GET_QUOTE_LABOUR_LIST_SUCCESS";
export const GET_QUOTE_LABOUR_LIST_FAIL = "GET_QUOTE_LABOUR_LIST_FAIL";

export const DELETE_QUOTE_LABOUR = "DELETE_QUOTE_LABOUR";
export const DELETE_QUOTE_LABOUR_SUCCESS = "DELETE_QUOTE_LABOUR_SUCCESS";
export const DELETE_QUOTE_LABOUR_FAIL = "DELETE_QUOTE_LABOUR_FAIL";

export const UPDATE_QUOTE_LABOUR = "UPDATE_QUOTE_LABOUR";
export const UPDATE_QUOTE_LABOUR_SUCCESS = "UPDATE_QUOTE_LABOUR_SUCCESS";
export const UPDATE_QUOTE_LABOUR_FAIL = "UPDATE_QUOTE_LABOUR_FAIL";

export const EDIT_QUOTE_LABOUR_MANY = "EDIT_QUOTE_LABOUR_MANY";
export const EDIT_QUOTE_LABOUR_MANY_SUCCESS = "EDIT_QUOTE_LABOUR_MANY_SUCCESS";
export const EDIT_QUOTE_LABOUR_MANY_FAIL = "EDIT_QUOTE_LABOUR_MANY_FAIL";

export const INLINE_UPDATE_LABOUR = "INLINE_UPDATE_LABOUR";
export const INLINE_UPDATE_LABOUR_SUCCESS = "INLINE_UPDATE_LABOUR_SUCCESS";
export const INLINE_UPDATE_LABOUR_FAIL = "INLINE_UPDATE_LABOUR_FAIL";
