import { takeLatest, put, call } from "redux-saga/effects";
import {
    GET_TECHNICIAN_SCHEDULE,
    GET_WORKSHOP_OTHER_DETAILS,
    GET_TECHNICIAN_HOUR,
    GET_WORKSHOP_BAYS,
    GET_INSURANCE,
    GET_JOB_TAGS,
    ADD_JOB_TAGS,
    PRINT_JOB_CARD_PDF,
    CHECK_AVAILABLE_HOURS,
    CREATE_QUOTE_TO_JOB,
    GET_AVAIALABLE_SLOTS,
    GET_CARRY_OVER_JOB,
    GET_JOBS_KIT_PARTS,
    GET_JOBS_KIT_LABOURS,
    ADD_MANY_JOB_PARTS,
    ADD_MANY_JOB_LABOURS,
    ADD_MANY_JOB_KIT_PARTS,
    ADD_MANY_JOB_KIT_LABOURS,
    GET_ACTIVITY,
    GET_SCHEDULE_ACTIVITY,
    ADD_ACTIVITY,
    ADD_SCHEDULE_ACTIVITY,
    ADD_MANY_CREATE_JOB,
    DELETE_JOB_ACTIVITY,
    DELETE_JOB_SCHEDULE_ACTIVITY,
    GET_MONTH_JOB_LIST,
    UPDATE_CARRY_OVER_JOB,
    GET_TIMER_DETAILS,
    GET_OPEN_REQUEST_JOB,
    GET_QUOTE_DETAILS,
    GET_QUOTE_KIT_PARTS,
    GET_QUOTE_KIT_LABOURS
} from "./actionTypes";

import {
    getTechnicianScheduleSuccess,
    getTechnicianScheduleFail,
    getWorkshopOtherDetailsSuccess,
    getWorkshopOtherDetailsFail,
    getTechnicianHourSuccess,
    getTechnicianHourFail,
    getWorkshopBaysSuccess,
    getWorkshopBaysFail,
    getInsuranceSuccess,
    getInsuranceFail,
    getJobTagsSuccess,
    getJobTagsFail,
    addJobTagsSuccess,
    addJobTagsFail,
    printJobCardPdfSuccess,
    printJobCardPdfFail,
    checkAvailableHoursSuccess,
    checkAvailableHoursFail,
    createQuoteToJobSuccess,
    createQuoteToJobFail,
    getAvailableSlotsSuccess,
    getAvailableSlotsFail,
    getCarryOverJobSuccess,
    getCarryOverJobFail,
    getJobsKitPartsSuccess,
    getJobsKitPartsFail,
    getJobsKitLaboursSuccess,
    getJobsKitLaboursFail,
    addManyJobPartsSuccess,
    addManyJobPartsFail,
    addManyJobLaboursSuccess,
    addManyJobLaboursFail,
    addManyJobKitPartsSuccess,
    addManyJobKitPartsFail,
    addManyJobKitLaboursSuccess,
    addManyJobKitLaboursFail,
    getActivitySuccess,
    getActivityFail,
    getScheduleActivitySuccess,
    getScheduleActivityFail,
    addActivitySuccess,
    addActivityFail,
    addScheduleActivitySuccess,
    addScheduleActivityFail,
    addManyCreateJobSuccess,
    addManyCreateJobFail,
    deleteJobActivitySuccess,
    deleteJobActivityFail,
    deleteJobScheduleActivitySuccess,
    deleteJobScheduleActivityFail,
    getMonthJobListSuccess,
    getMonthJobListFail,
    updateCarryOverJobSuccess,
    updateCarryOverJobFail,
    getTimerDetailsSuccess,
    getTimerDetailsFail,
    getOpenRequestJobSuccess,
    getOpenRequestJobFail,
    getQuoteDetailsSuccess,
    getQuoteDetailsFail,
    getQuoteKitPartsSuccess,
    getQuoteKitPartsFail,
    getQuoteKitLaboursSuccess,
    getQuoteKitLaboursFail,
} from "./actions";

import {
    getTechnician,
    getWorkshopOtherDetails,
    getTechnicianHour,
    getWorkshopBays,
    getInsurance,
    getJobTags,
    addJobTags,
    printJobCardPdf,
    checkAvailableHours,
    createQuoteToJob,
    getAvailableSlots,
    getCarryOverJob,
    getJobsKitParts,
    getJobsKitLabours,
    addManyJobParts,
    addManyJobLabours,
    addManyJobKitParts,
    addManyJobKitLabours,
    getActivity,
    getScheduleActivity,
    addActivity,
    addScheduleActivity,
    addManyCreateJob,
    deleteJobActivity,
    deleteJobScheduleActivity,
    getMonthJobList,
    updateCarryOverJob,
    getTimerDetails,
    getOpenRequestJob,
    getQuoteDetails,
    getQuoteKitParts,
    getQuoteKitLabours,
} from "../../helpers/backendHelper";

function* onGetTechnician(data) {
    try {
        const response = yield call(getTechnician, data);
        if (response) {
            if (response.code === 200) {
                yield put(getTechnicianScheduleSuccess(response));
            } else {
                yield put(getTechnicianScheduleFail(response.message));
            }
        } else {
            yield put(getTechnicianScheduleFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getTechnicianScheduleFail(error.response));
    }
}

function* onGetWorkshopOtherDetails(data) {
    try {
        const response = yield call(getWorkshopOtherDetails, data);
        if (response) {
            if (response.code === 200) {
                yield put(getWorkshopOtherDetailsSuccess(response));
            } else {
                yield put(getWorkshopOtherDetailsFail(response.message));
            }
        } else {
            yield put(getWorkshopOtherDetailsFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getWorkshopOtherDetailsFail(error.response));
    }
}

function* onGetTechnicianHour(data) {
    try {
        const response = yield call(getTechnicianHour, data);
        if (response) {
            if (response.code === 200) {
                yield put(getTechnicianHourSuccess(response));
            } else {
                yield put(getTechnicianHourFail(response.message));
            }
        } else {
            yield put(getTechnicianHourFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getTechnicianHourFail(error.response));
    }
}

function* onGetWorkshopBays(data) {
    try {
        const response = yield call(getWorkshopBays, data);
        if (response) {
            if (response.code === 200) {
                yield put(getWorkshopBaysSuccess(response));
            } else {
                yield put(getWorkshopBaysFail(response.message));
            }
        } else {
            yield put(getWorkshopBaysFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getWorkshopBaysFail(error.response));
    }
}

function* onGetInsurance(data) {
    try {
        const response = yield call(getInsurance, data);
        if (response) {
            if (response.code === 200) {
                yield put(getInsuranceSuccess(response));
            } else {
                yield put(getInsuranceFail(response.message));
            }
        } else {
            yield put(getInsuranceFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getInsuranceFail(error.response));
    }
}

function* onGetJobTags(data) {
    try {
        const response = yield call(getJobTags, data);
        if (response) {
            if (response.code === 200) {
                yield put(getJobTagsSuccess(response));
            } else {
                yield put(getJobTagsFail(response.message));
            }
        } else {
            yield put(getJobTagsFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getJobTagsFail(error.response));
    }
}

function* onAddJobTags(data) {
    try {
        const response = yield call(addJobTags, data);
        if (response) {
            if (response.code === 200) {
                yield put(addJobTagsSuccess(response));
            } else {
                yield put(addJobTagsFail(response.message));
            }
        } else {
            yield put(addJobTagsFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(addJobTagsFail(error.response));
    }
}

function* onPrintJobCardPdf(data) {
    try {
        const response = yield call(printJobCardPdf, data);
        if (response) {
            if (response.code === 200) {
                yield put(printJobCardPdfSuccess(response));
            } else {
                yield put(printJobCardPdfFail(response.message));
            }
        } else {
            yield put(printJobCardPdfFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(printJobCardPdfFail(error.response));
    }
}

function* onCheckAvailableHours(data) {
    try {
        const response = yield call(checkAvailableHours, data);
        if (response) {
            if (response.code === 200) {
                if (response.data.JobSchedules === 1) {
                    yield put(checkAvailableHoursSuccess(true));
                } else {
                    yield put(checkAvailableHoursSuccess(false));
                }
            } else {
                yield put(checkAvailableHoursFail(false));
            }
        } else {
            yield put(checkAvailableHoursFail("Didn't get api response"));
        }
    } catch (error) {
        console.log("error", error);
        yield put(checkAvailableHoursFail(error.response));
    }
}

function* onCreateQuoteToJob(data) {
    try {
        const response = yield call(createQuoteToJob, data);
        if (response) {
            if (response.code === 200) {
                yield put(createQuoteToJobSuccess(response.data.JobSchedules));
            } else {
                yield put(createQuoteToJobFail(response.message));
            }
        } else {
            yield put(createQuoteToJobFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(createQuoteToJobFail(error.response));
    }
}

function* onGetAvailableSlots(data) {
    try {
        const response = yield call(getAvailableSlots, data);
        if (response) {
            if (response.code === 200) {
                yield put(getAvailableSlotsSuccess(response.data.JobSchedules));
            } else {
                yield put(getAvailableSlotsFail(response.message));
            }
        } else {
            yield put(getAvailableSlotsFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getAvailableSlotsFail(error.response));
    }
}

function* onGetCarryOverJob(data) {
    try {
        const response = yield call(getCarryOverJob, data);
        if (response) {
            if (response.code === 200) {
                yield put(getCarryOverJobSuccess(response.data.JobSchedules));
            } else {
                yield put(getCarryOverJobFail(response.message));
            }
        } else {
            yield put(getCarryOverJobFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getCarryOverJobFail(error.response));
    }
}

function* onGetJobsKitParts(data) {
    try {
        const response = yield call(getJobsKitParts, data);
        if (response) {
            if (response.code === 200) {
                yield put(getJobsKitPartsSuccess(response.data.JobKitParts));
            } else {
                yield put(getJobsKitPartsFail(response.message));
            }
        } else {
            yield put(getJobsKitPartsFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getJobsKitPartsFail(error.response));
    }
}

function* onGetJobsKitLabours(data) {
    try {
        const response = yield call(getJobsKitLabours, data);
        if (response) {
            if (response.code === 200) {
                yield put(getJobsKitLaboursSuccess(response.data.JobKitLabours));
            } else {
                yield put(getJobsKitLaboursFail(response.message));
            }
        } else {
            yield put(getJobsKitLaboursFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getJobsKitLaboursFail(error.response));
    }
}

function* onAddManyJobParts(data) {
    try {
        const response = yield call(addManyJobParts, data);
        if (response) {
            if (response.code === 200) {
                yield put(addManyJobPartsSuccess(response.data));
            } else {
                yield put(addManyJobPartsFail(response.message));
            }
        } else {
            yield put(addManyJobPartsFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(addManyJobPartsFail(error.response));
    }
}

function* onAddManyJobLabours(data) {
    try {
        const response = yield call(addManyJobLabours, data);
        if (response) {
            if (response.code === 200) {
                yield put(addManyJobLaboursSuccess(response.data));
            } else {
                yield put(addManyJobLaboursFail(response.message));
            }
        } else {
            yield put(addManyJobLaboursFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(addManyJobLaboursFail(error.response));
    }
}

function* onAddManyJobKitParts(data) {
    try {
        const response = yield call(addManyJobKitParts, data);
        if (response) {
            if (response.code === 200) {
                yield put(addManyJobKitPartsSuccess(response.data));
            } else {
                yield put(addManyJobKitPartsFail(response.message));
            }
        } else {
            yield put(addManyJobKitPartsFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(addManyJobKitPartsFail(error.response));
    }
}

function* onAddManyJobKitLabours(data) {
    try {
        const response = yield call(addManyJobKitLabours, data);
        if (response) {
            if (response.code === 200) {
                yield put(addManyJobKitLaboursSuccess(response.data));
            } else {
                yield put(addManyJobKitLaboursFail(response.message));
            }
        } else {
            yield put(addManyJobKitLaboursFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(addManyJobKitLaboursFail(error.response));
    }
}

function* onGetActivity(data) {
    try {
        const response = yield call(getActivity, data);
        if (response) {
            if (response.code === 200) {
                yield put(getActivitySuccess(response.data.ActivityMaster));
            } else {
                yield put(getActivityFail(response.message));
            }
        } else {
            yield put(getActivityFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getActivityFail(error.response));
    }
}

function* onGetScheduleActivity(data) {
    try {
        const response = yield call(getScheduleActivity, data);
        if (response) {
            if (response.code === 200) {
                yield put(getScheduleActivitySuccess(response.data.ActivitySchedule));
            } else {
                yield put(getScheduleActivityFail(response.message));
            }
        } else {
            yield put(getScheduleActivityFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getScheduleActivityFail(error.response));
    }
}

function* onAddActivity(data) {
    try {
        const response = yield call(addActivity, data);
        if (response) {
            if (response.code === 200) {
                yield put(addActivitySuccess(response.data.ActivityMaster));
            } else {
                yield put(addActivityFail(response.message));
            }
        } else {
            yield put(addActivityFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(addActivityFail(error.response));
    }
}

function* onAddScheduleActivity(data) {
    try {
        const response = yield call(addScheduleActivity, data);
        if (response) {
            if (response.code === 200) {
                yield put(addScheduleActivitySuccess(response.data.ActivitySchedule));
            } else {
                yield put(addScheduleActivityFail(response.message));
            }
        } else {
            yield put(addScheduleActivityFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(addScheduleActivityFail(error.response));
    }
}

function* onAddManyCreateJob(data) {
    try {
        const response = yield call(addManyCreateJob, data);
        if (response) {
            if (response.code === 200) {
                yield put(addManyCreateJobSuccess(response.data.JobSchedules));
            } else {
                yield put(addManyCreateJobFail(response.message));
            }
        } else {
            yield put(addManyCreateJobFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(addManyCreateJobFail(error.response));
    }
}

function* onDeleteJobActivity(data) {
    try {
        const response = yield call(deleteJobActivity, data);
        if (response) {
            if (response.code === 200) {
                yield put(deleteJobActivitySuccess(response.data.JobSchedules));
            } else {
                yield put(deleteJobActivityFail(response.message));
            }
        } else {
            yield put(deleteJobActivityFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(deleteJobActivityFail(error.response));
    }
}

function* onDeleteJobScheduleActivity(data) {
    try {
        const response = yield call(deleteJobScheduleActivity, data);
        if (response) {
            if (response.code === 200) {
                yield put(deleteJobScheduleActivitySuccess(response.data.ActivitySchedule));
            } else {
                yield put(deleteJobScheduleActivityFail(response.message));
            }
        } else {
            yield put(deleteJobScheduleActivityFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(deleteJobScheduleActivityFail(error.response));
    }
}

function* onGetMonthJobList(data) {
    try {
        const response = yield call(getMonthJobList, data);
        if (response) {
            if (response.code === 200) {
                yield put(getMonthJobListSuccess(response.data.JobSchedules));
            } else {
                yield put(getMonthJobListFail(response.message));
            }
        } else {
            yield put(getMonthJobListFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getMonthJobListFail(error.response));
    }
}

function* onUpdateCarryOverJob(data) {
    try {
        const response = yield call(updateCarryOverJob, data);
        if (response) {
            if (response.code === 200) {
                yield put(updateCarryOverJobSuccess(response.data.JobSchedules));
            } else {
                yield put(updateCarryOverJobFail(response.message));
            }
        } else {
            yield put(updateCarryOverJobFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(updateCarryOverJobFail(error.response));
    }
}

function* onGetTimerDetails(data) {
    try {
        const response = yield call(getTimerDetails, data);
        if (response) {
            if (response.code === 200) {
                yield put(getTimerDetailsSuccess(response.data.JobSchedules));
            } else {
                yield put(getTimerDetailsFail(response.message));
            }
        } else {
            yield put(getTimerDetailsFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getTimerDetailsFail(error.response));
    }
}

function* onGetOpenRequestJob(data) {
    try {
        const response = yield call(getOpenRequestJob, data);
        if (response) {
            if (response.code === 200) {
                yield put(getOpenRequestJobSuccess(response.data.JobSchedules));
            } else {
                yield put(getOpenRequestJobFail(response.message));
            }
        } else {
            yield put(getOpenRequestJobFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getOpenRequestJobFail(error.response));
    }
}

function* onGetQuoteDetails(data) {
    try {
        const response = yield call(getQuoteDetails, data);
        if (response) {
            if (response.code === 200) {
                yield put(getQuoteDetailsSuccess(response.data.CustomerQuotes));
            } else {
                yield put(getQuoteDetailsFail(response.message));
            }
        } else {
            yield put(getQuoteDetailsFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getQuoteDetailsFail(error.response));
    }
}

function* onGetQuoteKitParts(data) {
    try {
        const response = yield call(getQuoteKitParts, data);
        if (response) {
            if (response.code === 200) {
                yield put(getQuoteKitPartsSuccess(response.data.QuoteKitParts));
            } else {
                yield put(getQuoteKitPartsFail(response.message));
            }
        } else {
            yield put(getQuoteKitPartsFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getQuoteKitPartsFail(error.response));
    }
}

function* onGetQuoteKitLabours(data) {
    try {
        const response = yield call(getQuoteKitLabours, data);
        if (response) {
            if (response.code === 200) {
                yield put(getQuoteKitLaboursSuccess(response.data.QuoteKitLabours));
            } else {
                yield put(getQuoteKitLaboursFail(response.message));
            }
        } else {
            yield put(getQuoteKitLaboursFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getQuoteKitLaboursFail(error.response));
    }
}

function* SchedulerSaga() {
    yield takeLatest(GET_TECHNICIAN_SCHEDULE, onGetTechnician);
    yield takeLatest(GET_WORKSHOP_OTHER_DETAILS, onGetWorkshopOtherDetails);
    yield takeLatest(GET_TECHNICIAN_HOUR, onGetTechnicianHour);
    yield takeLatest(GET_WORKSHOP_BAYS, onGetWorkshopBays);
    yield takeLatest(GET_INSURANCE, onGetInsurance);
    yield takeLatest(GET_JOB_TAGS, onGetJobTags);
    yield takeLatest(ADD_JOB_TAGS, onAddJobTags);
    yield takeLatest(PRINT_JOB_CARD_PDF, onPrintJobCardPdf);
    yield takeLatest(CHECK_AVAILABLE_HOURS, onCheckAvailableHours);
    yield takeLatest(CREATE_QUOTE_TO_JOB, onCreateQuoteToJob);
    yield takeLatest(GET_AVAIALABLE_SLOTS, onGetAvailableSlots);
    yield takeLatest(GET_CARRY_OVER_JOB, onGetCarryOverJob);
    yield takeLatest(GET_JOBS_KIT_PARTS, onGetJobsKitParts);
    yield takeLatest(GET_JOBS_KIT_LABOURS, onGetJobsKitLabours);
    yield takeLatest(ADD_MANY_JOB_PARTS, onAddManyJobParts);
    yield takeLatest(ADD_MANY_JOB_LABOURS, onAddManyJobLabours);
    yield takeLatest(ADD_MANY_JOB_KIT_PARTS, onAddManyJobKitParts);
    yield takeLatest(ADD_MANY_JOB_KIT_LABOURS, onAddManyJobKitLabours);
    yield takeLatest(GET_ACTIVITY, onGetActivity);
    yield takeLatest(GET_SCHEDULE_ACTIVITY, onGetScheduleActivity);
    yield takeLatest(ADD_ACTIVITY, onAddActivity);
    yield takeLatest(ADD_SCHEDULE_ACTIVITY, onAddScheduleActivity);
    yield takeLatest(ADD_MANY_CREATE_JOB, onAddManyCreateJob);
    yield takeLatest(DELETE_JOB_ACTIVITY, onDeleteJobActivity);
    yield takeLatest(DELETE_JOB_SCHEDULE_ACTIVITY, onDeleteJobScheduleActivity);
    yield takeLatest(GET_MONTH_JOB_LIST, onGetMonthJobList);
    yield takeLatest(UPDATE_CARRY_OVER_JOB, onUpdateCarryOverJob);
    yield takeLatest(GET_TIMER_DETAILS, onGetTimerDetails);
    yield takeLatest(GET_OPEN_REQUEST_JOB, onGetOpenRequestJob);
    yield takeLatest(GET_QUOTE_DETAILS, onGetQuoteDetails);
    yield takeLatest(GET_QUOTE_KIT_PARTS, onGetQuoteKitParts);
    yield takeLatest(GET_QUOTE_KIT_LABOURS, onGetQuoteKitLabours);
}

export default SchedulerSaga;
