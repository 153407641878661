import { takeLatest, put, call } from "redux-saga/effects";
import { GET_COMPLETED_JOBS, GET_COMPLETED_JOBS_COUNT } from "./actionTypes";
import {
  getincompletedJobsSuccess,
  getincompletedJobsFail,
  getincompletedJobsCountSuccess,
  getincompletedJobsCountFail,
} from "./actions";

import { getcompletedJobs } from "../../helpers/backendHelper";

function* onGetcompletedJobs(data) {
  try {
    const response = yield call(getcompletedJobs, data);
    if (response) {
      if (response.code === 200) {
        yield put(getincompletedJobsSuccess(response.data.JobSchedules));
      } else {
        yield put(getincompletedJobsFail(response.message));
      }
    } else {
      yield put(getincompletedJobsFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getincompletedJobsFail(error.response));
  }
}

function* onGetcompletedJobsCount(data) {
  try {
    const response = yield call(getcompletedJobs, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getincompletedJobsCountSuccess(response.data.JobSchedules[0].COUNT)
        );
      } else {
        yield put(getincompletedJobsCountFail(response.message));
      }
    } else {
      yield put(getincompletedJobsCountFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getincompletedJobsCountFail(error.response));
  }
}

function* completedJobsSaga() {
  yield takeLatest(GET_COMPLETED_JOBS, onGetcompletedJobs);
  yield takeLatest(GET_COMPLETED_JOBS_COUNT, onGetcompletedJobsCount);
}

export default completedJobsSaga;
