import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import LoginReducer from './login/reducer';
import RegisterReducer from './register/reducer';
import ForgotPasswordReducer from './forgotPassword/reducer';
import ForgotEmailReducer from './forgotEmail/reducer';
import NotificationReducer from './notification/reducer';
import NotificationListReducer from './notificationList/reducer';
import UserProfileReducer from './userProfile/reducer';
import ChangePasswordReducer from './changePassword/reducer';
import SubscriptionReducer from './subscription/reducer';
import SubscriptionAddonReducer from './subscriptionAddon/reducer';
import GlobalSearchReducer from './globalSearch/reducer';
import SchedulerReducer from './scheduler/reducer';
import OpenQuotesReducer from './openQuotes/reducer';
import WonQuotesReducer from './wonQuotes/reducer';
import LostQuotesReducer from './lostQuotes/reducer';
import QuoteDetails from './QuoteDetails/reducer';
import UnassignedJobsReducer from './unassignedJobs/reducer';
import assignedJobsReducer from './assignedJobs/reducer';
import inProgressJobsReducer from './inProcressJobs/reducer';
import completedJobsReducer from './completedJobs/reducer';
import CommonApiStoreReducer from './commonApiStore/reducer';
import JobPartReducer from './jobParts/reducer';
import PartReducer from './parts/reducer';
import JobDetails from './jobDetails/reducer';
import SupplierReducer from './supplier/reducer';
import jobReducer from './Jobs/reducer';
import CompanyDetailReducer from './companyDetails/reducer';
import defaultSettingsReducer from './defaultSettings/reducer';
import JobLabourReducer from './jobLabour/reducer';
import ContactsReducer from './contacts/reducer';
import SecurityGroupReducer from './securityGroup/reducer';
import QuotePartReducer from './QuoteParts/reducer';
import AssetsReducer from './assets/reducer';
import invoicesReducer from './invoices/reducer';
import StatusReducer from './statuses/reducer';
import invoicePaymentReducer from './invoicePayment/reducer';
import WorkshopBaysReducer from './workshopBays/reducer';
import { invoiceDetailReducer } from './invoiceDetail/reducer';
import QuoteLabourReducer from './quoteLabour/reducer';
import VehicleReducer from './vehicles/reducer';
import marketingListReducers from './marketing/reducer';

const rootReducer = combineReducers({
  LoginReducer,
  RegisterReducer,
  ForgotPasswordReducer,
  ForgotEmailReducer,
  NotificationReducer,
  NotificationListReducer,
  UserProfileReducer,
  ChangePasswordReducer,
  GlobalSearchReducer,
  SchedulerReducer,
  OpenQuotesReducer,
  WonQuotesReducer,
  LostQuotesReducer,
  UnassignedJobsReducer,
  assignedJobsReducer,
  inProgressJobsReducer,
  completedJobsReducer,
  CommonApiStoreReducer,
  SubscriptionReducer,
  SubscriptionAddonReducer,
  JobPartReducer,
  PartReducer,
  JobDetails,
  SupplierReducer,
  jobs: jobReducer,
  CompanyDetailReducer,
  defaultSettingsReducer,
  JobLabourReducer,
  ContactsReducer,
  SecurityGroupReducer,
  QuoteDetails,
  QuotePartReducer,
  AssetsReducer,
  StatusReducer,
  WorkshopBaysReducer,
  form: formReducer,
  invoice: invoicesReducer,
  marketing: marketingListReducers,
  invoiceDetail: invoiceDetailReducer,
  invoicePaymentReducer,
  QuoteLabourReducer,
  VehicleReducer,
});

export default rootReducer;
