import { takeLatest, put, call } from "redux-saga/effects";
import { GET_SUBSCRIPTION_ADDON,GET_SUBSCRIPTION_LOOKUP_ADDON } from "./actionTypes";
import {
  getSubscriptionAddonSuccess,
  getSubscriptionAddonFail,
  getSubscriptionLookupAddonSuccess,
  getSubscriptionLookupAddonFail
} from "./actions";

import { getSubscriptionAddon } from "../../helpers/backendHelper";

function* ongetSubscriptionAddon(data) {
  try {
    const response = yield call(getSubscriptionAddon, data);
    const lastarray = parseInt(response.data.WorkshopSubscriptionDetails.length) - 1;
    if(response){
      if(response.code===200){
        yield put(getSubscriptionAddonSuccess(response.data.WorkshopSubscriptionDetails[lastarray]));
      }else{
        yield put(getSubscriptionAddonFail(response.message));
      }
    }else{
      yield put(getSubscriptionAddonFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(getSubscriptionAddonFail(error.response));
  }
}


function* ongetSubscriptionLookupAddon(data) {
  try {
    const response = yield call(getSubscriptionAddon, data);
    const lastarray = parseInt(response.data.WorkshopSubscriptionDetails.length) - 1;
    if(response){
      if(response.code===200){
        yield put(getSubscriptionLookupAddonSuccess(response.data.WorkshopSubscriptionDetails[lastarray]));
      }else{
        yield put(getSubscriptionLookupAddonFail(response.message));
      }
    }else{
      yield put(getSubscriptionLookupAddonFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(getSubscriptionLookupAddonFail(error.response));
  }
}

function* SubscriptionSaga() {
  yield takeLatest(GET_SUBSCRIPTION_ADDON, ongetSubscriptionAddon);
  yield takeLatest(GET_SUBSCRIPTION_LOOKUP_ADDON, ongetSubscriptionLookupAddon);
}

export default SubscriptionSaga;
