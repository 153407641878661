import { getInvoicesCredits } from "helpers/backendHelper";
import { getWorkshopId } from "utils/getWorkshopId";

export async function getInvoiceCreditedViewDetail(id) {
  let invoice,
    customer,
    job_detail,
    invoices_payments_credit,
    invoices_payments_credits_other,
    all_credits;

  const request1 = {
    get: "all",
    conditions: {
      "InvoicesPaymentsCreditsOtherDetails.id": id,
    },
    contain: {
      0: "Workshops",
      1: "InvoicesPaymentsCredits.Invoices.JobSchedules.Vehicles.Users.States",
      2: "InvoicesPaymentsCredits.Invoices.JobSchedules.CreatedByUser",
      "InvoicesPaymentsCredits.Invoices.JobSchedules.Vehicles.Users.CustomersDetails":
        {
          conditions: {
            workshop_id: getWorkshopId(),
          },
        },
    },
  };

  const get_invoices = await getInvoicesCredits({ payload: request1 });

  if (get_invoices?.code === 200) {
    invoices_payments_credits_other =
      get_invoices?.data?.InvoicesPaymentsCreditsOtherDetails[0];
    invoices_payments_credit =
      invoices_payments_credits_other?.invoices_payments_credit;
    invoice = invoices_payments_credit?.invoice;
    job_detail = invoice?.job_schedule;
    customer = job_detail?.vehicle?.user;

    const request2 = {
      get: "all",
      conditions: {
        "InvoicesPaymentsCreditsOtherDetails.invoice_payment_credit_id":
          invoices_payments_credits_other?.invoice_payment_credit_id,
      },
    };

    const get_invoices_credits = await getInvoicesCredits({
      payload: request2,
    });
    if (get_invoices_credits?.code === 200) {
      all_credits =
        get_invoices_credits?.data?.InvoicesPaymentsCreditsOtherDetails;
    }
  }

  return {
    invoice,
    customer,
    job_detail: job_detail,
    invoices_payments_credit,
    invoices_payments_credits_other,
    all_credits,
  };
}
