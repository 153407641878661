import React, { useEffect } from "react";
import ProgressBar from 'react-bootstrap/ProgressBar';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Mechanic from "../../../assets/images/mechanic.svg";
import CustomerBookingRequest from "../../../assets/images/ic_customer_blue.svg";

const Resources = ({ resource, view })=> {
  // if(resource.extendedProps.is_on_leave === 1 ){
  //   if (document.querySelectorAll(`[data-resource-id="${resource.id}"]`).length){
  //     console.log(document.querySelectorAll(`[data-resource-id="${resource.id}"]`), "123")
  //     document.querySelectorAll(`[data-resource-id="${resource.id}"]`)[1].style.backgroundColor = 'pink';
  //   }
  // }
  var techPercentage = resource.extendedProps.tech_percent;
  var techAvailablaePercentage = parseFloat(100) - parseFloat(techPercentage);
    
  // extendedProps is used to access additional event properties.
  const content = (

    <ResourcesDetail>      
      <span className="resource-profile">
        {(resource.id === "z" ) ? 
          <img className="mechanic-img" src={CustomerBookingRequest} alt={resource.title} />
        :<img className="mechanic-img" src={(resource.extendedProps.user_profile !== "bay") ? resource.extendedProps.imageUrl : Mechanic } alt={resource.title} />
      }</span>
      <div className="right-block">
        <p>{resource.title}</p>
          {(resource.id !== "z" ) ? 
              (resource.extendedProps.user_profile !== "bay") ? 
              <OverlayTrigger
                    key="bottom"
                    placement="bottom"
                    overlay={
                      <Tooltip id="tooltip-bottom" className="resource-tooltip">
                        Booked :{techPercentage}% Available : {techAvailablaePercentage}%
                      </Tooltip>
                    }
                  >
                  {view.type === "resourceTimelineWeek" ? <span></span> : <ProgressBar now={techPercentage} placement="right" />}
                  {/* <ProgressBar now={techPercentage} placement="right" /> */}
                </OverlayTrigger>
              :
              
               view.type === "resourceTimelineWeek" ? "" : <span className="booked-hours">{resource.extendedProps.available_hrs} Hrs</span>
        : "" }
       
      </div>
    </ResourcesDetail>
  );
  return content;
};

export default Resources;

// This is only a wrapper so the component reads nicer in React Debugger. It is completely unnecessary.
const ResourcesDetail = ({ ...props }) => (
  
  <div className="resource-outer">{props.children}</div>
);
