import {
  GET_UPDATE_VEHICLE,
  GET_UPDATE_VEHICLE_SUCCESS,
  GET_UPDATE_VEHICLE_FAIL,
} from "./actionTypes";

const initialState = {
  vehicleData: null,
  error: {
    message: "",
  },
};

const VehicleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_UPDATE_VEHICLE:
      state = {
        ...state,
      };
      break;
    case GET_UPDATE_VEHICLE_SUCCESS:
      state = {
        ...state,
        vehicleData: action.payload
      };
      break;
    case GET_UPDATE_VEHICLE_FAIL:
      state = {
        ...state,
        vehicleData: action.payload,
        error: {
          message: action.payload,
        }
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default VehicleReducer;
