import Loadable from "react-loadable";

import { Loading } from "../common/navigation";

const Register = Loadable({
  loader: () => import("./components/register"),
  loading: Loading,
});

export const routes = [
  {
    path: "/register",
    exact: true,
    name: "register",
    element: Register,
  }
];

export * from "./components";
