import {
  GET_SECURITY_GROUP_DETAILS,
  GET_SECURITY_GROUP_DETAILS_FAIL,
  GET_SECURITY_GROUP_DETAILS_SUCCESS,
  UPDATE_SECURITY_GROUP_DETAILS,
  UPDATE_SECURITY_GROUP_DETAILS_SUCCESS,
  UPDATE_SECURITY_GROUP_DETAILS_FAIL,
  ADD_SECURITY_GROUP_DETAILS,
  ADD_SECURITY_GROUP_DETAILS_SUCCESS,
  ADD_SECURITY_GROUP_DETAILS_FAIL,
} from "./actionTypes";

export const getSecurityGroupDetail = () => {
  return {
    type: GET_SECURITY_GROUP_DETAILS,
  };
};

export const getSecurityGroupDetailSuccess = (data) => {
  return {
    type: GET_SECURITY_GROUP_DETAILS_SUCCESS,
    payload: data.data.SecurityGroupsDetails,
    message: data.message,
  };
};

export const getSecurityGroupDetailFail = (error) => {
  return {
    type: GET_SECURITY_GROUP_DETAILS_FAIL,
    payload: error,
  };
};

export const addSecurityGroupDetail = () => {
  return {
    type: ADD_SECURITY_GROUP_DETAILS,
  };
};

export const addSecurityGroupDetailSuccess = (data) => {
  return {
    type: ADD_SECURITY_GROUP_DETAILS_SUCCESS,
    payload: data.data.SecurityGroupsDetails,
    message: data.message,
  };
};

export const addSecurityGroupDetailFail = (error) => {
  return {
    type: ADD_SECURITY_GROUP_DETAILS_FAIL,
    payload: error,
  };
};

export const updateSecurityGroupDetail = () => {
  return {
    type: UPDATE_SECURITY_GROUP_DETAILS,
  };
};

export const updateSecurityGroupDetailSuccess = (data) => {
  return {
    type: UPDATE_SECURITY_GROUP_DETAILS_SUCCESS,
    payload: data.data.SecurityGroupsDetails,
    message: data.message,
  };
};

export const updateSecurityGroupDetailFail = (error) => {
  return {
    type: UPDATE_SECURITY_GROUP_DETAILS_FAIL,
    payload: error,
  };
};
