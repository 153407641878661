import {
  GET_UNASSIGNED_JOBS,
  GET_UNASSIGNED_JOBS_SUCCESS,
  GET_UNASSIGNED_JOBS_FAIL,
  GET_CUSTOMERS_LIST,
  GET_CUSTOMERS_LIST_SUCCESS,
  GET_CUSTOMERS_LIST_FAIL,
  GET_CUSTOMERANDVEHICLE_DETAILS,
  GET_CUSTOMERANDVEHICLE_DETAILS_FAIL,
  GET_CUSTOMERANDVEHICLE_DETAILS_SUCCESS,
  CREATE_JOB,
  CREATE_JOB_SUCCESS,
  CREATE_JOB_FAIL,
  REGOEXIST,
  REGOEXIST_SUCCESS,
  REGOEXIST_FAIL,
  GET_UNASSIGNED_JOBS_COUNT,
  GET_UNASSIGNED_JOBS_COUNT_SUCCESS,
  GET_UNASSIGNED_JOBS_COUNT_FAIL,
} from "./actionTypes";

const initialState = {
  loadingLogins: false,
  message: "",
  error: "",
  jobData: null,
  jobCount: 0,
  customerData: null,
  customerAndVehicleData: null,
  createJob: null,
  phoneCode: [],
  regoExist: false,
};

const UnassignedJobsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_UNASSIGNED_JOBS:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_UNASSIGNED_JOBS_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        jobData: action.payload,
      };
      break;
    case GET_UNASSIGNED_JOBS_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        jobData: null,
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_CUSTOMERS_LIST:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_CUSTOMERS_LIST_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        customerData: action.payload,
      };
      break;
    case GET_CUSTOMERS_LIST_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        customerData: null,
        error: {
          message: action.payload,
        },
      };
      break;
    case GET_CUSTOMERANDVEHICLE_DETAILS:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_CUSTOMERANDVEHICLE_DETAILS_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        customerAndVehicleData: action.payload,
      };
      break;
    case GET_CUSTOMERANDVEHICLE_DETAILS_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        customerAndVehicleData: null,
        error: {
          message: action.payload,
        },
      };
      break;
    case CREATE_JOB:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case CREATE_JOB_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        createJob: action.payload,
      };
      break;
    case CREATE_JOB_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        createJob: null,
        error: {
          message: action.payload,
        },
      };
      break;

    case REGOEXIST:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case REGOEXIST_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        regoExist: action.payload,
      };
      break;
    case REGOEXIST_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        regoExist: false,
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_UNASSIGNED_JOBS_COUNT:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_UNASSIGNED_JOBS_COUNT_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        jobCount: action.payload,
      };
      break;
    case GET_UNASSIGNED_JOBS_COUNT_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        jobCount: 0,
        error: {
          message: action.payload,
        },
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default UnassignedJobsReducer;
