import { getWorkshopId } from "utils/getWorkshopId";
import {
  GET_SCHEDULED_MARKETING_LIST,
  GET_LIVE_MARKETING_LIST,
  GET_COMPLETED_MARKETING_LIST,
  DELETE_SCHEDULED_MARKETING_LIST,
  DELETE_LIVE_MARKETING_LIST,
  DELETE_COMPLETED_MARKETING_LIST
} from "./actionTypes";

// SCHEDULED MARKETING

export const getScheduledMarketingListPayload = () => {
  const listRequest = {
    get: "all",
    conditions: {
      workshop_id: 426,
      "compaign_type !=": "Referral",
      status: 1,
      is_deleted: "0",
      "date_start >": "2022-06-09 08:42:19",
    },
    order: {
      id: "desc",
    },
    fields: [
      "id",
      "workshop_id",
      "job_department_id",
      "compaign_type",
      "name",
      "date_start",
      "date_end",
      "offer_type",
      "status",
      "offer",
      "compaign_type",
    ],
  };

  return {
    type: GET_SCHEDULED_MARKETING_LIST,
    payload: { marketingListRequest: listRequest },
    // payload: listRequest,
  };
};

// get sheduled list
export const getScheduledMarketingListAPI =
  (state) => (dispatch) => (workshop_id) => {
    dispatch(getScheduledMarketingListPayload(workshop_id));
  };
// SCHEDULED MARKETING

//DELETE SCHEDULED MARKETING
export const deleteArchiveScheduledMarketingListAPI = (dispatch) => (payload) => {
  dispatch({
    type: DELETE_SCHEDULED_MARKETING_LIST,
    payload: payload,
  });
};

//DELETE SCHEDULED MARKETING



// LIVE MARKETING

export const getLiveMarketingListPayload = () => {
  const listRequest = {
    get: "all",
    conditions: {
      workshop_id: 426,
      "compaign_type !=": "Referral",
      status: "1",
      is_deleted: "0",
      // AND: {
      //   "date_start <=": "2022-06-09 08:46:32",
      //   "date_end >=": "2022-06-09 08:46:32",
      // },
    },
    contain: {
      CronJobData: {
        conditions: {
          OR: {
            msg_status: "send",
            email_status: "send",
            notification_status: "send",
            OR: {
              notification_status: "not_required",
              msg_status: "not_required",
              email_status: "not_required",
            },
          },
        },
        fields: ["id", "marketing_id", "job_id"],
      },
      "CronJobData.JobSchedules": {
        conditions: {
          "JobSchedules.workshop_id": 112,
        },
        fields: {
          0: "JobSchedules.id",
          2: "JobSchedules.discount_code",
          3: "JobSchedules.discount_code_used",
        },
      },
    },
    order: {
      id: "desc",
    },
    fields: [
      "id",
      "workshop_id",
      "compaign_type",
      "name",
      "code",
      "offer_type",
      "date_start",
      "date_end",
      "offer",
      "status",
      "job_department_id",
    ],
  };

  return {
    type: GET_LIVE_MARKETING_LIST,
    payload: { liveMarketingListRequest: listRequest },
    // payload: listRequest,
  };
};

// get LIVE list
export const getLiveMarketingListAPI =
  (state) => (dispatch) => (workshop_id) => {
    dispatch(getLiveMarketingListPayload(workshop_id));
  };

// LIVE MARKETING

  //DELETE LIVE MARKETING
export const deleteArchiveLiveMarketingListAPI = (dispatch) => (payload) => {
  dispatch({
    type: DELETE_LIVE_MARKETING_LIST,
    payload: payload,
  });
};
//DELETE LIVE MARKETING

// COMPLETED MARKETING

export const getCompletedMarketingListPayload = () => {
  const listRequest = {
    get: "all",
    conditions: {
      workshop_id: 426,
      "compaign_type !=": "Referral",
      // OR: {
      //   "date_end <": "2022-06-09 08:48:28",
      //   status: 0,
      //   is_deleted: 1,
      // },
    },
    contain: {
      CronJobData: {
        conditions: {
          OR: {
            msg_status: "send",
            email_status: "send",
            notification_status: "send",
            OR: {
              notification_status: "not_required",
              msg_status: "not_required",
              email_status: "not_required",
            },
          },
        },
        fields: ["id", "marketing_id"],
      },
      "CronJobData.JobSchedules": {
        conditions: {
          "JobSchedules.workshop_id": 112,
        },
        fields: {
          0: "JobSchedules.id",
          2: "JobSchedules.discount_code",
          3: "JobSchedules.discount_code_used",
        },
      },
    },
    order: {
      id: "desc",
    },
    fields: [
      "id",
      "workshop_id",
      "date_start",
      "date_end",
      "offer",
      "offer_type",
      "compaign_type",
      "name",
      "job_department_id",
      "status",
    ],
  };

  return {
    type: GET_COMPLETED_MARKETING_LIST,
    payload: { completedMarketingListRequest: listRequest },
    // payload: listRequest,completedMarketingListRequest
  };
};

// get LIVE list
export const getCompletedMarketingListAPI =
  (state) => (dispatch) => (workshop_id) => {
    dispatch(getCompletedMarketingListPayload(workshop_id));
  };
// COMPLETED MARKETING

  //DELETE LIVE MARKETING
  export const deleteArchiveCompletedMarketingListAPI = (dispatch) => (payload) => {
    dispatch({
      type: DELETE_COMPLETED_MARKETING_LIST,
      payload: payload,
    });
  };
  //DELETE LIVE MARKETING
