import {
    GET_REGISTER,
    GET_REGISTER_SUCCESS,
    GET_REGISTER_FAIL,
    SEND_OTP,
    SEND_OTP_SUCCESS,
    SEND_OTP_FAIL,
    CONFIRM_OTP,
    CONFIRM_OTP_SUCCESS,
    CONFIRM_OTP_FAIL,
    EXIST_EMAIL,
    EXIST_EMAIL_SUCCESS,
    EXIST_EMAIL_FAIL,
    GET_COUNTRY,
    GET_COUNTRY_SUCCESS,
    GET_COUNTRY_FAIL,
    GET_STATE,
    GET_STATE_SUCCESS,
    GET_STATE_FAIL,
    GET_INDUSTRY,
    GET_INDUSTRY_SUCCESS,
    GET_INDUSTRY_FAIL,
    GET_ACCOUNTING_PACKAGE,
    GET_ACCOUNTING_PACKAGE_SUCCESS,
    GET_ACCOUNTING_PACKAGE_FAIL,
    GET_SIZE_OF_WORKSHOP,
    GET_SIZE_OF_WORKSHOP_SUCCESS,
    GET_SIZE_OF_WORKSHOP_FAIL,
    GET_REFERRAL_ADVERTISE,
    GET_REFERRAL_ADVERTISE_SUCCESS,
    GET_REFERRAL_ADVERTISE_FAIL
  } from "./actionTypes";
  
  const initialState = {
    data: [],
    userData: null,
    loadingLogins: false,
    error: {
      message: "",
    },
    success:{
        message:"",
    },
    confirmData:null,
    countries:null,
    states:null,
    industries:null,
    accountingPackage:null,
    industrySizes:null,
    advertisements:null,
    emailData:null
  };
  
const RegisterReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_REGISTER:
            state = { 
                ...state, 
                loadingLogins: true,
                userData: action.userData,
            };
            break;
        case GET_REGISTER_SUCCESS:
            state = { 
                ...state, 
                loadingLogins: false,
                userData: action.payload,
                success: {
                    message: action.message,
                },
            };
            break;
        case GET_REGISTER_FAIL:
            state = {
            ...state,
                error: {
                    message: action.payload,
                },
                loadingLogins: false,
            };
            break;
        case SEND_OTP:
            state = {
                ...state, 
                loadingLogins: true,
                userData: action.userData,
            };
            break;
        case SEND_OTP_SUCCESS:
            state = {
                ...state,
                userData: action.payload,
                success: {
                    message: action.message,
                },
                loadingLogins: false,
            };
            break;
        case SEND_OTP_FAIL:
            state = {
                ...state,
                userData: null,
                error: {
                    message: action.payload,
                },
                loadingLogins: false,
            };
            break;
        case CONFIRM_OTP:
            state = {
                ...state, 
                loadingLogins: true,
                confirmData: action.userData,
            };
            break;
        case CONFIRM_OTP_SUCCESS:
            state = {
                ...state,
                confirmData: action.payload,
                success: {
                    message: action.message,
                },
                loadingLogins: false,
            };
            break;
        case CONFIRM_OTP_FAIL:
            state = {
                ...state,
                confirmData: null,
                error: {
                    message: action.payload,
                },
                loadingLogins: false,
            };
            break;
        case EXIST_EMAIL:
            state = { 
                ...state, 
                loadingLogins: true
            };
            break;
        case EXIST_EMAIL_SUCCESS:
            state = { 
                ...state, 
                loadingLogins: false,
                existEmail:false,
                emailData:action.message
            };
            break;
        case EXIST_EMAIL_FAIL:
            state = {
                ...state,
                existEmail:true,
                loadingLogins: false,
                emailData:action.payload
            };
            break;
        case GET_COUNTRY:
            state = { 
                ...state, 
                loadingLogins: true,
                countries: action.countries,
            };
            break;
        case GET_COUNTRY_SUCCESS:
            state = { 
                ...state, 
                loadingLogins: false,
                countries: action.payload,
                success: {
                    message: action.message,
                },
            };
            break;
        case GET_COUNTRY_FAIL:
            state = {
                ...state,
                error: {
                    message: action.payload,
                },
                loadingLogins: false,
            };
            break;
        case GET_STATE:
            state = { 
                ...state, 
                loadingLogins: true,
                states: action.states,
            };
            break;
        case GET_STATE_SUCCESS:
            state = { 
                ...state, 
                loadingLogins: false,
                states: action.payload,
                success: {
                    message: action.message,
                },
            };
            break;
        case GET_STATE_FAIL:
            state = {
                ...state,
                error: {
                    message: action.payload,
                },
                loadingLogins: false,
            };
            break;
        
        case GET_INDUSTRY:
            state = { 
                ...state, 
                loadingLogins: true,
                industries: action.industries,
            };
            break;
        case GET_INDUSTRY_SUCCESS:
            state = { 
                ...state, 
                loadingLogins: false,
                industries: action.payload,
                success: {
                    message: action.message,
                },
            };
            break;
        case GET_INDUSTRY_FAIL:
            state = {
                ...state,
                error: {
                    message: action.payload,
                },
                loadingLogins: false,
            };
            break;
        case GET_ACCOUNTING_PACKAGE:
            state = { 
                ...state, 
                loadingLogins: true,
                accountingPackage: action.accountingPackages,
            };
            break;
        case GET_ACCOUNTING_PACKAGE_SUCCESS:
            state = { 
                ...state, 
                loadingLogins: false,
                accountingPackage: action.payload,
                success: {
                    message: action.message,
                },
            };
            break;
        case GET_ACCOUNTING_PACKAGE_FAIL:
            state = {
                ...state,
                error: {
                    message: action.payload,
                },
                loadingLogins: false,
            };
            break;        
        case GET_SIZE_OF_WORKSHOP:
            state = { 
                ...state, 
                loadingLogins: true,
                industrySizes: action.industrySizess,
            };
            break;
        case GET_SIZE_OF_WORKSHOP_SUCCESS:
            state = { 
                ...state, 
                loadingLogins: false,
                industrySizes: action.payload,
                success: {
                    message: action.message,
                },
            };
            break;
        case GET_SIZE_OF_WORKSHOP_FAIL:
            state = {
                ...state,
                error: {
                    message: action.payload,
                },
                loadingLogins: false,
            };
            break;
        case GET_REFERRAL_ADVERTISE:
            state = { 
                ...state, 
                loadingLogins: true,
                advertisements: action.advertisementss,
            };
            break;
        case GET_REFERRAL_ADVERTISE_SUCCESS:
            state = { 
                ...state, 
                loadingLogins: false,
                advertisements: action.payload,
                success: {
                    message: action.message,
                },
            };
            break;
        case GET_REFERRAL_ADVERTISE_FAIL:
            state = {
                ...state,
                error: {
                    message: action.payload,
                },
                loadingLogins: false,
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
  };
  
  export default RegisterReducer;
  