import React, { useState } from 'react';
import logo from '../../../assets/images/5iQ_logo.png' 
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { GET_FORGOT_EMAIL } from "../../../store/forgotEmail/actionTypes";

let ForgotEmail = () => {

  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState();

  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() !== false) {
      dispatch({
        type: GET_FORGOT_EMAIL,
        payload: {
          email: email
        },
      });
    }
    setValidated(true);
  };

  

    return (
      <div>
        <div className="pre-login-header">
          <h1 className="mb-2">Welcome to 5iQ</h1>
          <p className="mb-2">Login to website here.</p>
          <p className="link-wrapper mb-2">New Registration <a href="/register" className="primary-link">CLICK HERE</a></p>
        </div>
        <div className="pre-login-wrapper"> 
          <div className="form-wrapper">
            <a href='/' className="logo">
              <img src={logo} alt="LOGO"></img>
            </a>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group className="form-group mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control type="email" onBlur={(e) => setEmail(e.target.value)} />
                <Link to='/login' className="text-right secondary-link mt-1">Back to login</Link>
              </Form.Group>
              <div className="btn-wrapper">
                <Button type="submit" variant="primary" className="primary-btn">Send sms</Button>
              </div>
            </Form>
          </div>
        </div>
        <div className="pre-login-footer">
          <p className="mb-2">By signing up for 5iQ account, you accept 5iQ</p>
          <p>
            <a href="/" className="primary-link mx-2">Terms of Use</a>
              <span>and</span>
            <a href="/" className="primary-link mx-2">Privacy Policy</a>
          </p>
        </div>
      </div>
    );
 }

export default ForgotEmail;