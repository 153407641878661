import React from "react";
import { Button, Modal, Table } from 'react-bootstrap';
import smsImage from '../../../src/assets/images/sms-vector.png';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const SmsBalance = (props) => {

  const storedData = JSON.parse(sessionStorage.getItem('stored_data'));

 
  if(typeof storedData.is_sms_subscribe === "undefined"){
    var is_sms_subscribe = 0;
  } else {
    var is_sms_subscribe = parseInt(window.atob(storedData.is_sms_subscribe));
  }
  
  if(is_sms_subscribe === 1) {

    const sms_limit = parseInt(window.atob(storedData.sms_limit));
    const current_sms_limit = parseInt(window.atob(storedData.current_sms_limit));
    const used_sms_limit = parseInt(sms_limit) - parseInt(current_sms_limit);

    const options = {
      title: {
        text: ''
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        pie: {
            size: "150",
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: false
            },
            showInLegend: true
        }
      },
      series: [{
        depth: 45,
        type: "pie",
        innerSize: '70%',
        name: '',
        colorByPoint: true,
        data: [
            {
                name: 'Total',
                y: sms_limit,
                color: 'black'
            }, {
                name: 'Used',
                y: used_sms_limit,
                color: 'red'
            }, {
                name: 'Balanced',
                y: current_sms_limit,
                color: 'green'
            }
        ],
      
      }]
      
    }
    
    return (
      <>
          <Modal className='sms-balance-dialog' centered size="md" show={props.showSmsBalanceModal} onHide={props.handleCloseSmsBalanceModal}>
            <Modal.Header >
              <Modal.Title className="d-flex justify-space-between">SMS Balance</Modal.Title>
              <Button type="button" className="close-btn" onClick={props.handleCloseSmsBalanceModal}><i className="la la-times"></i> </Button>
            </Modal.Header>
              <Modal.Body>
              <HighchartsReact
                containerProps={{ style: { height: "200px" } }}
                highcharts={Highcharts}
                options={options}
              />
            <Table striped className="chart-data text-center">
              <thead>
                <tr>
                  <th>Total</th>
                  <th>Used</th>
                  <th>Balanced</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><strong>{sms_limit}</strong></td>
                  <td style={{color: "red"}} ><strong>{used_sms_limit}</strong></td>
                  <td style={{color: "green"}} ><strong>{current_sms_limit}</strong></td>
                </tr>
              </tbody>
            </Table>
            <h6 className="text-center" > *Will auto Top Up at a balance of 35 sms remaning </h6>
             <div className="btn-wrapper d-flex justify-center">
              <Button type="button" className="btn rounded-btn btn-primary" >Top Up Now!</Button>
            </div>
              </Modal.Body>
          </Modal>
      </>
    )

  } else {

    return (
      <>

          <Modal  
          className="sms-balance-active"
            size="sm"
            centered
            show={props.showSmsBalanceModal} 
            onHide={props.handleCloseSmsBalanceModal}
          >
              <Modal.Body >
                <div className="img-block d-flex justify-center">
                  <img src={smsImage} alt="SMS Balance"></img>
                </div>
                <p className="text-center" >It looks like you haven't </p>
                <p className="text-center" >activated SMS messaging.</p>
                <div className="btn-wrapper">
                 <Button type="button" variant="outline-secondary" className="btn-outline-primary rounded-btn">Activate Now</Button>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex justify-center">
                <Button variant="outline-secondary" className="btn-outline rounded-btn" id="closeButton" onClick={props.handleCloseSmsBalanceModal}>
                  Cancel
                </Button>
                </div>
              </Modal.Footer>
          </Modal>
      </>
    )

  }

}
export default SmsBalance;