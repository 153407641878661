import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Default from "./default";
import CustomerPricingLevels from "./customerPricingLevels";
import { GET_WORKSHOP_OTHER_DETAIL } from "../../../../store/companyDetails/actionTypes";
import { GET_LABOUR_CHARGES_LIST } from "../../../../store/commonApiStore/actionTypes";
import LoaderImage from "../../../../../src/assets/images/page-loader.svg";
import LabourCharges from "./labourCharges";
import "./defaultsettings.scss";
import AssetType from "./assetType";

const DefaultSettings = () => {
  const dispatch = useDispatch(); // Dispatch variable

  const companyDetailData = useSelector((state) => state.CompanyDetailReducer); // GET Company Detail Reducer data
  const commonApiData = useSelector((state) => state.CommonApiStoreReducer);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch({
      type: GET_WORKSHOP_OTHER_DETAIL,
      payload: {
        workshop_id: JSON.parse(localStorage.getItem("data")).workshop_id,
      },
    });
    dispatch({
      type: GET_LABOUR_CHARGES_LIST,
      payload: {
        get: "all",
        conditions: {
          workshop_id: JSON.parse(localStorage.getItem("data")).workshop_id,
        },
      },
    });
  }, []);

  useEffect(() => {
    setIsLoading(companyDetailData.loading);
  }, [companyDetailData]);

  useEffect(() => {
    setIsLoading(commonApiData.loadingLogins);
  }, [commonApiData]);

  return (
    <>
      <div className="right-section-wrapper">
        <div className="table-header">
          <div className="top-block">
            <div className="table-title">
              <i className="fa fa-gears text-primary me-2 font-size-24"></i>
              <span>DEFAULTS</span>
            </div>
            <div className="video-link-outer">
              <Link to="/settings" className="btn btn-primary btn-rounded">
                Back to Dashboard
              </Link>
            </div>
          </div>
          <div className="table-header-tabs profile-tabs">
            <Tabs defaultActiveKey="default">
              <Tab eventKey="default" title="Default">
                <Default
                  workShopOtherDetails={companyDetailData.workShopOtherDetails}
                  labourData={commonApiData.labourChargesList}
                />
              </Tab>
              <Tab
                eventKey="customer-pricing-levels"
                title="Customer Pricing Levels (Parts)"
              >
                <CustomerPricingLevels />
              </Tab>
              <Tab eventKey="labour-charges" title="Labour Charges">
                <LabourCharges />
              </Tab>
              <Tab eventKey="asset-type" title="Asset Type">
                <AssetType />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>

      {isLoading === true && (
        <div className="page-loader d-flex align-center justify-center">
          <img src={LoaderImage} alt="Loading"></img>
        </div>
      )}
    </>
  );
};

export default DefaultSettings;
