import React from "react";
import AssetTypeForm from "./components/assetTypeForm";
import AssetTypeList from "./components/assetTypeList";

const AssetType = () => {
  return (
    <>
      <div className="card">
        <div className="card-header">
          <h6 className="mb-0 text-uppercase py-2">ASSET TYPE</h6>
        </div>
        <div className="card-body">
          <AssetTypeForm />
          <AssetTypeList />
        </div>
      </div>
    </>
  );
};

export default AssetType;
