import { takeLatest, put, call } from "redux-saga/effects";
import {
  GET_USER_PROFILE,
  UPDATE_USER_PROFILE,
  CHECK_EMAILEXIST,
  GET_SELECTED_CUSTOMERLIST,
} from "./actionTypes";
import {
  getUserProfileSuccess,
  getUserProfileFail,
  updateUserProfileSuccess,
  updateUserProfileFail,
  checkEmialExistSuccess,
  checkEmialExistFail,
  getSelectedCustomerListSuccess,
  getSelectedCustomerListFail,
} from "./actions";

import {
  getUserProfile,
  updateUserProfile,
  getCustomerLists,
} from "../../helpers/backendHelper";
import { showError, showSuccess } from "../../utils/alertMessage";

function* onGetUserProfile(data) {
  try {
    const response = yield call(getUserProfile, data);
    if (response) {
      if (response.code === 200) {
        yield put(getUserProfileSuccess(response));
      } else {
        yield put(getUserProfileFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(getUserProfileFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getUserProfileFail(error.response));
  }
}

function* onUpdateUserProfile(data) {
  try {
    const response = yield call(updateUserProfile, data);
    if (response) {
      if (response.code === 200) {
        yield put(updateUserProfileSuccess(response));
        showSuccess(response.message);
      } else {
        yield put(updateUserProfileFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(updateUserProfileFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(updateUserProfileFail(error.response));
  }
}

function* onEmailChange(data) {
  try {
    const response = yield call(getUserProfile, data);
    if (response) {
      if (response.code === 200 || response.code === 400) {
        if (response.data.Users.length == 0) {
          yield put(checkEmialExistSuccess(false));
        } else {
          yield put(checkEmialExistSuccess(true));
        }
      } else {
        yield put(checkEmialExistFail(response.message));
      }
    } else {
      yield put(checkEmialExistFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(checkEmialExistFail(error.response));
  }
}

function* onCustomerNameChange(data) {
  try {
    const response = yield call(getCustomerLists, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getSelectedCustomerListSuccess(response.data.CustomersDetails)
        );
      } else {
        yield put(getSelectedCustomerListFail(response.message));
      }
    } else {
      yield put(getSelectedCustomerListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getSelectedCustomerListFail(error.response));
  }
}

function* UserProfileSaga() {
  yield takeLatest(GET_USER_PROFILE, onGetUserProfile);
  yield takeLatest(UPDATE_USER_PROFILE, onUpdateUserProfile);
  yield takeLatest(CHECK_EMAILEXIST, onEmailChange);
  yield takeLatest(GET_SELECTED_CUSTOMERLIST, onCustomerNameChange);
}

export default UserProfileSaga;
