import {
  GET_GLOBAL_SEARCH,
  GET_GLOBAL_SEARCH_SUCCESS,
  GET_GLOBAL_SEARCH_FAIL
} from "./actionTypes";

const initialState = {
  data: [],
  searchData: null,
  error: {
    message: "",
  },
  isFetching: false,
};

const GlobalSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GLOBAL_SEARCH:
      state = { 
        ...state, 
        searchData: action.searchData,
        isFetching: false,
       };
      break;
    case GET_GLOBAL_SEARCH_SUCCESS:
      state = { 
        ...state, 
        searchData: action.payload,
        isFetching: true,
      };
      break;
    case GET_GLOBAL_SEARCH_FAIL:
      state = {
        ...state,
        searchData: null,
        error: {
          message: action.payload,
          isFetching: true,
        },
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default GlobalSearchReducer;
