import { ACTIVE_JOB_TAB, MANAGE_STATUS_CHANGE } from "./actionTypes";

export const setActiveTab = (data) => {
  return {
    type: ACTIVE_JOB_TAB,
    payload: data,
  };
};

export const manageStatusChange = (data) => {
  return {
    type: MANAGE_STATUS_CHANGE,
    payload: data,
  };
};
