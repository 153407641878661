import { takeLatest, put, call } from "redux-saga/effects";
import { SAVE_QUOTE_LABOUR, GET_QUOTE_LABOUR_LIST , DELETE_QUOTE_LABOUR, 
        UPDATE_QUOTE_LABOUR, INLINE_UPDATE_LABOUR,
        EDIT_QUOTE_LABOUR_MANY
      } from "./actionTypes";
import {
  saveQuoteLabourSuccess,
  saveQuoteLabourFail,
  getQuoteLabourListSuccess,
  getQuoteLabourListFail,
  deleteQuoteLabourSuccess,
  deleteQuoteLabourFail,
  updateQuoteLabourSuccess,
  updateQuoteLabourFail,
  inlineUpdateLabourSuccess,
  inlineUpdateLabourFail,
  editQuoteLabourManySuccess,
  editQuoteLabourManyFail
} from "./actions";

import { saveQuoteLabour, getQuoteLabourList , deleteQuoteLabourPart, updateQuoteLabour, editQuoteLabourMany } from "../../helpers/backendHelper";
import { showError } from "../../utils/alertMessage";

function* onGetSaveQuoteLabour(data) {
  try {
    const response = yield call(saveQuoteLabour, data);
    if(response){
      if(response.code===200){
        yield put(saveQuoteLabourSuccess(response.data.CustomerQuotesLabours));
        
      }else{
        yield put(saveQuoteLabourFail(response.message));
        showError(response.message);
      }
    }else{
      yield put(saveQuoteLabourFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(saveQuoteLabourFail(error.response));
  }
}


function* onGetQuoteLabourList(data) {
  try {
    const response = yield call(getQuoteLabourList, data);
    if(response){
      
      if(response.code===200){
        yield put(getQuoteLabourListSuccess(response.data.CustomerQuotesLabours));
      }else{
        yield put(getQuoteLabourListFail(response.message));
      }
    }else{
      yield put(getQuoteLabourListFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(getQuoteLabourListFail(error.response));
  }
}



function* onGetDeleteLabour(data) {
  try {
    const response = yield call(deleteQuoteLabourPart, data);
    if(response){
      
      if(response.code===200){
        yield put(deleteQuoteLabourSuccess(response.data.CustomerQuotesLabours));
      }else{
        yield put(deleteQuoteLabourFail(response.message));
      }
    }else{
      yield put(deleteQuoteLabourFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(deleteQuoteLabourFail(error.response));
  }
}

function* onGetUpdateLabour(data) {
  try {
    const response = yield call(updateQuoteLabour, data);
    if(response){
      if(response.code===200){
        yield put(updateQuoteLabourSuccess(response.data.CustomerQuotesLabours));
        
      }else{
        yield put(updateQuoteLabourFail(response.message));
        showError(response.message);
      }
    }else{
      yield put(updateQuoteLabourFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(updateQuoteLabourFail(error.response));
  }
}

function* onInlineUpdateLabour(data) {
  try {
    yield put(inlineUpdateLabourSuccess(data.payload));
  } catch (error) {
    yield put(inlineUpdateLabourFail(error.response));
  }
}


function* onEditQuoteLabourMany(data) {
  try {
    const response = yield call(editQuoteLabourMany, data);
    if(response){
      if(response.code===200){
        yield put(editQuoteLabourManySuccess(response.data.CustomerQuotesLabours));
        
      }else{
        yield put(editQuoteLabourManyFail(response.message));
        showError(response.message);
      }
    }else{
      yield put(editQuoteLabourManyFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(editQuoteLabourManyFail(error.response));
  }
}


function* QuoteLabourSaga() {
  yield takeLatest(SAVE_QUOTE_LABOUR, onGetSaveQuoteLabour);
  yield takeLatest(GET_QUOTE_LABOUR_LIST, onGetQuoteLabourList);
  yield takeLatest(DELETE_QUOTE_LABOUR, onGetDeleteLabour);
  yield takeLatest(UPDATE_QUOTE_LABOUR, onGetUpdateLabour);
  yield takeLatest(INLINE_UPDATE_LABOUR, onInlineUpdateLabour);
  yield takeLatest(EDIT_QUOTE_LABOUR_MANY, onEditQuoteLabourMany);
}

export default QuoteLabourSaga;
