import {
  SAVE_QUOTE_PART,
  SAVE_QUOTE_PART_SUCCESS,
  SAVE_QUOTE_PART_FAIL,
  GET_QUOTE_PART_LIST,
  GET_QUOTE_PART_LIST_SUCCESS,
  GET_QUOTE_PART_LIST_FAIL,
  DELETE_QUOTE_PART,
  DELETE_QUOTE_PART_SUCCESS,
  DELETE_QUOTE_PART_FAIL,
  UPDATE_QUOTE_PART,
  UPDATE_QUOTE_PART_SUCCESS,
  UPDATE_QUOTE_PART_FAIL,
  INLINE_UPDATE_QUOTE_PART,
  INLINE_UPDATE_QUOTE_PART_SUCCESS,
  INLINE_UPDATE_QUOTE_PART_FAIL,
  EDIT_QUOTE_PART_MANY,
  EDIT_QUOTE_PART_MANY_SUCCESS,
  EDIT_QUOTE_PART_MANY_FAIL,
  GET_QUOTE_KIT_PART,
  GET_QUOTE_KIT_PART_SUCCESS,
  GET_QUOTE_KIT_PART_FAIL,
  GET_QUOTE_KIT_LABOUR,
  GET_QUOTE_KIT_LABOUR_SUCCESS,
  GET_QUOTE_KIT_LABOUR_FAIL,
  SAVE_QUOTE_KIT_PART,
  SAVE_QUOTE_KIT_PART_SUCCESS,
  SAVE_QUOTE_KIT_PART_FAIL,
  DELETE_QUOTE_KIT_PART,
  DELETE_QUOTE_KIT_PART_SUCCESS,
  DELETE_QUOTE_KIT_PART_FAIL,
  INLINE_UPDATE_QUOTE_KIT_PART,
  INLINE_UPDATE_QUOTE_KIT_PART_SUCCESS,
  INLINE_UPDATE_QUOTE_KIT_PART_FAIL,
  EDIT_QUOTE_KIT_PART_MANY,
  EDIT_QUOTE_KIT_PART_MANY_SUCCESS,
  EDIT_QUOTE_KIT_PART_MANY_FAIL,
  EDIT_QUOTE_KIT_LABOUR_MANY,
  EDIT_QUOTE_KIT_LABOUR_MANY_SUCCESS,
  EDIT_QUOTE_KIT_LABOUR_MANY_FAIL,
  INLINE_UPDATE_QUOTE_KIT_LABOUR,
  INLINE_UPDATE_QUOTE_KIT_LABOUR_SUCCESS,
  INLINE_UPDATE_QUOTE_KIT_LABOUR_FAIL,
} from "./actionTypes";

const initialState = {
  data: [],
  partsData: null,
  error: {
    message: "",
  },
  quotePartsList: [],
  isDataLoad: 0,
  deletePart: null,
  isPartDeleted: false,
  updatePartData: null,
  inlinePartData: null,
  quoteKitParts: null,
  quoteKitLabours: null,
  saveKitParts: null,
  addNewKitPart : false,
  deleteKitParts: null,
  isDeleteKitParts: false,
  inlineKitPart: null,
  inlineKitLabour: null,
};

const QuotePartReducer = (state = initialState, action) => {

  switch (action.type) {
    case SAVE_QUOTE_PART:
      state = { 
        ...state, 
        partsData: action.partsData,
      };
    break;
    case SAVE_QUOTE_PART_SUCCESS:
      state = { 
        ...state, 
        partsData: action.payload,
        quotePartsList: [...state.quotePartsList,action.payload],  
      };
    break;
    case SAVE_QUOTE_PART_FAIL:
      state = {
        ...state,
        partsData: null,
        error: {
          message: action.payload,
        },
      };
    break;
    case GET_QUOTE_PART_LIST:
      state = { 
        ...state, 
        isDataLoad: 0
      };
    break;
    case GET_QUOTE_PART_LIST_SUCCESS:
      state = { 
        ...state, 
        quotePartsList: action.payload,
        isDataLoad: 1
      };
    break;
    case GET_QUOTE_PART_LIST_FAIL:
      state = {
        ...state,
        quotePartsList: [],
        error: {
          message: action.payload,
        },
        isDataLoad: 1
      };
    break;
    case DELETE_QUOTE_PART:
      state = { 
        ...state, 
        deletePart: action.partsData,
        isPartDeleted: false,
      };
    break;
    case DELETE_QUOTE_PART_SUCCESS:
      state = { 
        ...state, 
        deletePart: action.payload,
        quotePartsList:  [
          ...state.quotePartsList.filter(item => (item.id !== action.payload.id)), 
        ],
        isPartDeleted: true,
      };
    break;
    case DELETE_QUOTE_PART_FAIL:
      state = {
        ...state,
        deletePart: null,
        isPartDeleted: false,
        error: {
          message: action.payload,
        },
      };
    break;

    case UPDATE_QUOTE_PART:
      state = { 
        ...state, 
        updatePartData: action.partsData,
      };
    break;
    case UPDATE_QUOTE_PART_SUCCESS: 
    {
      const parts = [...state.quotePartsList];
      const indexPart = parts.findIndex( c => c.id === action.payload.id);  
      if(indexPart !== -1){
          parts[indexPart] = {
              ...parts[indexPart],
              ...action.payload
          }
          // parts[indexPart] = {
          //   retail_price:action.payload.retail
          // }
      }
    
      state = { 
        ...state, 
        updatePartData: action.payload,
        quotePartsList : parts
      };

    }
    break;
    case UPDATE_QUOTE_PART_FAIL:
      state = {
        ...state,
        updatePartData: null,
        error: {
          message: action.payload,
        },
      };
    break;

    case INLINE_UPDATE_QUOTE_PART:
      state = { 
        ...state, 
        inlinePartData: action.partsData,
      };
    break;
    case INLINE_UPDATE_QUOTE_PART_SUCCESS: 
    {
      const parts = [...state.quotePartsList];
      const indexPart = parts.findIndex( c => c.id === action.payload.id);  
      if(indexPart !== -1){
          parts[indexPart] = {
              ...parts[indexPart],
              ...action.payload
          }
      }
      state = { 
        ...state, 
        inlinePartData: action.payload,
        quotePartsList : parts
      };

    }
    break;
    case INLINE_UPDATE_QUOTE_PART_FAIL:
      state = {
        ...state,
        inlinePartData: null,
        error: {
          message: action.payload,
        },
      };
    break;

    case EDIT_QUOTE_PART_MANY:
      state = { 
        ...state, 
      };
    break;
    case EDIT_QUOTE_PART_MANY_SUCCESS:
      state = { 
        ...state, 
      };
    break;
    case EDIT_QUOTE_PART_MANY_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
      };
    break;

    case GET_QUOTE_KIT_PART:
      state = { 
        ...state, 
        isDataLoad: 0
      };
    break;
    case GET_QUOTE_KIT_PART_SUCCESS:
      state = { 
        ...state, 
        quoteKitParts: action.payload,
        isDataLoad: 1
      };
    break;
    case GET_QUOTE_KIT_PART_FAIL:
      state = {
        ...state,
        quoteKitParts: [],
        error: {
          message: action.payload,
        },
        isDataLoad: 1
      };
    break;

    case GET_QUOTE_KIT_LABOUR:
      state = { 
        ...state, 
        isDataLoad: 0
      };
    break;
    case GET_QUOTE_KIT_LABOUR_SUCCESS:
      state = { 
        ...state, 
        quoteKitLabours: action.payload,
        isDataLoad: 1
      };
    break;
    case GET_QUOTE_KIT_LABOUR_FAIL:
      state = {
        ...state,
        quoteKitLabours: [],
        error: {
          message: action.payload,
        },
        isDataLoad: 1
      };
    break;

    case SAVE_QUOTE_KIT_PART:
      state = { 
        ...state, 
        isDataLoad: 0,
        addNewKitPart: false,
      };
    break;
    case SAVE_QUOTE_KIT_PART_SUCCESS:
      state = { 
        ...state, 
        saveKitParts: action.payload,
        quoteKitParts: [...state.quoteKitParts,action.payload],  
        isDataLoad: 1,
        addNewKitPart: true,
      };
    break;
    case SAVE_QUOTE_KIT_PART_FAIL:
      state = {
        ...state,
        saveKitParts: [],
        error: {
          message: action.payload,
        },
        isDataLoad: 1,
        addNewKitPart: false
      };
    break;

    case DELETE_QUOTE_KIT_PART:
      state = { 
        ...state, 
        isDataLoad: 0,
        isDeleteKitParts: false,
      };
    break;
    case DELETE_QUOTE_KIT_PART_SUCCESS:
      state = { 
        ...state, 
        deleteKitParts: action.payload,
        quoteKitParts:  [
          ...state.quoteKitParts.filter(item => (item.id !== action.payload.id)), 
        ],
        isDeleteKitParts: true,
        isDataLoad: 1
      };
    break;
    case DELETE_QUOTE_KIT_PART_FAIL:
      state = {
        ...state,
        deleteKitParts: [],
        error: {
          message: action.payload,
        },
        isDataLoad: 1,
        isDeleteKitParts: false,
      };
    break;

    case INLINE_UPDATE_QUOTE_KIT_PART:
      state = { 
        ...state, 
        inlineKitPart: action.partsData,
      };
    break;
    case INLINE_UPDATE_QUOTE_KIT_PART_SUCCESS: 
    {
      const parts = [...state.quoteKitParts];
      const indexPart = parts.findIndex( c => c.id === action.payload.id);  
      if(indexPart !== -1){
          parts[indexPart] = {
              ...parts[indexPart],
              ...action.payload
          }
      }
      state = { 
        ...state, 
        inlineKitPart: action.payload,
        quoteKitParts : parts
      };

    }
    break;
    case INLINE_UPDATE_QUOTE_KIT_PART_FAIL:
      state = {
        ...state,
        inlineKitPart: null,
        error: {
          message: action.payload,
        },
      };
    break;

    case EDIT_QUOTE_KIT_PART_MANY:
      state = { 
        ...state, 
      };
    break;
    case EDIT_QUOTE_KIT_PART_MANY_SUCCESS:
      state = { 
        ...state, 
      };
    break;
    case EDIT_QUOTE_KIT_PART_MANY_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
      };
    break;

    case EDIT_QUOTE_KIT_LABOUR_MANY:
      state = { 
        ...state, 
      };
    break;
    case EDIT_QUOTE_KIT_LABOUR_MANY_SUCCESS:
      state = { 
        ...state, 
      };
    break;
    case EDIT_QUOTE_KIT_LABOUR_MANY_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
      };
    break;

    case INLINE_UPDATE_QUOTE_KIT_LABOUR:
      state = { 
        ...state, 
        inlineKitLabour: action.partsData,
      };
    break;
    case INLINE_UPDATE_QUOTE_KIT_LABOUR_SUCCESS: 
    {
      const parts = [...state.quoteKitLabours];
      const indexPart = parts.findIndex( c => c.id === action.payload.id);  
      if(indexPart !== -1){
          parts[indexPart] = {
              ...parts[indexPart],
              ...action.payload
          }
      }
      state = { 
        ...state, 
        inlineKitLabour: action.payload,
        quoteKitLabours : parts
      };

    }
    break;
    case INLINE_UPDATE_QUOTE_KIT_LABOUR_FAIL:
      state = {
        ...state,
        inlineKitLabour: null,
        error: {
          message: action.payload,
        },
      };
    break;

    default:
      state = { ...state };
    break;
  }
  return state;
};

export default QuotePartReducer;
