import React from "react";
import { Button, Modal, Table } from 'react-bootstrap';
import regoImage from '../../../src/assets/images/rego-lookup.png';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const LookupBalance = (props) => {

  const storedData = JSON.parse(sessionStorage.getItem('stored_data'));

  if(typeof storedData.is_lookup_subscribe === "undefined"){
    var is_lookup_subscribe = 0;
  } else {
    var is_lookup_subscribe = parseInt(window.atob(storedData.is_lookup_subscribe));
  }


  if(is_lookup_subscribe === 1) {

    const vin_request = parseInt(window.atob(storedData.vin_request));
    const current_vin_request = parseInt(window.atob(storedData.current_vin_request));

    // const vin_request = props.Subscription ? props.Subscription.vin_request ? props.Subscription.vin_request : 0 : 0;
    // const current_vin_request = props.Subscription ? props.Subscription.current_vin_request ? props.Subscription.current_vin_request : 0 : 0;
    const used_vin_request = parseInt(vin_request) - parseInt(current_vin_request);

    const options = {
      title: {
        text: ''
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        pie: {
            size: "150",
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: false
            },
            showInLegend: true
        }
      },
      series: [{
        depth: 45,
        type: "pie",
        innerSize: '70%',
        name: '',
        colorByPoint: true,
        data: [
            {
                name: 'Total',
                y: vin_request,
                color: 'black'
            }, {
                name: 'Used',
                y: used_vin_request,
                color: 'red'
            }, {
                name: 'Balanced',
                y: current_vin_request,
                color: 'green'
            }
        ],

      }]

    }

    return (
      <>
          <Modal size="md" centered className="rogo-lookup" show={props.showLookupBalanceModal} onHide={props.handleCloseLookupBalanceModal}>
            <Modal.Header >
              <Modal.Title className="d-flex justify-space-between">Rego Lookup</Modal.Title>
              <Button type="button" className="close-btn" onClick={props.handleCloseLookupBalanceModal}><i className="la la-times"></i> </Button>
            </Modal.Header>

              <Modal.Body>

                <HighchartsReact
                  containerProps={{ style: { height: "200px" } }}
                  highcharts={Highcharts}
                  options={options}
                />

                <Table striped className="chart-data text-center">
                  <thead>
                    <tr>
                      <th>Total</th>
                      <th>Used</th>
                      <th>Balanced</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><strong>{vin_request}</strong></td>
                      <td style={{color: "red"}} ><strong>{used_vin_request}</strong></td>
                      <td style={{color: "green"}} ><strong>{current_vin_request}</strong></td>
                    </tr>
                  </tbody>
                </Table>

                <h6 className="text-center" > *Will auto Top Up at a balance of 35 sms remaning </h6>
                <div className="btn-wrapper d-flex justify-center">
                <Button type="button" className="btn rounded-btn btn-primary" >Top Up Now !</Button>
                </div>
              </Modal.Body>

          </Modal>
      </>
    )

  } else {

    return (
      <>
          <Modal
          className="rego-lookup-active"
            size="sm"
            centered
            show={props.showLookupBalanceModal}
            onHide={props.handleCloseLookupBalanceModal}
          >
              <Modal.Body  >
                <div className="img-block d-flex justify-center">
                  <img src={regoImage} alt="Rego Balance" ></img>
                </div>
                <p className="text-center" >It looks like you haven't </p>
                <p className="text-center" >activated Rego Lookup.</p>
                <div className="btn-wrapper">
                  <Button type="button" variant="outline-secondary" className="btn-outline-primary rounded-btn">Activate Now</Button>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex justify-center">
                  <Button variant="outline-secondary" className="btn-outline rounded-btn"  id="closeButton" onClick={props.handleCloseLookupBalanceModal}>
                    Cancel
                  </Button>
                </div>
              </Modal.Footer>
          </Modal>
      </>
    )

  }

}
export default LookupBalance;
