import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import MenuIcon from "../../../../assets/images/ic_menu.png";
import AppLogo from "../../../../assets/images/5iq-logo.png";
import {ReactComponent as ServiceIcon} from "../../../../assets/images/service-icon.svg";
import {ReactComponent as TyresIcon} from "../../../../assets/images/tyres-icon.svg";
import {ReactComponent as RepairsIcon} from "../../../../assets/images/repairs-icon.svg";
import {ReactComponent as InspectionIcon} from "../../../../assets/images/inspection-icon.svg";
import "./mobilepreview.scss";

const MobilePreview = ({ primaryColor, secondaryColor, fontColor, iconColor }) => {
  return (
    <>
      <div className="mobile-preview-box">
        <div className="device-container">
          <div className="device-wrap">
            <div className="device">
              <div className="screens">
                <Carousel interval={null}>
                  <Carousel.Item>
                    <Carousel.Caption style={{background: primaryColor}}>
                      <div className="app-header" style={{color: secondaryColor}}>
                        <div className="menu-btn">
                          <img src={MenuIcon} alt="" width="22" />
                        </div>
                        <div className="app-logo">
                          <img src={AppLogo} alt="" />
                        </div>
                      </div>
                      <div className="app-body flex-grow-1">
                        <h6 className="app-body-title cs-font" style={{color: fontColor}}>Book your service</h6>
                        <div className="app-body-menu">
                          <div className="menu-item">
                            <span className="alr-not">2</span>
                            <div className="icon" style={{color: iconColor}}>
                              <ServiceIcon />
                            </div>
                            <p className="menu-name cs-font" style={{color: fontColor}}>SERVICE</p>
                            <div className="box-border h-80 position-absolute top-50 start-100 translate-middle border-end" style={{color: secondaryColor}}></div>
                            <div className="box-border w-80 position-absolute bottom-0 start-50 translate-middle border-bottom" style={{color: secondaryColor}}></div>
                          </div>
                          <div className="menu-item">
                            <div className="icon" style={{color: iconColor}}>
                              <RepairsIcon />
                            </div>
                            <p className="menu-name cs-font" style={{color: fontColor}}>REPAIRS</p>
                            <div className="box-border w-80 position-absolute bottom-0 start-50 translate-middle border-bottom" style={{color: secondaryColor}}></div>
                          </div>
                          <div className="menu-item">
                            <div className="icon" style={{color: iconColor}}>
                              <TyresIcon />
                            </div>
                            <p className="menu-name cs-font" style={{color: fontColor}}>TYRES</p>
                            <div className="box-border h-80 position-absolute top-50 start-100 translate-middle border-end" style={{color: secondaryColor}}></div>
                          </div>
                          <div className="menu-item">
                            <div className="icon" style={{color: iconColor}}>
                              <InspectionIcon />
                            </div>
                            <p className="menu-name cs-font" style={{color: fontColor}}>INSPECTION</p>
                          </div>
                        </div>
                        <div className="app-footer">
                            <h6 className="sp-label cs-font" style={{color: fontColor}}>Serviced by</h6>
                            <h5 className="sp-name cs-font" style={{color: fontColor}}>Workshop Name</h5>
                        </div>
                      </div>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Carousel.Caption>
                      <div className="wrap-2">
                        <div className="head p-3" style={{background: primaryColor}}>
                            <p className="m-0">Booking</p>
                        </div>
                        <div className="icon-group pt-5 pb-3">
                          <div className="icon pb-2" style={{color: iconColor}}>
                            <ServiceIcon />
                          </div>
                          <p className="cs-font sp-name" style={{color: fontColor}}>Service</p>
                        </div>
                        <div className="app-service-form-list">
                          <ul>
                            <li>
                              <div className="left" style={{background: secondaryColor}}>Rego</div>
                              <div className="right">Select Range <i className="arrow-btm"></i></div>
                            </li>
                            <li>
                              <div className="left" style={{background: secondaryColor}}>Type</div>
                              <div className="right">Select Type <i className="arrow-btm"></i></div>
                            </li>
                            <li>
                              <div className="left" style={{background: secondaryColor}}>Special Marketing</div>
                              <div className="right">Select Special Marketing <i className="arrow-btm"></i></div>
                            </li>
                            <li>
                              <div className="left" style={{background: secondaryColor}}>Pick up Time</div>
                              <div className="right">03/03/2020 4:00 am</div>
                            </li>
                            <li className="text-box">
                              <div className="right">
                                <h2>Note</h2>
                                <p>Write Description here</p>
                              </div>
                            </li>
                          </ul>
                          <div className="button-box p-t-10">
                              <button type="button" name="button">Cancel</button>
                              <button type="button" name="button" className="sc-bg" style={{background: secondaryColor}}>Book</button>
                          </div>
                        </div>
                      </div>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Carousel.Caption>
                      <div className="app-header" style={{color: secondaryColor, background: primaryColor}}>
                        <div className="menu-btn">
                          <img src={MenuIcon} alt="" width="22" />
                        </div>
                        <div className="app-logo">
                          <img src={AppLogo} alt="" />
                        </div>
                      </div>
                      <div className="app-body">
                        <div className="about-container">
                          <div className="ab-info-box" style={{borderColor: secondaryColor}}>
                            <div className="ab-info-title">
                                <i className="fa fa-user info-icon cs-icon" style={{color: iconColor}}></i>
                            </div>
                            <div className="info value">
                                <span className="info-label gray-clr">Workshop Name</span>
                                <p className="cs-font" style={{color: fontColor}}>Tyre Repairing</p>
                            </div>
                          </div>
                          <div className="ab-info-box" style={{borderColor: secondaryColor}}>
                            <div className="ab-info-title">
                                <i className="fa fa-building-o info-icon cs-icon" style={{color: iconColor}}></i>
                            </div>
                            <div className="info value">
                                <span className="info-label gray-clr">Workshop Contact Name</span>
                                <p className="cs-font" style={{color: fontColor}}>Olive Mathew</p>
                            </div>
                          </div>
                          <div className="ab-info-box" style={{borderColor: secondaryColor}}>
                            <div className="ab-info-title">
                                <i className="fa fa-map-marker info-icon cs-icon" style={{color: iconColor}}></i>
                            </div>
                            <div className="info value">
                                <span className="info-label gray-clr">Workshop Address</span>
                                <p className="cs-font" style={{color: fontColor}}>92 Albacore Crescent</p>
                                <p className="cs-font" style={{color: fontColor}}>Lakesland</p>
                                <p className="cs-font" style={{color: fontColor}}>Now South Wales, 25722</p>
                            </div>
                          </div>
                          <div className="ab-info-box" style={{borderColor: secondaryColor}}>
                            <div className="ab-info-title">
                                <i className="fa fa-phone info-icon cs-icon" style={{color: iconColor}}></i>
                            </div>
                            <div className="info value">
                                <span className="info-label gray-clr">Workshop Contact</span>
                                <p className="cs-font" style={{color: fontColor}}>+67 98989 98889</p>
                                <p className="cs-font" style={{color: fontColor}}>+67 98989 98889</p>
                            </div>
                          </div>
                          <div className="ab-info-box" style={{borderColor: secondaryColor}}>
                            <div className="ab-info-title">
                                <i className="fa fa-link info-icon cs-icon" style={{color: iconColor}}></i>
                            </div>
                            <div className="info value">
                                <span className="info-label gray-clr">Workshop URL</span>
                                <p className="cs-font" style={{color: fontColor}}>workshop1.kwiq.com.au</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MobilePreview;