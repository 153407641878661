import React from "react";
import CompanyCommunicationEmail from "./forms/companyCommunicationEmail";
import CompanyCommunicationSMS from "./forms/companyCommunicationSMS";
import FacebookReview from "./forms/facebookReview";
import GoogleReview from "./forms/googleReview";

const CompanyCommunication = ({ userData, companyDetailData }) => {
  return (
    <>
      <CompanyCommunicationEmail workShopDetails={companyDetailData.workShopDetails} />
      <CompanyCommunicationSMS workShopDetails={companyDetailData.workShopDetails} workShopSMS={companyDetailData.workShopSMS} />
      <div className="card mb-3">
        <div className="card-header d-flex flex-wrap align-items-center">
          <div className="text-uppercase me-2">
            <h5 className="font-size-18">SOCIAL MEDIA REVIEWS</h5>
          </div>
        </div>
        <div className="card-body">
          <GoogleReview workShopOtherDetails={companyDetailData.workShopOtherDetails} />
          <FacebookReview workShopOtherDetails={companyDetailData.workShopOtherDetails} />
        </div>
      </div>
    </>
  );
}

export default CompanyCommunication;