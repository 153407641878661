import {
  deleteArchiveInvoices,
  deleteArchiveInvoicesSales,
  deleteInvoiceCredit,
  deleteInvoicePayment,
  getInvoicesCredits,
  getInvoicesList,
  getInvoicesPayments,
  getInvoicesSalesList,
} from 'helpers/backendHelper';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  deleteArchiveCompletedInvoiceFail,
  deleteArchiveCompletedInvoiceSuccess,
  deleteArchiveCreditInvoiceFail,
  deleteArchiveCreditInvoiceSuccess,
  deleteArchivePaymentInvoiceFail,
  deleteArchivePaymentInvoiceSuccess,
  deleteArchiveRaisedInvoiceFail,
  deleteArchiveRaisedInvoiceSuccess,
  getCompletedInvoicesFail,
  getCompletedInvoicesSuccess,
  getInvoiceCreditsFail,
  getInvoiceCreditsSuccess,
  getInvoicePaymentsFail,
  getInvoicePaymentsSuccess,
  getRaisedInvoiceFail,
  getRaisedInvoicesSuccess,
} from './actions';
import {
  DELETE_COMPLETED_INVOICES,
  DELETE_CREDIT_INVOICES,
  DELETE_PAYMENT_INVOICES,
  DELETE_RAISED_INVOICES,
  GET_COMPLETED_INVOICES,
  GET_INVOICE_CREDITS,
  GET_INVOICE_PAYMENTS,
  GET_RAISED_INVOICES,
} from './actionTypes';
import {
  getCompletedInvoicePayload,
  getInvoiceCreditAction,
  getInvoicePaymentAction,
  getRaisedInvoicePayload,
} from './api';
import { formatInvoiceList, formatSaleInvoiceList } from './formatter';

function* onGetRaisedInvoices(action) {
  const { invoiceListRequest, saleInvoiceRequest } = action.payload;
  const payload = {};
  console.log('invoiceListRequest ', invoiceListRequest);

  try {
    const listResponse = yield call(getInvoicesList, {
      payload: invoiceListRequest,
    });
    console.error(listResponse);

    const saleListResponse = yield call(getInvoicesSalesList, {
      payload: saleInvoiceRequest,
    });

    if (listResponse?.code === 200 || saleListResponse?.code === 200) {
      payload.list = formatInvoiceList(
        listResponse?.data?.Invoices || [],
        'Unpaid'
      ).concat(
        formatSaleInvoiceList(
          saleListResponse?.data?.CashSaleInvoice || [],
          'Unpaid'
        )
      );
      payload.count =
        listResponse?.data?.Invoices.length +
        saleListResponse?.data?.CashSaleInvoice.length;
      // console.log("Count",payload.count);
      payload.list.sort((a, b) => b.invoice_number - a.invoice_number);
    } else {
      console.log('error here 1');
      console.log(listResponse);
      yield put(getRaisedInvoiceFail('Failed to fetch invoice'));
    }

    yield put(getRaisedInvoicesSuccess(payload));
  } catch (err) {
    console.log('error here 2', err);
    yield put(getRaisedInvoiceFail('Failed to fetch invoice'));
  }
}

function* onGetCompletedInvoices(action) {
  const { invoiceListRequest, saleInvoiceRequest } = action.payload;
  const payload = {};

  try {
    const listResponse = yield call(getInvoicesList, {
      payload: invoiceListRequest,
    });
    const saleListResponse = yield call(getInvoicesSalesList, {
      payload: saleInvoiceRequest,
    });

    if (listResponse?.code === 200 || saleListResponse?.code === 200) {
      // payload.list = listResponse?.data?.Invoices || [];
      payload.list = formatInvoiceList(
        listResponse?.data?.Invoices || [],
        'Paid'
      ).concat(
        formatSaleInvoiceList(
          saleListResponse?.data?.CashSaleInvoice || [],
          'Paid'
        )
      );
      payload.count =
        listResponse?.data?.Invoices.length +
        saleListResponse?.data?.CashSaleInvoice.length;
      // console.log("Count",payload.count);
      payload.list.sort((a, b) => b.invoice_number - a.invoice_number);
    } else {
      console.log('error here 1');
      yield put(getCompletedInvoicesFail('Failed to fetch invoice'));
    }

    yield put(getCompletedInvoicesSuccess(payload));
  } catch (err) {
    console.log('error here 2', err);
    yield put(getCompletedInvoicesFail('Failed to fetch invoice'));
  }
}

function* onGetInvoicePayments(action) {
  const { listRequest } = action.payload;
  const payload = {};
  try {
    const listResponse = yield call(getInvoicesPayments, {
      payload: listRequest,
    });

    if (listResponse?.code == 200) {
      payload.list =
        listResponse?.data?.InvoicesPaymentsCredits.map((z) => ({
          ...z,
          customer:
            z?.invoice?.job_schedule?.vehicle?.user?.customers_details[0]
              ?.type == 1
              ? z.invoice?.job_schedule?.vehicle?.user?.customers_details[0]
                  ?.company_name
              : z.invoice?.job_schedule?.vehicle?.user?.customers_details[0]
                  ?.firstname +
                ' ' +
                z.invoice?.job_schedule?.vehicle?.user?.customers_details[0]
                  ?.lastname,
        })) || [];

      payload.count = listResponse?.data?.InvoicesPaymentsCredits.length;
    } else {
      console.log('error here 1');
      yield put(getInvoicePaymentsFail('Failed to fetch invoice'));
    }
    yield put(getInvoicePaymentsSuccess(payload));
  } catch (err) {
    console.log('error here 2', err);
    yield put(getInvoicePaymentsFail('Failed to fetch invoice'));
  }
}

function* onGetInvoiceCredits(action) {
  const { listRequest } = action.payload;
  const payload = {};
  try {
    const listResponse = yield call(getInvoicesCredits, {
      payload: listRequest,
    });

    if (listResponse?.code == 200) {
      payload.list =
        listResponse?.data?.InvoicesPaymentsCreditsOtherDetails.map((z) => ({
          ...z,
          customer:
            z?.invoices_payments_credit.invoice.job_schedule.vehicle.user
              ?.customers_details[0]?.type == 1
              ? z.invoices_payments_credit.invoice.job_schedule.vehicle.user
                  ?.customers_details[0]?.company_name
              : z?.invoices_payments_credit.invoice.job_schedule.vehicle.user
                  ?.customers_details[0]?.firstname +
                ' ' +
                z?.invoices_payments_credit.invoice.job_schedule.vehicle.user
                  ?.customers_details[0]?.lastname,
        })).sort(
          (a, b) =>
            b.invoices_payments_credit.invoice.invoice_number -
            a.invoices_payments_credit.invoice.invoice_number
        ) || [];

      payload.count =
        listResponse?.data?.InvoicesPaymentsCreditsOtherDetails.length;
    } else {
      console.log('error here 1');
      yield put(getInvoiceCreditsFail('Failed to fetch invoice'));
    }
    console.log();
    yield put(getInvoiceCreditsSuccess(payload));
  } catch (err) {
    console.log('error here 2', err);
    yield put(getInvoiceCreditsFail('Failed to fetch invoice'));
  }
}

function* onDeleteArchiveRaisedInvoice(action) {
  try {
    const { saleInvoice, invoice } = action.payload;
    let saleInvoiceDelete;
    let invoiceDelete;

    if (invoice.ids.length !== 0) {
      invoiceDelete = yield call(deleteArchiveInvoices, { payload: invoice });
    }
    if (saleInvoice && saleInvoice.ids.length !== 0) {
      saleInvoiceDelete = yield call(deleteArchiveInvoicesSales, {
        payload: saleInvoice,
      });
    }

    if (invoiceDelete?.code === 200 || saleInvoiceDelete?.code === 200) {
      yield put(deleteArchiveRaisedInvoiceSuccess(invoice.action_type));
      const storedData = JSON.parse(sessionStorage.getItem('stored_data'));
      let workshop_id = window.atob(storedData.workshop_id);
      yield put(getRaisedInvoicePayload(workshop_id));
    } else {
      yield put(deleteArchiveRaisedInvoiceFail());
    }
  } catch (error) {
    yield put(deleteArchiveRaisedInvoiceFail());
  }
}

function* onDeleteArchiveCompletedInvoice(action) {
  try {
    const { saleInvoice, invoice } = action.payload;
    let saleInvoiceDelete;
    let invoiceDelete;

    if (invoice.ids.length !== 0) {
      invoiceDelete = yield call(deleteArchiveInvoices, { payload: invoice });
    }
    if (saleInvoice.ids.length !== 0) {
      saleInvoiceDelete = yield call(deleteArchiveInvoicesSales, {
        payload: saleInvoice,
      });
    }

    if (invoiceDelete?.code === 200 || saleInvoiceDelete?.code === 200) {
      yield put(deleteArchiveCompletedInvoiceSuccess(invoice.action_type));
      const storedData = JSON.parse(sessionStorage.getItem('stored_data'));
      let workshop_id = window.atob(storedData.workshop_id);
      yield put(getCompletedInvoicePayload(workshop_id));
    } else {
      yield put(deleteArchiveCompletedInvoiceFail());
    }
  } catch (error) {
    yield put(deleteArchiveCompletedInvoiceFail());
  }
}

function* onDeleteInvoicePayment(action) {
  try {
    const data = yield call(deleteInvoicePayment, { payload: action.payload });
    if (data?.code === 200) {
      yield put(deleteArchivePaymentInvoiceSuccess(action.payload.action_type));
      yield put(getInvoicePaymentAction());
    } else {
      yield put(deleteArchivePaymentInvoiceFail());
    }
  } catch (error) {
    yield put(deleteArchivePaymentInvoiceFail());
  }
}

function* onDeleteInvoiceCredit(action) {
  try {
    const data = yield call(deleteInvoiceCredit, { payload: action.payload });
    if (data?.code === 200) {
      yield put(deleteArchiveCreditInvoiceSuccess(action.payload.action_type));
      yield put(getInvoiceCreditAction());
    } else {
      yield put(deleteArchiveCreditInvoiceFail());
    }
  } catch (error) {
    yield put(deleteArchiveCreditInvoiceFail());
  }
}

function* invoiceSagas() {
  yield takeLatest(GET_RAISED_INVOICES, onGetRaisedInvoices);
  // yield takeLatest(GET_RAISED_INVOICES_COUNT, onGetRaisedInvoicesCounts)
  yield takeLatest(GET_COMPLETED_INVOICES, onGetCompletedInvoices);
  // yield takeLatest(GET_COMPLETED_INVOICES_COUNT, onGetCompletedInvoicesCounts)
  yield takeLatest(GET_INVOICE_PAYMENTS, onGetInvoicePayments);
  yield takeLatest(GET_INVOICE_CREDITS, onGetInvoiceCredits);
  yield takeLatest(DELETE_RAISED_INVOICES, onDeleteArchiveRaisedInvoice);
  yield takeLatest(DELETE_COMPLETED_INVOICES, onDeleteArchiveCompletedInvoice);
  yield takeLatest(DELETE_PAYMENT_INVOICES, onDeleteInvoicePayment);
  yield takeLatest(DELETE_CREDIT_INVOICES, onDeleteInvoiceCredit);
}

export default invoiceSagas;
