import * as ACTION from "./actionTypes";

export const getRaisedInvoices = (data) => {
  return {
    type: ACTION.GET_RAISED_INVOICES,
  };
};
export const getRaisedInvoicesCount = () => {
  return {
    type: ACTION.GET_RAISED_INVOICES_COUNT,
  };
};
export const getRaisedInvoicesCountSuccess = (data) => {
  return {
    type: ACTION.GET_RAISED_INVOICES_COUNT_SUCCESS,
    payload: data,
  };
};
export const getRaisedInvoicesCountFail = (error) => {
  return {
    type: ACTION.GET_RAISED_INVOICES_COUNT_FAIL,
    payload: error,
  };
};

export const getRaisedInvoicesSuccess = (data) => {
  return {
    type: ACTION.GET_RAISED_INVOICES_SUCCESS,
    payload: data,
  };
};

export const getRaisedInvoiceFail = (error) => {
  return {
    type: ACTION.GET_RAISED_INVOICES_FAIL,
    payload: error,
  };
};

export const getCompletedInvoices = () => {
  return {
    type: ACTION.GET_COMPLETED_INVOICES,
  };
};
export const getCompletedInvoicesSuccess = (data) => {
  return {
    type: ACTION.GET_COMPLETED_INVOICES_SUCCESS,
    payload: data,
  };
};
export const getCompletedInvoicesFail = (error) => {
  return {
    type: ACTION.GET_COMPLETED_INVOICES_FAIL,
    payload: error,
  };
};

export const getCompletedInvoicesCount = () => {
  return {
    type: ACTION.GET_COMPLETED_INVOICES_COUNT,
  };
};
export const getCompletedInvoicesCountSuccess = (data) => {
  return {
    type: ACTION.GET_COMPLETED_INVOICES_COUNT_SUCCESS,
    payload: data,
  };
};
export const getCompletedInvoicesCountFail = (error) => {
  return {
    type: ACTION.GET_COMPLETED_INVOICES_COUNT_FAIL,
    payload: error,
  };
};

export const getInvoicePayments = () => {
  return {
    type: ACTION.GET_INVOICE_PAYMENTS,
  };
};
export const getInvoicePaymentsSuccess = (data) => {
  return {
    type: ACTION.GET_INVOICE_PAYMENTS_SUCCESS,
    payload: data,
  };
};
export const getInvoicePaymentsFail = (error) => {
  return {
    type: ACTION.GET_INVOICE_PAYMENTS_FAIL,
    payload: error,
  };
};

export const getInvoiceCredits = () => {
  return {
    type: ACTION.GET_INVOICE_CREDITS,
  };
};
export const getInvoiceCreditsSuccess = (data) => {
  return {
    type: ACTION.GET_INVOICE_CREDITS_SUCCESS,
    payload: data,
  };
};
export const getInvoiceCreditsFail = (error) => {
  return {
    type: ACTION.GET_INVOICE_CREDITS_FAIL,
    payload: error,
  };
};

export const deleteArchiveRaisedInvoice = () => {
  return {
    type: ACTION.DELETE_RAISED_INVOICES,
  };
};

export const deleteArchiveRaisedInvoiceSuccess = (action_type) => {
  return {
    type: ACTION.DELETE_RAISED_INVOICES_SUCCESS,
    payload: {
      action_type,
    },
  };
};

export const deleteArchiveRaisedInvoiceFail = () => {
  return {
    type: ACTION.DELETE_RAISED_INVOICES_FAIL,
  };
};

export const deleteArchiveCompletedInvoice = () => {
  return {
    type: ACTION.DELETE_COMPLETED_INVOICES,
  };
};

export const deleteArchiveCompletedInvoiceSuccess = (action_type) => {
  return {
    type: ACTION.DELETE_COMPLETED_INVOICES_SUCCESS,
    payload: {
      action_type,
    },
  };
};

export const deleteArchiveCompletedInvoiceFail = () => {
  return {
    type: ACTION.DELETE_COMPLETED_INVOICES_FAIL,
  };
};

export const deleteArchivePaymentInvoice = (payload) => {
  return {
    type: ACTION.DELETE_PAYMENT_INVOICES,
    payload,
  };
};

export const deleteArchivePaymentInvoiceSuccess = (action_type) => {
  return {
    type: ACTION.DELETE_PAYMENT_INVOICES_SUCCESS,
    payload: {
      action_type,
    },
  };
};

export const deleteArchivePaymentInvoiceFail = () => {
  return {
    type: ACTION.DELETE_PAYMENT_INVOICES_FAIL,
  };
};


export const deleteArchiveCreditInvoice = (payload) => {
  return {
    type: ACTION.DELETE_CREDIT_INVOICES,
    payload,
  };
};

export const deleteArchiveCreditInvoiceSuccess = (action_type) => {
  return {
    type: ACTION.DELETE_CREDIT_INVOICES_SUCCESS,
    payload: {
      action_type,
    },
  };
};

export const deleteArchiveCreditInvoiceFail = () => {
  return {
    type: ACTION.DELETE_CREDIT_INVOICES_FAIL,
  };
};