export const SAVE_JOB_LABOUR = "SAVE_JOB_LABOUR";
export const SAVE_JOB_LABOUR_SUCCESS = "SAVE_JOB_LABOUR_SUCCESS";
export const SAVE_JOB_LABOUR_FAIL = "SAVE_JOB_LABOUR_FAIL";

export const GET_JOB_LABOUR_LIST = "GET_JOB_LABOUR_LIST";
export const GET_JOB_LABOUR_LIST_SUCCESS = "GET_JOB_LABOUR_LIST_SUCCESS";
export const GET_JOB_LABOUR_LIST_FAIL = "GET_JOB_LABOUR_LIST_FAIL";

export const DELETE_JOB_LABOUR = "DELETE_JOB_LABOUR";
export const DELETE_JOB_LABOUR_SUCCESS = "DELETE_JOB_LABOUR_SUCCESS";
export const DELETE_JOB_LABOUR_FAIL = "DELETE_JOB_LABOUR_FAIL";

export const UPDATE_JOB_LABOUR = "UPDATE_JOB_LABOUR";
export const UPDATE_JOB_LABOUR_SUCCESS = "UPDATE_JOB_LABOUR_SUCCESS";
export const UPDATE_JOB_LABOUR_FAIL = "UPDATE_JOB_LABOUR_FAIL";

export const INLINE_UPDATE_JOB_LABOUR = "INLINE_UPDATE_JOB_LABOUR";
export const INLINE_UPDATE_JOB_LABOUR_SUCCESS = "INLINE_UPDATE_JOB_LABOUR_SUCCESS";
export const INLINE_UPDATE_JOB_LABOUR_FAIL = "INLINE_UPDATE_JOB_LABOUR_FAIL";

export const EDIT_JOB_LABOUR_MANY = "EDIT_JOB_LABOUR_MANY";
export const EDIT_JOB_LABOUR_MANY_SUCCESS = "EDIT_JOB_LABOUR_MANY_SUCCESS";
export const EDIT_JOB_LABOUR_MANY_FAIL = "EDIT_JOB_LABOUR_MANY_FAIL";