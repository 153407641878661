import { takeLatest, put, call } from "redux-saga/effects";
import { GET_FORGOT_PASSWORD } from "./actionTypes";
import {
  getForgotPasswordSuccess,
  getForgotPasswordFail
} from "./actions";

import { getForgotPassword } from "../../helpers/backendHelper";
import { showError, showSuccess } from "../../utils/alertMessage";

function* onGetForgotPassword(data) {
  try {
    const response = yield call(getForgotPassword, data);
    if(response){
      if(response.code === 200){
        yield put(getForgotPasswordSuccess(response.message));
        showSuccess(response.message);
      }else{
        yield put(getForgotPasswordFail(response.message));
        showError(response.message);
      }
    }else{
      yield put(getForgotPasswordFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(getForgotPasswordFail(error.response));
  }
}

function* ForgotPasswordSaga() {
  yield takeLatest(GET_FORGOT_PASSWORD, onGetForgotPassword);
}

export default ForgotPasswordSaga;
