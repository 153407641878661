import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import registerImage from '../../../assets/images/register-image.jpg';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import FirstStep from './firstStep';
import SecondStep from './secondStep';

let Register = () => {
  const [validated, setValidated] = useState('false');
  const [changeStep, setStep] = useState(true);
  const [formData, setFormData] = useState()

  function changeFormData(data) {
    setFormData(data);
  }

  return (
    <>
      <div className="pre-login-header">
        <h1 className="mb-2 mt-4">Welcome to 5iQ</h1>
      </div>
      <div className="register-wrapper mt-3">
        <Container className="link-wrapper">
          <p className="mb-3">Already Registered? <Link to="/login" className="primary-link mx-2">LOGIN</Link></p>
        </Container>
        <Container className="form-container mt-1">
          <form className="form-outer" noValidate validated={validated} id="submit_form" >
            <Row>
              <Col className="col-block" md={12} lg={6}>
                <img src={registerImage} alt="register-image"></img>
              </Col>
              <Col className="col-block" md={12} lg={6}>
                {(changeStep) ? <FirstStep setStep={setStep} changeFormData={changeFormData} /> :
                  <SecondStep
                    formData={formData}
                    setStepSecond={setStep}
                    setValidated={setValidated}
                  />}
              </Col>
            </Row>
          </form>
        </Container>
      </div>
    </>
  );
}
export default Register;