export const GET_DETAIL_FOR_CREATE_INVOICE = "GET_DETAIL_FOR_CREATE_INVOICE";
export const GET_DETAIL_FOR_CREATE_INVOICE_SUCCESS =
  "GET_DETAIL_FOR_CREATE_INVOICE_SUCCESS";
export const GET_DETAIL_FOR_CREATE_INVOICE_FAIL =
  "GET_DETAIL_FOR_CREATE_INVOICE_FAIL";

export const GET_INVOICE_REST_DATA = "GET_INVOICE_REST_DATA";
export const GET_INVOICE_REST_DATA_SUCCESS = "GET_INVOICE_REST_DATA_SUCCESS";

export const CREATE_INVOICE = "CREATE_INVOICE";
export const CREATE_INVOICE_SUCCESS = "CREATE_INVOICE_SUCCESS";
export const CREATE_INVOICE_FAIL = "CREATE_INVOICE_FAIL";

export const GET_DETAIL_FOR_EDIT_INVOICE = "GET_DETAIL_FOR_EDIT_INVOICE";
export const GET_DETAIL_FOR_EDIT_INVOICE_SUCCESS =
  "GET_DETAIL_FOR_EDIT_INVOICE_SUCCESS";
export const GET_DETAIL_FOR_EDIT_INVOICE_FAIL =
  "GET_DETAIL_FOR_EDIT_INVOICE_FAIL";

export const SEND_EMAIL = "SEND_EMAIL";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAIL = "SEND_EMAIL_FAIL";

export const GET_INVOICE_CREDIT_INFO = "GET_INVOICE_CREDIT_INFO";
export const GET_INVOICE_CREDIT_INFO_SUCCESS =
  "GET_INVOICE_CREDIT_INFO_SUCCESS";
export const GET_INVOICE_CREDIT_INFO_FAIL = "GET_INVOICE_CREDIT_INFO_FAIL";

export const ADD_INVOICE_CREDIT = "ADD_INVOICE_CREDIT";
export const ADD_INVOICE_CREDIT_SUCCESS = "ADD_INVOICE_CREDIT_SUCCESS";
export const ADD_INVOICE_CREDIT_FAIL = "ADD_INVOICE_CREDIT_FAIL";

export const GET_INVOCE_CREDIT_VIEW = "GET_INVOCE_CREDIT_VIEW";
export const GET_INVOCE_CREDIT_VIEW_SUCCESS = "GET_INVOCE_CREDIT_VIEW_SUCCESS";
export const GET_INVOCE_CREDIT_VIEW_FAIL = "GET_INVOCE_CREDIT_VIEW_FAIL";

export const GET_INSURANCE_DETAILS = "GET_INSURANCE_DETAILS";
export const GET_INSURANCE_DETAILS_SUCCESS = "GET_INSURANCE_DETAILS_SUCCESS";
export const GET_INSURANCE_DETAILS_FAIL = "GET_INSURANCE_DETAILS_FAIL";

export const GET_INVOICE_RECEIPT = "GET_INVOICE_RECEIPT";
export const GET_INVOICE_RECEIPT_SUCCESS = "GET_INVOICE_RECEIPT_SUCCESS";
export const GET_INVOICE_RECEIPT_FAIL = "GET_INVOICE_RECEIPT_FAIL";

export const GET_INVOICE_RECEIPT_EMAIL = "GET_INVOICE_RECEIPT_EMAIL";
export const GET_INVOICE_RECEIPT_EMAIL_SUCCESS =
  "GET_INVOICE_RECEIPT_EMAIL_SUCCESS";
export const GET_INVOICE_RECEIPT_EMAIL_FAIL = "GET_INVOICE_RECEIPT_EMAIL_FAIL";


export const GET_INVOICE_CREDIT_EMAIL =  "GET_INVOICE_CREDIT_EMAIL";
export const GET_INVOICE_CREDIT_EMAIL_SUCCESS =  "GET_INVOICE_CREDIT_EMAIL_SUCCESS";
export const GET_INVOICE_CREDIT_EMAIL_FAIL =  "GET_INVOICE_CREDIT_EMAIL_FAIL";