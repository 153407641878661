import {
  GET_FORGOT_PASSWORD,
  GET_FORGOT_PASSWORD_SUCCESS,
  GET_FORGOT_PASSWORD_FAIL
} from "./actionTypes";

export const getForgotPassword = () => {
  return {
    type: GET_FORGOT_PASSWORD,
  };
};

export const getForgotPasswordSuccess = (data) => {
  return {
    type: GET_FORGOT_PASSWORD_SUCCESS,
    payload: data,
  };
};

export const getForgotPasswordFail = (error) => {
  return {
    type: GET_FORGOT_PASSWORD_FAIL,
    payload: error,
  };
};
