import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { editQuotes } from "../../helpers/backendHelper";
import { showError, showSuccess } from "../../utils/alertMessage";

const MoveToLost = ({
  moveToLostModalShow,
  handleSMSModalClose,
  refreshData,
  selectedRow,
}) => {
  const [reason, setReason] = useState("");
  const [description, setDescription] = useState("");
  const [validated, setValidated] = useState(false);

  // Quote move to lost successfully
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (reason === "" || description === "") {
      setValidated(true);
    } else {
      let data = {
        payload: {
          id: selectedRow,
          quote_status: "Lost",
          reason: reason,
          reason_description: description,
        },
      };

      let response = await editQuotes(data);
      if (response.code === 200) {
        showSuccess("Quote move to lost successfully");
        handleSMSModalClose();
        refreshData();
      } else {
        showError(response.message);
        handleSMSModalClose();
      }
    }
  };

  return (
    <Modal
      className="preview-dialog"
      size="md"
      show={moveToLostModalShow}
      onHide={handleSMSModalClose}
    >
      <Modal.Header>
        <Modal.Title className="d-flex justify-space-between">
          <span>Move to Lost</span>
          <Button
            type="button"
            className="close-btn"
            onClick={handleSMSModalClose}
          >
            <i className="la la-times"></i>
          </Button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} id="emailTemplateForm">
          <div className="column-wrapper ">
            <div className="left-column">
              <div className="custom-normal-select mb-3">
                <Form.Label>Reason *</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  required
                  name="reason"
                  id="reason"
                  onChange={(e) => setReason(e.target.value)}
                >
                  <option value="">Select Reason</option>
                  <option value="Competitor">Competitor</option>
                  <option value="Price">Price</option>
                  <option value="Timing">Timing</option>
                  <option value="Other">Other</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  This field is required.
                </Form.Control.Feedback>
              </div>
              <Form.Group className="form-group mb-3">
                <Form.Label>Reason description *</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="description"
                  id="description"
                  required
                  onChange={(e) => setDescription(e.target.value)}
                />

                <Form.Control.Feedback type="invalid">
                  This field is required.
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          className="btn-outline rounded-btn"
          onClick={handleSMSModalClose}
        >
          Cancel
        </Button>
        <Button
          type="button"
          onClick={(e) => handleSubmit(e)}
          className="rounded-btn"
          variant="primary"
        >
          Move to Lost
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default MoveToLost;
