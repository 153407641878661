import { getInsurance } from "helpers/backendHelper";
import { getWorkshopId } from "utils/getWorkshopId";

export async function getInsuranceDetails(id) {
  const request = {
    get: "all",
    conditions: {
      "InsuranceCompany.id": id,
      "InsuranceCompany.workshop_id": getWorkshopId(),
    },
    fields: [
      "id",
      "company_name",
      "email",
      "phone_number",
      "street_address",
      "postcode",
    ],
    contain: {
      States: {
        fields: ["id", "name"],
      },
      Countries: {
        fields: ["id", "country_name"],
      },
    },
  };

  const resp = await getInsurance({ payload: request });
  if (resp?.code === 200) {
    return resp?.data?.InsuranceCompany[0];
  } else {
    throw Error("No Data found");
  }
}
