import React, { useState, useEffect } from "react";
import EmailIcon from "../../../../../assets/images/Email-Vector-SVG-Icon-20-SVG-Repo.png"

const CompanyCommunicationEmail = ({ workShopDetails }) => {
  const [email, setEmail] = useState("");
  const [disable, setDisable] = useState(false);
  const [showVerify, setShowVerify] = useState(false);

  useEffect(() => {
    if (workShopDetails) {
      if (workShopDetails.from_email_temp === "" && workShopDetails.from_email === "") {
        setEmail(workShopDetails.email);
        if (workShopDetails.from_email !== "" || workShopDetails.from_email_temp !== "") {
          setDisable(true);
        }
      }
      else if (workShopDetails.from_email_temp !== "" && workShopDetails.from_email === "") {
        setEmail(workShopDetails.from_email_temp);
        if (workShopDetails.from_email_temp !== "") {
          setDisable(true);
        }
      }
      else {
        setEmail(workShopDetails.from_email);
        if (workShopDetails.from_email !== "") {
          setDisable(true);
        }
      }
    }
  }, [workShopDetails]);

  const handleEdit = () => {
    setDisable(false);
    setShowVerify(true);
  }

  const handleVerify = () => {
    
  }

  const handleCancel = () => {

  }

  return (
    <div className="card mb-3">
      <div className="card-header d-flex flex-wrap align-items-center">
        <div className="img flex-shrink-0 me-2">
          <img src={EmailIcon} width="40" height="40" className="img-circle" alt="" />
        </div>
        <div className="text-uppercase me-2">
          <h5 className="font-size-18">EMAIL</h5>
        </div>
        <div className="text-uppercase font-size-13">5IQ WILL SEND ALL EMAILS WITH YOUR BUSINESS SENDER ID | EMAIL ADDRESS AS DISPLAYED BELOW.</div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-7">
            <div className="form-group pb-3">
              <label htmlFor="" className="form-label">
                {workShopDetails && workShopDetails.from_email_temp !== "" && workShopDetails.from_email === "" ? (
                  <>Under Moderation</>
                ) : (
                  <>From Email</>
                )}
              </label>
              <div className="d-flex flex-nowrap">
                <input
                  type="text"
                  className="form-control"
                  onChange={e => setEmail(e.target.value)}
                  value={email}
                  disabled={disable}
                />
                <div className="cta flex-shrink-0 ms-3">
                  {!showVerify && workShopDetails && (workShopDetails.from_email !== "" || workShopDetails.from_email_temp !== "") && (
                    <button type="button" className="btn btn-success btn-rounded px-4" onClick={handleEdit}>Edit</button>
                  )}
                  {workShopDetails && (workShopDetails.from_email === "" && workShopDetails.from_email_temp === "") && (
                    <>
                      <button type="button" className="btn btn-success btn-rounded px-4" onClick={handleVerify}>Verify</button>
                      <button type="button" className="btn btn-success btn-rounded px-4" onClick={handleCancel}>Cancel</button>
                    </>
                  )}
                  {showVerify && (
                    <>
                      <button type="button" className="btn btn-success btn-rounded px-4 " onClick={handleVerify}>Verify</button>
                      <button type="button" className="btn btn-success btn-rounded px-4" onClick={handleCancel}>Cancel</button>
                    </>
                  )}
                </div>
              </div>
              {workShopDetails && workShopDetails.from_email !== "" && workShopDetails.from_email_temp !== "" && (
                <span class="under-moderation pt-1 d-block font-size-13">Under Moderation :- {workShopDetails.from_email_temp}</span>
              )}
              </div>
          </div>
        </div>
        <p className="pb-3 font-size-12">Note :- Please ensure this is correct in order to make your email look professional and personalised when sending to your customers ,suppliers and employee's.</p>
      </div>
    </div>
  );
}

export default CompanyCommunicationEmail;