import {
  SAVE_PART,
  SAVE_PART_SUCCESS,
  SAVE_PART_FAIL,
  GET_PART_LIST,
  GET_PART_LIST_SUCCESS,
  GET_PART_LIST_FAIL,
  DELETE_PART,
  DELETE_PART_SUCCESS,
  DELETE_PART_FAIL,
} from "./actionTypes";

// Save part START
export const savePart = () => {
  return {
    type: SAVE_PART,
  };
};

export const savePartSuccess = (data) => {
  return {
    type: SAVE_PART_SUCCESS,
    payload: data,
  };
};

export const savePartFail = (error) => {
  return {
    type: SAVE_PART_FAIL,
    payload: error,
  };
};
// Save part END

// get part list, search part, check part exist or not START
export const getPartList = (payload) => {
  return {
    type: GET_PART_LIST,
    payload,
  };
};

export const getPartListSuccess = (data) => {
  return {
    type: GET_PART_LIST_SUCCESS,
    payload: data,
  };
};

export const getPartListFail = (error) => {
  return {
    type: GET_PART_LIST_FAIL,
    payload: error,
  };
};
// get part list, search part, check part exist or not START

// Delete part START
export const deletePart = () => {
  return {
    type: DELETE_PART,
  };
};

export const deletePartSuccess = (data) => {
  return {
    type: DELETE_PART_SUCCESS,
    payload: data,
  };
};

export const deletePartFail = (error) => {
  return {
    type: DELETE_PART_FAIL,
    payload: error,
  };
};
// Delete part END
