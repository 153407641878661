import {
  GET_SECURITY_GROUP_DETAILS,
  GET_SECURITY_GROUP_DETAILS_SUCCESS,
  GET_SECURITY_GROUP_DETAILS_FAIL,
  UPDATE_SECURITY_GROUP_DETAILS,
  UPDATE_SECURITY_GROUP_DETAILS_SUCCESS,
  UPDATE_SECURITY_GROUP_DETAILS_FAIL,
  ADD_SECURITY_GROUP_DETAILS,
  ADD_SECURITY_GROUP_DETAILS_SUCCESS,
  ADD_SECURITY_GROUP_DETAILS_FAIL,
} from "./actionTypes";

const initialState = {
  loading: false,
  formLoading: false,
  error: {
    message: "",
  },
  success: {
    message: "",
  },
  securityGroupsDetails: null,
};

const SecurityGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SECURITY_GROUP_DETAILS:
      state = {
        ...state,
        loading: true,
        securityGroupsDetails: action.securityGroupsDetails,
      };
      break;
    case GET_SECURITY_GROUP_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        securityGroupsDetails: action.payload,
        success: {
          message: action.message,
        },
      };
      break;
    case GET_SECURITY_GROUP_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      };
      break;
    case ADD_SECURITY_GROUP_DETAILS:
      state = {
        ...state,
        loading: true,
        securityGroupsDetails: action.securityGroupsDetails,
      };
      break;
    case ADD_SECURITY_GROUP_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        securityGroupsDetails: action.payload,
        success: {
          message: action.message,
        },
      };
      break;
    case ADD_SECURITY_GROUP_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      };
      break;

    case UPDATE_SECURITY_GROUP_DETAILS:
      state = {
        ...state,
        loading: true,
        securityGroupsDetails: action.securityGroupsDetails,
      };
      break;
    case UPDATE_SECURITY_GROUP_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        securityGroupsDetails: action.payload,
        success: {
          message: action.message,
        },
      };
      break;
    case UPDATE_SECURITY_GROUP_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default SecurityGroupReducer;
