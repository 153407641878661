import {
  GET_FORGOT_PASSWORD,
  GET_FORGOT_PASSWORD_SUCCESS,
  GET_FORGOT_PASSWORD_FAIL
} from "./actionTypes";

const initialState = {
  loadingLogins: false,
  responseData:null,
  error: {
    message: "",
  },
};

const ForgotPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FORGOT_PASSWORD:
      state = { 
        ...state, 
        loadingLogins: true,
      };
      break;
    case GET_FORGOT_PASSWORD_SUCCESS:
      state = { 
        ...state, 
        loadingLogins: false,
        responseData: action.payload,
      };
      break;
    case GET_FORGOT_PASSWORD_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loadingLogins: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ForgotPasswordReducer;
