import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import Spinner from 'react-bootstrap/Spinner';

const Notification = (props) => {

  if(props.notificationData.isFetching === true && props.notificationData.notificationData.length > 0){

    const notificationData = props.notificationData.notificationData;

    return (
      <>
          <div className="notification-inner custom-header-dropdown">
              <div className="notification-heading">
                  <span>You have 5 New Notification</span>
                  <Link to="/notification" className="btn-warning btn-view-all">View all</Link>
              </div>
              <Scrollbars
                  className="custom-scrollbar" style={{ width: 300, height: 240 }}
                  renderThumbVertical={props => <div {...props} className="thumb-vertical" />}>
                  <ul className="header-notification-list">
                  {notificationData.map((notification) =>
                    <li key={notification.id}>
                        <Link to="">
                          <span>
                              <i className="fa fa-envelope-o"></i>
                          </span>
                          <p>{notification.msg}</p>
                        </Link>
                    </li>
                  )}
                  </ul>
              </Scrollbars>
          </div>
      </>
    )

  } else {
    return (<>
      <div className="notification-inner custom-header-dropdown">
        <div className="spinner-loader">
          <Spinner animation="border" variant="info" />
        </div>
      </div>
    </>)
  }
}
export default Notification;