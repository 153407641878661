export function checkMakeIcon(make) {
    var makeIcon = "fa fa-question-circle";
    make = make.toLowerCase();
    let iconArr = ["Fiber","abarth","asia","chery","citroen","daihatsu","daimler","eunos","foton","fpv","geely","great","wall","haval","holden","hsv","iveco","jmc","ldv","lloyds","mahindra","mercedes amg","mg","opel","peugeot","pinzgauer","proton","range rover","renault","skoda","ssangyong","tata","harley","alpine","bertone","bmw alpina","caterham","daf","ds","genesis","kobelco","ktm","lada","mercedes maybach","mitsubishi fuso","morgan","noble","oka","pagani","scarab","seat","the london taxi company","trd","tvr","vauxhall","winnebago","zx auto","demo_1"];
    if(make.includes(iconArr)){
        return "fa fa-question-circle";
    }else{
        if(make && make!== null && make !== undefined){
            makeIcon = "ficon-"+make.replace(" ", "-");
        }else{
            makeIcon = "ficon-honda";
        }
        return makeIcon;
    }
}

const makeIcon = {
    checkMakeIcon
};

export default makeIcon;