import { takeLatest, put, call } from "redux-saga/effects";
import {
  GET_REGISTER, SEND_OTP, CONFIRM_OTP, EXIST_EMAIL, GET_COUNTRY, GET_STATE,
  GET_INDUSTRY, GET_ACCOUNTING_PACKAGE, GET_SIZE_OF_WORKSHOP, GET_REFERRAL_ADVERTISE
} from "./actionTypes";

import {
  getRegisterSuccess,
  getRegisterFail,
  sendOtpSuccess,
  sendOtpFail,
  confirmOtpSuccess,
  confirmOtpFail,
  existEmailSuccess,
  existEmailFail,
  getCountrySuccess,
  getCountryFail,
  getStateSuccess,
  getStateFail,
  getIndustrySuccess,
  getIndustryFail,
  getAccountingPackageSuccess,
  getAccountingPackageFail,
  getSizeOfWorkshopSuccess,
  getSizeOfWorkshopFail,
  getReferralAdvertiseSuccess,
  getReferralAdvertiseFail
} from "./actions";

import { getRegister } from "../../helpers/backendHelper";
import { sendOtp } from "../../helpers/backendHelper";
import { confirmOtp } from "../../helpers/backendHelper";
import { existEmail } from "../../helpers/backendHelper";
import { getCountry } from "../../helpers/backendHelper";
import { getState } from "../../helpers/backendHelper";
import { getIndustry } from "../../helpers/backendHelper";
import { getAccountingPackage } from "../../helpers/backendHelper";
import { getSizeOfWorkshop } from "../../helpers/backendHelper";
import { getReferralAdvertise } from "../../helpers/backendHelper";

function* onGetRegister(data) {
  try {
    const response = yield call(getRegister, data);
    if (response) {
      if (response.code === 200) {
        yield put(getRegisterSuccess(response));
      } else {
        yield put(getRegisterFail(response.message));
      }
    } else {
      yield put(getRegisterFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getRegisterFail(error.response));
  }
}

function* onSendOtp(data) {
  try {
    const response = yield call(sendOtp, data);
    if (response) {
      if (response.code === 200) {
        yield put(sendOtpSuccess(response));
      } else {
        yield put(sendOtpFail(response.message));
      }
    } else {
      yield put(sendOtpFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(sendOtpFail(error.response));
  }
}

function* onConfirmOtp(data) {
  try {
    const response = yield call(confirmOtp, data);
    if (response) {
      if (response.code === 200) {
        yield put(confirmOtpSuccess(response));
      } else {
        yield put(confirmOtpFail(response.message));
      }
    } else {
      yield put(confirmOtpFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(confirmOtpFail(error.response));
  }
}

function* onExistEmail(data) {
  try {
    const response = yield call(existEmail, data);
    if (response) {
      if (response.code === 400) {
        yield put(existEmailSuccess(response));
      } else {
        yield put(existEmailFail(response.message));
      }
    } else {
      yield put(existEmailFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(existEmailFail(error.response));
  }
}

function* onGetCountry(data) {
  try {
    const response = yield call(getCountry, data);
    if (response) {
      if (response.code === 200) {

        yield put(getCountrySuccess(response));
      } else {
        yield put(getCountryFail(response.message));
      }
    } else {
      yield put(getCountryFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getCountryFail(error.response));
  }
}

function* onGetState(data) {
  try {
    const response = yield call(getState, data);
    if (response) {
      if (response.code === 200) {

        yield put(getStateSuccess(response));
      } else {
        yield put(getStateFail(response.message));
      }
    } else {
      yield put(getStateFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getCountryFail(error.response));
  }
}

function* onGetIndusry(data) {
  try {
    const response = yield call(getIndustry, data);
    if (response) {
      if (response.code === 200) {
        yield put(getIndustrySuccess(response));
      } else {
        yield put(getIndustryFail(response.message));
      }
    } else {
      yield put(getIndustryFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getIndustryFail(error.response));
  }
}

function* onGetAccountingPackage(data) {
  try {
    const response = yield call(getAccountingPackage, data);
    if (response) {
      if (response.code === 200) {
        yield put(getAccountingPackageSuccess(response));
      } else {
        yield put(getAccountingPackageFail(response.message));
      }
    } else {
      yield put(getAccountingPackageFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getAccountingPackageFail(error.response));
  }
}

function* onGetSizeOfWorkshop(data) {
  try {
    const response = yield call(getSizeOfWorkshop, data);
    if (response) {
      if (response.code === 200) {
        yield put(getSizeOfWorkshopSuccess(response));
      } else {
        yield put(getSizeOfWorkshopFail(response.message));
      }
    } else {
      yield put(getSizeOfWorkshopFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getSizeOfWorkshopFail(error.response));
  }
}

function* onGetReferralAdvertise(data) {
  try {
    const response = yield call(getReferralAdvertise, data);
    if (response) {
      if (response.code === 200) {
        yield put(getReferralAdvertiseSuccess(response));
      } else {
        yield put(getReferralAdvertiseFail(response.message));
      }
    } else {
      yield put(getReferralAdvertiseFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getReferralAdvertiseFail(error.response));
  }
}

function* RegisterSaga() {
  yield takeLatest(SEND_OTP, onSendOtp);
  yield takeLatest(CONFIRM_OTP, onConfirmOtp);
  yield takeLatest(GET_REGISTER, onGetRegister);
  yield takeLatest(EXIST_EMAIL, onExistEmail);
  yield takeLatest(GET_COUNTRY, onGetCountry);
  yield takeLatest(GET_STATE, onGetState);
  yield takeLatest(GET_INDUSTRY, onGetIndusry);
  yield takeLatest(GET_ACCOUNTING_PACKAGE, onGetAccountingPackage);
  yield takeLatest(GET_SIZE_OF_WORKSHOP, onGetSizeOfWorkshop);
  yield takeLatest(GET_REFERRAL_ADVERTISE, onGetReferralAdvertise);
}

export default RegisterSaga;
