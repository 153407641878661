export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL";

export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAIL = "UPDATE_USER_PROFILE_FAIL";

export const CHECK_EMAILEXIST = "CHECK_EMAILEXIST";
export const CHECK_EMAILEXIST_SUCCESS = "CHECK_EMAILEXIST_SUCCESS";
export const CHECK_EMAILEXIST_FAIL = "CHECK_EMAILEXIST_FAIL";

export const GET_SELECTED_CUSTOMERLIST = "GET_SELECTED_CUSTOMERLIST";
export const GET_SELECTED_CUSTOMERLIST_SUCCESS =
  "GET_SELECTED_CUSTOMERLIST_SUCCESS";
export const GET_SELECTED_CUSTOMERLIST_FAIL = "GET_SELECTED_CUSTOMERLIST_FAIL";

export const CHECK_PHONE_EXIST = "CHECK_PHONE_EXIST";
export const CHECK_PHONE_EXIST_SUCCESS = "CHECK_PHONE_EXIST_SUCCESS";
export const CHECK_PHONE_EXIST_FAIL = "CHECK_PHONE_EXIST_FAIL";

export const CHECK_MOBILE_EXIST = "CHECK_MOBILE_EXIST";
export const CHECK_MOBILE_EXIST_SUCCESS = "CHECK_MOBILE_EXIST_SUCCESS";
export const CHECK_MOBILE_EXIST_FAIL = "CHECK_MOBILE_EXIST_FAIL";
