import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { authRoutes, ownerRoutes, adminRoutes, accountRoutes, officeRoutes, seniorTechRoutes, TechRoutes } from "./routes";
// import { GET_LOGOUT } from "../store/login/actionTypes";
import Wrapper from "../views/common/navigation/components/Wrapper";
import { v4 as uuidv4 } from 'uuid';


const renderSwitch = (user) => {
    if (user) {
        const role_id = user.role_id
        switch (role_id) {
            case 2:
                return ownerRoutes.map((route) => {
                    return route.element ? (
                        <Route
                            key={uuidv4()}
                            path={route.path}
                            exact={route.exact}
                            name={route.name}
                            element={
                                <Wrapper>
                                    <route.element />
                                </Wrapper>
                            }
                        />
                    ) : (
                        <Navigate to="/login" key={uuidv4()} />
                    );
                })
            case 3:
                return TechRoutes.map((route) => {
                    return route.element ? (
                        <Route
                            key={uuidv4()}
                            path={route.path}
                            exact={route.exact}
                            name={route.name}
                            element={
                                <route.element />
                            }
                        />
                    ) : (
                        <Navigate to="/login" key={uuidv4()} />
                    );
                })
            case 5:
                return accountRoutes.map((route) => {
                    return route.element ? (
                        <Route
                            key={uuidv4()}
                            path={route.path}
                            exact={route.exact}
                            name={route.name}
                            element={
                                <route.element />
                            }
                        />
                    ) : (
                        <Navigate to="/login" key={uuidv4()} />
                    );
                })
            case 6:
                return officeRoutes.map((route) => {
                    return route.element ? (
                        <Route
                            key={uuidv4()}
                            path={route.path}
                            exact={route.exact}
                            name={route.name}
                            element={
                                <route.element />
                            }
                        />
                    ) : (
                        <Navigate to="/login" key={uuidv4()} />
                    );
                })
            case 7:
                return seniorTechRoutes.map((route) => {
                    return route.element ? (
                        <Route
                            key={uuidv4()}
                            path={route.path}
                            exact={route.exact}
                            name={route.name}
                            element={
                                <route.element />
                            }
                        />
                    ) : (
                        <Navigate to="/login" key={uuidv4()} />
                    );
                })
            case 10:
                return adminRoutes.map((route) => {
                    return route.element ? (
                        <Route
                            key={uuidv4()}
                            path={route.path}
                            exact={route.exact}
                            name={route.name}
                            element={
                                <route.element />
                            }
                        />
                    ) : (
                        <Navigate to="/login" key={uuidv4()} />
                    );
                })
            default:
                return <Navigate to="/login" key={uuidv4()} />
        }
    }
};
const AllRoutes = () => {
    let navigate = useNavigate();
    const [user, setUser] = useState();
    let auth = useSelector((state) => state.LoginReducer);

    if (window.location.pathname === "/") {
        navigate("/login");
    }

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem("data")));
    }, [auth]);

    return (
        <Routes>
            {authRoutes.map((route) => {
                return route.element ? (
                    <Route
                        key={uuidv4()}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        element={<route.element />}
                    />
                ) : (
                    <Navigate to="/login" key={uuidv4()} />
                );
            })}
            {renderSwitch(user)}
        </Routes>
    )
}

export default AllRoutes;