import Loadable from "react-loadable";

import { Loading } from "../common/navigation";

const UserProfile = Loadable({
  loader: () => import("./components/userProfile"),
  loading: Loading,
});

export const routes = [
  {
    path: "/profile",
    exact: true,
    name: "UserProfile",
    element: UserProfile,
  }
];

export * from "./components";
