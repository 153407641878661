import {
  GET_WON_QUOTES,
  GET_WON_QUOTES_SUCCESS,
  GET_WON_QUOTES_FAIL,
  GET_WON_QUOTES_COUNT,
  GET_WON_QUOTES_COUNT_SUCCESS,
  GET_WON_QUOTES_COUNT_FAIL,
} from "./actionTypes";

export const getWonQuotes = () => {
  return {
    type: GET_WON_QUOTES,
  };
};

export const getWonQuotesSuccess = (data) => {
  return {
    type: GET_WON_QUOTES_SUCCESS,
    payload: data,
  };
};

export const getWonQuotesFail = (error) => {
  return {
    type: GET_WON_QUOTES_FAIL,
    payload: error,
  };
};

export const getWonQuotesCount = () => {
  return {
    type: GET_WON_QUOTES_COUNT,
  };
};

export const getWonQuotesCountSuccess = (data) => {
  return {
    type: GET_WON_QUOTES_COUNT_SUCCESS,
    payload: data,
  };
};

export const getWonQuotesCountFail = (error) => {
  return {
    type: GET_WON_QUOTES_COUNT_FAIL,
    payload: error,
  };
};
