import { takeLatest, put, call } from "redux-saga/effects";
import { GET_LOST_QUOTES, GET_LOST_QUOTES_COUNT } from "./actionTypes";
import {
  getLostQuotesSuccess,
  getLostQuotesFail,
  getLostQuotesCountSuccess,
  getLostQuotesCountFail,
} from "./actions";

import { getLostQuotes } from "../../helpers/backendHelper";

function* onGetLostQuotes(data) {
  try {
    const response = yield call(getLostQuotes, data);
    if (response) {
      if (response.code === 200) {
        yield put(getLostQuotesSuccess(response.data.CustomerQuotes));
      } else {
        yield put(getLostQuotesFail(response.message));
      }
    } else {
      yield put(getLostQuotesFail("Didn't get api response"));
    }
  } catch (error) {
    console.log("error", error);
    yield put(getLostQuotesFail(error.response));
  }
}

function* onGetLostQuotesCount(data) {
  try {
    const response = yield call(getLostQuotes, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getLostQuotesCountSuccess(response.data.CustomerQuotes[0].COUNT)
        );
      } else {
        yield put(getLostQuotesCountFail(response.message));
      }
    } else {
      yield put(getLostQuotesCountFail("Didn't get api response"));
    }
  } catch (error) {
    console.log("error", error);
    yield put(getLostQuotesCountFail(error.response));
  }
}

function* LostQuotesSaga() {
  yield takeLatest(GET_LOST_QUOTES, onGetLostQuotes);
  yield takeLatest(GET_LOST_QUOTES_COUNT, onGetLostQuotesCount);
}

export default LostQuotesSaga;
