import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Scrollbars } from "react-custom-scrollbars";
import Button from "react-bootstrap/Button";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import Select from "react-select";
import { EditorState, Modifier,convertToRaw, ContentState, convertFromHTML, stateToHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { DatePicker, TimePicker } from "antd";
import Accordion from 'react-bootstrap/Accordion';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import "./styles.scss";

const PurchasesCreate = () => {
  const [createdFor, setCreatedFor] = useState("Job");
  const jobOptions = [
    { value: "1", label: "Michelle Burch" },
    { value: "2", label: "Ricky Lee" },
    { value: "3", label: "Darrel Skinner" },
    { value: "4", label: "Donald Lee" },
    { value: "5", label: "Pujan Shah" },
  ];
  const allProducts =[{
    id:'1'
  },
  {
    id:'2'
  }
  ];
  const labourProducts =[{
    id:'1'
  },
  {
    id:'2'
  }
  ]
  const columns = [{
    dataField: 'part_number',
    text: 'Part No.',
    sort: true,
    style:{'width' : '14%'},
    formatter: ()=>{
        return(
            <Form.Group className="form-group disable">
                <Form.Control type="text" disabled  />
            </Form.Group>
        )
    }
}, 
{
    dataField: 'part_name',
    text: 'Part Name',
    sort: true,
    formatter: ()=>{
        return(
            <Form.Group className="part-name">
              <Form.Control type="text" />
              <button type="button" className="d-flex align-center"><i className="fa fa-plus"></i><span>create new part</span></button>
            </Form.Group>
            
        )
    }
},
{
    dataField: 'cost',
    text: 'Cost',
    sort: true,
    style:{'width' : '100px'},
    formatter:() =>{
        return(
            <Form.Group className="form-group">
                <Form.Control type="text" className="small-input" readOnly />
            </Form.Group>
        )
    }
},
{
    dataField: 'qty',
    text: 'Qty',
    sort: true,
    style:{'width' : '80px'},
    formatter:() =>{
        
        return(
            <Form.Group className="form-group disable">
                <Form.Control type="text" disabled/>
            </Form.Group>
        )
    }
}, 
{
    dataField: 'total',
    text: 'Total',
    sort: true,
    style:{'width' : '80px'},
    formatter:() =>{
        return(
            <Form.Group className="form-group disable">
                <Form.Control type="text" readOnly/>
            </Form.Group>
        )
    }
},  
{
    dataField: 'more',
    text: '',
    sort: true,
    style:{'width' : '50px'},
    formatter:() =>{
        return(
            <div className="btn-group toggle-hover">
                <Link to="/" className="remove-icon align-center justify-center">
                    <i className="fa fa-minus"></i>
                 </Link>
            </div>
        )
    }
},  

  ];
  const labourColumns = [{
    dataField: 'PartNo',
    text: 'Part No.',
    sort: true,
    style:{'width' : '140px'},
    formatter:() =>{
        return(
            <div>
              1223
            </div>
        )
    }
  }, 
  {
    dataField: 'Part Name',
    text: 'Part Name',
    sort: true,
    style:{'width' : '165px'},
    formatter:(cell, row) =>{
        return(
            <div>
              Oil Filter
            </div>
        )
    }
  }, 
  {
    dataField: 'Cost',
    text: 'Cost',
    sort: true,
    style:{'width' : '90px'},
    formatter:(cell, row) =>{
        return(
            <Form.Group>
                ₹8.00
            </Form.Group>
        )
    }
  },
  {
    dataField: 'Qty Required',
    text: 'Qty Required',
    sort: true,
    style:{'width' : '200px'},
    formatter:(cell, row) =>{
        return(
            <Form.Group>
                1
            </Form.Group>
        )
    }
  },
  {
    dataField: 'Qty Ordered',
    text: 'Qty Ordered',
    sort: true,
    style:{'width' : '190px'},
    formatter:(cell, row) =>{
        return(
            <Form.Group className="form-group ">
                <Form.Control type="text" className="small-input width-50"/>
            </Form.Group>
        )
    }
  }, 
  {
    dataField: 'Sub Total',
    text: 'Sub Total',
    sort: true,
    style:{'width' : '145px'},
    formatter:(cell, row) =>{
        return(
          <div>
            ₹8.00
          </div>
        )
    }
  }, 
  {
    dataField: 'total_price',
    text: '',
    sort: true,
    style:{'width' : '120px'},
    formatter:(cell, row) =>{
        return(
          <div className="btn-group toggle-hover">
            <Link to="/" className="remove-icon align-center justify-center">
                <i className="fa fa-minus"></i>
            </Link>
          </div>
        )
    }
  }, 
  ];
  return (
    <>
      <div className="right-section-wrapper has-white-bg purchase-order-wrapper">
        <div className="purchase-order-heading d-flex justify-space-between align-center">
          <h3>PURCHASE ORDER ~ JOB</h3>
          <div className="btn-wrapper">
            <Button variant="outline-secondary" className="btn-outline rounded-btn height-34">
              Cancel
            </Button>
            <Button className="rounded-btn btn-success height-34" variant="success">
              Save
            </Button>
            <Button className='btn btn-primary rounded-btn height-34'>
              Save &amp; Close
          </Button>
          </div>
        </div>
        <div className="purchase-order-filter">
        <Accordion defaultActiveKey="0" > 
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <span>PURCHASE ORDER</span>
              <i className="la la-angle-down"></i>
            </Accordion.Header>
            <Accordion.Body>
              <div className="form-outer d-flex align-center mb-3">
                <div className="date-created width-33">
                  <i className="fa fa-calendar text-primary font-size-20 m-r-5"></i>
                  <span>Date Created :</span>
                  <span> 26/04/2022</span>
                </div>
                <Form.Group className="form-group date-picker d-flex width-33 align-center">
                  <label>Due Date</label>
                    <DatePicker className="form-control" readOnly />
                  <button
                    type="button"
                    className="btn btn-primary date-set btn-icon"
                  >
                    <i className="fa fa-calendar"></i>
                  </button>
                </Form.Group>
                <Form.Group className="form-group d-flex width-33 align-center">
                  <div className="profile"><i className="fa fa-user"></i></div>
                  <label>Created By : </label>
                  <span>vibhuti Nakum</span>
                </Form.Group>
              </div>
              <div className="radio-wrapper align-center mb-3">
                <label>Created For: </label>
                  <div className="radio-block d-flex align-center">
                    <Form.Check
                      className="custom-check radio-btn secondary-radio"
                      type="radio"
                      id="Job"
                    >
                      <Form.Check.Input
                        name="size"
                        type="radio"
                        defaultChecked
                        onChange={() => setCreatedFor("Job")}
                        checked={
                            createdFor === "Job" ? true : false
                        }
                      />
                      <Form.Check.Label>
                        <span className="check"></span>
                      </Form.Check.Label>
                    </Form.Check>
                    <div className="select-wrapper form-group width-50 d-flex align-center">
                    <label>Job</label>
                    <Form.Group className="form-group mx-0">
                      <Select
                        className="form-control custom-select"
                        required
                        name="country"
                        id="country"
                        options={jobOptions}
                      />
                    </Form.Group>
                </div>
                  </div>
                  <div className="radio-block d-flex align-center">
                  <Form.Check
                    className="custom-check radio-btn secondary-radio"
                    type="radio"
                    id="Warehouse"
                  >
                    <Form.Check.Input
                      name="size"
                      type="radio"
                      onChange={() => setCreatedFor("Warehouse")}
                      checked={
                          createdFor === "Warehouse" ? true : false
                      }
                    />
                    <Form.Check.Label>
                      <span className="check"></span>
                    </Form.Check.Label>
                  </Form.Check>
                    <div className="select-block custom-normal-select d-flex align-center no-wrap">
                      <label>Warehouse</label>
                      <select disabled="disabled">
                        <option value="">Select</option>
                        <option value="2">Cover-More</option>
                        <option value="9">AJ Insurance</option>
                        <option value="11">demo</option>
                        <option value="13">Test M</option>
                      </select>
                    </div>
                  </div>
              </div>    
              <div className="form-outer form-align-start">
                <div className="select-wrapper form-group width-50 select2-disable">
                  <Form.Group className="form-group mx-0">
                    <label>Supplier</label>
                    <Select
                      className="form-control custom-select"
                      required
                      name="country"
                      id="country"
                      options={jobOptions}
                    />
                  </Form.Group>
                </div>
                <div className="form-group custom-normal-select d-flex align-center mb-3 width-50">
                  <label>Branch Name</label>
                  <Form.Select aria-label="Default select example">
                    <option>Job Department</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </div>
              </div>
              <div className="info-block mb-3">
                <div className="d-flex outer-div">
                  <div className="d-flex width-50 mb-2">
                    <i className="fa fa-envelope text-primary font-size-20 mr-10"></i>
                    <Link to="/">Info@repco.com.au</Link>
                  </div>
                  <div className="d-flex width-50">
                    <i className="fa fa-phone text-primary font-size-20"></i>
                    <span></span>
                  </div>
                </div>
                <div className="d-flex">
                  <i className="fa fa-map-marker text-primary font-size-20"></i>
                  <span>120 Old Cleveland Road,, Queensland,Australia,4152</span>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
         </Accordion>
         {createdFor === "Job" && (
           <>
            <div className="order-detail-outer mb-4">
              <div className="order-for-job">
                <div className="title-block">
                  <h4>DEMO AUTO-SAMPLE DATA</h4>
                </div>
                <div className="data-block-wrapper d-flex">
                <div className="data-block d-flex no-wrap mb-3">
                  <i className="fa fa-info-circle text-primary font-size-20 mr-10"></i>
                  <div className="select-block custom-normal-select">
                    <label>Branch Name</label>
                    <select>
                      <option value="">Select</option>
                      <option value="2">Cover-More</option>
                      <option value="9">AJ Insurance</option>
                      <option value="11">demo</option>
                      <option value="13">Test M</option>
                    </select>
                  </div>
                </div>
                <div className="data-block mb-3">
                  <div className="mb-2">
                    <i className="fa fa-hashtag text-primary font-size-20 mr-10"></i>
                    <span>PO No. 403</span>
                  </div>
                  <div>
                    <i className="fa fa-map-marker text-primary font-size-20"></i>
                    <span>25 Test Street,Queensland,4000</span>
                  </div>
                </div>
                <div className="data-block mb-3">
                  <i className="fa fa-envelope text-primary font-size-20 mr-10"></i>
                  <Link to="/">Demoauto@fiiviq.com</Link>
                </div>
                <div className="data-block mb-3">
                  <i className="fa fa-phone text-primary font-size-20 mr-10"></i>
                </div>
              </div>
                <div className="draggable-table mb-5">
                  <BootstrapTable
                        bootstrap4
                        keyField="id"
                        data={ labourProducts }
                        columns={ labourColumns }
                    />
                  </div>
                <div className="comment-total-block d-flex align-end">
                <div className="comment-block">
                  <label>Delivery Comments:</label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                />
                </div>
                <div className="form-outer">
                    <Form.Group className="form-group d-flex align-center mb-3 no-wrap disable">
                        <label>Sub Total ₹</label>
                        <span className="value">8.00</span>
                    </Form.Group>
                    <Form.Group className="form-group d-flex align-center mb-3 no-wrap">
                        <label>Freight ₹</label>
                        <Form.Control type="text" />
                    </Form.Group>
                    <Form.Group className="form-group d-flex align-center mb-3 no-wrap disable">
                        <label>GST ₹</label>
                        <span className="value">0.00</span>
                    </Form.Group>
                    <Form.Group className="form-group d-flex align-center mb-3 no-wrap disable">
                        <label className="total-amount">TOTAL ₹</label>
                        <span className="value total-amount">80.00</span>
                    </Form.Group>
                  </div>
              </div>
              </div>
            </div>
            <div className="order-detail-outer mb-4">
              <div className="order-for-job">
                <div className="title-block">
                  <h4>SUPPLIER 1</h4>
                </div>
                <div className="data-block-wrapper d-flex">
                <div className="data-block d-flex no-wrap mb-3">
                  <i className="fa fa-info-circle text-primary font-size-20 mr-10"></i>
                  <div className="select-block custom-normal-select">
                    <label>Branch Name</label>
                    <select>
                      <option value="">Select</option>
                      <option value="2">Cover-More</option>
                      <option value="9">AJ Insurance</option>
                      <option value="11">demo</option>
                      <option value="13">Test M</option>
                    </select>
                  </div>
                </div>
                <div className="data-block mb-3">
                  <div className="mb-2">
                    <i className="fa fa-hashtag text-primary font-size-20 mr-10"></i>
                    <span>PO No. 403</span>
                  </div>
                  <div>
                    <i className="fa fa-map-marker text-primary font-size-20"></i>
                    <span>25 Test Street,Queensland,4000</span>
                  </div>
                </div>
                <div className="data-block mb-3">
                  <i className="fa fa-envelope text-primary font-size-20 mr-10"></i>
                  <Link to="/">Demoauto@fiiviq.com</Link>
                </div>
                <div className="data-block mb-3">
                  <i className="fa fa-phone text-primary font-size-20 mr-10"></i>
                </div>
              </div>
                <div className="draggable-table mb-5">
                  <BootstrapTable
                        bootstrap4
                        keyField="id"
                        data={ labourProducts }
                        columns={ labourColumns }
                    />
                  </div>
                <div className="comment-total-block d-flex align-end">
                <div className="comment-block">
                  <label>Delivery Comments:</label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                />
                </div>
                <div className="form-outer">
                    <Form.Group className="form-group d-flex align-center mb-3 no-wrap disable">
                        <label>Sub Total ₹</label>
                        <span className="value">8.00</span>
                    </Form.Group>
                    <Form.Group className="form-group d-flex align-center mb-3 no-wrap">
                        <label>Freight ₹</label>
                        <Form.Control type="text" />
                    </Form.Group>
                    <Form.Group className="form-group d-flex align-center mb-3 no-wrap disable">
                        <label>GST ₹</label>
                        <span className="value">0.00</span>
                    </Form.Group>
                    <Form.Group className="form-group d-flex align-center mb-3 no-wrap disable">
                        <label className="total-amount">TOTAL ₹</label>
                        <span className="value total-amount">80.00</span>
                    </Form.Group>
                  </div>
              </div>
              </div>
            </div>
          </>
          )}
          {createdFor === "Warehouse" && (
            <div className="order-detail-outer ">
              <div className="order-for-warehouse">
                <div className="draggable-table mb-5">
                  <BootstrapTable bootstrap4 keyField="id"
                        data={ allProducts }
                        columns={ columns }
                    >
                    </BootstrapTable>
                  </div>
                <div className="comment-total-block d-flex align-end">
                  <div className="comment-block">
                    <label>Delivery Comments:</label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                  />
                  </div>
                  <div className="form-outer">
                      <Form.Group className="form-group d-flex align-center mb-3 no-wrap disable">
                          <label>Sub Total ₹</label>
                          <Form.Control type="text" disabled />
                      </Form.Group>
                      <Form.Group className="form-group d-flex align-center mb-3 no-wrap">
                          <label>Freight ₹</label>
                          <Form.Control type="text" />
                      </Form.Group>
                      <Form.Group className="form-group d-flex align-center mb-3 no-wrap disable">
                          <label>GST ₹</label>
                          <Form.Control type="text" disabled />
                      </Form.Group>
                      <Form.Group className="form-group d-flex align-center mb-3 no-wrap disable">
                          <label className="total-amount">TOTAL ₹</label>
                          <Form.Control type="text" disabled />
                      </Form.Group>
                    </div>
                </div>
              </div>
            </div>
          )}
         </div>
      </div>
    </>
  );
};

export default PurchasesCreate;
