import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  EditorState,
  Modifier,
  convertToRaw,
  ContentState,
  convertFromHTML,
  stateToHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { SAVE_JOBEMAILTEMPLATE } from "../../store/commonApiStore/actionTypes";
import { useNavigate } from "react-router-dom";

const SendEmailModal = ({
  emailModalShow,
  handleEmailModalClose,
  emailTemplateData,
  getEmailForEmailModal,
  shareAdditionalDetailsEmail,
  send_page,
  setIsLoading,
}) => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const getResponseFromApi = useSelector(
    (state) => state.CommonApiStoreReducer
  );

  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  let workshop_id = window.atob(storedData.workshop_id);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [emailTemplateValidated, setEmailTemplateValidated] = useState(false);
  let email = getEmailForEmailModal ? getEmailForEmailModal.email : "";
  const [tempEmailValue, setTempEmailValue] = useState({});
  const [emailValue, setEmailValue] = useState({
    template: "",
    send_to: email ? email : "",
    subject: "",
    message_body: "",
  });
  useEffect(() => {
    setEmailValue({ ...emailValue, send_to: email });
  }, [email]);

  let jobemailData = emailTemplateData.jobEmailData
    ? emailTemplateData.jobEmailData
    : [];
  function handleSubmitEmailTemplateModalForm(e) {
    e.preventDefault();
    setEmailTemplateValidated(true);
    if (
      emailValue.template === "" ||
      emailValue.send_to === "" ||
      emailValue.subject === "" ||
      emailValue.message_body === ""
    ) {
      return false;
    }
    setIsLoading(true);
    let emailFormData = {};
    emailFormData.workshop_id = workshop_id;
    emailFormData.job_id = shareAdditionalDetailsEmail.job_id;
    emailFormData.template_id = emailValue.template;
    emailFormData.send_method = shareAdditionalDetailsEmail.send_method;
    emailFormData.table = shareAdditionalDetailsEmail.table;
    if (shareAdditionalDetailsEmail.sendType == "") {
      if (shareAdditionalDetailsEmail.job_id.length == 1) {
        emailFormData.subject = emailValue.subject ? emailValue.subject : "";
        emailFormData.to = emailValue.send_to ? emailValue.send_to : "";
        emailFormData.message = emailValue.message_body
          ? emailValue.message_body
          : "";
      }
    } else {
      emailFormData.send_type = shareAdditionalDetailsEmail.sendType
        ? shareAdditionalDetailsEmail.sendType
        : "";
    }
    dispatch({
      type: SAVE_JOBEMAILTEMPLATE,
      payload: emailFormData,
    });
  }
  useEffect(() => {
    let saveDataResponse = getResponseFromApi.jobEmailSaveData
      ? getResponseFromApi.jobEmailSaveData
      : "";
    if (saveDataResponse !== "") {
      setIsLoading(false);
      if (send_page == "job_details") {
        navigate(
          `/job_detail/${
            shareAdditionalDetailsEmail.job_id
              ? shareAdditionalDetailsEmail.job_id[0]
              : ""
          }`
        );
        handleEmailModalClose();
      } else if (send_page == "job_list") {
        handleEmailModalClose();

        // } else if (send_page == "quote") {
        //   navigate("/");
        // } else if (send_page == "quote_list") {
        //   navigate("/");
        // } else if (send_page == "lead") {
        //   navigate("/");
        // } else if (send_page == "quote") {
        //   navigate("/");
      } else {
        navigate("/");
      }
    }
  }, [getResponseFromApi]);
  const getEmailTemplate = (e) => {
    let index = e.target.selectedIndex;
    let optionElement = e.target.childNodes[index];
    let emailTemplateID = e.target.value;
    let subject = optionElement.getAttribute("data-id")
      ? optionElement.getAttribute("data-id")
      : "";
    let message_body = optionElement.getAttribute("data-val")
      ? optionElement.getAttribute("data-val")
      : "";
    let { contentBlocks, entityMap } = htmlToDraft(message_body);
    setTempEmailValue({
      template: emailTemplateID ? emailTemplateID : "",
      send_to: emailValue.send_to ? emailValue.send_to : "",
      subject: subject ? subject : "",
      message_body: message_body ? message_body : "",
    });
    let emailContentState = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap()
    );
    onEditorStateChange(
      EditorState.push(editorState, emailContentState, "insert-characters")
    );
  };
  const handleEmailChange = (e) => {
    setEmailValue({ ...emailValue, [e.target.name]: e.target.value });
  };
  const onEditorStateChange = (editorState) => {
    let draftToHtmlData = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setEmailValue({
      ...emailValue,
      message_body: draftToHtmlData ? draftToHtmlData : "",
    });
    setEditorState(editorState);
  };
  useEffect(() => {
    setEmailValue({
      ...emailValue,
      template: tempEmailValue.template ? tempEmailValue.template : "",
      subject: tempEmailValue.subject ? tempEmailValue.subject : "",
      message_body: tempEmailValue.message_body
        ? tempEmailValue.message_body
        : "",
    });
  }, [tempEmailValue]);

  return (
    <Modal
      className="preview-dialog"
      size="lg"
      show={emailModalShow}
      onHide={handleEmailModalClose}
    >
      <Modal.Header>
        <Modal.Title className="d-flex justify-space-between">
          <span>Send email</span>
          <Button
            type="button"
            className="close-btn"
            onClick={handleEmailModalClose}
          >
            <i className="la la-times"></i>
          </Button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          noValidate
          validated={emailTemplateValidated}
          id="emailTemplateForm"
        >
          <div className="column-wrapper d-flex">
            <div className="left-column">
              <div className="custom-normal-select mb-3">
                <Form.Label>Template *</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  value={emailValue.template ? emailValue.template : ""}
                  required
                  name="template"
                  id="template"
                  onChange={getEmailTemplate}
                >
                  <option key={0} value="" data-id="" data-val="">
                    Select Template
                  </option>
                  {jobemailData.map((val) => {
                    return (
                      <option
                        key={val.id}
                        value={val.id}
                        data-id={val.subject ? val.subject : ""}
                        data-val={val.email_txt ? val.email_txt : ""}
                      >
                        {val.name ? val.name : ""}
                      </option>
                    );
                  })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please Select Template
                </Form.Control.Feedback>
              </div>
              <Form.Group className="form-group mb-3">
                <Form.Label>Email Address *</Form.Label>
                <Form.Control
                  type="email"
                  value={emailValue.send_to ? emailValue.send_to : ""}
                  name="send_to"
                  id="email"
                  onChange={handleEmailChange}
                  required
                />
                <p>
                  EX : For multiple user email test@fiiviq.com,test2@fiiviq.com
                </p>
                <Form.Control.Feedback type="invalid">
                  Please Enter Email Address
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-group mb-3">
                <Form.Label>Subject *</Form.Label>
                <Form.Control
                  type="text"
                  value={emailValue.subject ? emailValue.subject : ""}
                  name="subject"
                  id="subject_brodcast"
                  onChange={handleEmailChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Subject
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-group mb-3">
                <Form.Label>Message *</Form.Label>
                {/*<Form.Control as="textarea" value={emailValue.message_body ? emailValue.message_body : ""} name="message_body" id="message_body" onChange={handleEmailChange} rows={8} required/>
                            <Form.Control.Feedback type="invalid">Please Enter Message</Form.Control.Feedback>*/}
                <Editor
                  name="message_body"
                  id="message_body"
                  editorState={editorState}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  onEditorStateChange={onEditorStateChange}
                />
              </Form.Group>
            </div>
            <div className="right-column">
              <span className="panel-heading">PREVIEW</span>
              <div className="preview-outer">
                <div className="preview-wrapper">
                  <span className="panel-heading">EMAIL PREVIEW</span>
                  <div className="panel-body">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: emailValue.message_body
                          ? emailValue.message_body
                          : "",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          className="btn-outline rounded-btn"
          onClick={handleEmailModalClose}
        >
          Cancel
        </Button>
        <Button
          type="button"
          onClick={handleSubmitEmailTemplateModalForm}
          className="rounded-btn"
          variant="primary"
        >
          Send
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default SendEmailModal;
