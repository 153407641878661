import * as ACTION from "./actionTypes";

const initialState = {
  raisedInvoices: {
    loading: false,
    error: "",
    list: [],
    count: 0,
    invoiceCount: 0,
    saleInvoiceCount: 0,
    invoiceDeleteStatus: "",
  },
  completedInvoices: {
    list: [],
    count: 0,
    loading: false,
    invoiceCount: 0,
    saleInvoiceCount: 0,
    invoiceDeleteStatus: "",
    error: "",
  },
  invoicePayments: {
    list: [],
    count: 0,
    loading: false,
    error: "",
  },
  invoiceCredits: {
    list: [],
    count: 0,
    loading: false,
    error: "",
  },
};

const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.GET_RAISED_INVOICES_COUNT:
      return {
        ...state,
        raisedInvoices: {
          ...state.raisedInvoices,
          loading: true,
        },
      };
    case ACTION.GET_RAISED_INVOICES_COUNT_SUCCESS:
      return {
        ...state,
        raisedInvoices: {
          ...state.raisedInvoices,
          ...action.payload,
          loading: false,
        },
      };
    case ACTION.GET_RAISED_INVOICES_COUNT_FAIL:
      return {
        ...state,
        raisedInvoices: {
          ...state.raisedInvoices,
          error: action.payload,
          loading: false,
        },
      };
    
      case ACTION.GET_RAISED_INVOICES:
      return {
        ...state,
        raisedInvoices: {
          ...state.raisedInvoices,
          loading: true,
        },
      };
    case ACTION.GET_RAISED_INVOICES_SUCCESS:
      return {
        ...state,
        raisedInvoices: {
          ...state.raisedInvoices,
          ...action.payload,
          loading: false,
        },
      };
    case ACTION.GET_RAISED_INVOICES_FAIL:
      return {
        ...state,
        raisedInvoices: {
          ...state.raisedInvoices,
          error: action.payload,
          loading: false,
        },
      };
    case ACTION.GET_COMPLETED_INVOICES: {
      return {
        ...state,
        completedInvoices: {
          ...state.completedInvoices,
          loading: true,
        },
      };
    }
    case ACTION.GET_COMPLETED_INVOICES_SUCCESS:
      return {
        ...state,
        completedInvoices: {
          ...state.completedInvoices,
          ...action.payload,
          loading: false,
        },
      };

    case ACTION.GET_COMPLETED_INVOICES_FAIL:
      return {
        ...state,
        completedInvoices: {
          ...state.completedInvoices,
          error: action.payload,
          loading: false,
        },
      };
    case ACTION.GET_COMPLETED_INVOICES_COUNT: {
      return {
        ...state,
        completedInvoices: {
          ...state.completedInvoices,
          loading: true,
        },
      };
    }
    case ACTION.GET_COMPLETED_INVOICES_COUNT_SUCCESS:
      return {
        ...state,
        completedInvoices: {
          ...state.completedInvoices,
          ...action.payload,
          loading: false,
        },
      };

    case ACTION.GET_COMPLETED_INVOICES_COUNT_FAIL:
      return {
        ...state,
        completedInvoices: {
          ...state.completedInvoices,
          error: action.payload,
          loading: false,
        },
      };
    case ACTION.GET_INVOICE_PAYMENTS: {
      return {
        ...state,
        invoicePayments: {
          ...state.invoicePayments,
          loading: true,
        },
      };
    }
    case ACTION.GET_INVOICE_PAYMENTS_SUCCESS:
      return {
        ...state,
        invoicePayments: {
          ...state.invoicePayments,
          ...action.payload,
          loading: false,
        },
      };

    case ACTION.GET_INVOICE_PAYMENTS_FAIL:
      return {
        ...state,
        invoicePayments: {
          ...state.invoicePayments,
          error: action.payload,
          loading: false,
        },
      };

    case ACTION.GET_INVOICE_CREDITS: {
      return {
        ...state,
        invoiceCredits: {
          ...state.invoiceCredits,
          loading: true,
        },
      };
    }
    case ACTION.GET_INVOICE_CREDITS_SUCCESS:
      return {
        ...state,
        invoiceCredits: {
          ...state.invoiceCredits,
          ...action.payload,
          loading: false,
        },
      };

    case ACTION.GET_INVOICE_CREDITS_FAIL:
      return {
        ...state,
        invoiceCredits: {
          ...state.invoiceCredits,
          error: action.payload,
          loading: false,
        },
      };
    case ACTION.DELETE_RAISED_INVOICES:
      return {
        ...state,
        raisedInvoices: {
          ...state.raisedInvoices,
          loading: false,
          invoiceDeleteStatus: "started",
        },
      };
    case ACTION.DELETE_RAISED_INVOICES_SUCCESS:
      return {
        ...state,
        raisedInvoices: {
          ...state.raisedInvoices,
          loading: true,
          invoiceDeleteStatus: "success",
          action_type: action.payload.action_type,
        },
      };
    case ACTION.DELETE_RAISED_INVOICES_FAIL:
      return {
        ...state,
        raisedInvoices: {
          ...state.raisedInvoices,
          loading: false,
          invoiceDeleteStatus: "failed",
          action_type: action.payload,
        },
      };
    case ACTION.DELETE_COMPLETED_INVOICES:
      return {
        ...state,
        completedInvoices: {
          ...state.completedInvoices,
          loading: false,
          invoiceDeleteStatus: "started",
        },
      };
    case ACTION.DELETE_COMPLETED_INVOICES_SUCCESS:
      return {
        ...state,
        completedInvoices: {
          ...state.completedInvoices,
          loading: true,
          invoiceDeleteStatus: "success",
          action_type: action.payload.action_type,
        },
      };
    case ACTION.DELETE_COMPLETED_INVOICES_FAIL:
      return {
        ...state,
        completedInvoices: {
          ...state.completedInvoices,
          loading: false,
          invoiceDeleteStatus: "failed",
          action_type: action.payload,
        },
      };

    case ACTION.GET_RECEIPT_DETAILS:
      return {
        ...state,
        recieptDetail: {
          loading: true,
          data: null,
        },
      };
    case ACTION.GET_RECEIPT_DETAILS_SUCCESS:
      return {
        ...state,
        recieptDetail: {
          loading: false,
          data: action.payload,
        },
      };
    case ACTION.GET_RECEIPT_DETAILS_FAIL:
      return {
        ...state,
        recieptDetail: {
          loading: false,
          data: null,
        },
      };
    case ACTION.CLEAR_RECEIPT_DETAILS:
      return {
        ...state,
        recieptDetail: {
          loading: false,
          data: null,
        },
      };
    case ACTION.DELETE_PAYMENT_INVOICES:
      return {
        ...state,
        invoicePayments: {
          ...state.invoicePayments,
          loading: false,
          invoiceDeleteStatus: "started",
        },
      };
    case ACTION.DELETE_PAYMENT_INVOICES_SUCCESS:
      return {
        ...state,
        invoicePayments: {
          ...state.invoicePayments,
          loading: true,
          invoiceDeleteStatus: "success",
          action_type: action.payload.action_type,
        },
      };
    case ACTION.DELETE_PAYMENT_INVOICES_FAIL:
      return {
        ...state,
        invoicePayments: {
          ...state.invoicePayments,
          loading: false,
          invoiceDeleteStatus: "failed",
          action_type: action.payload,
        },
      };
      case ACTION.DELETE_CREDIT_INVOICES:
      return {
        ...state,
        invoiceCredits: {
          ...state.invoiceCredits,
          loading: false,
          invoiceDeleteStatus: "started",
        },
      };
    case ACTION.DELETE_CREDIT_INVOICES_SUCCESS:
      return {
        ...state,
        invoiceCredits: {
          ...state.invoiceCredits,
          loading: true,
          invoiceDeleteStatus: "success",
          action_type: action.payload.action_type,
        },
      };
    case ACTION.DELETE_CREDIT_INVOICES_FAIL:
      return {
        ...state,
        invoiceCredits: {
          ...state.invoiceCredits,
          loading: false,
          invoiceDeleteStatus: "failed",
          action_type: action.payload,
        },
      };
    default:
      return state;
  }
};

export default invoiceReducer;
