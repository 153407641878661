import {
  GET_JOBDEPARTMENT_LIST,
  GET_JOBDEPARTMENT_LIST_SUCCESS,
  GET_JOBDEPARTMENT_LIST_FAIL,
  GET_JOBTYPE_LIST,
  GET_JOBTYPE_LIST_SUCCESS,
  GET_JOBTYPE_LIST_FAIL,
  GET_JOBCANNED_LIST,
  GET_JOBCANNED_LIST_SUCCESS,
  GET_JOBCANNED_LIST_FAIL,
  GET_JOBSMSTEMPLATE_LIST,
  GET_JOBSMSTEMPLATE_LIST_SUCCESS,
  GET_JOBSMSTEMPLATE_LIST_FAIL,
  GET_JOBEMAILTEMPLATE_LIST,
  GET_JOBEMAILTEMPLATE_LIST_SUCCESS,
  GET_JOBEMAILTEMPLATE_LIST_FAIL,
  SAVE_JOBEMAILTEMPLATE,
  SAVE_JOBEMAILTEMPLATE_SUCCESS,
  SAVE_JOBEMAILTEMPLATE_FAIL,
  SAVE_JOBSMSTEMPLATE,
  SAVE_JOBSMSTEMPLATE_SUCCESS,
  SAVE_JOBSMSTEMPLATE_FAIL,
  UPDATE_WORKSHOP_OTHER_DETAIL,
  UPDATE_WORKSHOP_OTHER_DETAIL_SUCCESS,
  UPDATE_WORKSHOP_OTHER_DETAIL_FAIL,
  GET_LABOUR_CHARGES_LIST,
  GET_LABOUR_CHARGES_LIST_SUCCESS,
  GET_LABOUR_CHARGES_LIST_FAIL,
  GET_COUNTRY,
  GET_COUNTRY_SUCCESS,
  GET_COUNTRY_FAIL,
  GET_STATE,
  GET_STATE_SUCCESS,
  GET_STATE_FAIL,
  GET_VEHICLES,
  GET_VEHICLES_SUCCESS,
  GET_VEHICLES_FAIL,
  GET_VEHICLEMODEL,
  GET_VEHICLEMODEL_SUCCESS,
  GET_VEHICLEMODEL_FAIL,
  GET_VEHICLESERIES,
  GET_VEHICLESERIES_SUCCESS,
  GET_VEHICLESERIES_FAIL,
  GET_WORKSHOP_MARKUP_DETAILS,
  GET_WORKSHOP_MARKUP_DETAILS_SUCCESS,
  GET_WORKSHOP_MARKUP_DETAILS_FAIL,
  GET_WORKSHOP_TAX_DETAILS,
  GET_WORKSHOP_TAX_DETAILS_SUCCESS,
  GET_WORKSHOP_TAX_DETAILS_FAIL,
  GET_STATUS_COLOR,
  GET_STATUS_COLOR_SUCCESS,
  GET_STATUS_COLOR_FAIL,
  SUBMIT_JOB_SMSEMAIL_TEMPLATE,
  SUBMIT_JOB_SMSEMAIL_TEMPLATE_SUCCESS,
  SUBMIT_JOB_SMSEMAIL_TEMPLATE_FAIL,
  EDIT_WORKSHOP_MARKUP_DETAILS_SUCCESS,
  GET_ASSETS,
  GET_ASSETS_SUCCESS,
  GET_ASSETS_FAIL,
  SEARCH_JOBS,
  SEARCH_JOBS_SUCCESS,
  SEARCH_JOBS_FAIL,
  SEARCH_QUOTE_JOBS,
  SEARCH_QUOTE_JOBS_SUCCESS,
  SEARCH_QUOTE_JOBS_FAIL,
  APPLY_CODE,
  APPLY_CODE_SUCCESS,
  APPLY_CODE_FAIL,
  GET_WORKSHOP_TECHNICIAN,
  GET_WORKSHOP_TECHNICIAN_SUCCESS,
  GET_WORKSHOP_TECHNICIAN_FAIL,
  GET_SUPPLIER_INTEGRATION,
  GET_SUPPLIER_INTEGRATION_SUCCESS,
  GET_SUPPLIER_INTEGRATION_FAIL,
} from "./actionTypes";

const initialState = {
  loadingLogins: false,
  message: "",
  error: "",
  jobDepartmentData: null,
  jobTypeData: null,
  cannedData: null,
  jobSmsData: null,
  jobEmailData: null,
  jobEmailSaveData: null,
  jobSmsSaveData: null,
  workshopOtherData: null,
  labourChargesList: null,
  worskhopMarkupData: null,
  country: [],
  state: [],
  vehicles: [],
  vehicleModel: [],
  vehicleSeries: [],
  statusColor: null,
  worskhopTaxData: null,
  smsEmailTemplate: null,
  assetsDetails: null,
  searchJobs: {},
  searchQuoteJobs: {},
  applyCodeData: null,
  Workshops: null,
  colorList: [],
  supplierIntegration: []
};

const CommonApiStoreReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_JOBDEPARTMENT_LIST:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_JOBDEPARTMENT_LIST_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        jobDepartmentData: action.payload,
      };
      break;
    case GET_JOBDEPARTMENT_LIST_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        jobDepartmentData: null,
        error: {
          message: action.payload,
        },
      };
      break;
    case GET_JOBTYPE_LIST:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_JOBTYPE_LIST_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        jobTypeData: action.payload,
      };
      break;
    case GET_JOBTYPE_LIST_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        jobTypeData: null,
        error: {
          message: action.payload,
        },
      };
      break;
    case GET_JOBCANNED_LIST:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_JOBCANNED_LIST_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        cannedData: action.payload,
      };
      break;
    case GET_JOBCANNED_LIST_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        cannedData: null,
        error: {
          message: action.payload,
        },
      };
      break;
    case GET_JOBSMSTEMPLATE_LIST:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_JOBSMSTEMPLATE_LIST_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        jobSmsData: action.payload,
      };
      break;
    case GET_JOBSMSTEMPLATE_LIST_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        jobSmsData: null,
        error: {
          message: action.payload,
        },
      };
      break;
    case GET_JOBEMAILTEMPLATE_LIST:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_JOBEMAILTEMPLATE_LIST_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        jobEmailData: action.payload,
      };
      break;
    case GET_JOBEMAILTEMPLATE_LIST_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        jobEmailData: null,
        error: {
          message: action.payload,
        },
      };
      break;
    case SAVE_JOBEMAILTEMPLATE:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case SAVE_JOBEMAILTEMPLATE_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        jobEmailSaveData: action.payload,
      };
      break;
    case SAVE_JOBEMAILTEMPLATE_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        jobEmailSaveData: null,
        error: {
          message: action.payload,
        },
      };
      break;
    case SAVE_JOBSMSTEMPLATE:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case SAVE_JOBSMSTEMPLATE_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        jobSmsSaveData: action.payload,
      };
      break;
    case SAVE_JOBSMSTEMPLATE_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        jobSmsSaveData: null,
        error: {
          message: action.payload,
        },
      };
      break;
    case UPDATE_WORKSHOP_OTHER_DETAIL:
      state = {
        ...state,
        loadingLogins: true,
        workshopOtherData: null,
      };
      break;
    case UPDATE_WORKSHOP_OTHER_DETAIL_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        workshopOtherData: action.payload,
      };
      break;
    case UPDATE_WORKSHOP_OTHER_DETAIL_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        error: {
          message: action.payload,
        },
        workshopOtherData: null,
      };
      break;
    case GET_LABOUR_CHARGES_LIST:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_LABOUR_CHARGES_LIST_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        labourChargesList: action.payload,
      };
      break;
    case GET_LABOUR_CHARGES_LIST_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        error: {
          message: action.payload,
        },
        labourChargesList: null,
      };
      break;

    case GET_COUNTRY:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_COUNTRY_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        country: action.payload,
      };
      break;
    case GET_COUNTRY_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        country: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_STATE:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_STATE_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        state: action.payload,
      };
      break;
    case GET_STATE_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        state: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_VEHICLES:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_VEHICLES_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        vehicles: action.payload,
      };
      break;
    case GET_VEHICLES_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        vehicles: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_VEHICLEMODEL:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_VEHICLEMODEL_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        vehicleModel: action.payload,
      };
      break;
    case GET_VEHICLEMODEL_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        vehicleModel: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_VEHICLESERIES:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_VEHICLESERIES_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        vehicleSeries: action.payload,
      };
      break;
    case GET_VEHICLESERIES_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        vehicleSeries: [],
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_WORKSHOP_MARKUP_DETAILS:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_WORKSHOP_MARKUP_DETAILS_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        worskhopMarkupData: action.payload,
      };
      break;
    case GET_WORKSHOP_MARKUP_DETAILS_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        worskhopMarkupData: null,
        error: {
          message: action.payload,
        },
      };
      break;
    case EDIT_WORKSHOP_MARKUP_DETAILS_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        worskhopMarkupData: null,
      };
      break;
    case GET_WORKSHOP_TAX_DETAILS:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_WORKSHOP_TAX_DETAILS_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        worskhopTaxData: action.payload,
      };
      break;
    case GET_WORKSHOP_TAX_DETAILS_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        worskhopTaxData: null,
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_STATUS_COLOR:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_STATUS_COLOR_SUCCESS:
      let colourCode = [];

      action.payload.forEach(({ name, color }, i) => {
        colourCode[name] = color;
      });
      state = {
        ...state,
        loadingLogins: false,
        statusColor: colourCode,
      };
      break;
    case GET_STATUS_COLOR_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        statusColor: null,
        error: {
          message: action.payload,
        },
      };
      break;

    case SUBMIT_JOB_SMSEMAIL_TEMPLATE:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case SUBMIT_JOB_SMSEMAIL_TEMPLATE_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        smsEmailTemplate: action.payload,
      };
      break;
    case SUBMIT_JOB_SMSEMAIL_TEMPLATE_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        smsEmailTemplate: null,
        error: {
          message: action.payload,
        },
      };
      break;
    case GET_ASSETS:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_ASSETS_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        assetsDetails: action.payload,
      };
      break;
    case GET_ASSETS_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        assetsDetails: null,
        error: {
          message: action.payload,
        },
      };
      break;

    case SEARCH_JOBS:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case SEARCH_JOBS_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        searchJobs: action.payload,
      };
      break;
    case SEARCH_JOBS_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        searchJobs: {},
        error: {
          message: action.payload,
        },
      };
      break;
    case SEARCH_QUOTE_JOBS:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case SEARCH_QUOTE_JOBS_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        searchQuoteJobs: action.payload,
      };
      break;
    case SEARCH_QUOTE_JOBS_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        searchQuoteJobs: {},
        error: {
          message: action.payload,
        },
      };
      break;

    case APPLY_CODE:
        state = {
          ...state,
        };
        break;
    case APPLY_CODE_SUCCESS:
        state = {
          ...state,
          applyCodeData: action.payload,
        };
        break;
    case APPLY_CODE_FAIL:
        state = {
          ...state,
          applyCodeData: null,
          error: {
            message: action.payload,
          },
        };
        break;
    case GET_WORKSHOP_TECHNICIAN:
        state = {
          ...state,
        };
        break;
    case GET_WORKSHOP_TECHNICIAN_SUCCESS:
        state = {
          ...state,
          Workshops: action.payload,
        };
        break;
    case GET_WORKSHOP_TECHNICIAN_FAIL:
        state = {
          ...state,
          Workshops: null,
          error: {
            message: action.payload,
          },
        };
        break;

    case GET_SUPPLIER_INTEGRATION:
        state = {
          ...state,
        };
        break;
    case GET_SUPPLIER_INTEGRATION_SUCCESS:
        state = {
          ...state,
          supplierIntegration: action.payload,
        };
        break;
    case GET_SUPPLIER_INTEGRATION_FAIL:
        state = {
          ...state,
          supplierIntegration: [],
          error: {
            message: action.payload,
          },
        };
        break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default CommonApiStoreReducer;
