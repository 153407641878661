import Button from "@restart/ui/esm/Button";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { GET_TIMER_DETAILS } from "../../../store/scheduler/actionTypes"
import moment from "moment";
import { useStopwatch } from "react-timer-hook";
import { UPDATE_JOB_TIMER,UPDATE_VEHICLE } from '../../../store/jobDetails/actionTypes';
import { showSuccess } from "../../../utils/alertMessage";

const Timer = ({timerShow,handleTimerClose,jobIdData,setUpdateCalendar}) => {

    // const [secondsForTimer,setSecondsForTimer] = useState(0);
    const [fontColor,setFontColor] = useState();
    const [timerAction,setTimerAction] = useState(false);
    const [vehicleFlag,setVehicleFlag] = useState(false);
    const [employeeTimerName,setEmployeeTimerName] = useState();
    const [timerStatus,setTimerStatus] = useState('IDLE');
    const [timerTotalTime,setTimerTotalTime] = useState();
    const [startDisable,setStartDisable] = useState(false);
    const [pauseDisable,setPauseDisable] = useState(true);
    const [stopDisable,setStopDisable] = useState(true);
    const [startTimeState,setStartTimeState] = useState('start');

    const currentUtcTime = moment(new Date()).utc().format("YYYY-MM-DD HH:mm:ss");

    const stopwatchOffset = new Date();
    stopwatchOffset.setSeconds(stopwatchOffset.getSeconds());    
    const {
        seconds,
        minutes,
        hours,
        // isRunning,
        start,pause,reset
    } = useStopwatch({ 
        autoStart: false,
        offsetTimestamp: stopwatchOffset
    });

    const hourTime = hours < 10 ? `0${hours}` : `${hours}`;
    const secondTime = seconds < 10 ? `0${seconds}` : `${seconds}`;
    const minuteTime = minutes < 10 ? `0${minutes}` : `${minutes}`;

    const dispatch = useDispatch(); // Dispatch variable
    const timerDetails = useSelector((state) => state.SchedulerReducer.getTimerDetails);
    const updateJobTimer  = useSelector((state) => state.JobDetails.jobTimerData);

    useEffect(() => {
        if(updateJobTimer!== null && updateJobTimer !== undefined && timerAction === true){
            var job_number = updateJobTimer?.JobSchedules?.job_number || null;
            if(timerStatus === "Clocked In"){
                showSuccess("Job (#"+job_number+") timer start.");
            }
            if(timerStatus === "Clocked Out"){
                var vehicle_id = updateJobTimer?.JobSchedules?.vehicle_id || null;
                if(vehicle_id !== null && vehicleFlag === false){
                    dispatch({
                        type:UPDATE_VEHICLE,
                        payload:{
                            id:vehicle_id,
                            is_sent:"2"
                        }
                    })
                    setVehicleFlag(true);
                }
                showSuccess("Job (#"+job_number+") timer stop.");
            }
            if(timerStatus === "On Hold"){
                showSuccess("Job (#"+job_number+") timer pause.");
            }
            setTimerAction(false);
            handleTimerClose();
            setUpdateCalendar(true);
        }
    },[updateJobTimer])
    
    useEffect(() => {
        if(timerDetails !== null & timerDetails !== undefined){
            var firstname = timerDetails?.[0]?.user?.firstname || '';
            var lastname = timerDetails?.[0]?.user?.lastname || '';
            setEmployeeTimerName(firstname+" "+lastname);
            var jobTimerDetails = timerDetails?.[0] || null;
            if(jobTimerDetails !== null){
                
                if(jobTimerDetails.job_stage === "Inprogress" || jobTimerDetails.job_stage === "Completed"){
                    var totalWorkingTime = jobTimerDetails.timer_total_time;
                    var currentTime = moment(new Date()).utc().format("YYYY-MM-DD HH:mm:ss");
                    
                    var timerStartTime = moment(new Date(jobTimerDetails.timer_start_time)).format("YYYY-MM-DD HH:mm:ss");
                    
                    if(jobTimerDetails.timer_status === "pause" || jobTimerDetails.timer_status === "stop"){
                        setTimerTotalTime(totalWorkingTime);
                        // reset(stopwatchOffset.setSeconds(stopwatchOffset.getSeconds()+ secondJob),false);
                    }else{
                        
                        var a = moment(currentTime); //now
                        var b = moment(timerStartTime);
                        var secondJob = (a.diff(b,'seconds'));
                        
                        // setSecondsForTimer(secondJob)
                        var timer_total_time = new Date(secondJob * 1000).toISOString().substr(11, 8);

                        if(totalWorkingTime){
                            secondJob = secondJob + (moment.duration(totalWorkingTime).asSeconds())
                        }

                        if(jobTimerDetails.timer_status === "start"){
                            reset(stopwatchOffset.setSeconds(stopwatchOffset.getSeconds()+ secondJob),true);
                        }else{
                            setTimerTotalTime(timer_total_time);
                        }
                    }
                    
                    if(jobTimerDetails.timer_status === "pause"){
                        setTimerStatus('On Hold');
                        setFontColor('#CC931A')
                        setStartDisable(false);
                        setPauseDisable(true);
                        setStopDisable(false);
                        setStartTimeState('resume');
                    }else if(jobTimerDetails.timer_status === "stop"){
                        setTimerStatus('Clocked Out');
                        setFontColor('#C74134')
                        setStartDisable(true);
                        setPauseDisable(true);
                        setStopDisable(true);
                        setStartTimeState('start');
                    }else if(jobTimerDetails.timer_status === "start" || jobTimerDetails.timer_status === "resume"){
                        setTimerStatus('Clocked In');
                        setFontColor('#3FBF6A')
                        setStartDisable(true);
                        setPauseDisable(false);
                        setStopDisable(false);
                        setStartTimeState('start');
                    }else{
                        setStartDisable(false);
                        setPauseDisable(true);
                        setStopDisable(true);
                        setTimerStatus('');
                        setStartTimeState('start');
                        setFontColor('')
                    }
                }else{
                    if(jobTimerDetails.timer_start_time === null){
                        setTimerStatus('IDLE');
                    }
                    setTimerTotalTime('');
                    setStartDisable(false);
                    setPauseDisable(true);
                    setStopDisable(true);
                }
            }
        }
    },[timerDetails])

    useEffect(()=>{
        setTimerStatus('IDLE');
        setStartDisable(true);
        setPauseDisable(false);
        setStopDisable(false);
        setStartTimeState('start')
        setFontColor('')
        pause();
        setTimerTotalTime('');
    },[])

    useEffect(()=> {
        if(timerShow === true){
            
            dispatch({
                type:GET_TIMER_DETAILS,
                payload:{
                    get:"all",
                    conditions:{
                        "JobSchedules.id":jobIdData
                    },
                    contain:{
                        "Users.EmployeesDetails":{
                            fields:["id","user_id","workshop_id"]
                        }
                    },
                    fields:[
                        "id",
                        "workshop_id",
                        "job_name",
                        "job_number",
                        "user_id",
                        "timer_total_time",
                        "Users.id",
                        "Users.workshop_id",
                        "Users.firstname",
                        "Users.lastname",
                        "timer_status",
                        "clocked_in_time",
                        "clocked_off_time",
                        "job_stage",
                        "timer_start_time"
                    ]
                }
            })
        }
    },[jobIdData])

    useEffect(() => {
    //     if(isRunning){
            setTimerTotalTime(hourTime+":"+minuteTime+":"+secondTime)
    //     }
    },[hourTime,minuteTime,secondTime])

    const handleStartJob = () => {
        if(startTimeState === "resume"){
            dispatch({
                type:UPDATE_JOB_TIMER,
                payload:{
                    id:jobIdData,
                    timer_status:"resume",
                    timer_start_time:currentUtcTime,
                    job_stage:"Inprogress",
                    job_status:"InProgress",
                }
            })
        }else{
            dispatch({
                type:UPDATE_JOB_TIMER,
                payload:{
                    id:jobIdData,
                    timer_status:"start",
                    timer_start_time:currentUtcTime,
                    job_stage:"Inprogress",
                    job_status:"InProgress",
                    clocked_in_time:currentUtcTime
                }
            })
            start();
        }
        setTimerStatus('Clocked In');
        setFontColor('#3FBF6A')
        setStartDisable(true);
        setPauseDisable(false);
        setStopDisable(false);
        setTimerAction(true);
    }

    const handlePauseJob = () => {
        pause();
        setTimerStatus('On Hold');
        setFontColor('#CC931A')
        setStartTimeState('resume');
        setStartDisable(false);
        setPauseDisable(true);
        setStopDisable(false);
        dispatch({
            type:UPDATE_JOB_TIMER,
            payload:{
                id:jobIdData,
                timer_status:"pause",
                job_stage:"Inprogress",
                job_status:"OnHold",
                timer_total_time:timerTotalTime
            }
        })
        setTimerAction(true);
    }

    const handleRemoveJob = () => {
        pause();
        setTimerStatus('Clocked Out');
        setFontColor('#C74134')
        setStartDisable(true);
        setPauseDisable(true);
        setStopDisable(true);
        dispatch({
            type:UPDATE_JOB_TIMER,
            payload:{
                id:jobIdData,
                timer_status: "stop",
                job_status: "ReadyForInvoice",
                job_stage: "Completed",
                timer_total_time:timerTotalTime,
                labour_time: timerTotalTime,
                clocked_off_time: currentUtcTime
            }
        })
        setTimerAction(true);
    }

    return (
        <>
            <Modal
                className="time-clock-dialog"
                size="sm"
                show={timerShow}
                onHide={handleTimerClose}
                centered
            >
                <Modal.Header>
                    <Modal.Title className="d-flex justify-space-between">
                        <div>
                            <span className="name">Timer</span>
                        </div>
                        <Button type="button" className="close-btn" onClick={handleTimerClose}><i className="la la-times"></i> </Button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="content-block">
                        <div className="top-block d-flex justify-space-between">
                            <span className="panel-heading">{employeeTimerName}</span>
                            <span className="panel-heading">{timerTotalTime}</span>
                            <span className="panel-heading" style={{color:`${fontColor}`}}>{timerStatus}</span>
                        </div>
                        <div className="d-flex checkin-checkout-btn-block width-100">
                            <div className="btn-wrapper">
                                <div className="d-flex justify-space-between">
                                    <Button type="button" variant="success" className={"btn btn-success rounded-btn start " + (startDisable ? 'disabled' : '')} onClick={()=>handleStartJob()}>Clock In</Button>
                                    <Button type="button" variant="warning" className={"btn btn-warning rounded-btn pause " + (pauseDisable ? 'disabled' : '')} onClick={()=>handlePauseJob()}><i className="la la-pause"></i></Button>
                                    <Button type="button" variant="danger" className={"btn btn-danger rounded-btn remove " + (stopDisable ? 'disabled' : '')} onClick={()=>handleRemoveJob()}>Clock Out</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default Timer;
