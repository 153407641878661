import {
  GET_NOTIFICATION_LIST,
  GET_NOTIFICATION_LIST_SUCCESS,
  GET_NOTIFICATION_LIST_FAIL
} from "./actionTypes";

export const getNotificationList = () => {
  return {
    type: GET_NOTIFICATION_LIST,
  };
};

export const getNotificationListSuccess = (data) => {
  return {
    type: GET_NOTIFICATION_LIST_SUCCESS,
    payload: data,
  };
};

export const getNotificationListFail = (error) => {
  return {
    type: GET_NOTIFICATION_LIST_FAIL,
    payload: error,
  };
};
