import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Settings = () => {
  const [title] = useState("5iQ | Settings");

  useEffect(() => {
    document.title = title;
  }, [title]);
  
  return (
    <div className="right-section-wrapper">
      <div className="table-header mb-4">
        <div className="top-block">
          <div className="table-title position-relative">
            <i className="fa fa-gears text-primary me-2 font-size-24"></i>
            <span>System Setting</span>
          </div>
        </div>
      </div>
      <div className="settings-navigation-list">
        <div className="row">
          <div className="col-md-4 col-sm-6 mb-3">
            <div className="card h-100">
              <h5 className="card-header font-size-16">Company</h5>
              <div className="card-body">
                <Link
                  to="/settings_company_detail"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Company Details
                </Link>
                <Link
                  to="/settings_defaults"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Defaults
                </Link>
                <Link
                  to="/settings_security_group"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Security Groups
                </Link>
                <Link
                  to="/"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  3rd Party | Company
                </Link>
                <Link
                  to="/"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Canned Responses
                </Link>
                <Link
                  to="/"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Notifications
                </Link>
                <Link
                  to="/"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Inspection Templates
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-3">
            <div className="card h-100">
              <h5 className="card-header font-size-16">Accounts</h5>
              <div className="card-body">
                <Link
                  to="/"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Chart of Accounts
                </Link>
                <Link
                  to="/"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Tax Codes
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-3">
            <div className="card h-100">
              <h5 className="card-header font-size-16">Integration</h5>
              <div className="card-body">
                <Link
                  to="/"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Accounts
                </Link>
                <Link
                  to="/"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Supplier
                </Link>
                <Link
                  to="/"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Payment
                </Link>
                <Link
                  to="/"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Tyres - Coming Soon
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-3">
            <div className="card h-100">
              <h5 className="card-header font-size-16">Workshop</h5>
              <div className="card-body">
                <Link
                  to="/workshop_bays"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Bay
                </Link>
                <Link
                  to="/statuses"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Statuses
                </Link>
                <Link
                  to="/"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Storage Device
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-3">
            <div className="card h-100">
              <h5 className="card-header font-size-16">Utilities</h5>
              <div className="card-body">
                <Link
                  to="/"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Import
                </Link>
                <Link
                  to="/"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Reminders
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-3">
            <div className="card h-100">
              <h5 className="card-header font-size-16">Subscriptions</h5>
              <div className="card-body">
                <Link
                  to="/"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  Users
                </Link>
                <Link
                  to="/"
                  className="btn btn-primary btn-block font-weight-600 font-size-14 btn-rounded setting-link mb-2"
                >
                  SMS
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;