
export const CHECK_PHONE_EXIST = "CHECK_PHONE_EXIST";
export const CHECK_PHONE_EXIST_SUCCESS = "CHECK_PHONE_EXIST_SUCCESS";
export const CHECK_PHONE_EXIST_FAIL = "CHECK_PHONE_EXIST_FAIL";

export const CHECK_MOBILE_EXIST = "CHECK_MOBILE_EXIST";
export const CHECK_MOBILE_EXIST_SUCCESS = "CHECK_MOBILE_EXIST_SUCCESS";
export const CHECK_MOBILE_EXIST_FAIL = "CHECK_MOBILE_EXIST_FAIL";

export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS";
export const ADD_CUSTOMER_FAIL = "ADD_CUSTOMER_FAIL";

