import {
  GET_CHANGE_PASSWORD,
  GET_CHANGE_PASSWORD_SUCCESS,
  GET_CHANGE_PASSWORD_FAIL,
} from "./actionTypes";

export const getChangePassword = () => {
  return {
    type: GET_CHANGE_PASSWORD,
  };
};

export const getChangePasswordSuccess = (data) => {
  return {
    type: GET_CHANGE_PASSWORD_SUCCESS,
    payload: data,
  };
};

export const getChangePasswordFail = (error) => {
  return {
    type: GET_CHANGE_PASSWORD_FAIL,
    payload: error,
  };
};
