import { takeLatest, put, call } from "redux-saga/effects";
import { GET_FORGOT_EMAIL } from "./actionTypes";
import {
  getForgotEmailSuccess,
  getForgotEmailFail
} from "./actions";

import { getForgotEmail } from "../../helpers/backendHelper";
import { showError, showSuccess } from "../../utils/alertMessage";

function* onGetForgotEmail(data) {
  try {
    const response = yield call(getForgotEmail, data);
    if(response){
      if(response.code === 200){
        yield put(getForgotEmailSuccess(response.message));
        showSuccess(response.message);
      }else{
        yield put(getForgotEmailFail(response.message));
        showError(response.message);
      }
    }else{
      yield put(getForgotEmailFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(getForgotEmailFail(error.response));
  }
}

function* ForgotEmailSaga() {
  yield takeLatest(GET_FORGOT_EMAIL, onGetForgotEmail);
}

export default ForgotEmailSaga;
