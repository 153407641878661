import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import {Row,Col} from 'react-bootstrap';
import { GET_USER_PROFILE, UPDATE_USER_PROFILE } from '../../../store/userProfile/actionTypes';
import { useDispatch, useSelector } from "react-redux";
import LoaderImage from "../../../../src/assets/images/page-loader.svg";
import UploadImageToS3WithNativeSdk from "../../../utils/UploadImageToS3WithNativeSdk";
import { UploadFileToS3 } from "../../../utils/uploadFileToS3";
import { FilePond, File, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "./style.scss";

let UserProfile = () => {

    const title = useState("5iQ | User Profile");

    useEffect(() => {
        document.title = "5iQ | User Profile";
    }, [title]);
    
    const dispatch = useDispatch();

    const storedData = JSON.parse(sessionStorage.getItem('stored_data'));
    
    let user_id = window.atob(storedData.user_id);
    
    const [validated, setValidated] = useState(false);
    const [firstname, setFirstname] = useState();
    const [lastname, setLastname] = useState();
    const [profilePicture, setProfilePicture] = useState("");
    const [userProfile, setUserProfile] = useState();
    const [showUserProfile, setShowUserProfile] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isFormSave, setIsFormSave] = useState(false);
    const [files, setFiles] = useState([]); 

    

    // get user profile data
   const UserProfileData = useSelector((state) => state.UserProfileReducer);
 
    useEffect(() => {

        dispatch({
            type: GET_USER_PROFILE,
            payload: {
                  "get": "all",
                  "conditions": {
                     "id": user_id,
                  }
            },
         });

    },[]);

    useEffect(() => {


        if(UserProfileData.isFetching === true && UserProfileData.userData !== null){

            
            var userDetail = UserProfileData.userData;
            
            setFirstname(userDetail.firstname);
            setLastname(userDetail.lastname);
            setUserProfile(userDetail.user_profile);
            
            if(UserProfileData.isUpdate === true && isFormSave === true){
                setIsLoading(false);
                setShowUserProfile(true);
                setProfilePicture();
                setIsFormSave(false);
                let user_name = window.btoa(`${UserProfileData.userData.firstname} ${UserProfileData.userData.lastname}`);
                storedData.user_name = user_name;
                sessionStorage.setItem('stored_data',JSON.stringify(storedData));
                document.getElementById('header_username').innerHTML = `${UserProfileData.userData.firstname} ${UserProfileData.userData.lastname}`;
                
            }

        }


    },[UserProfileData]);


    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        // setIsLoading(true);
        setIsFormSave(true);

        if (form.checkValidity() !== false) {
          
            console.log(profilePicture[0]);

            if(profilePicture[0]){

                

                const d = new Date();
                var time = d.getTime();
                var filename = profilePicture[0].file.name.replace(/ /g,"_");
                var extenstion = profilePicture[0].file.type.split("/");
                filename = "user_profile/"+time+"_"+filename;

            
                setShowUserProfile(false);
                UploadFileToS3(profilePicture[0].file,filename);
            }
          
            dispatch({
                type: UPDATE_USER_PROFILE,
                payload: {
                    id: user_id,
                    firstname: firstname,
                    lastname: lastname,
                    user_profile: filename
                },
            });

          
        } else {
            setIsLoading(false);
        }
        setValidated(true);
      };

    return(
    <>
        <div className="right-section-wrapper has-white-bg has-heading-block">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <div className="page-heading-block d-flex align-center justify-space-between">
                        <h5>User Profile</h5>
                        <Button
                            type="submit"
                            className="rounded-btn btn-success height-34"
                            variant="success">
                            Save
                        </Button>
                    </div>
                    <div className="content-wrapper user-profile">
                        
                            <div className="form-outer d-flex">
                                <div className="form-group width-25 mb-3">
                                    <label>First Name</label>
                                    <Form.Group className="form-group">
                                        <Form.Control required type="text" placeholder="Firstname" defaultValue={firstname}  onBlur={(e) => setFirstname(e.target.value)} />
                                        <Form.Control.Feedback type="invalid">Please Enter Firstname</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="form-group width-25 mb-3">
                                    <label>Last Name</label>
                                    <Form.Group className="form-group">
                                        <Form.Control required type="text" placeholder="Lastname" defaultValue={lastname} onBlur={(e) => setLastname(e.target.value)}  />
                                        <Form.Control.Feedback type="invalid">Please Enter Lastname</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="form-group width-50 mb-3">
                                    <label>Profile Picture</label>
                                    <div className="file-upload-outer secondary-filepond browse-option d-flex">
                                        <FilePond
                                            files={profilePicture}
                                            onupdatefiles={setProfilePicture}
                                            allowMultiple={false}
                                            name="files"
                                            labelIdle='<i class="fa fa-folder-open" aria-hidden="true"></i> Browse …'
                                        />


                                    {profilePicture === '' && 
                                        <div className="user-profile-image">
                                            <img className="profile-img" 
                                                    src={"https://5iq-website-uploads.s3.ap-southeast-2.amazonaws.com/"+userProfile}
                                                        alt="Profile">
                                            </img>
                                        </div>
                                    }

                                    </div>
                                </div>

                                
                            

                            </div>

                            
                            {/* <Form.Label>Firstname</Form.Label>
                            <Form.Control required type="text" placeholder="Firstname" defaultValue={firstname}  onBlur={(e) => setFirstname(e.target.value)} />
                            <Form.Control.Feedback type="invalid">Please Enter Firstname</Form.Control.Feedback>
                        
                            <Form.Label>Lastname</Form.Label>
                            <Form.Control required type="text" placeholder="Lastname" defaultValue={lastname} onBlur={(e) => setLastname(e.target.value)}  />
                            <Form.Control.Feedback type="invalid">Please Enter Lastname</Form.Control.Feedback>
                        
                            <Form.Label>Profile Picture</Form.Label>
                            <Form.Control accept="image/*" type="file" placeholder="Profile Picture" onChange={imageChange} />  
                            {profilePicture && showUserProfile === false && (
                                <div>
                                    <p> Prveiw image </p>
                                    <img
                                        src={URL.createObjectURL(profilePicture)}
                                        alt="Preview Image"
                                        style={{"height":"200px", "width":"200px"}}
                                    />
                                    <Button  onClick={removeProfilePicture}>
                                    Remove This Image
                                    </Button>
                                </div>
                            )}
                            {userProfile && showUserProfile === true &&  (
                                <div>
                                    <p> user image </p>
                                    <img
                                        src={"https://5iq-website-uploads.s3.ap-southeast-2.amazonaws.com/"+userProfile}
                                        alt="Profile Image"
                                        style={{"height":"200px", "width":"200px"}}
                                    />
                                </div>
                            )}
                            <Button variant="primary" type="submit">
                                Submit
                            </Button> */}
                       
                    </div>
                    </Form>
                </div>
        {isLoading === true &&
            <div className="page-loader d-flex align-center justify-center" >
            <img src={LoaderImage} alt="Loading"></img>
            </div>
        }
    </>
    )
}

export default UserProfile;
