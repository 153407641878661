import {
  GET_NOTIFICATION,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAIL
} from "./actionTypes";

export const getNotification = () => {
  return {
    type: GET_NOTIFICATION,
  };
};

export const getNotificationSuccess = (data) => {
  return {
    type: GET_NOTIFICATION_SUCCESS,
    payload: data,
  };
};

export const getNotificationFail = (error) => {
  return {
    type: GET_NOTIFICATION_FAIL,
    payload: error,
  };
};
