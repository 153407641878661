import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GET_LOGOUT } from "../../../store/login/actionTypes";

let Logout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch({
            type: GET_LOGOUT
        });
    });
    localStorage.setItem('data',JSON.stringify(''));
    localStorage.clear();

    // Remove all saved data from sessionStorage
    sessionStorage.clear();

    let userData = JSON.parse(localStorage.getItem('data'));
    if(userData){
        if(userData.api_key===""){
            navigate("/login")
        }
    }else{
        navigate("/login")
    }
    return (
        <>
        </>
    );
}

export default Logout;
