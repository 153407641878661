import {
  SAVE_PART,
  SAVE_PART_SUCCESS,
  SAVE_PART_FAIL,
  GET_PART_LIST,
  GET_PART_LIST_SUCCESS,
  GET_PART_LIST_FAIL,
  DELETE_PART,
  DELETE_PART_SUCCESS,
  DELETE_PART_FAIL,
} from "./actionTypes";

const initialState = {
  partsData: null,
  error: {
    message: "",
  },
  partListData: null,
  isLoadData: false,
  deletedPart: null
};

const PartReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_PART:
      state = { 
        ...state, 
        partsData: action.partsData,
      };
    break;
    case SAVE_PART_SUCCESS:
      state = { 
        ...state, 
        partsData: action.payload,
      };
    break;
    case SAVE_PART_FAIL:
      state = {
        ...state,
        partsData: null,
        error: {
          message: action.payload,
        },
      };
    break;
    case GET_PART_LIST:
      state = { 
        ...state, 
        partListData: null,
        isLoadData: false,
      };
    break;
    case GET_PART_LIST_SUCCESS:
      state = { 
        ...state, 
        partListData: action.payload,
        isLoadData: true,
      };
    break;
    case GET_PART_LIST_FAIL:
      state = {
        ...state,
        partListData: null,
        isLoadData: true,
        error: {
          message: action.payload,
        },
      };
    break;
    case DELETE_PART:
      state = { 
        ...state, 
        deletedPart: action.partsData,
      };
    break;
    case DELETE_PART_SUCCESS:
      state = { 
        ...state, 
        deletedPart: action.payload,
      };
    break;
    case DELETE_PART_FAIL:
      state = {
        ...state,
        deletedPart: null,
        error: {
          message: action.payload,
        },
      };
    break;
    default:
      state = { ...state };
    break;
  }
  return state;
};

export default PartReducer;
