import {
  getInvoicesPayments,
  getJobCannedList,
  getUserProfile,
  getWorkshopOtherDetailsView
} from "helpers/backendHelper";
import { getSessionData, getWorkshopId } from "utils/getWorkshopId";

export async function getInvoiceReceiptEmail(id) {
  let invoices_payments_credits_other,
    credit_number,
    email,
    scripts,
    comp_logo,
    address,
    otherdata,
    type,
    customer,
    invoice,
    receipt_number,
    invoices_payments_credit,
    invoice_receipt,
    job_detail;

  const request1 = {
    get: "all",
    conditions: {
      "InvoicesPaymentsCredits.id": id,
    },
    contain: {
      0: "Invoices.Users.States",
      1: "Invoices.JobSchedules.CreatedByUser",
      2: "Invoices.JobSchedules.Vehicles",
      "Invoices.Users.CustomersDetails": {
        conditions: {
          workshop_id: getWorkshopId(),
        },
      },
    },
  };
  const get_invoices = await getInvoicesPayments({ payload: request1 });

  if (get_invoices?.code === 200) {
    invoice_receipt = get_invoices?.data?.InvoicesPaymentsCredits[0];
    invoice = invoice_receipt?.invoice;
    job_detail = invoice?.job_schedule;
    receipt_number = invoice_receipt?.receipt_no;
    const request2 = {
      get: "all",
      conditions: {
        "Users.id": getSessionData("user_id"),
      },
      contain: [
        "Workshops.States",
        "Workshops.Countries",
        "Workshops.WorkshopOtherDetails",
      ],
    };

    const users = await getUserProfile({ payload: request2 });
    if (users?.code === 200) {
      address = users?.data?.Users[0];
    }

    customer = invoice?.user;
    email = invoice?.user?.email;
  }

  const request3 = {
    get: "all",
    conditions: { workshop_id: getWorkshopId(), status: 1, "type IN": [3, 8] },
  };

  const get_scripts = await getJobCannedList({ payload: request3 });
  if (get_scripts?.code === 200) {
    scripts = get_scripts?.data?.WorkshopScriptsDetails;
  }

  const request4 = { workshop_id: getWorkshopId() };
  const getworkshopotherdata = await getWorkshopOtherDetailsView({
    payload: request4,
  });
  if (getworkshopotherdata?.code === 200) {
    otherdata = getworkshopotherdata?.data?.WorkshopOtherDetails;
  }

  return {
    invoices_payments_credits_other,
    credit_number,
    email,
    scripts,
    comp_logo,
    address,
    otherdata,
    type,
    customer,
    invoice,
    invoices_payments_credit,
    job_detail,
    receipt_number,
    invoice_receipt
  };
}
