import Loadable from "react-loadable";

import { Loading } from "../common/navigation";

const Login = Loadable({
  loader: () => import("./components/login"),
  loading: Loading,
});
const Logout = Loadable({
    loader: () => import("./components/logout"),
    loading: Loading,
  });
const ForgotPassword = Loadable({
  loader: () => import("./components/forgot-password"),
  loading: Loading,
});

const ForgotEmail = Loadable({
  loader: () => import("./components/forgot-email"),
  loading: Loading,
});

export const routes = [
  {
    path: "/login",
    exact: true,
    name: "login",
    element: Login,
  },
  {
    path: "/logout",
    exact: true,
    name: "logout",
    element: Logout,
  },
  {
    path: "/forgot-password",
    exact: true,
    name: "forgot-password",
    element: ForgotPassword,
  },
  {
    path: "/forgot-email",
    exact: true,
    name: "forgot-email",
    element: ForgotEmail,
  }
];

export * from "./components";
