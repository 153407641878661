import React, { useEffect, useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import bookJobBlue from "../../../assets/images/book-job-blue.svg";
import UnassignedJobList from "./unassignedjobs/unassignedJobList";
import AssignedJobList from "./assignedJobList";
import InProgressJobList from "./inProgressJobList";
import CompletedJobList from "./completedJobList";
import { useSelector } from "react-redux";

let JobList = () => {
  const currentTab = useSelector((state) => state.jobs);

  const [title, setTitle] = useState("5iQ | Job List");
  const [activeTab, setActiveTab] = useState("unassigned-job");

  useEffect(() => {
    setActiveTab(currentTab);
  }, [currentTab]);

  useEffect(() => {
    document.title = "5iQ | Job List";
  }, [title]);
  return (
    <>
      <div className="right-section-wrapper">
              <div className="table-header">
                <div className="top-block">
                  <div className="table-title">
                    <img src={bookJobBlue}></img>
                    <span>Jobs</span>
                  </div>
                  <div className="video-link-outer">
                    <div className="video-tutorial">
                      <i className="fa fa-icon"></i>
                      <span className="help-text">Help</span>
                      <span className="tutorial-title">Watch Tutorial</span>
                    </div>
                  </div>
                </div>
                <div className="table-header-tabs">
                  <Tabs
                    defaultActiveKey={"unassigned-job"}
                    // activeKey={activeTab.activeTab}
                    // onSelect={(k) => setActiveTab(k)}
                  >
                    <Tab eventKey="unassigned-job" title="Unassigned Jobs">
                      <UnassignedJobList />
                    </Tab>
                    <Tab eventKey="assigned-job" title="Assigned Jobs">
                      <AssignedJobList />
                    </Tab>
                    <Tab eventKey="inProgress-job" title="In Progress Jobs">
                      <InProgressJobList />
                    </Tab>
                    <Tab eventKey="complete-job" title="Completed Jobs">
                      <CompletedJobList />
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
    </>
  );
};

export default JobList;
