import Button from "@restart/ui/esm/Button";
import React, { useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { utcToTimezone } from '../../../utils/timeZoneConvert';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PRINT_JOB_CARD_PDF,DELETE_JOB_ACTIVITY,CHECK_AVAILABLE_HOURS } from "../../../store/scheduler/actionTypes";
import { downloadFileFromS3 } from "../../../utils/uploadFileToS3";
import DeleteConfirm from '../../../utils/deleteConfirm';
import Timer from "./Timer";
import Reschedule from "./Reschedule";
import CreateJob from './CreateJob';
import LoaderImage from "../../../assets/images/page-loader.svg";
import moment from 'moment';
import Modal from "react-bootstrap/Modal";
import { Draggable } from '@fullcalendar/interaction';

const JobTicket = ({ eventInfo, isDuplicate, dupeObjs, view, workshop_id, eventArray ,statusColor, setUpdateCalendar,technicianName,slotDuration }) => {
    
    const [loading, setLoading] = useState(false)
    const [slotLimit, setSlotLimitShow] = useState(false);
    const handleSlotLimitClose = () => setSlotLimitShow(false);
    var startEventTime = eventInfo.event._def.extendedProps.strt_time;
    var technicianName = "";
    if(eventInfo.view.type === "resourceTimelineDay" && eventInfo.view.type === "resourceTimelineWeek"){
        technicianName = eventInfo.event.getResources()[0]._resource.title;
    }
    let rescheduleDisable = '';
    let carryOverDisable = '';

    if(eventInfo.event.extendedProps.job_progress_status !== "InProgress" || eventInfo.event.extendedProps.is_copid_job === 1){
        carryOverDisable = 'disable-option';
    }
    
    if(eventInfo.event.extendedProps.job_progress_status !== "Pending"){
        rescheduleDisable = 'disable-option';
    }
    
    if(eventInfo.event.extendedProps.job_progress_status === "Rescheduled"){
        rescheduleDisable = 'disable-option';
        carryOverDisable = 'disable-option';
    }

    var jobId = eventInfo.event.id;

    const [clickForPdf, setClickForPdf] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleDeleteClose = () => setShowDeleteModal(false);
    const dispatch = useDispatch(); // Dispatch variable
    const navigate = useNavigate();

    const localStorageData = JSON.parse(localStorage.getItem('data'));
    const timezoneLable = localStorageData?.workshop?.workshop_other_details[0]?.timezone_label || 'Asia/Kolkata';
    let events = 0;
    let commonArray = [];
    if (eventInfo.event.extendedProps.req_type && eventInfo.event.extendedProps.req_type === "bay") {
        let EventDate = utcToTimezone(eventInfo.event.start, timezoneLable, "YYYY-MM-DD-HH:mm", "YYYY-MM-DD-HH:mm");
        let resourceId = eventInfo.event["_def"].resourceIds[0];
        let ObjToGet = EventDate.toString() + resourceId.toString();
        
        eventArray && eventArray.forEach(value => {
            if (value[ObjToGet]) {
                commonArray.push(value[ObjToGet])
            }
        });
        if(commonArray.length !== 0){
            if (commonArray[0].length) {
                events = commonArray[0].length;
                commonArray = commonArray[0];
            } else {
                events = commonArray.length;
            }
        }
        
        var elements = document.querySelectorAll(`[data-id="${eventInfo.event.startStr}"]`);
        for (var i = 1; i < elements.length; i++) {
            elements[i].remove();
        }
    }

    const getJobCardPdf = (id) => {
        dispatch({
            type: PRINT_JOB_CARD_PDF,
            payload: {
                id: id,
                workshop_id: workshop_id
            }
        });
        setClickForPdf(true)
    }

    const jobCardPdf = useSelector((state) => state.SchedulerReducer.jobCardPdfPath);

    useEffect(() => {
        if (jobCardPdf !== null && jobCardPdf !== undefined && clickForPdf === true) {
            const downloadUrl = downloadFileFromS3(jobCardPdf.path);
            const newWindow = window.open(downloadUrl, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
            setClickForPdf(false);
        }
    }, [jobCardPdf])
    const [jobIdData, setJobIdData] = useState();
    const [employeeName, setEmployeeName] = useState();
    const [jobNumber, setJobNumber] = useState();
    const [timerShow, setTimerShow] = useState(false);
    const [jobType, setJobType] = useState('reschedule');

    const handleTimerClose = () => setTimerShow(false);
    const handleTimerShow = (id) => {
        setJobIdData(id);
        setTimerShow(true);
    };

    const [rescheduleShow, setRescheduleShow] = useState(false);
    const handleRescheduleClose = () => {
        setRescheduleShow(false);
    }
    
    const handleRescheduleShow = (id,name,extProps,type) => {
        setJobType(type);
        setJobIdData(id);
        setEmployeeName(name);
        setRescheduleShow(true);
        setJobNumber(extProps.job_number);
        
    };
    const [activityDeleteId,setActivityDeleteId] = useState(0);
    const deleteRecord = async () => {    
        // console.log(activityDeleteId,'delete activityDeleteId');
        dispatch({
            type: DELETE_JOB_ACTIVITY,
            payload: {
                id: activityDeleteId
            }
        });
        //TODO: Call DELETE API HERE
        setShowDeleteModal(false);
        setUpdateCalendar(true);
    }
    const handleDeleteOpen = (delete_id) => {
        if(delete_id !== ""){
            setShowDeleteModal(true);
            setActivityDeleteId(delete_id);
        }
    }
    const availableHours = useSelector((state) => state.SchedulerReducer.checkAvailableHours);
    const [showModal, setShowModal] = useState(false);
    const [showMonthViewPopup, setMonthViewPopup] = useState(false)
    const setCloseModal = () => {
        setMonthViewPopup(false);
        setShowModal(false)
    };

    const [bayStartTime, setBayStartTime] = useState();
    const [bayEndTime, setBayEndTime] = useState();
    const [bayUserId, setBayUserId] = useState();
    const [bayUserName, setBayUserName] = useState();
    
    const createBayJob = (startTime,id,name) => {

        var avilableData = moment(new Date(startTime)).format("YYYY-MM-DD");

        dispatch({
            type: CHECK_AVAILABLE_HOURS,
            payload: {
                date: avilableData,
                workshop_id: workshop_id
            }
        });

        setBayStartTime(startTime);
        setBayEndTime(startTime);
        setBayUserId(id);
        setBayUserName(name);
        setShowModal(true);
    }
    useEffect(() => {
        if(eventInfo.event.extendedProps.req_type && eventInfo.event.extendedProps.req_type === "bay"){
            var bayJobId = eventInfo.event.id;
            let draggableElBays = document.getElementsByClassName("events-list-"+bayJobId);
            if(draggableElBays.length !== 0){
                let newDragg = new Draggable(draggableElBays[0], {
                    itemSelector: ".eventBays",
                    eventData: function (eventEl) {
                        let data = eventEl.getAttribute("data-value");
                        let id = eventEl.getAttribute("data");
                        return {
                            _id: id,
                            // title: `Rego | ${JSON.parse(data).vehicle.rego}`,
                            data: JSON.parse(data)
                        };
                    }
                });
                return () => newDragg.destroy();
            }
        }
    },[eventInfo])
    

    return (
        <>
        {showDeleteModal && (
            <DeleteConfirm 
                handleDeleteClose={() => handleDeleteClose()}
                deleteRecord={() => deleteRecord()}
                msg={"Are you sure?"}
            />
        )}
            {eventInfo.event.extendedProps.req_type && eventInfo.event.extendedProps.req_type === "bay" ? (
                <div data-id={eventInfo.event.startStr} className="job-content-main">
                    <div id={eventInfo.event.startStr} className="total-events font-weight-bold d-flex justify-content-center">{events}</div>
                    <div className="font-weight-light d-flex justify-content-center">Jobs Booked</div>
                    <div className="d-flex justify-content-center">
                        <button className="create-job-btn" onClick={() => {createBayJob(eventInfo.event.startStr,eventInfo.event["_def"].resourceIds[0],eventInfo.event.getResources()[0]._resource.title)}}>Create Job</button>
                    </div>
                    <div className="show-events-box">
                        <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                        <ul className={"events-list events-list-"+eventInfo.event.id}>
                            {commonArray.map((value, index) => (
                                <li className="px-2 mb-1 eventBays" data={value.id} key={index} data-value={JSON.stringify(value)}>
                                    <OverlayTrigger
                                        key={index}
                                        placement="auto"
                                        overlay={
                                            <Tooltip id="tooltip-bottom" className="calender-tooltip">
                                                <div
                                                    className="tooltip-header d-flex justify-space-between"
                                                    style={{ background: "#f9990b" }}
                                                >
                                                    <span>Job {value.job_number} | {utcToTimezone(value.pickup_time, timezoneLable, "HH:mm", "HH:mm")} </span>
                                                    <span>{value.job_progress_status}</span>
                                                </div>
                                                <div className="tooltip-content">
                                                    <ul key={value.make}>
                                                        <li>{value.rego} |</li>
                                                        <li>{value.make} {value.model} {value.year}</li>
                                                        <li>{value.title} | {value.tags.length ? value.tags[0] : ""}</li>
                                                        <li>{value.customer_name ? value.customer_name : ""} | {value.customer_mobile ? value.customer_mobile : ""}</li>
                                                    </ul>
                                                </div>
                                            </Tooltip>
                                        }
                                    >
                                        <div className="event p-1">
                                            <p>{value.rego}| {value.make}</p>
                                            <p>{value.customer_name ? value.customer_name : ""} | {value.customer_mobile ? value.customer_mobile : ""}</p>
                                        </div>
                                    </OverlayTrigger>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            ) : eventInfo.event.classNames.length > 0 && eventInfo.event.classNames.includes('left-stripe-primary-black') ? (
                <>
                    <OverlayTrigger
                        key="bottom"
                        placement="auto"
                        overlay={<></>}
                    >
                        <div
                            data-id={eventInfo.event["_def"].resourceIds[0]}
                            className="event-slide d-flex justify-space-between direction-column"
                            style={{ borderColor: "#f9990b" }}
                        >
                            <span className="label"><strong><small>{eventInfo.timeText}</small></strong></span>
                            <span>{eventInfo.event.title}</span>
                            <span className="time-block">
                                <i className="fa fa-clock-o font-size-16 text-warning text-warning"></i>
                                {eventInfo.timeText}
                            </span>
                        </div>
                    </OverlayTrigger>
                    <div className="close-job-icon">
                        <Button onClick={() => handleDeleteOpen(eventInfo?.event?.id || '')}>
                            <i className="fa fa-times fa-w-16"></i>
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    <OverlayTrigger
                        key="bottom"
                        placement="auto"
                        overlay={
                            <Tooltip id="tooltip-bottom" className="calender-tooltip">
                                <div
                                    className="tooltip-header d-flex align-center justify-space-between"
                                    style={{ background: `${statusColor[eventInfo?.event?.extendedProps?.job_progress_status || 'Pending']}` }}
                                >
                                    <span>Job {eventInfo?.event?.extendedProps?.job_number || 0} | {utcToTimezone(eventInfo?.event?.extendedProps?.pickup_time_only || "00:00", timezoneLable, "HH:mm:ss", "HH:mm")} </span>
                                    <div className="job-id">{eventInfo?.event?.extendedProps?.job_progress_status || ''}
                                        { eventInfo?.event?.extendedProps?.is_copy_job || 0 > 0 ? <label className="job-label co-tag m-l-5" title="Carry Over Job">CO</label> : '' }
                                    </div>
                                </div>
                                <div className="tooltip-content">
                                    <ul key={eventInfo?.event?.extendedProps?.rego}>
                                        <li>{eventInfo?.event?.extendedProps?.rego || ''} | { }</li>
                                        <li>{eventInfo?.event?.extendedProps?.make || ''} {eventInfo?.event?.extendedProps?.model || ''} {eventInfo?.event?.extendedProps?.year || ''}</li>
                                        <li>{eventInfo?.event?.title || ''} | {eventInfo?.event?.extendedProps?.tags?.[0] || ""}</li>
                                        <li>{eventInfo?.event?.extendedProps?.customer_name || ""} | {eventInfo?.event?.extendedProps?.customer_mobile || ""}</li>
                                    </ul>
                                </div>
                            </Tooltip>
                        }
                    >
                        <div
                            data-id={eventInfo?.event["_def"]?.resourceIds?.[0] || []}
                            className="event-slide d-flex justify-space-between direction-column"
                            style={{ borderLeft: `4px solid ${statusColor[eventInfo?.event?.extendedProps?.job_progress_status || 'Pending']}` }}
                        >
                            <span className="label">{eventInfo?.event?.extendedProps?.rego || ''} | {eventInfo?.event?.extendedProps?.make || ''}</span>
                            <span>{eventInfo?.event?.title || ''}</span>
                            <span className="time-block">
                                <i className="fa fa-clock-o font-size-16 text-warning text-warning"></i>
                                {eventInfo.timeText}
                            </span>
                        </div>
                    </OverlayTrigger>
                    <div className="more-options">
                        <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                        <ul className="options-list" key="jobList">
                            <li>
                                <span onClick={() => navigate("/job_detail/"+eventInfo.event.id)}>
                                    View Job
                                </span>
                            </li>
                            <li>
                                <span onClick={() => {handleTimerShow(eventInfo.event.id)}}>
                                    Time Clock
                                </span>
                            </li>
                            <li onClick={() => { getJobCardPdf(eventInfo.event.id) }}>
                                <span >
                                    Job Card
                                </span>
                            </li>
                            <li className={rescheduleDisable} onClick={()=>{ 
                                handleRescheduleShow(jobId,eventInfo.event.getResources()[0]._resource.title,eventInfo.event.extendedProps,'reschedule')}}>
                                <span >
                                    Reschedule
                                </span>
                            </li>
                            <li className={carryOverDisable}>
                                <span onClick={()=>{ 
                                    handleRescheduleShow(jobId,eventInfo.event.getResources()[0]._resource.title,eventInfo.event.extendedProps,'carryover')}}>
                                    Carry Over Job
                                </span>
                            </li>
                        </ul>
                    </div>
                </>
            )}
            {
                (timerShow === true) ? 
                    <Timer timerShow={timerShow} handleTimerClose={handleTimerClose} jobIdData={jobIdData} setUpdateCalendar={setUpdateCalendar}></Timer>
                :''
            }
            {
                (rescheduleShow === true) ? 
                    <Reschedule 
                        rescheduleShow={rescheduleShow}
                        handleRescheduleClose={handleRescheduleClose}
                        jobIdData={jobIdData}
                        startEventTime={startEventTime}
                        technicianName={employeeName}
                        jobNumber={jobNumber}
                        setUpdateCalendar={setUpdateCalendar}
                        jobType={jobType}
                    ></Reschedule> : ''
            }
            {
                loading ? <div className="page-loader d-flex align-center justify-center">
                    <img src={LoaderImage} alt="Loading"></img>
                </div> : ""
            }
            {
                (availableHours !== null || showMonthViewPopup) ?
                    ((showModal && availableHours === true) || showMonthViewPopup) ?
                    <CreateJob
                        show={showModal}
                        showMonthViewPopup={showMonthViewPopup}
                        close={setCloseModal}
                        slotDuration={slotDuration}
                        technicianName={bayUserName}
                        startTimeCalendar={bayStartTime}
                        endTimeCalendar={bayEndTime}
                        technicianId={bayUserId}
                        technicianType="bay"
                        setUpdateCalendar={setUpdateCalendar}
                    ></CreateJob> : 
                        (availableHours === false) ?
                                <Modal
                                    className="info-dialog"
                                    size="sm"
                                    show={slotLimit}
                                    onHide={handleSlotLimitClose}
                                    centered
                                >
                                    <div className="heading-block d-flex justify-center">
                                        <i className="la la-exclamation-circle info-icon" aria-hidden="true"></i>
                                    </div>
                                    <Modal.Body>
                                        <div>
                                            <h2 className="mb-4">Sorry..Daily Limit Exceed!</h2>
                                        </div>
                                        <div className="d-flex justify-center">
                                            <Button
                                                variant="primary"
                                                className="btn-primary rounded-btn"
                                                onClick={() => handleSlotLimitClose()}
                                            >
                                                Ok
                                            </Button>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                                : ''
                            :''

            }
        </>
    );
};

export default JobTicket;
