import { takeLatest, put, call } from "redux-saga/effects";
import { GET_NOTIFICATION } from "./actionTypes";
import {
  getNotificationSuccess,
  getNotificationFail
} from "./actions";

import { getNotification } from "../../helpers/backendHelper";

function* onGetNotification(data) {
  try {
    const response = yield call(getNotification, data);
    if(response){
      if(response.code === 200){
        yield put(getNotificationSuccess(response.data.NotificationsData));
      }else{
        yield put(getNotificationFail(response.message));
      }
    }else{
      yield put(getNotificationFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(getNotificationFail(error.response));
  }
}

function* NotificationSaga() {
  yield takeLatest(GET_NOTIFICATION, onGetNotification);
}

export default NotificationSaga;
