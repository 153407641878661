import {
    GET_CUSTOM_FIELDS,
    GET_CUSTOM_FIELDS_SUCCESS,
    GET_CUSTOM_FIELDS_FAIL,
    CHECK_VIN_EXIST,
    CHECK_VIN_EXIST_SUCCESS,
    CHECK_VIN_EXIST_FAIL,
    CHECK_CHASIS_EXIST,
    CHECK_CHASIS_EXIST_SUCCESS,
    CHECK_CHASIS_EXIST_FAIL,
    CHECK_ENGINE_EXIST,
    CHECK_ENGINE_EXIST_SUCCESS,
    CHECK_ENGINE_EXIST_FAIL,
    CHECK_FLEET_EXIST,
    CHECK_FLEET_EXIST_SUCCESS,
    CHECK_FLEET_EXIST_FAIL,
    GET_ASSETS_MASTER,
    GET_ASSETS_MASTER_SUCCESS,
    GET_ASSETS_MASTER_FAIL,
    GET_ASSETS_DETAILS,
    GET_ASSETS_DETAILS_SUCCESS,
    GET_ASSETS_DETAILS_FAIL,
    ADD_ASSETS_DETAILS,
    ADD_ASSETS_DETAILS_SUCCESS,
    ADD_ASSETS_DETAILS_FAIL,
    ADD_ASSETS_MASTER,
    ADD_ASSETS_MASTER_SUCCESS,
    ADD_ASSETS_MASTER_FAIL,
    ADD_ASSETS,
    ADD_ASSETS_SUCCESS,
    ADD_ASSETS_FAIL,
} from "./actionTypes";

export const getCustomFields = () => {
    return {
        type: GET_CUSTOM_FIELDS,
    };
};

export const getCustomFieldsSuccess = (data) => {
    return {
        type: GET_CUSTOM_FIELDS_SUCCESS,
        payload: data,
    };
};

export const getCustomFieldsFail = (error) => {
    return {
        type: GET_CUSTOM_FIELDS_FAIL,
        payload: error,
    };
};

export const checkVinExist = () => {
    return {
        type: CHECK_VIN_EXIST,
    };
};

export const checkVinExistSuccess = (data) => {
    return {
        type: CHECK_VIN_EXIST_SUCCESS,
        payload: data,
    };
};

export const checkVinExistFail = (error) => {
    return {
        type: CHECK_VIN_EXIST_FAIL,
        payload: error,
    };
};

export const checkChasisExist = () => {
    return {
        type: CHECK_CHASIS_EXIST,
    };
};

export const checkChasisExistSuccess = (data) => {
    return {
        type: CHECK_CHASIS_EXIST_SUCCESS,
        payload: data,
    };
};

export const checkChasisExistFail = (error) => {
    return {
        type: CHECK_CHASIS_EXIST_FAIL,
        payload: error,
    };
};

export const checkEngineExist = () => {
    return {
        type: CHECK_ENGINE_EXIST,
    };
};

export const checkEngineExistSuccess = (data) => {
    return {
        type: CHECK_ENGINE_EXIST_SUCCESS,
        payload: data,
    };
};

export const checkEngineExistFail = (error) => {
    return {
        type: CHECK_ENGINE_EXIST_FAIL,
        payload: error,
    };
};

export const checkFleetExist = () => {
    return {
        type: CHECK_FLEET_EXIST,
    };
};

export const checkFleetExistSuccess = (data) => {
    return {
        type: CHECK_FLEET_EXIST_SUCCESS,
        payload: data,
    };
};

export const checkFleetExistFail = (error) => {
    return {
        type: CHECK_FLEET_EXIST_FAIL,
        payload: error,
    };
};

export const getAssetsMaster = () => {
    return {
        type: GET_ASSETS_MASTER,
    };
};

export const getAssetsMasterSuccess = (data) => {
    return {
        type: GET_ASSETS_MASTER_SUCCESS,
        payload: data,
    };
};

export const getAssetsMasterFail = (error) => {
    return {
        type: GET_ASSETS_MASTER_FAIL,
        payload: error,
    };
};

export const getAssetsDetails = () => {
    return {
        type: GET_ASSETS_DETAILS,
    };
};

export const getAssetsDetailsSuccess = (data) => {
    return {
        type: GET_ASSETS_DETAILS_SUCCESS,
        payload: data,
    };
};

export const getAssetsDetailsFail = (error) => {
    return {
        type: GET_ASSETS_DETAILS_FAIL,
        payload: error,
    };
};

export const addAssetsDetails = () => {
    return {
        type: ADD_ASSETS_DETAILS,
    };
};

export const addAssetsDetailsSuccess = (data) => {
    return {
        type: ADD_ASSETS_DETAILS_SUCCESS,
        payload: data,
    };
};

export const addAssetsDetailsFail = (error) => {
    return {
        type: ADD_ASSETS_DETAILS_FAIL,
        payload: error,
    };
};

export const addAssetsMaster = () => {
    return {
        type: ADD_ASSETS_MASTER,
    };
};

export const addAssetsMasterSuccess = (data) => {
    return {
        type: ADD_ASSETS_MASTER_SUCCESS,
        payload: data,
    };
};

export const addAssetsMasterFail = (error) => {
    return {
        type: ADD_ASSETS_MASTER_FAIL,
        payload: error,
    };
};

export const addAssets = () => {
    return {
        type: ADD_ASSETS,
    };
};

export const addAssetsSuccess = (data) => {
    return {
        type: ADD_ASSETS_SUCCESS,
        payload: data,
    };
};

export const addAssetsFail = (error) => {
    return {
        type: ADD_ASSETS_FAIL,
        payload: error,
    };
};