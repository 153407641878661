import {
  GET_LOGIN,
  GET_LOGIN_SUCCESS,
  GET_LOGIN_FAIL,
  GET_LOGOUT,
} from "./actionTypes";

export const getLogin = () => {
  return {
    type: GET_LOGIN,
  };
};

export const getLoginSuccess = (data) => {
  return {
    type: GET_LOGIN_SUCCESS,
    payload: data.data.Users,
  };
};

export const getLoginFail = (error) => {
  return {
    type: GET_LOGIN_FAIL,
    payload: error,
  };
};

export const getLogout = () => {
  return {
    type: GET_LOGOUT,
  };
};

