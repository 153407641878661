import { takeLatest, put, call } from "redux-saga/effects";
import { 
        SAVE_JOB_PART, GET_JOB_PART_LIST, DELETE_JOB_PART, UPDATE_JOB_PART, 
        INLINE_UPDATE_JOB_PART, EDIT_JOB_PART_MANY, GET_JOB_KIT_PART, 
        GET_JOB_KIT_LABOUR, SAVE_JOB_KIT_PART, DELETE_JOB_KIT_PART, 
        INLINE_UPDATE_JOB_KIT_PART, EDIT_JOB_KIT_PART_MANY, EDIT_JOB_KIT_LABOUR_MANY,
        INLINE_UPDATE_JOB_KIT_LABOUR
      } from "./actionTypes";
import {
  saveJobPartSuccess,
  saveJobPartFail,
  getJobPartListSuccess,
  getJobPartListFail,
  deleteJobPartSuccess,
  deleteJobPartFail,
  updateJobPartSuccess,
  updateJobPartFail,
  inlineUpdateJobPartSuccess,
  inlineUpdateJobPartFail,
  editJobPartManySuccess,
  editJobPartManyFail,
  getJobKitPartSuccess,
  getJobKitPartFail,
  getJobKitLabourSuccess,
  getJobKitLabourFail,
  saveJobKitPartSuccess,
  saveJobKitPartFail,
  deleteJobKitPartSuccess,
  deleteJobKitPartFail,
  inlineUpdateJobKitPartSuccess,
  inlineUpdateJobKitPartFail,
  editJobKitPartManySuccess,
  editJobKitPartManyFail,
  editJobKitLabourManySuccess,
  editJobKitLabourManyFail,
  inlineUpdateJobKitLabourSuccess,
  inlineUpdateJobKitLabourFail
} from "./actions";

import { saveJobPart, getJobPartList , deleteJobPart, updateJobPart, editJobPartMany, getJobKitPart, getJobKitLabour, saveJobKitPart, deleteJobKitPart, editJobKitPartMany, editJobKitLabourMany } from "../../helpers/backendHelper";
import { showError, showSuccess } from "../../utils/alertMessage";

function* onGetSaveJobPart(data) {
  try {
    const response = yield call(saveJobPart, data);
    
    if(response){
      if(response.code===200){
        yield put(saveJobPartSuccess(response.data.JobsParts));
        showSuccess("Job part added successfully");
      }else{
        yield put(saveJobPartFail(response.message));
        showError(response.message);
      }
    }else{
      yield put(saveJobPartFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(saveJobPartFail(error.response));
  }
}


function* onGetJobPartList(data) {
  try {
    const response = yield call(getJobPartList, data);
    if(response){
      if(response.code===200){
        yield put(getJobPartListSuccess(response.data.JobsParts));
      }else{
        yield put(getJobPartListFail(response.message));
      }
    }else{
      yield put(getJobPartListFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(getJobPartListFail(error.response));
  }
}

// delete job part START
function* onGetDeleteJobPart(data) {
  try {
    const response = yield call(deleteJobPart, data);
    if(response){
      if(response.code===200){
        yield put(deleteJobPartSuccess(response.data.JobsParts[0]));
        showSuccess("Job part deleted successfully");
      }else{
        yield put(deleteJobPartFail(response.message));
        showError(response.message);
      }
    }else{
      yield put(deleteJobPartFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(deleteJobPartFail(error.response));
  }
}
// delete job part END

function* onGetUpdateJobPart(data) {
  try {
    const response = yield call(updateJobPart, data);
    
    if(response){
      if(response.code===200){
        yield put(updateJobPartSuccess(response.data.JobsParts));
        showSuccess("Job part updated successfully");
      }else{
        yield put(updateJobPartFail(response.message));
        showError(response.message);
      }
    }else{
      yield put(updateJobPartFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(updateJobPartFail(error.response));
  }
}

function* inlineUpdateJobPart(data) {
  try {
    yield put(inlineUpdateJobPartSuccess(data.payload));
  } catch (error) {
    yield put(inlineUpdateJobPartFail(error.response));
  }
}

function* onEditJobPartMany(data) {
  try {
    const response = yield call(editJobPartMany, data);
    
    if(response){
      if(response.code===200){
        yield put(editJobPartManySuccess(response.data.JobsParts));
        showSuccess("Job part updated successfully");
      }else{
        yield put(editJobPartManyFail(response.message));
        showError(response.message);
      }
    }else{
      yield put(editJobPartManyFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(editJobPartManyFail(error.response));
  }
}

function* onGetJobKitPart(data) {
  try {
    const response = yield call(getJobKitPart, data);
    
    if(response){
      if(response.code===200){
        yield put(getJobKitPartSuccess(response.data.JobKitParts));
        
      }else{
        yield put(getJobKitPartFail(response.message));
        showError(response.message);
      }
    }else{
      yield put(getJobKitPartFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(getJobKitPartFail(error.response));
  }
}


function* onGetJobKitLabour(data) {
  try {
    const response = yield call(getJobKitLabour, data);
    
    if(response){
      if(response.code===200){
        yield put(getJobKitLabourSuccess(response.data.JobKitLabours));
        
      }else{
        yield put(getJobKitLabourFail(response.message));
        showError(response.message);
      }
    }else{
      yield put(getJobKitLabourFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(getJobKitLabourFail(error.response));
  }
}

function* onSaveJobKitPart(data) {
  try {
    const response = yield call(saveJobKitPart, data);
    
    if(response){
      if(response.code===200){
        yield put(saveJobKitPartSuccess(response.data.JobKitParts));
        showSuccess("Job kit part added successfully");
      }else{
        yield put(saveJobKitPartFail(response.message));
        showError(response.message);
      }
    }else{
      yield put(saveJobKitPartFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(saveJobKitPartFail(error.response));
  }
}


function* onDeleteJobKitPart(data) {
  try {
    const response = yield call(deleteJobKitPart, data);
    
    if(response){
      if(response.code===200){
        yield put(deleteJobKitPartSuccess(response.data.JobKitParts));
        showSuccess("Job kit part deleted successfully");
      }else{
        yield put(deleteJobKitPartFail(response.message));
        showError(response.message);
      }
    }else{
      yield put(deleteJobKitPartFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(deleteJobKitPartFail(error.response));
  }
}


function* inlineUpdateJobKitPart(data) {
  try {
    yield put(inlineUpdateJobKitPartSuccess(data.payload));
  } catch (error) {
    yield put(inlineUpdateJobKitPartFail(error.response));
  }
}


function* onEditJobKitPartMany(data) {
  try {
    const response = yield call(editJobKitPartMany, data);
    
    if(response){
      if(response.code===200){
        yield put(editJobKitPartManySuccess(response.data.JobsParts));
        
      }else{
        yield put(editJobKitPartManyFail(response.message));
        showError(response.message);
      }
    }else{
      yield put(editJobKitPartManyFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(editJobKitPartManyFail(error.response));
  }
}


function* onEditJobKitLabourMany(data) {
  try {
    const response = yield call(editJobKitLabourMany, data);
    
    if(response){
      if(response.code===200){
        yield put(editJobKitLabourManySuccess(response.data.JobsParts));
        showSuccess("Job kit part updated successfully");
      }else{
        yield put(editJobKitLabourManyFail(response.message));
        showError(response.message);
      }
    }else{
      yield put(editJobKitLabourManyFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(editJobKitLabourManyFail(error.response));
  }
}


function* inlineUpdateJobKitLabour(data) {
  try {
    yield put(inlineUpdateJobKitLabourSuccess(data.payload));
  } catch (error) {
    yield put(inlineUpdateJobKitLabourFail(error.response));
  }
}

function* JobPartSaga() {
  yield takeLatest(SAVE_JOB_PART, onGetSaveJobPart);
  yield takeLatest(GET_JOB_PART_LIST, onGetJobPartList);
  yield takeLatest(DELETE_JOB_PART, onGetDeleteJobPart);
  yield takeLatest(UPDATE_JOB_PART, onGetUpdateJobPart);
  yield takeLatest(INLINE_UPDATE_JOB_PART, inlineUpdateJobPart);
  yield takeLatest(EDIT_JOB_PART_MANY, onEditJobPartMany);
  yield takeLatest(GET_JOB_KIT_PART, onGetJobKitPart);
  yield takeLatest(GET_JOB_KIT_LABOUR, onGetJobKitLabour);
  yield takeLatest(SAVE_JOB_KIT_PART, onSaveJobKitPart);
  yield takeLatest(DELETE_JOB_KIT_PART, onDeleteJobKitPart);
  yield takeLatest(INLINE_UPDATE_JOB_KIT_PART, inlineUpdateJobKitPart);
  yield takeLatest(EDIT_JOB_KIT_PART_MANY, onEditJobKitPartMany);
  yield takeLatest(EDIT_JOB_KIT_LABOUR_MANY, onEditJobKitLabourMany);
  yield takeLatest(INLINE_UPDATE_JOB_KIT_LABOUR, inlineUpdateJobKitLabour);
}

export default JobPartSaga;
