import React, { useEffect, useState } from 'react';
import { dashboardWrapper , blockWrapper ,blueTiles ,orangeTiles, greenTiles ,purpleTiles, rightBlock ,chartWrapper,chartTitle, chartBlock ,DropdownBtn,ratingBlock,ratingOuter,ratingWrapper,progressBar,customSlider,carouselItem} from "./styles.module.scss";
import serviceImg from '../../../assets/images/book-job-red.svg';
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Button from "react-bootstrap/Button";
import Carousel from 'react-bootstrap/Carousel';
import NoResult from "../../../assets/images/no-result-found.jpeg";
import StarRatings from 'react-star-ratings';
 
const Dashboard = () => {

    const [title, setTitle] = useState("5iQ | Home");

    useEffect(() => {
        document.title = "5iQ | Home";
    }, [title]);

    const barChartOptions = {
        title: {
          text: ''
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            //   size: "150",
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                  enabled: false
              },
              showInLegend: true
          }
        },
        series: [{
            type: 'column',
          name: '',
          data: [
            {
                name: 'Total',
                y: 10,
                color: '#09c3d0'
            }, {
                name: 'Used',
                y: 20,
                color: '#6491dd'
            }, {
                name: 'Total1',
                y: 15,
                color: '#09c3d0'
            }, {
                name: 'Used1',
                y: 25,
                color: '#6491dd'
            }, 
          ],
        
        }]
      }
      const pieChartOptions = {
        title: {
          text: ''
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          pie: {
              size: "150",
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                  enabled: false
              },
              showInLegend: true
          }
        },
        series: [{
          depth: 45,
          type: "pie",
          innerSize: '70%',
          name: '',
          colorByPoint: true,
          data: [
              {
                  name: 'Total',
                  y: 20,
                  color: '#09c3d0'
              }, {
                  name: 'Used',
                  y: 35,
                  color: '#6491dd'
              }
          ],
        
        }]
        
      }

    return(
    <>
        <div className={`right-section-wrapper has-white-bg ${dashboardWrapper}`}>
            <div className="row mb-3">
                <div className="col-sm-3">
                    <div className={`d-flex align-center ${blockWrapper} ${blueTiles}`}>
                    <i className="fa fa-car"></i>
                        <div className={`${rightBlock}`}>
                            <h1>4</h1>
                            <p>Car Count</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className={`d-flex align-center ${blockWrapper} ${orangeTiles}`}>
                    <img src={serviceImg}></img>
                        <div className={`${rightBlock}`}>
                            <h1>0</h1>
                            <p>Service Due</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className={`d-flex align-center ${blockWrapper} ${purpleTiles}`}>
                    <i className="fa fa-users"></i>
                        <div className={`${rightBlock}`}>
                            <h1>4</h1>
                            <p>Customer</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className={`d-flex align-center ${blockWrapper} ${greenTiles}`}>
                    <i className="fa fa-cloud-download" aria-hidden="true"></i>
                        <div className={`${rightBlock}`}>
                            <h1>3</h1>
                            <p>App Download</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-4 mb-3">
                    <div className={`dashboard-chart d-flex direction-column ${chartWrapper}`}>
                        <div className={`d-flex align-center justify-space-between ${chartTitle}`}>
                            <span>Jobs | 25 </span>
                            <DropdownButton className={`d-flex align-center justify-space-between dropdown-btn custom-dropdown-list ${DropdownBtn}`} title={<i className="la la-bars"></i>}>
                                <Dropdown.Item to='/home'>Day</Dropdown.Item>
                                <Dropdown.Item to='/home'>Week</Dropdown.Item>
                                <Dropdown.Item to='/home'>Month</Dropdown.Item>
                            </DropdownButton>
                        </div>
                        <div className={`${chartBlock}`}>
                            <HighchartsReact
                            containerProps={{ style: { height: "300px" , width:'300px'} }}
                            highcharts={Highcharts}
                            options={barChartOptions}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-sm-4 mb-3">
                    <div className={`dashboard-chart d-flex direction-column ${chartWrapper}`}>
                        <div className={`d-flex align-center justify-space-between ${chartTitle}`}>
                            <span>INVOICE | ₹0.00 </span>
                            <DropdownButton className={`d-flex align-center justify-space-between dropdown-btn custom-dropdown-list ${DropdownBtn}`} title={<i className="la la-bars"></i>}>
                                <Dropdown.Item to='/home'>Day</Dropdown.Item>
                                <Dropdown.Item to='/home'>Week</Dropdown.Item>
                                <Dropdown.Item to='/home'>Month</Dropdown.Item>
                            </DropdownButton>
                        </div>
                        <div className={`${chartBlock}`}>
                            <HighchartsReact
                                containerProps={{ style: { height: "300px" , width:'300px'} }}
                                highcharts={Highcharts}
                                options={barChartOptions}
                                />
                        </div>
                    </div>
                </div>
                <div className="col-sm-4 mb-3">
                    <div className={`dashboard-chart d-flex direction-column ${chartWrapper}`}>
                        <div className={`d-flex align-center justify-space-between ${chartTitle}`}>
                            <span>PAYMENTS | ₹0 </span>
                            <DropdownButton className={`d-flex align-center justify-space-between dropdown-btn custom-dropdown-list ${DropdownBtn}`} title={<i className="la la-bars"></i>}>
                                <Dropdown.Item to='/home'>Day</Dropdown.Item>
                                <Dropdown.Item to='/home'>Week</Dropdown.Item>
                                <Dropdown.Item to='/home'>Month</Dropdown.Item>
                            </DropdownButton>
                        </div>
                        <div className={`${chartBlock}`}>
                        <HighchartsReact
                                containerProps={{ style: { height: "300px" , width:'300px'} }}
                                highcharts={Highcharts}
                                options={barChartOptions}
                                />
                        </div>
                    </div>
                </div>
                <div className="col-sm-4 mb-3">
                    <div className={`dashboard-chart d-flex direction-column ${chartWrapper}`}>
                        <div className={`d-flex align-center justify-space-between ${chartTitle}`}>
                            <span>BOOK IN SOURCE </span>
                        </div>
                        <div className={`${chartBlock}`}>
                            <HighchartsReact
                                containerProps={{ style: { height: "200px" } }}
                                highcharts={Highcharts}
                                options={pieChartOptions}
                            />
                            <ul>
                                <li>
                                    <h3>8</h3>
                                    <label>In App</label>
                                </li>
                                <li>
                                    <h3>401</h3>
                                    <label>Website</label>
                                </li>
                                <li>
                                    <h3>0</h3>
                                    <label>Social Media</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4 mb-3">
                    <div className={`dashboard-chart d-flex direction-column ${chartWrapper}`}>
                        <div className={`d-flex align-center justify-space-between ${chartTitle}`}>
                            <span>CUSTOMER SURVEY</span>
                        </div>
                        <div className={`${ratingOuter}`}>
                            <h1 className="mb-2">4.27</h1>
                            <div className="mb-2">
                                <StarRatings
                                    rating={4.27}
                                    starRatedColor="#ffce00"
                                    starDimension="25px"
                                    starSpacing="0px"
                                />
                            </div>
                            <h4 className="mb-2">Based on 6 ratings</h4>
                            <div className={`${ratingWrapper}`}>
                                <div className={`d-flex align-center ${ratingBlock}`}>
                                    <label className="m-r-10">5 Star</label>
                                    <ProgressBar className={`m-r-10 ${progressBar}`} variant="warning" now={60} />
                                    <span>2</span>
                                </div>
                                <div className={`d-flex align-center ${ratingBlock}`}>
                                    <label className="m-r-10">4 Star</label>
                                    <ProgressBar className={`m-r-10 ${progressBar}`} variant="warning" now={40} />
                                    <span>1</span>
                                </div>
                                <div className={`d-flex align-center ${ratingBlock}`}>
                                    <label className="m-r-10">3 Star</label>
                                    <ProgressBar className={`m-r-10 ${progressBar}`} variant="warning" now={30} />
                                    <span>1</span>
                                </div>
                                <div className={`d-flex align-center ${ratingBlock}`}>
                                    <label className="m-r-10">2 Star</label>
                                    <ProgressBar className={`m-r-10 ${progressBar}`} variant="warning" now={0} />
                                    <span>0</span>
                                </div>
                                <div className={`d-flex align-center ${ratingBlock}`}>
                                    <label className="m-r-10">1 Star</label>
                                    <ProgressBar className={`m-r-10 ${progressBar}`} variant="warning" now={0} />
                                    <span>0</span>
                                </div>
                            </div>
                            <Button className="btn-primary rounded-btn height-34">
                                See Comments
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4 mb-3">
                    <div className={`dashboard-chart d-flex direction-column ${chartWrapper}`}>
                        <Carousel className={`custom-slider ${customSlider}`}>
                            <Carousel.Item className={`${carouselItem}`}>
                                <img
                                className="d-block w-100"
                                src={NoResult}
                                alt="First slide"
                                />
                                <Carousel.Caption>
                                    <h3 className="text-success">Liana Day Off</h3>
                                    <p className="date">20 Jan 2021 </p>
                                    <p className="title">Liana Day Off</p>
                                    <Button className="view-all">
                                        View All
                                    </Button>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item className={`${carouselItem}`}>
                                <img
                                className="d-block w-100"
                                src={NoResult}
                                alt="Second slide"
                                />
                                <Carousel.Caption>
                                    <h3 className="text-success">Liana Day Off</h3>
                                    <p className="date">20 Jan 2021 </p>
                                    <p className="title">Liana Day Off</p>
                                    <Button className="view-all">
                                        View All
                                    </Button>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item className={`${carouselItem}`}>
                                <Carousel.Caption>
                                    <h3 className="text-success">Liana Day Off</h3>
                                    <p className="date">20 Jan 2021 </p>
                                    <p className="title">Liana Day Off</p>
                                    <Button className="view-all">
                                        View All
                                    </Button>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default Dashboard;
