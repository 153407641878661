import Loadable from "react-loadable";

import { Loading } from "../common/navigation";

const Setting = Loadable({
  loader: () => import("./components/settings"),
  loading: Loading,
});

const CompanyDetail = Loadable({
  loader: () => import("./components/CompanyDetails/companyDetails"),
  loading: Loading,
});

const DefaultSettings = Loadable({
  loader: () => import("./components/DefaultSettings/defaultSettings"),
  loading: Loading,
});

const SecurityGroup = Loadable({
  loader: () => import("./components/SecurityGroup/securityGroup"),
  loading: Loading,
});

const Statuses = Loadable({
  loader: () => import("./components/Statuses/statuses"),
  loading: Loading,
});

const WorkshopBays = Loadable({
  loader: () => import("./components/WorkshopBays/workshopBays"),
  loading: Loading,
});

export const routes = [
  {
    path: "/settings",
    exact: true,
    name: "settings",
    element: Setting,
  },
  {
    path: "/settings_company_detail",
    exact: true,
    name: "settings_company_detail",
    element: CompanyDetail,
  },
  {
    path: "/settings_defaults",
    exact: true,
    name: "settings_default",
    element: DefaultSettings,
  },
  {
    path: "/settings_security_group",
    exact: true,
    name: "settings_security_group",
    element: SecurityGroup,
  },
  {
    path: "/statuses",
    exact: true,
    name: "settings_statuses",
    element: Statuses,
  },
  {
    path: "/workshop_bays",
    exact: true,
    name: "workshop_bays",
    element: WorkshopBays,
  },
];

export * from "./components";