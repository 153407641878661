import {
    GET_REGISTER,
    GET_REGISTER_SUCCESS,
    GET_REGISTER_FAIL,
    SEND_OTP,
    SEND_OTP_SUCCESS,
    SEND_OTP_FAIL,
    CONFIRM_OTP,
    CONFIRM_OTP_SUCCESS,
    CONFIRM_OTP_FAIL,
    EXIST_EMAIL,
    EXIST_EMAIL_SUCCESS,
    EXIST_EMAIL_FAIL,
    GET_COUNTRY,
    GET_COUNTRY_SUCCESS,
    GET_COUNTRY_FAIL,
    GET_STATE,
    GET_STATE_SUCCESS,
    GET_STATE_FAIL,
    GET_INDUSTRY,
    GET_INDUSTRY_SUCCESS,
    GET_INDUSTRY_FAIL,
    GET_ACCOUNTING_PACKAGE,
    GET_ACCOUNTING_PACKAGE_SUCCESS,
    GET_ACCOUNTING_PACKAGE_FAIL,
    GET_SIZE_OF_WORKSHOP,
    GET_SIZE_OF_WORKSHOP_SUCCESS,
    GET_SIZE_OF_WORKSHOP_FAIL,
    GET_REFERRAL_ADVERTISE,
    GET_REFERRAL_ADVERTISE_SUCCESS,
    GET_REFERRAL_ADVERTISE_FAIL   
    
  } from "./actionTypes";
  
  export const getRegister = () => {
    return {
      type: GET_REGISTER,
    };
  };
  
  export const getRegisterSuccess = (data) => {
    return {
      type: GET_REGISTER_SUCCESS,
      payload: data.data.Users,
    };
  };
  
  export const getRegisterFail = (error) => {
    return {
      type: GET_REGISTER_FAIL,
      payload: error,
    };
  };
  
  export const sendOtp = () => {
    return {
      type: SEND_OTP,
    };
  };
  
  export const sendOtpSuccess = (data) => {
    return {
      type: SEND_OTP_SUCCESS,
      payload: data.data.UsersOtpDetails,
      message :data.message,
    };
  };
  
  export const sendOtpFail = (error) => {
    return {
      type: SEND_OTP_FAIL,
      payload: error,
    };
  };

  export const confirmOtp = () => {
    return {
      type: CONFIRM_OTP,
    };
  };
  
  export const confirmOtpSuccess = (data) => {
    return {
      type: CONFIRM_OTP_SUCCESS,
      payload: data.data.UsersOtpDetails,
      message :data.message,
    };
  };
  
  export const confirmOtpFail = (error) => {
    return {
      type: CONFIRM_OTP_FAIL,
      payload: error,
    };
  };

  export const existEmail = () => {
    return {
      type: EXIST_EMAIL,
    };
  };
  
  export const existEmailSuccess = (data) => {
    return {
      type: EXIST_EMAIL_SUCCESS,
      payload: data.data.Users,
      message :data.message,
    };
  };
  
  export const existEmailFail = (error) => {
    return {
      type: EXIST_EMAIL_FAIL,
      payload: error,
    };
  };
  
  export const getCountry = () => {
    return {
      type: GET_COUNTRY,
    };
  };
  
  export const getCountrySuccess = (data) => {
    return {
      type: GET_COUNTRY_SUCCESS,
      payload: data.data.Countries,
      message :data.message,
    };
  };
  
  export const getCountryFail = (error) => {
    return {
      type: GET_COUNTRY_FAIL,
      payload: error,
    };
  };

  export const getState = () => {
    return {
      type: GET_STATE,
    };
  };
  
  export const getStateSuccess = (data) => {
    return {
      type: GET_STATE_SUCCESS,
      payload: data.data.States,
      message :data.message,
    };
  };
  
  export const getStateFail = (error) => {
    return {
      type: GET_STATE_FAIL,
      payload: error,
    };
  };

  export const getIndustry = () => {
    return {
      type: GET_INDUSTRY,
    };
  };
  
  export const getIndustrySuccess = (data) => {
    return {
      type: GET_INDUSTRY_SUCCESS,
      payload: data.data.Industries,
      message :data.message,
    };
  };
  
  export const getIndustryFail = (error) => {
    return {
      type: GET_INDUSTRY_FAIL,
      payload: error,
    };
  };

  export const getAccountingPackage = () => {
    return {
      type: GET_ACCOUNTING_PACKAGE,
    };
  };
  
  export const getAccountingPackageSuccess = (data) => {
    return {
      type: GET_ACCOUNTING_PACKAGE_SUCCESS,
      payload: data.data.AccountingPackages,
      message :data.message,
    };
  };
  
  export const getAccountingPackageFail = (error) => {
    return {
      type: GET_ACCOUNTING_PACKAGE_FAIL,
      payload: error,
    };
  };

  export const getSizeOfWorkshop = () => {
    return {
      type: GET_SIZE_OF_WORKSHOP,
    };
  };
  
  export const getSizeOfWorkshopSuccess = (data) => {
    return {
      type: GET_SIZE_OF_WORKSHOP_SUCCESS,
      payload: data.data.IndustrySizes,
      message :data.message,
    };
  };
  
  export const getSizeOfWorkshopFail = (error) => {
    return {
      type: GET_SIZE_OF_WORKSHOP_FAIL,
      payload: error,
    };
  };

  export const getReferralAdvertise = () => {
    return {
      type: GET_REFERRAL_ADVERTISE,
    };
  };
  
  export const getReferralAdvertiseSuccess = (data) => {
    return {
      type: GET_REFERRAL_ADVERTISE_SUCCESS,
      payload: data.data.Advertisements,
      message :data.message,
    };
  };
  
  export const getReferralAdvertiseFail = (error) => {
    return {
      type: GET_REFERRAL_ADVERTISE_FAIL,
      payload: error,
    };
  };