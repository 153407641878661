import { takeLatest, put, call } from "redux-saga/effects";
import { SAVE_JOB_LABOUR, GET_JOB_LABOUR_LIST , DELETE_JOB_LABOUR, 
          UPDATE_JOB_LABOUR, INLINE_UPDATE_JOB_LABOUR,
          EDIT_JOB_LABOUR_MANY
      } from "./actionTypes";
import {
  saveJobLabourSuccess,
  saveJobLabourFail,
  getJobLabourListSuccess,
  getJobLabourListFail,
  deleteJobLabourSuccess,
  deleteJobLabourFail,
  updateJobLabourSuccess,
  updateJobLabourFail,
  inlineUpdateLabourSuccess,
  inlineUpdateLabourFail,
  editJobLabourManySuccess,
  editJobLabourManyFail
} from "./actions";

import { saveJobLabour, getJobLabourList , deleteLabourPart, updateJobLabour, editJobLabourMany } from "../../helpers/backendHelper";
import { showError } from "../../utils/alertMessage";

function* onGetSaveJobLabour(data) {
  try {
    const response = yield call(saveJobLabour, data);
    if(response){
      if(response.code===200){
        yield put(saveJobLabourSuccess(response.data.JobLabours));
        
      }else{
        yield put(saveJobLabourFail(response.message));
        showError(response.message);
      }
    }else{
      yield put(saveJobLabourFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(saveJobLabourFail(error.response));
  }
}


function* onGetJobLabourList(data) {
  try {
    const response = yield call(getJobLabourList, data);
    if(response){
      
      if(response.code===200){
        yield put(getJobLabourListSuccess(response.data.JobLabours));
      }else{
        yield put(getJobLabourListFail(response.message));
      }
    }else{
      yield put(getJobLabourListFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(getJobLabourListFail(error.response));
  }
}



function* onGetDeleteLabour(data) {
  try {
    const response = yield call(deleteLabourPart, data);
    if(response){
      
      if(response.code===200){
        yield put(deleteJobLabourSuccess(response.data.JobLabours));
      }else{
        yield put(deleteJobLabourFail(response.message));
      }
    }else{
      yield put(deleteJobLabourFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(deleteJobLabourFail(error.response));
  }
}

function* onGetUpdateLabour(data) {
  try {
    const response = yield call(updateJobLabour, data);
    if(response){
      if(response.code===200){
        yield put(updateJobLabourSuccess(response.data.JobLabours));
        
      }else{
        yield put(updateJobLabourFail(response.message));
        showError(response.message);
      }
    }else{
      yield put(updateJobLabourFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(updateJobLabourFail(error.response));
  }
}


function* onInlineUpdateLabour(data) {
  try {
    yield put(inlineUpdateLabourSuccess(data.payload));
  } catch (error) {
    yield put(inlineUpdateLabourFail(error.response));
  }
}


function* onEditJobLabourMany(data) {
  try {
    const response = yield call(editJobLabourMany, data);
    if(response){
      if(response.code===200){
        yield put(editJobLabourManySuccess(response.data.JobLabours));
        
      }else{
        yield put(editJobLabourManyFail(response.message));
        showError(response.message);
      }
    }else{
      yield put(editJobLabourManyFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(editJobLabourManyFail(error.response));
  }
}

function* JobLabourSaga() {
  yield takeLatest(SAVE_JOB_LABOUR, onGetSaveJobLabour);
  yield takeLatest(GET_JOB_LABOUR_LIST, onGetJobLabourList);
  yield takeLatest(DELETE_JOB_LABOUR, onGetDeleteLabour);
  yield takeLatest(UPDATE_JOB_LABOUR, onGetUpdateLabour);
  yield takeLatest(INLINE_UPDATE_JOB_LABOUR, onInlineUpdateLabour);
  yield takeLatest(EDIT_JOB_LABOUR_MANY, onEditJobLabourMany);
}

export default JobLabourSaga;
