import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { FilePond, File, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

import { GET_COUNTRY, GET_STATE } from "../../../../store/register/actionTypes";
import {
  GET_TIMEZONE,
  UPDATE_COMPANY_DETAIL,
  UPDATE_COMPANY_ACN_DETAIL,
} from "../../../../store/companyDetails/actionTypes";

import { UploadFileToS3 } from "../../../../utils/uploadFileToS3";

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

const CompanyDetailForm = ({ userData, companyDetailData }) => {
  const dispatch = useDispatch(); // Dispatch variable

  const [workShopDetails, setWorkShopDetails] = useState();
  const [workShopOtherDetails, setWorkShopOtherDetails] = useState();
  const filePondRef = useRef();
  const odometerOptions = [
    { value: "1", label: "Km" },
    { value: "2", label: "Mileage" },
  ];

  let countryData = [];
  let languageData = [];
  let currencyData = [];
  let stateData = [];
  let timeZoneData = [];

  useEffect(() => {
    dispatch({
      type: GET_COUNTRY,
      payload: {
        get: "all",
        conditions: {
          status: 1,
        },
      },
    });

    dispatch({
      type: GET_TIMEZONE,
      payload: {
        get: "all",
        conditions: {
          status: 1,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (companyDetailData.workShopDetails) {
      setWorkShopDetails(companyDetailData.workShopDetails);
    }
  }, [companyDetailData]);

  useEffect(() => {
    if (companyDetailData.workShopOtherDetails) {
      setWorkShopOtherDetails(companyDetailData.workShopOtherDetails);
    }
  }, [companyDetailData]);

  useEffect(() => {
    if (workShopDetails) {
      dispatch({
        type: GET_STATE,
        payload: {
          get: "all",
          conditions: {
            is_deleted: 0,
            country_id: workShopDetails.country.id,
          },
          order: {
            name: "asc",
          },
          fields: ["id", "name"],
        },
      });
    }
  }, [workShopDetails]);

  // Create a list for display country data
  if (userData.countries != null) {
    const country = userData.countries;
    country.map((countryName) =>
      countryData.push({
        value: countryName.id,
        label: countryName.country_name,
      })
    );
  }

  if (userData.countries != null) {
    const country = userData.countries;
    country.map((countryName) =>
      languageData.push({
        value: countryName.id,
        label: countryName.language,
      })
    );
  }

  if (userData.countries != null) {
    const country = userData.countries;
    country.map((countryName) =>
      currencyData.push({
        value: countryName.id,
        label: `${countryName.currency} (${countryName.symbol})`,
      })
    );
  }

  // Create a list for display timezone data
  if (companyDetailData.timezones != null) {
    const timezones = companyDetailData.timezones;
    timezones.map((timezone) =>
      timeZoneData.push({
        value: timezone.time_label,
        label: timezone.timezone,
      })
    );
  }

  //Dispatch function for get state based on selected country
  function handleState(e) {
    var countryId = e.value;
    dispatch({
      type: GET_STATE,
      payload: {
        get: "all",
        conditions: {
          is_deleted: 0,
          country_id: countryId,
        },
        order: {
          name: "asc",
        },
        fields: ["id", "name"],
      },
    });
  }

  // Create a list for display state data
  if (userData.states != null) {
    if (userData.success.message !== "") {
      const state = userData.states;
      state.map((stateName) =>
        stateData.push({
          value: stateName.id,
          label: stateName.name,
        })
      );
    }
  }
  const formInitialValues = {
    companyName: workShopDetails ? workShopDetails.name : "",
    phone: workShopDetails ? workShopDetails.phone : "",
    email: workShopDetails ? workShopDetails.email : "",
    website: workShopDetails ? workShopDetails.websiteUrl : "",
    logo: workShopDetails ? workShopDetails.logo : "",
    streetAddress: workShopDetails ? workShopDetails.street_address : "",
    country: workShopDetails ? workShopDetails.country.id : "",
    state: workShopDetails ? workShopDetails.state.id : "",
    zipcode: workShopDetails ? workShopDetails.postcode : "",
    suburb: workShopDetails ? workShopDetails.suburb : "",
    abn: workShopOtherDetails ? workShopOtherDetails.abn : "",
    acn: workShopOtherDetails ? workShopOtherDetails.acn : "",
    odometer: workShopOtherDetails ? workShopOtherDetails.odometer_type : "",
    language: workShopOtherDetails
      ? workShopOtherDetails.language
      : workShopDetails
      ? workShopDetails.country.id
      : "",
    currency: workShopOtherDetails ? workShopOtherDetails.currency : "",
    bsb: workShopOtherDetails ? workShopOtherDetails.bsb : "",
    accountNumber: workShopOtherDetails
      ? workShopOtherDetails.account_number
      : "",
    accountName: workShopOtherDetails ? workShopOtherDetails.account_name : "",
    timezone: workShopOtherDetails
      ? `${workShopOtherDetails.timezone}_${workShopOtherDetails.timezone_label}`
      : "",
    onlineScheduleCode: workShopDetails
      ? `<a href="/bookJob?token=${workShopDetails.workshopToken}" target="_blank" style="display: inline-block;font: bold 15px!important;background-color: hsl(188, 86%, 63%);color: #ffffff;text-decoration:none;border-color:hsl(188, 86%, 63%);border-radius:3px;padding: 10px;" rel="noreferrer">Book A Job</a>`
      : "",
  };

  return (
    <Formik
      initialValues={formInitialValues}
      enableReinitialize={true}
      // innerRef={formRef}
      onSubmit={async (values) => {
        var filename = "";
        const timezone = values.timezone.split("_");
        if (values.file) {
          const d = new Date();
          var time = d.getTime();
          filename = values.file.name.replace(/ /g,"_");
          var extenstion = values.file.type.split("/");
          filename = "company_details/"+time+"_"+filename;
          UploadFileToS3(values.file,filename);
        }
        
        const companyFormData = {
          name: values.companyName,
          phone: values.phone,
          email: values.email,
          websiteUrl: values.website,
          street_address: values.streetAddress,
          suburb: values.suburb,
          postcode: values.zipcode,
          country: values.country,
          state: values.state,
          logo: filename,
          id: JSON.parse(localStorage.getItem("data")).workshop_id,
          initial_setup: 1,
        };

        const companyOtherData = {
          acn: values.acn,
          abn: values.abn,
          workshop_id: JSON.parse(localStorage.getItem("data")).workshop_id,
          odometer_type: values.odometer,
          language: values.language,
          currency: values.currency,
          bsb: values.bsb,
          account_number: values.accountNumber,
          account_name: values.accountName,
          timezone: timezone[0],
          timezone_label: timezone[1],
          id: workShopOtherDetails.id,
        };
        dispatch({
          type: UPDATE_COMPANY_DETAIL,
          payload: companyFormData,
        });
        dispatch({
          type: UPDATE_COMPANY_ACN_DETAIL,
          payload: companyOtherData,
        });

        filePondRef.current && filePondRef.current.removeFile();
      }}
      validate={(values) => {
        const error = {};
        if (!values.companyName) {
          error.companyName = "Please enter company name";
        }

        if (!values.phone) {
          error.phone = "Please enter a phone number";
        } else if (values.phone.length < 6) {
          error.phone = "Your phone no must be at least 6 characters long";
        }

        if (!values.email) {
          error.email = "Please enter an email address";
        } else if (
          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            values.email.trim().toLowerCase()
          )
        ) {
          error.email = "Please enter a valid email address";
        }

        if (
          !values.website ||
          !/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/.test(
            values.website.trim().toLowerCase()
          )
        ) {
          error.website = "Please enter a valid URL";
        }

        if (!values.timezone) {
          error.timezone = "This field is required.";
        }
        return error;
      }}
    >
      {({ values, errors, touched, handleSubmit, setFieldValue }) => (
        <div className="card">
          <form onSubmit={handleSubmit}>
            <div className="card-header d-flex justify-content-between align-items-center">
              <h4 className="text-uppercase">Company Details</h4>
              <div className="cta">
                <button
                  type="submit"
                  className="btn btn-success btn-rounded"
                  disabled={companyDetailData.formLoading}
                >
                  Save
                </button>
              </div>
            </div>
            <div className="p-3">
              <div className="row">
                <div className="col-md-12 col-xl-6">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          Company Name
                        </label>
                        <input
                          type="text"
                          className={`form-control ${
                            errors.companyName &&
                            touched.companyName &&
                            "is-invalid"
                          }`}
                          value={values.companyName}
                          onChange={(e) =>
                            setFieldValue("companyName", e.target.value)
                          }
                        />
                        {errors.companyName && touched.companyName && (
                          <div className="form-error text-danger">
                            {errors.companyName}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          Main Phone
                        </label>
                        <input
                          type="number"
                          className={`form-control ${
                            errors.phone && touched.phone && "is-invalid"
                          }`}
                          value={values.phone}
                          onChange={(e) =>
                            setFieldValue("phone", e.target.value)
                          }
                        />
                        {errors.phone && touched.phone && (
                          <div className="form-error text-danger">
                            {errors.phone}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          Email Address
                        </label>
                        <input
                          type="text"
                          className={`form-control ${
                            errors.email && touched.email && "is-invalid"
                          }`}
                          value={values.email}
                          onChange={(e) =>
                            setFieldValue("email", e.target.value)
                          }
                        />
                        {errors.email && touched.email && (
                          <div className="form-error text-danger">
                            {errors.email}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          Website
                        </label>
                        <input
                          type="text"
                          className={`form-control ${
                            errors.website && touched.website && "is-invalid"
                          }`}
                          value={values.website}
                          onChange={(e) =>
                            setFieldValue("website", e.target.value)
                          }
                        />
                        {errors.website && touched.website && (
                          <div className="form-error text-danger">
                            {errors.website}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          App URL
                        </label>
                        <div>wertyu.fiiviq.com</div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          Street Address
                        </label>
                        <textarea
                          className="form-control"
                          value={values.streetAddress}
                          onChange={(e) =>
                            setFieldValue("streetAddress", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          Country
                        </label>
                        <Select
                          className="custom-select"
                          classNamePrefix="custom-select"
                          name="country"
                          id="country"
                          options={countryData}
                          value={countryData.filter(
                            (option) => option.value === values.country
                          )}
                          onChange={(e) => {
                            setFieldValue("country", e.value);
                            handleState(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          State
                        </label>
                        <Select
                          className="custom-select"
                          classNamePrefix="custom-select"
                          name="state"
                          id="state"
                          options={stateData}
                          value={stateData.filter(
                            (option) => option.value === values.state
                          )}
                          onChange={(e) => setFieldValue("state", e.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          Zip code
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.zipcode}
                          onChange={(e) =>
                            setFieldValue("zipcode", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          Suburb
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.suburb}
                          onChange={(e) =>
                            setFieldValue("suburb", e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-xl-6">
                  <div className="row">
                    <div className={values.logo !== "" ? "col-md-7" : "col-md-12"}>
                      <div className="form-group pb-3">
                        <div className="file-upload-box">
                          <FilePond
                            ref={filePondRef}
                            id="file"
                            allowMultiple={false}
                            // allowProcess={true}
                            // server={{
                            //   process: function (
                            //     fieldName,
                            //     file,
                            //     metadata,
                            //     load,
                            //     error,
                            //     progress,
                            //     abort
                            //   ) {
                            //     const res = UploadFileToS3(file, "file/");
                            //     console.log(res);
                            //     load(res);
                            //   },
                            // }}
                            instantUpload={false}
                            onaddfile={async (err, fileEx) => {
                              if (!err) {
                                setFieldValue("file", fileEx.file);
                              }
                            }}
                            onremovefile={() => {
                              // setFileError(false);
                              setFieldValue("file", "");
                            }}
                            onerror={() => {
                              // setFileError(true);
                              // setFieldTouched("file", true);
                            }}
                            name="file"
                            acceptedFileTypes={["image/png", "image/jpeg"]}
                            maxFileSize="2MB"
                            labelIdle='<div class="dropzone-placeholder">
                              <div class="txt pb-1">
                                Drag and drop your file here
                                <br /> <strong>or</strorng>
                              </div>
                              <span class="btn btn-sm btn-primary btn-rounded px-3">Select File</span>
                              </div>'
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                        {values.logo !== "" && 
                            <div className="user-profile-image ms-0">
                                <img className="profile-img" 
                                        src={"https://5iq-website-uploads.s3.ap-southeast-2.amazonaws.com/"+values.logo}
                                            alt="Profile">
                                </img>
                            </div>
                        }
                    </div>
                  </div>
                </div>
              </div>

              <h5 className="text-uppercase border-bottom pb-2 pt-2 mb-3">
                Other
              </h5>
              <div className="row">
                <div className="col-md-12 col-xl-6">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          ABN
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.abn}
                          onChange={(e) => setFieldValue("abn", e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          ACN
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.acn}
                          onChange={(e) => setFieldValue("acn", e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          BSB
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.bsb}
                          onChange={(e) => setFieldValue("bsb", e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          Account Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.accountNumber}
                          onChange={(e) =>
                            setFieldValue("accountNumber", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          Account Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.accountName}
                          onChange={(e) =>
                            setFieldValue("accountName", e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-xl-6">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          Odometer Default
                        </label>
                        <Select
                          className="custom-select"
                          classNamePrefix="custom-select"
                          options={odometerOptions}
                          onChange={(e) => {
                            setFieldValue("odometer", e.value);
                          }}
                          value={odometerOptions.filter(
                            (option) => option.value === values.odometer
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          Language
                        </label>
                        <Select
                          className="custom-select"
                          classNamePrefix="custom-select"
                          options={languageData}
                          onChange={(e) => {
                            setFieldValue("language", e.value);
                          }}
                          value={languageData.filter(
                            (option) => option.value == values.language
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          Currency
                        </label>
                        <Select
                          className="custom-select"
                          classNamePrefix="custom-select"
                          options={currencyData}
                          onChange={(e) => {
                            setFieldValue("currency", e.value);
                          }}
                          value={currencyData.filter(
                            (option) => option.value == values.currency
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group pb-3">
                        <label htmlFor="" className="form-label">
                          Time Zone
                        </label>
                        <Select
                          className={`custom-select ${
                            errors.timezone && touched.timezone && "is-invalid"
                          }`}
                          classNamePrefix="custom-select"
                          name="timezone"
                          id="timezone"
                          options={timeZoneData}
                          onChange={(e) => {
                            setFieldValue("timezone", e.value);
                          }}
                          value={timeZoneData.filter(
                            (option) => option.value == values.timezone
                          )}
                        />
                        {errors.timezone && touched.timezone && (
                          <div className="form-error text-danger">
                            {errors.timezone}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">
                      Online Schedule Code
                    </label>
                    <textarea
                      className="form-control"
                      cols="40"
                      rows="10"
                      onChange={(e) =>
                        setFieldValue("onlineScheduleCode", e.target.value)
                      }
                      value={values.onlineScheduleCode}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
};

export default CompanyDetailForm;
