import React from "react";
import LabourChargeForm from "./components/labourChargeForm";
import LabourChargesList from "./components/labourChargesList";

const LabourCharges = () => {
  return (
    <div className="card">
      <div className="card-header">
        <h6 className="mb-0 text-uppercase py-2">LABOUR CHARGES</h6>
      </div>
      <div className="card-body">
        <LabourChargeForm />

        <LabourChargesList />
      </div>
    </div>
  );
};

export default LabourCharges;
