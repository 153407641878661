import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Scrollbars } from "react-custom-scrollbars";
import Button from "react-bootstrap/Button";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showError, showSuccess } from "../../utils/alertMessage";
import phoneCode from "../../utils/phoneCode.json";
import {
    GET_STATE,
    GET_VEHICLEMODEL,
    GET_VEHICLESERIES,
    GET_COUNTRY,
    GET_VEHICLES,
    GET_ASSETS
} from "../../store/commonApiStore/actionTypes";
import { REGOEXIST } from "../../store/unassignedJobs/actionTypes";
import {
    GET_CUSTOM_FIELDS,
    CHECK_VIN_EXIST,
    CHECK_CHASIS_EXIST,
    CHECK_ENGINE_EXIST,
    CHECK_FLEET_EXIST,
    GET_ASSETS_MASTER,
    GET_ASSETS_DETAILS,
    ADD_ASSETS_DETAILS,
    ADD_ASSETS_MASTER,
    ADD_ASSETS
} from "../../store/assets/actionTypes";
import {
    CHECK_EMAILEXIST,
    GET_SELECTED_CUSTOMERLIST
} from "../../store/userProfile/actionTypes";
import {
    ADD_CUSTOMER,
    CHECK_PHONE_EXIST,
    CHECK_MOBILE_EXIST
} from "../../store/contacts/actionTypes";
import LoaderImage from "../../assets/images/page-loader.svg";
import { getSelectedCustomerListSuccess } from "../../store/userProfile/actions";
import {
    getUserProfile
} from "../../helpers/backendHelper";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker } from "antd";
import { serializeArr } from "../../utils/unserialize";
import {GET_REGO_LOOKUP_DATA} from "store/jobDetails/actionTypes";
import { GET_UPDATE_VEHICLE } from 'store/vehicles/actionTypes';

import RegoLookup from "./regoLookup";
import { getRegoLookUpDataSuccess } from 'store/jobDetails/actions';
import { getUpdateVehicleSuccess } from 'store/vehicles/actions';
import {getStateAbbreviations} from "utils/stateAbbreviations";

const CustomerAndVehicle = ({ showModal, setShowModal, selectedUser, setManageStateModal, customerFormType, setCustomerDetails, setVehicleDetails }) => {

    const md5 = require("md5");
    const [lookUpShow, setLookUpShow] = useState(false);
    const [activeTab, setActiveTab] = useState("customers");
    const [fieldShow, setFieldShow] = useState(false);
    const [selectConsumer, setSelectConsumer] = useState("Individual");
    const [validateCustomersFrom, setValidateCustomersFrom] = useState(false);
    const [validateVehiclesFrom, setValidateVehiclesFrom] = useState(false);
    const [VehicleModelData, setVehicleModeldata] = useState([]);
    const [VehicleSeriesData, setVehicleSeriesdata] = useState([]);
    const [transmissionData, setTransmissionData] = useState([]);
    const [engineTypeData, setEngineTypeData] = useState([]);
    const [typeData, setTypeData] = useState([]);

    const dispatch = useDispatch();
    const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
    let defaultCountryId = storedData.country;
    
    let workshopId = window.atob(storedData.workshop_id);
    let assets_type = storedData.assets_type;
    let date_format = storedData.date_format;
    let listArr = [];

    const currentYear = parseInt(new Date().getFullYear());
    const { country, vehicles, vehicleModel, state, loadingLogins, vehicleSeries, assetsDetails } = useSelector((state) => state.CommonApiStoreReducer);
    const { regoExist } = useSelector((state) => state.UnassignedJobsReducer);
    const customFields = useSelector((state) => state.AssetsReducer.customFieldsData);
    const phoneExist = useSelector((state) => state.ContactsReducer.checkPhoneData);
    const MobileExist = useSelector((state) => state.ContactsReducer.checkMobileData);
    const { emailExist, customerList } = useSelector((state) => state.UserProfileReducer);
    const customerResp = useSelector((state) => state.ContactsReducer)

    const vinExist = useSelector((state) => state.AssetsReducer.checkVinData);
    const chasisExist = useSelector((state) => state.AssetsReducer.checkChasisData);
    const engineExist = useSelector((state) => state.AssetsReducer.checkEngineData);
    const fleetExist = useSelector((state) => state.AssetsReducer.checkFleetData);
    
    const assetsMasterDetails = useSelector((state) => state.AssetsReducer.assetsMasterData);
    const assetsDetailsData = useSelector((state) => state.AssetsReducer.assetsDetailsData);
    const addAssetsDetails = useSelector((state) => state.AssetsReducer.addAssetsDetails);
    const addAssetsMaster = useSelector((state) => state.AssetsReducer.addAssetsMaster);
    const addAssetsData = useSelector((state) => state.AssetsReducer);
    // const addAssetsDataErrorMsg = useSelector((state) => state.AssetsReducer.addAssetsData);

    const [assetsMaster, setAssetsMaster] = useState(false);
    const [assetsDetailsFlag, setAssetsDetailsFlag] = useState(false);
    const [addAssetsDetailsFlag, setAddAssetsDetailsFlag] = useState(false);
    const [addAssetsMasterFlag, setAddAssetsMasterFlag] = useState(false);
    const [assetsMasterId, setAssetsMasterId] = useState();
    const [assetsDetailsId, setAssetsDetailsId] = useState();
    const [makeValue, setMakeValue] = useState();
    const [modelValue, setModelValue] = useState();
    const [vehicleAddFlag, setVehicleAddFlag] = useState(false);
    const [displayVehicleMsg, setDisplayVehicleMsg] = useState(false);
    const [saveAndNext, setSaveAndNext] = useState(false);
    const [save, setSave] = useState(false);
    const [customerCreatedFlag, setCustomerCreatedFlag] = useState(false);
    

    //// rego lookup variables
    const [regoLookUpModalCV, setRegoLookUpModalCV] = useState(true);
    
    const handleCloseRegoLookupModalCV = () => {
        setRegoLookUpModalCV(false);
        dispatch(getRegoLookUpDataSuccess(null))
    }

    const handleShowRegoLookupModalCV = () => setRegoLookUpModalCV(true);
    const [vehicleRegoData, setVehicleRegoData] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const getVehicleHistory = useSelector((state) => state.JobDetails);
    const VehicleReducerData = useSelector((state) => state.VehicleReducer);
    
    useEffect(() => {
        if (country.length === 0) {
            dispatch({
                type: GET_COUNTRY,
                payload: { get: "all" },
            });
        }
        if (vehicles.length === 0) {
            dispatch({
                type: GET_VEHICLES,
                payload: {
                    get: "all",
                    group: "assets_make",
                },
            });
        }
        if (assetsDetails === null || assetsDetails === undefined) {
            dispatch({
                type: GET_ASSETS,
                payload: {
                    get: "all"
                },
            });
        }
        getCustomFields('Vehicle')
    }, [])

    useEffect(() => {
        if (customerResp.customerDetails !== null && customerResp.customerDetails !== undefined && customerCreatedFlag === true) {
            var custDetails = customerResp.customerDetails.customers_details[0];
            setVehiclesFormData({
                ...vehiclesFormData,
                customer_name1: custDetails.firstname + " " + custDetails.lastname,
                user_id: custDetails.user_id
            });
            if (custDetails.user_id !== undefined) {
                setCustomerDetails(customerResp.customerDetails);
                showSuccess('Customer Created Successfully!');
                setCustomerCreatedFlag(false);
                if (save === true && saveAndNext === false) {
                    handleCustomerClose();
                }
            }
        } else {
            if (customerResp.error.custMessage !== null) {
                showError(customerResp.error.custMessage);
            }
            setCustomerCreatedFlag(false)
        }
    }, [customerResp.customerDetails])

    useEffect(() => {
        let transmission = [];
        let engineType = [];
        let typeMarine = [];
        if (assetsDetails !== null) {
            assetsDetails.map((asset_type, index) => {
                if (asset_type.type === "4") {
                    transmission.push(asset_type)
                }
                if (asset_type.type === "6") {
                    engineType.push(asset_type)
                }
                if (asset_type.type === "7") {
                    typeMarine.push(asset_type)
                }
            })
        }
        setTransmissionData(transmission);
        setEngineTypeData(engineType);
        setTypeData(typeMarine);
    }, [assetsDetails])

    const [phone_code, setPhone_code] = useState("");
    const [customersFormData, setCustomersFormData] = useState({
        first_name: "",
        last_name: "",
        company_name: "",
        abn: "",
        phone_number: "",
        email: "",
        website: "",
        position: "",
        country: "",
        phone_code: "",
        mobile_number: "",
        street_address: "",
        suburb: "",
        postcode: "",
        state: 0,
        is_serialize: 1,
        customer_status: 1,
        payment_method: 1,
        customer_type: 1,
        payment_term: 1,
    });

    const [vehiclesFormData, setVehiclesFormData] = useState({
        asset_type_val: "Vehicle",
        rego_val: "",
        id: "",
        customer_name1: "",
        customer_name: "",
        is_verified: 0,
        make: "",
        model: "",
        model_id: "",
        model_series: "",
        model_series_id: "",
        fuel_type: "",
        body_type: "",
        year: "",
        transmission: "",
        category: "",
        colour: "",
        seat_capacity: "",
        tyre_size_front: "",
        tyre_size_rear: "",
        vin_number: "",
        chasis_number: "",
        engine_number: "",
        fleet_number: "",
        odometer_reading: "",
        serial_no: "",
        engine_type: "",
        marine_type: "",
        engine_hours: "",
        engine_size: "",
    });

    useEffect(() => {

        if (Object.keys(selectedUser).length !== 0) {
            setVehiclesFormData({
                ...vehiclesFormData,
                customer_name1: selectedUser.name,
                user_id: selectedUser.user_id
            });
    
            if (selectedUser.country_id !== "") {
                const newLocalCountry = selectedUser.country_id;
                defaultCountryId = newLocalCountry;
            }
            
            var tempCustomer = customersFormData;
            tempCustomer.first_name = selectedUser.firstname;
            tempCustomer.last_name = selectedUser.lastname;
            tempCustomer.phone_number = selectedUser.phone;
            tempCustomer.email = selectedUser.email;
            tempCustomer.mobile_number = selectedUser.mobile;
            tempCustomer.street_address = selectedUser.address;
            tempCustomer.suburb = selectedUser.suburb;
            tempCustomer.postcode = selectedUser.postcode;
            tempCustomer.state = selectedUser.state_id;
            if (selectedUser.type === "1") {
                tempCustomer.company_name = selectedUser.companyname;
                tempCustomer.abn = selectedUser.abn;
                tempCustomer.website = selectedUser.website;
                tempCustomer.position = selectedUser.position;
                setSelectConsumer('Company')
            } else {
                setSelectConsumer('Individual')
            }
            setCustomersFormData(tempCustomer);
        }

        if (Object.keys(selectedUser).length !== 0 && customerFormType === "edit") {
            setActiveTab("vehicles");
        } else if (customerFormType === "view" && Object.keys(selectedUser).length !== 0) {
            var tempVehicleData = vehiclesFormData;
            tempVehicleData.rego_val = selectedUser.rego;
            tempVehicleData.make = selectedUser.make;
            tempVehicleData.model = selectedUser.model;
            setVehiclesFormData(tempVehicleData);
            setActiveTab("customers");
        } else { 
            setActiveTab("customers");
        }

    }, [selectedUser]);
    
    useEffect(() => {
        if (country.length !== 0) {
            let countryId =
                customersFormData.country === ""
                    ? defaultCountryId
                    : customersFormData.country;
            let selectedCountry = country.find(({ id, country_name }) => {
                if (id == countryId) {
                    return country_name;
                }
            });
            getPhoneCode(selectedCountry.country_name);
            getState(selectedCountry.id);
        }
    }, [country, customersFormData.country]);

    const getPhoneCode = (countryName) => {
        if (phoneCode.length !== 0) {
            phoneCode.find(({ name, dial_code }) => {
                if (name == countryName) {
                    setPhone_code(dial_code);
                }
            });
        }
    };

    const getState = (countryId) => {

        dispatch({
            type: GET_STATE,
            payload: {
                get: "all",
                conditions: {
                    country_id: countryId,
                    is_deleted: 0,
                },
            },
        });
        setCustomersFormData({ ...customersFormData, country: countryId });
    };

    useEffect(() => {
        if (
            (vehiclesFormData.asset_type_val === "Vehicle" ||
                vehiclesFormData.asset_type_val === "") &&
            vehiclesFormData.make !== ""
        ) {
            dispatch({
                type: GET_VEHICLEMODEL,
                payload: {
                    get: "all",
                    conditions: {
                        assets_make: vehiclesFormData.make,
                    },
                },
            });
        }
    }, [vehiclesFormData.make]);

    useEffect(() => {
        if (vehiclesFormData.asset_type_val === "Vehicle" || vehiclesFormData.asset_type_val === "") {
            dispatch({
                type: GET_VEHICLESERIES,
                payload: {
                    get: "all",
                    conditions: {
                        asset_master_id: vehiclesFormData.model_id
                    },
                    group: ['series'],
                    fields: ['id', 'series', 'asset_master_id']
                },
            });
            if (vehicleModel.length != 0) {
                let filteredData = [];
                vehicleModel.filter((el) => {
                    if (el.assets_model.toLowerCase().includes(vehiclesFormData.model))
                        filteredData.push(el);
                });
                setVehicleModeldata(filteredData);
            }
        }
    }, [vehiclesFormData.model]);

    useEffect(() => {
        if (vehiclesFormData.asset_type_val === "Vehicle" || vehiclesFormData.asset_type_val === "") {
            if (vehicleSeries.length !== 0) {
                let filteredSeriesData = [];
                vehicleSeries.filter((el) => {
                    if (el.series.toLowerCase().includes(vehiclesFormData.model_series))
                        filteredSeriesData.push(el);
                });
                setVehicleSeriesdata(filteredSeriesData);
            }
        }
    }, [vehiclesFormData.model_series]);

    useEffect(() => {
        if (vehiclesFormData.rego_val !== "") {
            dispatch({
                type: REGOEXIST,
                payload: {
                    get: "all",
                    conditions: {
                        "Vehicles.workshop_id": workshopId,
                        "Vehicles.rego": vehiclesFormData.rego_val,
                    },
                },
            });
        }
    }, [vehiclesFormData.rego_val]);

    const checkRegoExist = (e) => {
        if (e.target.value !== "") {
            dispatch({
                type: REGOEXIST,
                payload: {
                    get: "all",
                    conditions: {
                        "Vehicles.workshop_id": workshopId,
                        "Vehicles.rego": e.target.value,
                    },
                },
            });
        }
    }

    const handleLookupShow = () => {
        setLookUpShow(!lookUpShow);
    };
    const expandFields = () => {
        setFieldShow(!fieldShow);
    };
    const handleCustomerClose = () => {
        setShowModal(false)
        setManageStateModal('parent')
    };

    const handleCustomersInputChange = (e) => {
        const { name, value } = e.target;
        setCustomersFormData({
            ...customersFormData,
            [name]: value,
        });
    };

    const handleVehiclesInputChange = (e) => {
        const { name, value } = e.target;
        console.log(name);
        console.log(value);
        setVehiclesFormData({
            ...vehiclesFormData,
            [name]: value,
        });
        if (name === "asset_type_val") {
            if (name === "asset_type_val" && (value !== "" || value !== "Vehicle")) {
                setFieldShow(true);
            }
            getCustomFields(value);
        }

        if (name === "customer_name1" && value.length > 2) {
            dispatch({
                type: GET_SELECTED_CUSTOMERLIST,
                payload: {
                    get: "all",
                    fields: [
                        "CustomersDetails.id",
                        "CustomersDetails.type",
                        "CustomersDetails.firstname",
                        "CustomersDetails.lastname",
                        "CustomersDetails.company_name",
                        "CustomersDetails.mobile_number",
                        "CustomersDetails.user_id",
                        "Users.email",
                        "Countries.id",
                        "Countries.country_name",
                        "States.id",
                        "States.name",
                    ],
                    order: {
                        "CustomersDetails.firstname": "asc",
                    },
                    conditions: {
                        "CustomersDetails.workshop_id": workshopId,
                        role_id: 4,
                        "CustomersDetails.is_deleted": 0,
                        "CustomersDetails.status": 1,
                        OR: [
                            `CustomersDetails.firstname LIKE '%${value}%'`,
                            `CustomersDetails.lastname LIKE '%${value}%'`,
                            `CONCAT(CustomersDetails.firstname, ' ', CustomersDetails.lastname) like'%${value}%'`,
                            `CustomersDetails.company_name LIKE '%${value}%'`,
                        ],
                    },
                    contain: ["Users", "Countries", "States"],
                },
            });
        }
    };

    const getCustomFields = (value) => {
        dispatch({
            type: GET_CUSTOM_FIELDS,
            payload: {
                get: "all",
                conditions: {
                    workshop_id: workshopId,
                    vehicle_type: value,
                },
            },
        });
    }

    const validatecustomerFrom = () => {
        if (selectConsumer === "Individual") {
            if (
                customersFormData.first_name === "" ||
                customersFormData.last_name === "" ||
                customersFormData.country === "" ||
                customersFormData.mobile_number.length < 5
            ) {
                setValidateCustomersFrom(true);
                return true;
            } else {
                setValidateCustomersFrom(false);
                return false;
            }
        } else {
            if (
                customersFormData.company_name === "" ||
                customersFormData.country === "" ||
                customersFormData.mobile_number.length < 5
            ) {
                setValidateCustomersFrom(true);
                return true;
            } else {
                setValidateCustomersFrom(false);
                return false;
            }
        }
    };

    const validateVehicleFrom = () => {
        if (
            vehiclesFormData.asset_type_val === "" ||
            vehiclesFormData.rego_val === "" ||
            vehiclesFormData.customer_name1 === ""
        ) {
            setValidateVehiclesFrom(true);
            return true;
        } else {
            setValidateVehiclesFrom(false);
            return false;
        }
    };

    const handleSave = async () => {
        if (!validatecustomerFrom()) {
            let customerType = 2;
            if (selectConsumer === "Company") {
                customerType = 1
            }
            let email = "";
            if (customersFormData.email === "") {
                let getEmail = {
                    payload: {
                        get: "all",
                        conditions: {
                            workshop_id: workshopId,
                            "email LIKE": "%newcustomer-%",
                        },
                        limit: 1,
                        order: {
                            id: "DESC",
                        },
                    },
                };
                let emailNo = "";

                await getUserProfile(getEmail).then((res) => {
                    emailNo = res.data.Users[0].email.substring(
                        12,
                        res.data.Users[0].email.indexOf("@")
                    );
                    email = res.data.Users[0].email.replace(
                        emailNo,
                        parseInt(emailNo) + 1
                    );
                });
            } else {
                email = customersFormData.email;
            }
            setCustomerCreatedFlag(true);
            dispatch({
                type: ADD_CUSTOMER,
                payload: {
                    workshop_id: workshopId,
                    role_id: 4,
                    email: email,
                    country: customersFormData.country,
                    state: customersFormData.state,
                    password: md5(Math.random().toString(36).slice(-8)),
                    customers_details: [{
                        workshop_id: workshopId,
                        firstname: customersFormData.first_name,
                        lastname: customersFormData.last_name,
                        type: customerType,
                        is_onsite: 0,
                        phone_number: customersFormData.phone_number,
                        country: customersFormData.country,
                        state: customersFormData.state,
                        source_of_business: "",
                        customer_status: 1,
                        customer_type: customersFormData.customer_type,
                        payment_method: 1,
                        payment_terms: "",
                        is_days_from_invoice: 0,
                        is_days_from_eom: 0,
                        customer_app: 0,
                        mobile_code: phone_code,
                        mobile_number: customersFormData.mobile_number,
                        street_address: customersFormData.street_address,
                        suburb: customersFormData.suburb,
                        postcode: customersFormData.postcode,
                        onsite_street_address: "",
                        onsite_suburb: "",
                        onsite_state: "",
                        onsite_postcode: "",
                        onsite_country: "",
                        position: customersFormData.position,
                        company_name: customersFormData.company_name,
                        abn: customersFormData.abn,
                        website: customersFormData.website
                    }],
                }
            })
            if (saveAndNext === true) {
                setSave(false);    
            } else {
                setSave(true);
            }
        }
    };

    const handleSaveNext = () => {
        if (activeTab === "customers") {
            if (!validatecustomerFrom()) {
                setActiveTab("vehicles");
                setSaveAndNext(true);
                handleSave();
            }
        } else {
            if (!validateVehicleFrom()) {
                saveVehicleDetails();
                
                // handleCustomerClose();
            }
        }
    };

    useEffect(() => {
        if (assetsMaster === true) {
            if (assetsMasterDetails !== null && assetsMasterDetails !== undefined) {
                var model = assetsMasterDetails?.[0]?.assets_model || '';
                var make = assetsMasterDetails?.[0]?.assets_make || '';
                setMakeValue(make);
                setModelValue(model);
                var assets_master_id = assetsMasterDetails?.[0]?.id || '';
                setAssetsMasterId(assets_master_id);
                dispatch({
                    type: GET_ASSETS_DETAILS,
                    payload: {
                        get: 'all',
                        conditions: {
                            asset_master_id: assets_master_id,
                            fuel_type: vehiclesFormData.fuel_type,
                            series: vehiclesFormData.model_series
                        },
                        limit: 1
                    }
                })
                setAssetsDetailsFlag(true)
            } else {
                dispatch({
                    type: ADD_ASSETS_MASTER,
                    payload: {
                        assets_model: vehiclesFormData.model,
                        assets_make: vehiclesFormData.make
                    }
                })
                setAddAssetsMasterFlag(true);
            }
            setAssetsMaster(false)
        }
    }, [assetsMasterDetails])

    useEffect(() => {
        if (assetsDetailsFlag === true) {
            if (assetsDetailsData !== null && assetsDetailsData !== undefined) {
                var assets_details_id = assetsDetailsData?.[0]?.id || '';
                setAssetsDetailsId(assets_details_id);
                setVehicleAddFlag(true);
            } else {
                let assetsDetailsArr = {};
                assetsDetailsArr['assets_master_id'] = assetsMasterId;
                assetsDetailsArr['fuel_type'] = vehiclesFormData.fuel_type;
                assetsDetailsArr['row_data'] = vehiclesFormData.body_type + '$-$' + vehiclesFormData.year + '$-$';
                assetsDetailsArr['series'] = vehiclesFormData.model_series;
                assetsDetailsArr['is_in_au'] = 1;
                assetsDetailsArr['is_in_nz'] = 1;
                dispatch({
                    type: ADD_ASSETS_DETAILS,
                    payload: assetsDetailsArr
                })
                setAddAssetsDetailsFlag(true);
            }
            setAssetsDetailsFlag(false)
        }
    }, [assetsDetailsFlag])

    useEffect(() => {
        if (addAssetsDetailsFlag === true) {
            if (addAssetsDetails !== null && addAssetsDetails !== undefined) {
                var assets_details_id = addAssetsDetails?.id || '';
                setAssetsDetailsId(assets_details_id);
                setVehicleAddFlag(true);                
            } else {
                setVehicleAddFlag(true);
            }
            setAddAssetsDetailsFlag(false)
        }
    }, [addAssetsDetails])

    useEffect(() => {
        if (addAssetsMasterFlag === true) {
            if (addAssetsMaster !== null && addAssetsMaster !== undefined) {
                var assets_master_id = addAssetsMaster?.id || '';
                setAssetsMasterId(assets_master_id);
                let assetsDetailsArr = {};
                assetsDetailsArr['assets_master_id'] = assets_master_id;
                assetsDetailsArr['fuel_type'] = vehiclesFormData.fuel_type;
                assetsDetailsArr['row_data'] = vehiclesFormData.body_type + '$-$' + vehiclesFormData.year + '$-$';
                assetsDetailsArr['series'] = vehiclesFormData.model_series;
                assetsDetailsArr['is_in_au'] = 1;
                assetsDetailsArr['is_in_nz'] = 1;
                dispatch({
                    type: ADD_ASSETS_DETAILS,
                    payload: assetsDetailsArr
                })
                setAddAssetsDetailsFlag(true)
            } else {
                setVehicleAddFlag(true);
            }
            setAddAssetsMasterFlag(false);
        }
    }, [addAssetsMaster])

    useEffect(() => {
        if (vehicleAddFlag === true) {
            var vehicleArr = {}
            vehicleArr['workshop_id'] = workshopId;
            vehicleArr['user_id'] = vehiclesFormData.user_id;
            vehicleArr['rego'] = vehiclesFormData.rego_val;
            vehicleArr['make'] = makeValue;
            vehicleArr['model'] = modelValue;
            vehicleArr['make_icon'] = "fa fa-question-circle";
            vehicleArr['year'] = vehiclesFormData.year;
            vehicleArr['fuel_type'] = vehiclesFormData.fuel_type;
            vehicleArr['assets_master_id'] = assetsMasterId;
            vehicleArr['assets_detail_id'] = assetsDetailsId;
            vehicleArr['transmission'] = vehiclesFormData.transmission;
            vehicleArr['body_type'] = vehiclesFormData.body_type;
            vehicleArr['odometer_reading'] = vehiclesFormData.odometer_reading !== '' ? vehiclesFormData.odometer_reading : 0;
            vehicleArr['color'] = vehiclesFormData.colour;
            vehicleArr['vin_number'] = vehiclesFormData.vin_number;
            vehicleArr['chasis_number'] = vehiclesFormData.chasis_number;
            vehicleArr['engine_number'] = vehiclesFormData.engine_number;
            vehicleArr['fleet_number'] = vehiclesFormData.fleet_number;
            vehicleArr['vehicle_type'] = vehiclesFormData.asset_type_val;
            vehicleArr['engine_type'] = vehiclesFormData.engine_type;
            vehicleArr['engine_hours'] = vehiclesFormData.engine_hours;
            vehicleArr['engine_size'] = vehiclesFormData.engine_size;
            vehicleArr['marine_type'] = vehiclesFormData.marine_type;
            vehicleArr['serial_no'] = vehiclesFormData.serial_no;
            vehicleArr['category'] = vehiclesFormData.category;
            vehicleArr['is_mot_wof'] = 0;
            vehicleArr['seat_capacity'] = vehiclesFormData.seat_capacity;
            vehicleArr['tyre_size_front'] = vehiclesFormData.tyre_size_front;
            vehicleArr['tyre_size_rear'] = vehiclesFormData.tyre_size_rear;
            vehicleArr['series'] = vehiclesFormData.model_series;
            vehicleArr['is_verified'] = vehiclesFormData.is_verified;
            
            if (customFields !== null) {
                var allFieldName = {};
                customFields.map((customField, index) => {
                    var fieldName = customField.name;
                    allFieldName[fieldName] = vehiclesFormData[fieldName];
                })
                var fieldStr = serializeArr(allFieldName);
                vehicleArr['vehicle_custom_fields'] = fieldStr.replace(/\\/g, '');
            }
            setDisplayVehicleMsg(true)
            dispatch({
                type: ADD_ASSETS,
                payload: vehicleArr
            })
            
            setVehicleAddFlag(false)
        }
    }, [vehicleAddFlag])

    useEffect(() => {
        if (addAssetsData.addAssetsData !== null && addAssetsData.addAssetsData !== undefined) {
            if (displayVehicleMsg === true) {
                showSuccess('Vehicle Created Successfully!');
                setVehicleDetails(addAssetsData.addAssetsData);
                handleCustomerClose();
                setDisplayVehicleMsg(false)
            }
        } else {
            if (addAssetsData.error.vehicleMsg !== null) {
                if (displayVehicleMsg === true) {
                    showError(addAssetsData.error.vehicleMsg);
                    // setDisplayVehicleMsg(false)
                }
            }
        }
    }, [addAssetsData, displayVehicleMsg])
    
    const saveVehicleDetails = () => {
        setModelValue(vehiclesFormData.model)
        setMakeValue(vehiclesFormData.make);
        dispatch({
            type: GET_ASSETS_MASTER,
            payload: {
                get: "all",
                conditions: {
                    assets_model: vehiclesFormData.model,
                    assets_make: vehiclesFormData.make
                },
                limit: 1
            },
        });
        setAssetsMaster(true);
    }

    const checkEmailExist = (e) => {
        dispatch({
            type: CHECK_EMAILEXIST,
            payload: {
                get: "all",
                conditions: {
                    email: e.target.value,
                },
            },
        });
    };

    const checkPhoneExist = (e) => {
        dispatch({
            type: CHECK_PHONE_EXIST,
            payload: {
                get: "all",
                conditions: {
                    "phone_number": e.target.value,
                    "is_deleted": 0,
                    "workshop_id": workshopId
                }
            },
        });
    };

    const checkMobileExist = (e) => {
        dispatch({
            type: CHECK_MOBILE_EXIST,
            payload: {
                get: "all",
                conditions: {
                    "mobile_number": e.target.value,
                    "is_deleted": 0,
                    "workshop_id": workshopId
                }
            },
        });
    };

    const checkVinExist = (e) => {
        dispatch({
            type: CHECK_VIN_EXIST,
            payload: {
                get: "all",
                conditions: {
                    "vin_number": e.target.value,
                    "is_deleted": 0,
                    "workshop_id": workshopId
                }
            },
        });
    };

    const checkEngineExist = (e) => {
        dispatch({
            type: CHECK_ENGINE_EXIST,
            payload: {
                get: "all",
                conditions: {
                    "engine_number": e.target.value,
                    "is_deleted": 0,
                    "workshop_id": workshopId
                }
            },
        });
    };

    const checkChasisExist = (e) => {
        dispatch({
            type: CHECK_CHASIS_EXIST,
            payload: {
                get: "all",
                conditions: {
                    "chasis_number": e.target.value,
                    "is_deleted": 0,
                    "workshop_id": workshopId
                }
            },
        });
    };

    const checkFleetExist = (e) => {
        dispatch({
            type: CHECK_FLEET_EXIST,
            payload: {
                get: "all",
                conditions: {
                    "fleet_number": e.target.value,
                    "is_deleted": 0,
                    "workshop_id": workshopId
                }
            },
        });
    };


    useEffect(() => {
         

        if(typeof getVehicleHistory.regoLookUpData !== "undefined" && getVehicleHistory.regoLookUpData !== null){
            if(getVehicleHistory.regoLookUpData.code === 200) {
                setIsLoading(false);
                setRegoLookUpModalCV(true);
                setVehicleRegoData(getVehicleHistory.regoLookUpData.data.Vehicles);
            } else {

                setRegoLookUpModalCV(false);
                setIsLoading(false);
                showError("Rego/Plate not found!");
                dispatch(getRegoLookUpDataSuccess(null))

            }
        } else {
            setRegoLookUpModalCV(false);
            setIsLoading(false);
        }

        

    },[getVehicleHistory]);


    const regoLookUpCV = () => {

        console.log('regoLookUpCVregoLookUpCVregoLookUpCVregoLookUpCVregoLookUpCV');

       
        if(vehiclesFormData.rego_val === ""){
            showError("Please enter rego number");
            return;
        }

        var statedata = document.getElementById("state");
        var stateval = statedata.options[statedata.selectedIndex].text;
       
        var countrydata = document.getElementById("country");
        var countryval = countrydata.options[countrydata.selectedIndex].text;

        let state = "QLD";
        let country = "Australia";
        let rego = vehiclesFormData.rego_val;

        console.log('stateval --> ' + stateval);
        console.log('country --> ' + country);
        console.log('rego --> ' + rego);
        
        dispatch({
            type: GET_REGO_LOOKUP_DATA,
            payload: {
                country: country,
                state: state,
                licenceplate: rego,
                workshop_id:workshopId
            },
        });

        setIsLoading(true);
        
    };

    // useEffect(() => {

    //     if(typeof VehicleReducerData.vehicleData !== "undefined" && VehicleReducerData.vehicleData !== null){

    //         if(VehicleReducerData.vehicleData.code === 200){
    //             showSuccess("Vehicles is updated");
    //             setIsLoading(false);
    //             dispatch(getUpdateVehicleSuccess(null))
    //             handleCloseRegoLookupModal();
    //         } else {
    //             showError("Vehicles not updated");
    //             setIsLoading(false);
    //             dispatch(getUpdateVehicleSuccess(null))
    //             setRegoLookUpModalCV(false);
    //             handleCloseRegoLookupModal();
    //         }
    //     }

    // },[VehicleReducerData]);

    function setVehicleDetailDataCV(){


        console.log('setVehicleDetailDataCV setVehicleDetailDataCV setVehicleDetailDataCV');

        if(typeof getVehicleHistory.regoLookUpData !== "undefined" && getVehicleHistory.regoLookUpData !== null){
        
            const vehicle_data = vehicleRegoData;
            console.log(vehicleRegoData);   


            setVehiclesFormData({
                ...vehiclesFormData,
                'make': vehicleRegoData.make,
                'model': vehicleRegoData.model,
                'model_series': vehicleRegoData.series,
                'fuel_type': vehicleRegoData.fuel_type,
                'body_type': vehicleRegoData.body_type,
                'year': vehicleRegoData.year,
                'transmission': vehicleRegoData.transmission,
                'category': vehicleRegoData.category,
                'is_verified': 1
            });
            
            setRegoLookUpModalCV(false);
            setIsLoading(false);
            dispatch(getUpdateVehicleSuccess(null))
            handleCloseRegoLookupModalCV();

            console.log(vehiclesFormData);
            
            // vehicle_data.is_verified = 1;

            // dispatch({
            //     type: GET_UPDATE_VEHICLE,
            //     payload: vehicle_data,
            // });
        }
    
    }


    return (
        <>
            {loadingLogins === true && (
                <div className="page-loader d-flex align-center justify-center">
                    <img src={LoaderImage} alt="Loading"></img>
                </div>
            )}

            {setIsLoading === true && (
                <div className="page-loader d-flex align-center justify-center">
                    <img src={LoaderImage} alt="Loading"></img>
                </div>
            )}

            <Modal
                className="add-customer-vehicle"
                size="md"
                show={showModal}
                onHide={handleCustomerClose}
                centered
            >
                <Modal.Body>
                    <div className="custom-tabs">
                        <Tabs
                            defaultActiveKey="customers"
                            activeKey={activeTab}
                            onSelect={(k) => setActiveTab(k)}
                        >
                            <Tab eventKey="customers" title="Customers">
                                <Scrollbars
                                    autoHeight
                                    autoHeightMax={300}
                                    className="custom-scrollbar"
                                    style={{ width: "100%" }}
                                    renderThumbVertical={(props) => (
                                        <div className="thumb-vertical grey-thumb" />
                                    )}
                                    renderTrackHorizontal={(props) => (
                                        <div style={{ display: "none" }} />
                                    )}
                                >
                                    <div className="content-wrapper">
                                        <div className="radio-wrapper">
                                            <Form.Check
                                                className="custom-check radio-btn secondary-radio mb-2"
                                                type="radio"
                                                id="1"
                                            >
                                                <Form.Check.Input
                                                    name="csvpackage"
                                                    type="radio"
                                                    isValid
                                                    onChange={() => setSelectConsumer("Individual")}
                                                    checked={
                                                        selectConsumer === "Individual" ? true : false
                                                    }
                                                    value="1"
                                                />
                                                <Form.Check.Label>
                                                    Individual
                                                    <span className="check"></span>
                                                </Form.Check.Label>
                                            </Form.Check>
                                            <Form.Check
                                                className="custom-check radio-btn secondary-radio mb-2"
                                                type="radio"
                                                id="2"
                                            >
                                                <Form.Check.Input
                                                    name="csvpackage"
                                                    type="radio"
                                                    isValid
                                                    onChange={() => setSelectConsumer("Company")}
                                                    checked={selectConsumer === "Company" ? true : false}
                                                    value="2"
                                                />
                                                <Form.Check.Label>
                                                    Company
                                                    <span className="check"></span>
                                                </Form.Check.Label>
                                            </Form.Check>
                                        </div>

                                        {selectConsumer === "Individual" ? (
                                            <Form validated={validateCustomersFrom}>
                                                <div className="form-outer form-align-start">
                                                    <Form.Group className="form-group width-50 mb-3">
                                                        <label>First Name *</label>
                                                        <Form.Control
                                                            type="text"
                                                            required
                                                            name="first_name"
                                                            value={customersFormData.first_name}
                                                            onChange={(e) => handleCustomersInputChange(e)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            This field is required.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group className="form-group width-50 mb-3">
                                                        <label>Last Name *</label>
                                                        <Form.Control
                                                            type="text"
                                                            required
                                                            name="last_name"
                                                            value={customersFormData.last_name}
                                                            onChange={(e) => handleCustomersInputChange(e)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            This field is required.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group className="form-group width-50 mb-3">
                                                        <label>Main Phone</label>
                                                        <Form.Control
                                                            type="text"
                                                            name="phone_number"
                                                            className={
                                                                phoneExist && "form-control is-invalid"
                                                            }
                                                            onChange={(e) => handleCustomersInputChange(e)}
                                                            onBlur={(e) => checkPhoneExist(e)}
                                                            value={customersFormData.phone_number}
                                                        />
                                                        {phoneExist &&
                                                            <div className="invalid-feedback">
                                                                This Phone number is already registered.
                                                            </div>
                                                        }
                                                    </Form.Group>
                                                    <Form.Group className="form-group width-50 mb-3">
                                                        <label>Email Address</label>
                                                        <Form.Control
                                                            type="text"
                                                            name="email"
                                                            className={
                                                                emailExist && "form-control is-invalid"
                                                            }
                                                            onChange={(e) => handleCustomersInputChange(e)}
                                                            onBlur={(e) => checkEmailExist(e)}
                                                            value={customersFormData.email}
                                                        />
                                                        {emailExist && (
                                                            <div className="invalid-feedback">
                                                                This email is already registered.
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                    <div className="form-group custom-normal-select width-50 mb-3">
                                                        <label>Country *</label>
                                                        <Form.Select
                                                            aria-label="Default select example"
                                                            required
                                                            name="country"
                                                            id="country"
                                                            onChange={(e) => handleCustomersInputChange(e)}
                                                            value={customersFormData.country}
                                                        >
                                                            <option value="">Select Country</option>
                                                            {country.length !== 0 &&
                                                                country.map(({ id, country_name }, index) => (
                                                                    <option
                                                                        key={index}
                                                                        value={id}
                                                                        selected={
                                                                            id === defaultCountryId ? true : false
                                                                        }
                                                                    >
                                                                        {country_name}
                                                                    </option>
                                                                ))}
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            This field is required.
                                                        </Form.Control.Feedback>
                                                    </div>
                                                    <div className="mobile-wrapper form-group width-50 mb-3">
                                                        <Form.Group className="mobile-code-input form-group">
                                                            <label>Code</label>
                                                            <Form.Control
                                                                type="text"
                                                                name="phone_code"
                                                                value={phone_code}
                                                                onChange={(e) => handleCustomersInputChange(e)}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group className="form-group">
                                                            <label>Mobile No. *</label>
                                                            <Form.Control
                                                                type="text"
                                                                required
                                                                minLength={6}
                                                                className={
                                                                    MobileExist && "form-control is-invalid"
                                                                }
                                                                name="mobile_number"
                                                                onChange={(e) => handleCustomersInputChange(e)}
                                                                onBlur={(e) => checkMobileExist(e)}
                                                                value={customersFormData.mobile_number}
                                                            />
                                                            {MobileExist &&
                                                                <div className="invalid-feedback">
                                                                    This Mobile Number is already registered.
                                                                </div>
                                                            }
                                                            {
                                                                (MobileExist === false) ?
                                                                    <Form.Control.Feedback type="invalid">
                                                                        Please enter at least 6 characters.
                                                                    </Form.Control.Feedback> : ''
                                                            }
                                                        </Form.Group>
                                                    </div>
                                                    <Form.Group className="form-group mb-3">
                                                        <label>Street Address</label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={4}
                                                            name="street_address"
                                                            onChange={(e) => handleCustomersInputChange(e)}
                                                            value={customersFormData.street_address}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="form-group width-50 mb-3">
                                                        <label>Suburb</label>
                                                        <Form.Control
                                                            type="text"
                                                            name="suburb"
                                                            onChange={(e) => handleCustomersInputChange(e)}
                                                            value={customersFormData.suburb}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="form-group width-50 mb-3">
                                                        <label>Post code</label>
                                                        <Form.Control
                                                            type="text"
                                                            name="postcode"
                                                            onChange={(e) => handleCustomersInputChange(e)}
                                                            value={customersFormData.postcode}
                                                        />
                                                    </Form.Group>
                                                    <div className="form-group custom-normal-select">
                                                        <label>State</label>
                                                        <Form.Select
                                                            aria-label="Default select example"
                                                            name="state"
                                                            id="state"
                                                            onChange={(e) => handleCustomersInputChange(e)}
                                                        >
                                                            <option>Select State</option>
                                                            {state.length !== 0 &&
                                                                state.map(({ id, name }, index) => (
                                                                    <option key={id} value={id}>{name}</option>
                                                                ))}
                                                        </Form.Select>
                                                    </div>
                                                </div>
                                            </Form>
                                        ) : (
                                            <Form validated={validateCustomersFrom}>
                                                <div className="form-outer form-align-start">
                                                    <Form.Group className="form-group width-50 mb-3">
                                                        <label>Company Name *</label>
                                                        <Form.Control
                                                            type="text"
                                                            name="company_name"
                                                            onChange={(e) => handleCustomersInputChange(e)}
                                                            value={customersFormData.company_name}
                                                            required
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            This field is required.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group className="form-group width-50 mb-3">
                                                        <label>ABN</label>
                                                        <Form.Control
                                                            type="text"
                                                            name="abn"
                                                            onChange={(e) => handleCustomersInputChange(e)}
                                                            value={customersFormData.abn}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="form-group width-50 mb-3">
                                                        <label>Main Phone</label>
                                                        <Form.Control
                                                            type="text"
                                                            name="phone_number"
                                                            onChange={(e) => handleCustomersInputChange(e)}
                                                            value={customersFormData.phone_number}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="form-group width-50 mb-3">
                                                        <label>Email Address</label>
                                                        <Form.Control
                                                            type="text"
                                                            name="email"
                                                            className={
                                                                emailExist && "form-control is-invalid"
                                                            }
                                                            onChange={(e) => handleCustomersInputChange(e)}
                                                            value={customersFormData.email}
                                                        />
                                                        {emailExist && (
                                                            <div className="invalid-feedback">
                                                                This email is already registered.
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                    <Form.Group className="form-group width-50 mb-3">
                                                        <label>Website</label>
                                                        <Form.Control
                                                            type="text"
                                                            name="website"
                                                            onChange={(e) => handleCustomersInputChange(e)}
                                                            value={customersFormData.website}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="form-group width-50 mb-3">
                                                        <label>Position</label>
                                                        <Form.Control
                                                            type="text"
                                                            name="position"
                                                            onChange={(e) => handleCustomersInputChange(e)}
                                                            value={customersFormData.position}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="form-group width-50 mb-3">
                                                        <label>First Name</label>
                                                        <Form.Control
                                                            type="text"
                                                            name="first_name"
                                                            onChange={(e) => handleCustomersInputChange(e)}
                                                            value={customersFormData.first_name}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="form-group width-50 mb-3">
                                                        <label>Last Name</label>
                                                        <Form.Control
                                                            type="text"
                                                            name="last_name"
                                                            onChange={(e) => handleCustomersInputChange(e)}
                                                            value={customersFormData.last_name}
                                                        />
                                                    </Form.Group>
                                                    <div className="form-group custom-normal-select width-50 mb-3">
                                                        <label>Country *</label>
                                                        <Form.Select
                                                            aria-label="Default select example"
                                                            required
                                                            name="country"
                                                            value={customersFormData.country}
                                                            onChange={(e) => handleCustomersInputChange(e)}
                                                        >
                                                            <option value="">Select Country</option>
                                                            {country.length !== 0 &&
                                                                country.map(({ id, country_name }, index) => (
                                                                    <option
                                                                        key={index}
                                                                        value={id}
                                                                        selected={
                                                                            id == defaultCountryId ? true : false
                                                                        }
                                                                    >
                                                                        {country_name}
                                                                    </option>
                                                                ))}
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            This field is required.
                                                        </Form.Control.Feedback>
                                                    </div>
                                                    <div className="mobile-wrapper form-group width-50 mb-3">
                                                        <Form.Group className="mobile-code-input form-group">
                                                            <label>Code</label>
                                                            <Form.Control
                                                                type="text"
                                                                name="phone_code"
                                                                onChange={(e) => handleCustomersInputChange(e)}
                                                                value={phone_code}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group className="form-group">
                                                            <label>Mobile No. *</label>
                                                            <Form.Control
                                                                type="text"
                                                                name="mobile_number"
                                                                onChange={(e) => handleCustomersInputChange(e)}
                                                                value={customersFormData.mobile_number}
                                                                required
                                                                minLength={6}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please enter at least 6 characters.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                    <Form.Group className="form-group mb-3">
                                                        <label>Street Address</label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={4}
                                                            name="street_address"
                                                            onChange={(e) => handleCustomersInputChange(e)}
                                                            value={customersFormData.street_address}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="form-group width-50 mb-3">
                                                        <label>Suburb</label>
                                                        <Form.Control
                                                            type="text"
                                                            name="suburb"
                                                            onChange={(e) => handleCustomersInputChange(e)}
                                                            value={customersFormData.suburb}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="form-group width-50 mb-3">
                                                        <label>Post code</label>
                                                        <Form.Control
                                                            type="text"
                                                            name="postcode"
                                                            onChange={(e) => handleCustomersInputChange(e)}
                                                            value={customersFormData.postcode}
                                                        />
                                                    </Form.Group>
                                                    <div className="form-group custom-normal-select">
                                                        <label>State</label>
                                                        <Form.Select
                                                            aria-label="Default select example"
                                                            name="state"
                                                            onChange={(e) => handleCustomersInputChange(e)}
                                                        >
                                                            <option value="">Select State</option>
                                                            {state.length !== 0 &&
                                                                state.map(({ id, name }, index) => (
                                                                    <option key={id} value={id}>{name}</option>
                                                                ))}
                                                        </Form.Select>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </div>
                                </Scrollbars>
                            </Tab>
                            <Tab eventKey="vehicles" title="Vehicles">
                                <Scrollbars
                                    autoHeight
                                    autoHeightMax={300}
                                    className="custom-scrollbar"
                                    style={{ width: "100%" }}
                                    renderThumbVertical={(props) => (
                                        <div className="thumb-vertical grey-thumb" />
                                    )}
                                    renderTrackHorizontal={(props) => (
                                        <div style={{ display: "none" }} />
                                    )}
                                >
                                    <Form validated={validateVehiclesFrom}>
                                        <div className="content-wrapper">
                                            <div className="form-outer form-align-start">
                                                <Form.Group className="form-group custom-normal-select width-50 mb-3">
                                                    <label>Asset Type *</label>

                                                    <Form.Select
                                                        aria-label="Default select example"
                                                        name="asset_type_val"
                                                        required
                                                        onChange={(e) => handleVehiclesInputChange(e)}
                                                        value={vehiclesFormData.asset_type_val}
                                                    >
                                                        <option value="">Select Vehicle</option>
                                                        {
                                                            assets_type.length !== 0 &&
                                                            assets_type.map((assets, index) => (
                                                                <option key={index} value={assets}>{assets}</option>
                                                            ))
                                                        }

                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        This field is required.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className="form-group width-50 mb-3">
                                                    <label>Rego *</label>
                                                    <div className="d-flex search-gradient-wrapper">
                                                        <Form.Control
                                                            type="text"
                                                            name="rego_val"
                                                            id="rego_val"
                                                            className={regoExist && "form-control is-invalid"}
                                                            required
                                                            onBlur={(e) => checkRegoExist(e)}
                                                            onChange={(e) => handleVehiclesInputChange(e)}
                                                            value={vehiclesFormData.rego_val}
                                                        />
                                                        {regoExist &&
                                                            <div className="invalid-feedback">
                                                                This rego is already exist.
                                                            </div>
                                                        }
                                                        {
                                                            (regoExist === false) ?
                                                                <Form.Control.Feedback type="invalid">
                                                                    This field is required.
                                                                </Form.Control.Feedback> : ''
                                                        }
                                                        <Button className="btn-gradient" onClick={regoLookUpCV}>
                                                            <i className="fa fa-search"></i>
                                                        </Button>
                                                    </div>

                                                </Form.Group>
                                                <Form.Group className="form-group auto-complete-wrapper mb-3">
                                                    <label>Customer Name *</label>
                                                    <Form.Control
                                                        type="text"
                                                        name="customer_name1"
                                                        required
                                                        onChange={(e) => handleVehiclesInputChange(e)}
                                                        value={vehiclesFormData.customer_name1}
                                                        autoComplete="off"
                                                    />

                                                    {customerList !== null && customerList.length !== 0 && (
                                                        <ul className="auto-complete-list">
                                                            <Scrollbars
                                                                autoHeight
                                                                autoHeightMax={200}
                                                                className="custom-scrollbar"
                                                                style={{ width: "100%" }}
                                                                renderThumbVertical={(props) => (
                                                                    <div
                                                                        {...props}
                                                                        className="thumb-vertical grey-thumb"
                                                                    />
                                                                )}
                                                            >
                                                                {customerList.map(({ firstname, lastname, company_name, type, id, user_id }, index) => (
                                                                    <li
                                                                        key={index}
                                                                        name="customer_name1"
                                                                        onClick={() => {
                                                                            setVehiclesFormData({
                                                                                ...vehiclesFormData,
                                                                                customer_name1: firstname + " " + lastname,
                                                                                user_id: user_id
                                                                            });
                                                                            dispatch(
                                                                                getSelectedCustomerListSuccess([])
                                                                            );
                                                                        }}
                                                                    >
                                                                        {(type === "2") ? firstname + " " + lastname : company_name}
                                                                    </li>
                                                                ))}
                                                            </Scrollbars>
                                                        </ul>
                                                    )}

                                                    <Form.Control.Feedback type="invalid">
                                                        This field is required.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <div className="form-group custom-normal-select m-0 width-100 ">
                                                    {["Trailer", "Caravan"].includes(
                                                        vehiclesFormData.asset_type_val
                                                    ) && (
                                                            <Form.Group className=" width-50 mb-3">
                                                                <label>Serial Number</label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="serial_no"
                                                                    onChange={(e) => handleVehiclesInputChange(e)}
                                                                    value={vehiclesFormData.serial_no}
                                                                />
                                                            </Form.Group>
                                                        )}
                                                </div>
                                                {["Marine"].includes(
                                                    vehiclesFormData.asset_type_val
                                                ) && (
                                                        <Form.Group className=" form-group width-100 mb-3">
                                                            <label>Engine Type</label>
                                                            <Form.Select
                                                                aria-label="Default select example"
                                                                name="engine_type"
                                                                onChange={(e) => handleVehiclesInputChange(e)}
                                                                value={vehiclesFormData.engine_type}
                                                            >
                                                                <option value="">Select Engine Type</option>
                                                                {
                                                                    engineTypeData.length !== 0 &&
                                                                    engineTypeData.map((engine, index) => (
                                                                        <option key={index} value={engine.id}>{engine.name}</option>
                                                                    ))
                                                                }
                                                                {/* <option value="26">Inboard</option>
                                                                <option value="25">Outboard</option> */}
                                                            </Form.Select>
                                                        </Form.Group>
                                                    )}

                                                {["Marine"].includes(
                                                    vehiclesFormData.asset_type_val
                                                ) && (
                                                        <div className="form-group custom-normal-select width-33 mb-3">
                                                            <Form.Group className="width-100 mb-3">
                                                                <label>Type</label>
                                                                <Form.Select
                                                                    aria-label="Default select example"
                                                                    name="marine_type"
                                                                    onChange={(e) => handleVehiclesInputChange(e)}
                                                                    value={vehiclesFormData.marine_type}
                                                                >
                                                                    <option value="">Select Type</option>
                                                                    {
                                                                        typeData.length !== 0 &&
                                                                        typeData.map((type, index) => (
                                                                            <option key={index} value={type.id}>{type.name}</option>
                                                                        ))
                                                                    }
                                                                    {/* <option value="27">Boat</option>
                                                                    <option value="28">Jetski</option>
                                                                    <option value="29">Other</option> */}
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </div>
                                                    )}
                                                <div className="form-group custom-normal-select width-33 mb-3">
                                                    <label>Make</label>
                                                    {["Vehicle", ""].includes(
                                                        vehiclesFormData.asset_type_val
                                                    ) ? (
                                                        <Form.Select
                                                            aria-label="Default select example"
                                                            name="make"
                                                            onChange={(e) => handleVehiclesInputChange(e)}
                                                            value={vehiclesFormData.make}
                                                        >
                                                            <option value="">Select Make</option>
                                                            {vehicles.length !== 0 &&
                                                                vehicles.map(({ assets_make }, index) => (
                                                                    <option value={assets_make} key={index}>
                                                                        {assets_make}
                                                                    </option>
                                                                ))}
                                                        </Form.Select>
                                                    ) : (
                                                        <Form.Control
                                                            type="text"
                                                            name="make"
                                                            onChange={(e) => handleVehiclesInputChange(e)}
                                                            value={vehiclesFormData.make}
                                                        />
                                                    )}
                                                </div>
                                                <Form.Group className="form-group auto-complete-wrapper width-33 mb-3">
                                                    <label>Model</label>
                                                    <Form.Control
                                                        type="text"
                                                        name="model"
                                                        onChange={(e) => handleVehiclesInputChange(e)}
                                                        value={vehiclesFormData.model}
                                                        autoComplete="off"
                                                    />
                                                    {VehicleModelData.length !== 0 && (
                                                        <ul className="auto-complete-list">
                                                            <Scrollbars
                                                                autoHeight
                                                                autoHeightMax={200}
                                                                className="custom-scrollbar"
                                                                style={{ width: "100%" }}
                                                                renderThumbVertical={(props) => (
                                                                    <div
                                                                        {...props}
                                                                        className="thumb-vertical grey-thumb"
                                                                    />
                                                                )}
                                                            >
                                                                {VehicleModelData.map(
                                                                    ({ assets_model, id }, index) => (
                                                                        <li
                                                                            key={index}
                                                                            name="model"
                                                                            onClick={() =>
                                                                                setVehiclesFormData({
                                                                                    ...vehiclesFormData,
                                                                                    model: assets_model,
                                                                                    model_id: id,
                                                                                })
                                                                            }
                                                                        >
                                                                            {assets_model}
                                                                        </li>
                                                                    )
                                                                )}
                                                            </Scrollbars>
                                                        </ul>
                                                    )}
                                                </Form.Group>
                                                {["Vehicle", ""].includes(
                                                    vehiclesFormData.asset_type_val
                                                ) && (
                                                        <Form.Group className="form-group auto-complete-wrapper width-33 mb-3">
                                                            <label>Series</label>
                                                            <Form.Control
                                                                type="text"
                                                                name="model_series"
                                                                onChange={(e) => handleVehiclesInputChange(e)}
                                                                value={vehiclesFormData.model_series}
                                                                autoComplete="off"
                                                            />
                                                            {VehicleSeriesData.length !== 0 && (
                                                                <ul className="auto-complete-list">
                                                                    <Scrollbars
                                                                        autoHeight
                                                                        autoHeightMax={200}
                                                                        className="custom-scrollbar"
                                                                        style={{ width: "100%" }}
                                                                        renderThumbVertical={(props) => (
                                                                            <div
                                                                                {...props}
                                                                                className="thumb-vertical grey-thumb"
                                                                            />
                                                                        )}
                                                                    >
                                                                        {VehicleSeriesData.map(
                                                                            ({ series, id }, index) => (
                                                                                <li
                                                                                    key={index}
                                                                                    name="model_series"
                                                                                    onClick={() =>
                                                                                        setVehiclesFormData({
                                                                                            ...vehiclesFormData,
                                                                                            model_series: series,
                                                                                            model_series_id: id,
                                                                                        })
                                                                                    }
                                                                                >
                                                                                    {series}
                                                                                </li>
                                                                            )
                                                                        )}
                                                                    </Scrollbars>
                                                                </ul>
                                                            )}
                                                        </Form.Group>
                                                    )}
                                                {vehiclesFormData.asset_type_val !== "Truck" && (
                                                    <div className="form-group custom-normal-select width-33 mb-3">
                                                        <label>Fuel Type</label>
                                                        {["Vehicle", ""].includes(
                                                            vehiclesFormData.asset_type_val
                                                        ) ? (
                                                            <Form.Select
                                                                aria-label="Default select example"
                                                                name="fuel_type"
                                                                onChange={(e) => handleVehiclesInputChange(e)}
                                                                value={vehiclesFormData.fuel_type}
                                                            >
                                                                <option value="">Select Fuel Type</option>
                                                                <option value="PETROL">PETROL</option>
                                                                <option value="DIESEL">DIESEL</option>
                                                                <option value="HYBRID">HYBRID</option>
                                                                <option value="LPG">LPG</option>
                                                                <option value="ELECTRIC">ELECTRIC</option>
                                                                <option value="ELEC/DIESEL">ELEC/DIESEL</option>
                                                                <option value="ELEC/PETROL">ELEC/PETROL</option>
                                                                <option value="PETROL/LPG">PETROL/LPG</option>
                                                            </Form.Select>
                                                        ) : (
                                                            <Form.Control
                                                                type="text"
                                                                name="fuel_type"
                                                                onChange={(e) => handleVehiclesInputChange(e)}
                                                                value={vehiclesFormData.fuel_type}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                                <div className="form-group custom-normal-select width-33 mb-3">
                                                    <label>Body Type</label>

                                                    {(vehiclesFormData.asset_type_val === "Truck" || vehiclesFormData.asset_type_val === "Caravan") ? (
                                                        <Form.Select
                                                            aria-label="Default select example"
                                                            name="body_type"
                                                            onChange={(e) => handleVehiclesInputChange(e)}
                                                            value={vehiclesFormData.body_type}
                                                        >
                                                            <option value="">Select Body Type</option>
                                                            <option value="Single Axle">Single Axle</option>
                                                            <option value="Double Axle">Double Axle</option>
                                                        </Form.Select>
                                                    ) : ["Vehicle", ""].includes(
                                                        vehiclesFormData.asset_type_val
                                                    ) ? (
                                                        <Form.Select
                                                            aria-label="Default select example"
                                                            name="body_type"
                                                            onChange={(e) => handleVehiclesInputChange(e)}
                                                            value={vehiclesFormData.body_type}
                                                        >
                                                            <option value="">Select Body Type</option>
                                                            <option value="Hatchback">Hatchback</option>
                                                            <option value="Sedan">Sedan</option>
                                                            <option value="Wagon">Wagon</option>
                                                            <option value="Coupe">Coupe</option>
                                                            <option value="Convertible">Convertible</option>
                                                            <option value="SUV">SUV</option>
                                                            <option value="Roadster">Roadster</option>
                                                            <option value="Utility">Utility</option>
                                                            <option value="Minivan">Minivan</option>
                                                            <option value="Pickup Truck">Pickup Truck</option>
                                                            <option value="Compact">Compact</option>
                                                            <option value="Off Road">Off Road</option>
                                                            <option value="Crossover">Crossover</option>
                                                            <option value="Hardtop">Hardtop</option>
                                                            <option value="Flat Bed Truck">
                                                                Flat Bed Truck
                                                            </option>
                                                            <option value="Tipper Truck">Tipper Truck</option>
                                                            <option value="Curtain-Siders Truck">
                                                                Curtain-Siders Truck
                                                            </option>
                                                            <option value="Box Body Truck">
                                                                Box Body Truck
                                                            </option>
                                                            <option value="Road Tankers">Road Tankers</option>
                                                        </Form.Select>
                                                    ) : (
                                                        <Form.Control
                                                            type="text"
                                                            name="body_type"
                                                            onChange={(e) => handleVehiclesInputChange(e)}
                                                            value={vehiclesFormData.body_type}
                                                        />
                                                    )}
                                                </div>
                                                <div className="form-group custom-normal-select width-33 mb-3">
                                                    <label>Year</label>
                                                    {["Vehicle", ""].includes(
                                                        vehiclesFormData.asset_type_val
                                                    ) ? (
                                                        <Form.Select
                                                            aria-label="Default select example"
                                                            name="year"
                                                            onChange={(e) => handleVehiclesInputChange(e)}
                                                            value={vehiclesFormData.year}
                                                        >
                                                            <option value="">Select Year</option>

                                                            {[...Array(currentYear - 1979)].map(
                                                                (data, index) => (
                                                                    <option
                                                                        value={currentYear - index}
                                                                        key={index}
                                                                    >
                                                                        {currentYear - index}
                                                                    </option>
                                                                )
                                                            )}
                                                        </Form.Select>
                                                    ) : (
                                                        <Form.Control
                                                            type="text"
                                                            name="year"
                                                            onChange={(e) => handleVehiclesInputChange(e)}
                                                            value={vehiclesFormData.year}
                                                        />
                                                    )}
                                                </div>

                                                {["Vehicle", "", "Truck"].includes(
                                                    vehiclesFormData.asset_type_val
                                                ) && (
                                                        <div className="form-group custom-normal-select width-33 mb-3">
                                                            <label>Transmission</label>
                                                            <Form.Select
                                                                aria-label="Default select example"
                                                                name="transmission"
                                                                onChange={(e) => handleVehiclesInputChange(e)}
                                                                value={vehiclesFormData.transmission}
                                                            >
                                                                <option value="">Select transmission</option>
                                                                {
                                                                    transmissionData.length !== 0 &&
                                                                    transmissionData.map((trans, index) => (
                                                                        <option key={index} value={trans.id}>{trans.name}</option>
                                                                    ))
                                                                }
                                                            </Form.Select>
                                                        </div>
                                                    )}
                                                {["Vehicle", ""].includes(
                                                    vehiclesFormData.asset_type_val
                                                ) && (
                                                        <div className="form-group custom-normal-select width-33 mb-3">
                                                            <label>Drivetrain</label>
                                                            <Form.Select
                                                                aria-label="Default select example"
                                                                name="category"
                                                                onChange={(e) => handleVehiclesInputChange(e)}
                                                                value={vehiclesFormData.category}
                                                            >
                                                                <option value="">Select Drivetrain</option>
                                                                <option value="Front Wheel Drive(FWD)">
                                                                    Front Wheel Drive(FWD)
                                                                </option>
                                                                <option value="Rear Wheel Drive(RWD)">
                                                                    Rear Wheel Drive(RWD)
                                                                </option>
                                                                <option value="4 Wheel Drive(4WD)">
                                                                    4 Wheel Drive(4WD)
                                                                </option>
                                                                <option value="All Wheel Drive(AWD)">
                                                                    All Wheel Drive(AWD)
                                                                </option>
                                                            </Form.Select>
                                                        </div>
                                                    )}

                                                {!["Trailer", "Caravan", "Marine", "Truck"].includes(
                                                    vehiclesFormData.asset_type_val
                                                ) && (
                                                        <Form.Group className="form-group width-33 mb-3">
                                                            <label>Colour</label>
                                                            <Form.Control
                                                                type="text"
                                                                name="colour"
                                                                onChange={(e) => handleVehiclesInputChange(e)}
                                                                value={vehiclesFormData.colour}
                                                            />
                                                        </Form.Group>
                                                    )}

                                                {["Caravan", "Truck"].includes(
                                                    vehiclesFormData.asset_type_val
                                                ) && (
                                                        <Form.Group className="form-group width-33 mb-3">
                                                            <label>Other</label>
                                                            <Form.Control
                                                                type="text"
                                                                name="other"
                                                                onChange={(e) => handleVehiclesInputChange(e)}
                                                                value={vehiclesFormData.other}
                                                            />
                                                        </Form.Group>
                                                    )}
                                            </div>
                                            <div
                                                className={`form-outer hidden-form ${fieldShow ? "active" : ""
                                                    }`}
                                            >
                                                {["Vehicle", ""].includes(
                                                    vehiclesFormData.asset_type_val
                                                ) && (
                                                        <div className="form-group custom-normal-select width-33 mb-3">
                                                            <label>Seat Capacity</label>
                                                            <Form.Select
                                                                aria-label="Default select example"
                                                                name="seat_capacity"
                                                                onChange={(e) => handleVehiclesInputChange(e)}
                                                                value={vehiclesFormData.seat_capacity}
                                                            >
                                                                <option value="select">
                                                                    Select Seat Capacity
                                                                </option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="6">6</option>
                                                                <option value="7">7</option>
                                                                <option value="8">8</option>
                                                                <option value="9">9</option>
                                                                <option value="10">10</option>
                                                                <option value="11">11</option>
                                                                <option value="12">12</option>
                                                                <option value="13">13</option>
                                                                <option value="14">14</option>
                                                                <option value="15">15</option>
                                                                <option value="16">16</option>
                                                                <option value="17">17</option>
                                                                <option value="18">18</option>
                                                                <option value="19">19</option>
                                                                <option value="20">20</option>
                                                                <option value="21">21</option>
                                                                <option value="22">22</option>
                                                            </Form.Select>
                                                        </div>
                                                    )}
                                                {["Vehicle", ""].includes(
                                                    vehiclesFormData.asset_type_val
                                                ) && (
                                                        <Form.Group className="form-group width-33 mb-3">
                                                            <label>Tyre Size - Front</label>
                                                            <Form.Control
                                                                type="text"
                                                                name="tyre_size_front"
                                                                onChange={(e) => handleVehiclesInputChange(e)}
                                                                value={vehiclesFormData.tyre_size_front}
                                                            />
                                                        </Form.Group>
                                                    )}
                                                {["Vehicle", ""].includes(
                                                    vehiclesFormData.asset_type_val
                                                ) && (
                                                        <Form.Group className="form-group width-33 mb-3">
                                                            <label>Tyre Size - Rear</label>
                                                            <Form.Control
                                                                type="text"
                                                                name="tyre_size_rear"
                                                                onChange={(e) => handleVehiclesInputChange(e)}
                                                                value={vehiclesFormData.tyre_size_rear}
                                                            />
                                                        </Form.Group>
                                                    )}
                                                {!["Trailer", "Caravan", "Marine"].includes(
                                                    vehiclesFormData.asset_type_val
                                                ) && (
                                                        <Form.Group className="form-group width-50 mb-3">
                                                            <label>
                                                                VIN Number
                                                                <div
                                                                    className={`lookup-dropdown ${lookUpShow ? "active" : ""
                                                                        }`}
                                                                >
                                                                    <Button
                                                                        className="rounded-btn"
                                                                        variant="primary"
                                                                        onClick={handleLookupShow}
                                                                    >
                                                                        <i className="fa fa-chevron-circle-down m-r-5 font-size-14"></i>
                                                                        Lookup
                                                                    </Button>
                                                                    <div className="dropdown-list">
                                                                        <ul>
                                                                            <Scrollbars
                                                                                autoHeight
                                                                                autoHeightMax={150}
                                                                                className="custom-scrollbar"
                                                                                style={{ width: "100%" }}
                                                                                renderThumbVertical={(props) => (
                                                                                    <div className="thumb-vertical grey-thumb" />
                                                                                )}
                                                                                renderTrackHorizontal={(props) => (
                                                                                    <div style={{ display: "none" }} />
                                                                                )}
                                                                            >
                                                                                <li key="act">
                                                                                    <Link to="/">ACT</Link>
                                                                                </li>
                                                                                <li key="nsw">
                                                                                    <Link to="/">NSW</Link>
                                                                                </li>
                                                                                <li key="nt">
                                                                                    <Link to="/">NT</Link>
                                                                                </li>
                                                                                <li key="qld">
                                                                                    <Link to="/">QLD</Link>
                                                                                </li>
                                                                                <li key="sa">
                                                                                    <Link to="/">SA</Link>
                                                                                </li>
                                                                                <li key="tas">
                                                                                    <Link to="/">TAS</Link>
                                                                                </li>
                                                                                <li key="wa">
                                                                                    <Link to="/">WA</Link>
                                                                                </li>
                                                                                <li key="safety">
                                                                                    <Link to="/">Safety Recalls</Link>
                                                                                </li>
                                                                                <li key="nz">
                                                                                    <Link to="/">NZ</Link>
                                                                                </li>
                                                                                <li key="uk">
                                                                                    <Link to="/">UK</Link>
                                                                                </li>
                                                                            </Scrollbars>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </label>

                                                            <Form.Control
                                                                type="text"
                                                                name="vin_number"
                                                                className={
                                                                    vinExist && "form-control is-invalid"
                                                                }
                                                                onChange={(e) => handleVehiclesInputChange(e)}
                                                                onBlur={(e) => checkVinExist(e)}
                                                                value={vehiclesFormData.vin_number}
                                                            />
                                                            {vinExist &&
                                                                <div className="invalid-feedback">
                                                                    This Vin Number is already registered.
                                                                </div>
                                                            }
                                                        </Form.Group>
                                                    )}
                                                {!["Trailer", "Caravan", "Marine"].includes(
                                                    vehiclesFormData.asset_type_val
                                                ) && (
                                                        <Form.Group className="form-group width-50 mb-3">
                                                            <label>Chasis Number</label>
                                                            <Form.Control
                                                                type="text"
                                                                name="chasis_number"
                                                                className={
                                                                    chasisExist && "form-control is-invalid"
                                                                }
                                                                onChange={(e) => handleVehiclesInputChange(e)}
                                                                onBlur={(e) => checkChasisExist(e)}
                                                                value={vehiclesFormData.chasis_number}
                                                            />
                                                            {chasisExist &&
                                                                <div className="invalid-feedback">
                                                                    This Chasis Number is already registered.
                                                                </div>
                                                            }
                                                        </Form.Group>
                                                    )}

                                                {["Marine"].includes(
                                                    vehiclesFormData.asset_type_val
                                                ) && (
                                                        <Form.Group className="form-group width-33 mb-3">
                                                            <label>Engine Hours</label>
                                                            <Form.Control
                                                                type="text"
                                                                name="engine_hours"
                                                                onChange={(e) => handleVehiclesInputChange(e)}
                                                                value={vehiclesFormData.engine_hours}
                                                            />
                                                        </Form.Group>
                                                    )}

                                                {["Marine"].includes(
                                                    vehiclesFormData.asset_type_val
                                                ) && (
                                                        <Form.Group className="form-group width-33 mb-3">
                                                            <label>Engine Size</label>
                                                            <Form.Control
                                                                type="text"
                                                                name="engine_size"
                                                                onChange={(e) => handleVehiclesInputChange(e)}
                                                                value={vehiclesFormData.engine_size}
                                                            />
                                                        </Form.Group>
                                                    )}

                                                {!["Trailer", "Caravan"].includes(
                                                    vehiclesFormData.asset_type_val
                                                ) && (
                                                        <Form.Group
                                                            className={`form-group ${vehiclesFormData.asset_type_val == "Marine"
                                                                ? `width-33`
                                                                : `width-50`
                                                                } mb-3`}
                                                        >
                                                            <label>Engine Number</label>
                                                            <Form.Control
                                                                type="text"
                                                                name="engine_number"
                                                                className={
                                                                    engineExist && "form-control is-invalid"
                                                                }
                                                                onChange={(e) => handleVehiclesInputChange(e)}
                                                                onBlur={(e) => checkEngineExist(e)}
                                                                value={vehiclesFormData.engine_number}
                                                            />

                                                            {engineExist &&
                                                                <div className="invalid-feedback">
                                                                    This Engine Number is already registered.
                                                                </div>
                                                            }
                                                        </Form.Group>
                                                    )}
                                                {["Vehicle", "", "Truck"].includes(
                                                    vehiclesFormData.asset_type_val
                                                ) && (
                                                        <Form.Group className="form-group width-50 mb-3">
                                                            <label>Fleet Number</label>
                                                            <Form.Control
                                                                type="text"
                                                                name="fleet_number"
                                                                onChange={(e) => handleVehiclesInputChange(e)}
                                                                value={vehiclesFormData.fleet_number}
                                                                onBlur={(e) => checkFleetExist(e)}
                                                                className={
                                                                    fleetExist && "form-control is-invalid"
                                                                }
                                                            />
                                                            {fleetExist &&
                                                                <div className="invalid-feedback">
                                                                    This Fleet Number is already registered.
                                                                </div>
                                                            }
                                                        </Form.Group>
                                                    )}

                                                {["Truck", "Motorbike"].includes(
                                                    vehiclesFormData.asset_type_val
                                                ) && (
                                                        <Form.Group className="form-group width-50 mb-3">
                                                            <label>Odometer Reading</label>
                                                            <Form.Control
                                                                type="text"
                                                                name="odometer_reading"
                                                                onChange={(e) => handleVehiclesInputChange(e)}
                                                                value={vehiclesFormData.odometer_reading}
                                                            />
                                                        </Form.Group>
                                                    )}

                                                <div className="">
                                                    <div className="form-outer form-align-start">
                                                        {
                                                            customFields !== null && (
                                                                customFields.map((customField, index) => {
                                                                    if (customField.field_type === "1") {
                                                                        var labelTypeOne = customField.label;
                                                                        return (
                                                                            <Form.Group className="form-group width-33 mb-3">
                                                                                <label>{customField.label}</label>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    name={customField.name}
                                                                                    onChange={(e) => handleVehiclesInputChange(e)}
                                                                                    value={vehiclesFormData.labelTypeOne}
                                                                                />
                                                                            </Form.Group>
                                                                        )
                                                                    }

                                                                    if (customField.field_type === "2") {
                                                                        return (
                                                                            <Form.Group className="form-group date-picker width-33">
                                                                                <label>{customField.label}</label>
                                                                                <DatePicker className="form-control" id={customField.name} name={customField.name}
                                                                                    defaultValue={moment()}
                                                                                    format={date_format}
                                                                                />
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-primary date-set btn-icon"
                                                                                >
                                                                                    <i className="fa fa-calendar"></i>
                                                                                </button>
                                                                            </Form.Group>
                                                                        )
                                                                    }

                                                                    if (customField.field_type === "3") {
                                                                        var labelTypeThree = customField.label;
                                                                        listArr = customField.list_value.split(',');
                                                                        return (
                                                                            <div className="form-group custom-normal-select width-33 mb-3">
                                                                                <label>{customField.label}</label>
                                                                                <Form.Select
                                                                                    aria-label="Default select example"
                                                                                    name={customField.name}
                                                                                    onChange={(e) => handleVehiclesInputChange(e)}
                                                                                    value={vehiclesFormData.labelTypeThree}
                                                                                >
                                                                                    <option value="">Select Drivetrain</option>
                                                                                    {
                                                                                        listArr.map((list, index) => (
                                                                                            <option key={list} value={list}>{list}</option>
                                                                                        ))
                                                                                    }
                                                                                </Form.Select>
                                                                            </div>
                                                                        )
                                                                    }
                                                                })
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {["Vehicle", ""].includes(
                                                vehiclesFormData.asset_type_val
                                            ) && (
                                                    <div className="link-wrapper d-flex justify-center">
                                                        <Button
                                                            type="button"
                                                            variant="outline-primary"
                                                            className="expand-link"
                                                            onClick={expandFields}
                                                        >
                                                            {fieldShow ? "See Less" : "See More"}
                                                        </Button>
                                                    </div>
                                                )}
                                        </div>
                                    </Form>
                                </Scrollbars>
                            </Tab>
                        </Tabs>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="outline-secondary"
                        className="btn-outline rounded-btn"
                        onClick={handleCustomerClose}
                    >
                        Close
                    </Button>

                    {activeTab === "customers" && (
                        <Button
                            className="rounded-btn btn-success"
                            variant="primary"
                            onClick={() => handleSave()}
                        >
                            Save
                        </Button>
                    )}
                    <Button
                        className="rounded-btn btn-success"
                        variant="primary"
                        onClick={() => handleSaveNext()}
                    >
                        {activeTab === "customers" ? `Save & Next` : `Save & Create a Job`}
                    </Button>
                </Modal.Footer>
            </Modal>


            {regoLookUpModalCV &&
                <RegoLookup 
                    showRegoLookupModal={regoLookUpModalCV}
                    handleShowRegoLookupModal={handleShowRegoLookupModalCV}
                    handleCloseRegoLookupModal={handleCloseRegoLookupModalCV}
                    setVehicleDetailData={setVehicleDetailDataCV}
                    vehicleRegoData={vehicleRegoData}
                    action="set_vehicle"
                />
            }
        </>
    );
};

export default CustomerAndVehicle;
