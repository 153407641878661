import React from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from 'yup';
import axios from "axios";
import { UPDATE_COMPANY_OTHER_DETAIL } from "../../../../../store/companyDetails/actionTypes";
import FbIcon from "../../../../../assets/images/facebook-icons-Google-Search.png";

const FacebookReview = ({ workShopOtherDetails }) => {
  const dispatch = useDispatch(); // Dispatch variable

  const reviewSchema = Yup.object().shape({
    facebookLink: Yup.string().required("This field is required.").url("Please enter a valid URL.")
  });

  const formik = useFormik({
    initialValues: {
      facebookLink: workShopOtherDetails ? workShopOtherDetails.facebook_link : "",
      tinyFacebookLink: workShopOtherDetails ? workShopOtherDetails.tiny_facebook_link : "",
      isActive: workShopOtherDetails ? workShopOtherDetails.is_facebook_link_active : "0",
      id: workShopOtherDetails ? workShopOtherDetails.id : "",

    },
    enableReinitialize: true,
    validationSchema: reviewSchema,
    onSubmit: async values => {
      let tinyUrl = "";
      
      await axios.get("http://tinyurl.com/api-create.php?url="+values.facebookLink).then(response => {
        tinyUrl = response.data;
      }).catch(error => {
        console.log(error);
      });

      dispatch({
        type: UPDATE_COMPANY_OTHER_DETAIL,
        payload: {
          facebook_link: values.facebookLink,
          tiny_facebook_link: tinyUrl,
          is_facebook_link_active: values.isActive,
          id: values.id
        }
      });
    }
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="border mb-3">
        <div className="card-header d-flex flex-wrap align-items-center">
          <div className="img flex-shrink-0 me-2">
            <img src={FbIcon} width="40" height="40" className="img-circle" alt="" />
          </div>
          <div className="text-uppercase font-size-13">FACEBOOK</div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group pb-3">
                <label htmlFor="" className="form-label">Facebook link *</label>
                <input 
                  type="text" 
                  className={`form-control ${formik.errors.facebookLink &&
                    formik.touched.facebookLink && "is-invalid"}`}
                  value={formik.values.facebookLink}
                  onChange={e => formik.setFieldValue("facebookLink", e.target.value)}
                />
                {formik.errors.facebookLink &&
                  formik.touched.facebookLink && (
                    <div className="form-error text-danger">
                      {formik.errors.facebookLink}
                    </div>
                  )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group pb-3">
                <label htmlFor="" className="form-label">Preview</label>
                <div className="chat-bubble bubble-primary">
                  {formik.values.tinyFacebookLink}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 pt-3">
              <div className="form-check">
                <label className="form-check-label">
                  <input 
                    className="form-check-input" 
                    type="checkbox" 
                    defaultChecked={formik.values.isActive === "1"}
                    onChange={e => formik.setFieldValue("isActive", e.target.checked ? "1" : "0")}
                  />
                  Activated
                </label>
              </div>
            </div>
            <div className="col-md-8 pt-3">
              <p className="mb-0">A link will be embedded in your invoice template</p>
            </div>
          </div>
        </div>
      </div>
      <div className="cta">
        <button type="submit" className="btn btn-success btn-rounded px-3">Save</button>
      </div>
    </form>
  );
}

export default FacebookReview;