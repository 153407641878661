import {
  GET_ASSIGNED_JOBS,
  GET_ASSIGNED_JOBS_SUCCESS,
  GET_ASSIGNED_JOBS_FAIL,
  GET_ASSIGNED_JOBS_COUNT,
  GET_ASSIGNED_JOBS_COUNT_SUCCESS,
  GET_ASSIGNED_JOBS_COUNT_FAIL,
} from "./actionTypes";

export const getassignedJobs = () => {
  return {
    type: GET_ASSIGNED_JOBS,
  };
};

export const getassignedJobsSuccess = (data) => {
  return {
    type: GET_ASSIGNED_JOBS_SUCCESS,
    payload: data,
  };
};

export const getassignedJobsFail = (error) => {
  return {
    type: GET_ASSIGNED_JOBS_FAIL,
    payload: error,
  };
};

export const getassignedJobsCount = () => {
  return {
    type: GET_ASSIGNED_JOBS_COUNT,
  };
};

export const getassignedJobsCountSuccess = (data) => {
  return {
    type: GET_ASSIGNED_JOBS_COUNT_SUCCESS,
    payload: data,
  };
};

export const getassignedJobsCountFail = (error) => {
  return {
    type: GET_ASSIGNED_JOBS_COUNT_FAIL,
    payload: error,
  };
};
