import { apiCall } from "helpers/apiHelper";
import {
  CASH_SALE_PAYMENTS_CREDITS_SAVE,
  EDIT_CASH_SALE_INVOICE,
  INVOICES_PAYMENTS_CREDITS_SAVE,
  UPDATE_INVOICE,
  UPDATE_JOB_TIMER,
} from "helpers/urlHelper";

export async function createInvoicePayment(payload) {
  if (parseFloat(payload.balance_remaining) === 0) {
    /*
            Full Payment
            Update invoice status = 2
            for normal invoice update job status and job stage
        */

    const req = {
      invoice_status: "2",
      id: payload.invoice_id,
    };

    if (payload.type === "cash_sale") {
      console.log("Update invoice cash sale");
      await apiCall(EDIT_CASH_SALE_INVOICE, { payload: req }, "POST", true);
    } else {
      console.log("Update normal invoice");
      const p = await apiCall(UPDATE_INVOICE, { payload: req }, "POST", true);
      let job_id = p.data.Invoices.job_id;
      const job_detail = {
        job_status: "Payment",
        job_stage: "Completed",
        id: job_id,
      };

      await apiCall(UPDATE_JOB_TIMER, { payload: job_detail }, "POST", true);
    }
  }

  const pr = {
    ...payload,
  };
  let url;
  delete pr.type;

  pr["balance_remaining"] = pr["balance_remaining"].toFixed(2);
  pr["payment_method"] = +pr["payment_method"];

//   console.log(JSON.stringify(pr));
//   return;
  if (payload.type === "cash_sale") {
    url = CASH_SALE_PAYMENTS_CREDITS_SAVE;
  } else {
    url = INVOICES_PAYMENTS_CREDITS_SAVE;
  }

  const data = await apiCall(url, { payload: pr }, "POST", true);
  if (data?.code === 200) {
    return data.data.InvoicesPaymentsCredits;
  } else {
    throw new Error("Failed to create payment");
  }
}
