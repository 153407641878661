export const SAVE_LABOUR_CHARGE = "SAVE_LABOUR_CHARGE";
export const SAVE_LABOUR_CHARGE_SUCCESS = "SAVE_LABOUR_CHARGE_SUCCESS";
export const SAVE_LABOUR_CHARGE_FAIL = "SAVE_LABOUR_CHARGE_FAIL";

export const EDIT_LABOUR_CHARGES_DETAILS = "EDIT_LABOUR_CHARGES_DETAILS";
export const EDIT_LABOUR_CHARGES_DETAILS_SUCCESS =
  "EDIT_LABOUR_CHARGES_DETAILS_SUCCESS";
export const EDIT_LABOUR_CHARGES_DETAILS_FAIl =
  "EDIT_LABOUR_CHARGES_DETAILS_FAIl";

export const UPDATE_LABOUR_CHARGE_DETAILS = "UPDATE_LABOUR_CHARGE_DETAILS";

export const UPDATE_LABOUR_CHARGE_DETAILS_SUCCESS =
  "UPDATE_LABOUR_CHARGE_DETAILS_SUCCESS";
export const UPDATE_LABOUR_CHARGE_DETAILS_FAIL =
  "UPDATE_LABOUR_CHARGE_DETAILS_FAIL";

export const UPDATE_DEFAULT_LABOUR_CHARGE_DETAIL =
  "UPDATE_DEFAULT_LABOUR_CHARGE_DETAIL";

export const DELETE_LABOUR_CHARGE_DETAILS = "DELETE_LABOUR_CHARGE_DETAILS";
export const DELETE_LABOUR_CHARGE_DETAILS_SUCCESS =
  "DELETE_LABOUR_CHARGE_DETAILS_SUCCESS";
export const DELETE_LABOUR_CHARGE_DETAILS_FAIL =
  "DELETE_LABOUR_CHARGE_DETAILS_FAIL";

// asset type

export const GET_CUSTOM_VEHICLE_LIST = "GET_CUSTOM_VEHICLE_LIST";
export const GET_CUSTOM_VEHICLE_LIST_SUCCESS =
  "GET_CUSTOM_VEHICLE_LIST_SUCCESS";
export const GET_CUSTOM_VEHICLE_LIST_FAIL = "GET_CUSTOM_VEHICLE_LIST_FAIL";

export const SAVE_CUSTOM_VEHICLE = "SAVE_CUSTOM_VEHICLE";
export const SAVE_CUSTOM_VEHICLE_SUCCESS = "SAVE_CUSTOM_VEHICLE_SUCCESS";
export const SAVE_CUSTOM_VEHICLE_FAIL = "SAVE_CUSTOM_VEHICLE_FAIL";

export const EDIT_CUSTOM_VEHICLE_DETAILS = "EDIT_CUSTOM_VEHICLE_DETAILS";
export const EDIT_CUSTOM_VEHICLE_DETAILS_SUCCESS =
  "EDIT_CUSTOM_VEHICLE_DETAILS_SUCCESS";
export const EDIT_CUSTOM_VEHICLE_DETAILS_FAIL =
  "EDIT_CUSTOM_VEHICLE_DETAILS_FAIL";

export const UPDATE_CUSTOM_VEHICLE_DETAILS = "UPDATE_CUSTOM_VEHICLE_DETAILS";

export const UPDATE_CUSTOM_VEHICLE_DETAILS_SUCCESS =
  "UPDATE_CUSTOM_VEHICLE_DETAILS_SUCCESS";
export const UPDATE_CUSTOM_VEHICLE_DETAILS_FAIL =
  "UPDATE_CUSTOM_VEHICLE_DETAILS_FAIL";

export const DELETE_CUSTOM_VEHICLE_DETAILS = "DELETE_CUSTOM_VEHICLE_DETAILS";
export const DELETE_CUSTOM_VEHICLE_DETAILS_SUCCESS =
  "DELETE_CUSTOM_VEHICLE_DETAILS_SUCCESS";
export const DELETE_CUSTOM_VEHICLE_DETAILS_FAIL = "DELETE_WORKSHOP_MARKUP_FAIL";

export const UPDATE_CUSTOM_VEHICLE_ASSET_TYPE =
  "UPDATE_CUSTOM_VEHICLE_ASSET_TYPE";
export const UPDATE_CUSTOM_VEHICLE_ASSET_TYPE_SUCCESS =
  "UPDATE_CUSTOM_VEHICLE_ASSET_TYPE_SUCCESS";
export const UPDATE_CUSTOM_VEHICLE_ASSET_TYPE_FAIL =
  "UPDATE_CUSTOM_VEHICLE_ASSET_TYPE_FAIL";
