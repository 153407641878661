import { all } from 'redux-saga/effects';

import LoginSaga from './login/saga';
import RegisterSaga from './register/saga';
import ForgotPasswordSaga from './forgotPassword/saga';
import ForgotEmailSaga from './forgotEmail/saga';
import NotificationSaga from './notification/saga';
import NotificationListSaga from './notificationList/saga';
import UserProfileSaga from './userProfile/saga';
import ChangePasswordSaga from './changePassword/saga';
import SubscriptionSaga from './subscription/saga';
import SubscriptionAddonSaga from './subscriptionAddon/saga';
import GlobalSearchSaga from './globalSearch/saga';
import SchedulerSaga from './scheduler/saga';
import OpenQuotesSaga from './openQuotes/saga';
import WonQuotesSaga from './wonQuotes/saga';
import LostQuotesSaga from './lostQuotes/saga';
import UnassignedJobsSaga from './unassignedJobs/saga';
import assignedJobsSaga from './assignedJobs/saga';
import inProgressJobsSaga from './inProcressJobs/saga';
import completedJobsSaga from './completedJobs/saga';
import CommonApiStoreSaga from './commonApiStore/saga';
import JobPartSaga from './jobParts/saga';
import PartSaga from './parts/saga';
import JobDetails from './jobDetails/saga';
import SupplierSaga from './supplier/saga';
import CompanyDetailSaga from './companyDetails/saga';
import defaultSettingsSaga from './defaultSettings/saga';
import JobLabourSaga from './jobLabour/saga';
import ContactsSaga from './contacts/saga';
import AssetsSaga from './assets/saga';
import invoiceSaga from './invoices/saga';

import marketingSaga from './marketing/saga';
import invoiceDetailSaga from './invoiceDetail/saga';
import SecurityGroupSaga from './securityGroup/saga';
import QuoteDetails from './QuoteDetails/saga';
import QuotePartSaga from './QuoteParts/saga';
import invoicePaymentSaga from './invoicePayment/saga';
import StatusSaga from './statuses/saga';
import QuoteLabourSaga from './quoteLabour/saga';
import workshopBaysSaga from './workshopBays/saga';
import VehicleSaga from './vehicles/saga';

export default function* rootSaga() {
  yield all([
    LoginSaga(),
    RegisterSaga(),
    ForgotPasswordSaga(),
    ForgotEmailSaga(),
    NotificationSaga(),
    NotificationListSaga(),
    UserProfileSaga(),
    ChangePasswordSaga(),
    GlobalSearchSaga(),
    SchedulerSaga(),
    OpenQuotesSaga(),
    WonQuotesSaga(),
    LostQuotesSaga(),
    UnassignedJobsSaga(),
    assignedJobsSaga(),
    inProgressJobsSaga(),
    completedJobsSaga(),
    CommonApiStoreSaga(),
    SubscriptionSaga(),
    SubscriptionAddonSaga(),
    JobPartSaga(),
    PartSaga(),
    JobDetails(),
    SupplierSaga(),
    CompanyDetailSaga(),
    JobLabourSaga(),
    ContactsSaga(),
    defaultSettingsSaga(),
    SecurityGroupSaga(),
    AssetsSaga(),
    QuoteDetails(),
    QuotePartSaga(),
    invoiceSaga(),
    marketingSaga(),
    invoicePaymentSaga(),
    StatusSaga(),
    QuoteLabourSaga(),
    AssetsSaga(),
    JobLabourSaga(),
    ContactsSaga(),
    invoiceDetailSaga(),
    workshopBaysSaga(),
    VehicleSaga(),
  ]);
}
