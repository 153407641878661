import React, { useState } from "react";
import logo from '../../../assets/images/5iQ_logo.png'; 
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { GET_FORGOT_PASSWORD } from "../../../store/forgotPassword/actionTypes";

let ForgotPassword = (props) => {

  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState();

  const { classes } = props;

  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() !== false) {
      dispatch({
        type: GET_FORGOT_PASSWORD,
        payload: {
          email: email
        },
      });
    }
    setValidated(true);
  };


    return (
      <div className={classes}>
        <div className="pre-login-header">
          <h1 className="mb-2">Welcome to 5iQ</h1>
          <p className="mb-2">Login to website here.</p>
          <p className="link-wrapper mb-2">New Registration <a href="/register" className="primary-link">CLICK HERE</a></p>
        </div>
        <div className="pre-login-wrapper"> 
          <div className="form-wrapper">
            <a href="/" className="logo">
              <img src={logo} alt="Forgot Password" />
            </a>
            <Form noValidate validated={validated} onSubmit={handleSubmit} id="forgot-password-form">
              <Form.Group className="form-group mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control required type="email" onBlur={(e) => setEmail(e.target.value)} />
                <Form.Control.Feedback type="invalid">Please Enter Email</Form.Control.Feedback>
                <Link to='/login' className="text-right secondary-link mt-1">Back to login</Link>
              </Form.Group>
              <div className="btn-wrapper">
                <Button type="submit" variant="primary" className="primary-btn">SEND RESET LINK</Button>
              </div>
            </Form>
          </div>
        </div>
        <div className="pre-login-footer">
          <p className="mb-2">By signing up for 5iQ account, you accept 5iQ</p>
          <p>
            <a href="/" className="primary-link mx-2">Terms of Use</a>
              <span>and</span>
            <a href="/" className="primary-link mx-2">Privacy Policy</a>
          </p>
        </div>
      </div>
    );
 }

//  ForgotPassword.propTypes = {
//   classes: PropTypes.object.isRequired,
// };
 export default ForgotPassword;