import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { GET_CHANGE_PASSWORD } from "../../../../store/changePassword/actionTypes";
import { showError } from "../../../../utils/alertMessage";
import LoaderImage from "../../../../../src/assets/images/page-loader.svg";

const ChangePassword = (props) => {

  
  //set all variable value
  const [validated, setValidated] = useState(false);
  const [currentPassword, setCurrentPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [isLoading, setIsLoading] = useState(false);

  //call dispath method
  const dispatch = useDispatch();

  const storedData = JSON.parse(sessionStorage.getItem('stored_data'));

  //submit event
  function handleSubmit(event) {
    
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() !== false) {
      if(newPassword === confirmPassword) {
        setIsLoading(true);
        dispatch({
          type: GET_CHANGE_PASSWORD,
          payload: {
            id:window.atob(storedData.user_id),
            old_password: currentPassword,
            new_password: newPassword
          },
        });
      } else {
        showError("New Password and Confirm Password not match.");
      }
    }
    setValidated(true);
  }

  const passwordResponse = useSelector((state) => state.ChangePasswordReducer);

  useEffect(() => {
    if(passwordResponse.isFetching === true){
      setIsLoading(false);
      props.handleClosePasswordModal();
    }
  },[passwordResponse]);

  return (
    <>
        <Modal size="md" show={props.showPasswordModal} onHide={props.handleClosePasswordModal}>
          <Modal.Header>
            <Modal.Title className="d-flex justify-space-between">Change Password</Modal.Title>
            <Button type="button" className="close-btn" onClick={props.handleClosePasswordModal}><i className="la la-times"></i> </Button>
          </Modal.Header>
          <Form onSubmit={handleSubmit} noValidate validated={validated} >
            <Modal.Body>
              <Form.Group className="form-group mb-3">
                <label>Current Password*</label>
                <Form.Control required type="password" name="current_password" autoComplete="on" onBlur={(e) => setCurrentPassword(e.target.value)} />
                <Form.Control.Feedback type="invalid">Please Enter Password</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-group mb-3">
                <label>New Password*</label>
                <Form.Control required type="password" name="new_password" autoComplete="on" onBlur={(e) => setNewPassword(e.target.value)} />
                <Form.Control.Feedback type="invalid">Please Enter Password</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-group mb-3">
                <label>Confirm Password*</label>
                <Form.Control required type="password" name="confirm_password" autoComplete="on" onBlur={(e) => setConfirmPassword(e.target.value)} />
                <Form.Control.Feedback type="invalid">Please Enter Password</Form.Control.Feedback>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-secondary" className="btn-outline rounded-btn" id="closeButton" onClick={props.handleClosePasswordModal}>
                Close
              </Button>
              <Button type="submit" className="rounded-btn" variant="primary">Change Password</Button>
            </Modal.Footer>
          </Form>
        </Modal>

        {isLoading === true &&
          <div className="page-loader d-flex align-center justify-center" >
            <img src={LoaderImage} alt="Loading"></img>
          </div>
        }
    </>
   )

}
export default ChangePassword;