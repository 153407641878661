import Loadable from "react-loadable";

import { Loading } from "../common/navigation";

const QuoteList = Loadable({
  loader: () => import("./components/QuoteList"),
  loading: Loading,
});
const CreateQuote = Loadable({
  loader: () => import("./components/CreateQuotes/CreateQuote"),
  loading: Loading,
});
const CreateEstimate = Loadable({
  loader: () => import("./components/CreateQuotes/CreateEstimate"),
  loading: Loading,
});
const QuoteDetails = Loadable({
  loader: () => import("./components/QuoteDetails/jobDetails"),
  loading: Loading,
});
const EmailQuote = Loadable({
  loader: () => import("./components/EmailQuote/EmailQuote"),
  loading: Loading,
});
const EmailPreviewQuote = Loadable({
  loader: () => import("./components/EmailPreviewQuote/EmailPreviewQuote"),
  loading: Loading,
});

const ViewQuote = Loadable({
  loader: () => import("./components/ViewQuote/jobDetails"),
  loading: Loading,
});
export const routes = [
  {
    path: "/quote-list",
    exact: true,
    name: "QuoteList",
    element: QuoteList,
  },
  {
    path: "/quote_create",
    exact: true,
    name: "QuoteCreate",
    element: CreateQuote,
  },
  {
    path: "/quote_estimate",
    exact: true,
    name: "QuoteEstimate",
    element: CreateEstimate,
  },
  {
    path: "/edit_quote/:id",
    exact: true,
    name: "QuoteDetails",
    element: QuoteDetails,
  },
  {
    path: "/email_quote/:id",
    exact: true,
    name: "EmailQuote",
    element: EmailQuote,
  },
  {
    path: "/email_preview_quote/:id",
    exact: true,
    name: "EmailPreviewQuote",
    element: EmailPreviewQuote,
  },
  {
    path: "/view_quote/:id",
    exact: true,
    name: "ViewQuote",
    element: ViewQuote,
  },
];

export * from "./components";
