import {
  GET_USER_PROFILE,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAIL,
  CHECK_EMAILEXIST,
  CHECK_EMAILEXIST_SUCCESS,
  CHECK_EMAILEXIST_FAIL,
  GET_SELECTED_CUSTOMERLIST,
  GET_SELECTED_CUSTOMERLIST_SUCCESS,
  GET_SELECTED_CUSTOMERLIST_FAIL,
} from "./actionTypes";

export const getUserProfile = () => {
  return {
    type: GET_USER_PROFILE,
  };
};

export const getUserProfileSuccess = (data) => {
  return {
    type: GET_USER_PROFILE_SUCCESS,
    payload: data.data.Users[0],
  };
};

export const getUserProfileFail = (error) => {
  return {
    type: GET_USER_PROFILE_FAIL,
    payload: error,
  };
};

export const updateUserProfile = () => {
  return {
    type: UPDATE_USER_PROFILE,
  };
};

export const updateUserProfileSuccess = (data) => {
  return {
    type: UPDATE_USER_PROFILE_SUCCESS,
    payload: data.data.Users,
  };
};

export const updateUserProfileFail = (error) => {
  return {
    type: UPDATE_USER_PROFILE_FAIL,
    payload: error,
  };
};

export const checkEmialExist = () => {
  return {
    type: CHECK_EMAILEXIST,
  };
};

export const checkEmialExistSuccess = (data) => {
  return {
    type: CHECK_EMAILEXIST_SUCCESS,
    payload: data,
  };
};

export const checkEmialExistFail = (error) => {
  return {
    type: CHECK_EMAILEXIST_FAIL,
    payload: error,
  };
};

export const getSelectedCustomerList = () => {
  return {
    type: GET_SELECTED_CUSTOMERLIST,
  };
};

export const getSelectedCustomerListSuccess = (data) => {
  return {
    type: GET_SELECTED_CUSTOMERLIST_SUCCESS,
    payload: data,
  };
};

export const getSelectedCustomerListFail = (error) => {
  return {
    type: GET_SELECTED_CUSTOMERLIST_FAIL,
    payload: error,
  };
};
