import {
  GET_LOST_QUOTES,
  GET_LOST_QUOTES_SUCCESS,
  GET_LOST_QUOTES_FAIL,
  GET_LOST_QUOTES_COUNT,
  GET_LOST_QUOTES_COUNT_SUCCESS,
  GET_LOST_QUOTES_COUNT_FAIL,
} from "./actionTypes";

export const getLostQuotes = () => {
  return {
    type: GET_LOST_QUOTES,
  };
};

export const getLostQuotesSuccess = (data) => {
  return {
    type: GET_LOST_QUOTES_SUCCESS,
    payload: data,
  };
};

export const getLostQuotesFail = (error) => {
  return {
    type: GET_LOST_QUOTES_FAIL,
    payload: error,
  };
};

export const getLostQuotesCount = () => {
  return {
    type: GET_LOST_QUOTES_COUNT,
  };
};

export const getLostQuotesCountSuccess = (data) => {
  return {
    type: GET_LOST_QUOTES_COUNT_SUCCESS,
    payload: data,
  };
};

export const getLostQuotesCountFail = (error) => {
  return {
    type: GET_LOST_QUOTES_COUNT_FAIL,
    payload: error,
  };
};
