import React, { useEffect } from "react";
import { showError, showSuccess } from "./alertMessage";
import Swal from "sweetalert2";

const DeleteRecords = ({
  showDeleteModal,
  refreshData,
  api,
  selectedRow,
  message,
  payloadData,
  handleOutSide
}) => {
  const handleClose = () => showDeleteModal(false);
  const deleteRecords = async () => {
    if (handleOutSide) {
      return api()
    }
    if (selectedRow.length !== 0) {
      let response = await api(payloadData);
      if (response.code === 200) {
        showSuccess(message);
        handleClose();
        refreshData();
      } else {
        showError(response.message);
        handleClose();
      }
    } else {
      showError("Select Atlest One Record");
      handleClose();
    }
  };

  useEffect(() => {
    Swal.fire({
      title: "Delete this records?",
      text: "",
      icon: "error",
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) deleteRecords();
      else handleClose();
    });
  }, []);

  return "";
};
export default DeleteRecords;
