import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { GET_NOTIFICATION_LIST } from "../../../store/notificationList/actionTypes";
import BootstrapTable from "react-bootstrap-table-next";
import LoaderImage from "../../../../src/assets/images/page-loader.svg";
import { Link } from "react-router-dom";
import moment from "moment";
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import {Row,Col} from 'react-bootstrap';

let Notification = () => {
    const storedData = JSON.parse(sessionStorage.getItem('stored_data'));

    const dateFormat = storedData.date_format;

    const dateFormatter = (cell, row) => {
        var responseDate = moment(cell).format(dateFormat);
    
        return responseDate;
      };

    const [isLoading, setIsLoading] = useState(true);
    const [notificationListData, setNotificationListData] = useState([]);
    const [notificationLimit, setNotificationLimit] = useState(5);
    const [categoryData, setCategory] = useState('all_category');
   
    const dispatch = useDispatch();
        
    // get notification by reducer
    const getNotificationData = useSelector((state) => state.NotificationListReducer);
    
    let user_id = window.atob(storedData.user_id);
    let workshop_id = window.atob(storedData.workshop_id);

    const [title, setTitle] = useState("5iQ | Notification");

    useEffect(() => {
        document.title = "5iQ | Notification";
    }, [title]);

    // query for notification data
    useEffect(() => {
        loadNotificationData();
    },[]);

    function handleChange(e){
        setCategory(e.target.value);
    }

    function loadNotificationData(action=''){

        var cat = categoryData;
        var newLimit = 5;
        if(action === 'load_more'){
            newLimit = parseInt(notificationLimit) + 5;
        } 
        
        setNotificationLimit(newLimit);
        setIsLoading(true);
        if(cat === '' || cat === 'all_category'){
            dispatch({
                type: GET_NOTIFICATION_LIST,
                payload: {
                    "get": "all",
                    "conditions": {
                        "to_id": user_id,
                        "is_read": 0,
                        "workshop_id": workshop_id,
                        'type !=' : 'inspection_verification_sms',
                    },
                    "limit": newLimit,
                    "offset": 0,
                    "order": {
                        "id": "desc"
                    }
                },
            });

        } else {

            setNotificationLimit(newLimit);
            dispatch({
                type: GET_NOTIFICATION_LIST,
                payload: {
                    "get": "all",
                    "conditions": {
                        "to_id": user_id,
                        "is_read": 0,
                        "workshop_id": workshop_id,
                        'type !=' : 'inspection_verification_sms',
                        'type' : cat
                    },
                    "limit": newLimit,
                    "offset": 0,
                    "order": {
                        "id": "desc"
                    }
                },
            });

        }
    }

    useEffect(() => {
        if(getNotificationData.isFetching === true){
            setNotificationListData(getNotificationData.notificationListData);
            setIsLoading(false);
        }

        
    },[getNotificationData]);

    
    const columns = [
    {
        dataField: 'type1',
        isDummyField: true,
        text: "",
        formatter: (cell, row)=>{
            if(row.type === 'open_request_job_count'){
                return (<span><i className="fa fa-briefcase"></i></span>);
            } else if(row.type === 'inspection_report_response'){
                return (<span><i className="fa fa-file-o"></i></span>);
            } else if(row.type === 'special_marketing_expiry'){
                return (<span><i className="fa fa-bullhorn"></i></span>);
            } else if(row.type === 'technician_license_expire'){
                return (<span><i className="fa fa-credit-card"></i></span>);
            } else if(row.type === 'po_reciept_reminder'){
                return (<span><i className="fa fa-credit-card"></i></span>);
            } else {
                return (<span><i className="fa fa-envelope-o"></i></span>);
            }
        },
    },
    {
        dataField: 'type',
        text: 'Category'
    },
    {
        dataField: 'title',
        text: 'Title'
    },
    {
        dataField: 'msg',
        text: 'Message'
    },
    {
        dataField: "created_on",
        text: "Date",
        formatter: dateFormatter,
    },
    {
      dataField: "df1",
      isDummyField: true,
      text: "Action",
      formatter: () => {
        return (
            <Link to="/" className="" title="EDIT">
                <span>View</span>
            </Link>
        );
      },
    },
    ];
    

    return(
    <>
        {isLoading === true &&
            <div className="page-loader d-flex align-center justify-center">
                <img src={LoaderImage}  alt="Loading"></img>
            </div>
        }
    <div className="right-section-wrapper card m-b-10" >

        <Row className="m-b-10">
            <Col>
                <h5>Notification</h5>
            </Col>
            <Col>
                <Form.Select onChange={handleChange}  aria-label="Default select example">
                    <option value="all_category">All category</option>
                    <option value="open_request_job_count"> Open request job count </option>
                    <option value="inspection_report_response">Inspection report response</option>
                    <option value="special_marketing_expiry"> Special Marketing expiry </option>
                    <option value="technician_license_expire">Technician License Expire</option>
                    <option value="po_reciept_reminder">Po Reciept Reminder</option>
                </Form.Select>
            </Col>
            <Col>
                <Button type="button" onClick={() => loadNotificationData()} variant="primary" className="primary-btn">Search</Button>
            </Col>
            
        </Row>
        
    </div>
    <div className="right-section-wrapper card">
        <div className="m-b-10">
            {notificationListData && notificationListData.length > 0 &&
                <BootstrapTable keyField="id" data={ notificationListData } columns={ columns } />
            }
        </div>
        <Button type="button" onClick={() => loadNotificationData('load_more')} variant="primary" className="primary-btn">Load More</Button>
    </div>
    </>
    )
}

export default Notification;
