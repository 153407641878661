import { apiCall } from "helpers/apiHelper";
import { GET_CASH_SALE_PAYMENTS_CREDITS, GET_INVOICES_LIST, GET_INVOICES_PAYMENT, GET_INVOICE_CASH_SALE_LIST } from "helpers/urlHelper";
import { getWorkshopId } from 'utils/getWorkshopId';
export async function getInvoiceReciept({ id, invoiceType }) {
    // console.log("getInvoiceReciept",id, invoiceType);
    let request;
    let url;
    if (invoiceType === 'cash_sale') {
        request = {
            get: "all",
            conditions: {
                'CashSalePaymentsCredits.cash_sale_invoice_id': id
            },
            contain: ['CashSaleInvoice'],
            order: {
                'CashSalePaymentsCredits.id': 'desc'
            }
        }
        url = GET_CASH_SALE_PAYMENTS_CREDITS
    } else {
        request = {
            'get': 'all',
            'conditions': {
                'InvoicesPaymentsCredits.invoice_id': id
            }
            ,
            'contain': ['Invoices'],
            'order': {
                'InvoicesPaymentsCredits.id': 'desc'
            }
        }
        url = GET_INVOICES_PAYMENT;
    }

    const data = await apiCall(url, { payload: request }, "POST", true);
    let paymentCredits = data.data.InvoicesPaymentsCredits;
    const invoiceArr = {};
    let paid_amount = 0;
    const paymentDetails = [];

    if (paymentCredits.length !== 0) {

        paymentCredits.forEach((payCred, i) => {
            paid_amount = paid_amount + parseFloat(payCred.amount_paid);

            paymentDetails[i] = {
                date: payCred.payment_date,
            }
            if (payCred.payment_method == 1) {
                paymentDetails[i].type = "Cash";
            }
            else if (payCred.payment_method == 2 || payCred.payment_method == 3) {
                paymentDetails[i].type = "Card";
            }
            else if (payCred.payment_method == 4) {
                paymentDetails[i].type = "Other";
            }
            paymentDetails[i].amount_paid = payCred.amount_paid;
        });

        paymentCredits[0].amount_paid = paid_amount;
        invoiceArr['data'] = paymentCredits[0];
        invoiceArr['type'] = 'credits';
        invoiceArr['paymentRecords'] = paymentDetails;
        invoiceArr['invoiceType'] = invoiceType;
    }

    if (Object.keys(invoiceArr).length === 0) {
        if (invoiceType === 'cash_sale') {
            const req = {
                get: "all",
                conditions: {
                    workshop_id: getWorkshopId(),
                    invoice_status: 1,
                    id: id
                },
                fields: [
                    "id",
                    "workshop_id",
                    "cash_sale_no",
                    "created_on",
                    "total",
                    "description",
                    // "tax_invoice",
                    // "remain_balance",
                    "invoice_status",
                ]
            }
            let invoiceDetails = await apiCall(GET_INVOICE_CASH_SALE_LIST, { payload: req }, "POST", true);
            if (invoiceDetails.code == 200) {
                invoiceArr['data'] = invoiceDetails.data.CashSaleInvoice[0];
                invoiceArr['type'] = 'cash_sale';
                invoiceArr['invoiceType'] = invoiceType;
            }

        } else {
            const req = {
                'get': 'all',
                'conditions': {
                    'Invoices.workshop_id': getWorkshopId(),
                    'invoice_status': 1,
                    'Invoices.id': id
                },
                'fields': [

                    "id",
                    "workshop_id",
                    "invoice_number",
                    "created_on",
                    "total",
                    "comments",
                    "tax_invoice",
                    "remain_balance",
                    "invoice_status",
                ]
            }
            let invoiceDetails = await apiCall(GET_INVOICES_LIST, { payload: req }, "POST", true);
            if (invoiceDetails.code == 200) {
                invoiceArr['data'] = invoiceDetails.data.Invoices[0];
                invoiceArr['type'] = 'invoice';
                invoiceArr['invoiceType'] = invoiceType;

            }
        }
    }

    return invoiceArr;

} 