export const GET_WORKSHOP_BAYS_DETAILS = "GET_WORKSHOP_BAYS_DETAILS";
export const GET_WORKSHOP_BAYS_DETAILS_SUCCESS =
  "GET_WORKSHOP_BAYS_DETAILS_SUCCESS";
export const GET_WORKSHOP_BAYS_DETAILS_FAIL = "GET_WORKSHOP_BAYS_DETAILS_FAIL";

export const ADD_WORKSHOP_BAYS_DETAILS = "ADD_WORKSHOP_BAYS_DETAILS";
export const ADD_WORKSHOP_BAYS_DETAILS_SUCCESS =
  "ADD_WORKSHOP_BAYS_DETAILS_SUCCESS";
export const ADD_WORKSHOP_BAYS_DETAILS_FAIL = "ADD_WORKSHOP_BAYS_DETAILS_FAIL";

export const CHECK_NAME_WORKSHOP_BAYS_DETAILS =
  "CHECK_NAME_WORKSHOP_BAYS_DETAILS";
export const CHECK_NAME_WORKSHOP_BAYS_DETAILS_SUCCESS =
  "CHECK_NAME_WORKSHOP_BAYS_DETAILS_SUCCESS";
export const CHECK_NAME_WORKSHOP_BAYS_DETAILS_FAIL =
  "CHECK_NAME_WORKSHOP_BAYS_DETAILS_FAIL";

export const CHECK_ORDER_WORKSHOP_BAYS_DETAILS =
  "CHECK_ORDER_WORKSHOP_BAYS_DETAILS";
export const CHECK_ORDER_WORKSHOP_BAYS_DETAILS_SUCCESS =
  "CHECK_ORDER_WORKSHOP_BAYS_DETAILS_SUCCESS";
export const CHECK_ORDER_WORKSHOP_BAYS_DETAILS_FAIL =
  "CHECK_ORDER_WORKSHOP_BAYS_DETAILS_FAIL";

export const EDIT_WORKSHOP_BAYS_DETAILS = "EDIT_WORKSHOP_BAYS_DETAILS";
export const EDIT_WORKSHOP_BAYS_DETAILS_SUCCESS =
  "EDIT_WORKSHOP_BAYS_DETAILS_SUCCESS";
export const EDIT_WORKSHOP_BAYS_DETAILS_FAIL =
  "EDIT_WORKSHOP_BAYS_DETAILS_FAIL";

export const UPDATE_WORKSHOP_BAYS_DETAILS = "UPDATE_WORKSHOP_BAYS_DETAILS";
export const UPDATE_WORKSHOP_BAYS_DETAILS_SUCCESS =
  "UPDATE_WORKSHOP_BAYS_DETAILS_SUCCESS";
export const UPDATE_WORKSHOP_BAYS_DETAILS_FAIL =
  "UPDATE_WORKSHOP_BAYS_DETAILS_FAIL";

export const DELETE_WORKSHOP_BAYS_DETAILS = "DELETE_WORKSHOP_BAYS_DETAILS";
export const DELETE_WORKSHOP_BAYS_DETAILS_SUCCESS =
  "DELETE_WORKSHOP_BAYS_DETAILS_SUCCESS";
export const DELETE_WORKSHOP_BAYS_DETAILS_FAIL =
  "DELETE_WORKSHOP_BAYS_DETAILS_FAIL";
