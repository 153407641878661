import {
  GET_CHANGE_PASSWORD,
  GET_CHANGE_PASSWORD_SUCCESS,
  GET_CHANGE_PASSWORD_FAIL
} from "./actionTypes";

const initialState = {
  loadingLogins: false,
  passwordData:null,
  error: {
    message: "",
  },
  isSuccess: false,
  isFetching: false,
};

const ChangePasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CHANGE_PASSWORD:
      state = { 
        ...state, 
        loadingLogins: true,
        passwordData:null,
        isSuccess: false,
        isFetching: false,
      };
      break;
    case GET_CHANGE_PASSWORD_SUCCESS:
      state = { 
        ...state, 
        loadingLogins: false,
        passwordData: action.payload,
        isSuccess: true,
        isFetching: true,
      };
      break;
    case GET_CHANGE_PASSWORD_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loadingLogins: false,
        passwordData:null,
        isSuccess: false,
        isFetching: true,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ChangePasswordReducer;
