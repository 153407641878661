import React, { useEffect, useState } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import cashSaleBlue from "../../../assets/images/cash-sale-blue.svg";

import OpenQuotes from './OpenQuotes';
import WonQuotes from './WonQuotes';
import LostQuotes from './LostQuotes';

let QuoteList = () => {

    const [title, setTitle] = useState("5iQ | Quotes");

    useEffect(() => {
        document.title = "5iQ | Quotes";
    }, [title]);

    return (
        <div className="right-section-wrapper">
            <div className="table-header">
                <div className='top-block'>
                    <div className="table-title">
                        <img src={cashSaleBlue} alt=""></img>
                        <span>QUOTES</span>
                    </div>
                    <div className="video-link-outer">
                        <div className="video-tutorial">
                            <i className="fa fa-icon"></i>
                            <span className="help-text">Help</span>
                            <span className="tutorial-title">Watch Tutorial</span>
                        </div>
                    </div>
                </div>
                <div className="table-header-tabs">
                    <Tabs defaultActiveKey="open-quotes">
                        <Tab eventKey="open-quotes" title="Open Quotes">
                            <OpenQuotes />
                        </Tab>
                        <Tab eventKey="won-quotes" title="Won Quotes">
                            <WonQuotes />
                        </Tab>
                        <Tab eventKey="lost-quotes" title="Lost Quotes">
                            <LostQuotes />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

export default QuoteList;
