import {
  ADD_SUPPLIER,
  ADD_SUPPLIER_SUCCESS,
  ADD_SUPPLIER_FAIL,
  GET_SUPPLIER_LIST,
  GET_SUPPLIER_LIST_SUCCESS,
  GET_SUPPLIER_LIST_FAIL,
  CHECK_SUPPLIER_EXIST,
  CHECK_SUPPLIER_EXIST_SUCCESS,
  CHECK_SUPPLIER_EXIST_FAIL  
} from "./actionTypes";

const initialState = {
  data: [],
  supplierData: null,
  error: {
    message: "",
  },
  supplierListData: null,
  loadingData: false,
  isSupplierExist: false,
};

const SupplierReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SUPPLIER:
      state = { 
        ...state, 
        loadingData: true,
        supplierData: null,
      };
    break;
    case ADD_SUPPLIER_SUCCESS:
      state = { 
        ...state, 
        supplierData: action.payload,
      };
    break;
    case ADD_SUPPLIER_FAIL:
      state = {
        ...state,
        supplierData: null,
        error: {
          message: action.payload,
        },
      };
    break;
    case GET_SUPPLIER_LIST:
      state = { 
        ...state, 
        supplierListData: null,
      };
    break;
    case GET_SUPPLIER_LIST_SUCCESS:
      state = { 
        ...state, 
        supplierListData: action.payload,
      };
    break;
    case GET_SUPPLIER_LIST_FAIL:
      state = {
        ...state,
        supplierListData: null,
        error: {
          message: action.payload,
        },
      };
    break;
    case CHECK_SUPPLIER_EXIST:
      state = { 
        ...state, 
        isSupplierExist: false,
      };
    break;
    case CHECK_SUPPLIER_EXIST_SUCCESS:
      state = { 
        ...state, 
        isSupplierExist: true,
      };
    break;
    case CHECK_SUPPLIER_EXIST_FAIL:
      state = { 
        ...state, 
        isSupplierExist: false,
      };
    break;
    default:
      state = { ...state };
    break;
  }
  return state;
};

export default SupplierReducer;
