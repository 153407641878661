import AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: "AKIA2HR5ROK6PX56XFXB",
  secretAccessKey: "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",
});

const S3_BUCKET = "5iq-website-uploads";
const REGION = "ap-southeast-2";

const myBucket = new AWS.S3({
  params: {
    Bucket: S3_BUCKET,
  },
  region: REGION,
});

export function UploadFileToS3(file, filename) {

    console.log('upload file to s3');

  var params = {
    ACL: "public-read",
    ContentType: file.type,
    Body: file,
    Bucket: S3_BUCKET,
    Key: filename,
  };

  myBucket.putObject(params, function (err, data) {
    if (err) {
      console.log(err, err.stack);
      return err;
    } else {
      console.log(data);
      return filename;
    }
  });
}

export function deleteFileToS3(filename) {
  console.log("delete file from s3");

  var params = {
    Bucket: S3_BUCKET,
    Key: filename,
  };

  myBucket.deleteObject(params, function (err, data) {
    if (err) {
      console.log(err, err.stack);
      return err;
    } else {
      console.log(data);
      return filename;
    }
  });
}

export function downloadFileFromS3(bucketKey) {
  var s3 = new AWS.S3();
  const signedUrlExpireSeconds = 60 * 5;
  var Url = s3.getSignedUrl("getObject", {
    Bucket: S3_BUCKET,
    Key: bucketKey,
    Expires: signedUrlExpireSeconds,
  });
  return Url;
}

export async function uploadS3(file) {
  return new Promise((resolve, reject) => {
    myBucket.upload(
      {
        Bucket: S3_BUCKET,
        Key: Date.now() + "_" + file.name,
        Body: file,
        ContentType: file.type,
        ACL: "public-read",
      },
      function (err, data) {
        if (err) {
          reject(err);
        }

        // pass file unique id back to filepond
        resolve(data);
      }
    );
  });
}

export async function S3ImageShow(path) {
  return new Promise( async (res, rej) => {
    const signedUrlExpireSeconds = 60 * 5;
    let url = await myBucket.getSignedUrl("getObject", {
      Bucket: S3_BUCKET,
      Key: path,
      Expires: signedUrlExpireSeconds,
    });
    res(url);
  });
  // return imageUrl;
  // console.log('imageUrl',imageUrl)
}

const awsS3Functions = {
  UploadFileToS3,
  downloadFileFromS3,
  S3ImageShow
};

export default awsS3Functions;