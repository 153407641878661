import { takeLatest, put, call } from "redux-saga/effects";
import { GET_CHANGE_PASSWORD } from "./actionTypes";
import {
  getChangePasswordSuccess,
  getChangePasswordFail
} from "./actions";

import { getChangePassword } from "../../helpers/backendHelper";
import { showError, showSuccess } from "../../utils/alertMessage";

function* onGetChangePassword(data) {
  try {
    const response = yield call(getChangePassword, data);
    if(response){
      if(response.code===200){
        yield put(getChangePasswordSuccess(response));
        showSuccess(response.message);
      }else{
        yield put(getChangePasswordFail(response.message));
        showError(response.message);
      }
    }else{
      yield put(getChangePasswordFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(getChangePasswordFail(error.response));
  }
}

function* ChangePasswordSaga() {
  yield takeLatest(GET_CHANGE_PASSWORD, onGetChangePassword);
}

export default ChangePasswordSaga;
