import {
  GET_SUBSCRIPTION_ADDON,
  GET_SUBSCRIPTION_ADDON_SUCCESS,
  GET_SUBSCRIPTION_ADDON_FAIL,
  GET_SUBSCRIPTION_LOOKUP_ADDON,
  GET_SUBSCRIPTION_LOOKUP_ADDON_SUCCESS,
  GET_SUBSCRIPTION_LOOKUP_ADDON_FAIL,
} from "./actionTypes";

export const getSubscriptionAddon = () => {
  return {
    type: GET_SUBSCRIPTION_ADDON,
  };
};

export const getSubscriptionAddonSuccess = (data) => {
  return {
    type: GET_SUBSCRIPTION_ADDON_SUCCESS,
    payload: data,
  };
};

export const getSubscriptionAddonFail = (error) => {
  return {
    type: GET_SUBSCRIPTION_ADDON_FAIL,
    payload: error,
  };
};


export const getSubscriptionLookupAddon = () => {
  return {
    type: GET_SUBSCRIPTION_LOOKUP_ADDON,
  };
};

export const getSubscriptionLookupAddonSuccess = (data) => {
  return {
    type: GET_SUBSCRIPTION_LOOKUP_ADDON_SUCCESS,
    payload: data,
  };
};

export const getSubscriptionLookupAddonFail = (error) => {
  return {
    type: GET_SUBSCRIPTION_LOOKUP_ADDON_FAIL,
    payload: error,
  };
};
