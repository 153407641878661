import { takeLatest, put, call } from "redux-saga/effects";
import {
    GET_CUSTOM_FIELDS,
    CHECK_VIN_EXIST,
    CHECK_CHASIS_EXIST,
    CHECK_ENGINE_EXIST,
    CHECK_FLEET_EXIST,
    GET_ASSETS_MASTER,
    GET_ASSETS_DETAILS,
    ADD_ASSETS_DETAILS,
    ADD_ASSETS_MASTER,
    ADD_ASSETS
} from "./actionTypes";
import {
    getCustomFieldsSuccess,
    getCustomFieldsFail,
    checkVinExistSuccess,
    checkVinExistFail,
    checkChasisExistSuccess,
    checkChasisExistFail,
    checkEngineExistSuccess,
    checkEngineExistFail,
    checkFleetExistSuccess,
    checkFleetExistFail,
    getAssetsMasterSuccess,
    getAssetsMasterFail,
    getAssetsDetailsSuccess,
    getAssetsDetailsFail,
    addAssetsDetailsSuccess,
    addAssetsDetailsFail,
    addAssetsMasterSuccess,
    addAssetsMasterFail,
    addAssetsSuccess,
    addAssetsFail
} from "./actions";

import {
    getCustomFields,
    checkVinExist,
    checkEngineExist,
    checkChasisExist,
    checkFleetExist,
    getAssetsMaster,
    getAssetsDetails,
    addAssetsDetails,
    addAssetsMaster,
    addAssets
} from "../../helpers/backendHelper";


function* onGetCustomFields(data) {
    try {
        const response = yield call(getCustomFields, data);
        if (response) {
            if (response.code === 200) {
                yield put(
                    getCustomFieldsSuccess(response.data.VehiclesCustomFields)
                );
            } else {
                yield put(getCustomFieldsFail(response.message));
            }
        } else {
            yield put(getCustomFieldsFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getCustomFieldsFail(error.response));
    }
}

function* onCheckVinExist(data) {
    try {
        const response = yield call(checkVinExist, data);
        if (response) {
            if (response.code === 200) {
                yield put(checkVinExistSuccess(true));
            } else {
                yield put(checkVinExistFail(false));
            }
        } else {
            yield put(checkVinExistFail("Didn't get api response"));
        }
    } catch (error) {
        console.log("error", error);
        yield put(checkVinExistFail(error.response));
    }
}

function* onCheckChasisExist(data) {
    try {
        const response = yield call(checkChasisExist, data);
        if (response) {
            if (response.code === 200) {
                yield put(checkChasisExistSuccess(true));
            } else {
                yield put(checkChasisExistFail(false));
            }
        } else {
            yield put(checkChasisExistFail("Didn't get api response"));
        }
    } catch (error) {
        console.log("error", error);
        yield put(checkChasisExistFail(error.response));
    }
}

function* onCheckEngineExist(data) {
    try {
        const response = yield call(checkEngineExist, data);
        if (response) {
            if (response.code === 200) {
                yield put(checkEngineExistSuccess(true));
            } else {
                yield put(checkEngineExistFail(false));
            }
        } else {
            yield put(checkEngineExistFail("Didn't get api response"));
        }
    } catch (error) {
        console.log("error", error);
        yield put(checkEngineExistFail(error.response));
    }
}

function* onCheckFleetExist(data) {
    try {
        const response = yield call(checkFleetExist, data);
        if (response) {
            if (response.code === 200) {
                yield put(checkFleetExistSuccess(true));
            } else {
                yield put(checkFleetExistFail(false));
            }
        } else {
            yield put(checkFleetExistFail("Didn't get api response"));
        }
    } catch (error) {
        console.log("error", error);
        yield put(checkFleetExistFail(error.response));
    }
}

function* onGetAssetsMaster(data) {
    try {
        const response = yield call(getAssetsMaster, data);
        if (response) {
            if (response.code === 200) {
                yield put(getAssetsMasterSuccess(response.data.AssetsMaster));
            } else {
                yield put(getAssetsMasterFail(response.message));
            }
        } else {
            yield put(getAssetsMasterFail("Didn't get api response"));
        }
    } catch (error) {
        console.log("error", error);
        yield put(getAssetsMasterFail(error.response));
    }
}

function* onGetAssetsDetails(data) {
    try {
        const response = yield call(getAssetsDetails, data);
        if (response) {
            if (response.code === 200) {
                yield put(getAssetsDetailsSuccess(response.data.AssetsDetails));
            } else {
                yield put(getAssetsDetailsFail(response.message));
            }
        } else {
            yield put(getAssetsDetailsFail("Didn't get api response"));
        }
    } catch (error) {
        console.log("error", error);
        yield put(getAssetsDetailsFail(error.response));
    }
}

function* onAddAssetsDetails(data) {
    try {
        const response = yield call(addAssetsDetails, data);
        if (response) {
            if (response.code === 200) {
                yield put(addAssetsDetailsSuccess(response.data.AssetsDetails));
            } else {
                yield put(addAssetsDetailsFail(response.message));
            }
        } else {
            yield put(addAssetsDetailsFail("Didn't get api response"));
        }
    } catch (error) {
        console.log("error", error);
        yield put(addAssetsDetailsFail(error.response));
    }
}

function* onAddAssetsMaster(data) {
    try {
        const response = yield call(addAssetsMaster, data);
        if (response) {
            if (response.code === 200) {
                yield put(addAssetsMasterSuccess(response.data.AssetsMaster));
            } else {
                yield put(addAssetsMasterFail(response.message));
            }
        } else {
            yield put(addAssetsMasterFail("Didn't get api response"));
        }
    } catch (error) {
        console.log("error", error);
        yield put(addAssetsMasterFail(error.response));
    }
}

function* onAddAssets(data) {
    try {
        const response = yield call(addAssets, data);
        if (response) {
            if (response.code === 200) {
                yield put(addAssetsSuccess(response.data.Vehicles));
            } else {
                yield put(addAssetsFail(response.message));
            }
        } else {
            yield put(addAssetsFail("Didn't get api response"));
        }
    } catch (error) {
        console.log("error", error);
        yield put(addAssetsFail(error.response));
    }
}

function* AssetsSaga() {
    yield takeLatest(GET_CUSTOM_FIELDS, onGetCustomFields);
    yield takeLatest(CHECK_VIN_EXIST, onCheckVinExist);
    yield takeLatest(CHECK_CHASIS_EXIST, onCheckChasisExist);
    yield takeLatest(CHECK_ENGINE_EXIST, onCheckEngineExist);
    yield takeLatest(CHECK_FLEET_EXIST, onCheckFleetExist);
    yield takeLatest(GET_ASSETS_MASTER, onGetAssetsMaster);
    yield takeLatest(GET_ASSETS_DETAILS, onGetAssetsDetails);
    yield takeLatest(ADD_ASSETS_DETAILS, onAddAssetsDetails);
    yield takeLatest(ADD_ASSETS_MASTER, onAddAssetsMaster);
    yield takeLatest(ADD_ASSETS, onAddAssets);
}

export default AssetsSaga;
