import {
  GET_SUPPLIER_LIST,
  GET_SUPPLIER_LIST_SUCCESS,
  GET_SUPPLIER_LIST_FAIL,
  ADD_SUPPLIER,
  ADD_SUPPLIER_SUCCESS,
  ADD_SUPPLIER_FAIL,
  CHECK_SUPPLIER_EXIST,
  CHECK_SUPPLIER_EXIST_SUCCESS,
  CHECK_SUPPLIER_EXIST_FAIL
} from "./actionTypes";

// get Supplier list, search Supplier, check Supplier exist or not START
export const getSupplierList = () => {
  return {
    type: GET_SUPPLIER_LIST,
  };
};

export const getSupplierListSuccess = (data) => {
  return {
    type: GET_SUPPLIER_LIST_SUCCESS,
    payload: data,
  };
};

export const getSupplierListFail = (error) => {
  return {
    type: GET_SUPPLIER_LIST_FAIL,
    payload: error,
  };
};
// get Supplier list, search Supplier, check Supplier exist or not END


// Save Supplier START
export const addSupplier = () => {
  return {
    type: ADD_SUPPLIER,
  };
};

export const addSupplierSuccess = (data) => {
  return {
    type: ADD_SUPPLIER_SUCCESS,
    payload: data,
  };
};

export const addSupplierFail = (error) => {
  return {
    type: ADD_SUPPLIER_FAIL,
    payload: error,
  };
};
// Save Supplier END


// Check Supplier exists START
export const checkSupplierExist = () => {
  return {
    type: CHECK_SUPPLIER_EXIST,
  };
};

export const checkSupplierExistSuccess = (data) => {
  return {
    type: CHECK_SUPPLIER_EXIST_SUCCESS,
    payload: data,
  };
};

export const checkSupplierExistFail = (error) => {
  return {
    type: CHECK_SUPPLIER_EXIST_FAIL,
    payload: error,
  };
};
// Check Supplier exists END