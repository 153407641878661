import {
    GET_CUSTOM_FIELDS,
    GET_CUSTOM_FIELDS_SUCCESS,
    GET_CUSTOM_FIELDS_FAIL,
    CHECK_VIN_EXIST,
    CHECK_VIN_EXIST_SUCCESS,
    CHECK_VIN_EXIST_FAIL,
    CHECK_CHASIS_EXIST,
    CHECK_CHASIS_EXIST_SUCCESS,
    CHECK_CHASIS_EXIST_FAIL,
    CHECK_ENGINE_EXIST,
    CHECK_ENGINE_EXIST_SUCCESS,
    CHECK_ENGINE_EXIST_FAIL,
    CHECK_FLEET_EXIST,
    CHECK_FLEET_EXIST_SUCCESS,
    CHECK_FLEET_EXIST_FAIL,
    GET_ASSETS_MASTER,
    GET_ASSETS_MASTER_SUCCESS,
    GET_ASSETS_MASTER_FAIL,
    GET_ASSETS_DETAILS,
    GET_ASSETS_DETAILS_SUCCESS,
    GET_ASSETS_DETAILS_FAIL,
    ADD_ASSETS_DETAILS,
    ADD_ASSETS_DETAILS_SUCCESS,
    ADD_ASSETS_DETAILS_FAIL,
    ADD_ASSETS_MASTER,
    ADD_ASSETS_MASTER_SUCCESS,
    ADD_ASSETS_MASTER_FAIL,
    ADD_ASSETS,
    ADD_ASSETS_SUCCESS,
    ADD_ASSETS_FAIL,
} from "./actionTypes";

const initialState = {
    loadingLogins: false,
    message: "",
    // error: "",
    customFieldsData: null,
    checkVinData: false,
    checkChasisData: false,
    checkEngineData: false,
    checkFleetData: false,
    assetsMasterData: null,
    assetsDetailsData: null,
    addAssetsDetails: null,
    addAssetsMaster: null,
    addAssetsData: null,
    error: {
        message: "",
        vehicleMsg : null
    }
};

const AssetsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CUSTOM_FIELDS:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case GET_CUSTOM_FIELDS_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                customFieldsData: action.payload,
            };
            break;
        case GET_CUSTOM_FIELDS_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                customFieldsData: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case CHECK_VIN_EXIST:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case CHECK_VIN_EXIST_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                checkVinData: action.payload,
            };
            break;
        case CHECK_VIN_EXIST_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                checkVinData: action.payload,
                error: {
                    message: action.payload,
                },
            };
            break;
        case CHECK_CHASIS_EXIST:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case CHECK_CHASIS_EXIST_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                checkChasisData: action.payload,
            };
            break;
        case CHECK_CHASIS_EXIST_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                checkChasisData: action.payload,
                error: {
                    message: action.payload,
                },
            };
            break;
        case CHECK_ENGINE_EXIST:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case CHECK_ENGINE_EXIST_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                checkEngineData: action.payload,
            };
            break;
        case CHECK_ENGINE_EXIST_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                checkEngineData: action.payload,
                error: {
                    message: action.payload,
                },
            };
            break;
        case CHECK_FLEET_EXIST:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case CHECK_FLEET_EXIST_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                checkFleetData: action.payload,
            };
            break;
        case CHECK_FLEET_EXIST_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                checkFleetData: action.payload,
                error: {
                    message: action.payload,
                },
            };
            break;
        case GET_ASSETS_MASTER:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case GET_ASSETS_MASTER_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                assetsMasterData: action.payload,
            };
            break;
        case GET_ASSETS_MASTER_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                assetsMasterData: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case GET_ASSETS_DETAILS:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case GET_ASSETS_DETAILS_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                assetsDetailsData: action.payload,
            };
            break;
        case GET_ASSETS_DETAILS_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                assetsDetailsData: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case ADD_ASSETS_DETAILS:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case ADD_ASSETS_DETAILS_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                addAssetsDetails: action.payload,
            };
            break;
        case ADD_ASSETS_DETAILS_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                addAssetsDetails: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case ADD_ASSETS_MASTER:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case ADD_ASSETS_MASTER_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                addAssetsMaster: action.payload,
            };
            break;
        case ADD_ASSETS_MASTER_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                addAssetsMaster: null,
                error: {
                    message: action.payload,
                },
            };
            break;
        case ADD_ASSETS:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case ADD_ASSETS_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                addAssetsData: action.payload,
            };
            break;
        case ADD_ASSETS_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                error: {
                    vehicleMsg: action.payload,
                },
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default AssetsReducer;
