export const GET_REGISTER = "GET_REGISTER";
export const GET_REGISTER_SUCCESS = "GET_REGISTER_SUCCESS";
export const GET_REGISTER_FAIL = "GET_REGISTER_FAIL";

export const SEND_OTP = "SEND_OTP";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAIL = "SEND_OTP_FAIL";

export const CONFIRM_OTP = "CONFIRM_OTP";
export const CONFIRM_OTP_SUCCESS = "CONFIRM_OTP_SUCCESS";
export const CONFIRM_OTP_FAIL = "CONFIRM_OTP_FAIL";

export const EXIST_EMAIL = "EXIST_EMAIL";
export const EXIST_EMAIL_SUCCESS = "EXIST_EMAIL_SUCCESS";
export const EXIST_EMAIL_FAIL = "EXIST_EMAIL_FAIL";

export const GET_COUNTRY = "GET_COUNTRY";
export const GET_COUNTRY_SUCCESS = "GET_COUNTRY_SUCCESS";
export const GET_COUNTRY_FAIL = "GET_COUNTRY_FAIL";

export const GET_STATE = "GET_STATE";
export const GET_STATE_SUCCESS = "GET_STATE_SUCCESS";
export const GET_STATE_FAIL = "GET_STATE_FAIL";

export const GET_INDUSTRY = "GET_INDUSTRY";
export const GET_INDUSTRY_SUCCESS = "GET_INDUSTRY_SUCCESS";
export const GET_INDUSTRY_FAIL = "GET_INDUSTRY_FAIL";

export const GET_ACCOUNTING_PACKAGE = "GET_ACCOUNTING_PACKAGE";
export const GET_ACCOUNTING_PACKAGE_SUCCESS = "GET_ACCOUNTING_PACKAGE_SUCCESS";
export const GET_ACCOUNTING_PACKAGE_FAIL = "GET_ACCOUNTING_PACKAGE_FAIL";

export const GET_SIZE_OF_WORKSHOP = "GET_SIZE_OF_WORKSHOP";
export const GET_SIZE_OF_WORKSHOP_SUCCESS = "GET_SIZE_OF_WORKSHOP_SUCCESS";
export const GET_SIZE_OF_WORKSHOP_FAIL = "GET_SIZE_OF_WORKSHOP_FAIL";

export const GET_REFERRAL_ADVERTISE = "GET_REFERRAL_ADVERTISE";
export const GET_REFERRAL_ADVERTISE_SUCCESS = "GET_REFERRAL_ADVERTISE_SUCCESS";
export const GET_REFERRAL_ADVERTISE_FAIL = "GET_REFERRAL_ADVERTISE_FAIL";
