import moment from 'moment-timezone/builds/moment-timezone-with-data';

export function timeZoneToUTC(fromTime, fromTimezone, fromTimeformat, convertformat = "HH:mm:ss") {
    // construct a moment object
    var m = moment.tz(fromTime, fromTimeformat, fromTimezone);

    // convert it to utc
    m.utc();

    // format it for output
    var s = m.format(convertformat)

    return s;
}

export function utcToTimezone(fromTime, toTimezone, fromTimeformat, convertformat = "HH:mm:ss") {
    const m = moment.utc(fromTime, fromTimeformat).tz(toTimezone).format(convertformat);
    return m;
}

export function convertToMinute(time) {
    return moment.duration(time).asMinutes();
}

export function convertToHours(time) {
    var hours = (time / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    if (rhours < 10) {
        rhours = "0" + rhours;
    }
    if (rminutes < 10) {
        rminutes = "0" + rminutes;
    }
    return rhours + ":" + rminutes;
    // return moment.utc(moment.duration(time, "minutes").asMilliseconds()).format("HH:mm");
}

export function timeDiffMinute(startTime, endTime) {

    var start = moment(startTime, 'HH:mm:ss');
    var end = moment(endTime, 'HH:mm:ss');

    var duration = moment.duration(end.diff(start));
    var minute = duration.asMinutes();

    return minute;
}

export function dateDiff(startDate, endDate) {
    let time = "";
    console.log(startDate, endDate);

    return time;
}

const dateFunctions = {
    timeZoneToUTC,
    utcToTimezone,
    convertToMinute,
    convertToHours,
    timeDiffMinute,
    dateDiff
};

export default dateFunctions;
