import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import LoaderImage from "../../../../../../src/assets/images/page-loader.svg";
import {
  GET_WORKSHOP_MARKUP_DETAIL,
  UPDATE_DEFAULT_WORKSHOP_MARKUP_DETAIL,
} from "../../../../../store/companyDetails/actionTypes";
import { deleteWorkshopMarkup } from "../../../../../helpers/backendHelper";
import { GET_WORKSHOP_MARKUP_DETAILS } from "../../../../../store/commonApiStore/actionTypes";
import DeleteConfirm from "../../../../../utils/deleteConfirm";
import { showError, showSuccess } from "../../../../../utils/alertMessage";

const CustomerPricingLevelsList = () => {
  const dispatch = useDispatch(); // Dispatch variable
  const { levelsCount, levelsData, formLoading } = useSelector(
    (state) => state.CompanyDetailReducer
  ); // GET Form loader from Company Detail Reducer data
  const { SearchBar } = Search;
  const [levelData, setLevelData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [selectedRow, setSelectedRow] = useState();

  // GET mark up details for edit
  const editMarkUpDetails = (markup) => {
    dispatch({
      type: GET_WORKSHOP_MARKUP_DETAILS,
      payload: {
        get: "all",
        conditions: {
          id: markup.id,
          workshop_id: JSON.parse(localStorage.getItem("data")).workshop_id,
        },
      },
    });
  };
  // UPDATE default value of all markups and set default as selected mark up
  const updateDefaultMarkUpDetails = (markup) => {
    const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
    const workshop_id = window.atob(storedData.workshop_id);
    const editFormData = {
      is_default: "0",
      workshop_id,
      id: markup.id,
    };
    dispatch({
      type: UPDATE_DEFAULT_WORKSHOP_MARKUP_DETAIL,
      payload: editFormData,
    });
  };
  const sorting = (order, column) => {
    if (!order)
      return (
        <span className="ps-2">
          <i className="fa fa-sort" aria-hidden="true"></i>
        </span>
      );
    else if (order === "asc")
      return (
        <span className="ps-2">
          <i className="fa fa-sort-asc" aria-hidden="true"></i>
        </span>
      );
    else if (order === "desc")
      return (
        <span className="ps-2">
          <i className="fa fa-sort-desc" aria-hidden="true"></i>
        </span>
      );
    return null;
  };

  const columns = [
    {
      dataField: "charge_type",
      text: "Name",
      sort: true,
      sortCaret: (order, column) => {
        return sorting(order, column);
      },
    },
    {
      dataField: "percentage",
      text: "Percentage",
      formatter: (cell, row) => {
        return <div>{`${row.percentage}%`}</div>;
      },
      sort: true,
      sortCaret: (order, column) => {
        return sorting(order, column);
      },
    },
    {
      dataField: "is_markup",
      text: "Charge Type",
      formatter: (cell, row) => {
        return <div>{row.is_markup === "1" ? "Margin" : "Markup"}</div>;
      },
      sort: true,
      sortCaret: (order, column) => {
        return sorting(order, column);
      },
    },
    {
      dataField: "is_default",
      text: "Default",
      sort: true,
      sortCaret: (order, column) => {
        return sorting(order, column);
      },
      formatter: (cell, row) => {
        return (
          <div>
            <Form.Check className="custom-check mb-2">
              <Form.Check.Input
                type="checkbox"
                name="favorite"
                id={`default-${row.id}`}
                className="custom-check secondary-check mb-2"
                checked={row.is_default === "1"}
                onChange={(e) => {
                  if (e.target.checked) {
                    updateDefaultMarkUpDetails(row);
                  }
                }}
              />
              <Form.Check.Label htmlFor={`default-${row.id}`}>
                <span className="check"></span>
              </Form.Check.Label>
            </Form.Check>
          </div>
        );
      },
    },

    {
      dataField: "df1",
      isDummyField: true,
      text: " ",
      formatter: (cell, row) => {
        return (
          <div className="btn-group toggle-hover">
            <button className="btn " type="button">
              <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
            </button>
            <div className="dropdown-list">
              <li>
                <button
                  onClick={() => {
                    editMarkUpDetails(row);
                  }}
                  type="button"
                  className="w-100"
                >
                  <span>
                    <i className="fa fa-pencil text-info"></i>
                  </span>
                  <span>Edit</span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    setSelectedRow(row.id);
                    setShowDeleteModal(!showDeleteModal);
                  }}
                  type="button"
                  variant="danger"
                  className="delete-link w-100"
                >
                  <span className="red-bg">
                    <i className="fa fa-minus"></i>
                  </span>
                  <span>Delete</span>
                </button>
              </li>
            </div>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    setLoading(true);
    dispatch({
      type: GET_WORKSHOP_MARKUP_DETAIL,
      payload: {
        workshop_id: JSON.parse(localStorage.getItem("data")).workshop_id,
        limit: sizePerPage,
      },
    });
  }, []);
  const onTableChange = (type, { page, sizePerPage }) => {
    setCurrentPage(page);
    setSizePerPage(sizePerPage);
    loadData(page, sizePerPage);
  };
  const loadData = (page = 1, pageLimit = 10) => {
    dispatch({
      type: GET_WORKSHOP_MARKUP_DETAIL,
      payload: {
        workshop_id: JSON.parse(localStorage.getItem("data")).workshop_id,
      },
    });
  };
  useEffect(() => {
    if (levelsData) {
      setLevelData(levelsData);
      setLoading(false);
    }
  }, [levelsData]);
  const deleteRecord = async () => {
    setLoading(true);
    let data = {
      payload: { id: selectedRow },
    };
    let response = await deleteWorkshopMarkup(data);
    if (response.code === 200) {
      setLoading(false);
      showSuccess("Markup Deleted Sucessfully");
      setShowDeleteModal(false);
      loadData();
    } else {
      setLoading(false);
      showError(response.message);
      setShowDeleteModal(false);
    }
  };
  const emptyDataMessage = () => {
    return (
      <div style={{ textAlign: "center" }}>No data available in table</div>
    );
  };
  return (
    <>
      <div>
        <ToolkitProvider
          keyField="id"
          data={levelData}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="table-search-wrapper ">
                <SearchBar {...props.searchProps} />
              </div>
              <PaginationProvider
                pagination={paginationFactory({
                  custom: true,
                  page: currentPage,
                  sizePerPage,
                  totalSize: levelsCount,
                  nextPageText: "Next",
                  prePageText: "Previous",
                })}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <div>
                    <BootstrapTable
                      keyField="id"
                      data={levelData}
                      columns={columns}
                      onTableChange={onTableChange}
                      noDataIndication={emptyDataMessage}
                      {...paginationTableProps}
                      {...props.baseProps}
                    />
                    <div>
                      <div className="table-pagination">
                        <div>
                          <span>Show</span>
                          <SizePerPageDropdownStandalone {...paginationProps} />
                          <span>entries</span>
                        </div>
                        <PaginationTotalStandalone {...paginationProps} />
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </div>
                  </div>
                )}
              </PaginationProvider>
            </div>
          )}
        </ToolkitProvider>
      </div>
      {showDeleteModal && (
        <DeleteConfirm
          handleDeleteClose={setShowDeleteModal}
          deleteRecord={deleteRecord}
          msg={"Delete this record?"}
        />
      )}

      {(formLoading || loading) && (
        <div className="page-loader d-flex align-center justify-center">
          <img src={LoaderImage} alt="Loading"></img>
        </div>
      )}
    </>
  );
};

export default CustomerPricingLevelsList;
