import Loadable from "react-loadable";

import { Loading } from "../common/navigation";

const Scheduler = Loadable({
  loader: () => import("./components/Scheduler"),
  loading: Loading,
});

export const routes = [
  {
    path: "/scheduler",
    exact: true,
    name: "scheduler",
    element: Scheduler,
  }
];

export * from "./components";
