import {
  GET_SUBSCRIPTION_ADDON,
  GET_SUBSCRIPTION_ADDON_SUCCESS,
  GET_SUBSCRIPTION_ADDON_FAIL,
  GET_SUBSCRIPTION_LOOKUP_ADDON,
  GET_SUBSCRIPTION_LOOKUP_ADDON_SUCCESS,
  GET_SUBSCRIPTION_LOOKUP_ADDON_FAIL,
} from "./actionTypes";


const initialState = {
  loadingLogins: false,
  subscribeAddonData:null,
  subscribeLookupAddonData:null,
  error: {
    message: "",
  },
  isSubscription: 0,
  isLookupSubscription: 0,
  isFetching: false,
};

const SubscriptionAddonReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_ADDON:
      state = { 
        ...state, 
        loadingLogins: true,
      };
      break;
    case GET_SUBSCRIPTION_ADDON_SUCCESS:
      state = { 
        ...state, 
        loadingLogins: false,
        subscribeAddonData: action.payload,
        isSubscription: 1,
      };
      break;
    case GET_SUBSCRIPTION_ADDON_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        error: {
          message: action.payload,
        },
        isSubscription: 0,
      };
      break;


      case GET_SUBSCRIPTION_LOOKUP_ADDON:
      state = { 
        ...state, 
        subscribeLookupAddonData: null,
        isLookupSubscription: 0,
      };
      break;
    case GET_SUBSCRIPTION_LOOKUP_ADDON_SUCCESS:
      state = { 
        ...state, 
        subscribeLookupAddonData: action.payload,
        isLookupSubscription: 1,
      };
      break;
    case GET_SUBSCRIPTION_LOOKUP_ADDON_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        subscribeLookupAddonData: null,
        isLookupSubscription: 0,
     };
      break;
      
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default SubscriptionAddonReducer;
