import {
  GET_STATUSES_DETAILS,
  GET_STATUSES_DETAILS_SUCCESS,
  GET_STATUSES_DETAILS_FAIL,
  ADD_STATUSES_DETAILS,
  ADD_STATUSES_DETAILS_SUCCESS,
  ADD_STATUSES_DETAILS_FAIL,
  EDIT_STATUSES_DETAILS,
  EDIT_STATUSES_DETAILS_SUCCESS,
  EDIT_STATUSES_DETAILS_FAIL,
  UPDATE_STATUSES_DETAILS,
  UPDATE_STATUSES_DETAILS_SUCCESS,
  UPDATE_STATUSES_DETAILS_FAIL,
  DELETE_STATUSES_DETAILS,
  DELETE_STATUSES_DETAILS_SUCCESS,
  DELETE_STATUSES_DETAILS_FAIL,
  CHECK_NAME_STATUSES_DETAILS,
  CHECK_NAME_STATUSES_DETAILS_SUCCESS,
  CHECK_NAME_STATUSES_DETAILS_FAIL,
  GET_STATUSES_COUNT,
  GET_STATUSES_COUNT_SUCCESS,
  GET_STATUSES_COUNT_FAIL,
  GET_STATUS_TECHNICIAN_DETAILS,
  GET_STATUS_TECHNICIAN_DETAILS_SUCCESS,
  GET_STATUS_TECHNICIAN_DETAILS_FAIL
} from "./actionTypes";

export const getStatusesDetail = (data) => {
  return {
    type: GET_STATUSES_DETAILS,
    payload: data,
  };
};

export const getStatusesSuccess = (data) => {
  return {
    type: GET_STATUSES_DETAILS_SUCCESS,
    payload: data.data,
    message: data.message,
  };
};

export const getStatusesFail = (error) => {
  return {
    type: GET_STATUSES_DETAILS_FAIL,
    payload: error,
  };
};

export const getStatusTechnicianDetail = (data) => {
  return {
    type: GET_STATUS_TECHNICIAN_DETAILS,
    payload: data,
  };
};

export const getStatusTechnicianSuccess = (data) => {
  return {
    type: GET_STATUS_TECHNICIAN_DETAILS_SUCCESS,
    payload: data.data,
    message: data.message,
  };
};

export const getStatusTechnicianFail = (error) => {
  return {
    type: GET_STATUS_TECHNICIAN_DETAILS_FAIL,
    payload: error,
  };
};

export const addStatusesDetail = () => {
  return {
    type: ADD_STATUSES_DETAILS,
  };
};

export const addStatusesSuccess = (data) => {
  return {
    type: ADD_STATUSES_DETAILS_SUCCESS,
    payload: data.data,
    message: data.message,
  };
};

export const addStatusesFail = (error) => {
  return {
    type: ADD_STATUSES_DETAILS_FAIL,
    payload: error,
  };
};

export const checkNameStatusesDetail = () => {
  return {
    type: CHECK_NAME_STATUSES_DETAILS,
  };
};

export const checkNameStatusesSuccess = (data) => {
  return {
    type: CHECK_NAME_STATUSES_DETAILS_SUCCESS,
    payload: data,
    message: data.message,
  };
};

export const checkNameStatusesFail = (error) => {
  return {
    type: CHECK_NAME_STATUSES_DETAILS_FAIL,
    payload: error,
  };
};

export const editStatusesDetail = () => {
  return {
    type: EDIT_STATUSES_DETAILS,
  };
};

export const editStatusesSuccess = (data) => {
  return {
    type: EDIT_STATUSES_DETAILS_SUCCESS,
    payload: data.data,
    message: data.message,
  };
};

export const editStatusesFail = (error) => {
  return {
    type: EDIT_STATUSES_DETAILS_FAIL,
    payload: error,
  };
};

export const updateStatusesDetail = () => {
  return {
    type: UPDATE_STATUSES_DETAILS,
  };
};

export const updateStatusesSuccess = (data) => {
  return {
    type: UPDATE_STATUSES_DETAILS_SUCCESS,
    payload: data.data,
    message: data.message,
  };
};

export const updateStatusesFail = (error) => {
  return {
    type: UPDATE_STATUSES_DETAILS_FAIL,
    payload: error,
  };
};

export const deleteStatusesDetail = () => {
  return {
    type: DELETE_STATUSES_DETAILS,
  };
};

export const deleteStatusesSuccess = (data) => {
  return {
    type: DELETE_STATUSES_DETAILS_SUCCESS,
    payload: data.data,
    message: data.message,
  };
};

export const deleteStatusesFail = (error) => {
  return {
    type: DELETE_STATUSES_DETAILS_FAIL,
    payload: error,
  };
};

export const getStatusesCount = () => {
  return {
    type: GET_STATUSES_COUNT,
  };
};

export const getStatusesCountSuccess = (data) => {
  return {
    type: GET_STATUSES_COUNT_SUCCESS,
    payload: data,
  };
};

export const getStatusesCountFail = (error) => {
  return {
    type: GET_STATUSES_COUNT_FAIL,
    payload: error,
  };
};
