import { takeLatest, put, call } from "redux-saga/effects";
import { GET_INPROGRESS_JOBS, GET_INPROGRESS_JOBS_COUNT } from "./actionTypes";
import {
  getinProgressJobsSuccess,
  getinProgressJobsFail,
  getinProgressJobsCountSuccess,
  getinProgressJobsCountFail,
} from "./actions";

import { getinProgressJobs } from "../../helpers/backendHelper";

function* onGetinProgressJobs(data) {
  try {
    const response = yield call(getinProgressJobs, data);
    if (response) {
      if (response.code === 200) {
        yield put(getinProgressJobsSuccess(response.data.JobSchedules));
      } else {
        yield put(getinProgressJobsFail(response.message));
      }
    } else {
      yield put(getinProgressJobsFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getinProgressJobsFail(error.response));
  }
}

function* onGetinProgressJobsCount(data) {
  try {
    const response = yield call(getinProgressJobs, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getinProgressJobsCountSuccess(response.data.JobSchedules[0].COUNT)
        );
      } else {
        yield put(getinProgressJobsCountFail(response.message));
      }
    } else {
      yield put(getinProgressJobsCountFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getinProgressJobsCountFail(error.response));
  }
}

function* assignedJobsSaga() {
  yield takeLatest(GET_INPROGRESS_JOBS, onGetinProgressJobs);
  yield takeLatest(GET_INPROGRESS_JOBS_COUNT, onGetinProgressJobsCount);
}

export default assignedJobsSaga;
