import { takeLatest, put, call } from "redux-saga/effects";
import { GET_SUBSCRIPTION, GET_SUBSCRIPTION_PLANS, GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST } from "./actionTypes";
import {
  getSubscriptionSuccess,
  getSubscriptionFail,
  getWorkShopSmsSubscriptionDetailsListSuccess,
  getWorkShopSmsSubscriptionDetailsListFail,
  getSubscriptionPlansSuccess,
  getSubscriptionPlansFail
} from "./actions";

import { getSubscription, getSubscriptionPlans, getWorkshopSubscriptionDetailsList } from "../../helpers/backendHelper";

function* onGetSubscription(data) {
  try {
    const response = yield call(getSubscription, data);
    if(response){
      if(response.code===200){
        const lastarray = parseInt(response.data.WorkshopSubscription.length) - 1;
        yield put(getSubscriptionSuccess(response.data.WorkshopSubscription[lastarray]));
      }else if(response.code===400){
        yield put(getSubscriptionSuccess({}));
      }else{
        yield put(getSubscriptionFail(response.message));
      }
    }else{
      yield put(getSubscriptionFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getSubscriptionFail(error.response));
  }
}

function* onGetSubscriptionPlans(data) {
  try {
    const response = yield call(getSubscriptionPlans, data);
    if(response){
      if(response.code===200){
        yield put(getSubscriptionPlansSuccess(response.data.SubscriptionPlan));
      }else{
        yield put(getSubscriptionFail(response.message));
      }
    }else{
      yield put(getSubscriptionFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getSubscriptionPlansFail(error.response));
  }
}

function* onGetWorkShopSmsSubscription(data) {
    try {
        const response = yield call(getWorkshopSubscriptionDetailsList, data);
        if(response){
            if(response.code===200){
                let manualData = {};
                manualData.isLookSubscription = 0;
                if(response.data.WorkshopSubscriptionDetails.length > 0) {
                    manualData.id = response.data.WorkshopSubscriptionDetails ? response.data.WorkshopSubscriptionDetails[0].id : "";
                    manualData.workshop_subscription_id = response.data.WorkshopSubscriptionDetails ? response.data.WorkshopSubscriptionDetails[0].workshop_subscription_id : "";
                    manualData.subscription_plan_id = response.data.WorkshopSubscriptionDetails ? response.data.WorkshopSubscriptionDetails[0].subscription_plan_id : "";
                    manualData.current_vin_request = response.data.WorkshopSubscriptionDetails ? response.data.WorkshopSubscriptionDetails[0].current_vin_request : "";
                    manualData.isLookSubscription = 1;
                }
                yield put(getWorkShopSmsSubscriptionDetailsListSuccess(manualData));
            }else{
                yield put(getWorkShopSmsSubscriptionDetailsListFail(response.message));
            }
        }else{
            yield put(getWorkShopSmsSubscriptionDetailsListFail("Didn't get api response"));
        }
    } catch (error) {
        yield put(getWorkShopSmsSubscriptionDetailsListFail(error.response));
    }
}

function* SubscriptionSaga() {
  yield takeLatest(GET_SUBSCRIPTION, onGetSubscription);
  yield takeLatest(GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST, onGetWorkShopSmsSubscription);
  yield takeLatest(GET_SUBSCRIPTION_PLANS, onGetSubscriptionPlans);
}

export default SubscriptionSaga;
