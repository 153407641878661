import {
  GET_WORKSHOP_BAYS_DETAILS,
  GET_WORKSHOP_BAYS_DETAILS_SUCCESS,
  GET_WORKSHOP_BAYS_DETAILS_FAIL,
  ADD_WORKSHOP_BAYS_DETAILS,
  ADD_WORKSHOP_BAYS_DETAILS_SUCCESS,
  ADD_WORKSHOP_BAYS_DETAILS_FAIL,
  EDIT_WORKSHOP_BAYS_DETAILS,
  EDIT_WORKSHOP_BAYS_DETAILS_SUCCESS,
  EDIT_WORKSHOP_BAYS_DETAILS_FAIL,
  UPDATE_WORKSHOP_BAYS_DETAILS,
  UPDATE_WORKSHOP_BAYS_DETAILS_SUCCESS,
  UPDATE_WORKSHOP_BAYS_DETAILS_FAIL,
  DELETE_WORKSHOP_BAYS_DETAILS,
  DELETE_WORKSHOP_BAYS_DETAILS_SUCCESS,
  DELETE_WORKSHOP_BAYS_DETAILS_FAIL,
  CHECK_NAME_WORKSHOP_BAYS_DETAILS,
  CHECK_NAME_WORKSHOP_BAYS_DETAILS_SUCCESS,
  CHECK_NAME_WORKSHOP_BAYS_DETAILS_FAIL,
  CHECK_ORDER_WORKSHOP_BAYS_DETAILS,
  CHECK_ORDER_WORKSHOP_BAYS_DETAILS_SUCCESS,
  CHECK_ORDER_WORKSHOP_BAYS_DETAILS_FAIL,
} from "./actionTypes";

const initialState = {
  loading: false,
  formLoading: false,
  error: {
    message: "",
  },
  success: {
    message: "",
  },
  isAddOrUpdate: false,
  isNameAvailable: true,
  isOrderAvailable: true,
  workshopBays: null,
  workshopBayDetails: null,
};

const WorkshopBaysReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WORKSHOP_BAYS_DETAILS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_WORKSHOP_BAYS_DETAILS_SUCCESS:
      state = {
        ...state,
        workshopBays: action.payload.WorkshopBaysDetails,
        loading: false,
        success: {
          message: action.message,
        },
      };
      break;
    case GET_WORKSHOP_BAYS_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      };
      break;

    case ADD_WORKSHOP_BAYS_DETAILS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_WORKSHOP_BAYS_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        isAddOrUpdate: true,
        statuses: action.payload,
        success: {
          message: action.message,
        },
      };
      break;
    case ADD_WORKSHOP_BAYS_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      };
      break;

    case CHECK_NAME_WORKSHOP_BAYS_DETAILS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CHECK_NAME_WORKSHOP_BAYS_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        isNameAvailable: action.payload.length > 0 ? false : true,
      };
      break;
    case CHECK_NAME_WORKSHOP_BAYS_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      };
      break;

    case CHECK_ORDER_WORKSHOP_BAYS_DETAILS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CHECK_ORDER_WORKSHOP_BAYS_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        isOrderAvailable: action.payload.length !== 0 ? false : true,
      };
      break;
    case CHECK_ORDER_WORKSHOP_BAYS_DETAILS_FAIL:
      state = {
        ...state,
        loading: false,
        error: {
          message: action.payload,
        },
      };
      break;

    case EDIT_WORKSHOP_BAYS_DETAILS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case EDIT_WORKSHOP_BAYS_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        workshopBayDetails: action.payload.WorkshopBaysDetails[0],
        success: {
          message: action.message,
        },
      };
      break;
    case EDIT_WORKSHOP_BAYS_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      };
      break;

    case UPDATE_WORKSHOP_BAYS_DETAILS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_WORKSHOP_BAYS_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: {
          message: action.message,
        },
        workshopBayDetails: null,
        isAddOrUpdate: true,
      };
      break;
    case UPDATE_WORKSHOP_BAYS_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      };
      break;

    case DELETE_WORKSHOP_BAYS_DETAILS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_WORKSHOP_BAYS_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        statuses: action.payload,
        success: {
          message: action.message,
        },
      };
      break;
    case DELETE_WORKSHOP_BAYS_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default WorkshopBaysReducer;
