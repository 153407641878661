import * as ACTIONS from './actionType';

export const startPaymentReceipt = (payload) => {
    return {
        type: ACTIONS.START_PAYMENT_RECEIPT,
        payload
    }
}

export const startPaymentReceiptSuccess = (payload) => {
    return {
        type: ACTIONS.START_PAYMENT_RECEIPT_SUCCESS,
        payload
    }
}

export const startPaymentReceiptFail = (payload) => {
    return {
        type: ACTIONS.START_PAYMENT_RECEIPT_FAIL,
        payload
    }
}

export const clearPaymentReceipt = (payload) => {
    return {
        type: ACTIONS.START_PAYMENT_RECEIPT_FAIL,
        payload
    }
}

export const clearPaymentReceiptWithNavigate = (payload) => {
    return {
        type: ACTIONS.CLEAR_PAYMENT_RECEIPT,
        payload
    }
}





export const getReceiptDetail = (payload) => {
    return {
        type: ACTIONS.GET_RECEIPT_DETAILS,
        payload
    }
}

export const getReceiptDetailSuccess = (payload) => {
    return {
        type: ACTIONS.GET_RECEIPT_DETAILS_SUCCESS,
        payload
    }
}

export const getReceiptDetailFail = () => {
    return {
        type: ACTIONS.GET_RECEIPT_DETAILS_FAIL,
    }
}

export const clearReceiptDetail = () => {
    return {
        type: ACTIONS.CLEAR_RECEIPT_DETAILS,
    }
}