export function formatInvoiceList(list, status) {
    return list.map(c => {
        let fullname = "";
        if (c.job_schedule?.vehicle?.user?.customers_details[0]?.type == 1) {
            fullname = c.job_schedule?.vehicle?.user?.customers_details[0]?.company_name;
        }
        else {
            fullname = c.job_schedule?.vehicle?.user?.customers_details[0]?.firstname + " " + c.job_schedule?.vehicle?.user?.customers_details[0]?.lastname;
        }

        let invoice_type = "Progress Claim";
        if (c.tax_invoice?.toString() === "1") {
            invoice_type = "Full";
        }
        else if (c.tax_invoice?.toString() === "2") {
            invoice_type = "Deposit";
        }

        return {
            id: c.id,
            invoice_number: c.invoice_number,
            date: c.created_on,
            customer: fullname,
            job_no: c.job_schedule.job_number,
            job_total: c.job_schedule.total,
            invoice_type: invoice_type,
            invoice_balance: c.remain_balance,
            invoice_total: c.tax_invoice.toString() === "1" ? c.total : c.claim_amount,
            invoice_status: status,
            comments: c.comments,
            type:"normal",
            data: {
                ...c,
            }
        }
    })
}

export function formatSaleInvoiceList(list, status) {
    return list.map(c => {

        return {
            id: c.id,
            invoice_number: c.cash_sale_no,
            date: c.created_on,
            customer: c.user.customers_details[0].type === "1" ?
                c.user.customers_details[0].company_name :
                (c.user.customers_details[0].firstname
                    + " " +
                    c.user.customers_details[0].firstname),
            job_no: "Cash Sale",
            job_total: "0",
            invoice_type: "Full",
            invoice_balance: 0,
            invoice_total: c.total,
            invoice_status: status,
            comments: c.description,
            type:"sale",
            data: {
                ...c,
            }
        }
    })
}


