import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Calendar from './Calendar';
import { Scrollbars } from 'react-custom-scrollbars';
import schedularImage from '../../../assets/images/schedule-blue.svg';
import DetailsTable from './DetailsTable';
import { GET_WORKSHOP_TECHNICIAN } from "../../../store/commonApiStore/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { GET_UNASSIGNED_JOBS } from 'store/unassignedJobs/actionTypes';

const Scheduler = () => {
    const dispatch = useDispatch();
    const [UnassignedJobs, setUnassignedJobs] = useState([]);
    const [CarryOverJobs, setCarryOverJobs] = useState([]);
    const [jobCreated, setJobCreated] = useState(false)
    // const [title, setTitle] = useState("5iQ | Scheduler");
    const [clickDate, setClickDate] = useState(null);
    const [monthView, setMonthView] = useState("day");
    const [displayMonth, setDisplayMonth] = useState(true);
    const storedData = JSON.parse(sessionStorage.getItem('stored_data'));
    let workshop_id = window.atob(storedData.workshop_id);

    useEffect(() => {
        setDisplayMonth(false);
    }, [clickDate])

    useEffect(() => {
        setDisplayMonth(true);
    }, [monthView])

    const title = "5iQ | Scheduler";

    useEffect(() => {
        document.title = "5iQ | Scheduler";
    }, [title]);

    useEffect(() => {
        dispatch({
            type: GET_WORKSHOP_TECHNICIAN,
            payload: {
                get: "all",
                conditions: { "Workshops.id": workshop_id },
                contain: {
                    Users: {
                        conditions: {
                            "Users.workshop_id": workshop_id,
                            "Users.role_id IN": [3, 7, 8, 9],
                            "Users.is_deleted": 0
                        },
                        fields: ["id", "workshop_id", "firstname", "lastname"]
                    },
                    "Users.EmployeesDetails": {
                        fields: [
                            "EmployeesDetails.id",
                            "EmployeesDetails.user_id",
                            "EmployeesDetails.shift_working_day",
                            "EmployeesDetails.workshop_id"
                        ]
                    },
                    WorkshopOtherDetails: {
                        fields: [
                            "shift_start_time",
                            "shift_finish_time",
                            "shift_start_day",
                            "shift_finish_day",
                            "id",
                            "workshop_id",
                            "schedule_details",
                            "technician_timeslot"
                        ]
                    }
                },
                fields: ["id"]
            }
        })
    }, [dispatch, workshop_id])

    const handleUnassignedJobData = (flag) => {
        setJobCreated(flag);
    }
    useEffect(() => {
        const fetchData = async () => {
            dispatch({
                type: GET_UNASSIGNED_JOBS,
                payload: {
                    "get": "all",
                    "conditions": {
                        "JobSchedules.job_stage": "Unassigned",
                        "JobSchedules.job_status": "Unassigned",
                        "JobSchedules.user_id": "0",
                        "JobSchedules.workshop_id": workshop_id
                    },
                    "contain": {
                        "Vehicles.Users": {
                            "fields": [
                                "id",
                                "email"
                            ]
                        },
                        "Vehicles.Users.CustomersDetails": {
                            "conditions": {
                                "workshop_id": workshop_id
                            },
                            "fields": [
                                "CustomersDetails.id",
                                "CustomersDetails.user_id",
                                "CustomersDetails.firstname",
                                "CustomersDetails.lastname",
                                "CustomersDetails.mobile_number",
                                "CustomersDetails.customer_app",
                                "CustomersDetails.type",
                                "CustomersDetails.street_address",
                                "CustomersDetails.suburb",
                                "CustomersDetails.postcode",
                                "CustomersDetails.company_name"
                            ]
                        }
                    },
                    "fields": [
                        "JobSchedules.id",
                        "JobSchedules.workshop_id",
                        "JobSchedules.job_stage",
                        "JobSchedules.job_number",
                        "JobSchedules.job_status",
                        "JobSchedules.customer_id",
                        "JobSchedules.vehicle_id",
                        "Vehicles.id",
                        "Vehicles.user_id",
                        "Vehicles.rego",
                        "Vehicles.make",
                        "Vehicles.year",
                        "Vehicles.model",
                        "JobSchedules.job_department_id",
                        "JobSchedules.job_type_parent_id",
                        "JobSchedules.job_type",
                        "JobSchedules.is_copy_job"
                    ]
                }
            })
        }
        fetchData().catch(console.error)
    }, [jobCreated])

    // Get unassigned and carry over job listing
    const UnassignedJobsReducer = useSelector((state) => state.UnassignedJobsReducer);
    const TotalJobs = UnassignedJobsReducer.jobData;
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        let UnassignedJob = TotalJobs && TotalJobs.filter(value => {
            return value.is_copy_job === 0
        })
        let CarryOverJob = TotalJobs && TotalJobs.filter(value => {
            return value.is_copy_job === 1
        });
        setUnassignedJobs(UnassignedJob);
        setCarryOverJobs(CarryOverJob);
    }, [TotalJobs])
    return (
        <>
            <Container fluid className="p-r-0">
                <div className="right-section-wrapper schedular-header card m-b-10">
                    <div className="d-flex align-center">
                        <img className="image-block" src={schedularImage} alt=""></img>
                        <h5 className="schedular-title">Scheduler</h5>
                    </div>
                    <div className="video-link-outer">
                        <div className="video-tutorial">
                            <i className="fa fa-icon"></i>
                            <span className="help-text">Help</span>
                            <span className="tutorial-title">Watch Tutorial</span>
                        </div>
                    </div>
                </div>
                <div className="schedular-outer m-b-10">
                    <div className="right-section-wrapper card">
                        <Row >
                            <Col className="col-block" md={12} lg={12}>
                                <Calendar
                                    handleUnassignedJobData={handleUnassignedJobData}
                                    jobCreated={jobCreated}
                                    clickDate={setClickDate}
                                    monthView={setMonthView}
                                />
                            </Col>
                        </Row>
                    </div>
                    <div id="right-col" className="right-col">
                        <div id="unassigned-list" className="unassigned-list">
                            <div className="block-title d-flex align-center justify-center">
                                <span>Unassigned Job</span>
                                <span className="count d-flex align-center justify-center">{UnassignedJobs ? UnassignedJobs.length : 0}</span>
                            </div>
                            <div className="job-tiles-wrapper">
                                <Scrollbars
                                    className="custom-scrollbar" style={{ width: '100%', height: 350 }}
                                    renderThumbVertical={props => <div {...props} className="thumb-vertical black-thumb" />}
                                    renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} />}>
                                    {UnassignedJobs && UnassignedJobs.length > 0 ? UnassignedJobs.map((value, index) => (
                                        <div className="job-tiles" style={{ borderColor: '#ffd726' }} data={value.id} key={index} data-value={JSON.stringify(value)}>
                                            <p className="rego-id">Rego | #{value?.vehicle?.rego || ''}</p>
                                            <p>{value?.vehicle?.make || ''} {value?.vehicle?.model || ''} {value?.vehicle?.year || ''}</p>
                                            <p>{value?.vehicle?.user?.customers_details?.[0]?.firstname || ''} {value?.vehicle?.user?.customers_details?.[0]?.lastname || ''}-{value?.vehicle?.user?.customers_details?.[0]?.mobile_number || ''}</p>
                                        </div>
                                    )) : ""}
                                </Scrollbars>
                            </div>
                        </div>
                        <div id="carry-over-jobs" className="unassigned-list">
                            <div className="block-title black-bg d-flex align-center justify-center">
                                <span>Carry Over Job</span>
                                <span className="count d-flex align-center justify-center">{CarryOverJobs ? CarryOverJobs.length : 0}</span>
                            </div>
                            <div className="job-tiles-wrapper">
                                <Scrollbars
                                    className="custom-scrollbar" style={{ width: '100%', height: 350 }}
                                    renderThumbVertical={props => <div {...props} className="thumb-vertical black-thumb" />}
                                    renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} />}>
                                    {CarryOverJobs && CarryOverJobs.length > 0 ? CarryOverJobs.map((value, index) => (
                                        <div className="job-tiles" style={{ borderColor: '#ffd726' }} data={value.id} key={index} data-value={JSON.stringify(value)}>
                                            <p className="rego-id">Rego | #{value?.vehicle?.rego || ''}</p>
                                            <p>{value?.vehicle?.make || ''} {value?.vehicle?.model || ''} {value?.vehicle?.year || ''}</p>
                                            <p>{value?.vehicle?.user?.customers_details?.[0]?.firstname || ''} {value?.vehicle?.user?.customers_details?.[0]?.lastname || ''}-{value?.vehicle?.user?.customers_details?.[0]?.mobile_number || ''}</p>
                                        </div>
                                    )) : ""}
                                </Scrollbars>
                            </div>
                        </div>
                    </div>
                </div>
                {monthView === "month" && displayMonth === false ? <DetailsTable clickDate={clickDate} /> : ''}
            </Container>
        </>
    )
}

export default Scheduler;