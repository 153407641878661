import { takeLatest, put, call } from "redux-saga/effects";
import {
  GET_UPDATE_VEHICLE
} from "./actionTypes";
import {
  getUpdateVehicleSuccess,
  getUpdateVehicleFail
} from "./actions";

import {
  updateVehicle
} from "../../helpers/backendHelper";
import { showError, showSuccess } from "../../utils/alertMessage";


function* onupdateVehicle(data) {
  try {
    const response = yield call(updateVehicle, data);
    if (response) {
      if (response.code === 200) {
        yield put(getUpdateVehicleSuccess(response));
      } else {
        yield put(getUpdateVehicleFail(response));
      }
    } else {
      yield put(getUpdateVehicleFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getUpdateVehicleFail(error.response));
  }
}

function* VehicleSaga() {
  yield takeLatest(GET_UPDATE_VEHICLE, onupdateVehicle);
}

export default VehicleSaga;
