//Login
export const GET_LOGIN = 'users/commonlogin.json';

//Register
export const GET_REGISTER = 'users/signup.json';
export const SEND_OTP = 'UsersOtpDetails/add.json';
export const CONFIRM_OTP = 'UsersOtpDetails/verifyOtp.json';
export const EXIST_EMAIL = 'Users/checkEmail.json';
export const GET_COUNTRY = 'Countries/index.json';
export const GET_STATE = 'States/index.json';
export const GET_INDUSTRY = 'industries/index.json';
export const GET_ACCOUNTING_PACKAGE = 'AccountingPackages/index.json';
export const GET_SIZE_OF_WORKSHOP = 'IndustrySizes/index.json';
export const GET_REFERRAL_ADVERTISE = 'advertisements/index.json';

//Forgot Password
export const GET_FORGOT_PASSWORD = 'users/forgotPassword.json';

//Forgot Email
export const GET_FORGOT_EMAIL = 'users/forgotPassword.json';

//Job list
export const GET_JOBS = 'JobSchedules/index.json';

// delete job
export const DELETE_JOBS = 'JobSchedules/deleteMany.json';

//User Profile
export const GET_USER_PROFILE = 'users/index.json';
export const UPDATE_USER_PROFILE = 'users/edit.json';

//Customer listing
export const GET_CUSTOMER_LIST = 'CustomersDetails/index.json';

//Customer and vehicle details
export const GET_CUSTOMER_AND_VEHICLE_DETAILS = 'Vehicles/index/desktop.json';

//Job department list
export const GET_JOB_DEPARTMENT_LIST =
  'WorkshopDepartmentsAccountsDetails/index.json';

//Job type list
export const GET_JOB_TYPE_LIST = 'JobBookingTypes/index.json';

//Job canned list
export const GET_JOBCANNED_LIST = 'WorkshopScriptsDetails/index.json';

//Job sms template list
export const GET_JOBSMSTEMPLATE_LIST = 'CustomNotifications/index.json';

//Job email template list
export const GET_JOBEMAILTEMPLATE_LIST = 'CustomNotifications/index.json';

//Job email template save
export const Save_JOBEMAILTEMPLATE = 'JobSchedules/sendScriptOfJobs.json';

//Job sms template save
export const Save_JOBSMSTEMPLATE = 'JobSchedules/sendScriptOfJobs.json';

//Job Create
export const CREATE_JOB = 'JobSchedules/add.json';


//Marketing Create
export const CREATE_MARKETING = 'WorkshopMarketing/add.json';

//Notification
export const GET_NOTIFICATION = 'NotificationsData/index.json';

//Change Password
export const GET_CHANGE_PASSWORD = 'users/changePassword.json';

//Subscription other detail
export const GET_SUBSCRIPTION = 'WorkshopSubscription/index.json';

//Subscription other detail
export const GET_SUBSCRIPTION_ADDON = 'WorkshopSubscriptionDetails/index.json';

//Global Search
export const GET_GLOBAL_SEARCH = 'Users/globalSearch.json';

//Scheduler
export const GET_TECHNICIAN = 'users/index.json';
export const GET_WORKSHOP_BAYS = 'WorkshopBaysDetails/index.json';
export const GET_TECHNICIAN_HOUR = 'JobSchedules/getTechnicianHours.json';
export const GET_INSURANCE = 'InsuranceCompany/index.json';
export const GET_JOB_TAGS = 'Tags/index.json';
export const ADD_JOB_TAGS = 'Tags/addMany.json';
export const PRINT_JOB_CARD_PDF = 'JobSchedules/jobCardPdf.json';
export const CHECK_AVAILABLE_HOURS = 'JobSchedules/checkHoursCalculation.json';
export const GET_WORKSHOP_TECHNICIAN = 'workshops/index.json';
export const GET_AVAIALABLE_SLOTS =
  'JobSchedules/technicianAvailableSlots.json';
export const GET_JOBS_KIT_PARTS = 'JobKitParts/index.json';
export const GET_JOBS_KIT_LABOURS = 'JobKitLabours/index.json';
export const ADD_MANY_JOB_PARTS = 'JobsParts/addMany.json';
export const ADD_MANY_JOB_LABOURS = 'JobLabours/AddMany.json';
export const ADD_MANY_JOB_KIT_PARTS = 'JobKitParts/AddMany.json';
export const ADD_MANY_JOB_KIT_LABOURS = 'JobKitLabours/AddMany.json';
export const GET_ACTIVITY = 'ActivityMaster/index.json';
export const GET_SCHEDULE_ACTIVITY = 'ActivitySchedule/index.json';
export const ADD_ACTIVITY = 'ActivityMaster/add.json';
export const ADD_SCHEDULE_ACTIVITY = 'ActivitySchedule/add.json';
export const ADD_MANY_CREATE_JOB = 'JobSchedules/addMany.json';
export const DELETE_JOB_ACTIVITY = 'JobSchedules/delete.json';
export const DELETE_JOB_SCHEDULE_ACTIVITY = 'ActivitySchedule/delete.json';

/* Customer  */
export const ADD_CUSTOMER = 'Users/add.json';

/* Quotes */
export const GET_QUOTES = 'CustomerQuotes/index.json';
export const EDIT_QUOTES = 'CustomerQuotes/edit.json';
export const ADD_QUOTES = 'CustomerQuotes/add.json';
export const UPDATE_QUOTES = 'CustomerQuotes/add.json';

/* Workshop & Workshop Other Detail */
export const GET_WORKSHOP_OTHER_DETAILS_VIEW = 'WorkshopOtherDetails/view.json';
export const GET_WORKSHOP_OTHER_DETAILS = 'WorkshopOtherDetails/index.json';
export const UPDATE_WORKSHOP_OTHER_DETAILS = 'WorkshopOtherDetails/edit.json';

/*Workshop subscription detail*/
export const GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST =
  'WorkshopSubscriptionDetails/index.json';

/* Parts */
// get list or search parts / products
export const GET_PART_LIST = 'Products/index.json';

// save parts / products
export const SAVE_PART = 'Products/add.json';

// delete parts / products
export const DELETE_PART = 'Products/delete.json';

//get job part
export const GET_JOB_PART_LIST = 'JobsParts/index.json';

//save job parts
export const SAVE_JOB_PART = 'JobsParts/add.json';

//delete job parts
export const DELETE_JOB_PART = 'JobsParts/delete.json';

//update job parts
export const UPDATE_JOB_PART = 'JobsParts/add.json';

//save job parts Many
export const EDIT_JOB_PART_MANY = 'JobsParts/editMany.json';

//save job kit parts Many
export const EDIT_JOB_KIT_PART_MANY = 'JobKitParts/editMany.json';

//save job kit labour Many
export const EDIT_JOB_KIT_LABOUR_MANY = 'JobKitLabours/editMany.json';

// get job kit part
export const GET_JOB_KIT_PART = 'JobKitParts/index.json';

// get job kit part
export const GET_JOB_KIT_LABOUR = 'JobKitLabours/index.json';

// save job kit part
export const SAVE_JOB_KIT_PART = 'JobKitParts/add.json';

// save job kit part
export const DELETE_JOB_KIT_PART = 'JobKitParts/delete.json';

/************************
 ░░░░ Job Labour START ░░░░
 *************************/

//GET LABOUR CHARGE LIST
export const GET_LABOUR_CHARGES_LIST = 'LabourCharges/index.json';

//get job Labour
export const GET_JOB_LABOUR_LIST = 'JobLabours/index.json';

//save job Labour
export const SAVE_JOB_LABOUR = 'JobLabours/add.json';

//delete job Labour
export const DELETE_LABOUR_PART = 'JobLabours/delete.json';

//update job Labour
export const UPDATE_LABOUR_PART = 'JobLabours/edit.json';

//edit job Labour many
export const EDIT_JOB_LABOUR_MANY = 'JobLabours/editMany.json';
//save job Labour
export const SAVE_QUOTE_LABOUR = 'CustomerQuotesLabours/add.json';
//get job Labour
export const GET_QUOTE_LABOUR_LIST = 'CustomerQuotesLabours/index.json';
//get job Labour
export const DELETE_QUOTE_LABOUR_PART = 'CustomerQuotesLabours/delete.json';
//get job Labour
export const UPDATE_QUOTE_LABOUR_PART = 'CustomerQuotesLabours/edit.json';
//get job Labour
export const EDIT_QUOTE_LABOUR_MANY = 'CustomerQuotesLabours/editMany.json';

/************************
 ░░░░ Job Labour END ░░░░
 *************************/

/************************
 ░░░░ Job detail START ░░░░
 *************************/

// get job booked details
export const GET_JOB_BOOKED_DETAILS = 'JobSchedules/index.json';

// get job notes
export const GET_JOB_NOTES = 'JobNotes/index.json';

// add job notes
export const ADD_JOB_NOTES = 'JobNotes/add.json';

// update job notes
export const UPDATE_JOB_NOTES = 'JobNotes/add.json';

/************************
 ░░░░ Workshop START ░░░░
 *************************/

//GET workshop markup LIST
export const GET_WORKSHOP_MARKUP_DETAILS = 'WorkshopMarkupDetails/index.json';

// GET workshop markup list
export const GET_WORKSHOP_MARKUP_LIST = 'WorkshopMarkupDetails/view.json';

// DELETE workshop markup list
export const DELETE_WORKSHOP_MARKUP = 'WorkshopMarkupDetails/delete.json';

// SAVE workshop markup
export const SAVE_WORKSHOP_MARKUP_DETAILS = 'WorkshopMarkupDetails/add.json';

// UPDATE workshop markup
export const UPDATE_WORKSHOP_MARKUP_DETAILS = 'WorkshopMarkupDetails/edit.json';

// UPDATE default workshop markup
export const UPDATE_DEFAULT_WORKSHOP_MARKUP_DETAILS =
  'WorkshopMarkupDetails/updateMany.json';

/************************
 ░░░░ Labour Charge START ░░░░
 *************************/

//get Labour charge
export const GET_LABOUR_CHARGE = 'LabourCharges/index.json';

//save Labour charge
export const SAVE_LABOUR_CHARGE = 'LabourCharges/add.json';

//delete Labour charge
export const DELETE_LABOUR_CHARGE = 'LabourCharges/delete.json';

//update Labour charge
export const UPDATE_LABOUR_CHARGE = 'LabourCharges/edit.json';

// UPDATE default labour charges
export const UPDATE_DEFAULT_LABOUR_CHARGE = 'LabourCharges/updateMany.json';

/************************
 ░░░░ Labour Charge END ░░░░
 *************************/

/************************
 ░░░░ Custom Vehicle START ░░░░
 *************************/

//get Labour charge
export const GET_CUSTOM_VEHICLE = 'VehiclesCustomFields/index.json';

//save Labour charge
export const SAVE_CUSTOM_VEHICLE = 'VehiclesCustomFields/add.json';

//delete Labour charge
export const DELETE_CUSTOM_VEHICLE = 'VehiclesCustomFields/delete.json';

//update Labour charge
export const UPDATE_CUSTOM_VEHICLE = 'VehiclesCustomFields/edit.json';

/************************
 ░░░░ Custom Vehicle END ░░░░
 *************************/

//GET workshop Tax / Rates LIST
export const GET_WORKSHOP_TAX_DETAILS = 'WorkshopTaxRatesDetails/index.json';

/************************
 ░░░░ Supplier START ░░░░
 *************************/

// get list of supplier
export const GET_SUPPLIER_LIST = 'Suppliers/index.json';

// save Supplier
export const ADD_SUPPLIER = 'Suppliers/add.json';

/************************
 ░░░░ Supplier END ░░░░
 *************************/

/* list of all vehicle */
export const GET_VEHICLES = 'AssetsMaster/index.json';

/* list of selected vehicle model  */
export const GET_VEHICLESMODEL = 'AssetsMaster/index.json';

/* list of selected vehicle series  */
export const GET_VEHICLESERIES = 'AssetsDetails/index.json';

/* list of selected vehicle engine type,type and transmission for marine  */
export const GET_ASSETS = 'Assets/index.json';

/* list of custom fields  */
export const GET_CUSTOM_FIELDS = 'VehiclesCustomFields/index.json';

/* Add assets details  */
export const ADD_ASSETS_DETAILS = 'AssetsDetails/add.json';

/* Add assets master  */
export const ADD_ASSETS_MASTER = 'AssetsMaster/add.json';

/* Add assets details (Vehicle,Marine,Truck,etc...)  */
export const ADD_ASSETS = 'Vehicles/add.json';

/* save user request  */
export const SAVE_USER_REQUEST = 'users/add.json';

/* get status color */
export const GET_STATUS_COLOR = 'Statuses/index.json';

/*rego lookup details*/
// export const GET_REGO_LOOKUP_DETAILS =
//   "https://test.autoinfo.com.au/api/autoinfogateway.asmx/VehicleByLicencePlateD";

export const GET_REGO_LOOKUP_DETAILS = 'Vehicles/getRegoLookupDetails.json';

/*update job timer details*/
export const UPDATE_JOB_TIMER = 'JobSchedules/edit.json';

/*update vehicle details*/
export const UPDATE_VEHICLE = 'Vehicles/edit.json';

/* Settings Company Detail */
export const GET_TIMEZONE = 'Timezones/index.json';
export const GET_WORK_SHOP_DETAIL = 'Workshops/getdata.json';
export const GET_WORK_SHOP_OTHER_DETAIL = 'WorkshopOtherDetails/view.json';
export const UPDATE_COMPANY_DETAIL = 'Workshops/edit.json';
export const UPDATE_COMPANY_OTHER_DETAIL = 'WorkshopOtherDetails/edit.json';
export const GET_WORK_SHOP_SMS = 'WorkshopSms/index.json';
export const UPDATE_WORKSHOP_SMS = 'WorkshopSms/add.json';

/****************************
 ░░░░░░░░ JOB DETAILS ░░░░░░░░
 ****************************/
export const GET_INSPECTIONS = 'InspInspections/index.json';
export const GET_INSPECTION_TEMPLATES = 'InspTemplates/index.json';
/* get inspections view */
export const GET_INSPECTION_VIEW = 'InspInspections/view.json';
//Update Inspection
export const UPDATE_INPECTION = 'InspInspections/edit';

/* get internal  notes */
export const GET_INTERNAL_NOTES = 'InternalNotes/index.json';

/* add internal  notes */
export const ADD_INTERNAL_NOTES = 'InternalNotes/add.json';

/* delete internal  notes */
export const DELETE_INTERNAL_NOTES = 'InternalNotes/delete.json';

/* save internal notes attachments */
export const SAVE_INTERNAL_NOTES_ATTACHMENTS =
  'InternalNotesAttachments/addMany.json';
/* search JobSchedules */
export const JOBSCHEDULESSEARCH = 'JobSchedules/searchJobs.json';

/****************************
 ░░░░░░░░ SETTINGS: SECURITY GROUP ░░░░░░░░
 ****************************/
// GET secuity groups
export const GETSECURITYGROUP = 'SecurityGroupsDetails/getData.json';
// ADD security group
export const ADDSECURITYGROUP = 'SecurityGroupsDetails/add.json';
// UPDATE security group
export const UPDATESECURITYGROUP = 'SecurityGroupsDetails/edit.json';

/* get Quote internal  notes */
export const GET_QUOTE_INTERNAL_NOTES = 'QuoteInternalNotes/index.json';

/* add Quote internal  notes */
export const ADD_QUOTE_INTERNAL_NOTES = 'QuoteInternalNotes/add.json';

/* delete Quote internal  notes */
export const DELETE_QUOTE_INTERNAL_NOTES = 'QuoteInternalNotes/delete.json';

/* save Quote internal notes attachments */
export const SAVE_QUOTE_INTERNAL_NOTES_ATTACHMENTS =
  'QuoteInternalNotesAttachment/addMany.json';

/* search Quotes */
export const QUOTE_SEARCH = 'CustomerQuotes/searchQuotes.json';

// get list of quote parts
export const GET_QUOTE_PART_LIST = 'CustomerQuotesParts/index.json';

// save quote parts
export const SAVE_QUOTE_PART = 'CustomerQuotesParts/add.json';

// update quote parts
export const UPDATE_QUOTE_PART = 'CustomerQuotesParts/edit.json';

//  delete quote parts
export const DELETE_QUOTE_PART = 'CustomerQuotesParts/delete.json';

// edit quote many parts
export const EDIT_QUOTE_PART_MANY = 'CustomerQuotesParts/editMany.json';

// get quote kit part
export const GET_QUOTE_KIT_PART = 'QuoteKitParts/index.json';

// add quote kit part
export const SAVE_QUOTE_KIT_PART = 'QuoteKitParts/add.json';

// delete quote kit part
export const DELETE_QUOTE_KIT_PART = 'QuoteKitParts/delete.json';

// get quote kit labour
export const GET_QUOTE_KIT_LABOUR = 'QuoteKitLabours/index.json';

//save quote kit parts Many
export const EDIT_QUOTE_KIT_PART_MANY = 'QuoteKitParts/editMany.json';

//save quote kit labour Many
export const EDIT_QUOTE_KIT_LABOUR_MANY = 'QuoteKitLabours/editMany.json';

/* COPY QUOTES */
export const COPYQUOTES = 'CustomerQuotes/copyQuotes.json';

/* delete QUOTES */
export const DELETEQUOTES = 'CustomerQuotes/deleteMany.json';

/* send QUOTES mail */
export const SENDQUOTEMAIL = 'CustomerQuotes/sendQuotesMail.json';

/* apply referal code */
export const APPLY_CODE = 'JobSchedules/verifyMarketingCodes.json';

/** GET INVOICES */
export const GET_INVOICES_LIST = 'Invoices/index.json';
export const GET_INVOICES_PAYMENT = 'InvoicesPaymentsCredits/index.json';
export const GET_INVOICE_CREDIT =
  'InvoicesPaymentsCreditsOtherDetails/index.json';
export const GET_INVOICE_CASH_SALE_LIST = 'CashSaleInvoice/index.json';

/**DELETE/ARCHIVE INVOICES */
export const DELETE_INVOICES = 'Invoices/deleteMany.json';
export const DELETE_INVOICES_SALES = 'CashSaleInvoice/deleteMany.json';

/** GET MARKETING LIST */
export const GET_MARKETINGS_LIST = 'WorkshopMarketing/index.json';
export const GET_LIVE_MARKETINGS_LIST = 'WorkshopMarketing/index.json';
export const GET_COMPLETED_MARKETINGS_LIST = 'WorkshopMarketing/index.json';

/**DELETE/ARCHIVE MARKETINGS */
// export const DELETE_MARKETINGS = 'Invoices/deleteMany.json';


/* Inspection Approve */
export const APPROVE_MANUAL_INSPECTION = 'InspInspections/InspEdit.json';
export const ADD_APPROVED_PARTS_INSPECTION =
  'InspInspections/addInspectionParts.json';

/* Inspection Resend */
export const RESEND_INSPECTION = 'InspInspections/resendInspPDF.json';

/* Get inspection;s groups */
export const INSPECTION_GROUP = 'InspGroups/index.json';

export const ADD_INSPECTION = 'InspInspections/add.json';
//Update Invoice
export const UPDATE_INVOICE = 'Invoices/edit.json';
//Add Invoice
export const ADD_INVOICE = 'Invoices/add.json';

export const EDIT_CASH_SALE_INVOICE = 'CashSaleInvoice/edit.json';

export const CASH_SALE_PAYMENTS_CREDITS_SAVE =
  'CashSalePaymentsCredits/add.json';
export const INVOICES_PAYMENTS_CREDITS_SAVE =
  'InvoicesPaymentsCredits/add.json';

//Payment credi
export const GET_CASH_SALE_PAYMENTS_CREDITS =
  'CashSalePaymentsCredits/index.json';
export const SEND_INVOICE_MAIL = 'Invoices/sendInvoiceMail.json';

export const ADD_INVOICE_CREDIT =
  'InvoicesPaymentsCreditsOtherDetails/add.json';
/****************************
 ░░░░░░░░ SETTINGS: STATUSES ░░░░░░░░
 ****************************/
export const GET_STATUSES_LIST = 'Statuses/index.json';
export const ADD_STATUS = 'Statuses/add.json';
export const UPDATE_STATUS = 'Statuses/edit.json';
export const DELETE_STATUS = 'Statuses/delete.json';

/* add job reminder */
export const ADD_JOB_REMINDER = 'JobsReminder/add.json';

/* update job reminder */
export const UPDATE_JOB_REMINDER = 'JobAttachments/edit.json';

/* DELETE job reminder */
export const DELETE_JOB_REMINDER = 'JobAttachments/delete.json';

/* add attachment */
export const ADD_ATTACHMENT = 'JobAttachments/add.json';

/* GET attachment */
export const GET_ATTACHMENT = 'JobAttachments/index.json';

// KIT PART
export const GET_KIT_PART_LIST = 'KitPartsDetails/index.json';

/****************************
 ░░░░░░░░ WORKSHOP BAYS ░░░░░░░░
 ****************************/
export const GET_WORKSHOP_BAYS_LIST = 'WorkshopBaysDetails/index.json';
export const ADD_WORKSHOP_BAY = 'WorkshopBaysDetails/add.json';
export const VIEW_WORKSHOP_BAY = 'WorkshopBaysDetails/view.json';
export const UPDATE_WORKSHOP_BAY = 'WorkshopBaysDetails/edit.json';
export const DELETE_WORKSHOP_BAY = 'WorkshopBaysDetails/delete.json';

export const DELETE_INVOICE_PAYMENT = 'InvoicesPaymentsCredits/deleteMany.json';
export const DELETE_INVOICE_CREDIT =
  'InvoicesPaymentsCreditsOtherDetails/deleteMany.json';

/* GET Subscription Plans Data */
export const GET_SUBSCRIPTION_PLANS = 'SubscriptionPlan/index.json';

/* GET Subscription Plans Data */
export const GET_SUPPLIER_INTEGRATION = 'SupplierIntegration/index.json';
