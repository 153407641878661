import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { SAVE_JOBSMSTEMPLATE } from "../../store/commonApiStore/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

const SendSmsModal = ({
  SMSModalShow,
  handleSMSModalClose,
  smsTemplateData,
  getPhoneForPhoneModal,
  shareAdditionalDetailsSms,
  send_page,
  setIsLoading,
}) => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const getResponseFromApi = useSelector(
    (state) => state.CommonApiStoreReducer
  );
  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  let workshop_id = window.atob(storedData.workshop_id);
  const [smsTemplateValidated, setSmsTemplateValidated] = useState(false);
  let phone = getPhoneForPhoneModal ? getPhoneForPhoneModal.phone : "";
  let jobsmsData = smsTemplateData.jobSmsData ? smsTemplateData.jobSmsData : [];
  const [smsValue, setSmsValue] = useState({
    template: "",
    send_to: phone ? phone : "",
    message_body: "",
  });
  useEffect(() => {
    setSmsValue({ ...smsValue, send_to: phone });
  }, [phone]);
  const getSmsTemplate = (e) => {
    let index = e.target.selectedIndex;
    let optionElement = e.target.childNodes[index];
    let smsTemplateID = e.target.value;
    let message_body = optionElement.getAttribute("data-val")
      ? optionElement.getAttribute("data-val")
      : "";
    setSmsValue({
      template: smsTemplateID ? smsTemplateID : "",
      send_to: smsValue.send_to ? smsValue.send_to : "",
      message_body: message_body ? message_body : "",
    });
  };
  const handleSmsChange = (e) => {
    setSmsValue({ ...smsValue, [e.target.name]: e.target.value });
  };
  function handleSubmitSmsTemplateModalForm(e) {
    e.preventDefault();
    setSmsTemplateValidated(true);
    if (
      smsValue.template === "" ||
      smsValue.send_to === "" ||
      smsValue.message_body === ""
    ) {
      console.log("stop");
      return false;
    }
    setIsLoading(true);
    let smsFormData = {};
    smsFormData.workshop_id = workshop_id;
    smsFormData.job_id = shareAdditionalDetailsSms.job_id;
    smsFormData.template_id = smsValue.template;
    smsFormData.send_method = shareAdditionalDetailsSms.send_method;
    smsFormData.table = shareAdditionalDetailsSms.table;
    if (shareAdditionalDetailsSms.sendType == "") {
      if (shareAdditionalDetailsSms.job_id.length == 1) {
        smsFormData.send_to = smsValue.send_to;
        smsFormData.message = smsValue.message_body;
      }
    } else {
      smsFormData.send_type = shareAdditionalDetailsSms.sendType;
    }
    dispatch({
      type: SAVE_JOBSMSTEMPLATE,
      payload: smsFormData,
    });
  }
  useEffect(() => {
    let saveDataResponse = getResponseFromApi.jobSmsSaveData
      ? getResponseFromApi.jobSmsSaveData
      : "";
    if (saveDataResponse !== "") {
      setIsLoading(false);
      if (send_page == "job_details") {
        handleSMSModalClose();
        navigate(
          `/job_detail/${
            shareAdditionalDetailsSms.job_id
              ? shareAdditionalDetailsSms.job_id[0]
              : ""
          }`
        );
      } else if (send_page == "job_list") {
        handleSMSModalClose();
      } else if (send_page == "quote") {
        navigate("/");
      } else if (send_page == "quote_list") {
        navigate("/");
      } else if (send_page == "lead") {
        navigate("/");
      } else if (send_page == "quote") {
        navigate("/");
      } else {
        navigate("/");
      }
    }
  }, [getResponseFromApi]);
  return (
    <Modal
      className="preview-dialog"
      size="lg"
      show={SMSModalShow}
      onHide={handleSMSModalClose}
    >
      <Modal.Header>
        <Modal.Title className="d-flex justify-space-between">
          <span>Send sms</span>
          <Button
            type="button"
            className="close-btn"
            onClick={handleSMSModalClose}
          >
            <i className="la la-times"></i>{" "}
          </Button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={smsTemplateValidated} id="smsTemplateForm">
          <div className="column-wrapper d-flex">
            <div className="left-column">
              <div className="custom-normal-select mb-3">
                <Form.Label>Template *</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  required
                  value={smsValue.template ? smsValue.template : ""}
                  name="template"
                  id="template"
                  onChange={getSmsTemplate}
                >
                  <option key={0} value="" data-val="">
                    Select Template
                  </option>
                  {jobsmsData.map((val) => {
                    return (
                      <option
                        key={val.id}
                        value={val.id}
                        data-val={val.email_txt ? val.email_txt : ""}
                      >
                        {val.name}
                      </option>
                    );
                  })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please Select Template
                </Form.Control.Feedback>
              </div>
              <Form.Group className="form-group mb-3">
                <Form.Label>Mobile NO *</Form.Label>
                <Form.Control
                  type="text"
                  name="send_to"
                  id="phone_no"
                  value={smsValue.send_to}
                  onChange={handleSmsChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Phone No
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-group mb-3">
                <Form.Label>Message *</Form.Label>
                <Form.Control
                  as="textarea"
                  value={smsValue.message_body ? smsValue.message_body : ""}
                  name="message_body"
                  id="text_message_body"
                  onChange={handleSmsChange}
                  rows={8}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Message
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="right-column">
              <span className="panel-heading">PREVIEW</span>
              <div className="preview-outer">
                <div className="preview-wrapper">
                  <span className="panel-heading">MESSAGES</span>
                  <div className="panel-body">
                    <div className="msg-content">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: smsValue.message_body
                            ? smsValue.message_body
                            : "",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          className="btn-outline rounded-btn"
          onClick={handleSMSModalClose}
        >
          Cancel
        </Button>
        <Button
          className="rounded-btn"
          variant="primary"
          type="button"
          onClick={handleSubmitSmsTemplateModalForm}
        >
          Send
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default SendSmsModal;
