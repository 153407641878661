import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import moment from "moment";
import { GET_WORKSHOP_SMS, UPDATE_WORKSHOP_SMS } from "../../../../../store/companyDetails/actionTypes";
import SmsIcon from "../../../../../assets/images/Sms-Vector-SVG-Icon-10-SVG-Repo.png"

const CompanyCommunicationSMS = ({ workShopDetails, workShopSMS }) => {
  const dispatch = useDispatch(); // Dispatch variable

  const [reminderMsgData, setReminderMsgData] = useState("");
  const [thankyouMsgData, setThankyouMsgData] = useState("");
  const [notificationMsgData, setNotificationMsgData] = useState("");

  const formik = useFormik({
    initialValues: {
      reminderMsgId: reminderMsgData ? reminderMsgData.id : "",
      thankyouMsgId: thankyouMsgData ? thankyouMsgData.id : "",
      reminderMsg: reminderMsgData ? reminderMsgData.message : "",
      thankyouMsg: thankyouMsgData ? thankyouMsgData.message : "",
      reminderActive: reminderMsgData && reminderMsgData.is_active === "1" ? "1" : "0",
      thankyouActive: thankyouMsgData && thankyouMsgData.is_active === "1" ? "1" : "0"
    },
    enableReinitialize: true,
    onSubmit: values => {
      const reminderPayload = values.reminderMsgId === "" ? {
          workshop_id: JSON.parse(localStorage.getItem("data")).workshop_id,
          message: values.reminderMsg ? values.reminderMsg : `Hi just reminder that your car is booked tomorrow, if you cant make it please call us on ${workShopDetails.phone}(NO-REPLY)`,
          is_activate: values.reminderActive,
          activated_by: values.reminderActive === "1" ? JSON.parse(localStorage.getItem("data")).id : "",
          activated_date: values.reminderActive === "1" ? moment().format("YYYY-MM-DD") : "",
          deactivated_by: values.reminderActive === "0" ? JSON.parse(localStorage.getItem("data")).id : "",
          deactivated_date: values.reminderActive === "0" ? moment().format("YYYY-MM-DD") : "",
          type: "reminder", 
          title: "Service Booking Reminder"
      } : {
        workshop_id: JSON.parse(localStorage.getItem("data")).workshop_id,
        message: values.reminderMsg ? values.reminderMsg : `Hi just reminder that your car is booked tomorrow, if you cant make it please call us on ${workShopDetails.phone}(NO-REPLY)`,
        is_activate: values.reminderActive,
        activated_by: values.reminderActive === "1" ? JSON.parse(localStorage.getItem("data")).id : "",
        activated_date: values.reminderActive === "1" ? moment().format("YYYY-MM-DD") : "",
        deactivated_by: values.reminderActive === "0" ? JSON.parse(localStorage.getItem("data")).id : "",
        deactivated_date: values.reminderActive === "0" ? moment().format("YYYY-MM-DD") : "",
        id: values.reminderMsgId
      };

      const thankyouPayload = values.reminderMsgId === "" ? {
          workshop_id: JSON.parse(localStorage.getItem("data")).workshop_id,
          message: values.thankyouMsg ? values.thankyouMsg : `Thank you for choosing ${workShopDetails.name} to maintain your vehicle and we look forward to seeing you soon. If you need anything call us ${workShopDetails.phone}(NO-REPLY)`,
          is_activate: values.thankyouActive,
          activated_by: values.thankyouActive === "1" ? JSON.parse(localStorage.getItem("data")).id : "",
          activated_date: values.thankyouActive === "1" ? moment().format("YYYY-MM-DD") : "",
          deactivated_by: values.thankyouActive === "0" ? JSON.parse(localStorage.getItem("data")).id : "",
          deactivated_date: values.thankyouActive === "0" ? moment().format("YYYY-MM-DD") : "",
          type: "thankyou", 
          title: "Service | Repair Thank You"
      } : {
        workshop_id: JSON.parse(localStorage.getItem("data")).workshop_id,
        message: values.thankyouMsg ? values.thankyouMsg : `Thank you for choosing ${workShopDetails.name} to maintain your vehicle and we look forward to seeing you soon. If you need anything call us ${workShopDetails.phone}(NO-REPLY)`,
        is_activate: values.thankyouActive,
        activated_by: values.thankyouActive === "1" ? JSON.parse(localStorage.getItem("data")).id : "",
        activated_date: values.thankyouActive === "1" ? moment().format("YYYY-MM-DD") : "",
        deactivated_by: values.thankyouActive === "0" ? JSON.parse(localStorage.getItem("data")).id : "",
        deactivated_date: values.thankyouActive === "0" ? moment().format("YYYY-MM-DD") : "",
        id: values.thankyouMsgId
      };

      // Update reminder 
      dispatch({
        type: UPDATE_WORKSHOP_SMS,
        payload: reminderPayload
      });

      // Update thankyou
      dispatch({
        type: UPDATE_WORKSHOP_SMS,
        payload: thankyouPayload
      });
    }
  });
  
  useEffect(() => {
    dispatch({
      type: GET_WORKSHOP_SMS,
      payload: {
        "get": "all",
        "contain": ["ActivatedUsers", "DeactivatedUsers"],
        "conditions": {
          "WorkshopSms.workshop_id": JSON.parse(localStorage.getItem("data")).workshop_id
        }
      }
    });
  }, []);

  useEffect(() => {
    if (workShopSMS) {
      workShopSMS.forEach(value => {
        if (value.type === "reminder") {
          setReminderMsgData(value);
        }
        else if (value.type === "thankyou") {
          setThankyouMsgData(value);
        }
        else if (value.type === "notification") {
          setNotificationMsgData(value);
        }
      });
    }
  }, [workShopSMS]);

  return (
    <div className="card mb-3">
      <div className="card-header d-flex flex-wrap align-items-center">
        <div className="text-uppercase me-2">
          <h5 className="font-size-18">SMS</h5>
        </div>
        <div className="text-uppercase font-size-13">THESE SMS ARE AUTOMATED SO ONCE ACTIVATED THEY WILL BE SENT BASED ON THE TIMING INDICATED FOR EACH.</div>
      </div>
      <div className="card-body">
        <form onSubmit={formik.handleSubmit}>
          <div className="border mb-3">
            <div className="card-header d-flex flex-wrap align-items-center">
              <div className="img flex-shrink-0 me-2">
                <img src={SmsIcon} width="40" height="40" className="img-circle" alt="" />
              </div>
              <div className="text-uppercase font-size-13">SERVICE BOOKING REMINDER</div>
            </div>
            
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">Message *</label>
                    <textarea
                      className="form-control"
                      cols={30}
                      value={formik.values.reminderMsg}
                      onChange={e => formik.setFieldValue("reminderMsg", e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">Preview</label>
                    <div className="chat-bubble bubble-primary">
                      {formik.values.reminderMsg}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 pt-3">
                  <div className="form-check">
                    <label className="form-check-label">
                      <input 
                        className="form-check-input" 
                        type="checkbox"
                        defaultChecked={formik.values.reminderActive === "1"}
                        onChange={e => formik.setFieldValue("reminderActive", e.target.checked ? "1" : "0")}
                      />
                      Activated
                    </label>
                  </div>
                </div>
                <div className="col-md-8 pt-3">
                  <p className="mb-0">A text message will be sent out for each booked job 1 day prior to the schedule date</p>
                </div>
                <div className="col-md-4 pt-3">
                  <label htmlFor="">Activated By : </label> 
                  <span> {reminderMsgData && reminderMsgData.activated_user && reminderMsgData.activated_user.firstname + " " + reminderMsgData.activated_user.lastname } </span>
                </div>
                <div className="col-md-4 pt-3">
                  <label htmlFor="">Activated Date : </label> 
                  <span> {reminderMsgData && reminderMsgData.activated_date && moment(reminderMsgData.activated_date).format("DD/MM/YYYY") } </span>
                </div>
                <div className="col-md-4 pt-3">
                  <div className="font-size-12">Note : Text mgs are charged at std rate</div>
                </div>
                <div className="col-md-4 pt-3">
                  <label htmlFor="">De-activated By : </label> 
                  <span> {reminderMsgData && reminderMsgData.deactivated_user && reminderMsgData.deactivated_user.firstname + " " + reminderMsgData.deactivated_user.lastname } </span>
                </div>
                <div className="col-md-4 pt-3">
                  <label htmlFor="">De-activated Date : </label> 
                  <span> {reminderMsgData && reminderMsgData.deactivated_date && moment(reminderMsgData.deactivated_date).format("DD/MM/YYYY") } </span>
                </div>
              </div>
            </div>
          </div>
          <div className="border mb-3">
            <div className="card-header d-flex flex-wrap align-items-center">
              <div className="img flex-shrink-0 me-2">
                <img src={SmsIcon} width="40" height="40" className="img-circle" alt="" />
              </div>
              <div className="text-uppercase font-size-13">Thank you</div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">Message *</label>
                    <textarea
                      className="form-control"
                      cols={30}
                      value={formik.values.thankyouMsg}
                      onChange={e => formik.setFieldValue("thankyouMsg", e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">Preview</label>
                    <div className="chat-bubble bubble-primary">
                      {formik.values.thankyouMsg}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 pt-3">
                  <div className="form-check">
                    <label className="form-check-label">
                      <input 
                        className="form-check-input" 
                        type="checkbox"
                        defaultChecked={formik.values.thankyouActive === "1"}
                        onChange={e => formik.setFieldValue("thankyouActive", e.target.checked ? "1" : "0")}
                      />
                      Activated
                    </label>
                  </div>
                </div>
                <div className="col-md-8 pt-3">
                  <p className="mb-0">A text message will be sent out for each booked job 1 day prior to the schedule date</p>
                </div>
                <div className="col-md-4 pt-3">
                  <label htmlFor="">Activated By : </label> 
                  <span> {thankyouMsgData && thankyouMsgData.activated_user && thankyouMsgData.activated_user.firstname + " " + thankyouMsgData.activated_user.lastname } </span>
                </div>
                <div className="col-md-4 pt-3">
                  <label htmlFor="">Activated Date : </label> 
                  <span> {thankyouMsgData && thankyouMsgData.activated_date && moment(thankyouMsgData.activated_date).format("DD/MM/YYYY") } </span>
                </div>
                <div className="col-md-4 pt-3">
                  <div className="font-size-12">Note : Text mgs are charged at std rate</div>
                </div>
                <div className="col-md-4 pt-3">
                  <label htmlFor="">De-activated By : </label> 
                  <span> {thankyouMsgData && thankyouMsgData.deactivated_user && thankyouMsgData.deactivated_user.firstname + " " + thankyouMsgData.deactivated_user.lastname } </span>
                </div>
                <div className="col-md-4 pt-3">
                  <label htmlFor="">De-activated Date : </label> 
                  <span> {thankyouMsgData && thankyouMsgData.deactivated_date && moment(thankyouMsgData.deactivated_date).format("DD/MM/YYYY") } </span>
                </div>
              </div>
            </div>
          </div>
          <div className="cta">
            <button type="submit" className="btn btn-success btn-rounded px-3">Save</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CompanyCommunicationSMS;