import {
  GET_GLOBAL_SEARCH,
  GET_GLOBAL_SEARCH_SUCCESS,
  GET_GLOBAL_SEARCH_FAIL,
} from "./actionTypes";

export const getGlobalSearch = () => {
  return {
    type: GET_GLOBAL_SEARCH,
  };
};

export const getGlobalSearchSuccess = (data) => {
  return {
    type: GET_GLOBAL_SEARCH_SUCCESS,
    payload: data.data.Users,
  };
};

export const getGlobalSearchFail = (error) => {
  return {
    type: GET_GLOBAL_SEARCH_FAIL,
    payload: error,
  };
};
