import { takeLatest, put, call } from "redux-saga/effects";

import {
  ADD_SECURITY_GROUP_DETAILS,
  GET_SECURITY_GROUP_DETAILS,
  UPDATE_SECURITY_GROUP_DETAILS,
} from "./actionTypes";

import {
  getSecurityGroupDetailSuccess,
  getSecurityGroupDetailFail,
  updateSecurityGroupDetailSuccess,
  updateSecurityGroupDetailFail,
} from "./actions";

import {
  addSecruityGroup,
  getSecruityGroup,
  updateSecruityGroup,
} from "../../helpers/backendHelper";

import { showError, showSuccess } from "../../utils/alertMessage";

// Get security group details
function* onGetSecurityGroupDetails(data) {
  try {
    const response = yield call(getSecruityGroup, data);
    if (response) {
      if (response.code === 200) {
        yield put(getSecurityGroupDetailSuccess(response));
      } else {
        yield put(getSecurityGroupDetailFail(response.message));
      }
    } else {
      yield put(getSecurityGroupDetailFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getSecurityGroupDetailFail(error.response));
  }
}

// ADD security group details

function* onAddSecurityGroupDetails(data) {
  try {
    const response = yield call(addSecruityGroup, data);
    if (response) {
      if (response.code === 200) {
        yield put(updateSecurityGroupDetailSuccess(response));
        showSuccess("Company details updated successfully.");
      } else {
        yield put(updateSecurityGroupDetailFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(updateSecurityGroupDetailFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(updateSecurityGroupDetailFail(error.response));
  }
}

// UPDATE security group details

function* onUpdateSecurityGroupDetails(data) {
  try {
    const response = yield call(updateSecruityGroup, data);
    if (response) {
      if (response.code === 200) {
        yield put(updateSecurityGroupDetailSuccess(response));
        showSuccess("Company details updated successfully.");
      } else {
        yield put(updateSecurityGroupDetailFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(updateSecurityGroupDetailFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(updateSecurityGroupDetailFail(error.response));
  }
}

function* SecurityGroupSaga() {
  yield takeLatest(GET_SECURITY_GROUP_DETAILS, onGetSecurityGroupDetails);
  yield takeLatest(ADD_SECURITY_GROUP_DETAILS, onAddSecurityGroupDetails);

  yield takeLatest(UPDATE_SECURITY_GROUP_DETAILS, onUpdateSecurityGroupDetails);
}

export default SecurityGroupSaga;
