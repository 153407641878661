import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { Scrollbars } from "react-custom-scrollbars";
import Button from "react-bootstrap/Button";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import { useNavigate, Link } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import "react-datepicker/dist/react-datepicker.css";
import { EditorState, Modifier, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
// import draftToHtml from 'draftjs-to-html';
import { DatePicker, TimePicker } from "antd";
import { timeZoneToUTC, convertToMinute } from "../../../utils/timeZoneConvert";
import CustomerAndVehicle from "../../modals/customerAndVehicle";
import { showSuccess, showError } from "../../../utils/alertMessage";
import moment from "moment";
import IcCustomerBlue from "../../../assets/images/ic_customer_blue.svg";
import { useDispatch, useSelector } from "react-redux";
import MonthViewCreateJob from "./MonthViewCreateJob";
import { utcToTimezone } from "../../../utils/timeZoneConvert";
import DeleteConfirm from "../../../utils/deleteConfirm";
import {
  GET_INSURANCE,
  GET_JOB_TAGS,
  ADD_JOB_TAGS,
  CREATE_QUOTE_TO_JOB,
  GET_AVAIALABLE_SLOTS,
  GET_ACTIVITY,
  ADD_ACTIVITY,
  GET_SCHEDULE_ACTIVITY,
  ADD_SCHEDULE_ACTIVITY,
  ADD_MANY_CREATE_JOB,
  DELETE_JOB_SCHEDULE_ACTIVITY,
  GET_QUOTE_DETAILS,
  ADD_MANY_JOB_PARTS,
  ADD_MANY_JOB_LABOURS,
  GET_QUOTE_KIT_PARTS,
  GET_QUOTE_KIT_LABOURS,
  ADD_MANY_JOB_KIT_PARTS,
  ADD_MANY_JOB_KIT_LABOURS,
} from "../../../store/scheduler/actionTypes";
import {
  GET_CUSTOMERS_LIST,
  GET_CUSTOMERANDVEHICLE_DETAILS,
  CREATE_JOB,
} from "../../../store/unassignedJobs/actionTypes";
import { getCustomerAndVehicleDetailsSuccess } from "store/unassignedJobs/actions";
import {
  GET_JOBDEPARTMENT_LIST,
  GET_JOBTYPE_LIST,
  GET_JOBCANNED_LIST,
} from "../../../store/commonApiStore/actionTypes";
import {
  GET_OPEN_QUOTES,
  EDIT_QUOTES,
} from "../../../store/openQuotes/actionTypes";
import { SAVE_JOB_LABOUR } from "../../../store/jobLabour/actionTypes";
import { unserializeArr } from "../../../utils/unserialize";
import CarMakeLogo from "views/common/UI/CarMakeLogo";

let CreateJob = ({
  setUpdateCalendar,
  close,
  technicianType,
  technicianId,
  startTimeCalendar,
  endTimeCalendar,
  technicianName,
  show,
  slotDuration,
  showMonthViewPopup,
}) => {
  const navigate = useNavigate(); // Navigate URL
  const dispatch = useDispatch(); // Dispatch variable
  const [isLoading, setIsLoading] = useState(true);
  const [customerShow, setCustomerShow] = useState(false);
  const [activeStep, setActiveStep] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [manageStateModal, setManageStateModal] = useState("parent");
  const [jobFormValue, setJobFormValue] = useState([]);
  const [createJobFlag, setCreateJobFlag] = useState(false);
  const [createQuoteFlag, setCreateQuoteFlag] = useState(false);
  const [technicianNameNew, setTechnicianName] = useState("");
  const [updateActivityList, setUpdateActivityList] = useState(false);
  useEffect(() => {
    setJobFormValue({ ...jobFormValue, source_of_business: "Existing" });
  }, []);

  const setFormValue = (e) => {
    setJobFormValue({ ...jobFormValue, [e.target.name]: e.target.value });
  };

  const ChangeActiveStep = () => {
    setActiveStep(!activeStep);
  };

  const onEditorStateChange = (editorState) => {
    // let draftToHtmlData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setEditorState(editorState);
  };

  // Create Customer and Vehicle
  const [customerFormType, setCustomerFormType] = useState();
  const [customerCreatedData, setCustomerDetails] = useState();
  const [vehicleCreatedData, setVehicleDetails] = useState();

  useEffect(() => {
    if (
      manageStateModal === "parent" &&
      (customerCreatedData !== undefined || vehicleCreatedData !== undefined) &&
      (customerCreatedData !== null || vehicleCreatedData !== null)
    ) {
      var firstname =
        customerCreatedData?.customers_details?.[0]?.firstname || "";
      var lastname =
        customerCreatedData?.customers_details?.[0]?.lastname || "";

      setCustomerName(firstname + " " + lastname);
      setRego(vehicleCreatedData?.rego || "");
      setMobileNumber(
        customerCreatedData?.customers_details?.[0]?.mobile_number || ""
      );
      setEmail(customerCreatedData?.email || "");
      setMake(vehicleCreatedData?.make || "");
      setModal(vehicleCreatedData?.model || "");
      setYear(vehicleCreatedData?.year || "");
      // setInspectionRoType(customerVehicleData?.[0]?.insp_inspections || null);
      setActiveSearchList(false);
      setVechicleSection("");
      setCustomerVehicleFlag("");

      // let vinDataUser = {};
      // vinDataUser['user_id'] = customerCreatedData?.id || '';
      // vinDataUser['cust_id'] = customerVehicleData?.[0]?.id || '';
      // vinDataUser['firstname'] = firstname;
      // vinDataUser['lastname'] = lastname;
      // vinDataUser['company_name'] = customerCreatedData?.customers_details?.[0]?.company_name || '';
      // vinDataUser['type'] = customerCreatedData?.customers_details?.[0]?.type || '';
      // vinDataUser['mobile'] = customerCreatedData?.customers_details?.[0]?.mobile_number || '';
      // vinDataUser['phone'] = customerCreatedData?.customers_details?.[0]?.phone_number || '';
      // vinDataUser['rego'] = customerVehicleData?.[0].rego || '';
      // vinDataUser['make'] = customerVehicleData?.[0].make || '';
      // vinDataUser['model'] = customerVehicleData?.[0].model || '';
      // vinDataUser['suburb'] = customerCreatedData?.customers_details?.[0]?.suburb || '';
      // vinDataUser['street_address'] = customerCreatedData?.customers_details?.[0]?.street_address || '';
      // vinDataUser['postcode'] = customerCreatedData?.customers_details?.[0]?.postcode || '';
      // vinDataUser['vehicle_type'] = customerVehicleData?.[0]?.vehicle_type || '';
      // vinDataUser['email'] = customerCreatedData?.email || '';
      // vinDataUser['is_vehicle_available'] = 1;
      // vinDataUser['state'] = customerCreatedData?.state?.name || "";
      // vinDataUser['state_id'] = customerCreatedData?.state?.id || "";
      // vinDataUser['country'] = customerCreatedData?.country?.country_name || "";
      // vinDataUser['country_id'] = customerCreatedData?.country?.id || "";

      // setSelectedUser(vinDataUser);
      setJobFormValue({
        ...jobFormValue,
        rego: vehicleCreatedData?.rego || "",
        vehicle_id: vehicleCreatedData?.id || "",
        customer_id: customerCreatedData?.id || "",
        veh_type: customerVehicleData?.[0]?.type || "",
        veh_type_name: vehicleCreatedData?.vehicle_type || "",
        cust_type: customerCreatedData?.customers_details?.[0]?.type || "",
      });
    }
  }, [customerCreatedData, vehicleCreatedData]);

  const [selectedUser, setSelectedUser] = useState({});
  const [showCustomersAndVehiclesModal, setShowCustomersAndVehiclesModal] =
    useState(false);

  const handleCustomerShow = () => {
    setCustomerFormType("add");
    setSelectedUser({});
    showCustomersAndVehiclesModal === true
      ? setShowCustomersAndVehiclesModal(false)
      : setShowCustomersAndVehiclesModal(true);
    setManageStateModal("child");
    setCustomerShow(true);
  };

  const viewCustomerVehicle = () => {
    setCustomerFormType("view");
    setShowCustomersAndVehiclesModal(true);
    setManageStateModal("child");
  };

  // for dynamic value of job popup
  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  let workshop_id = window.atob(storedData.workshop_id);
  let user_id = window.atob(storedData.user_id);
  let date_format = storedData.date_format;
  let timezoneLable = window.atob(storedData.timezone_lable);
  let startJobTime = moment(startTimeCalendar).format("DD/MM/YYYY hh:mm a");
  let endJobTime = moment(endTimeCalendar).format("DD/MM/YYYY hh:mm a");
  let startEventTime = moment(startTimeCalendar).format("DD/MM/YYYY");
  let startTimeNew = moment(startTimeCalendar).format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [durationJob, setDurationJob] = useState();
  const [job_user_id, setUserId] = useState();
  const [job_bay_id, setBayId] = useState();
  const [validated, setValidated] = useState(false);
  const [activityValidated, setActivityValidated] = useState(false);
  const [createEvent, setCreateEvent] = useState("Jobs");
  const [createdJobId, setCreatedJobId] = useState();
  const formRef = useRef(null);
  let vin_data = [];
  let filterVinData = [];

  // Reducer (data store)
  const customerVehicleData = useSelector(
    (state) => state.UnassignedJobsReducer.customerAndVehicleData
  );
  const getCustomerData = useSelector(
    (state) => state.UnassignedJobsReducer.customerData
  );
  // for quote to job
  // const quoteToJobResp = useSelector((state) => state.UnassignedJobsReducer.createJob);
  const quoteToJobResp = null;
  const insuranceData = useSelector(
    (state) => state.SchedulerReducer.insuranceData
  );
  const jobDepartmentData = useSelector(
    (state) => state.CommonApiStoreReducer.jobDepartmentData
  );
  const jobTypeData = useSelector(
    (state) => state.CommonApiStoreReducer.jobTypeData
  );
  const cannedResponse = useSelector(
    (state) => state.CommonApiStoreReducer.cannedData
  );
  const labourChargesList = useSelector(
    (state) => state.CommonApiStoreReducer.labourChargesList
  );
  const tagsData = useSelector((state) => state.SchedulerReducer.tagsData);
  // for quote details
  const quoteDetailsData = useSelector(
    (state) => state.SchedulerReducer.createQuoteDetails
  );

  const quoteKitParts = useSelector(
    (state) => state.SchedulerReducer.getQuoteKitParts
  );
  const quoteKitLabours = useSelector(
    (state) => state.SchedulerReducer.getQuoteKitLabours
  );

  const quotesData = useSelector(
    (state) => state.OpenQuotesReducer.openQuotesData
  );
  const quotesDetailsData = useSelector(
    (state) => state.SchedulerReducer.quoteDetails
  );

  const saveQuoteDetails = useSelector(
    (state) => state.WonQuotesReducer.wonQuotesData
  );
  // console.log(saveQuoteDetails,'saveQuoteDetails');
  const updateQuotesData = useSelector(
    (state) => state.OpenQuotesReducer.editQuotesData
  );
  const addTagsData = useSelector(
    (state) => state.SchedulerReducer.addtagsData
  );
  const createJobData = useSelector(
    (state) => state.UnassignedJobsReducer.createJob
  );
  // For activity
  const activityData = useSelector(
    (state) => state.SchedulerReducer.activityData
  );
  const activityScheduleData = useSelector(
    (state) => state.SchedulerReducer.activityScheduleData
  );
  const addActivityData = useSelector(
    (state) => state.SchedulerReducer.addActivityData
  );
  const addActivityScheduleData = useSelector(
    (state) => state.SchedulerReducer.addActivityScheduleData
  );
  const addCreateActivityJob = useSelector(
    (state) => state.SchedulerReducer.createJobActivity
  );
  const deleteJobActivity = useSelector(
    (state) => state.SchedulerReducer.deleteJobScheduleActivity
  );

  // console.log(deleteJobActivity, 'deleteJobActivity');
  // console.log(addActivityData,'addActivityData');

  useEffect(() => {
    if (createJobFlag === true) {
      if (createJobData !== null && createJobData !== undefined) {
        if (createJobData.JobSchedules !== null) {
          var id = createJobData?.JobSchedules?.id || null;
          if (labourChargesList !== null && labourChargesList !== undefined) {
            let objToSend = {
              labour_charges_id: labourChargesList[0].id,
              job_schedules_id: createJobData?.JobSchedules?.id || null,
              cost_price: labourChargesList[0].cost_price,
              margin: labourChargesList[0].margin,
              sell_price: labourChargesList[0].sell_price,
              retail_price: labourChargesList[0].retail_price,
              profit: labourChargesList[0].profit_price,
              labour_description: labourChargesList[0].description,
              total_price: 0,
            };
            dispatch({
              type: SAVE_JOB_LABOUR,
              payload: objToSend,
            });
          }

          setCreatedJobId(id);
          showSuccess("Job Created Successfully!");
          setCreateJobFlag(false);
          setManageStateModal("save");
          handleShow();
        }
      }
    }
  }, [createJobData, createJobFlag]);

  //Customer & vehicle section
  const [vehicleSection, setVechicleSection] = useState("hide");
  const [filterVinDatas, setFilterVinDatas] = useState([]);
  const [vinData, setVinData] = useState([]);
  const [activeSearchList, setActiveSearchList] = useState(false);
  const [rego, setRego] = useState();
  const [customerName, setCustomerName] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [email, setEmail] = useState();
  const [make, setMake] = useState();
  const [modal, setModal] = useState();
  const [year, setYear] = useState();

  const [inspectionRoType, setInspectionRoType] = useState(null);
  const [customerVehicleFlag, setCustomerVehicleFlag] = useState("disabled");

  //Job type filter data
  const [filterJobType, setFilterJobType] = useState([]);

  //job canned response filter data
  const [filterCannedResponse, setCannedResponse] = useState([]);

  //job canned response filter data
  const [jobTagsData, setJobTagsData] = useState([]);
  const [getJobTagsData, setGetJobTagsData] = useState([]);
  const [tagsArrId, setTagsArrId] = useState([]);

  //Quote listing
  const [quoteList, setQuoteList] = useState([]);
  const [makeQuote, setMakeQuote] = useState("MAKE");
  const [modelQuote, setModelQuote] = useState("MODEL");
  const [yearQuote, setYearQuote] = useState("YEAR");
  const [jobDeptQuote, setJobDeptQuote] = useState("DEPARTMENT");
  const [jobTypeQuote, setJobTypeQuote] = useState("TYPE");
  const [descQuote, setDescQuote] = useState("DESCRIPTION");
  const [showJob, setShow] = useState(false);

  const clearModalData = () => {
    setCustomerFormType("");
    setCustomerDetails(null);
    setVehicleDetails(null);
    setCustomerVehicleFlag("disabled");
    dispatch(getCustomerAndVehicleDetailsSuccess(null));
  };

  const handleClose = () => {
    clearModalData();
    setManageStateModal("parent");
    close();
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const handleTagsChange = (skills) => {
    setGetJobTagsData(skills || []);
  };

  const handleSchedule = () => {
    clearModalData();
    setUpdateCalendar(true);
    close();
    setShow(false);
  };

  const handleViewJob = () => {
    clearModalData();
    close();
    setShow(false);
    if (createdJobId !== null && createdJobId !== undefined) {
      navigate("/job_detail/" + createdJobId);
    }
  };

  const handleDashboard = () => {
    clearModalData();
    close();
    navigate("/home");
    setShow(false);
  };

  useEffect(() => {
    var fromData = timeZoneToUTC(
      new Date(startTimeCalendar),
      timezoneLable,
      "",
      "YYYY-MM-DD HH:mm:ss"
    );
    var startDateJob = moment(new Date(startTimeCalendar)).format("YYYY-MM-DD");
    var startTimeJob = moment(fromData).format("HH:mm:ss");
    var durationMinutes = convertToMinute(slotDuration);
    var endDateJob = moment(new Date(startTimeCalendar)).format("YYYY-MM-DD");
    var endTimeJob = moment(startTimeJob, "HH:mm:ss")
      .add(durationMinutes, "minutes")
      .format("HH:mm:ss");

    setStartDate(startDateJob);
    setEndDate(endDateJob);
    setStartTime(startTimeJob);
    setEndTime(endTimeJob);
    setDurationJob(durationMinutes);

    if (technicianType === "bay") {
      setUserId(0);
      setBayId(technicianId);
    } else {
      setUserId(technicianId);
      setBayId(0);
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    dispatch({
      type: GET_CUSTOMERS_LIST,
      payload: {
        get: "all",
        fields: [
          "CustomersDetails.id",
          "CustomersDetails.user_id",
          "CustomersDetails.type",
          "CustomersDetails.firstname",
          "CustomersDetails.lastname",
          "CustomersDetails.company_name",
          "CustomersDetails.mobile_number",
          "CustomersDetails.phone_number",
          "CustomersDetails.customer_app",
          "CustomersDetails.postcode",
          "CustomersDetails.suburb",
          "CustomersDetails.street_address",
          "CustomersDetails.abn",
          "CustomersDetails.position",
          "CustomersDetails.website",
        ],
        order: {
          "CustomersDetails.firstname": "asc",
        },
        conditions: {
          "CustomersDetails.workshop_id": workshop_id,
          role_id: 4,
          "CustomersDetails.is_deleted": 0,
          "CustomersDetails.status": 1,
        },
        contain: {
          Users: {
            fields: ["id", "workshop_id", "email"],
          },
          "Users.States": {
            fields: ["id", "name"],
          },
          "Users.Countries": {
            fields: ["id", "country_name"],
          },
          "Users.Vehicles": {
            fields: ["Vehicles.user_id", "Vehicles.rego"],
            conditions: {
              "Vehicles.workshop_id": workshop_id,
              "Vehicles.is_deleted": 0,
            },
          },
        },
      },
    });
  }, []);

  useEffect(() => {
    var fullname = "";
    var icon = false;
    var img = "";
    let address = "";
    let vehiclesData = "";
    let vinDataArr = {};
    let vehicleDataArr = {};
    if (getCustomerData !== null && getCustomerData !== undefined) {
      getCustomerData.map((customer, index) => {
        if (customer.type === "1") {
          fullname = customer.company_name;
        } else {
          fullname = customer.firstname + " " + customer.lastname;
        }
        icon = false;
        if (customer.customer_app === 1) {
          icon = true;
        }
        address = customer.street_address + ",";
        address += customer.suburb + ",";
        // eslint-disable-next-line no-useless-concat
        address += customer?.user?.state?.name || "" + ",";
        // eslint-disable-next-line no-useless-concat
        address += customer?.user?.country?.country_name || "" + ",";
        address += customer.postcode;
        vehiclesData = customer?.user?.vehicles || null;
        vinDataArr = {};
        if (vehiclesData !== null && vehiclesData.length !== 0) {
          vehiclesData.map((data, custIndex) => {
            vehicleDataArr = {};
            vehicleDataArr["user_id"] = customer.user_id;
            vehicleDataArr["cust_id"] = customer.id;
            vehicleDataArr["name"] = fullname;
            vehicleDataArr["type"] = customer.type;
            vehicleDataArr["firstname"] = customer.firstname;
            vehicleDataArr["lastname"] = customer.lastname;
            vehicleDataArr["companyname"] = customer.company_name;
            vehicleDataArr["abn"] = customer.abn;
            vehicleDataArr["website"] = customer.website;
            vehicleDataArr["position"] = customer.position;
            vehicleDataArr["mobile"] = customer.mobile_number;
            vehicleDataArr["phone"] = customer.phone_number;
            vehicleDataArr["rego"] = data.rego.toUpperCase();
            vehicleDataArr["icon"] = icon;
            vehicleDataArr["address"] = address;
            vehicleDataArr["email"] = customer.user.email;
            vehicleDataArr["is_vehicle_available"] = 1;
            vehicleDataArr["suburb"] = customer.suburb;
            vehicleDataArr["postcode"] = customer.postcode;
            vehicleDataArr["state"] = customer?.user?.state?.name || "";
            vehicleDataArr["state_id"] = customer?.user?.state?.id || "";
            vehicleDataArr["country"] =
              customer?.user?.country?.country_name || "";
            vehicleDataArr["country_id"] = customer?.user?.country?.id || "";
            vin_data.push(vehicleDataArr);
          });
        } else {
          vinDataArr["user_id"] = customer.user_id;
          vinDataArr["cust_id"] = customer.id;
          vinDataArr["name"] = fullname;
          vinDataArr["firstname"] = customer.firstname;
          vinDataArr["lastname"] = customer.lastname;
          vinDataArr["companyname"] = customer.company_name;
          vinDataArr["mobile"] = customer.mobile_number;
          vinDataArr["phone"] = customer.phone_number;
          vinDataArr["rego"] = "";
          vinDataArr["icon"] = icon;
          vinDataArr["address"] = address;
          vinDataArr["email"] = customer?.user?.email || "";
          vinDataArr["is_vehicle_available"] = 0;
          vinDataArr["suburb"] = customer.suburb;
          vinDataArr["postcode"] = customer.postcode;
          vinDataArr["state"] = customer?.user?.state?.name || "";
          vinDataArr["state_id"] = customer?.user?.state?.id || "";
          vinDataArr["country"] = customer?.user?.country?.country_name || "";
          vinDataArr["country_id"] = customer?.user?.country?.id || "";
          vinDataArr["type"] = customer.type;
          vinDataArr["abn"] = customer.abn;
          vinDataArr["website"] = customer.website;
          vinDataArr["position"] = customer.position;
          vin_data.push(vinDataArr);
        }
      });
    }
    setVinData(vin_data);
    setIsLoading(false);
  }, [getCustomerData]);

  const getChildSearchVehicle = (e) => {
    let value = e.target.value.toLowerCase();
    if (value !== "") {
      searchVehicle(value);
      setActiveSearchList(true);
    } else {
      setActiveSearchList(false);
    }
  };

  const searchVehicle = (value) => {
    filterVinData = vinData.filter(function (item) {
      let vinData1 = (
        item.name +
        item.mobile +
        item.phone +
        item.rego
      ).toLowerCase();
      let vinData = (
        item.name +
        item.mobile +
        item.phone +
        item.rego +
        item.icon
      ).toLowerCase();
      if (vinData1.indexOf(value) !== -1) {
        return vinData;
      }
    });
    setFilterVinDatas(filterVinData);
  };

  const getVehicleDetails = (id) => {
    setIsLoading(true);
    dispatch({
      type: GET_CUSTOMERANDVEHICLE_DETAILS,
      payload: {
        get: "all",
        conditions: {
          "Vehicles.workshop_id": workshop_id,
          "Vehicles.status": 1,
          "Vehicles.rego": id,
        },
        contain: {
          Users: {
            fields: ["id", "workshop_id", "email"],
          },
          "Users.CustomersDetails": {
            conditions: {
              workshop_id: workshop_id,
            },
            fields: [
              "id",
              "user_id",
              "workshop_id",
              "mobile_number",
              "firstname",
              "lastname",
              "type",
              "company_name",
              "customer_app",
              "street_address",
              "suburb",
              "postcode",
              "phone_number",
              "abn",
              "position",
              "website",
            ],
          },
          "Users.States": {
            fields: ["id", "name"],
          },
          "Users.Countries": {
            fields: ["id", "country_name"],
          },
          InspInspections: {
            conditions: {
              "InspInspections.Insp_status": "Approved",
              "InspInspections.insp_work_type": "Need to book a job",
            },
          },
          0: "InspInspections.InspTemplates",
        },
        fields: [
          "Vehicles.id",
          "Vehicles.user_id",
          "Vehicles.workshop_id",
          "Vehicles.rego",
          "Vehicles.make",
          "Vehicles.model",
          "Vehicles.year",
          "Vehicles.type",
          "Vehicles.vehicle_type",
          "Vehicles.is_verified",
          "Vehicles.odometer_reading",
        ],
      },
    });
    setJobFormValue({ ...jobFormValue, inspection_name: "" });
  };

  useEffect(() => {
    if (customerVehicleData !== null && customerVehicleData.length !== 0) {
      console.log(customerVehicleData, "customerVehicleData");

      var firstname =
        customerVehicleData?.[0]?.user?.customers_details?.[0]?.firstname || "";
      var lastname =
        customerVehicleData?.[0]?.user?.customers_details?.[0]?.lastname || "";
      // setCustomerAndVehicleData(customerVehicleData)
      setCustomerName(firstname + " " + lastname);
      setRego(customerVehicleData?.[0].rego || "");
      setMobileNumber(
        customerVehicleData?.[0]?.user?.customers_details?.[0]?.mobile_number ||
          ""
      );
      setEmail(customerVehicleData?.[0]?.user?.email || "");
      setMake(customerVehicleData?.[0]?.make || "");
      setModal(customerVehicleData?.[0]?.model || "");
      setYear(customerVehicleData?.[0]?.year || "");
      setInspectionRoType(customerVehicleData?.[0]?.insp_inspections || null);
      setActiveSearchList(false);
      setVechicleSection("");
      setCustomerVehicleFlag("");

      let vinDataUser = {};
      vinDataUser["user_id"] = customerVehicleData?.[0]?.user_id || "";
      vinDataUser["cust_id"] = customerVehicleData?.[0]?.id || "";
      vinDataUser["firstname"] = firstname;
      vinDataUser["lastname"] = lastname;
      vinDataUser["company_name"] =
        customerVehicleData?.[0]?.user?.customers_details?.[0]?.company_name ||
        "";
      vinDataUser["type"] =
        customerVehicleData?.[0]?.user?.customers_details?.[0]?.type || "";
      vinDataUser["mobile"] =
        customerVehicleData?.[0]?.user?.customers_details?.[0]?.mobile_number ||
        "";
      vinDataUser["phone"] =
        customerVehicleData?.[0]?.user?.customers_details?.[0]?.phone_number ||
        "";
      vinDataUser["rego"] = customerVehicleData?.[0].rego || "";
      vinDataUser["make"] = customerVehicleData?.[0].make || "";
      vinDataUser["model"] = customerVehicleData?.[0].model || "";
      vinDataUser["suburb"] =
        customerVehicleData?.[0]?.user?.customers_details?.[0]?.suburb || "";
      vinDataUser["street_address"] =
        customerVehicleData?.[0]?.user?.customers_details?.[0]
          ?.street_address || "";
      vinDataUser["postcode"] =
        customerVehicleData?.[0]?.user?.customers_details?.[0]?.postcode || "";
      vinDataUser["vehicle_type"] =
        customerVehicleData?.[0]?.vehicle_type || "";
      vinDataUser["email"] = customerVehicleData?.[0]?.user?.email || "";
      vinDataUser["is_vehicle_available"] = 1;
      vinDataUser["state"] = customerVehicleData?.[0]?.user?.state?.name || "";
      vinDataUser["state_id"] = customerVehicleData?.[0]?.user?.state?.id || "";
      vinDataUser["country"] =
        customerVehicleData?.[0]?.user?.country?.country_name || "";
      vinDataUser["country_id"] =
        customerVehicleData?.[0]?.user?.country?.id || "";

      setSelectedUser(vinDataUser);
      setJobFormValue({
        ...jobFormValue,
        rego: customerVehicleData?.[0].rego || "",
        vehicle_id: customerVehicleData?.[0].id || "",
        customer_id: customerVehicleData?.[0]?.user_id || "",
        veh_type: customerVehicleData?.[0]?.type || "",
        veh_type_name: customerVehicleData?.[0]?.vehicle_type || "",
        cust_type:
          customerVehicleData?.[0]?.user?.customers_details?.[0]?.type || "",
      });
    }
  }, [customerVehicleData]);

  // Get insurance details
  useEffect(() => {
    const fetchData = async () => {
      dispatch({
        type: GET_INSURANCE,
        payload: {
          get: "all",
          conditions: {
            workshop_id: workshop_id,
          },
          fields: ["id", "company_name", "contact_name"],
        },
      });
    };
    fetchData().catch(console.error);
  }, []);

  //Get job department
  useEffect(() => {
    const fetchData = async () => {
      dispatch({
        type: GET_JOBDEPARTMENT_LIST,
        payload: {
          get: "all",
          conditions: {
            workshop_id: workshop_id,
          },
          fields: ["id", "job_type_parent_id", "name", "is_custom"],
        },
      });
    };
    fetchData().catch(console.error);
  }, []);

  //Get job type
  useEffect(() => {
    const fetchData = async () => {
      dispatch({
        type: GET_JOBTYPE_LIST,
        payload: {
          get: "all",
          conditions: {
            status: 1,
            "parent_id !=": 0,
          },
          fields: ["id", "parent_id", "name"],
        },
      });
    };
    fetchData().catch(console.error);
  }, []);

  // Get job type filter based on job dept
  const getJobType = (elem) => {
    setFormValue(elem);
    var index = elem.target.selectedIndex;
    var optionElement = elem.target.childNodes[index];
    var jobTypeParentId = optionElement.getAttribute("data-id");
    if (jobTypeData !== null && jobTypeData !== undefined) {
      var filterJobTypeData = jobTypeData.filter(
        (item) => JSON.stringify(item.parent_id) === jobTypeParentId
      );
      setFilterJobType(filterJobTypeData);
    }

    if (cannedResponse !== null && cannedResponse !== undefined) {
      var filterCannedResponseData = cannedResponse.filter(
        (item) => JSON.stringify(item.department_id) === elem.target.value
      );
      setCannedResponse(filterCannedResponseData);
    }
  };

  // Get job canned response
  useEffect(() => {
    const fetchData = async () => {
      dispatch({
        type: GET_JOBCANNED_LIST,
        payload: {
          get: "all",
          conditions: {
            workshop_id: workshop_id,
            status: 1,
            "type IN": [2, 8],
          },
          fields: [
            "id",
            "department_id",
            "workshop_id",
            "name",
            "description",
            "type",
          ],
        },
      });
    };
    fetchData().catch(console.error);
  }, []);

  const jobCannedResp = [];
  if (filterCannedResponse.length !== 0 && filterCannedResponse !== null) {
    filterCannedResponse.map((canned) =>
      jobCannedResp.push({
        value: canned.id,
        label: canned.name,
        description: canned.description,
      })
    );
  }

  // Get description based on selected script
  const handleCannedScript = (e) => {
    setJobFormValue({
      ...jobFormValue,
      script_name_id: e.value,
      script_description: e.description,
    });
    var descriptionVal = e.description;
    let { contentBlocks, entityMap } = htmlToDraft(descriptionVal);
    let contentState = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap()
    );
    onEditorStateChange(
      EditorState.push(editorState, contentState, "insert-characters")
    );
  };

  // Get Job tags
  useEffect(() => {
    const fetchData = async () => {
      dispatch({
        type: GET_JOB_TAGS,
        payload: {
          get: "all",
          conditions: {
            workshop_id: workshop_id,
          },
          fields: ["id", "name", "type"],
        },
      });
    };
    fetchData().catch(console.error);
  }, []);

  let jobTagsArr = [];
  useEffect(() => {
    if (tagsData !== null && tagsData !== undefined) {
      tagsData.map((tags) => {
        jobTagsArr.push({
          value: tags.id,
          label: tags.name,
        });
      });
      setJobTagsData(jobTagsArr);
    }
  }, [tagsData]);

  useEffect(() => {
    let newTagsArr = tagsArrId;
    if (addTagsData !== null && addTagsData !== undefined) {
      addTagsData.map((data, index) => {
        newTagsArr.push(data.id);
      });
      setTagsArrId(newTagsArr);
    }
  }, [addTagsData]);

  function handleActivitySubmitForm(e) {
    const form = e.currentTarget;
    if (form.checkValidity() !== false) {
      console.log("test");
    } else {
      console.log("not");
    }
    e.preventDefault();
    setActivityValidated(true);
  }

  function handleSubmitForm(e) {
    let tagsIdArrPush = [];
    let addTagsIdArr = [];
    if (getJobTagsData.length !== 0) {
      getJobTagsData.map((tags, index) => {
        if (tags.__isNew__ === true) {
          let tagsRequest = {};
          tagsRequest["name"] = tags.value;
          tagsRequest["workshop_id"] = workshop_id;
          tagsRequest["type"] = "aircon";
          addTagsIdArr.push(tagsRequest);
        } else {
          tagsIdArrPush.push(tags.value);
        }
      });
    }
    setTagsArrId(tagsIdArrPush);
    if (addTagsIdArr.length !== 0) {
      dispatch({
        type: ADD_JOB_TAGS,
        payload: addTagsIdArr,
      });
    }

    e.preventDefault();

    const form = e.currentTarget;
    var jobEstimateTime = form.job_estimate_time.value;
    var pickupDate = moment(form.pickup_time.value, "DD/MM/YYYY").format(
      "YYYY-MM-DD"
    );
    var pickupTime = form.am_hours.value;

    //If Job is created from month view use this
    if (showMonthViewPopup) {
      var startDateJob = moment(new Date(startTimeNew)).format("YYYY-MM-DD");
      var endDateJob = moment(new Date(startTimeNew)).format("YYYY-MM-DD");
      let dateandtime = startTimeNew + " " + selectedSlot;
      var startTimeJob = timeZoneToUTC(
        new Date(dateandtime),
        timezoneLable,
        "YYYY-MM-DD HH:mm:ss",
        "HH:mm:ss"
      );
      var endTimeJob = moment(startTimeJob, "HH:mm:ss")
        .add(90, "minutes")
        .format("HH:mm:ss");
    }

    if (form.checkValidity() !== false) {
      let createJob = {};
      createJob["insurance_company_id"] = jobFormValue?.customer_name || 0;
      createJob["claim_number"] = jobFormValue?.request_claim || "";
      createJob["pickup_time"] = pickupDate;
      createJob["workshop_id"] = workshop_id;
      createJob["created_by"] = user_id;
      createJob["customer_id"] = jobFormValue.customer_id;
      createJob["user_id"] = showMonthViewPopup
        ? empDataType === "res_tech"
          ? employeeId
          : 0
        : job_user_id;
      createJob["bay_id"] = showMonthViewPopup
        ? empDataType === "res_bay"
          ? employeeId
          : 0
        : job_bay_id;
      createJob["vehicle_id"] = jobFormValue.vehicle_id;
      createJob["job_type"] = jobFormValue.job_type;
      createJob["job_department_id"] = jobFormValue.job_department;
      createJob["from_date"] = showMonthViewPopup ? startDateJob : startDate;
      createJob["to_date"] = showMonthViewPopup ? endDateJob : endDate;
      createJob["from_time"] = showMonthViewPopup ? startTimeJob : startTime;
      createJob["to_time"] = showMonthViewPopup ? endTimeJob : endTime;
      createJob["duration"] = showMonthViewPopup ? 90 : durationJob;
      createJob["tags_id"] = tagsArrId;
      createJob["description"] = jobFormValue?.script_description || "";
      createJob["script_id"] = jobFormValue?.script_id || "";
      createJob["job_status"] = "Pending";
      createJob["job_stage"] = "Bookin";
      createJob["pickup_time_only"] = "";
      createJob["customer_vehicle_option"] = jobFormValue?.customer_type || 0;
      createJob["insp_reference_id"] = jobFormValue?.inspection_name || "";
      createJob["bookin_source"] = 2;
      createJob["source_of_business"] = jobFormValue?.source_of_business || "";
      createJob["job_estimate_time"] = jobEstimateTime;
      setCreateJobFlag(true);
      dispatch({
        type: CREATE_JOB,
        payload: createJob,
      });
    }
    setValidated(true);
  }

  const getCreateEvent = (key) => {
    setCreateEvent(key);
  };

  // Quote submit form
  const [quoteSelectedFlag, setQuoteSelectedFlag] = useState("disabled");
  const [selectedQuoteId, setSelectedQuoteId] = useState();
  const [quotesParts, setQuotesParts] = useState([]);
  const [quotesLabours, setQuotesLabours] = useState([]);

  const handleQuoteSubmitForm = () => {
    if (
      selectedQuoteId !== undefined &&
      selectedQuoteId !== "" &&
      selectedQuoteId !== null
    ) {
      if (quotesDetailsData !== null && quotesDetailsData !== undefined) {
        let quoteToJob = {};
        quoteToJob["workshop_id"] = workshop_id;
        quoteToJob["created_by"] = user_id;
        quoteToJob["customer_id"] = quotesDetailsData[0].user_id;
        quoteToJob["bay_id"] = job_bay_id;
        quoteToJob["user_id"] = job_user_id;
        quoteToJob["vehicle_id"] = quotesDetailsData[0].vehicle_id;
        quoteToJob["job_type"] = quotesDetailsData?.[0]?.job_type || "";
        quoteToJob["job_department_id"] =
          quotesDetailsData?.[0]?.job_department || "";
        quoteToJob["from_date"] = startDate;
        quoteToJob["to_date"] = endDate;
        quoteToJob["from_time"] = startTime;
        quoteToJob["to_time"] = endTime;
        quoteToJob["duration"] = durationJob;
        quoteToJob["tags_id"] = {};
        quoteToJob["description"] = quotesDetailsData?.[0]?.description || "";
        quoteToJob["job_status"] = "Pending";
        quoteToJob["job_stage"] = "Bookin";
        quoteToJob["insp_reference_id"] = 0;
        quoteToJob["bookin_source"] = 2;
        quoteToJob["source_of_business"] = "Existing";
        quoteToJob["customer_quotes_id"] = selectedQuoteId;
        quoteToJob["parts_cost"] = quotesDetailsData?.[0]?.parts_cost || "0.00";
        quoteToJob["labour_cost"] =
          quotesDetailsData?.[0]?.labour_cost || "0.00";
        quoteToJob["parts_markup"] =
          quotesDetailsData?.[0]?.parts_markup || "0.00";
        quoteToJob["labour_markup"] =
          quotesDetailsData?.[0]?.labour_markup || "0.00";
        quoteToJob["parts_total_cost"] =
          quotesDetailsData?.[0]?.parts_total_cost || "0.00";
        quoteToJob["labour_total_cost"] =
          quotesDetailsData?.[0]?.labour_total_cost || "0.00";
        quoteToJob["sub_total"] =
          quotesDetailsData?.[0]?.labour_parts_total_cost || "0.00";
        quoteToJob["gst"] = quotesDetailsData?.[0]?.gst || "0.00";
        quoteToJob["total"] = quotesDetailsData?.[0]?.total_cost || "0.00";
        quoteToJob["discount_rate"] =
          quotesDetailsData?.[0]?.discount_rate || "0.00";
        dispatch({
          type: CREATE_QUOTE_TO_JOB,
          payload: quoteToJob,
        });
        dispatch({
          type: EDIT_QUOTES,
          payload: {
            id: selectedQuoteId,
            quote_status: "Won",
          },
        });
        setCreateQuoteFlag(true);
      }
    }
  };

  // useEffect(() => {
  //     if (saveQuoteDetails !== null && saveQuoteDetails !== undefined) {
  //         let quoteToJob = {};
  //         quoteToJob['workshop_id'] = workshop_id;
  //         quoteToJob['created_by'] = user_id;
  //         quoteToJob['customer_id'] = saveQuoteDetails[0].user_id;
  //         quoteToJob['bay_id'] = job_bay_id;
  //         quoteToJob['user_id'] = job_user_id;
  //         quoteToJob['vehicle_id'] = saveQuoteDetails[0].vehicle_id;
  //         quoteToJob['job_type'] = saveQuoteDetails?.[0]?.job_type || '';
  //         quoteToJob['job_department_id'] = saveQuoteDetails?.[0]?.job_department || '';
  //         quoteToJob['from_date'] = startDate;
  //         quoteToJob['to_date'] = endDate;
  //         quoteToJob['from_time'] = startTime;
  //         quoteToJob['to_time'] = endTime;
  //         quoteToJob['duration'] = durationJob;
  //         quoteToJob['tags_id'] = {};
  //         quoteToJob['description'] = saveQuoteDetails?.[0]?.description || '';
  //         quoteToJob['job_status'] = 'Pending';
  //         quoteToJob['job_stage'] = 'Bookin';
  //         quoteToJob['insp_reference_id'] = 0;
  //         quoteToJob['bookin_source'] = 2;
  //         quoteToJob['source_of_business'] = 'Existing';
  //         quoteToJob['customer_quotes_id'] = selectedQuoteId;
  //         quoteToJob['parts_cost'] = saveQuoteDetails?.[0]?.parts_cost || '0.00';
  //         quoteToJob['labour_cost'] = saveQuoteDetails?.[0]?.labour_cost || '0.00';
  //         quoteToJob['parts_markup'] = saveQuoteDetails?.[0]?.parts_markup || '0.00';
  //         quoteToJob['labour_markup'] = saveQuoteDetails?.[0]?.labour_markup || '0.00';
  //         quoteToJob['parts_total_cost'] = saveQuoteDetails?.[0]?.parts_total_cost || '0.00';
  //         quoteToJob['labour_total_cost'] = saveQuoteDetails?.[0]?.labour_total_cost || '0.00';
  //         quoteToJob['sub_total'] = saveQuoteDetails?.[0]?.labour_parts_total_cost || '0.00';
  //         quoteToJob['gst'] = saveQuoteDetails?.[0]?.gst || '0.00';
  //         quoteToJob['total'] = saveQuoteDetails?.[0]?.total_cost || '0.00';
  //         quoteToJob['discount_rate'] = saveQuoteDetails?.[0]?.discount_rate || '0.00';
  //         dispatch({
  //             type: CREATE_QUOTE,
  //             payload: quoteToJob,
  //         });
  //         dispatch({
  //             type: EDIT_QUOTES,
  //             payload: {
  //                 'id': selectedQuoteId,
  //                 'quote_status': 'Won'
  //             },
  //         });
  //         setCreateQuoteFlag(true);
  //     }
  // }, [saveQuoteDetails])

  useEffect(() => {
    if (
      quoteDetailsData !== null &&
      quoteDetailsData !== undefined &&
      createQuoteFlag === true
    ) {
      console.log(quoteDetailsData, "quoteDetailsData");
      var job_id = quoteDetailsData?.id || null;

      console.log(job_id, "job_id");

      let partsArr = [];
      if (quotesParts.length !== 0 && quotesParts !== null) {
        quotesParts.map((partData, index) => {
          let parts = {};
          parts["workshop_id"] = workshop_id;
          parts["job_schedule_id"] = job_id;
          parts["product_id"] = partData?.product_id || 0;
          parts["is_inspection_part"] = partData?.is_inspection_part || 0;
          var productType = partData?.product?.product_type || 0;
          if (productType === 1) {
            parts["is_kit"] = partData?.is_kit || 0;
          } else {
            parts["is_kit"] = partData?.is_kit || 1;
          }
          parts["job_part_name"] = partData?.quote_part_name || 0;
          parts["job_part_priority"] = partData?.quote_part_priority || 0;
          parts["cost"] = partData?.cost || 0;
          parts["po_qty_sent"] = partData?.po_qty_sent || 0;
          parts["price"] = partData?.price || 0;
          parts["is_demo_data"] = partData?.is_demo_data || 0;
          parts["margin"] = partData?.margin || 0;
          parts["sell"] = partData?.sell || 0;
          parts["profit"] = partData?.profit || 0;
          parts["gst"] = partData?.gst || 0;
          parts["time"] = partData?.time || 0;
          parts["qty"] = partData?.qty || 0;
          parts["retail"] = partData?.retail || 0;
          parts["assigned_qty"] = partData?.assigned_qty || 0;
          parts["purchase_order_qty"] = partData?.purchase_order_qty || 0;
          parts["basic_stock"] = partData?.basic_stock || 0;
          parts["is_assigned"] = partData?.is_assigned || 0;
          parts["repco_extra_part"] = partData?.repco_extra_part || 0;
          partsArr.push(parts);
        });

        dispatch({
          type: ADD_MANY_JOB_PARTS,
          payload: {
            data: partsArr,
          },
        });
      }

      let laboursArr = [];
      if (quotesLabours !== null && quotesLabours.length !== 0) {
        quotesLabours.map((labourData, index) => {
          let labours = {};
          labours["labour_charges_id"] = labourData?.id || 0;
          labours["cost_price"] = labourData?.cost || 0;
          labours["margin_markup"] = labourData?.markup || 0;
          labours["sell_price"] = labourData?.sell || 0;
          labours["retail_price"] = labourData?.retail || 0;
          labours["labour_time"] = labourData?.time || 0;
          labours["total_price"] = labourData?.total || 0;
          labours["job_schedules_id"] = job_id;
          labours["margin"] = labourData?.margin || 0;
          labours["labour_description"] = labourData?.labour_description || 0;
          labours["labour_ph"] = labourData?.labour_ph || 0;
          labours["profit"] = labourData?.profit || 0;
          labours["priority"] = labourData?.priority || 0;
          labours["is_inspection"] = labourData?.is_inspection || 0;
          laboursArr.push(labours);
        });

        dispatch({
          type: ADD_MANY_JOB_LABOURS,
          payload: {
            data: laboursArr,
          },
        });
      }

      if (quoteKitParts !== null && quoteKitParts !== undefined) {
        let jobKitPartsArr = [];
        quoteKitParts.map((jobKitPart, index) => {
          let kitPart = {};
          kitPart["workshop_id"] = workshop_id;
          kitPart["job_id"] = job_id;
          kitPart["job_parts_id"] = jobKitPart.quotes_parts_id;
          kitPart["job_kit_labour_id"] = jobKitPart.quote_kit_labour_id;
          kitPart["kit_id"] = jobKitPart.kit_id;
          kitPart["part_id"] = jobKitPart.part_id;
          kitPart["priority"] = jobKitPart.priority;
          kitPart["cost"] = jobKitPart.cost;
          kitPart["margin"] = jobKitPart.margin;
          kitPart["sell"] = jobKitPart.sell;
          kitPart["qty"] = jobKitPart.qty;
          kitPart["retail"] = jobKitPart.retail;
          kitPart["margin_markup"] = jobKitPart.margin_markup;
          kitPart["gst"] = jobKitPart.gst;
          kitPart["gst_price"] = jobKitPart.gst_price;
          kitPart["assigned_qty"] = jobKitPart.assigned_qty;
          kitPart["purchase_order_qty"] = jobKitPart.purchase_order_qty;
          kitPart["po_qty_sent"] = jobKitPart.po_qty_sent;
          kitPart["basic_stock"] = jobKitPart.basic_stock;
          kitPart["is_assigned"] = jobKitPart.is_assigned;
          jobKitPartsArr.push(kitPart);
        });

        dispatch({
          type: ADD_MANY_JOB_KIT_PARTS,
          payload: {
            data: jobKitPartsArr,
          },
        });
      }

      if (quoteKitLabours !== null && quoteKitLabours !== undefined) {
        let jobKitLabourArr = [];
        quoteKitLabours.map((jobKitLabour, index) => {
          let kitLabour = {};
          kitLabour["workshop_id"] = workshop_id;
          kitLabour["job_id"] = job_id;
          kitLabour["kit_id"] = jobKitLabour.kit_id;
          kitLabour["labour_charge_id"] = jobKitLabour.labour_charge_id;
          kitLabour["cost_price"] = jobKitLabour.cost_price;
          kitLabour["margin"] = jobKitLabour.margin;
          kitLabour["sell_price"] = jobKitLabour.sell_price;
          kitLabour["qty_hours"] = jobKitLabour.qty_hours;
          kitLabour["retail_price"] = jobKitLabour.retail_price;
          kitLabour["margin_markup"] = jobKitLabour.margin_markup;
          kitLabour["gst"] = jobKitLabour.gst;
          kitLabour["gst_price"] = jobKitLabour.gst_price;
          kitLabour["discription"] = jobKitLabour.discription;
          jobKitLabourArr.push(kitLabour);
        });

        dispatch({
          type: ADD_MANY_JOB_KIT_LABOURS,
          payload: {
            data: jobKitLabourArr,
          },
        });
      }
      showSuccess("Quote to Job converted Successfully!");
      setCreatedJobId(job_id);
      handleShow();
      setManageStateModal("save");
    }
    setCreateQuoteFlag(false);
  }, [quoteDetailsData]);

  useEffect(() => {
    if (createEvent === "quotes") {
      dispatch({
        type: GET_OPEN_QUOTES,
        payload: {
          get: "all",
          conditions: {
            "CustomerQuotes.workshop_id": workshop_id,
            "CustomerQuotes.quote_status": "Open",
          },
          contain: {
            Vehicles: {
              fields: ["id", "rego"],
            },
            "Users.CustomersDetails": {
              fields: ["id", "user_id"],
            },
          },
          fields: ["id", "vehicle_id", "quote_number", "Users.id", "user_id"],
        },
      });
    }

    if (createEvent === "activities") {
      dispatch({
        type: GET_ACTIVITY,
        payload: {
          get: "all",
          conditions: {
            workshop_id: workshop_id,
            is_deleted: 0,
          },
          fields: ["id", "name", "is_default", "type"],
        },
      });
    }
  }, [createEvent]);

  useEffect(() => {
    if (createEvent === "activities") {
      dispatch({
        type: GET_SCHEDULE_ACTIVITY,
        payload: {
          get: "all",
          conditions: {
            "ActivitySchedule.workshop_id": workshop_id,
          },
          group: ["ActivitySchedule.name"],
          contain: {
            ActivityMaster: {
              fields: ["id", "name", "type"],
            },
          },
          fields: ["id", "activity_master_id", "name", "description"],
        },
      });
    }
  }, [createEvent, updateActivityList]);

  // Convert quote to job
  useEffect(() => {
    if (quotesData !== null && quotesData !== undefined) {
      setQuoteList(quotesData);
    }
  }, [quotesData]);

  async function getQuoteDetails(id) {
    setSelectedQuoteId(id);
    dispatch({
      type: GET_QUOTE_DETAILS,
      payload: {
        get: "all",
        conditions: {
          "CustomerQuotes.id": id,
          "CustomerQuotes.workshop_id": workshop_id,
        },
        contain: {
          Vehicles: {
            fields: [
              "id",
              "user_id",
              "rego",
              "make",
              "model",
              "year",
              "transmission",
              "service_due_date",
              "created_on",
            ],
          },
          WorkshopDepartmentsAccountsDetails: {
            fields: ["id", "name", "job_type_parent_id"],
          },
          JobSubTypes: {
            fields: ["id", "name"],
          },
          "Users.CustomersDetails": {
            fields: ["id", "user_id", "firstname", "lastname"],
          },
          CustomerQuotesParts: {
            fields: [
              "product_id",
              "cost",
              "margin",
              "sell",
              "retail",
              "qty",
              "price",
              "profit",
              "time",
              "qty",
              "gst",
              "quote_part_priority",
              "customer_quotes_id",
              "quote_part_name",
              "quote_part_description",
              "id",
            ],
          },
          "CustomerQuotesParts.Products": {
            fields: ["id", "product_type"],
          },
          CustomerQuotesLabours: {
            fields: [
              "id",
              "type",
              "cost",
              "markup",
              "sell",
              "retail",
              "labour_description",
              "time",
              "total",
              "profit",
              "customer_quotes_id",
            ],
          },
        },
        fields: [
          "workshop_id",
          "Users.id",
          "id",
          "vehicle_id",
          "user_id",
          "job_department",
          "description",
          "job_type",
          "reason_description",
          "total_cost",
          "quote_number",
          "parts_cost",
          "labour_cost",
          "parts_markup",
          "labour_markup",
          "parts_total_cost",
          "labour_total_cost",
          "labour_parts_total_cost",
          "gst",
          "total_cost",
          "discount_rate",
        ],
      },
    });
    dispatch({
      type: GET_QUOTE_KIT_PARTS,
      payload: {
        get: "all",
        conditions: {
          quote_id: id,
          workshop_id: workshop_id,
        },
      },
    });
    dispatch({
      type: GET_QUOTE_KIT_LABOURS,
      payload: {
        get: "all",
        conditions: {
          quote_id: id,
          workshop_id: workshop_id,
        },
      },
    });
  }

  useEffect(() => {
    if (quotesDetailsData !== null && quotesDetailsData !== undefined) {
      setQuotesParts(quotesDetailsData?.[0]?.customer_quotes_parts || []);
      setQuotesLabours(quotesDetailsData?.[0]?.customer_quotes_labours || []);
      setQuoteSelectedFlag("");
      setSelectedQuoteId(quotesDetailsData?.[0]?.id);
      setMakeQuote(quotesDetailsData?.[0]?.vehicle?.make || "MAKE");
      setModelQuote(quotesDetailsData?.[0]?.vehicle?.model || "MODEL");
      setYearQuote(quotesDetailsData?.[0]?.vehicle?.year || "YEAR");
      setJobDeptQuote(
        quotesDetailsData?.[0]?.workshop_departments_accounts_detail?.name ||
          "DEPARTMENT"
      );
      setJobTypeQuote(quotesDetailsData?.[0]?.job_sub_type?.name || "TYPE");
      setDescQuote(quotesDetailsData?.[0]?.description || "DESCRIPTION");
    }
  }, [quotesDetailsData]);

  const getSearchQuote = (e) => {
    let value = e.target.value.toLowerCase();
    if (value !== "") {
      searchQuote(value);
    } else {
      setQuoteList(quotesData);
    }
  };

  let quotesDataArr = [];
  const searchQuote = (value) => {
    quotesDataArr = quotesData.filter((item) => {
      let vinData1 = (item.quote_number + item.vehicle.rego).toLowerCase();
      if (vinData1.indexOf(value) !== -1) {
        return vinData1;
      }
    });
    setQuoteList(quotesDataArr);
  };

  //If Month View
  const employeeDetails = useSelector(
    (state) => state.CommonApiStoreReducer.Workshops.users
  );
  const workshopBayData = useSelector(
    (state) => state.SchedulerReducer.workshopBaysDetails
  );
  const currentDay = new Date().getDay();
  const [employeeId, setEmployeeId] = useState("");
  const [timeSlotsList, setTimeSlotsList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState("");
  const [empDataType, setEmpDataType] = useState("res_tech");
  const [fromDate, setfromdate] = useState();
  const [disabledSave, setDisabledSave] = useState("disabled");

  let defaultSelect = true;
  useEffect(() => {
    var startTime = utcToTimezone(
      workshopOtherDetails[0].shift_start_time,
      timezoneLable,
      "HH:mm:ss",
      "HH:mm:ss"
    );
    var fromDate = startTimeNew + " " + startTime;
    setfromdate(fromDate);
  }, []);
  const handleTechnicianSlot = (e, emp) => {
    setEmpDataType(e.dataset.id);
    setTechnicianName(emp["name"]);
    var startTime = utcToTimezone(
      workshopOtherDetails[0].shift_start_time,
      timezoneLable,
      "HH:mm:ss",
      "HH:mm:ss"
    );
    var fromDate = startTimeNew + " " + startTime;
    setfromdate(fromDate);
  };

  useEffect(() => {
    if (showMonthViewPopup) {
      if (employeeId !== "") {
        setTimeSlot(null);
        setDisabledSave("disabled");
        if (empDataType === "res_tech") {
          dispatch({
            type: GET_AVAIALABLE_SLOTS,
            payload: {
              workshop_id: workshop_id,
              from_date: fromDate,
              days: currentDay,
              "job_stage NOT IN": ["Archive"],
              user_id: employeeId,
            },
          });
        } else {
          dispatch({
            type: GET_AVAIALABLE_SLOTS,
            payload: {
              workshop_id: workshop_id,
              from_date: fromDate,
              days: currentDay,
              "job_stage NOT IN": ["Archive"],
              user_id: 0,
              bay_id: employeeId,
            },
          });
        }
      }
    }
  }, [fromDate, employeeId]);

  useEffect(() => {
    if (showMonthViewPopup) {
      let technicianList = [];
      if (employeeDetails !== null || employeeDetails !== undefined) {
        employeeDetails.map((employee, index) => {
          let technicianListArr = [];
          technicianListArr["data_type"] = "res_tech";
          technicianListArr["name"] =
            employee.firstname + " " + employee.lastname;
          technicianListArr["id"] = employee.id;
          var employeeWorkingDayArr = [];
          if (
            employee.employees_details[0] !== "" &&
            employee.employees_details[0] !== undefined &&
            employee.employees_details[0] !== null
          ) {
            if (employee.employees_details[0].shift_working_day) {
              employeeWorkingDayArr = unserializeArr(
                employee.employees_details[0].shift_working_day
              );
            }
          }
          technicianListArr["working_days"] = 0;
          if (employeeWorkingDayArr.includes(`${currentDay}`)) {
            technicianListArr["working_days"] = 1;
            if (defaultSelect === true) {
              setEmployeeId(employee.id);
              setEmpDataType("res_tech");
              // eslint-disable-next-line react-hooks/exhaustive-deps
              defaultSelect = false;
            }
          }
          technicianList.push(technicianListArr);
        });
      }

      if (workshopBayData !== null || workshopBayData !== undefined) {
        workshopBayData.map((bays, index) => {
          let baysArr = [];
          baysArr["data_type"] = "res_bay";
          baysArr["name"] = bays.name;
          baysArr["id"] = bays.id;
          baysArr["working_days"] = 1;
          if (defaultSelect === true) {
            setEmpDataType("res_bay");
            setEmployeeId(bays.id);
            defaultSelect = false;
          }
          technicianList.push(baysArr);
        });
      }

      setTechnicianName(technicianList[0]["name"]);
      setEmployeeList(technicianList);
    }
  }, [workshopBayData, employeeDetails]);
  // Month View Fetch Timeslot List
  const workshopOtherDetails = useSelector(
    (state) => state.CommonApiStoreReducer.Workshops.workshop_other_details
  );
  const schedulerData = useSelector(
    (state) => state.SchedulerReducer.getAvailableSlots
  );
  useEffect(() => {
    if (showMonthViewPopup) {
      if (schedulerData !== null && schedulerData !== undefined) {
        var techTimeSlots = workshopOtherDetails[0].technician_timeslot;
        let slotsArrList = [];
        for (var key in schedulerData) {
          let slotsArr = [];
          var startTimeSlots = moment.utc(key, "hh:mm:ss").format("hh:mm A");
          var endTimeSlots = moment
            .utc(key, "hh:mm:ss")
            .add(techTimeSlots, "minutes")
            .format("hh:mm A");
          slotsArr["start_time"] = key;
          slotsArr["slots"] = startTimeSlots + " - " + endTimeSlots;
          slotsArr["available"] = schedulerData[key][0];
          slotsArrList.push(slotsArr);
        }
        setTimeSlotsList(slotsArrList);
      }
    }
  }, [schedulerData]);

  const setTimeSlot = (slot) => {
    setSelectedSlot(slot);
  };

  //activity
  const [selectedActivitySchedule, setSelectedActivitySchedule] = useState();
  const [selectedActivityName, setSelectedActivityName] = useState();
  const [selectedActivityDescription, setSelectedActivityDescription] =
    useState();
  const [activityScheduleList, setActivityScheduleList] = useState(null);
  const [openCreateActivity, setOpenCreateActivity] = useState(false);
  const [activityName, setActivityName] = useState();
  const [activityEmployee, setActivityEmployee] = useState([]);
  const [defaultActivity, setDefaultActivity] = useState([
    { value: technicianId, label: technicianName },
  ]);
  const [actionType, setActionType] = useState();
  const [activityId, setActivityId] = useState();
  const [activityEmpArr, setActivityEmpArr] = useState([
    { value: technicianId, label: technicianName },
  ]);
  const [saveActivityFlag, setSaveActivityFlag] = useState(false);
  const [activityDeleteId, setActivityDeleteId] = useState();
  const [activityDeleted, setActivityDeleted] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteClose = () => setShowDeleteModal(false);

  useEffect(() => {
    if (deleteJobActivity !== null && deleteJobActivity !== undefined) {
      if (activityDeleted === true) {
        showSuccess("Activity deleted successfully!");
        setUpdateActivityList(true);
        setActivityDeleted(false);
      }
    }
  }, [deleteJobActivity]);

  const handleEmployeeChange = (employee) => {
    setActivityEmpArr(employee);
  };

  useEffect(() => {
    if (addActivityData !== null && addActivityData !== undefined) {
      showSuccess("Activity created successfully!");
      activityData.push(addActivityData);
      setOpenCreateActivity(false);
    }
  }, [addActivityData]);

  const saveCreateActivity = () => {
    var alreadyExist = false;
    activityData.filter((item) => {
      let activityArr = item.name;
      if (activityArr.indexOf(activityName) !== -1) {
        alreadyExist = true;
      }
    });
    if (alreadyExist === true) {
      showError("Activity already exist!");
    } else {
      dispatch({
        type: ADD_ACTIVITY,
        payload: {
          workshop_id: workshop_id,
          name: activityName,
          type: 1,
          is_default: 0,
        },
      });
    }
  };

  useEffect(() => {
    if (activityScheduleData !== null && activityScheduleData !== undefined) {
      setActivityScheduleList(activityScheduleData);
    }
  }, [activityScheduleData]);

  const getSearchActivity = (e) => {
    let value = e.target.value.toLowerCase();
    if (value !== "") {
      searchActivity(value);
    } else {
      setActivityScheduleList(activityScheduleData);
    }
  };

  let activityDataArr = [];
  const searchActivity = (value) => {
    activityDataArr = activityScheduleList.filter((item) => {
      let activityArr = item.name.toLowerCase();
      if (activityArr.indexOf(value) !== -1) {
        return activityArr;
      }
    });
    setActivityScheduleList(activityDataArr);
  };

  useEffect(() => {
    let employeeActivity = [];
    if (employeeDetails !== null && employeeDetails !== undefined) {
      employeeDetails.map((emp, index) => {
        let empArr = {};
        empArr["value"] = emp.id;
        empArr["label"] = emp.firstname + " " + emp.lastname;
        employeeActivity.push(empArr);
      });
    }
    if (workshopBayData !== null && workshopBayData !== undefined) {
      workshopBayData.map((bay, index) => {
        let bayArr = {};
        bayArr["value"] = bay.id;
        bayArr["label"] = bay.name;
        employeeActivity.push(bayArr);
      });
    }
    setActivityEmployee(employeeActivity);
  }, [employeeDetails, workshopBayData]);

  useEffect(() => {
    if (actionType !== undefined) {
      if (activityScheduleData !== null && activityScheduleData !== undefined) {
        let activityListDataArr = [];
        if (actionType === "Meeting") {
          activityListDataArr = activityScheduleData.filter((item) => {
            if (item.activity_master.type === "1") {
              return item;
            }
          });
        } else if (actionType === "Holiday") {
          activityListDataArr = activityScheduleData.filter((item) => {
            if (item.activity_master.type === "2") {
              return item;
            }
          });
        } else if (actionType === "Job") {
          activityListDataArr = activityScheduleData.filter((item) => {
            if (item.activity_master.type === "3") {
              return item;
            }
          });
        }
        setActivityScheduleList(activityListDataArr);
      }
    }
  }, [actionType]);

  const saveActivity = () => {
    dispatch({
      type: ADD_SCHEDULE_ACTIVITY,
      payload: {
        workshop_id: workshop_id,
        activity_master_id: activityId,
        name: selectedActivityName,
        description: selectedActivityDescription,
      },
    });
    setSaveActivityFlag(true);
  };

  useEffect(() => {
    if (addCreateActivityJob !== null && addCreateActivityJob !== undefined) {
      showSuccess("Activity created Successfully!");
      setUpdateCalendar(true);
      clearModalData();
      close();
    }
  }, [addCreateActivityJob]);

  useEffect(() => {
    if (
      addActivityScheduleData !== null &&
      addActivityScheduleData !== undefined &&
      saveActivityFlag === true
    ) {
      var activityScheduleId = addActivityScheduleData.id;
      let activityCreateArr = [];
      console.log(activityEmpArr, "activityEmpArr");
      if (activityEmpArr) {
        activityEmpArr.map((emp, index) => {
          let activityJob = {};
          activityJob["workshop_id"] = workshop_id;
          activityJob["created_by"] = user_id;
          activityJob["user_id"] = emp.value;
          activityJob["activity_schedule_id"] = activityScheduleId;
          activityJob["from_date"] = startDate;
          activityJob["to_date"] = endDate;
          activityJob["from_time"] = startTime;
          activityJob["to_time"] = endTime;
          activityJob["duration"] = durationJob;
          activityJob["job_status"] = "Pending";
          activityJob["job_stage"] = "Bookin";
          activityJob["is_on_leave"] = 0;
          activityJob["bookin_source"] = 2;
          activityJob["source_of_business"] = "Signage";
          activityCreateArr.push(activityJob);
        });
        if (activityCreateArr.length !== 0) {
          dispatch({
            type: ADD_MANY_CREATE_JOB,
            payload: {
              data: activityCreateArr,
            },
          });
          setSaveActivityFlag(false);
        }
      }
    }
  }, [addActivityScheduleData]);

  const deleteActivitySchedule = async () => {
    dispatch({
      type: DELETE_JOB_SCHEDULE_ACTIVITY,
      payload: {
        id: activityDeleteId,
      },
    });
    setActivityDeleted(true);
    setShowDeleteModal(false);
    setUpdateCalendar(true);
  };

  const handleDeleteOpen = (delete_id) => {
    if (delete_id !== "" && delete_id !== undefined) {
      setShowDeleteModal(true);
      setActivityDeleteId(delete_id);
    }
  };

  return (
    <>
      {manageStateModal === "parent" ? (
        <Modal
          className="create-job"
          size="lg"
          show={show}
          onHide={() => {
            clearModalData();
            close();
          }}
        >
          <Modal.Header>
            <Modal.Title className="d-flex justify-space-between">
              {showMonthViewPopup ? (
                <>
                  <div>
                    <span className="name">{technicianNameNew}</span>
                    <span> | </span>
                    <span className="time">
                      {startEventTime} {selectedSlot}
                    </span>
                  </div>
                </>
              ) : (
                <div>
                  <span className="name">{technicianName}</span>
                  <span> | </span>
                  <span className="time">{startJobTime}</span>
                </div>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="rounded-tabs">
              <Tabs
                defaultActiveKey="Jobs"
                onSelect={(key) => getCreateEvent(key)}
              >
                <Tab eventKey="Jobs" title="Jobs">
                  {showMonthViewPopup ? (
                    <MonthViewCreateJob
                      timeSlotsList={timeSlotsList}
                      employeeList={employeeList}
                      setTimeSlot={setTimeSlot}
                      timeSlot={selectedSlot}
                      setEmployeeId={setEmployeeId}
                      employeeId={employeeId}
                      handleTechnicianSlot={handleTechnicianSlot}
                    />
                  ) : (
                    ""
                  )}
                  {/* step 1 for jobs */}
                  <Form
                    ref={formRef}
                    onSubmit={handleSubmitForm}
                    noValidate
                    validated={validated}
                    id="jobCreate"
                  >
                    {!activeStep ? (
                      <div className="tab-wrapper">
                        <div className="search-wrapper">
                          <span className="title">Vehicle & Customer</span>
                          <div
                            className={`general-search-wrapper ${
                              activeSearchList ? "active-search-list" : ""
                            }`}
                          >
                            <Form.Group className="form-group mb-3">
                              <Form.Control
                                type="text"
                                placeholder="Search for an Asset,Customer or Mobile "
                                onKeyUp={getChildSearchVehicle}
                                id="select_activity_new"
                                name="select_activity_new"
                              />
                              <Button
                                className="btn-primary rounded-btn"
                                onClick={() => handleCustomerShow()}
                              >
                                <span>+</span> Customers & Vehicles
                              </Button>
                            </Form.Group>
                            <ul className="search-list with-icon">
                              <Scrollbars
                                autoHeight
                                id="customerVehicleData"
                                autoHeightMax={185}
                                className="custom-scrollbar"
                                style={{ width: "100%" }}
                                renderThumbVertical={(props) => (
                                  <div
                                    {...props}
                                    className="thumb-vertical black-thumb"
                                  />
                                )}
                              >
                                {filterVinDatas.map((filter, index) => {
                                  var is_vehicle_available =
                                    filter.is_vehicle_available;
                                  let img = "";
                                  if (filter.icon === true) {
                                    img = (
                                      <img
                                        src={IcCustomerBlue}
                                        className="filter_ic_customer_blue"
                                        alt=""
                                      ></img>
                                    );
                                  }
                                  if (is_vehicle_available === 1) {
                                    return (
                                      <li key={index}>
                                        <Link
                                          className="d-flex justify-space-between align-center no-wrap"
                                          to=""
                                          onClick={() => {
                                            getVehicleDetails(filter.rego);
                                          }}
                                        >
                                          <span>
                                            <span className="title">
                                              {filter.rego} | {filter.name} |{" "}
                                              {filter.mobile}
                                            </span>
                                            {img}
                                          </span>
                                          <span
                                            className="add-icon"
                                            onClick={() => {
                                              setCustomerFormType("edit");
                                              setSelectedUser(filter);
                                              setShowCustomersAndVehiclesModal(
                                                true
                                              );
                                              setManageStateModal("child");
                                            }}
                                          >
                                            <i className="fa fa-plus"></i>
                                          </span>
                                        </Link>
                                      </li>
                                    );
                                  } else {
                                    return (
                                      <li key={index}>
                                        <Link
                                          className="d-flex justify-space-between align-center no-wrap"
                                          to=""
                                          onClick={() => {
                                            setCustomerFormType("edit");
                                            setSelectedUser(filter);
                                            setShowCustomersAndVehiclesModal(
                                              true
                                            );
                                            setManageStateModal("child");
                                          }}
                                        >
                                          <span>
                                            <span className="title">
                                              {filter.rego} | {filter.name} |{" "}
                                              {filter.mobile}
                                            </span>
                                            {img}
                                          </span>
                                          <span
                                            className="add-icon"
                                            onClick={() => {
                                              setCustomerFormType("edit");
                                              setSelectedUser(filter);
                                              setShowCustomersAndVehiclesModal(
                                                true
                                              );
                                              setManageStateModal("child");
                                            }}
                                          >
                                            <i className="fa fa-plus"></i>
                                          </span>
                                        </Link>
                                      </li>
                                    );
                                  }
                                })}
                              </Scrollbars>
                            </ul>
                          </div>
                        </div>
                        <div className={"panel-wrapper " + vehicleSection}>
                          <div className="panel">
                            <div className="number-plate">
                              <span>{rego}</span>
                            </div>
                            <div className="panel-right">
                              <div>
                                <div className="top-block">
                                  <i className="fa fa-user"></i>
                                  <span>{customerName}</span>
                                  <span>{mobileNumber}</span>
                                  <span>{email}</span>
                                </div>
                                <div className="bottom-block d-flex align-items-center">
                                  <CarMakeLogo make={make || ""} />
                                  <span>{make}</span>
                                  <span>{modal}</span>
                                  <span>{year}</span>
                                </div>
                              </div>
                              <Button
                                className="btn-black rounded-btn"
                                onClick={() => viewCustomerVehicle()}
                              >
                                <i className="fa fa-eye"></i>
                                <span>View</span>
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="select-wrapper d-flex justify-space-between">
                          <div className="select-block custom-normal-select d-flex align-center no-wrap">
                            <label>Inspection RO</label>
                            <select
                              className="form-control custom-select"
                              name="inspection_name"
                              id="inspection_name"
                              onChange={(e) => setFormValue(e)}
                            >
                              <option>Select</option>
                              {inspectionRoType !== null &&
                              inspectionRoType.length !== 0
                                ? inspectionRoType.map((insp) => {
                                    return (
                                      <option key={insp.id} value={insp.id}>{`${
                                        insp?.insp_template?.name || ""
                                      } (${insp.id})`}</option>
                                    );
                                  })
                                : ""}
                            </select>
                          </div>
                          <div className="select-block custom-normal-select d-flex align-center no-wrap">
                            <label>Booking Source</label>
                            <Form.Select
                              aria-label="Default select example"
                              id="source_of_business"
                              name="source_of_business"
                              onChange={(e) => setFormValue(e)}
                            >
                              <option value="Existing">Existing</option>
                              <option value="Walk In">Walk In</option>
                              <option value="Referral">Referral</option>
                              <option value="Social Media">Social Media</option>
                              <option value="Customer App">Customer App</option>
                              <option value="Signage">Signage</option>
                              <option value="Other">Other</option>
                            </Form.Select>
                          </div>
                          <div className="select-block custom-normal-select d-flex align-center no-wrap">
                            <label>C/O | 3rd Party</label>
                            <select
                              name="customer_name"
                              id="customer_name_assets"
                              onChange={(e) => setFormValue(e)}
                            >
                              <option value="">Select</option>
                              {insuranceData !== null &&
                              insuranceData !== undefined
                                ? insuranceData.map((insurance) => {
                                    return (
                                      <option
                                        key={insurance.id}
                                        value={insurance.id}
                                      >
                                        {insurance.company_name}
                                      </option>
                                    );
                                  })
                                : ""}
                            </select>
                          </div>
                          <div className="select-block custom-normal-select d-flex align-center no-wrap">
                            <label>Request | Claim</label>
                            <Form.Group className="form-group">
                              <Form.Control
                                type="text"
                                id="request_claim"
                                name="request_claim"
                                onKeyUp={(e) => setFormValue(e)}
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="tab-wrapper jobs-description">
                        <div className="form-outer">
                          <div className="form-group custom-normal-select d-flex align-center mb-3">
                            <label>Job Department</label>
                            <Form.Select
                              aria-label="Default select example"
                              name="job_department"
                              id="job_department"
                              onChange={getJobType}
                              required
                            >
                              <option value="">Select Job Department</option>
                              {jobDepartmentData !== null &&
                              jobDepartmentData !== undefined
                                ? jobDepartmentData.map((jobDept) => {
                                    return (
                                      <option
                                        key={jobDept.id}
                                        value={jobDept.id}
                                        data-id={jobDept.job_type_parent_id}
                                      >
                                        {jobDept.name}
                                      </option>
                                    );
                                  })
                                : ""}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Required
                            </Form.Control.Feedback>
                          </div>
                          <div className="form-group custom-normal-select d-flex align-center mb-3">
                            <label>Job Type</label>
                            <Form.Select
                              aria-label="Default select example"
                              name="job_type"
                              id="job_type"
                              required
                              onChange={(e) => setFormValue(e)}
                            >
                              <option value="">Select Job Type</option>
                              {filterJobType !== null &&
                              filterJobType.length !== 0
                                ? filterJobType.map((jobType) => {
                                    return (
                                      <option
                                        key={jobType.id}
                                        value={jobType.id}
                                        data-id={jobType.job_type_parent_id}
                                      >
                                        {jobType.name}
                                      </option>
                                    );
                                  })
                                : ""}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Required
                            </Form.Control.Feedback>
                          </div>
                          <div className="select-block select-wrapper d-flex justify-space-between">
                            <Form.Group className="form-group mb-3">
                              <label>Job Description</label>
                              <Select
                                className="form-control custom-select"
                                name="script_name"
                                id="script_name"
                                options={jobCannedResp}
                                onChange={handleCannedScript}
                              />
                            </Form.Group>
                          </div>
                          <Form.Group className="form-group mb-3">
                            <label>Description</label>
                            <Editor
                              name="script_description"
                              id="script_description"
                              editorState={editorState}
                              wrapperClassName="wrapper-class"
                              editorClassName="editor-class"
                              onEditorStateChange={onEditorStateChange}
                            />
                          </Form.Group>
                          <div className="date-time-wrapper d-flex justify-space-between  mb-3">
                            <Form.Group className="form-group width-33 time-input">
                              <label>Job Estimate Time</label>
                              <Form.Control
                                type="time"
                                defaultValue={slotDuration}
                                name="job_estimate_time"
                                id="job_estimate_time"
                              />
                              <span>Hrs</span>
                            </Form.Group>
                            <Form.Group className="form-group date-picker width-33">
                              <label>Select Pickup Date</label>
                              <DatePicker
                                className="form-control"
                                id="pickup_time"
                                name="pickup_time"
                                defaultValue={moment()}
                                format={date_format}
                              />{" "}
                            </Form.Group>
                            <Form.Group className="form-group time-picker width-33">
                              <label>Select Pickup Time</label>
                              <Form.Control
                                type="time"
                                defaultValue="00:00"
                                name="am_hours"
                                id="am_hours"
                              />
                            </Form.Group>
                          </div>
                          <div className="select-options d-flex justify-space-between">
                            <div className="multi-select-wrapper">
                              <span>Job Tags</span>
                              <CreatableSelect
                                defaultValue={[]}
                                isMulti
                                name="jobTags"
                                options={jobTagsData}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={handleTagsChange}
                              />
                            </div>
                            <div className="radio-wrapper">
                              <label>Customer Options</label>
                              <Form.Check
                                className="custom-check radio-btn secondary-radio mb-2"
                                type="radio"
                                id="1"
                              >
                                <Form.Check.Input
                                  name="customer_type"
                                  type="radio"
                                  isValid
                                  value="1"
                                  onClick={(e) => setFormValue(e)}
                                />
                                <Form.Check.Label>
                                  Waiting Instore
                                  <span className="check"></span>
                                </Form.Check.Label>
                              </Form.Check>
                              <Form.Check
                                className="custom-check radio-btn secondary-radio mb-2"
                                type="radio"
                                id="2"
                              >
                                <Form.Check.Input
                                  name="customer_type"
                                  type="radio"
                                  isValid
                                  value="2"
                                  onClick={(e) => setFormValue(e)}
                                />
                                <Form.Check.Label>
                                  Loan Vehicle
                                  <span className="check"></span>
                                </Form.Check.Label>
                              </Form.Check>
                              <Form.Check
                                className="custom-check radio-btn secondary-radio mb-2"
                                type="radio"
                                id="3"
                              >
                                <Form.Check.Input
                                  name="customer_type"
                                  type="radio"
                                  isValid
                                  value="3"
                                  onClick={(e) => setFormValue(e)}
                                />
                                <Form.Check.Label>
                                  Pickup / Drop Off
                                  <span className="check"></span>
                                </Form.Check.Label>
                              </Form.Check>
                              <Form.Check
                                className="custom-check radio-btn secondary-radio mb-2"
                                type="radio"
                                id="4"
                              >
                                <Form.Check.Input
                                  name="customer_type"
                                  type="radio"
                                  isValid
                                  value="4"
                                  onClick={(e) => setFormValue(e)}
                                />
                                <Form.Check.Label>
                                  Ride Sharing
                                  <span className="check"></span>
                                </Form.Check.Label>
                              </Form.Check>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="modal-footer">
                      <Button
                        variant="outline-secondary"
                        className={
                          "btn-outline rounded-btn " +
                          (activeStep === false ? "" : "hide")
                        }
                        onClick={() => {
                          clearModalData();
                          close();
                        }}
                      >
                        Cancel
                      </Button>
                      {activeStep ? (
                        <Button
                          variant="outline-secondary"
                          className="btn-outline rounded-btn"
                          onClick={ChangeActiveStep}
                        >
                          <i className="fa fa-arrow-left m-r-5"></i> Back
                        </Button>
                      ) : (
                        <Button
                          className={
                            "rounded-btn btn-primary " + customerVehicleFlag
                          }
                          variant="primary"
                          onClick={ChangeActiveStep}
                        >
                          Next
                        </Button>
                      )}
                      <Button
                        type="submit"
                        className={
                          "rounded-btn btn-success " +
                          (activeStep === false ? "hide" : "")
                        }
                        variant="success"
                        // onClick={handleShow}
                      >
                        Save
                      </Button>
                    </div>
                  </Form>
                </Tab>
                <Tab eventKey="quotes" title="Quotes">
                  <Form id="quoteToJobCreate">
                    <div className="tab-wrapper mb-3">
                      <div className="search-wrapper full-width">
                        <div className="input-wrapper">
                          <i className="fa fa-search search-icon"></i>
                          <Form.Group className="form-group mb-3">
                            <Form.Control
                              type="text"
                              placeholder="Convert your Quote to a Job and Schedule it in one simple step!"
                              onKeyUp={getSearchQuote}
                            />
                          </Form.Group>
                        </div>
                        <ul className="search-list">
                          <Scrollbars
                            autoHeight
                            autoHeightMax={250}
                            className="custom-scrollbar"
                            style={{ width: "100%" }}
                            renderThumbVertical={(props) => (
                              <div
                                {...props}
                                className="thumb-vertical black-thumb"
                              />
                            )}
                            renderTrackHorizontal={(props) => (
                              <div {...props} style={{ display: "none" }} />
                            )}
                          >
                            {quoteList.length !== 0
                              ? quoteList.map((quote) => {
                                  return (
                                    <li
                                      key={quote.id}
                                      className={
                                        quote.id === selectedQuoteId
                                          ? "active"
                                          : ""
                                      }
                                    >
                                      <Link
                                        to="#"
                                        onClick={(e) => {
                                          getQuoteDetails(quote.id);
                                        }}
                                      >
                                        <strong>Quote </strong>
                                        {quote.quote_number}
                                        <span> | </span>
                                        <strong>Rego </strong>
                                        {quote.vehicle.rego}
                                      </Link>
                                    </li>
                                  );
                                })
                              : ""}
                          </Scrollbars>
                        </ul>
                      </div>
                      <div className="quote-detail-wrapper d-flex justify-space-between">
                        <div className="quote-detail">
                          <h3>Quote Details</h3>
                          <div className="quote-block-outer d-flex justify-space-between">
                            <div className="quote-block width-33">
                              {makeQuote}
                            </div>
                            <div className="quote-block width-33">
                              {modelQuote}
                            </div>
                            <div className="quote-block width-33">
                              {yearQuote}
                            </div>
                          </div>
                          <div className="quote-block-outer d-flex justify-space-between">
                            <div className="quote-block width-50">
                              {jobDeptQuote}
                            </div>
                            <div className="quote-block width-50">
                              {jobTypeQuote}
                            </div>
                          </div>
                        </div>
                        <div className="quote-detail">
                          <div className="quote-block-outer d-flex justify-space-between">
                            <div className="quote-block description-block d-flex justify-center align-center">
                              {descQuote}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <Button
                        variant="outline-secondary"
                        className={"btn-outline rounded-btn"}
                        onClick={close}
                      >
                        Cancel
                      </Button>
                      <Button
                        className={
                          "rounded-btn btn-success " + quoteSelectedFlag
                        }
                        variant="success"
                        onClick={() => {
                          handleQuoteSubmitForm();
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </Form>
                </Tab>
                <Tab eventKey="activities" title="Activities">
                  {/* <Form ref={formRef} onSubmit={handleActivitySubmitForm} noValidate validated={activityValidated}> */}
                  <Form>
                    <div className="tab-wrapper mb-3">
                      <div className="activity-wrapper d-flex justify-space-between">
                        <ul className="activity-list d-flex">
                          {activityData !== null && activityData !== undefined
                            ? activityData.map((activity, index) => {
                                var activityType = activity.type;
                                if (activity.type === "1") {
                                  activityType = "Meeting";
                                } else if (activity.type === "2") {
                                  activityType = "Holiday";
                                } else if (activity.type === "3") {
                                  activityType = "Job";
                                }
                                return (
                                  <>
                                    <li
                                      key={activity.id}
                                      onClick={() => {
                                        setActionType(activityType);
                                        setActivityId(activity.id);
                                      }}
                                    >
                                      <Form.Check
                                        className="radio-btn mb-2"
                                        type="radio"
                                        id={activity.id}
                                      >
                                        <Form.Check.Input
                                          name="activity"
                                          type="radio"
                                          isValid
                                          value={activity.name}
                                          required
                                        />
                                        <Form.Check.Label>
                                          {activity.name}
                                          <span className="check"></span>
                                        </Form.Check.Label>
                                      </Form.Check>
                                    </li>
                                  </>
                                );
                              })
                            : ""}
                        </ul>
                        <Form.Control.Feedback type="invalid">
                          Required
                        </Form.Control.Feedback>
                        <div className="add-activity">
                          <Button
                            className="btn-primary rounded-btn activity-btn"
                            onClick={() => setOpenCreateActivity(true)}
                          >
                            <span>+</span> Activity{" "}
                          </Button>
                        </div>
                      </div>
                      {openCreateActivity === true ? (
                        <div className="add-activity d-flex">
                          <Form.Group className="form-group mb-3">
                            <Form.Control
                              type="text"
                              placeholder="Activity Name"
                              onChange={(e) => setActivityName(e.target.value)}
                            />
                            <div className="btn-wrapper">
                              <Button
                                className="btn-default"
                                onClick={() => setOpenCreateActivity(false)}
                              >
                                Cancel
                              </Button>
                              <Button
                                className="btn-success"
                                variant="success"
                                onClick={() => saveCreateActivity()}
                              >
                                Save
                              </Button>
                            </div>
                          </Form.Group>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="search-wrapper full-width">
                        <div className="input-wrapper">
                          <i className="fa fa-search search-icon"></i>
                          <Form.Group className="form-group mb-3">
                            <Form.Control
                              type="text"
                              placeholder="Search for an Activity"
                              onKeyUp={getSearchActivity}
                            />
                          </Form.Group>
                        </div>
                        <ul className="search-list with-close-icon">
                          <Scrollbars
                            autoHeight
                            autoHeightMax={250}
                            className="custom-scrollbar"
                            style={{ width: "100%" }}
                            renderThumbVertical={(props) => (
                              <div
                                {...props}
                                className="thumb-vertical black-thumb"
                              />
                            )}
                            renderTrackHorizontal={(props) => (
                              <div {...props} style={{ display: "none" }} />
                            )}
                          >
                            {activityScheduleList !== null &&
                            activityScheduleList !== undefined
                              ? activityScheduleList.map(
                                  (activitySchedule, index) => {
                                    return (
                                      <>
                                        <li
                                          key={activitySchedule.name}
                                          className={
                                            activitySchedule.id ===
                                            selectedActivitySchedule
                                              ? "active"
                                              : ""
                                          }
                                          onClick={() => {
                                            setSelectedActivitySchedule(
                                              activitySchedule.id
                                            );
                                            setSelectedActivityName(
                                              activitySchedule.name
                                            );
                                            setSelectedActivityDescription(
                                              activitySchedule.description
                                            );
                                          }}
                                        >
                                          <Link
                                            to="#"
                                            className="d-flex justify-space-between"
                                          >
                                            <span>{activitySchedule.name}</span>
                                            <i
                                              className="font-size-16 fa fa-close"
                                              onClick={() =>
                                                handleDeleteOpen(
                                                  activitySchedule.id
                                                )
                                              }
                                            ></i>
                                          </Link>
                                        </li>
                                      </>
                                    );
                                  }
                                )
                              : ""}
                          </Scrollbars>
                        </ul>
                        <div className="multi-select-wrapper">
                          <span>Employees *</span>
                          <Select
                            // defaultValue={activityEmployee}
                            isMulti
                            name="employees"
                            value={defaultActivity}
                            options={activityEmployee}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(e) => {
                              handleEmployeeChange(e);
                              setDefaultActivity(e);
                            }}
                          />
                        </div>
                        <div className="description">
                          <span>
                            Description - This activity description will display
                            in the schedule
                          </span>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            value={selectedActivityDescription}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <Button
                        variant="outline-secondary"
                        className={"btn-outline rounded-btn "}
                        onClick={close}
                      >
                        Cancel
                      </Button>
                      <Button
                        // type="submit"
                        className={"rounded-btn btn-success "}
                        variant="success"
                        onClick={() => saveActivity()}
                      >
                        Save
                      </Button>
                    </div>
                  </Form>
                </Tab>
              </Tabs>
            </div>
          </Modal.Body>
        </Modal>
      ) : manageStateModal === "child" ? (
        <CustomerAndVehicle
          showModal={showCustomersAndVehiclesModal}
          setShowModal={setShowCustomersAndVehiclesModal}
          selectedUser={selectedUser}
          setManageStateModal={setManageStateModal}
          customerFormType={customerFormType}
          setCustomerDetails={setCustomerDetails}
          setVehicleDetails={setVehicleDetails}
        />
      ) : (
        ""
      )}
      {manageStateModal === "save" ? (
        <Modal
          size="md"
          centered
          className="option-after-jobCreate"
          show={showJob}
          onHide={handleClose}
        >
          <Modal.Header>
            <Modal.Title className="d-flex justify-space-between">
              Awesome! You have created a Job.
            </Modal.Title>
            <Button type="button" className="close-btn" onClick={handleClose}>
              <i className="la la-times"></i>{" "}
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-center">
              <Button
                className={"rounded-btn btn-success "}
                variant="success"
                onClick={handleSchedule}
              >
                View Schedule
              </Button>
              <Button
                className={"rounded-btn btn-success "}
                variant="success"
                onClick={handleViewJob}
              >
                View Job
              </Button>
            </div>
            <div className="d-flex justify-center">
              <Button
                className={"rounded-btn btn-success "}
                variant="success"
                onClick={handleDashboard}
              >
                Back To Dashboard
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        ""
      )}
      {showDeleteModal && (
        <DeleteConfirm
          handleDeleteClose={() => handleDeleteClose()}
          deleteRecord={() => deleteActivitySchedule()}
          msg={"Are you sure?"}
        />
      )}
    </>
  );
};

export default CreateJob;
