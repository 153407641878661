export const GET_TIMEZONE = "GET_TIMEZONE";
export const GET_TIMEZONE_SUCCESS = "GET_TIMEZONE_SUCCESS";
export const GET_TIMEZONE_FAIL = "GET_TIMEZONE_FAIL";

export const GET_WORKSHOP_DETAIL = "GET_WORKSHOP_DETAIL";
export const GET_WORKSHOP_DETAIL_SUCCESS = "GET_WORKSHOP_DETAIL_SUCCESS";
export const GET_WORKSHOP_DETAIL_FAIL = "GET_WORKSHOP_DETAIL_FAIL";

export const GET_WORKSHOP_OTHER_DETAIL = "GET_WORKSHOP_OTHER_DETAIL";
export const GET_WORKSHOP_OTHER_DETAIL_SUCCESS =
  "GET_WORKSHOP_OTHER_DETAIL_SUCCESS";
export const GET_WORKSHOP_OTHER_DETAIL_FAIL = "GET_WORKSHOP_OTHER_DETAIL_FAIL";

export const UPDATE_COMPANY_DETAIL = "UPDATE_COMPANY_DETAIL";
export const UPDATE_COMPANY_DETAIL_SUCCESS = "UPDATE_COMPANY_DETAIL_SUCCESS";
export const UPDATE_COMPANY_DETAIL_FAIL = "UPDATE_COMPANY_DETAIL_FAIL";

export const UPDATE_COMPANY_ACN_DETAIL = "UPDATE_COMPANY_ACN_DETAIL";
export const UPDATE_COMPANY_ACN_DETAIL_SUCCESS =
  "UPDATE_COMPANY_ACN_DETAIL_SUCCESS";
export const UPDATE_COMPANY_ACN_DETAIL_FAIL = "UPDATE_COMPANY_ACN_DETAIL_FAIL";

export const UPDATE_COMPANY_OTHER_DETAIL = "UPDATE_COMPANY_OTHER_DETAIL";
export const UPDATE_COMPANY_OTHER_DETAIL_SUCCESS =
  "UPDATE_COMPANY_OTHER_DETAIL_SUCCESS";
export const UPDATE_COMPANY_OTHER_DETAIL_FAIL =
  "UPDATE_COMPANY_OTHER_DETAIL_FAIL";

export const UPDATE_WORKSHOP_MARKUP_DETAIL = "UPDATE_WORKSHOP_MARKUP_DETAIL";
export const UPDATE_WORKSHOP_MARKUP_DETAIL_SUCCESS =
  "UPDATE_WORKSHOP_MARKUP_DETAIL_SUCCESS";
export const UPDATE_WORKSHOP_MARKUP_DETAIL_FAIL =
  "UPDATE_WORKSHOP_MARKUP_DETAIL_FAIL";

export const UPDATE_DEFAULT_WORKSHOP_MARKUP_DETAIL =
  "UPDATE_DEFAULT_WORKSHOP_MARKUP_DETAIL";

export const DELETE_WORKSHOP_MARKUP = "DELETE_WORKSHOP_MARKUP";
export const DELETE_WORKSHOP_MARKUP_SUCCESS = "DELETE_WORKSHOP_MARKUP_SUCCESS";
export const DELETE_WORKSHOP_MARKUP_FAIL = "DELETE_WORKSHOP_MARKUP_FAIL";

export const GET_WORKSHOP_SMS = "GET_WORKSHOP_SMS";
export const GET_WORKSHOP_SMS_SUCCESS = "GET_WORKSHOP_SMS_SUCCESS";
export const GET_WORKSHOP_SMS_FAIL = "GET_WORKSHOP_SMS_FAIL";

export const GET_WORKSHOP_MARKUP_DETAIL = "GET_WORKSHOP_MARKUP_DETAIL";
export const GET_WORKSHOP_MARKUP_DETAIL_SUCCESS =
  "GET_WORKSHOP_MARKUP_DETAIL_SUCCESS";
export const GET_WORKSHOP_MARKUP_DETAIL_FAIL =
  "GET_WORKSHOP_MARKUP_DETAIL_FAIL";

export const UPDATE_WORKSHOP_SMS = "UPDATE_WORKSHOP_SMS";
export const UPDATE_WORKSHOP_SMS_SUCCESS = "UPDATE_WORKSHOP_SMS_SUCCESS";
export const UPDATE_WORKSHOP_SMS_FAIL = "UPDATE_WORKSHOP_SMS_FAIL";
