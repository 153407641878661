import { takeLatest, put, call } from "redux-saga/effects";
import { GET_GLOBAL_SEARCH } from "./actionTypes";
import {
  getGlobalSearchSuccess,
  getGlobalSearchFail
} from "./actions";

import { getGlobalSearch } from "../../helpers/backendHelper";

function* onGetGlobalSearch(data) {
  try {
    const response = yield call(getGlobalSearch, data);
    if(response){
      if(response.code===200){
        yield put(getGlobalSearchSuccess(response));
      }else{
        yield put(getGlobalSearchFail(response.message));
      }
    }else{
      yield put(getGlobalSearchFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(getGlobalSearchFail(error.response));
  }
}

function* GlobalSearchSaga() {
  yield takeLatest(GET_GLOBAL_SEARCH, onGetGlobalSearch);
}

export default GlobalSearchSaga;
