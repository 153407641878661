import { takeLatest, put, call } from "redux-saga/effects";
import { 
        SAVE_QUOTE_PART, GET_QUOTE_PART_LIST, DELETE_QUOTE_PART, UPDATE_QUOTE_PART, 
        INLINE_UPDATE_QUOTE_PART, EDIT_QUOTE_PART_MANY, GET_QUOTE_KIT_PART, 
        GET_QUOTE_KIT_LABOUR, SAVE_QUOTE_KIT_PART, DELETE_QUOTE_KIT_PART, 
        INLINE_UPDATE_QUOTE_KIT_PART, EDIT_QUOTE_KIT_PART_MANY, EDIT_QUOTE_KIT_LABOUR_MANY,
        INLINE_UPDATE_QUOTE_KIT_LABOUR
      } from "./actionTypes";
import {
  saveQuotePartSuccess,
  saveQuotePartFail,
  getQuotePartListSuccess,
  getQuotePartListFail,
  deleteQuotePartSuccess,
  deleteQuotePartFail,
  updateQuotePartSuccess,
  updateQuotePartFail,
  inlineUpdateQuotePartSuccess,
  inlineUpdateQuotePartFail,
  editQuotePartManySuccess,
  editQuotePartManyFail,
  getQuoteKitPartSuccess,
  getQuoteKitPartFail,
  getQuoteKitLabourSuccess,
  getQuoteKitLabourFail,
  saveQuoteKitPartSuccess,
  saveQuoteKitPartFail,
  deleteQuoteKitPartSuccess,
  deleteQuoteKitPartFail,
  inlineUpdateQuoteKitPartSuccess,
  inlineUpdateQuoteKitPartFail,
  editQuoteKitPartManySuccess,
  editQuoteKitPartManyFail,
  editQuoteKitLabourManySuccess,
  editQuoteKitLabourManyFail,
  inlineUpdateQuoteKitLabourSuccess,
  inlineUpdateQuoteKitLabourFail
} from "./actions";

import { saveQuotePart, getQuotePartList , deleteQuotePart, 
        updateQuotePart, editQuotePartMany, getQuoteKitPart, getQuoteKitLabour, 
        saveQuoteKitPart, deleteQuoteKitPart, editQuoteKitPartMany, editQuoteKitLabourMany 
      } from "../../helpers/backendHelper";
import { showError, showSuccess } from "../../utils/alertMessage";

function* onGetSaveQuotePart(data) {
  try {
    const response = yield call(saveQuotePart, data);
    
    if(response){
      if(response.code===200){
        yield put(saveQuotePartSuccess(response.data.CustomerQuotesParts));
        showSuccess("Quote part added successfully");
      }else{
        yield put(saveQuotePartFail(response.message));
        showError(response.message);
      }
    }else{
      yield put(saveQuotePartFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(saveQuotePartFail(error.response));
  }
}


function* onGetQuotePartList(data) {
  try {
    const response = yield call(getQuotePartList, data);
    if(response){
      if(response.code===200){
        yield put(getQuotePartListSuccess(response.data.CustomerQuotesParts));
      }else{
        yield put(getQuotePartListFail(response.message));
      }
    }else{
      yield put(getQuotePartListFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(getQuotePartListFail(error.response));
  }
}

// delete job part START
function* onGetDeleteQuotePart(data) {
  try {
    const response = yield call(deleteQuotePart, data);
    if(response){
      if(response.code===200){
        yield put(deleteQuotePartSuccess(response.data.CustomerQuotesParts));
        showSuccess("Quote part deleted successfully");
      }else{
        yield put(deleteQuotePartFail(response.message));
        showError(response.message);
      }
    }else{
      yield put(deleteQuotePartFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(deleteQuotePartFail(error.response));
  }
}
// delete job part END

function* onGetUpdateQuotePart(data) {
  try {
    const response = yield call(updateQuotePart, data);
    
    if(response){
      if(response.code===200){
        yield put(updateQuotePartSuccess(response.data.CustomerQuotesParts));
        showSuccess("Quote part updated successfully");
      }else{
        yield put(updateQuotePartFail(response.message));
        showError(response.message);
      }
    }else{
      yield put(updateQuotePartFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(updateQuotePartFail(error.response));
  }
}

function* inlineUpdateQuotePart(data) {
  try {
    yield put(inlineUpdateQuotePartSuccess(data.payload));
  } catch (error) {
    yield put(inlineUpdateQuotePartFail(error.response));
  }
}

function* onEditQuotePartMany(data) {
  try {
    const response = yield call(editQuotePartMany, data);
    
    if(response){
      if(response.code===200){
        yield put(editQuotePartManySuccess(response.data.CustomerQuotesParts));
        showSuccess("Quote part updated successfully");
      }else{
        yield put(editQuotePartManyFail(response.message));
        showError(response.message);
      }
    }else{
      yield put(editQuotePartManyFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(editQuotePartManyFail(error.response));
  }
}

function* onGetQuoteKitPart(data) {
  try {
    const response = yield call(getQuoteKitPart, data);
    
    if(response){
      if(response.code===200){
        yield put(getQuoteKitPartSuccess(response.data.QuoteKitParts));
        
      }else{
        yield put(getQuoteKitPartFail(response.message));
        showError(response.message);
      }
    }else{
      yield put(getQuoteKitPartFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(getQuoteKitPartFail(error.response));
  }
}


function* onGetQuoteKitLabour(data) {
  try {
    const response = yield call(getQuoteKitLabour, data);
    
    if(response){
      if(response.code===200){
        yield put(getQuoteKitLabourSuccess(response.data.QuoteKitLabours));
        
      }else{
        yield put(getQuoteKitLabourFail(response.message));
        showError(response.message);
      }
    }else{
      yield put(getQuoteKitLabourFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(getQuoteKitLabourFail(error.response));
  }
}

function* onSaveQuoteKitPart(data) {
  try {
    const response = yield call(saveQuoteKitPart, data);
    
    if(response){
      if(response.code===200){
        yield put(saveQuoteKitPartSuccess(response.data.QuoteKitParts));
        showSuccess("Quote kit part added successfully");
      }else{
        yield put(saveQuoteKitPartFail(response.message));
        showError(response.message);
      }
    }else{
      yield put(saveQuoteKitPartFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(saveQuoteKitPartFail(error.response));
  }
}


function* onDeleteQuoteKitPart(data) {
  try {
    const response = yield call(deleteQuoteKitPart, data);
    
    if(response){
      if(response.code===200){
        yield put(deleteQuoteKitPartSuccess(response.data.QuoteKitParts));
        showSuccess("Quote kit part deleted successfully");
      }else{
        yield put(deleteQuoteKitPartFail(response.message));
        showError(response.message);
      }
    }else{
      yield put(deleteQuoteKitPartFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(deleteQuoteKitPartFail(error.response));
  }
}


function* inlineUpdateQuoteKitPart(data) {
  try {
    yield put(inlineUpdateQuoteKitPartSuccess(data.payload));
  } catch (error) {
    yield put(inlineUpdateQuoteKitPartFail(error.response));
  }
}


function* onEditQuoteKitPartMany(data) {
  try {
    const response = yield call(editQuoteKitPartMany, data);
    
    if(response){
      if(response.code===200){
        yield put(editQuoteKitPartManySuccess(response.data.CustomerQuotesParts));
        
      }else{
        yield put(editQuoteKitPartManyFail(response.message));
        showError(response.message);
      }
    }else{
      yield put(editQuoteKitPartManyFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(editQuoteKitPartManyFail(error.response));
  }
}


function* onEditQuoteKitLabourMany(data) {
  try {
    const response = yield call(editQuoteKitLabourMany, data);
    
    if(response){
      if(response.code===200){
        yield put(editQuoteKitLabourManySuccess(response.data.CustomerQuotesParts));
        showSuccess("Quote kit part updated successfully");
      }else{
        yield put(editQuoteKitLabourManyFail(response.message));
        showError(response.message);
      }
    }else{
      yield put(editQuoteKitLabourManyFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(editQuoteKitLabourManyFail(error.response));
  }
}


function* inlineUpdateQuoteKitLabour(data) {
  try {
    yield put(inlineUpdateQuoteKitLabourSuccess(data.payload));
  } catch (error) {
    yield put(inlineUpdateQuoteKitLabourFail(error.response));
  }
}

function* QuotePartSaga() {
  yield takeLatest(SAVE_QUOTE_PART, onGetSaveQuotePart);
  yield takeLatest(GET_QUOTE_PART_LIST, onGetQuotePartList);
  yield takeLatest(DELETE_QUOTE_PART, onGetDeleteQuotePart);
  yield takeLatest(UPDATE_QUOTE_PART, onGetUpdateQuotePart);
  yield takeLatest(INLINE_UPDATE_QUOTE_PART, inlineUpdateQuotePart);
  yield takeLatest(EDIT_QUOTE_PART_MANY, onEditQuotePartMany);
  yield takeLatest(GET_QUOTE_KIT_PART, onGetQuoteKitPart);
  yield takeLatest(GET_QUOTE_KIT_LABOUR, onGetQuoteKitLabour);
  yield takeLatest(SAVE_QUOTE_KIT_PART, onSaveQuoteKitPart);
  yield takeLatest(DELETE_QUOTE_KIT_PART, onDeleteQuoteKitPart);
  yield takeLatest(INLINE_UPDATE_QUOTE_KIT_PART, inlineUpdateQuoteKitPart);
  yield takeLatest(EDIT_QUOTE_KIT_PART_MANY, onEditQuoteKitPartMany);
  yield takeLatest(EDIT_QUOTE_KIT_LABOUR_MANY, onEditQuoteKitLabourMany);
  yield takeLatest(INLINE_UPDATE_QUOTE_KIT_LABOUR, inlineUpdateQuoteKitLabour);
}

export default QuotePartSaga;
