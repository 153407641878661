import {
  GET_WORKSHOP_BAYS_DETAILS,
  GET_WORKSHOP_BAYS_DETAILS_SUCCESS,
  GET_WORKSHOP_BAYS_DETAILS_FAIL,
  ADD_WORKSHOP_BAYS_DETAILS,
  ADD_WORKSHOP_BAYS_DETAILS_SUCCESS,
  ADD_WORKSHOP_BAYS_DETAILS_FAIL,
  EDIT_WORKSHOP_BAYS_DETAILS,
  EDIT_WORKSHOP_BAYS_DETAILS_SUCCESS,
  EDIT_WORKSHOP_BAYS_DETAILS_FAIL,
  UPDATE_WORKSHOP_BAYS_DETAILS,
  UPDATE_WORKSHOP_BAYS_DETAILS_SUCCESS,
  UPDATE_WORKSHOP_BAYS_DETAILS_FAIL,
  DELETE_WORKSHOP_BAYS_DETAILS,
  DELETE_WORKSHOP_BAYS_DETAILS_SUCCESS,
  DELETE_WORKSHOP_BAYS_DETAILS_FAIL,
  CHECK_NAME_WORKSHOP_BAYS_DETAILS,
  CHECK_NAME_WORKSHOP_BAYS_DETAILS_SUCCESS,
  CHECK_NAME_WORKSHOP_BAYS_DETAILS_FAIL,
  CHECK_ORDER_WORKSHOP_BAYS_DETAILS,
  CHECK_ORDER_WORKSHOP_BAYS_DETAILS_SUCCESS,
  CHECK_ORDER_WORKSHOP_BAYS_DETAILS_FAIL,
} from "./actionTypes";

export const getWorkShopBaysDetail = (data) => {
  return {
    type: GET_WORKSHOP_BAYS_DETAILS,
    payload: data,
  };
};

export const getWorkShopBaysSuccess = (data) => {
  return {
    type: GET_WORKSHOP_BAYS_DETAILS_SUCCESS,
    payload: data.data,
    message: data.message,
  };
};

export const getWorkShopBaysFail = (error) => {
  return {
    type: GET_WORKSHOP_BAYS_DETAILS_FAIL,
    payload: error,
  };
};

export const addWorkShopBaysDetail = () => {
  return {
    type: ADD_WORKSHOP_BAYS_DETAILS,
  };
};

export const addWorkShopBaysSuccess = (data) => {
  return {
    type: ADD_WORKSHOP_BAYS_DETAILS_SUCCESS,
    payload: data.data,
    message: data.message,
  };
};

export const addWorkShopBaysFail = (error) => {
  return {
    type: ADD_WORKSHOP_BAYS_DETAILS_FAIL,
    payload: error,
  };
};

export const checkNameWorkShopBaysDetail = () => {
  return {
    type: CHECK_NAME_WORKSHOP_BAYS_DETAILS,
  };
};

export const checkNameWorkShopBaysSuccess = (data) => {
  return {
    type: CHECK_NAME_WORKSHOP_BAYS_DETAILS_SUCCESS,
    payload: data,
    message: data.message,
  };
};

export const checkNameWorkShopBaysFail = (error) => {
  return {
    type: CHECK_NAME_WORKSHOP_BAYS_DETAILS_FAIL,
    payload: error,
  };
};

export const checkOrderWorkShopBaysDetail = () => {
  return {
    type: CHECK_ORDER_WORKSHOP_BAYS_DETAILS,
  };
};

export const checkOrderWorkShopBaysSuccess = (data) => {
  return {
    type: CHECK_ORDER_WORKSHOP_BAYS_DETAILS_SUCCESS,
    payload: data,
    message: data.message,
  };
};

export const checkOrderWorkShopBaysFail = (error) => {
  return {
    type: CHECK_ORDER_WORKSHOP_BAYS_DETAILS_FAIL,
    payload: error,
  };
};

export const editWorkShopBaysDetail = () => {
  return {
    type: EDIT_WORKSHOP_BAYS_DETAILS,
  };
};

export const editWorkShopBaysSuccess = (data) => {
  return {
    type: EDIT_WORKSHOP_BAYS_DETAILS_SUCCESS,
    payload: data.data,
    message: data.message,
  };
};

export const editWorkShopBaysFail = (error) => {
  return {
    type: EDIT_WORKSHOP_BAYS_DETAILS_FAIL,
    payload: error,
  };
};

export const updateWorkShopBaysDetail = (data) => {
  return {
    type: UPDATE_WORKSHOP_BAYS_DETAILS,
    payload: data,
  };
};

export const updateWorkShopBaysSuccess = (data) => {
  return {
    type: UPDATE_WORKSHOP_BAYS_DETAILS_SUCCESS,
    payload: data.data,
    message: data.message,
  };
};

export const updateWorkShopBaysFail = (error) => {
  return {
    type: UPDATE_WORKSHOP_BAYS_DETAILS_FAIL,
    payload: error,
  };
};

export const deleteWorkShopBaysDetail = () => {
  return {
    type: DELETE_WORKSHOP_BAYS_DETAILS,
  };
};

export const deleteWorkShopBaysSuccess = (data) => {
  return {
    type: DELETE_WORKSHOP_BAYS_DETAILS_SUCCESS,
    payload: data.data,
    message: data.message,
  };
};

export const deleteWorkShopBaysFail = (error) => {
  return {
    type: DELETE_WORKSHOP_BAYS_DETAILS_FAIL,
    payload: error,
  };
};
