import * as ACTION from "./actionTypes";

const initialState = {
  scheduledMarketingList: {
    loading: false,
    error: "",
    list: [],
    count: 0,
    marketingCount: 0,
    saleInvoiceCount: 0,
    marketingDeleteStatus: "",
  },
  liveMarketingList: {
    loading: false,
    error: "",
    list: [],
    count: 0,
    marketingCount: 0,
    saleInvoiceCount: 0,
    marketingDeleteStatus: "",
  },
  completedMarketingList: {
    loading: false,
    error: "",
    list: [],
    count: 0,
    marketingCount: 0,
    saleInvoiceCount: 0,
    marketingDeleteStatus: "",
  },
  createMarketingList: {
    loading: false,
    message: "",
    error: "",
    list: [],
    count: 0,
    marketingData: null,
    createMarketing: null,
    marketingCount: 0,
  },
};

const marketingListReducers = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.GET_SCHEDULED_MARKETING_LIST:
      return {
        ...state,
        scheduledMarketingList: {
          ...state.scheduledMarketingList,
          loading: true,
        },
      };
    case ACTION.GET_SCHEDULED_MARKETING_LIST_SUCCESS:
      return {
        ...state,
        scheduledMarketingList: {
          ...state.scheduledMarketingList,
          ...action.payload,
          loading: false,
        },
      };
    case ACTION.GET_SCHEDULED_MARKETING_LIST_COUNT:
      return {
        ...state,
        scheduledMarketingList: {
          ...state.scheduledMarketingList,
          loading: true,
        },
      };
    case ACTION.GET_SCHEDULED_MARKETING_LIST_COUNT_SUCCESS:
      return {
        ...state,
        scheduledMarketingList: {
          ...state.scheduledMarketingList,
          ...action.payload,
          loading: false,
        },
      };
    case ACTION.GET_SCHEDULED_MARKETING_LIST_COUNT_FAIL:
      return {
        ...state,
        scheduledMarketingList: {
          ...state.scheduledMarketingList,
          error: action.payload,
          loading: false,
        },
      };

    case ACTION.GET_SCHEDULED_MARKETING_LIST_FAIL:
      return {
        ...state,
        scheduledMarketingList: {
          ...state.scheduledMarketingList,
          error: action.payload,
          loading: false,
        },
      };

    // CREATE MARKETING LIST

    case ACTION.CREATE_MARKETING:
      return {
        ...state,
        createMarketingList: {
          ...state.createMarketingList,
          loading: true,
        },
      };
    case ACTION.CREATE_MARKETING_SUCCESS:
      return {
        ...state,
        createMarketingList: {
          ...state.createMarketingList,
          loading: false,
          createMarketing: action.payload,
        },
      };
    case ACTION.CREATE_MARKETING_FAIL:
      return {
        ...state,
        createMarketingList: {
          ...state.createMarketingList,
          loading: false,
          createMarketing: null,
          error: {
            message: action.payload,
          },
        },
      };

    // CREATE MARKETING LIST

    // DELETE SCHEDULED MARKETING LIST
    case ACTION.DELETE_SCHEDULED_MARKETING_LIST:
      return {
        ...state,
        scheduledMarketingList: {
          ...state.scheduledMarketingList,
          loading: false,
          marketingDeleteStatus: "started",
        },
      };
    case ACTION.DELETE_SCHEDULED_MARKETING_LIST_SUCCESS:
      return {
        ...state,
        scheduledMarketingList: {
          ...state.scheduledMarketingList,
          loading: true,
          marketingDeleteStatus: "success",
          action_type: action.payload.action_type,
        },
      };
    case ACTION.DELETE_SCHEDULED_MARKETING_LIST_FAIL:
      return {
        ...state,
        scheduledMarketingList: {
          ...state.scheduledMarketingList,
          loading: false,
          marketingDeleteStatus: "failed",
          action_type: action.payload,
        },
      };
    // DELETE SCHEDULED MARKETING LIST

    // LIVE MARKETING LIST
    case ACTION.GET_LIVE_MARKETING_LIST:
      return {
        ...state,
        liveMarketingList: {
          ...state.liveMarketingList,
          loading: true,
        },
      };
    case ACTION.GET_LIVE_MARKETING_LIST_SUCCESS:
      return {
        ...state,
        liveMarketingList: {
          ...state.liveMarketingList,
          ...action.payload,
          loading: false,
        },
      };
    // LIVE MARKETING LIST

    // DELETE LIVE MARKETING LIST
    case ACTION.DELETE_LIVE_MARKETING_LIST:
      return {
        ...state,
        liveMarketingList: {
          ...state.liveMarketingList,
          loading: false,
          marketingDeleteStatus: "started",
        },
      };
    case ACTION.DELETE_LIVE_MARKETING_LIST_SUCCESS:
      return {
        ...state,
        liveMarketingList: {
          ...state.liveMarketingList,
          loading: true,
          marketingDeleteStatus: "success",
          action_type: action.payload.action_type,
        },
      };
    case ACTION.DELETE_LIVE_MARKETING_LIST_FAIL:
      return {
        ...state,
        liveMarketingList: {
          ...state.liveMarketingList,
          loading: false,
          marketingDeleteStatus: "failed",
          action_type: action.payload,
        },
      };
    // DELETE LIVE MARKETING LIST

    // COMPLETED MARKETING LIST

    case ACTION.GET_COMPLETED_MARKETING_LIST:
      return {
        ...state,
        completedMarketingList: {
          ...state.completedMarketingList,
          loading: true,
        },
      };
    case ACTION.GET_COMPLETED_MARKETING_LIST_SUCCESS:
      return {
        ...state,
        completedMarketingList: {
          ...state.completedMarketingList,
          ...action.payload,
          loading: false,
        },
      };

    // COMPLETED MARKETING LIST

    // DELETE LIVE MARKETING LIST
    case ACTION.DELETE_COMPLETED_MARKETING_LIST:
      return {
        ...state,
        completedMarketingList: {
          ...state.completedMarketingList,
          loading: false,
          marketingDeleteStatus: "started",
        },
      };
    case ACTION.DELETE_COMPLETED_MARKETING_LIST_SUCCESS:
      return {
        ...state,
        completedMarketingList: {
          ...state.completedMarketingList,
          loading: true,
          marketingDeleteStatus: "success",
          action_type: action.payload.action_type,
        },
      };
    case ACTION.DELETE_COMPLETED_MARKETING_LIST_FAIL:
      return {
        ...state,
        completedMarketingList: {
          ...state.completedMarketingList,
          loading: false,
          marketingDeleteStatus: "failed",
          action_type: action.payload,
        },
      };
    // DELETE LIVE MARKETING LIST
    default:
      return state;
  }
};

export default marketingListReducers;
