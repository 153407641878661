import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Scrollbars } from "react-custom-scrollbars";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { DatePicker } from "antd";
import Accordion from 'react-bootstrap/Accordion';
import moment from 'moment';
import { unserializeArr } from "../../../utils/unserialize";
import { timeZoneToUTC, utcToTimezone } from "../../../utils/timeZoneConvert";
import { showError, showSuccess } from "../../../utils/alertMessage";
import LoaderImage from "../../../assets/images/page-loader.svg";
// import { GET_INPROGRESS_JOBS } from "../../../store/inProcressJobs/actionTypes";
import {
    GET_AVAIALABLE_SLOTS,
    GET_CARRY_OVER_JOB,
    GET_JOBS_KIT_PARTS,
    GET_JOBS_KIT_LABOURS,
    ADD_MANY_JOB_PARTS,
    ADD_MANY_JOB_LABOURS,
    ADD_MANY_JOB_KIT_PARTS,
    ADD_MANY_JOB_KIT_LABOURS,
    // UPDATE_CARRY_OVER_JOB
} from "../../../store/scheduler/actionTypes";
import { GET_QUOTE_BOOKED_DETAILS } from "../../../store/QuoteDetails/actionTypes";
import { editQuotes } from "../../../helpers/backendHelper";
import { UPDATE_JOB_TIMER } from '../../../store/jobDetails/actionTypes';
import { CREATE_JOB } from "../../../store/unassignedJobs/actionTypes";

const Reschedule = ({ rescheduleShow, handleRescheduleClose, jobIdData, startEventTime, technicianName, jobNumber, setUpdateCalendar, jobType, job_details, refreshData }) => {
    const [loading, setLoading] = useState(false);
    const storedData = JSON.parse(sessionStorage.getItem('stored_data'));
    let workshop_id = window.atob(storedData.workshop_id);
    let timezoneLable = window.atob(storedData.timezone_lable);
    let user_id = window.atob(storedData.user_id);
    let date_format = storedData.date_format;
    let time_format = storedData.time_format;
    const [currentDay, setCurrentDay] = useState(new Date(startEventTime).getDay());
    const [currentDate, setCurrentDate] = useState(moment(startEventTime).format('YYYY-MM-DD'));
    const dispatch = useDispatch(); // Dispatch action variable
    const [modalType, setModalType] = useState('');
    const [unassignedFlag, setUnassignedFlag] = useState(false);
    // const [jobLabours,setJobLabours] = useState([]);
    // const [jobParts,setJobParts] = useState([]);

    const [createJobLabours, setCreateJobLabours] = useState([]);
    const [createJobParts, setCreateJobParts] = useState([]);
    const [parentJobId, setParentJobId] = useState(null);
    // const [jobKitLabours,setJobKitLabours] = useState([]);
    // const [jobKitParts,setJobKitParts] = useState([]);
    const [copiedJobCount, setCopiedJobCount] = useState(0);

    const [titleDate, setTitleDate] = useState(moment(startEventTime).format(date_format));
    const [titleTime, setTitleTime] = useState(moment(startEventTime).format(time_format));
    const [employeeName, setEmployeeName] = useState(technicianName);
    const [employeeId, setEmployeeId] = useState('');
    const [fromDate, setFromDate] = useState();
    const [empDataType, setEmpDataType] = useState('res_tech');
    const [timeSlot, setTimeSlot] = useState(null);
    const [callDispatchAction, setCallDispatchAction] = useState(false);
    const [callDispatchActionForCarryOver, setCallDispatchActionForCarryOver] = useState(false);

    const [disabledSave, setDisabledSave] = useState('disabled');
    const [employeeList, setEmployeeList] = useState([]);
    const [timeSlotsList, setTimeSlotsList] = useState([]);
    let defaultSelect = true;
    const [rescheduleFormData, setRescheduleFormData] = useState({
        type: "reschedule",
        make: "",
        model: "",
        year: "",
        rego: "",
        customer_name: "",
        mobile_number: "",
        email: "",
        pickup_date: "",
        pickup_time: "",
        job_estimate_time: ""
    })
    const [unassignedDialog, setUnassignedDialogShow] = useState(false);
    const unassignedDialogClose = () => {
        setModalType('');
        setUnassignedDialogShow(false);
    }
    const unassignedDialogShow = () => {
        setModalType('unassigned');
        setUnassignedDialogShow(true);
    };

    useEffect(() => {
        if (startEventTime) {
            setTitleDate(moment(startEventTime).format(date_format));
            setTitleTime(moment(startEventTime).format(time_format));
        }
    }, [startEventTime])

    const workshopOtherDetails = useSelector((state) => state.CommonApiStoreReducer.Workshops.workshop_other_details);
    const employeeDetails = useSelector((state) => state.CommonApiStoreReducer.Workshops.users);
    const workshopBayData = useSelector((state) => state.SchedulerReducer.workshopBaysDetails);
    // const jobDetailsData = useSelector((state) => state.inProgressJobsReducer.jobData);
    const schedulerData = useSelector((state) => state.SchedulerReducer.getAvailableSlots);
    const { jobTimerData } = useSelector((state) => state.JobDetails);
    const jobDetailsData = useSelector((state) => state.SchedulerReducer.getCarryOverJob);
    const jobKitPartsData = useSelector((state) => state.SchedulerReducer.getJobsKitParts);
    const jobKitLaboursData = useSelector((state) => state.SchedulerReducer.getJobsKitLabours);
    const createJobData = useSelector((state) => state.UnassignedJobsReducer.createJob);
    const { jobBookedData } = useSelector((state) => state.QuoteDetails);


    useEffect(() => {
        if (callDispatchAction === true) {
            if (jobTimerData !== null && jobTimerData !== undefined) {
                showSuccess('Job updated successfully!');
                setUpdateCalendar(true);
            } else {
                showError('Job is not updated');
            }
            // setLoading(false);
            handleRescheduleClose();
            setCallDispatchAction(false);
        }
    }, [jobTimerData])

    useEffect(() => {
        if (callDispatchActionForCarryOver === true) {
            if (createJobData !== null && createJobData !== undefined) {
                console.log(createJobData, 'createJobData');
                var id = createJobData?.JobSchedules?.id || null;
                setParentJobId(id);
                showSuccess('Job created successfully!');
                if (jobType === "Open") {
                    changeQuoteStatus(createJobData?.JobSchedules.job_parent_id);
                }
                // setUpdateCalendar(true);
            } else {
                showError('Job is not updated');
            }
            // handleRescheduleClose();
            // setCallDispatchActionForCarryOver(false);
        }
    }, [createJobData])

    const changeQuoteStatus = async (id) => {
        let data = {
            payload: {
                id: id,
                quote_status: "Won",               
            },
        };
        let response = await editQuotes(data);

        if (response.code === 200) {
            refreshData();
        }
    };

    useEffect(() => {

        console.log(createJobParts, createJobLabours, jobKitPartsData, jobKitLaboursData, "parentJobId");
        if (parentJobId !== null && callDispatchActionForCarryOver === true) {
            let partsArr = [];
            if (createJobParts.length !== 0 && createJobParts !== null) {
                createJobParts.map((partData, index) => {
                    let parts = {};
                    parts['workshop_id'] = workshop_id;
                    parts['job_schedule_id'] = parentJobId;
                    parts['product_id'] = partData?.product_id || 0;
                    parts['is_inspection_part'] = partData?.is_inspection_part || 0;
                    parts['is_kit'] = partData?.is_kit || 0;
                    if (jobType === "Open") {
                        parts['job_part_name'] = partData?.quote_part_name || 0;
                        parts['job_part_priority'] = partData?.quote_part_priority || 0;
                    } else {
                        parts['job_part_name'] = partData?.job_part_name || 0;
                        parts['job_part_priority'] = partData?.job_part_priority || 0;
                    }
                    parts['cost'] = partData?.cost || 0;
                    parts['po_qty_sent'] = partData?.po_qty_sent || 0;
                    parts['price'] = partData?.price || 0;
                    parts['is_demo_data'] = partData?.is_demo_data || 0;
                    parts['margin'] = partData?.margin || 0;
                    parts['sell'] = partData?.sell || 0;
                    parts['profit'] = partData?.profit || 0;
                    parts['gst'] = partData?.gst || 0;
                    parts['time'] = partData?.time || 0;
                    parts['qty'] = partData?.qty || 0;
                    parts['retail'] = partData?.retail || 0;
                    parts['assigned_qty'] = partData?.assigned_qty || 0;
                    parts['purchase_order_qty'] = partData?.purchase_order_qty || 0;
                    parts['basic_stock'] = partData?.basic_stock || 0;
                    parts['is_assigned'] = partData?.is_assigned || 0;
                    parts['is_carried_over_parts'] = 1;
                    parts['repco_extra_part'] = partData?.repco_extra_part || 0;
                    partsArr.push(parts);
                })
                // setJobParts(partsArr);

                dispatch({
                    type: ADD_MANY_JOB_PARTS,
                    payload: {
                        data: partsArr
                    }
                })
            }

            let laboursArr = [];
            if (createJobLabours !== null && createJobLabours.length !== 0) {
                createJobLabours.map((labourData, index) => {
                    let labours = {};
                    if (jobType === "Open") {
                        labours['labour_charges_id'] = labourData?.id || 0;
                        labours['cost_price'] = labourData?.cost || 0;
                        labours['margin_markup'] = labourData?.markup || 0;
                        labours['sell_price'] = labourData?.sell || 0;
                        labours['retail_price'] = labourData?.retail || 0;
                        labours['labour_time'] = labourData?.time || 0;
                        labours['total_price'] = labourData?.total || 0;
                    } else {
                        labours['labour_charges_id'] = labourData?.labour_charges_id || 0;
                        labours['cost_price'] = labourData?.cost_price || 0;
                        labours['margin_markup'] = labourData?.margin_markup || 0;
                        labours['sell_price'] = labourData?.sell_price || 0;
                        labours['retail_price'] = labourData?.retail_price || 0;
                        labours['labour_time'] = labourData?.labour_time || 0;
                        labours['total_price'] = labourData?.total_price || 0;
                    }
                    labours['job_schedules_id'] = parentJobId;
                    labours['margin'] = labourData?.margin || 0;
                    labours['labour_description'] = labourData?.labour_description || 0;
                    labours['labour_ph'] = labourData?.labour_ph || 0;
                    labours['profit'] = labourData?.profit || 0;
                    labours['is_carried_over_labour'] = 1;
                    labours['priority'] = labourData?.priority || 0;
                    labours['is_inspection'] = labourData?.is_inspection || 0;
                    laboursArr.push(labours);
                })
                // setJobLabours(laboursArr);

                dispatch({
                    type: ADD_MANY_JOB_LABOURS,
                    payload: {
                        data: laboursArr
                    }
                })
            }

            if (jobKitPartsData !== null && jobKitPartsData !== undefined) {
                let jobKitPartsArr = [];
                jobKitPartsData.map((jobKitPart, index) => {
                    let kitPart = {};
                    kitPart['workshop_id'] = workshop_id;
                    kitPart['job_id'] = parentJobId;
                    kitPart['job_parts_id'] = jobKitPart.job_parts_id;
                    kitPart['job_kit_labour_id'] = jobKitPart.job_kit_labour_id;
                    kitPart['kit_id'] = jobKitPart.kit_id;
                    kitPart['part_id'] = jobKitPart.part_id;
                    kitPart['priority'] = jobKitPart.priority;
                    kitPart['cost'] = jobKitPart.cost;
                    kitPart['margin'] = jobKitPart.margin;
                    kitPart['sell'] = jobKitPart.sell;
                    kitPart['qty'] = jobKitPart.qty;
                    kitPart['retail'] = jobKitPart.retail;
                    kitPart['margin_markup'] = jobKitPart.margin_markup;
                    kitPart['gst'] = jobKitPart.gst;
                    kitPart['gst_price'] = jobKitPart.gst_price;
                    kitPart['assigned_qty'] = jobKitPart.assigned_qty;
                    kitPart['purchase_order_qty'] = jobKitPart.purchase_order_qty;
                    kitPart['po_qty_sent'] = jobKitPart.po_qty_sent;
                    kitPart['basic_stock'] = jobKitPart.basic_stock;
                    kitPart['is_assigned'] = jobKitPart.is_assigned;
                    kitPart['is_carried_over_parts'] = 1;
                    jobKitPartsArr.push(kitPart);
                })
                // setJobKitParts(jobKitPartsArr);

                dispatch({
                    type: ADD_MANY_JOB_KIT_PARTS,
                    payload: {
                        data: jobKitPartsArr
                    }
                })
            }

            if (jobKitLaboursData !== null && jobKitLaboursData !== undefined) {
                let jobKitLabourArr = [];
                jobKitLaboursData.map((jobKitLabour, index) => {
                    let kitLabour = {};
                    kitLabour['workshop_id'] = workshop_id;
                    kitLabour['job_id'] = parentJobId;
                    kitLabour['kit_id'] = jobKitLabour.kit_id;
                    kitLabour['labour_charge_id'] = jobKitLabour.labour_charge_id;
                    kitLabour['cost_price'] = jobKitLabour.cost_price;
                    kitLabour['margin'] = jobKitLabour.margin;
                    kitLabour['sell_price'] = jobKitLabour.sell_price;
                    kitLabour['qty_hours'] = jobKitLabour.qty_hours;
                    kitLabour['retail_price'] = jobKitLabour.retail_price;
                    kitLabour['margin_markup'] = jobKitLabour.margin_markup;
                    kitLabour['gst'] = jobKitLabour.gst;
                    kitLabour['gst_price'] = jobKitLabour.gst_price;
                    kitLabour['discription'] = jobKitLabour.discription;
                    kitLabour['is_carried_over_labour'] = 1;
                    jobKitLabourArr.push(kitLabour);
                })
                // setJobKitLabours(jobKitLabourArr);    
                dispatch({
                    type: ADD_MANY_JOB_KIT_LABOURS,
                    payload: {
                        data: jobKitLabourArr
                    }
                })
            }
            // setLoading(false);
            setUpdateCalendar(true);
            handleRescheduleClose();
            setCallDispatchActionForCarryOver(false);
        }
    }, [parentJobId, callDispatchActionForCarryOver])

    useEffect(() => {
        if (jobIdData !== undefined) {
            if (jobType === "carryover") {
                dispatch({
                    type: GET_CARRY_OVER_JOB,
                    payload: {
                        get: "all",
                        conditions: {
                            "JobSchedules.id": jobIdData
                        },
                        contain: {
                            "JobsParts": {},
                            "JobLabours": {},
                            "Vehicles.Users.CustomersDetails": {
                                conditions: {
                                    workshop_id: workshop_id
                                }
                            }
                        }
                    }
                })
                dispatch({
                    type: GET_JOBS_KIT_PARTS,
                    payload: {
                        get: "all",
                        conditions: {
                            "job_id": jobIdData
                        }
                    }
                })
                dispatch({
                    type: GET_JOBS_KIT_LABOURS,
                    payload: {
                        get: "all",
                        conditions: {
                            "job_id": jobIdData
                        }
                    }
                })
            } else if (jobType === "Open") {
                dispatch({
                    type: GET_QUOTE_BOOKED_DETAILS,
                    payload: {
                        get: "all",
                        conditions: {
                            "CustomerQuotes.id": jobIdData,
                        },
                        contain: [
                            'Vehicles',
                            'Users.CustomersDetails',
                            'CustomerQuotesParts.Products',
                            'CustomerQuotesLabours'
                        ]

                    },
                });
            } else {
                dispatch({
                    type: GET_CARRY_OVER_JOB,
                    payload: {
                        get: "all",
                        conditions: {
                            "JobSchedules.id": jobIdData
                        },
                        contain: {
                            "Vehicles.Users.CustomersDetails": {
                                conditions: {
                                    workshop_id: workshop_id
                                }
                            }
                        }
                    }
                })
            }
        }
    }, [jobIdData, jobType])

    useEffect(() => {
        if (jobDetailsData !== null || jobDetailsData !== undefined) {
            var custname = '';
            var contact = '';
            var make = '';
            var model = '';
            var year = '';
            var rego = '';
            var custDetails = jobDetailsData?.[0]?.vehicle?.user?.customers_details?.[0] || null;
            var customerApp = jobDetailsData?.[0]?.vehicle?.user?.customers_details?.[0]?.customer_app || null;
            if (custDetails !== null) {
                if (custDetails.type === "2") {
                    custname = custDetails.firstname + " " + custDetails.lastname;
                } else {
                    custname = custDetails.company_name;
                }
                contact = custDetails.mobile_number;
            }
            var email = jobDetailsData?.[0]?.vehicle?.user?.email || '';
            var vehicleDetails = jobDetailsData?.[0]?.vehicle || null;
            if (vehicleDetails !== null) {
                make = vehicleDetails.make;
                model = vehicleDetails.model;
                year = vehicleDetails.year;
                rego = vehicleDetails.rego;
            }
            var jobEstimate = jobDetailsData?.[0]?.job_estimate_time || '00:00';
            var pickupDate = jobDetailsData?.[0]?.pickup_time || '';
            if (pickupDate !== "") {
                pickupDate = moment(pickupDate).format('YYYY-MM-DD');
            }
            var pickupTime = jobDetailsData?.[0]?.pickup_time_only || '00:00';
            if (pickupTime !== "") {
                pickupTime = moment().format('HH:mm');
            }

            setRescheduleFormData({
                ...rescheduleFormData,
                customer_name: custname,
                mobile_number: contact,
                email: email,
                make: make,
                model: model,
                year: year,
                rego: rego,
                job_estimate_time: jobEstimate,
                pickup_date: pickupDate,
                pickup_time: pickupTime,
                customer_app: customerApp
            });

            if (jobType === "carryover") {
                var jobPartsArr = jobDetailsData?.[0]?.['jobs_parts'] || [];
                var jobLaboursArr = jobDetailsData?.[0]?.['job_labours'] || [];
                var copyJobCount = jobDetailsData?.[0]?.is_copy_job || 0;
                copyJobCount = copyJobCount + 1;
                setCreateJobParts(jobPartsArr);
                setCreateJobLabours(jobLaboursArr);
                setCopiedJobCount(copyJobCount);

                var custId = jobDetailsData?.[0]?.vehicle?.user?.customers_details?.[0]?.id || null;
                var vehId = jobDetailsData?.[0]?.vehicle?.id || null;
                var createJobType = jobDetailsData?.[0]?.job_type || null;
                var jobDeptId = jobDetailsData?.[0]?.job_department_id || null;
                var tags = jobDetailsData?.[0]?.tags_id || [];
                var description = jobDetailsData?.[0]?.description || null;
                var jobStatus = jobDetailsData?.[0]?.job_status || null;
                var jobStage = jobDetailsData?.[0]?.job_stage || null;
                var customerType = jobDetailsData?.[0]?.customer_vehicle_option || 0;
                var InspectionId = jobDetailsData?.[0]?.inspections_id || 0;
                var inspReferenceId = jobDetailsData?.[0]?.insp_reference_id || 0;
                var inspTechnicianId = jobDetailsData?.[0]?.insp_technician_id || 0;
                var insuranceCompanyId = jobDetailsData?.[0]?.insurance_company_id || 0;
                var inspCertificateId = jobDetailsData?.[0]?.insp_certificate_id || 0;
                var claimNumber = jobDetailsData?.[0]?.claim_number || 0;
                var bookinSource = jobDetailsData?.[0]?.bookin_source || null;
                var sourceOfBusiness = jobDetailsData?.[0]?.source_of_business || null;
                var vehicleServiceDepartment = jobDetailsData?.[0]?.vehicle_service_department || null;
                var jobTypeParentId = jobDetailsData?.[0]?.job_type_parent_id || null;
                var employeesDetailsId = jobDetailsData?.[0]?.employees_details_id || 0;
                var isOnLeave = jobDetailsData?.[0]?.is_on_leave || 0;
                var partsCost = jobDetailsData?.[0]?.parts_cost || 0;
                var labourCost = jobDetailsData?.[0]?.labour_cost || 0;
                var partsMarkup = jobDetailsData?.[0]?.parts_markup || 0;
                var labourMarkup = jobDetailsData?.[0]?.labour_markup || 0;
                var partsTotalCost = jobDetailsData?.[0]?.parts_total_cost || 0;
                var labourTotalCost = jobDetailsData?.[0]?.labour_total_cost || 0;
                var labourTime = jobDetailsData?.[0]?.labour_time || 0;
                var subTotal = jobDetailsData?.[0]?.sub_total || 0;
                var gst = jobDetailsData?.[0]?.gst || 0;
                var discountRate = jobDetailsData?.[0]?.discount_rate || 0;
                var discountName = jobDetailsData?.[0]?.discount_name || 0;
                var discountCost = jobDetailsData?.[0]?.discount_cost || 0;
                var discountType = jobDetailsData?.[0]?.discount_type || 0;
                var discountCode = jobDetailsData?.[0]?.discount_code || 0;
                var jobDiscountFromId = jobDetailsData?.[0]?.jobs_discount_from_id || 0;
                var referralUserId = jobDetailsData?.[0]?.referral_user_id || 0;
                var isDiscountAppliedByCustomer = jobDetailsData?.[0]?.is_discount_applied_by_customer || 0;
                var jobAddInCalendar = jobDetailsData?.[0]?.job_add_in_calendar || 0;
                var discountEndDate = jobDetailsData?.[0]?.discount_end_date || "";
                var total = jobDetailsData?.[0]?.total || 0;
                var nextServiceId = jobDetailsData?.[0]?.next_service_id || 0;
                var time = jobDetailsData?.[0]?.time || 0;
                var nextServiceDue = jobDetailsData?.[0]?.next_service_due || "";
                var motWotDuration = jobDetailsData?.[0]?.mot_wot_duration || "";
                var motWotDue = jobDetailsData?.[0]?.mot_wot_due || "";
                var odometerReading = jobDetailsData?.[0]?.odometer_reading || 0;
                var duration = jobDetailsData?.[0]?.duration || 0;

                // For carry over job details 
                setRescheduleFormData({
                    ...rescheduleFormData,
                    customer_name: custname,
                    mobile_number: contact,
                    email: email,
                    make: make,
                    model: model,
                    year: year,
                    rego: rego,
                    job_estimate_time: jobEstimate,
                    pickup_date: pickupDate,
                    pickup_time: pickupTime,
                    customer_app: customerApp,
                    duration: duration,
                    cust_id: custId,
                    vehicle_id: vehId,
                    job_type: createJobType,
                    job_department_id: jobDeptId,
                    tags_id: tags,
                    description: description,
                    job_status: jobStatus,
                    job_stage: jobStage,
                    customer_vehicle_option: customerType,
                    job_number: jobNumber,
                    inspections_id: InspectionId,
                    insp_reference_id: inspReferenceId,
                    insp_technician_id: inspTechnicianId,
                    insurance_company_id: insuranceCompanyId,
                    insp_certificate_id: inspCertificateId,
                    claim_number: claimNumber,
                    bookin_source: bookinSource,
                    source_of_business: sourceOfBusiness,
                    vehicle_service_department: vehicleServiceDepartment,
                    job_type_parent_id: jobTypeParentId,
                    timer_status: 'stop',
                    employees_details_id: employeesDetailsId,
                    is_on_leave: isOnLeave,
                    parts_cost: partsCost,
                    labour_cost: labourCost,
                    parts_markup: partsMarkup,
                    labour_markup: labourMarkup,
                    parts_total_cost: partsTotalCost,
                    labour_total_cost: labourTotalCost,
                    labour_time: labourTime,
                    sub_total: subTotal,
                    gst: gst,
                    discount_rate: discountRate,
                    discount_name: discountName,
                    discount_cost: discountCost,
                    discount_type: discountType,
                    discount_code: discountCode,
                    jobs_discount_from_id: jobDiscountFromId,
                    referral_user_id: referralUserId,
                    is_discount_applied_by_customer: isDiscountAppliedByCustomer,
                    job_add_in_calendar: jobAddInCalendar,
                    discount_end_date: discountEndDate,
                    total: total,
                    next_service_id: nextServiceId,
                    time: time,
                    next_service_due: nextServiceDue,
                    mot_wot_duration: motWotDuration,
                    mot_wot_due: motWotDue,
                    odometer_reading: odometerReading,
                    job_parent_id: jobIdData,
                    is_copy_job: copyJobCount
                });
            } else {
                setRescheduleFormData({
                    ...rescheduleFormData,
                    customer_name: custname,
                    mobile_number: contact,
                    email: email,
                    make: make,
                    model: model,
                    year: year,
                    rego: rego,
                    job_estimate_time: jobEstimate,
                    pickup_date: pickupDate,
                    pickup_time: pickupTime,
                    customer_app: customerApp
                });
            }
        }
    }, [jobDetailsData])

    useEffect(() => {
        let jobDetailsData = jobBookedData;
        if (jobDetailsData !== null && jobDetailsData !== undefined) {
            console.log(jobDetailsData);
            var custname = "";
            var contact = "";
            var make = "";
            var model = "";
            var year = "";
            var rego = "";
            var custDetails =
                jobDetailsData?.user?.customers_details?.[0] || null;
            var customerApp =
                jobDetailsData?.user?.customers_details?.[0]?.customer_app ||
                null;
            if (custDetails !== null) {
                if (custDetails.type === "2") {
                    custname = custDetails.firstname + " " + custDetails.lastname;
                } else {
                    custname = custDetails.company_name;
                }
                contact = custDetails.mobile_number;
            }
            var email = jobDetailsData?.user?.email || "";
            var vehicleDetails = jobDetailsData?.vehicle || null;
            if (vehicleDetails !== null) {
                make = vehicleDetails.make;
                model = vehicleDetails.model;
                year = vehicleDetails.year;
                rego = vehicleDetails.rego;
            }
            var jobEstimate = jobDetailsData?.job_estimate_time || "";
            var pickupDate = jobDetailsData?.pickup_time || "";
            if (pickupDate !== "") {
                pickupDate = moment(pickupDate).format("YYYY-MM-DD");
            }
            var pickupTime = jobDetailsData?.pickup_time_only || "";
            if (pickupTime !== "") {
                pickupTime = moment().format("HH:mm");
            }

            setRescheduleFormData({
                ...rescheduleFormData,
                customer_name: custname,
                mobile_number: contact,
                email: email,
                make: make,
                model: model,
                year: year,
                rego: rego,
                job_estimate_time: jobEstimate,
                pickup_date: pickupDate,
                pickup_time: pickupTime,
                customer_app: customerApp,
            });

            if (jobType === "Open") {
                var jobPartsArr = jobDetailsData?.customer_quotes_parts || [];
                var jobLaboursArr = jobDetailsData?.customer_quotes_labours || [];
                var copyJobCount = jobDetailsData?.quote_type || 0;
                copyJobCount = copyJobCount + 1;
                setCreateJobParts(jobPartsArr);
                setCreateJobLabours(jobLaboursArr);
                setCopiedJobCount(copyJobCount);

                var custId =
                    jobDetailsData?.user?.customers_details?.[0]?.id || null;
                var vehId = jobDetailsData?.vehicle?.id || null;
                var createJobType = jobDetailsData?.job_type || null;
                var jobDeptId = jobDetailsData?.job_department || null;
                var tags = jobDetailsData?.tags || [];
                var description = jobDetailsData?.description || null;
                var jobStatus = jobDetailsData?.quote_status || null;
                var jobStage = jobDetailsData?.job_stage || null;
                var customerType = jobDetailsData?.customer_vehicle_option || 0;
                var InspectionId = jobDetailsData?.inspections_id || 0;
                var inspReferenceId = jobDetailsData?.insp_reference_id || 0;
                var inspTechnicianId = jobDetailsData?.insp_technician_id || 0;
                var insuranceCompanyId = jobDetailsData?.insurance_company_id || 0;
                var inspCertificateId = jobDetailsData?.insp_certificate_id || 0;
                var claimNumber = jobDetailsData?.claim_number || 0;
                var bookinSource = jobDetailsData?.bookin_source || null;
                var sourceOfBusiness = jobDetailsData?.source_of_business || null;
                var vehicleServiceDepartment =
                    jobDetailsData?.vehicle_service_department || null;
                var jobTypeParentId = jobDetailsData?.job_type_parent_id || null;
                var employeesDetailsId = jobDetailsData?.employees_details_id || 0;
                var isOnLeave = jobDetailsData?.is_on_leave || 0;
                var partsCost = jobDetailsData?.parts_cost || 0;
                var labourCost = jobDetailsData?.labour_cost || 0;
                var partsMarkup = jobDetailsData?.parts_markup || 0;
                var labourMarkup = jobDetailsData?.labour_markup || 0;
                var partsTotalCost = jobDetailsData?.parts_total_cost || 0;
                var labourTotalCost = jobDetailsData?.labour_total_cost || 0;
                var labourTime = jobDetailsData?.labour_time || 0;
                var subTotal = jobDetailsData?.sub_total || 0;
                var gst = jobDetailsData?.gst || 0;
                var discountRate = jobDetailsData?.discount_rate || 0;
                var discountName = jobDetailsData?.discount_name || 0;
                var discountCost = jobDetailsData?.discount_cost || 0;
                var discountType = jobDetailsData?.discount_type || 0;
                var discountCode = jobDetailsData?.discount_code || 0;
                var jobDiscountFromId = jobDetailsData?.jobs_discount_from_id || 0;
                var referralUserId = jobDetailsData?.referral_user_id || 0;
                var isDiscountAppliedByCustomer =
                    jobDetailsData?.is_discount_applied_by_customer || 0;
                var jobAddInCalendar = jobDetailsData?.job_add_in_calendar || 0;
                var discountEndDate = jobDetailsData?.discount_end_date || "";
                var total = jobDetailsData?.total || 0;
                var nextServiceId = jobDetailsData?.next_service_id || 0;
                var time = jobDetailsData?.time || 0;
                var nextServiceDue = jobDetailsData?.next_service_due || "";
                var motWotDuration = jobDetailsData?.mot_wot_duration || "";
                var motWotDue = jobDetailsData?.mot_wot_due || "";
                var odometerReading = jobDetailsData?.odometer_reading || 0;
                var duration = jobDetailsData?.duration || 0;

                // For carry over job details
                setRescheduleFormData({
                    ...rescheduleFormData,
                    customer_name: custname,
                    mobile_number: contact,
                    email: email,
                    make: make,
                    model: model,
                    year: year,
                    rego: rego,
                    job_estimate_time: jobEstimate,
                    pickup_date: pickupDate,
                    pickup_time: pickupTime,
                    customer_app: customerApp,
                    duration: duration,
                    cust_id: custId,
                    vehicle_id: vehId,
                    job_type: createJobType,
                    job_department_id: jobDeptId,
                    tags_id: tags,
                    description: description,
                    job_status: jobStatus,
                    job_stage: jobStage,
                    customer_vehicle_option: customerType,
                    job_number: jobNumber,
                    inspections_id: InspectionId,
                    insp_reference_id: inspReferenceId,
                    insp_technician_id: inspTechnicianId,
                    insurance_company_id: insuranceCompanyId,
                    insp_certificate_id: inspCertificateId,
                    claim_number: claimNumber,
                    bookin_source: bookinSource,
                    source_of_business: sourceOfBusiness,
                    vehicle_service_department: vehicleServiceDepartment,
                    job_type_parent_id: jobTypeParentId,
                    timer_status: "stop",
                    employees_details_id: employeesDetailsId,
                    is_on_leave: isOnLeave,
                    parts_cost: partsCost,
                    labour_cost: labourCost,
                    parts_markup: partsMarkup,
                    labour_markup: labourMarkup,
                    parts_total_cost: partsTotalCost,
                    labour_total_cost: labourTotalCost,
                    labour_time: labourTime,
                    sub_total: subTotal,
                    gst: gst,
                    discount_rate: discountRate,
                    discount_name: discountName,
                    discount_cost: discountCost,
                    discount_type: discountType,
                    discount_code: discountCode,
                    jobs_discount_from_id: jobDiscountFromId,
                    referral_user_id: referralUserId,
                    is_discount_applied_by_customer: isDiscountAppliedByCustomer,
                    job_add_in_calendar: jobAddInCalendar,
                    discount_end_date: discountEndDate,
                    total: total,
                    next_service_id: nextServiceId,
                    time: time,
                    next_service_due: nextServiceDue,
                    mot_wot_duration: motWotDuration,
                    mot_wot_due: motWotDue,
                    odometer_reading: odometerReading,
                    job_parent_id: jobIdData,
                    is_copy_job: copyJobCount,
                });
            } else {
                setRescheduleFormData({
                    ...rescheduleFormData,
                    customer_name: custname,
                    mobile_number: contact,
                    email: email,
                    make: make,
                    model: model,
                    year: year,
                    rego: rego,
                    job_estimate_time: jobEstimate,
                    pickup_date: pickupDate,
                    pickup_time: pickupTime,
                    customer_app: customerApp,
                });
            }
        }
    }, [jobBookedData]);

    useEffect(() => {
        let baysList = [];
        let technicianList = [];
        if ((employeeDetails !== null && employeeDetails !== undefined)) {
            employeeDetails.map((employee, index) => {
                let technicianListArr = [];
                technicianListArr['data_type'] = 'res_tech';
                technicianListArr['name'] = employee.firstname + " " + employee.lastname;
                technicianListArr['id'] = employee.id;
                var employeeWorkingDayArr = [];
                if (employee.employees_details[0] !== "" && employee.employees_details[0] !== undefined && employee.employees_details[0] !== null) {
                    if (employee.employees_details[0].shift_working_day) {
                        employeeWorkingDayArr = unserializeArr(employee.employees_details[0].shift_working_day);
                    }
                }
                technicianListArr['working_days'] = 0;
                if (employeeWorkingDayArr.includes(`${currentDay}`)) {
                    technicianListArr['working_days'] = 1;
                    if (defaultSelect === true) {
                        var name = employee.firstname + " " + employee.lastname;
                        setEmployeeName(name);
                        setEmployeeId(employee.id);
                        setEmpDataType('res_tech');
                        // eslint-disable-next-line react-hooks/exhaustive-deps
                        defaultSelect = false;
                    }
                }
                technicianList.push(technicianListArr);
            })
        }

        if (workshopBayData !== null && workshopBayData !== undefined) {
            workshopBayData.map((bays, index) => {
                let baysArr = [];
                baysArr['data_type'] = 'res_bay';
                baysArr['name'] = bays.name;
                baysArr['id'] = bays.id;
                baysArr['working_days'] = 1;
                if (defaultSelect === true) {
                    setEmployeeName(bays.name);
                    setEmpDataType('res_bay');
                    setEmployeeId(bays.id);
                    defaultSelect = false;
                }
                technicianList.push(baysArr);
            })
        }
        setEmployeeList(technicianList);
    }, [employeeDetails, workshopBayData, currentDay])

    const changeBookedDate = (date) => {
        setEmpDataType('res_tech');
        // setEmployeeId('');
        var currentDate = moment(date).format('YYYY-MM-DD');
        setTitleDate(moment(date).format(date_format));
        setCurrentDate(currentDate);
        setCurrentDay(new Date(currentDate).getDay());
    }

    useEffect(() => {
        var startTime = utcToTimezone(workshopOtherDetails[0].shift_start_time, timezoneLable, "HH:mm:ss", 'HH:mm:ss');
        var fromDate = currentDate + " " + startTime;
        setFromDate(fromDate);
    }, [currentDate])

    const handleTechnicianSlot = (e) => {
        setEmpDataType(e.dataset.id);
        var startTime = utcToTimezone(workshopOtherDetails[0].shift_start_time, timezoneLable, "HH:mm:ss", 'HH:mm:ss');
        var fromDate = currentDate + " " + startTime;
        setFromDate(fromDate);
    }

    useEffect(() => {
        if (employeeId !== "") {
            setTimeSlot(null);
            setDisabledSave('disabled');
            if (empDataType === "res_tech") {
                dispatch({
                    type: GET_AVAIALABLE_SLOTS,
                    payload: {
                        workshop_id: workshop_id,
                        from_date: fromDate,
                        days: currentDay,
                        'job_stage NOT IN': ['Archive'],
                        user_id: employeeId
                    }
                })
            } else {
                dispatch({
                    type: GET_AVAIALABLE_SLOTS,
                    payload: {
                        workshop_id: workshop_id,
                        from_date: fromDate,
                        days: currentDay,
                        'job_stage NOT IN': ['Archive'],
                        user_id: 0,
                        bay_id: employeeId
                    }
                })
            }
        }
    }, [fromDate, employeeId])

    useEffect(() => {
        if (schedulerData !== null && schedulerData !== undefined) {
            var techTimeSlots = workshopOtherDetails[0].technician_timeslot;
            let slotsArrList = [];
            for (var key in schedulerData) {
                let slotsArr = [];
                var startTimeSlots = moment.utc(key, 'hh:mm:ss').format('hh:mm A');
                var endTimeSlots = moment.utc(key, 'hh:mm:ss').add(techTimeSlots, 'minutes').format('hh:mm A');
                slotsArr['start_time'] = key;
                slotsArr['slots'] = startTimeSlots + " - " + endTimeSlots;
                slotsArr['available'] = schedulerData[key][0];
                slotsArrList.push(slotsArr);
            }
            setTimeSlotsList(slotsArrList);
        }
    }, [schedulerData])

    const handlePickupDate = (date) => {
        rescheduleFormData.pickup_date = moment(date).format('YYYY-MM-DD');
    }

    const handleRescheduleFormData = (e) => {
        const { name, value } = e.target;
        setRescheduleFormData({
            ...rescheduleFormData,
            [name]: value,
        });
    }

    const handleSaveForm = () => {
        var startDate = currentDate;
        var startTime = timeSlot;
        var startTimeSlots = moment(startDate + " " + startTime).format('YYYY-MM-DD HH:mm:ss');
        var techTimeSlots = workshopOtherDetails[0].technician_timeslot;
        var startTimeJob = timeZoneToUTC(new Date(startTimeSlots), timezoneLable, "HH:mm:ss", 'HH:mm:ss');
        var endTimeJob = moment(startTimeJob, 'HH:mm:ss').add(techTimeSlots, 'minutes').format('HH:mm:ss');
        var userId = 0;
        var bayId = 0;
        if (empDataType === "res_tech") {
            userId = employeeId;
        } else {
            bayId = employeeId;
        }
        let createJobArr = {};

        createJobArr['user_id'] = userId;
        createJobArr['bay_id'] = bayId;
        createJobArr['from_date'] = startDate;
        createJobArr['to_date'] = startDate;
        createJobArr['from_time'] = startTimeJob;
        createJobArr['to_time'] = endTimeJob;
        createJobArr['pickup_time_only'] = rescheduleFormData.pickup_time;
        createJobArr['pickup_time'] = rescheduleFormData.pickup_date;

        if (jobType === "carryover" || jobType === "Open") {
            createJobArr['duration'] = rescheduleFormData.duration;
            createJobArr['workshop_id'] = workshop_id;
            createJobArr['created_by'] = user_id;
            createJobArr['customer_id'] = rescheduleFormData.cust_id;
            createJobArr['vehicle_id'] = rescheduleFormData.vehicle_id;
            createJobArr['job_type'] = rescheduleFormData.job_type;
            createJobArr['job_department_id'] = rescheduleFormData.job_department_id;
            createJobArr['tags_id'] = rescheduleFormData.tags_id;
            createJobArr['description'] = rescheduleFormData.description;
            createJobArr['job_status'] = 'Pending';
            createJobArr['job_stage'] = 'Bookin';
            if (unassignedFlag === true) {
                createJobArr['job_status'] = 'Unassigned';
                createJobArr['job_stage'] = 'Unassigned';
                createJobArr['carry_over_to_unassign'] = 1;
                createJobArr['user_id'] = 0;
            }
            createJobArr['customer_vehicle_option'] = rescheduleFormData.customer_vehicle_option;
            createJobArr['job_number'] = rescheduleFormData.job_number;
            createJobArr['inspections_id'] = rescheduleFormData.inspections_id;
            createJobArr['insp_reference_id'] = rescheduleFormData.insp_reference_id;
            createJobArr['insp_technician_id'] = rescheduleFormData.insp_technician_id;
            createJobArr['insurance_company_id'] = rescheduleFormData.insurance_company_id;
            createJobArr['insp_certificate_id'] = rescheduleFormData.insp_certificate_id;
            createJobArr['claim_number'] = rescheduleFormData.claim_number;
            createJobArr['bookin_source'] = rescheduleFormData.bookin_source;
            createJobArr['source_of_business'] = rescheduleFormData.source_of_business;
            createJobArr['vehicle_service_department'] = rescheduleFormData.vehicle_service_department;
            createJobArr['job_type_parent_id'] = rescheduleFormData.job_type_parent_id;
            createJobArr['timer_status'] = 'stop';
            // createJobArr['employees_details_id'] = rescheduleFormData.employees_details_id;
            createJobArr['is_on_leave'] = rescheduleFormData.is_on_leave;
            createJobArr['parts_cost'] = rescheduleFormData.parts_cost;
            createJobArr['labour_cost'] = rescheduleFormData.labour_cost;
            createJobArr['parts_markup'] = rescheduleFormData.parts_markup;
            createJobArr['labour_markup'] = rescheduleFormData.labour_markup;
            createJobArr['parts_total_cost'] = rescheduleFormData.parts_total_cost;
            createJobArr['labour_total_cost'] = rescheduleFormData.labour_total_cost;
            createJobArr['labour_time'] = rescheduleFormData.labour_time;
            createJobArr['sub_total'] = rescheduleFormData.sub_total;
            createJobArr['gst'] = rescheduleFormData.gst;
            createJobArr['discount_rate'] = rescheduleFormData.discount_rate;
            createJobArr['discount_name'] = rescheduleFormData.discount_name;
            createJobArr['discount_cost'] = rescheduleFormData.discount_cost;
            createJobArr['discount_type'] = rescheduleFormData.discount_type;
            createJobArr['discount_code'] = rescheduleFormData.discount_code;
            createJobArr['jobs_discount_from_id'] = rescheduleFormData.jobs_discount_from_id;
            createJobArr['referral_user_id'] = rescheduleFormData.referral_user_id;
            createJobArr['is_discount_applied_by_customer'] = rescheduleFormData.is_discount_applied_by_customer;
            createJobArr['job_add_in_calendar'] = rescheduleFormData.job_add_in_calendar;
            createJobArr['discount_end_date'] = rescheduleFormData.discount_end_date;
            createJobArr['total'] = rescheduleFormData.total;
            createJobArr['next_service_id'] = rescheduleFormData.next_service_id;
            createJobArr['time'] = rescheduleFormData.time;
            createJobArr['next_service_due'] = rescheduleFormData.next_service_due;
            createJobArr['mot_wot_duration'] = rescheduleFormData.mot_wot_duration;
            createJobArr['mot_wot_due'] = rescheduleFormData.mot_wot_due;
            createJobArr['odometer_reading'] = rescheduleFormData.odometer_reading;
            createJobArr['job_parent_id'] = rescheduleFormData.job_parent_id;
            createJobArr['is_copy_job'] = rescheduleFormData.is_copy_job;
            copyJob(createJobArr);
        } else if (jobType === "Unassigned") {
            createJobArr["workshop_id"] = workshop_id;
            createJobArr["created_by"] = user_id;
            createJobArr["tags_id"] = [];
            createJobArr["job_status"] = "Pending";
            createJobArr["job_stage"] = "Bookin";
            createJobArr["insp_reference_id"] = 0;
            createJobArr["bookin_source"] = 2;
            createJobArr["source_of_business"] = "Existing";
            createJobArr["labour_time"] = 0;
            createJobArr["parts_cost"] = 0;
            createJobArr["labour_cost"] = 0;
            createJobArr["parts_markup"] = 0;
            createJobArr["labour_markup"] = 0;
            createJobArr["parts_total_cost"] = 0;
            createJobArr["labour_total_cost"] = 0;
            createJobArr["sub_total"] = 0;
            createJobArr["gst"] = 0;
            createJobArr["total"] = 0;
            createJobArr["discount_rate"] = 0;
            createJobArr["id"] = jobIdData;
            updateJob(createJobArr);
        } else {
            createJobArr['id'] = jobIdData;
            if (rescheduleFormData.customer_app === "1") {
                createJobArr['job_status'] = 'Rescheduled';
            }
            if (createJobArr['id']) {
                updateJob(createJobArr);
            }
        }
    }

    const updateJob = (createJobArr) => {
        // setLoading(true);
        setCallDispatchAction(true);
        dispatch({
            type: UPDATE_JOB_TIMER,
            payload: createJobArr
        })
    }

    const copyJob = (createJobArr) => {
        // setLoading(true);
        setCallDispatchActionForCarryOver(true);
        if (unassignedFlag === false) {
            // dispatch({
            //   type: UPDATE_CARRY_OVER_JOB,
            //   payload: {
            //     id: jobIdData,
            //     timer_status: "pause",
            //     job_stage: "Inprogress",
            //     job_status: "OnHold"
            //   }
            // })
        }
        dispatch({
            type: CREATE_JOB,
            payload: createJobArr
        })
    }

    const createUnassignedJob = () => {
        // setLoading(true);
        setUnassignedFlag(true);
        handleSaveForm();
    }

    return (
        <>
            {
                loading ? <div className="page-loader d-flex align-center justify-center">
                    <img src={LoaderImage} alt="Loading"></img>
                </div> : ""
            }
            {
                modalType === '' ?
                    <Modal
                        className="reschedule-job-modal"
                        size="lg"
                        show={rescheduleShow}
                        onHide={handleRescheduleClose}
                        centered
                    >
                        <Modal.Header>
                            <Modal.Title className="">
                                <div className="row d-flex">
                                    <div className="col-sm-6">
                                        <span className="name">{employeeName}</span>
                                        <span> | </span>
                                        <span className="time">{titleDate} {titleTime}</span>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="job-id">JOB # {jobNumber}
                                            {
                                                (jobType === "carryover") ? <label className="job-label m-l-5" title="Carry Over Job">CO</label> : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="content-block">
                                <div className="row mb-3">
                                    <div className="col-sm-6 d-flex align-center">
                                        {
                                            (jobType === "carryover") ? <span className="display-index d-flex align-center justify-center m-r-10">1</span> : ''
                                        }

                                        <div className="date-block-outer">
                                            <Form.Group className="form-group date-picker d-flex align-center no-wrap">
                                                <label>Select Date</label>
                                                <DatePicker className="form-control"
                                                    defaultValue={moment(startEventTime)}
                                                    format={date_format}
                                                    onChange={(date) => changeBookedDate(date)}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    {
                                        (jobType === "carryover") ?
                                            <div className="col-sm-6 d-flex align-center no-wrap">
                                                <span className="display-index d-flex align-center justify-center m-r-10">2</span>
                                                <div className="date-block-outer d-flex align-center move-to-co">
                                                    <Form.Group className="form-group date-picker d-flex align-center no-wrap">
                                                        <label>Move CO Job </label>
                                                        <Button variant="outline-secondary" className="btn-outline outline-black height-34" onClick={unassignedDialogShow}>
                                                            Move to Unassigned
                                                        </Button>
                                                    </Form.Group>
                                                </div>
                                            </div> : ''
                                    }
                                </div>

                                <div className="schedular-blocks d-flex mb-4">
                                    <ul className="scheduler-person-list activity-list">
                                        <Scrollbars
                                            autoHeight
                                            autoHeightMax={160}
                                            className="custom-scrollbar"
                                            style={{ width: "100%" }}
                                            renderThumbVertical={(props) => (
                                                <div className="thumb-vertical grey-thumb" />
                                            )}
                                            renderTrackHorizontal={(props) => (
                                                <div style={{ display: "none" }} />
                                            )}
                                        >
                                            {
                                                employeeList.length !== 0 ?
                                                    employeeList.map((emp, index) => {
                                                        return (
                                                            <li key={emp.id} >
                                                                <Form.Check
                                                                    className="radio-btn mb-2"
                                                                    type="radio"
                                                                    id={emp.id}
                                                                >
                                                                    <Form.Check.Input
                                                                        checked={emp.id === employeeId ? true : false}
                                                                        name="tech"
                                                                        type="radio"
                                                                        data-id={emp.data_type}
                                                                        isValid
                                                                        value={emp.id}
                                                                        onChange={
                                                                            (e) => {
                                                                                setEmployeeId(emp.id);
                                                                                handleTechnicianSlot(e.target)
                                                                                setEmployeeName(emp.name);
                                                                            }
                                                                        }
                                                                        disabled={(emp.working_days === 0) ? true : false}
                                                                    />
                                                                    <Form.Check.Label className={(emp.working_days === 0) ? 'red-disable' : ''}>
                                                                        {emp.name}
                                                                        <span className="check"></span>
                                                                    </Form.Check.Label>
                                                                </Form.Check>
                                                            </li>
                                                        )
                                                    }) : ""
                                            }

                                        </Scrollbars>
                                    </ul>
                                    <ul className="schedular-list d-flex">
                                        {
                                            timeSlotsList.length !== 0 ?
                                                timeSlotsList.map((time, index) => {
                                                    // disabledSlots = (time.available === 0) ? disabled : '';
                                                    return (
                                                        <li key={time.start_time}>
                                                            <Form.Check
                                                                className="radio-btn mb-2"
                                                                type="radio"
                                                                id={time.slots}
                                                            >
                                                                <Form.Check.Input
                                                                    checked={time.start_time === timeSlot ? true : false}
                                                                    name="slots"
                                                                    type="radio"
                                                                    data-time={time.start_time}
                                                                    isValid
                                                                    value={time.slots}
                                                                    data-available={time.available}
                                                                    disabled={(time.available === 0) ? true : false}
                                                                    onChange={
                                                                        (e) => {
                                                                            setTimeSlot(time.start_time)
                                                                            setTitleTime(moment(time.start_time, 'HH:mm:ss').format(time_format))
                                                                            setDisabledSave('');
                                                                        }
                                                                    }
                                                                />
                                                                <Form.Check.Label>
                                                                    {time.slots}
                                                                    <span className="check"></span>
                                                                </Form.Check.Label>
                                                            </Form.Check>
                                                        </li>
                                                    )
                                                })
                                                : <div style={{ textAlign: "center" }}> No Slots available . </div>
                                        }
                                    </ul>
                                </div>
                                {/* <Accordion defaultActiveKey="0" className="small-accordion"> */}
                                <Accordion className="small-accordion">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <span>Pickup Time</span>
                                            <i className="la la-angle-down"></i>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className="form-outer">
                                                <div className="date-time-wrapper d-flex justify-space-between  mb-3">
                                                    <Form.Group className="form-group width-33 time-input">
                                                        <label>Job Estimate Time</label>
                                                        <Form.Control type="time" name="job_estimate_time" defaultValue={rescheduleFormData.job_estimate_time} onChange={(e) => handleRescheduleFormData(e)} />
                                                        <span>Hrs</span>
                                                    </Form.Group>
                                                    <Form.Group className="form-group date-picker width-33">
                                                        <label>Select Pickup Date</label>
                                                        <DatePicker
                                                            className="form-control"
                                                            format={date_format}
                                                            name="pickup_date"
                                                            // defaultValue={rescheduleFormData.pickup_date} 
                                                            onChange={(date) => handlePickupDate(date)}
                                                        />{" "}
                                                    </Form.Group>
                                                    <Form.Group className="form-group width-33 time-input">
                                                        <label>Select Pickup Time</label>
                                                        <Form.Control type="time"
                                                            name="pickup_time"
                                                            defaultValue={rescheduleFormData.pickup_time}
                                                            onChange={(e) => handleRescheduleFormData(e)}
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                            <span>Customer</span>
                                            <i className="la la-angle-down"></i>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className="customer-assets-block d-flex">
                                                <div className="left-block block-wrapper">
                                                    <div className="heading-block">
                                                        Asset Details
                                                    </div>
                                                    <div className="d-flex detail-outer">
                                                        <div className="detail-wrapper width-50">
                                                            <div className="d-flex detail-block">
                                                                <label>
                                                                    <i className="fa fa-car text-primary m-r-5 font-size-16 w-20"></i>
                                                                    <b>Rego</b>
                                                                </label>
                                                                <span>{rescheduleFormData.rego}</span>
                                                            </div>
                                                            <div className="d-flex detail-block">
                                                                <label>
                                                                    <i className="fa fa-calendar m-r-5 text-primary font-size-16 w-20"></i>
                                                                    <b>Year</b>
                                                                </label>
                                                                <span>{rescheduleFormData.year}</span>
                                                            </div>
                                                        </div>
                                                        <div className="detail-wrapper width-50">
                                                            <div className="d-flex detail-block">
                                                                <label>
                                                                    <b>Make</b>
                                                                </label>
                                                                <span>{rescheduleFormData.make}</span>
                                                            </div>
                                                            <div className="d-flex detail-block">
                                                                <label>
                                                                    <i className="fa fa-car text-primary m-r-5 font-size-16 w-20"></i>
                                                                    <b>Model</b>
                                                                </label>
                                                                <span>{rescheduleFormData.model}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="right-block block-wrapper">
                                                    <div className="heading-block">
                                                        Customer Details
                                                    </div>
                                                    <div className="d-flex detail-outer">
                                                        <div className="detail-wrapper">
                                                            <div className="d-flex detail-block">
                                                                <label>
                                                                    <i className="fa fa-user text-primary m-r-5 font-size-16 w-20"></i>
                                                                    <b>Customer</b>
                                                                </label>
                                                                <span>{rescheduleFormData.customer_name}</span>
                                                            </div>
                                                            <div className="d-flex detail-block row">
                                                                <div className="d-flex col-md-5 no-wrap">
                                                                    <label className="d-flex no-wrap">
                                                                        <i className="m-r-5 font-size-16 w-20 fa fa-2x text-primary fa-phone"></i>
                                                                        <b>Contact</b>
                                                                    </label>
                                                                    <span>{rescheduleFormData.mobile_number}</span>
                                                                </div>
                                                                <div className="d-flex col-md-7 no-wrap">
                                                                    <label className="d-flex no-wrap">
                                                                        <i className="m-r-5 font-size-16 w-20 fa fa-2x text-primary fa-envelope"></i>
                                                                        <b>Email</b>
                                                                    </label>
                                                                    <span>{rescheduleFormData.email}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="outline-secondary"
                                className="btn-outline rounded-btn"
                                onClick={handleRescheduleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                className={"rounded-btn btn-success " + disabledSave}
                                variant="primary"
                                onClick={handleSaveForm}
                            >
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal> :
                    <Modal
                        className="move-unassigned-dialog info-dialog"
                        size="sm"
                        show={unassignedDialog}
                        onHide={unassignedDialogClose}
                    >
                        <div className="dialog-title text-center mb-3">
                            Move CO Job to <span className="text-danger">Unassigned?</span>
                        </div>
                        <Modal.Body>
                            <div className="d-flex justify-center">
                                <Button
                                    variant="outline-secondary"
                                    className="btn-outline rounded-btn"
                                    onClick={() => unassignedDialogClose()}
                                >
                                    No
                                </Button>
                                <Button
                                    className="btn btn-primary rounded-btn"
                                    onClick={() => createUnassignedJob()}
                                >
                                    Yes
                                </Button>
                            </div>
                        </Modal.Body>
                    </Modal>
            }

        </>
    )
}

export default Reschedule;
