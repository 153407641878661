import Loadable from "react-loadable";

import { Loading } from "../common/navigation";

const PurchasesCreate = Loadable({
  loader: () => import("./components/PurchasesCreate"),
  loading: Loading,
});

const PurchasesEdit = Loadable({
  loader: () => import("./components/PurchasesEdit"),
  loading: Loading,
});

const PurchasesView = Loadable({
  loader: () => import("./components/PurchasesView"),
  loading: Loading,
});


const RaisedOrderReceipt = Loadable({
  loader: () => import("./components/RaisedPurchaseOrder/RaisedOrderReceipt"),
  loading: Loading,
});

const CompletedOrderReceipt = Loadable({
  loader: () => import("./components/CompletedPurchasedOrder/CompletedOrderReceipt"),
  loading: Loading,
});

const PurchaseReceipts = Loadable({
  loader: () => import("./components/PurchaseOrderReceipts/PurchaseReceipts"),
  loading: Loading,
});

const CreditedOrderReceipt = Loadable({
  loader: () => import("./components/PurchaseOrderCredits/CreditedOrderReceipt"),
  loading: Loading,
});

const PurchasesCredit = Loadable({
  loader: () => import("./components/PurchasesCredit"),
  loading: Loading,
});

const PurchasesEmail = Loadable({
  loader: () => import("./components/PurchasesEmail"),
  loading: Loading,
});

const PurchasesList = Loadable({
  loader: () => import("./components/PurchasesList"),
  loading: Loading,
});


export const routes = [
  {
    path: "/purchase-create",
    exact: true,
    name: "PurchasesCreate",
    element: PurchasesCreate,
  },
  {
    path: "/purchase-edit",
    exact: true,
    name: "PurchasesEdit",
    element: PurchasesEdit,
  },
  {
    path: "/purchase-view",
    exact: true,
    name: "PurchasesView",
    element: PurchasesView,
  },
  {
    path: "/raised-order-receipt",
    exact: true,
    name: "RaisedOrderReceipt",
    element: RaisedOrderReceipt,
  },
  {
    path: "/complete-order-receipt",
    exact: true,
    name: "CompletedOrderReceipt",
    element: CompletedOrderReceipt,
  },
  {
    path: "/credited-order-receipt",
    exact: true,
    name: "CreditedOrderReceipt",
    element: CreditedOrderReceipt,
  },
  {
    path: "/purchase-receipt",
    exact: true,
    name: "PurchaseReceipts",
    element: PurchaseReceipts,
  },
  {
    path: "/purchase-credit",
    exact: true,
    name: "PurchasesCredit",
    element: PurchasesCredit,
  },
  {
    path: "/purchase-email",
    exact: true,
    name: "PurchasesEmail",
    element: PurchasesEmail,
  },
  {
    path: "/purchase-list",
    exact: true,
    name: "PurchasesList",
    element: PurchasesList,
  }
];

export * from "./components";
