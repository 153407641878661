import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_OPEN_QUOTES_COUNT,
  GET_OPEN_QUOTES,
} from "../../../../store/openQuotes/actionTypes";
import { getOpenQuotes } from "../../../../store/openQuotes/actions";
import {
  GET_STATUS_COLOR,
  GET_JOBSMSTEMPLATE_LIST,
  GET_JOBEMAILTEMPLATE_LIST,
  SEARCH_QUOTE_JOBS,
  SUBMIT_JOB_SMSEMAIL_TEMPLATE,
  GET_WORKSHOP_TECHNICIAN,
} from "../../../../store/commonApiStore/actionTypes";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { searchQuoteJobsSuccess } from "../../../../store/commonApiStore/actions";

import { GET_WORKSHOP_BAYS } from "../../../../store/scheduler/actionTypes";
import { copyQuotes, deleteQuotes } from "../../../../helpers/backendHelper";
import DeleteRecords from "../../../../utils/deleteRecords";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import LoaderImage from "../../../../assets/images/page-loader.svg";
import convertJob from "../../../../assets/images/ic_convertjob.png";
import SendEmailModal from "../../../modals/sendEmailModal";
import SendSmsModal from "../../../modals/sendSmsModal";
import MoveToLost from "../../../modals/MoveToLost";
import { showError, showSuccess } from "../../../../utils/alertMessage";
import QuoteFooter from "../Footer";
import Reschedule from "views/scheduler/components/Reschedule";
import { addComma } from "../../../../utils/numberFunction";

const OpenQuotesList = () => {
  const [jobData, setJobData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [sendNotificationTo, setSendNotificationTo] = useState("");
  const [notificationTemplate, setNotificationTemplate] = useState("");
  const [emailModalShow, setEmailModalShow] = useState(false);
  const [rescheduleShow, setRescheduleShow] = useState(false);
  const [moveToLostModalShow, setMoveToLostlModalShow] = useState(false);
  const [sendSmsModal, setSendSmsModal] = useState(false);
  const [selectedRowEmail, setSelectedRowEmail] = useState({});
  const [selectedRowPhone, setSelectedRowPhone] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [updateCalendar, setUpdateCalendar] = useState(false);
  const navigate = useNavigate();

  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  let workshop_id = window.atob(storedData.workshop_id);
  const localStorageData = JSON.parse(localStorage.getItem("data"));
  const currencySymbol =
    localStorageData?.workshop?.workshop_other_details[0]?.country?.symbol ||
    "$";

  const dispatch = useDispatch();
  const send_page = "job_list";
  const deleteMsg = "Records deleted successfully";
  const dateFormat = storedData.date_format;
  const deletePayloadData = {
    payload: {
      action_type: "delete",
      ids: selectedRow,
    },
  };

  const { openQuotesCount } = useSelector((state) => state.OpenQuotesReducer);
  const {
    statusColor,
    loadingLogins,
    jobSmsData,
    jobEmailData,
    searchQuoteJobs,
  } = useSelector((state) => state.CommonApiStoreReducer);

  let emailTemplateData = {
    jobEmailData: jobEmailData ? jobEmailData : null,
  };
  let smsTemplateData = {
    jobSmsData: jobSmsData ? jobSmsData : null,
  };

  let shareAdditionalDetailsEmail = {
    job_id: [selectedRow],
    send_method: "email",
    table: "chkBookin",
    send_page: "quote",
    sendType: "",
  };
  let shareAdditionalDetailsSms = {
    job_id: [selectedRow],
    send_method: "sms",
    table: "chkBookin",
    send_page: "quote",
    sendType: "",
  };

  useEffect(() => {
    if (setTotalRecords !== 0) {
      setTotalRecords(openQuotesCount);
    }
  }, [openQuotesCount]);

  const dateFormatter = (cell, row) => {
    var responseDate = moment(cell).format(dateFormat);
    return responseDate;
  };

  const checkStatusColor = (cell, row) => {
    if (statusColor !== null) {
      return (
        <div
          style={{
            background: statusColor[cell],
          }}
          className="jobStatus"
        >
          {cell}
        </div>
      );
    }
  };

  useEffect(() => {
    dispatch({
      type: GET_WORKSHOP_TECHNICIAN,
      payload: {
        get: "all",
        conditions: { "Workshops.id": workshop_id },
        contain: {
          Users: {
            conditions: {
              "Users.workshop_id": workshop_id,
              "Users.role_id IN": [3, 7, 8, 9],
              "Users.is_deleted": 0,
            },
            fields: ["id", "workshop_id", "firstname", "lastname"],
          },
          "Users.EmployeesDetails": {
            fields: [
              "EmployeesDetails.id",
              "EmployeesDetails.user_id",
              "EmployeesDetails.shift_working_day",
              "EmployeesDetails.workshop_id",
            ],
          },
          WorkshopOtherDetails: {
            fields: [
              "shift_start_time",
              "shift_finish_time",
              "shift_start_day",
              "shift_finish_day",
              "id",
              "workshop_id",
              "schedule_details",
              "technician_timeslot",
            ],
          },
        },
        fields: ["id"],
      },
    });
    dispatch({
      type: GET_WORKSHOP_BAYS,
      payload: {
        get: "all",
        conditions: {
          workshop_id: workshop_id,
          status: 1,
          display_in_schedule: 1,
        },
        fields: ["id", "workshop_id", "name", "location"],
      },
    });
  }, []);

  const columns = [
    {
      dataField: "quote_number",
      text: "Quote No.",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_quote/${row.id}`);
        },
      },
      formatter: (cell, row) => {
        return (
          <div>
            {row.quote_number}
            {row.quote_type == 1 && (
              <span className="co-tag" style={{ padding: "0 5px" }}>
                E
              </span>
            )}
          </div>
        );
      },
    },
    {
      dataField: "created_on",
      text: "Date",
      formatter: dateFormatter,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_quote/${row.id}`);
        },
      },
    },
    {
      dataField: "user.customers_details[0]",
      text: "Contact Name",
      formatter: (data) => {
        return `${data?.firstname || ""} ${data?.lastname || ""}`;
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_quote/${row.id}`);
        },
      },
    },
    {
      dataField: "user.customers_details[0].mobile_number",
      text: "Contact Number",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_quote/${row.id}`);
        },
      },
    },
    {
      dataField: "vehicle.rego",
      text: "Rego",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_quote/${row.id}`);
        },
      },
    },
    {
      dataField: "job_sub_type.name",
      text: "Quote Type",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_quote/${row.id}`);
        },
      },
    },
    {
      dataField: "expiry_date",
      text: "Exp Date",
      formatter: (data) => {
        var responseDate = new Date(data);
        var currentDate = new Date();
        if (!data) {
          return null
        }
        else if (currentDate > responseDate) {
          return (
            <div style={{ color: "red" }}>
              {moment(data).format(dateFormat)}
            </div>
          );
        } else {
          return (
            <div style={{ color: "green" }}>
              {moment(data).format(dateFormat)}
            </div>
          );
        }
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_quote/${row.id}`);
        },
      },
    },
    {
      dataField: "total_cost",
      text: "Est Total",
      formatter: (data) => {
        return `${currencySymbol} ${addComma(data)}`;
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_quote/${row.id}`);
        },
      },
    },
    {
      dataField: "quote_status",
      text: "Status",
      formatter: (data) => {
        return <span className="rounded-pill pill-open">{data}</span>;
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          navigate(`/edit_quote/${row.id}`);
        },
      },
      formatter: checkStatusColor,
    },
    {
      dataField: "df1",
      isDummyField: true,
      text: "Action 1",
      formatter: (cell, row) => {
        return (
          <div className="btn-group toggle-hover">
            <button className="btn " type="button">
              <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
            </button>
            <div className="dropdown-list large-width">
              <li>
                <Link to={`/edit_quote/${row.id}`} className="" title="EDIT">
                  <span className="icon-wrapper">
                    <i className="fa fa-pencil text-info"></i>
                  </span>
                  <span>Edit</span>
                </Link>
              </li>
              <li onClick={() => copyQuote(row.id)}>
                <Link to="#" className=" " title="Copy Quote">
                  <span className="icon-wrapper">
                    <i className="fa fa-clone text-info"></i>
                  </span>
                  <span>Copy Quote</span>
                </Link>
              </li>
              <li onClick={() => convertToJob(row)}>
                <Link to="#" className=" " title="Convert To Job">
                  <span className="icon-wrapper">
                    <img
                      src={convertJob}
                      // style={{ width: "15%", height: "15%", marginRight: "8px" }}
                    />
                  </span>
                  <span>Convert To Job</span>
                </Link>
              </li>
              <li
                onClick={() => {
                  setSendSmsModal(!sendSmsModal);
                  setSelectedRowPhone({
                    phone: row.user.customers_details.mobile_number,
                  });
                  setSelectedRow(row.id);
                }}
              >
                <Link to="#" className=" " title="SMS">
                  <span className="icon-wrapper">
                    <label className="smsText">SMS</label>
                  </span>
                  <span>SMS</span>
                </Link>
              </li>
              <li
                onClick={() => {
                  setEmailModalShow(!emailModalShow);
                  setSelectedRowEmail({ email: row.user.email });

                  setSelectedRow(row.id);
                }}
              >
                <Link to="#" className=" " title="EMAIL">
                  <span className="icon-wrapper">
                    <i className="fa fa-envelope font-size-20 text-info"></i>
                  </span>
                  <span>Email</span>
                </Link>
              </li>
              <li
                onClick={() => {
                  setMoveToLostlModalShow(true);
                  setSelectedRow(row.id);
                }}
              >
                <Link to="#" className=" " title="Lost Quote">
                  <span className="moveToLost-icon icon-wrapper">
                    <i className="fa fa-times"></i>
                  </span>

                  <span>Move To Lost</span>
                </Link>
              </li>
              <li
                onClick={() => {
                  setShowDeleteModal(!showDeleteModal);
                  setSelectedRow(row.id);
                }}
              >
                <Link to="#" className="delete-link" title="DELETE">
                  <span className="icon-wrapper">
                    <span className="red-bg">
                      <i className="fa fa-minus"></i>
                    </span>
                  </span>
                  <span>Delete</span>
                </Link>
              </li>
            </div>
          </div>
        );
      },
    },
  ];

  const convertToJob = (row) => {
    setSelectedRowData(row);
    setRescheduleShow(true);
  };

  const copyQuote = async (id) => {
    setIsLoading(true);
    let data = {
      payload: {
        id: id.toString(),
      },
    };
    let response = await copyQuotes(data);
    if (response.code === 200) {
      showSuccess("Quote is copied Sucessfully");
      loadData();
      getTotalCount();
      setIsLoading(false);
    } else {
      showError(response.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (statusColor === null) {
      dispatch({
        type: GET_STATUS_COLOR,
        payload: {
          get: "all",
          conditions: {
            workshop_id: workshop_id,
          },

          fields: ["name", "color"],
        },
      });
    }

    loadData();
    getTotalCount();

    if (jobSmsData === null || jobEmailData === null) {
      dispatch({
        type: GET_JOBEMAILTEMPLATE_LIST,
        payload: {
          get: "all",
          conditions: {
            workshop_id: workshop_id,
            type: "script",
            template_type: "1",
            is_email: 1,
            is_deleted: 0,
          },
        },
      });

      dispatch({
        type: GET_JOBSMSTEMPLATE_LIST,
        payload: {
          get: "all",
          conditions: {
            workshop_id: workshop_id,
            type: "script",
            template_type: "1",
            is_sms: 1,
            is_deleted: 0,
          },
        },
      });
    }
  }, []);

  async function loadData(page = 1, pageLimit = 10) {
    dispatch({
      type: GET_OPEN_QUOTES,
      payload: {
        get: "all",
        conditions: {
          quote_status: "Open",
          "CustomerQuotes.workshop_id": Number(workshop_id),
        },
        contain: {
          Vehicles: {
            fields: [
              "Vehicles.id",
              "Vehicles.rego",
              "Vehicles.service_due_date",
              "Vehicles.created_on",
              "Vehicles.make",
              "Vehicles.model",
              "Vehicles.user_id",
            ],
          },
          Users: {
            conditions: {
              "Users.workshop_id": Number(workshop_id),
            },
            fields: ["id", "firstname", "email", "phone_number"],
          },
          "Users.CustomersDetails": {
            conditions: {
              workshop_id: Number(workshop_id),
            },
            fields: [
              "CustomersDetails.firstname",
              "CustomersDetails.lastname",
              "CustomersDetails.id",
              "CustomersDetails.user_id",
              "CustomersDetails.mobile_number",
              "CustomersDetails.mobile_code",
              "CustomersDetails.type",
              "CustomersDetails.company_name",
            ],
          },
          CustomerQuotesLabours: {
            fields: [
              "CustomerQuotesLabours.type",
              "CustomerQuotesLabours.id",
              "CustomerQuotesLabours.customer_quotes_id",
              "CustomerQuotesLabours.user_id",
            ],
          },
          JobSubTypes: {
            fields: ["JobSubTypes.name", "JobSubTypes.parent_id"],
          },
          WorkshopDepartmentsAccountsDetails: {
            fields: ["name"],
          },
        },
        limit: pageLimit,
        page: page,
        order: {
          "CustomerQuotes.id": "desc",
        },
        fields: [
          "CustomerQuotes.id",
          "CustomerQuotes.workshop_id",
          "CustomerQuotes.user_id",
          "CustomerQuotes.vehicle_id",
          "CustomerQuotes.job_department",
          "CustomerQuotes.job_type",
          "CustomerQuotes.employee_id",
          "CustomerQuotes.quote_number",
          "CustomerQuotes.description",
          "CustomerQuotes.total_cost",
          "CustomerQuotes.quote_status",
          "CustomerQuotes.created_on",
          "CustomerQuotes.quote_number",
          "CustomerQuotes.quote_type",
          "CustomerQuotes.expiry_date",
          "CustomerQuotes.created_on",
          "CustomerQuotes.parts_cost",
        ],
      },
    });
  }

  const getTotalCount = () => {
    dispatch({
      type: GET_OPEN_QUOTES_COUNT,
      payload: {
        get: "all",
        conditions: {
          quote_status: "Open",
          "CustomerQuotes.workshop_id": Number(workshop_id),
        },
        contain: {
          Vehicles: {
            fields: ["Vehicles.id", "Vehicles.user_id"],
          },
          Users: {
            conditions: {
              "Users.workshop_id": Number(workshop_id),
            },
            fields: ["id", "firstname", "email", "phone_number"],
          },
          "Users.CustomersDetails": {
            conditions: {
              workshop_id: Number(workshop_id),
            },
            fields: [
              "CustomersDetails.firstname",
              "CustomersDetails.lastname",
              "CustomersDetails.id",
              "CustomersDetails.user_id",
            ],
          },
          CustomerQuotesLabours: {
            fields: [
              "CustomerQuotesLabours.type",
              "CustomerQuotesLabours.id",
              "CustomerQuotesLabours.customer_quotes_id",
              "CustomerQuotesLabours.user_id",
            ],
          },
          JobSubTypes: {
            fields: ["JobSubTypes.name", "JobSubTypes.parent_id"],
          },
          WorkshopDepartmentsAccountsDetails: {
            fields: ["name"],
          },
        },

        order: {
          "CustomerQuotes.id": "desc",
        },
        fields: {
          COUNT: "COUNT(CustomerQuotes.id)",
        },
      },
    });
  };

  const openQuotes = useSelector((state) => state.OpenQuotesReducer);

  useEffect(() => {
    if (Object.keys(searchQuoteJobs).length !== 0) {
      var text = [];

      let totalSearchCount = 0;
      for (var key = 0; key < 10; key++) {
        if (searchQuoteJobs[key] !== undefined) {
          totalSearchCount = searchQuoteJobs.total_count;
          text.push(searchQuoteJobs[key]);
        }
      }
      setTotalRecords(totalSearchCount);

      setJobData(text);
    } else {
      if (openQuotes.openQuotesData) {
        setJobData(openQuotes.openQuotesData);
      }
    }
  }, [openQuotes]);

  const expandRow = {
    showExpandColumn: true,

    renderer: (row) => (
      <div>
        <span dangerouslySetInnerHTML={{ __html: row.description }}></span>
      </div>
    ),
  };

  const onTableChange = (type, { page, sizePerPage }) => {
    setCurrentPage(page);
    setSizePerPage(sizePerPage);

    setCurrentPage(page);
    setSizePerPage(sizePerPage);

    if (search === "") {
      loadData(page, sizePerPage);
    } else {
      let text = [];
      for (
        var key = (page - 1) * sizePerPage;
        key < sizePerPage * page;
        key++
      ) {
        if (searchQuoteJobs[key] !== undefined) text.push(searchQuoteJobs[key]);
      }
      setJobData(text);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);

    if (e.target.value !== "") {
      dispatch({
        type: SEARCH_QUOTE_JOBS,
        payload: {
          workshop_id: workshop_id,
          type: "Open",
          keyword: e.target.value,
        },
      });
    } else {
      dispatch(searchQuoteJobsSuccess({}));
      setJobData(openQuotes.openQuotesData);
      setTotalRecords(openQuotesCount);
    }
  };

  const checkBox = {
    mode: "checkbox",
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedRow([...selectedRow, row.id]);
      } else {
        let rowSelected = [...selectedRow];

        const index = rowSelected.indexOf(row.id);
        if (index > -1) {
          rowSelected.splice(index, 1);
        }
        setSelectedRow(rowSelected);
      }
    },
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {},
  };

  const emptyDataMessage = () => {
    return (
      <div style={{ textAlign: "center" }}>No data available in table</div>
    );
  };

  const sendNotification = () => {
    if (selectedRow.length !== 0) {
      if (sendNotificationTo) {
        if (notificationTemplate) {
          dispatch({
            type: SUBMIT_JOB_SMSEMAIL_TEMPLATE,
            payload: {
              workshop_id: workshop_id,
              job_id: selectedRow,
              template_id: notificationTemplate,
              send_method: sendNotificationTo,
              table: "chkBookin",
              send_type: "bulk",
            },
          });
        } else {
          showError("Please select the Template");
        }
      } else {
        showError("Please select send method SMS / Email");
      }
    } else {
      showError("Please select the Quote");
    }
  };

  const handleEmailModalClose = () => setEmailModalShow(false);
  const handleSMSModalClose = () => setSendSmsModal(false);
  const handleMoveToLostModalClose = () => setMoveToLostlModalShow(false);
  const handleRescheduleClose = () => setRescheduleShow(false);

  return (
    <>
      {openQuotes.loading || loadingLogins || isLoading ? (
        <div className="page-loader d-flex align-center justify-center">
          <img src={LoaderImage} alt="Loading"></img>
        </div>
      ) : (
        <div>
          <div className="table-search-wrapper">
            <input
              type="text"
              className="form-control"
              id="search"
              placeholder="Search..."
              onChange={(e) => handleSearch(e)}
              value={search}
            />
            {/* <Button
              className="btn-primary rounded-btn"
              onClick={() => navigate("/quote_create")}
            >
              <i className="fa fa-plus"></i> Create Quote
            </Button> */}

            <label className="dropdown">
              <div className="dd-button btn btn-primary rounded-btn dropdown-toggle">
                Options
              </div>
              <input type="checkbox" className="dd-input" id="test" />
              <ul className="dd-menu">
                <li onClick={() => navigate(`/quote_create`)}>Create Quote</li>
                <li onClick={() => navigate(`/quote_estimate`)}>
                  Create Estimate
                </li>
              </ul>
            </label>
          </div>

          <PaginationProvider
            pagination={paginationFactory({
              custom: true,
              page: currentPage,
              sizePerPage: sizePerPage,
              totalSize: Number(totalRecords),
              nextPageText: "Next",
              prePageText: "Previous",
            })}
          >
            {({ paginationProps, paginationTableProps }) => (
              <div>
                <BootstrapTable
                  remote
                  keyField="id"
                  data={jobData}
                  columns={columns}
                  onTableChange={onTableChange}
                  selectRow={checkBox}
                  rowEvents={rowEvents}
                  expandRow={expandRow}
                  noDataIndication={emptyDataMessage}
                  {...paginationTableProps}
                />
                <div>
                  <div className="table-pagination">
                    <div>
                      <span>Show</span>
                      <SizePerPageDropdownStandalone {...paginationProps} />
                      <span>entries</span>
                    </div>
                    <PaginationTotalStandalone {...paginationProps} />
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                </div>
              </div>
            )}
          </PaginationProvider>
          {showDeleteModal && (
            <DeleteRecords
              showDeleteModal={setShowDeleteModal}
              refreshData={loadData}
              api={deleteQuotes}
              selectedRow={selectedRow}
              message={deleteMsg}
              payloadData={deletePayloadData}
            />
          )}

          <SendEmailModal
            emailModalShow={emailModalShow}
            handleEmailModalClose={handleEmailModalClose}
            emailTemplateData={emailTemplateData}
            getEmailForEmailModal={selectedRowEmail}
            shareAdditionalDetailsEmail={shareAdditionalDetailsEmail}
            send_page={send_page}
            setIsLoading={setIsLoading}
          />
          <SendSmsModal
            SMSModalShow={sendSmsModal}
            handleSMSModalClose={handleSMSModalClose}
            smsTemplateData={smsTemplateData}
            getPhoneForPhoneModal={selectedRowPhone}
            shareAdditionalDetailsSms={shareAdditionalDetailsSms}
            send_page={send_page}
            setIsLoading={setIsLoading}
          />

          <MoveToLost
            selectedRow={selectedRow}
            moveToLostModalShow={moveToLostModalShow}
            handleSMSModalClose={handleMoveToLostModalClose}
            refreshData={loadData}
          />

          <QuoteFooter
            selectedRow={selectedRow}
            refreshData={loadData}
            jobSmsData={jobSmsData}
            jobEmailData={jobEmailData}
            setSendNotificationTo={setSendNotificationTo}
            setNotificationTemplate={setNotificationTemplate}
            sendNotification={sendNotification}
            activeTab={"open"}
          />
          {rescheduleShow && (
            <Reschedule
              rescheduleShow={rescheduleShow}
              handleRescheduleClose={handleRescheduleClose}
              jobIdData={selectedRowData.id}
              startEventTime={new Date()}
              technicianName={
                selectedRowData.user.customers_details[0].firstname +
                " " +
                selectedRowData.user.customers_details[0].lastname
              }
              jobNumber={selectedRowData.quote_number}
              setUpdateCalendar={setUpdateCalendar}
              jobType={selectedRowData.quote_status}
              job_details={selectedRowData}
              refreshData={loadData}
            ></Reschedule>
          )}
        </div>
      )}
    </>
  );
};

export default OpenQuotesList;
