import { post, apiCall, thirdPartyApiCall, regoLookApiCall } from "./apiHelper";
import * as url from "./urlHelper";

//Login
export const getLogin = (data) => post(url.GET_LOGIN, data);

//Registration
export const getRegister = (data) => post(url.GET_REGISTER, data);
export const sendOtp = (data) => post(url.SEND_OTP, data);
export const confirmOtp = (data) => post(url.CONFIRM_OTP, data);
export const existEmail = (data) => post(url.EXIST_EMAIL, data);
export const getCountry = (data) => post(url.GET_COUNTRY, data);
export const getState = (data) => post(url.GET_STATE, data);
export const getIndustry = (data) => post(url.GET_INDUSTRY, data);
export const getAccountingPackage = (data) =>
  post(url.GET_ACCOUNTING_PACKAGE, data);
export const getSizeOfWorkshop = (data) => post(url.GET_SIZE_OF_WORKSHOP, data);
export const getReferralAdvertise = (data) =>
  post(url.GET_REFERRAL_ADVERTISE, data);

//Forgot Password
export const getForgotPassword = (data) => post(url.GET_FORGOT_PASSWORD, data);

//Forgot Email
export const getForgotEmail = (data) => post(url.GET_FORGOT_EMAIL, data);

/***************************************
 ░░░░ Jobs ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
****************************************/

/*Unassigned jobs*/
export const getJobs = (data) =>
  apiCall(url.GET_JOBS, data, "POST", true);

export const getUnassignedJobs = (data) =>
  apiCall(url.GET_JOBS, data, "POST", true);

//assigned jobs
export const getassignedJobs = (data) =>
  apiCall(url.GET_JOBS, data, "POST", true);

//inProgress job
export const getinProgressJobs = (data) =>
  apiCall(url.GET_JOBS, data, "POST", true);

//completed job
export const getcompletedJobs = (data) =>
  apiCall(url.GET_JOBS, data, "POST", true);

// delete job
export const deleteJobs = (data) =>
  apiCall(url.DELETE_JOBS, data, "POST", true);

//User Profile
export const getUserProfile = (data) =>
  apiCall(url.GET_USER_PROFILE, data, "POST", true);
export const updateUserProfile = (data) =>
  apiCall(url.UPDATE_USER_PROFILE, data, "POST", true);

//Notification
export const getNotification = (data) =>
  apiCall(url.GET_NOTIFICATION, data, "POST", true);

//Change Password
export const getChangePassword = (data) =>
  apiCall(url.GET_CHANGE_PASSWORD, data, "POST", true);

//Get subscription other detail (SMS and REgo lookup data)
export const getSubscriptionAddon = (data) =>
  apiCall(url.GET_SUBSCRIPTION_ADDON, data, "POST", true);
export const getSubscription = (data) =>
  apiCall(url.GET_SUBSCRIPTION, data, "POST", true);

// Customer lists
export const getCustomerLists = (data) =>
  apiCall(url.GET_CUSTOMER_LIST, data, "POST", true);

// Customer and Vehicle details
export const getCustomerAndVehicleDetails = (data) =>
  apiCall(url.GET_CUSTOMER_AND_VEHICLE_DETAILS, data, "POST", true);

// Job department list
export const getJobDepartmentList = (data) =>
  apiCall(url.GET_JOB_DEPARTMENT_LIST, data, "POST", true);

// Job type list
export const getJobTypeList = (data) =>
  apiCall(url.GET_JOB_TYPE_LIST, data, "POST", true);

//Job canned list
export const getJobCannedList = (data) =>
  apiCall(url.GET_JOBCANNED_LIST, data, "POST", true);

//Job sms template list
export const getJobSmsTemplateList = (data) =>
  apiCall(url.GET_JOBSMSTEMPLATE_LIST, data, "POST", true);

//Job email template list
export const getJobEmailTemplateList = (data) =>
  apiCall(url.GET_JOBEMAILTEMPLATE_LIST, data, "POST", true);

//Job submit email template
export const submitJobEmailTemplate = (data) =>
  apiCall(url.Save_JOBEMAILTEMPLATE, data, "POST", true);

//Job submit sms template
export const submitJobSmsTemplate = (data) =>
  apiCall(url.Save_JOBSMSTEMPLATE, data, "POST", true);

//Job submit sms-email template
export const submitJobSmsEmailTemplate = (data) =>
  apiCall(url.Save_JOBSMSTEMPLATE, data, "POST", true);

//Job Create
export const createJob = (data) => apiCall(url.CREATE_JOB, data, "POST", true);

//Marketing Create
export const createMarketing = (data) => apiCall(url.CREATE_MARKETING, data, "POST", true);

// list of selected vehicle model
export const getVehicles = (data) =>
  apiCall(url.GET_VEHICLES, data, "POST", true);

// list of selected vehicle model
export const getVehicleModel = (data) =>
  apiCall(url.GET_VEHICLESMODEL, data, "POST", true);

// list of selected vehicle series

export const getVehicleSeries = (data) =>
  apiCall(url.GET_VEHICLESERIES, data, "POST", true);

// list of assets details
export const getAssets = (data) => apiCall(url.GET_ASSETS, data, "POST", true);

// list of custom fields
export const getCustomFields = (data) =>
  apiCall(url.GET_CUSTOM_FIELDS, data, "POST", true);

//check vin number exist or not
export const checkVinExist = (data) =>
  apiCall(url.GET_CUSTOMER_AND_VEHICLE_DETAILS, data, "POST", true);

//check chasis number exist or not
export const checkChasisExist = (data) =>
  apiCall(url.GET_CUSTOMER_AND_VEHICLE_DETAILS, data, "POST", true);

//Check engine exist or not
export const checkEngineExist = (data) =>
  apiCall(url.GET_CUSTOMER_AND_VEHICLE_DETAILS, data, "POST", true);

//check fleet exist or not
export const checkFleetExist = (data) =>
  apiCall(url.GET_CUSTOMER_AND_VEHICLE_DETAILS, data, "POST", true);

// Get assets master table details
export const getAssetsMaster = (data) =>
  apiCall(url.GET_VEHICLES, data, "POST", true);

// Get assets details table details
export const getAssetsDetails = (data) =>
  apiCall(url.GET_VEHICLESERIES, data, "POST", true);

// Add assets details
export const addAssetsDetails = (data) =>
  apiCall(url.ADD_ASSETS_DETAILS, data, "POST", true);

// Add assets master details
export const addAssetsMaster = (data) =>
  apiCall(url.ADD_ASSETS_MASTER, data, "POST", true);

// Add vehicle details 
export const addAssets = (data) => apiCall(url.ADD_ASSETS, data, "POST", true);

export const saveUserRequest = (data) => {
  apiCall(url.SAVE_USER_REQUEST, data, "POST", true);
};

export const getVehicleHistoryDetails = (data) =>
  apiCall(url.GET_JOB_BOOKED_DETAILS, data, "POST", true);

export const addCustomer = (data) => post(url.ADD_CUSTOMER, data);

/***************************************
 ░░░░ Job Detail START ░░░░░░░░░░░░░░░░░
****************************************/
// get job notes
export const getJobNotes = (data) =>
  apiCall(url.GET_JOB_NOTES, data, "POST", true);

// add job notes
export const addJobNotes = (data) =>
  apiCall(url.ADD_JOB_NOTES, data, "POST", true);

// update job notes
export const updateJobNotes = (data) =>
  apiCall(url.ADD_JOB_NOTES, data, "POST", true);

/***************************************
 ░░░░ Job Detail END ░░░░░░░░░░░░░░░░░
****************************************/

/***************************************
 ░░░░ Global Search ░░░░░░░░░░░░░░░░░░░░
****************************************/
export const getGlobalSearch = (data) =>
  apiCall(url.GET_GLOBAL_SEARCH, data, "POST", true);

/***************************************
 ░░░░ Scheduler ░░░░░░░░░░░░░░░░░░░░░░░░
****************************************/
export const getTechnician = (data) =>
  apiCall(url.GET_TECHNICIAN, data, "POST", true);
export const getWorkshopOtherDetailsView = (data) =>
  apiCall(url.GET_WORKSHOP_OTHER_DETAILS_VIEW, data, "POST", true);
export const getWorkshopOtherDetails = (data) =>
  apiCall(url.GET_WORKSHOP_OTHER_DETAILS, data, "POST", true);
export const getTechnicianHour = (data) =>
  apiCall(url.GET_TECHNICIAN_HOUR, data, "POST", true);
export const getWorkshopBays = (data) =>
  apiCall(url.GET_WORKSHOP_BAYS, data, "POST", true);
export const getInsurance = (data) =>
  apiCall(url.GET_INSURANCE, data, "POST", true);
export const getJobTags = (data) =>
  apiCall(url.GET_JOB_TAGS, data, "POST", true);
export const addJobTags = (data) => post(url.ADD_JOB_TAGS, data);
export const printJobCardPdf = (data) => post(url.PRINT_JOB_CARD_PDF, data);
export const checkAvailableHours = (data) =>
  apiCall(url.CHECK_AVAILABLE_HOURS, data, "POST", true);
export const getWorkshopTechnician = (data) =>
  apiCall(url.GET_WORKSHOP_TECHNICIAN, data, "POST", true);
export const createQuoteToJob = (data) => 
  apiCall(url.CREATE_JOB, data, "POST", true);
export const getAvailableSlots = (data) =>
  apiCall(url.GET_AVAIALABLE_SLOTS, data, "POST", true);
export const getCarryOverJob = (data) =>
  apiCall(url.GET_JOBS, data, "POST", true);
export const getJobsKitParts = (data) =>
  apiCall(url.GET_JOBS_KIT_PARTS, data, "POST", true);
export const getJobsKitLabours = (data) =>
  apiCall(url.GET_JOBS_KIT_LABOURS, data, "POST", true);
export const addManyJobParts = (data) =>
  apiCall(url.ADD_MANY_JOB_PARTS, data, "POST", true);
export const addManyJobLabours = (data) =>
  apiCall(url.ADD_MANY_JOB_LABOURS, data, "POST", true);
export const addManyJobKitParts = (data) =>
  apiCall(url.ADD_MANY_JOB_KIT_PARTS, data, "POST", true);
export const addManyJobKitLabours = (data) =>
  apiCall(url.ADD_MANY_JOB_KIT_LABOURS, data, "POST", true);
export const getActivity = (data) =>
  apiCall(url.GET_ACTIVITY, data, "POST", true);
export const getScheduleActivity = (data) =>
  apiCall(url.GET_SCHEDULE_ACTIVITY, data, "POST", true);
export const addActivity = (data) =>
  apiCall(url.ADD_ACTIVITY, data, "POST", true);
export const addScheduleActivity = (data) =>
  apiCall(url.ADD_SCHEDULE_ACTIVITY, data, "POST", true);
export const addManyCreateJob = (data) =>
 apiCall(url.ADD_MANY_CREATE_JOB, data, "POST", true);
export const deleteJobActivity = (data) =>
 apiCall(url.DELETE_JOB_ACTIVITY, data, "POST", true);
export const deleteJobScheduleActivity = (data) =>
 apiCall(url.DELETE_JOB_SCHEDULE_ACTIVITY, data, "POST", true);
export const getMonthJobList = (data) =>
 apiCall(url.GET_JOBS, data, "POST", true);
export const updateCarryOverJob = (data) =>
  apiCall(url.UPDATE_JOB_TIMER, data, "POST", true);
export const getTimerDetails = (data) =>
  apiCall(url.GET_JOB_BOOKED_DETAILS, data, "POST", true);
export const getOpenRequestJob = (data) =>
  apiCall(url.GET_JOB_BOOKED_DETAILS, data, "POST", true);
export const getQuoteDetails = (data) =>
  apiCall(url.GET_QUOTES, data, "POST", true);
export const getQuoteKitParts = (data) =>
  apiCall(url.GET_QUOTE_KIT_PART, data, "POST", true);
export const getQuoteKitLabours = (data) =>
  apiCall(url.GET_QUOTE_KIT_LABOUR, data, "POST", true);


/****************************************
 ░░░░ Quotes ░░░░░░░░░░░░░░░░░░░░░░░░░░░░
*****************************************/
export const getOpenQuotes = (data) =>
  apiCall(url.GET_QUOTES, data, "POST", true);
export const getWonQuotes = (data) =>
  apiCall(url.GET_QUOTES, data, "POST", true);
export const getLostQuotes = (data) =>
  apiCall(url.GET_QUOTES, data, "POST", true);
export const editQuotes = (data) =>
  apiCall(url.EDIT_QUOTES, data, "POST", true);
export const getQuoteBookedDetails = (data) =>
  apiCall(url.GET_QUOTES, data, "POST", true);

/******************************************************************
 ░░░░ Workshop / Workshop Other Detail END ░░░░░░░░░░░░░░░░░░░░░░░░░░░░
*******************************************************************/
export const updateWorkshopOtherDetail = (data) =>
  apiCall(url.UPDATE_WORKSHOP_OTHER_DETAILS, data, "POST", true);
export const getWorkshopSubscriptionDetailsList = (data) =>
  apiCall(url.GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST, data, "POST", true);

// get workshop markup detail
export const getWorkshopMarkupDetail = (data) =>
  apiCall(url.GET_WORKSHOP_MARKUP_DETAILS, data, "POST", true);

// get workshop markup lists
export const getWorkshopMarkupList = (data) =>
  apiCall(url.GET_WORKSHOP_MARKUP_LIST, data, "POST", true);

// delete workshop markup
export const deleteWorkshopMarkup = (data) =>
  apiCall(url.DELETE_WORKSHOP_MARKUP, data, "POST", true);

// get workshop TAX / RATES detail
export const getWorkshopTaxDetail = (data) =>
  apiCall(url.GET_WORKSHOP_TAX_DETAILS, data, "POST", true);

/******************************************************************
 ░░░░ Workshop / Workshop Other Detail END ░░░░░░░░░░░░░░░░░░░░░░░░░░░░
*******************************************************************/

/***************
 ░░░░ PARTS ░░░░
****************/
// get parts / product list, Search parts
export const getPartList = (data) =>
  apiCall(url.GET_PART_LIST, data, "POST", true);

// get Status
export const getStatusColor = (data) =>
  apiCall(url.GET_STATUS_COLOR, data, "POST", true);

//save parts / products
export const savePart = (data) => apiCall(url.SAVE_PART, data, "POST", true);

//delete parts / products
export const deletePart = (data) =>
  apiCall(url.DELETE_PART, data, "POST", true);

/***************
 ░░░░ PARTS ░░░░
****************/

/***************
 ░░░░ JOB PARTS ░░░░
****************/
// get job parts
export const getJobPartList = (data) =>
  apiCall(url.GET_JOB_PART_LIST, data, "POST", true);

// save job parts
export const saveJobPart = (data) =>
  apiCall(url.SAVE_JOB_PART, data, "POST", true);

// delete job parts
export const deleteJobPart = (data) =>
  apiCall(url.DELETE_JOB_PART, data, "POST", true);

export const updateJobPart = (data) =>
  apiCall(url.UPDATE_JOB_PART, data, "POST", true);

// save job parts many
export const editJobPartMany = (data) =>
  apiCall(url.EDIT_JOB_PART_MANY, data, "POST", true);


  // save job kit parts many
export const editJobKitPartMany = (data) =>
apiCall(url.EDIT_JOB_KIT_PART_MANY, data, "POST", true);

// save job kit parts many
export const editJobKitLabourMany = (data) =>
  apiCall(url.EDIT_JOB_KIT_LABOUR_MANY, data, "POST", true);

/***************
 ░░░░ JOB  PARTS ░░░░
****************/

// get job booked details
export const getJobBookedDetails = (data) =>
  apiCall(url.GET_JOB_BOOKED_DETAILS, data, "POST", true);

// get rego look up data
export const getRegoLookUpDetails = (data) =>
apiCall(url.GET_REGO_LOOKUP_DETAILS, data, "POST", false);

// update job timer data
export const updateJobTimer = (data) =>
  apiCall(url.UPDATE_JOB_TIMER, data, "POST", true);

// update job timer data
export const updateJob = (data) =>
  apiCall(url.UPDATE_JOB_TIMER, data, "POST", true);

// update vehicle data
export const updateVehicle = (data) =>
  apiCall(url.UPDATE_VEHICLE, data, "POST", true);


// get job kit part list
export const getJobKitPart = (data) =>
apiCall(url.GET_JOB_KIT_PART, data, "POST", true);

// get job kit part list
export const getJobKitLabour = (data) =>
apiCall(url.GET_JOB_KIT_LABOUR, data, "POST", true);

export const saveJobKitPart = (data) =>
apiCall(url.SAVE_JOB_KIT_PART, data, "POST", true);

export const deleteJobKitPart = (data) =>
apiCall(url.DELETE_JOB_KIT_PART, data, "POST", true);

/***********************
 ░░░░ Labour START ░░░░
************************/

// get default labour charge list
export const getLabourChargesList = (data) =>
  apiCall(url.GET_LABOUR_CHARGES_LIST, data, "POST", true);

// get labour list
export const getJobLabourList = (data) =>
  apiCall(url.GET_JOB_LABOUR_LIST, data, "POST", true);

//save job labour
export const saveJobLabour = (data) =>
  apiCall(url.SAVE_JOB_LABOUR, data, "POST", true);

// delete job Labour
export const deleteLabourPart = (data) =>
  apiCall(url.DELETE_LABOUR_PART, data, "POST", true);

// delete job Labour
export const updateLabourPart = (data) =>
  apiCall(url.UPDATE_LABOUR_PART, data, "POST", true);

//update job labour
export const updateJobLabour = (data) =>
  apiCall(url.UPDATE_LABOUR_PART, data, "POST", true);


//edit job labour many
export const editJobLabourMany = (data) =>

apiCall(url.EDIT_JOB_LABOUR_MANY, data, "POST", true);
  // quote start
//save quote labour
export const saveQuoteLabour = (data) =>
apiCall(url.SAVE_QUOTE_LABOUR, data, "POST", true);
// get labour list
export const getQuoteLabourList = (data) =>
  apiCall(url.GET_QUOTE_LABOUR_LIST, data, "POST", true);
// delete job Labour
export const deleteQuoteLabourPart = (data) =>
  apiCall(url.DELETE_QUOTE_LABOUR_PART, data, "POST", true);
// delete job Labour
export const updateQuoteLabour = (data) =>
  apiCall(url.UPDATE_QUOTE_LABOUR_PART, data, "POST", true);
 //edit job labour many
 export const editQuoteLabourMany = (data) =>
 apiCall(url.EDIT_QUOTE_LABOUR_MANY, data, "POST", true);


/***********************
 ░░░░ Labour END ░░░░
************************/
/************************************
 ░░░░░░░░░░░░ Inspection ░░░░░░░░░░░░ 
*************************************/
export const getInspections = (data) =>
  apiCall(url.GET_INSPECTIONS, data, "POST", true);

export const getInspectionTemplates = (data) =>
  apiCall(url.GET_INSPECTION_TEMPLATES, data, "POST", true);

export const updateInspection = (data) =>
  apiCall(url.UPDATE_INPECTION, data, "POST", true);

/***********************
 ░░░░ Supplier START ░░░░
************************/

// get Supplier / Supplier list, Search Supplier
export const getSupplierList = (data) =>
  apiCall(url.GET_SUPPLIER_LIST, data, "POST", true);

//save Supplier
export const addSupplier = (data) =>
  apiCall(url.ADD_SUPPLIER, data, "POST", true);

/***********************
 ░░░░ Supplier END ░░░░
************************/
// Settings Company Detail Page
export const getTimeZone = (data) => post(url.GET_TIMEZONE, data);
export const getWorkShopDetail = (data) => post(url.GET_WORK_SHOP_DETAIL, data);
export const getWorkShopOtherDetail = (data) =>
  post(url.GET_WORK_SHOP_OTHER_DETAIL, data);
export const updateCompanyDetail = (data) =>
  apiCall(url.UPDATE_COMPANY_DETAIL, data, "POST", true);
export const updateCompanyOtherDetail = (data) =>
  apiCall(url.UPDATE_COMPANY_OTHER_DETAIL, data, "POST", true);
export const getWorkShopSMS = (data) => post(url.GET_WORK_SHOP_SMS, data);
export const updateWorkShopSMS = (data) =>
  apiCall(url.UPDATE_WORKSHOP_SMS, data, "POST", true);
export const addWorkShopMarkup = (data) =>
  apiCall(url.SAVE_WORKSHOP_MARKUP_DETAILS, data, "POST", true);
export const updateWorkShopMarkup = (data) =>
  apiCall(url.UPDATE_WORKSHOP_MARKUP_DETAILS, data, "POST", true);

export const updateDefaultWorkShopMarkup = (data) =>
  apiCall(url.UPDATE_DEFAULT_WORKSHOP_MARKUP_DETAILS, data, "POST", true);

// Setting Default Page

//get inspections view
export const getInspectionView = (data) =>
  apiCall(url.GET_INSPECTION_VIEW, data, "POST", true);

//get internal notes
export const getInternalNotes = (data) =>
  apiCall(url.GET_INTERNAL_NOTES, data, "POST", true);

//Job internalnotes scripts
export const getInternalNotesScripts = (data) =>
  apiCall(url.GET_JOBCANNED_LIST, data, "POST", true);

// add internal  notes
export const addInternalNotes = (data) =>
  apiCall(url.ADD_INTERNAL_NOTES, data, "POST", true);

// Check mobile number exist
export const checkMobileExist = (data) =>
  apiCall(url.GET_CUSTOMER_LIST, data, "POST", true);

// Check phone number exist
export const checkPhoneExist = (data) =>
  apiCall(url.GET_USER_PROFILE, data, "POST", true);

// delete internal notes
export const deleteInternalNotes = (data) =>
  apiCall(url.DELETE_INTERNAL_NOTES, data, "POST", true);

/******************************************************************
 ░░░░ Setting / Default / Labour Charge Start ░░░░░░░░░░░░░░░░░░░░░░░░░░░░
*******************************************************************/

// get labour charge list
export const getLabourChargeList = (data) =>
  apiCall(url.GET_LABOUR_CHARGE, data, "POST", true);

// Add labour charge
export const saveLabourChargeDetails = (data) =>
  apiCall(url.SAVE_LABOUR_CHARGE, data, "POST", true);

// Update labour charge
export const updateLabourChargeDetail = (data) =>
  apiCall(url.UPDATE_LABOUR_CHARGE, data, "POST", true);

// delete labour charge
export const deleteLabourCharge = (data) =>
  apiCall(url.DELETE_LABOUR_CHARGE, data, "POST", true);

// update all labour charge default as 0
export const updateDefaultLabourCharge = (data) =>
  apiCall(url.UPDATE_DEFAULT_LABOUR_CHARGE, data, "POST", true);

/******************************************************************
░░░░ Setting / Default / Labour Charge END ░░░░░░░░░░░░░░░░░░░░░░░░░░░░
*******************************************************************/

/******************************************************************
 ░░░░ Setting / Default / Asset Type Start ░░░░░░░░░░░░░░░░░░░░░░░░░░░░
*******************************************************************/

// get labour charge list
export const getCustomVehicleLists = (data) =>
  apiCall(url.GET_CUSTOM_VEHICLE, data, "POST", true);

// Add labour charge
export const saveCustomVehicleDetails = (data) =>
  apiCall(url.SAVE_CUSTOM_VEHICLE, data, "POST", true);

// Update labour charge
export const updateCustomVehicleDetail = (data) =>
  apiCall(url.UPDATE_CUSTOM_VEHICLE, data, "POST", true);

// delete labour charge
export const deleteCustomVehicle = (data) =>
  apiCall(url.DELETE_CUSTOM_VEHICLE, data, "POST", true);

/******************************************************************
░░░░ Setting / Default / Asset Type END ░░░░░░░░░░░░░░░░░░░░░░░░░░░░
*******************************************************************/
// save internal notes attachments
export const saveInternalNotesAttachments = (data) =>
  apiCall(url.SAVE_INTERNAL_NOTES_ATTACHMENTS, data, "POST", true);
// search JobSchedules
export const jobSchedulesSearch = (data) =>
  apiCall(url.JOBSCHEDULESSEARCH, data, "POST", true);

// check referal code vaild or not
export const applyCode = (data) => apiCall(url.APPLY_CODE, data, "POST", true);

/***********************
 ░░░░ Settings: Security Group START  ░░░░
************************/
// GET Security group
export const getSecruityGroup = (data) =>
  apiCall(url.GETSECURITYGROUP, data, "POST", true);

// ADD security group
export const addSecruityGroup = (data) =>
  apiCall(url.ADDSECURITYGROUP, data, "POST", true);
// update security group
export const updateSecruityGroup = (data) =>
  apiCall(url.UPDATESECURITYGROUP, data, "POST", true);

/***********************
 ░░░░ Settings: Security Group END  ░░░░
************************/

//get Quote internal notes
export const getQuoteInternalNotes = (data) =>
  apiCall(url.GET_QUOTE_INTERNAL_NOTES, data, "POST", true);

// add Quote internal  notes
export const addQuoteInternalNotes = (data) =>
  apiCall(url.ADD_QUOTE_INTERNAL_NOTES, data, "POST", true);

// delete Quote internal notes
export const deleteQuoteInternalNotes = (data) =>
  apiCall(url.DELETE_QUOTE_INTERNAL_NOTES, data, "POST", true);

// save Quote internal notes attachments
export const saveQuoteInternalNotesAttachments = (data) =>
  apiCall(url.SAVE_QUOTE_INTERNAL_NOTES_ATTACHMENTS, data, "POST", true);

// search Quotes
export const quoteSearch = (data) =>
  apiCall(url.QUOTE_SEARCH, data, "POST", true);

// create Quotes
export const addQuote = (data) => apiCall(url.ADD_QUOTES, data, "POST", true);

// update Quotes
export const updateQuote = (data) => apiCall(url.UPDATE_QUOTES, data, "POST", true);

// get Quote parts
export const getQuotePartList = (data) =>
  apiCall(url.GET_QUOTE_PART_LIST, data, "POST", true);

//save Quote parts
export const saveQuotePart = (data) =>
  apiCall(url.SAVE_QUOTE_PART, data, "POST", true);

//update Quote parts
export const updateQuotePart = (data) =>
apiCall(url.UPDATE_QUOTE_PART, data, "POST", true);

//delete Quote parts
export const deleteQuotePart = (data) =>
  apiCall(url.DELETE_QUOTE_PART, data, "POST", true);


  // save Quote parts many
export const editQuotePartMany = (data) =>
apiCall(url.EDIT_QUOTE_PART_MANY, data, "POST", true);


// get Quote kit part list
export const getQuoteKitPart = (data) =>
apiCall(url.GET_QUOTE_KIT_PART, data, "POST", true);

// get Quote kit part list
export const getQuoteKitLabour = (data) =>
apiCall(url.GET_QUOTE_KIT_LABOUR, data, "POST", true);

export const saveQuoteKitPart = (data) =>
apiCall(url.SAVE_QUOTE_KIT_PART, data, "POST", true);

export const deleteQuoteKitPart = (data) =>
apiCall(url.DELETE_QUOTE_KIT_PART, data, "POST", true);


// save Quote kit parts many
export const editQuoteKitPartMany = (data) =>
apiCall(url.EDIT_QUOTE_KIT_PART_MANY, data, "POST", true);

// save Quote kit parts many
export const editQuoteKitLabourMany = (data) =>
apiCall(url.EDIT_QUOTE_KIT_LABOUR_MANY, data, "POST", true);


//COPY QUOTES
export const copyQuotes = (data) => apiCall(url.COPYQUOTES, data, "POST", true);

//delete QUOTES
export const deleteQuotes = (data) =>
  apiCall(url.DELETEQUOTES, data, "POST", true);

//send QUOTES mail
export const sendQuoteMail = (data) =>
  apiCall(url.SENDQUOTEMAIL, data, "POST", true);

//get invoices list
export const getInvoicesList = (data) =>
apiCall(url.GET_INVOICES_LIST, data, "POST", true);

export const getInvoicesPayments = (data) =>
apiCall(url.GET_INVOICES_PAYMENT, data, "POST", true);

export const getInvoicesCredits = (data) =>
apiCall(url.GET_INVOICE_CREDIT, data, "POST", true);

export const getInvoicesSalesList = (data) =>
apiCall(url.GET_INVOICE_CASH_SALE_LIST, data, "POST", true);

export const deleteArchiveInvoices = (data) =>
apiCall(url.DELETE_INVOICES, data, "POST", true);

export const deleteArchiveInvoicesSales = (data) =>
apiCall(url.DELETE_INVOICES_SALES, data, "POST", true);




/////////get marketing list
export const getMarketingsList = (data) =>
apiCall(url.GET_MARKETINGS_LIST, data, "POST", true);

export const getLiveMarketingsList = (data) =>
apiCall(url.GET_LIVE_MARKETINGS_LIST, data, "POST", true);

export const getCompletedMarketingsList = (data) =>
apiCall(url.GET_COMPLETED_MARKETINGS_LIST, data, "POST", true);

// export const deleteArchiveMarketings = (data) =>
// apiCall(url.DELETE_MARKETINGS, data, "POST", true);

/////////get marketing list

///Approve inspection
export const setApproveManualInspection = (data) =>
  apiCall(url.APPROVE_MANUAL_INSPECTION, data, "POST", true);

//Add approved parts
export const addApprovedPartsInspection = (data) =>
  apiCall(url.ADD_APPROVED_PARTS_INSPECTION, data, "POST", true);

/// Resend inspection
export const resendInspection = (data) =>
  apiCall(url.RESEND_INSPECTION, data, "POST", true);

/// Inspection groups
export const getInspectionGroups = (data) =>
  apiCall(url.INSPECTION_GROUP, data, "POST", true);

export const addInpections = (data) =>
  apiCall(url.ADD_INSPECTION, data, "POST", true);
export const sendEmailInvoice = (data) =>
  apiCall(url.SEND_INVOICE_MAIL, data, "POST", true);

export const addInvoiceCreate = (data) =>
  apiCall(url.ADD_INVOICE_CREDIT, data, "POST", true);

export const deleteInvoicePayment = (data) =>
  apiCall(url.DELETE_INVOICE_PAYMENT, data, "POST", true);

export const deleteInvoiceCredit = (data) =>
  apiCall(url.DELETE_INVOICE_CREDIT, data, "POST", true);

/***********************
 ░░░░ Settings: Statuses  ░░░░
************************/

// GET workshop bays
export const getStatusesList = (data) =>
  apiCall(url.GET_STATUSES_LIST, data, "POST", true);

// ADD workshop bays
export const addStatus = (data) => apiCall(url.ADD_STATUS, data, "POST", true);

// EDIT workshop bays
export const updateStatus = (data) =>
  apiCall(url.UPDATE_STATUS, data, "POST", true);

// VIEW workshop bays
export const editStatus = (data) =>
  apiCall(url.GET_STATUSES_LIST, data, "POST", true);

// DELETE workshop bays
export const deleteStatus = (data) =>
  apiCall(url.DELETE_STATUS, data, "POST", true);

// ADD job remiminder
export const addJobReminder = (data) =>
  apiCall(url.ADD_JOB_REMINDER, data, "POST", true);

// update job remiminder
export const updateJobReminder = (data) =>
  apiCall(url.UPDATE_JOB_REMINDER, data, "POST", true);

// delete job remiminder
export const deleteJobReminder = (data) =>
  apiCall(url.DELETE_JOB_REMINDER, data, "POST", true);

// add attachment
export const addAttachment = (data) =>
  apiCall(url.ADD_ATTACHMENT, data, "POST", true);

// get attachment
export const getAttachment = (data) =>
  apiCall(url.GET_ATTACHMENT, data, "POST", true);

  /***********************
 ░░░░ KIT PART START ░░░░
************************/
export const getKitPartList = (data) =>
  apiCall(url.GET_KIT_PART_LIST, data, "POST", true);

/***********************
 ░░░░ KIT PART START ░░░░
************************/

/***********************
 ░░░░ Workshop Bays ░░░░
************************/

// GET workshop bays
export const getWorkshopBaysList = (data) =>
  apiCall(url.GET_WORKSHOP_BAYS_LIST, data, "POST", true);

// ADD workshop bays
export const addWorkshopBay = (data) =>
  apiCall(url.ADD_WORKSHOP_BAY, data, "POST", true);

// EDIT workshop bays
export const updateWorkshopBay = (data) =>
  apiCall(url.UPDATE_WORKSHOP_BAY, data, "POST", true);

// VIEW workshop bays
export const editWorkshopBay = (data) =>
  apiCall(url.VIEW_WORKSHOP_BAY, data, "POST", true);

// DELETE workshop bays
export const deleteWorkshopBay = (data) =>
  apiCall(url.DELETE_WORKSHOP_BAY, data, "POST", true);

// Get Subscription Plans Data
export const getSubscriptionPlans = (data) =>
  apiCall(url.GET_SUBSCRIPTION_PLANS, data, "POST", true);

  export const getSupplierIntegration = (data) =>
  apiCall(url.GET_SUPPLIER_INTEGRATION, data, "POST", true);
