import {
  GET_USER_PROFILE,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
  UPDATE_USER_PROFILE_SUCCESS,
  CHECK_EMAILEXIST,
  CHECK_EMAILEXIST_SUCCESS,
  CHECK_EMAILEXIST_FAIL,
  GET_SELECTED_CUSTOMERLIST,
  GET_SELECTED_CUSTOMERLIST_SUCCESS,
  GET_SELECTED_CUSTOMERLIST_FAIL,
} from "./actionTypes";

const initialState = {
  data: [],
  userData: null,
  error: {
    message: "",
  },
  isFetching: false,
  isUpdate: false,
  emailExist: null,
  customerList: [],
};

const UserProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_PROFILE:
      state = {
        ...state,
        userData: action.userData,
        isFetching: false,
        isUpdate: false,
      };
      break;
    case GET_USER_PROFILE_SUCCESS:
      state = {
        ...state,
        userData: action.payload,
        isFetching: true,
        isUpdate: false,
      };
      break;
    case GET_USER_PROFILE_FAIL:
      state = {
        ...state,
        userData: null,
        error: {
          message: action.payload,
        },
        isFetching: true,
        isUpdate: false,
      };
      break;
    case UPDATE_USER_PROFILE_SUCCESS:
      state = {
        ...state,
        userData: action.payload,
        isFetching: true,
        isUpdate: true,
      };
      break;

    case CHECK_EMAILEXIST:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case CHECK_EMAILEXIST_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        emailExist: action.payload,
      };
      break;
    case CHECK_EMAILEXIST_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        emailExist: null,
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_SELECTED_CUSTOMERLIST:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_SELECTED_CUSTOMERLIST_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        customerList: action.payload,
      };
      break;
    case GET_SELECTED_CUSTOMERLIST_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        customerList: null,
        error: {
          message: action.payload,
        },
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default UserProfileReducer;
