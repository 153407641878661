import * as ACTIONS from "./actionType";

export const getInvoceCreateDetail = (payload) => {
  return {
    type: ACTIONS.GET_DETAIL_FOR_CREATE_INVOICE,
    payload,
  };
};

export const getInvoiceCreateDetailSuccess = (payload) => {
  return {
    type: ACTIONS.GET_DETAIL_FOR_CREATE_INVOICE_SUCCESS,
    payload,
  };
};

export const getInvoiceCreateDetailFail = (payload) => {
  return {
    type: ACTIONS.GET_DETAIL_FOR_CREATE_INVOICE_FAIL,
    payload,
  };
};

export const createInvoice = (payload) => ({
  type: ACTIONS.CREATE_INVOICE,
  payload,
});

export const createInvoiceSuccess = (payload) => ({
  type: ACTIONS.CREATE_INVOICE_SUCCESS,
  payload,
});

export const createInvoiceFail = (payload) => ({
  type: ACTIONS.CREATE_INVOICE_FAIL,
  payload,
});

export const getInvoceEditDetail = (payload) => {
  return {
    type: ACTIONS.GET_DETAIL_FOR_EDIT_INVOICE,
    payload,
  };
};

export const getInvoiceEditDetailSuccess = (payload) => {
  return {
    type: ACTIONS.GET_DETAIL_FOR_EDIT_INVOICE_SUCCESS,
    payload,
  };
};

export const getInvoiceEditDetailFail = (payload) => {
  return {
    type: ACTIONS.GET_DETAIL_FOR_EDIT_INVOICE_FAIL,
    payload,
  };
};

export const sendEmailInvoice = (payload) => ({
  type: ACTIONS.SEND_EMAIL,
  payload,
});

export const sendEmailInvoiceSuccess = (payload) => ({
  type: ACTIONS.SEND_EMAIL_SUCCESS,
  payload,
});

export const sendEmailInvoiceFail = (payload) => ({
  type: ACTIONS.SEND_EMAIL_FAIL,
  payload,
});

export const getInvoiceCreditInfo = (payload) => ({
  type: ACTIONS.GET_INVOICE_CREDIT_INFO,
  payload,
});

export const getInvoiceCreditInfoSuccess = (payload) => ({
  type: ACTIONS.GET_INVOICE_CREDIT_INFO_SUCCESS,
  payload,
});

export const getInvoiceCreditInfoFail = (payload) => ({
  type: ACTIONS.GET_INVOICE_CREDIT_INFO_FAIL,
  payload,
});

export const createInvoiceCredit = (payload) => ({
  type: ACTIONS.ADD_INVOICE_CREDIT,
  payload,
});

export const createInvoiceCreditSuccess = (payload) => ({
  type: ACTIONS.ADD_INVOICE_CREDIT_SUCCESS,
  payload,
});

export const createInvoiceCreditFail = (payload) => ({
  type: ACTIONS.ADD_INVOICE_CREDIT_SUCCESS,
  payload,
});

export const getInvoiceCreditView = (payload) => ({
  type: ACTIONS.GET_INVOCE_CREDIT_VIEW,
  payload,
});

export const getInvoiceCreditViewSuccess = (payload) => ({
  type: ACTIONS.GET_INVOCE_CREDIT_VIEW_SUCCESS,
  payload,
});

export const getInvoiceCreditViewFail = (payload) => ({
  type: ACTIONS.GET_INVOCE_CREDIT_VIEW_FAIL,
  payload,
});

export const getInsuranceDetail = (payload) => ({
  type: ACTIONS.GET_INSURANCE_DETAILS,
  payload,
});

export const getInsuranceDetailSuccess = (payload) => ({
  type: ACTIONS.GET_INSURANCE_DETAILS_SUCCESS,
  payload,
});

export const getInsuranceDetailFail = (payload) => ({
  type: ACTIONS.GET_INSURANCE_DETAILS_FAIL,
  payload,
});

export const getReceiptDetail = (payload) => ({
  type: ACTIONS.GET_INVOICE_RECEIPT,
  payload,
});

export const getReceiptDetailSuccess = (payload) => ({
  type: ACTIONS.GET_INVOICE_RECEIPT_SUCCESS,
  payload,
});

export const getReceiptDetailFail = (payload) => ({
  type: ACTIONS.GET_INVOICE_RECEIPT_FAIL,
  payload,
});

export const getReceiptEmailDetail = (payload) => ({
  type: ACTIONS.GET_INVOICE_RECEIPT_EMAIL,
  payload,
});

export const getReceiptEmailDetailSuccess = (payload) => ({
  type: ACTIONS.GET_INVOICE_RECEIPT_EMAIL_SUCCESS,
  payload,
});

export const getReceiptEmailDetailFail = (payload) => ({
  type: ACTIONS.GET_INVOICE_RECEIPT_EMAIL_FAIL,
  payload,
});


export const getCreditEmailDetail = (payload) => ({
  type: ACTIONS.GET_INVOICE_CREDIT_EMAIL,
  payload,
});

export const getCreditEmailDetailSuccess = (payload) => ({
  type: ACTIONS.GET_INVOICE_CREDIT_EMAIL_SUCCESS,
  payload,
});

export const getCreditEmailDetailFail = (payload) => ({
  type: ACTIONS.GET_INVOICE_CREDIT_EMAIL_FAIL,
  payload,
});


export const getInvoiceRest = (payload) => {
  return {
    type: ACTIONS.GET_INVOICE_REST_DATA,
    payload,
  };
};
export const getInvoiceRestSuccess = (payload) => {
  return {
    type: ACTIONS.GET_INVOICE_REST_DATA_SUCCESS,
    payload,
  };
};