import {
  GET_NOTIFICATION_LIST,
  GET_NOTIFICATION_LIST_SUCCESS,
  GET_NOTIFICATION_LIST_FAIL
} from "./actionTypes";

const initialState = {
  loadingLogins: false,
  message: "",
  error: "",
  notificationListData: null,
  isFetching: false,
};

const NotificationListReducer = (state = initialState, action) => {
  switch (action.type) {
      case GET_NOTIFICATION_LIST:
        state = { 
          ...state, 
          loadingLogins: true,
         notificationListData: null,
          isFetching: false,
        };
      break;
      case GET_NOTIFICATION_LIST_SUCCESS:
        state = { 
          ...state, 
          loadingLogins: false,
          notificationListData: action.payload,
          isFetching: true,
        };
      break;
      case GET_NOTIFICATION_LIST_FAIL:
        state = {
          ...state,
          loadingLogins: false,
          notificationListData: null,
          error: {
            message: action.payload,
          },
          isFetching: true,
        };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default NotificationListReducer;
