// import Login from '../components/Login/index';
import { routes as Login }  from '../views/login';
import { routes as Dashboard } from '../views/dashboard';
import { routes as Jobs } from '../views/job';
import { routes as Register } from  '../views/register';
import { routes as Scheduler } from  '../views/scheduler';
import { routes as UserProfile } from  '../views/userProfile';
import { routes as Calender } from '../views/calender';
import { routes as Notification } from '../views/notification';
import { routes as Quote } from '../views/quotes';
import { routes as Setting } from "../views/settings";
import { routes as InvoicePreview } from "../views/invoicePreview";
import { routes as Invoice } from '../views/invoices';
import { routes as PurchasesOrder } from '../views/purchaseOrder';
import { routes as Subscription } from '../views/subscription';
import { routes as Marketing } from '../views/marketing';

export const ownerRoutes = [
  ...Dashboard,
  ...Jobs,
  ...Scheduler,
  ...UserProfile,
  ...Calender,
  ...Notification,
  ...Quote,
  ...Setting,
  ...InvoicePreview,
  ...Setting,
  ...Invoice,
  ...PurchasesOrder,
  ...Subscription,
  ...Marketing
];

export const adminRoutes = [
  ...Dashboard,
  ...Jobs,
  ...Scheduler,
  ...UserProfile,
  ...Notification,
  ...Quote,
  ...Invoice,
];

export const accountRoutes = [
  ...Dashboard
];

export const officeRoutes = [
  ...Dashboard
];

export const seniorTechRoutes = [
  ...Dashboard
];

export const TechRoutes = [
  ...Dashboard
];

export const authRoutes = [...Login, ...Register];