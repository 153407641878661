export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION";
export const GET_SUBSCRIPTION_SUCCESS = "GET_SUBSCRIPTION_SUCCESS";
export const GET_SUBSCRIPTION_FAIL = "GET_SUBSCRIPTION_FAIL";


export const GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST = "GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST";
export const GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST_SUCCESS = "GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST_SUCCESS";
export const GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST_FAIL = "GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST_FAIL";

export const GET_SUBSCRIPTION_PLANS = "GET_SUBSCRIPTION_PLANS";
export const GET_SUBSCRIPTION_PLANS_SUCCESS = "GET_SUBSCRIPTION_PLANS_SUCCESS"
export const GET_SUBSCRIPTION_PLANS_FAIL = "GET_SUBSCRIPTION_PLANS_FAIL"