import {
    ADD_CUSTOMER,
    ADD_CUSTOMER_SUCCESS,
    ADD_CUSTOMER_FAIL,
    CHECK_PHONE_EXIST,
    CHECK_PHONE_EXIST_SUCCESS,
    CHECK_PHONE_EXIST_FAIL,
    CHECK_MOBILE_EXIST,
    CHECK_MOBILE_EXIST_SUCCESS,
    CHECK_MOBILE_EXIST_FAIL
} from "./actionTypes";


export const checkPhoneExist = () => {
    return {
        type: CHECK_PHONE_EXIST,
    };
};

export const checkPhoneExistSuccess = (data) => {
    return {
        type: CHECK_PHONE_EXIST_SUCCESS,
        payload: data,
    };
};

export const checkPhoneExistFail = (error) => {
    return {
        type: CHECK_PHONE_EXIST_FAIL,
        payload: error,
    };
};

export const checkMobileExist = () => {
    return {
        type: CHECK_MOBILE_EXIST,
    };
};

export const checkMobileExistSuccess = (data) => {
    return {
        type: CHECK_MOBILE_EXIST_SUCCESS,
        payload: data,
    };
};

export const checkMobileExistFail = (error) => {
    return {
        type: CHECK_MOBILE_EXIST_FAIL,
        payload: error,
    };
};

export const addCustomer = () => {
    return {
        type: ADD_CUSTOMER,
    };
};

export const addCustomerSuccess = (data) => {
    return {
        type: ADD_CUSTOMER_SUCCESS,
        payload: data,
    };
};

export const addCustomerFail = (error) => {
    return {
        type: ADD_CUSTOMER_FAIL,
        payload: error,
    };
};