import { getInvoceCreateDetail, getInvoiceRest } from "./action"

export function getBookedJobPayload(id, workshop_id) {
    return {
        "get": "all",
        "conditions": {
            "JobSchedules.id": id
        },
        "contain": {
            "0": "Vehicles",
            "1": "WorkshopDepartmentsAccountsDetails",
            "2": "JobBookingTypes",
            "3": "Users.EmployeesDetails.LabourCharges",
            "4": "Users.Workshops",
            "5": "Vehicles.VehicleMake",
            "6": "Vehicles.VehicleModel",
            "7": "JobAttachments",
            "8": "JobLabours.LabourCharges",
            "9": "JobsParts.Products",
            "10": "InsuranceCompany.States",
            "11": "InsuranceCompany.Countries",
            "12": "CreatedByUser",
            "13": "JobBookingTypes",
            "14": "Invoices",
            "15": "Workshops.WorkshopOtherDetails",
            "16": "Vehicles.Users.CustomersDetails.States",
            "17": "Vehicles.Users.CustomersDetails.Countries",
            "Vehicles.Users.CustomersDetails": {
                "conditions": {
                    "CustomersDetails.workshop_id": workshop_id
                }
            },
            "JobsParts": {
                "conditions": {
                    "JobsParts.status": 1
                },
                "sort": {
                    "JobsParts.job_part_priority": "asc"
                }
            }
        }
    }
}

export function getInvoiceListPayload(workshop_id) {
    return { "get": "all", "conditions": { "Invoices.workshop_id": workshop_id }, "contain": ["JobSchedules.Vehicles.Users"] }
}

export function getWorkshopOtherDetailViewPayload(workshop_id) {
    return { "workshop_id": workshop_id }
}

export function getJobNotesPayload(id) {
    return { "get": "all", "conditions": { "job_id": id } }
}

export function getInsurancePayload(workshop_id) {
    return { "get": "all", "conditions": { "workshop_id": workshop_id }, "fields": ["id", "company_name"] }
}

export function getJobCannedListPayload(workshop_id) {
    return { "get": "all", "conditions": { "workshop_id": workshop_id, "status": 1, "type In": [6, 3, 8] }, "fields": ["id", "department_id", "name", "description"] }
}

export function getLabourChargeListPayload(workshop_id) {
    return { "get": "all", "conditions": { "workshop_id": workshop_id, "is_default": "1", "is_deleted": 0 } }
}

export const getInvoiceCreateDetailAPI = (dispatch) => (id, workshop_id) => {
    const payload = {
        getBookedJobRequest: getBookedJobPayload(id, workshop_id),
        getInvoiceListRequest: getInvoiceListPayload(workshop_id),
        getWorkShopOtherDetailRequest: getWorkshopOtherDetailViewPayload(workshop_id),
        getJobNotesRequest: getJobNotesPayload(id),
        getInsuranceRequest: getInsurancePayload(workshop_id),
        getJobCannedRequest: getJobCannedListPayload(workshop_id),
        getLabourChargeRequest: getLabourChargeListPayload(workshop_id)
    }
    dispatch(getInvoceCreateDetail(payload));
    dispatch(getInvoiceRest(payload));
}