import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { GET_LOGIN } from "../../../store/login/actionTypes";
import logo from "../../../assets/images/5iQ_logo.png";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import LoaderImage from "../../../../src/assets/images/page-loader.svg";
import moment from "moment";
import { unserializeArr } from "../../../utils/unserialize";

let UserLogin = () => {
  const [validated, setValidated] = useState(false);
  // const [email, setEmail] = useState();
  // const [password, setPassword] = useState();
   const [email1, setEmail1] = useState("anup@technostacks.in");
  const [password1, setPassword1] = useState("yhV7hgl1qE2@");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const userData = useSelector((state) => state.LoginReducer);

  useEffect(() => {
    if (userData.loadingLogins === false) {
      setIsLoading(false);
    }
    if (userData) {
      if (userData.token !== "" && userData.token !== undefined) {
        let user_id = window.btoa(userData.userData.id);
        let user_name = window.btoa(
          `${userData.userData.firstname} ${userData.userData.lastname}`
        );
        let ws_id = window.btoa(userData.userData.workshop_id);
        let ws_other_id = window.btoa(
          userData.userData.workshop.workshop_other_details[0].id
        );
        let ws_name = window.btoa(userData.userData.workshop.name);
        let theme_mode =
          userData.userData.workshop.workshop_other_details[0].is_light_mode;
        let currency =
          userData.userData.workshop.workshop_other_details[0].country.symbol;
        let country =
          userData.userData.workshop.workshop_other_details[0].country.id;
        let api_token = window.btoa(userData.token);
        let timezone_lable = window.btoa(userData?.userData?.workshop?.workshop_other_details[0]?.timezone_label || 'Asia/Kolkata');
        let technician_timeslot = window.btoa(userData?.userData?.workshop?.workshop_other_details[0].technician_timeslot || null);
        let schedule_details = window.btoa(userData?.userData?.workshop?.workshop_other_details[0].schedule_details || null);
        let assets_type = userData?.userData?.workshop?.vehicle_types || [];
        
        let trial_expiry_date = window.btoa(
          userData.userData.workshop.trial_expiry_date
            ? userData.userData.workshop.trial_expiry_date
            : moment().toDate()
        );
        // create object for session items
        const items = {
          user_id: user_id,
          user_name: user_name,
          workshop_id: ws_id,
          workshop_other_id: ws_other_id,
          workshop_name: ws_name,
          token: api_token,
          theme_mode: theme_mode,
          currency: currency ? currency : "$",
          country: country ? country : 2,
          date_format: "DD/MM/YYYY",
          time_format:"HH:mm",
          trial_expiry_date: trial_expiry_date,
          timezone_lable:timezone_lable,
          technician_timeslot:technician_timeslot,
          schedule_details:schedule_details,
          assets_type:unserializeArr(assets_type)
        };

        // set session of all items
        sessionStorage.setItem("stored_data", JSON.stringify(items));

        navigate("/home");
      } else if (userData.error.message !== "") {
        navigate("/login");
      }
    }
  }, [userData]);

  const dispatch = useDispatch();
  function handleSubmit(e) {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() !== false) {
      setIsLoading(true);
      dispatch({
        type: GET_LOGIN,
        // payload: {
        //   email: email,
        //   password: password,
        // },
        payload: {
          email: email1,
          password: password1,
        },
      });
    }
    setValidated(true);
  }
  return (
    <>
      <div className="pre-login-header">
        <h1 className="mb-2">Welcome to Business Management</h1>
        <p className="mb-2">Login to website here.</p>
        <p className="link-wrapper mb-2">
          New Registration{" "}
          <Link to="#" className="primary-link">
            CLICK HERE
          </Link> 
          {/* <Link to="/register" className="primary-link">
            CLICK HERE
          </Link> */}
        </p>
      </div>
      <div className="pre-login-wrapper">
        <div className="form-wrapper">
          {/* <a className="logo">
            <img src={logo} alt=""></img>
          </a> */}
          <Form onSubmit={handleSubmit} noValidate validated={validated}>
            <Form.Group className="form-group mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                type="email"
                value="test@gmail.com"
                // onBlur={(e) => setEmail(e.target.value)}
                // onBlur={(e) => setEmail1(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please Enter Email
              </Form.Control.Feedback>
              <Link
                to="/forgot-email"
                className="text-right secondary-link mt-1"
              >
                Forgot Email
              </Link>
            </Form.Group>
            <Form.Group className="form-group mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                required
                type="password"
                value="yhV7hgl1qE2"
                // onBlur={(e) => setPassword(e.target.value)} 
                //  onBlur={(e) => setPassword1(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please Enter Password
              </Form.Control.Feedback>
              <Link
                to="/forgot-password"
                className="text-right secondary-link mt-1"
              >
                Forgot Password
              </Link>
            </Form.Group>
            <div className="btn-wrapper">
              <Button type="submit" variant="primary" className="primary-btn">
                LOGIN
              </Button>
            </div>
          </Form>
        </div>
      </div>
      <div className="pre-login-footer">
        <p className="mb-2">By signing up for our Business Management account, you accept our terms & conditions </p>
        <p>
          <a href="#" className="primary-link mx-2">
            Terms of Use
          </a>
          <span>and</span>
          <a href="#" className="primary-link mx-2">
            Privacy Policy
          </a>
        </p>
      </div>

      {isLoading === true && (
        <div className="page-loader d-flex align-center justify-center">
          <img src={LoaderImage} alt="Loading"></img>
        </div>
      )}
    </>
  );
};

//  UserLogin.propTypes = {
//   classes: PropTypes.object.isRequired,
// };
export default UserLogin;
