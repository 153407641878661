import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import 'react-toastify/dist/ReactToastify.css';
import { showError, showSuccess } from "../../../utils/alertMessage";
import { useDispatch, useSelector } from "react-redux";
import { CONFIRM_OTP, SEND_OTP, EXIST_EMAIL } from "../../../store/register/actionTypes";
import * as yup from "yup";
import { Formik, useFormik } from "formik";

const FirstStep = (props) => {
    const otpData = useSelector((state) => state.RegisterReducer); // Get Regiser Reducer data
    const { setStep, changeFormData } = props; // Get parent component data
    const dispatch = useDispatch(); // Dispatch variable
    // input fields valiadation for only first step
    const [firstname, setFirstName] = useState();
    const [lastname, setLastName] = useState();
    const [email, setEmail] = useState();
    const [confirmOtp, setConfirmOtp] = useState();
    const [alternateno, setAlternate] = useState();
    // Manage state for display success or error response
    const [successOtp, setSuccessOtp] = useState(false);
    const [otpMessages, setOtpMessages] = useState("");
    const [messageColor, setMessageColor] = useState("");
    const [otpMethod, setOtpMethod] = useState("send");
 
    const validationSchema = yup.object({
        firstname: yup.string().required('Please enter your Firstname '),
        lastname: yup.string().required('Please enter your Lastname'),
        checkEmail: yup.boolean(),
        email: yup.string()
            .email('Please enter a valid Email Address')
            .required('Please enter a Email Address')
            .when("checkEmail", { 
                is:true,
                then:yup.string()
                .test('email', 'This Email is already registered',
                    function (value) {
                        if (value !== "") {
                            dispatch({
                                type: EXIST_EMAIL,
                                payload: {
                                    "get": "all",
                                    "conditions": {
                                        "email": value
                                    }
                                },
                            });
                            if(otpData.emailData != null){
                                if(otpData.emailData === "Email already exists."){
                                    return otpData.existEmail;
                                }
                                else if(otpData.emailData === "Email Not Exist"){
                                    return otpData.existEmail;
                                }
                            }else{
                                return false;
                            }
                        }
                    }
                ),
            }),            
        password: yup.string()
            .required('Please enter a Password')
            .min(8)
            .max(15),
        confirmpassword: yup.string()
            .when('password',(password, field) => 
                password ? field.required('Please enter a confirm Password')
                    .oneOf([yup.ref('password')], 'Confirm Password must be same as Password') : field),
        checkMobile:yup.boolean(),
        mobileno: yup.number()
            .required('Please enter a Mobile Number')
            .when("checkMobile", { 
                is:true,
                then:yup.number().test('mobileno', 'This Mobile number is already registered',
                    function (value) {
                        if (value !== "") {
                            dispatch({
                                type: EXIST_EMAIL,
                                payload: {
                                    "get": "all",
                                    "conditions": {
                                        "phone_number": value
                                    }
                                },
                            });
                            if(otpData.emailData != null){
                                if(otpData.emailData === "Phone No already exists."){
                                    return otpData.existEmail;
                                }
                                else if(otpData.emailData === "Phone No Not Exist"){
                                    return otpData.existEmail;
                                }
                            }else{
                                return false;
                            }
                        }
                    }
                )
            }),
        terms_and_condition: yup.bool().required().oneOf([true], 'Terms must be accepted.')
    })

    const formik = useFormik({
        initialValues: {
            firstname: "",
            lastname: "",
            email: "",
            password: "",
            confirmpassword: "",
            mobileno: "",
            terms_and_condition: false,
            checkEmail:false,
            checkMobile:false,
        },
        validationSchema        
    });

    //Dispatch action for send Otp to the customer
    function handleSubmitForm(e) {
        formik.handleSubmit();
        setFirstName(formik.values.firstname);
        setLastName(formik.values.lastname);
        setEmail(formik.values.email);
        //Pass form data to parent component (Register form data)
        const formData = {
            firstname: formik.values.firstname,
            lastname: formik.values.lastname,
            email: formik.values.email,
            mobile_no: formik.values.mobileno,
            confirm: formik.values.confirmpassword,
            password: formik.values.password,
            alternate_no: alternateno,
        }
        changeFormData(formData);
        
        const form = document.getElementById('submit_form');
        if (form.checkValidity() !== false) {
            dispatch({
                type: SEND_OTP,
                payload: {
                    email: formik.values.email,
                    name: formik.values.firstname + ` ` + formik.values.lastname,
                },
            });
        }
    }

    //For get data once send otp and resend otp
    useEffect(() => {
        // return () => {
        let messageVar = "";
        if (otpData.userData != null) {
            if (otpData.success.message !== '') {
                if (otpMethod === "resend") {
                    setSuccessOtp(true);
                    messageVar = "The OTP code has been resent, please copy it and enter it here.";
                } else {
                    setSuccessOtp(!successOtp);
                    messageVar = "The OTP code has been sent to your email, please copy it and enter it here.";
                }
                setOtpMessages(messageVar);
                let colorClass = "otp-success-msg";
                setMessageColor(colorClass);
                showSuccess(otpData.success.message);
            }
        } else if (otpData.error.message !== '') {
            if (otpMethod === "resend") {
                messageVar = "The OTP code did not send, please click on resend button.";
            } else {
                messageVar = "The OTP code did not send, please send it again!";
            }
            setSuccessOtp(false);
            setOtpMessages(messageVar);
            let colorClass = "otp-error-msg";
            setMessageColor(colorClass);
            showError(otpData.error.message);
        }
        // }
    }, [otpData.userData]);

    //For confirm OTP data
    useEffect(() => {
        return () => {
            if (otpData.confirmData != null) {
                if (otpData.success.message !== '') {
                    showSuccess(otpData.success.message);
                    setStep(false)
                }
            } else if (otpData.error.message !== '') {
                showError(otpData.error.message);
            }
        }
    }, [otpData.confirmData]);

    //Dispatch action for confirm OTP 
    function handleConfirm(e) {
        e.preventDefault();
        if (email !== '' && confirmOtp !== '') {
            dispatch({
                type: CONFIRM_OTP,
                payload: {
                    email: formik.values.email,
                    otp: confirmOtp,
                },
            });
        }
    }

    //Manage resend OTP variable 
    function handleResendOTP(resend) {
        setOtpMethod(resend);
    }

    function handleFocusInEmail(){
        formik.values.checkEmail = true;
        formik.values.checkMobile = false;
    }

    function handleFocusInMobile(){
        formik.values.checkEmail = false;
        formik.values.checkMobile = true;
    }

    return (
        <>
        <Formik>
            <div className="register-form-wrapper form-outer pa-4 step-1">
                <Form.Group className="form-group  width-50 mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control className={formik.touched.firstname && formik.errors.firstname ? 'is-invalid' : null} required type="text" name="firstname" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.firstname} />
                    {formik.touched.firstname && formik.errors.firstname ? (<div className="invalidFeedback">{formik.errors.firstname}</div>) : null}
                </Form.Group>
                <Form.Group className="form-group width-50 mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control className={formik.touched.lastname && formik.errors.lastname ? 'is-invalid' : null} required type="text" name="lastname" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.lastname} />
                    {formik.touched.lastname && formik.errors.lastname ? (<div className="invalidFeedback">{formik.errors.lastname}</div>) : null}
                </Form.Group>
                <Form.Group className="form-group mb-3">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control className={formik.touched.email && formik.errors.email ? 'is-invalid' : null} required type="email" name="email" onChange={formik.handleChange} value={formik.values.email} onBlur={handleFocusInEmail}/>
                    {formik.touched.email && formik.errors.email ? (<div className="invalidFeedback">{formik.errors.email}</div>) : null}
                </Form.Group>
                <Form.Group className="form-group width-50 mb-3">
                    <Form.Label>Mobile No</Form.Label>
                    <Form.Control className={formik.touched.mobileno && formik.errors.mobileno ? 'is-invalid' : null} required type="text" name="mobileno" onChange={formik.handleChange} value={formik.values.mobileno} onBlur={handleFocusInMobile}/>
                    {formik.touched.mobileno && formik.errors.mobileno ? (<div className="invalidFeedback">{formik.errors.mobileno}</div>) : null}
                </Form.Group>
                <Form.Group className="form-group width-50 mb-3">
                    <Form.Label>Alternate contact No</Form.Label>
                    <Form.Control type="text" name="altcontact" onChange={(e) => setAlternate(e.target.value)} />
                </Form.Group>
                <Form.Group className="form-group mb-3">
                    <Form.Label>Password (Min 8 Characters)</Form.Label>
                    <Form.Control className={formik.touched.password && formik.errors.password ? 'is-invalid' : null} required name="password" type="password" onChange={formik.handleChange} onBlur={formik.handleBlur} />
                    {formik.touched.password && formik.errors.password ? (<div className="invalidFeedback">{formik.errors.password}</div>) : null}
                </Form.Group>
                <Form.Group className="form-group mb-3">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control className={formik.touched.confirmpassword && formik.errors.confirmpassword ? 'is-invalid' : null} required name="confirmpassword" type="password" onChange={formik.handleChange} onBlur={formik.handleBlur} />
                    {formik.touched.confirmpassword && formik.errors.confirmpassword ? (<div className="invalidFeedback">{formik.errors.confirmpassword}</div>) : null}
                </Form.Group>
                <Form.Check className="custom-check mb-2" type='checkbox' id="1">
                    <Form.Check.Input type='checkbox' />
                    <Form.Check.Label>
                        I would like to be informed about offers and updates via email.
                        <span className="check"></span>
                    </Form.Check.Label>
                </Form.Check>
                <Form.Check className="custom-check mb-2" id="2" >
                    <Form.Check.Input type='checkbox' required name="terms_and_condition" className={formik.touched.terms_and_condition && formik.errors.terms_and_condition ? 'is-invalid' : null} value={formik.values.firstname} onChange={formik.handleChange} />
                    <Form.Check.Label>
                        By signing up for 5iQ account, you accept 5iQ
                        <span className="check"></span>
                    </Form.Check.Label>
                </Form.Check>
                <p className="mb-2 ml-5 terms-block">
                    <a href="#" className="primary-link mx-2">Terms of Use</a>
                    <span>and</span>
                    <a href="#" className="primary-link mx-2">Privacy Policy</a>
                </p>
                {formik.touched.terms_and_condition && formik.errors.terms_and_condition ? (<div className="invalidFeedback">{formik.errors.terms_and_condition}</div>) : null}
                <p className="otp-text">An OTP will be sent to your email for validation</p>
                <div className={"btn-wrapper mt-3 " + (successOtp ? 'hide' : ' ')}>
                    <Button type="button" variant="primary" className="primary-btn" onClick={handleSubmitForm}>Send OTP</Button>
                </div>
                <div className={"confirm-otp mt-1 " + (!successOtp ? 'hide' : ' ')}>
                    <Form.Group className="form-group">
                        <Form.Control type="text" onChange={(e) => setConfirmOtp(e.target.value)} />
                    </Form.Group>
                    <Button variant="primary" className="primary-btn" onClick={handleConfirm}>Confirm & Continue</Button>
                </div>
                <p className={" mt-1 " + (otpMessages === "" ? ' hide ' : ' ') + messageColor}>{otpMessages} </p>
                <Form.Check.Label type="button" className={"mt-1 resend-link mx-2 " + (!successOtp ? 'hide ' : ' ')} onClick={() => { handleResendOTP('resend'); handleSubmitForm() }}>Resend OTP</Form.Check.Label>
            </div>
        </Formik>
        </>
    )
}

export default FirstStep;