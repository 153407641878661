export const SAVE_PART = "SAVE_PART";
export const SAVE_PART_SUCCESS = "SAVE_PART_SUCCESS";
export const SAVE_PART_FAIL = "SAVE_PART_FAIL";

export const GET_PART_LIST = "GET_PART_LIST";
export const GET_PART_LIST_SUCCESS = "GET_PART_LIST_SUCCESS";
export const GET_PART_LIST_FAIL = "GET_PART_LIST_FAIL";

export const DELETE_PART = "DELETE_PART";
export const DELETE_PART_SUCCESS = "DELETE_PART_SUCCESS";
export const DELETE_PART_FAIL = "DELETE_PART_FAIL";