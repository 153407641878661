import { getWorkshopId } from 'utils/getWorkshopId';
import {
  DELETE_COMPLETED_INVOICES,
  DELETE_RAISED_INVOICES,
  GET_COMPLETED_INVOICES,
  GET_INVOICE_CREDITS,
  GET_INVOICE_PAYMENTS,
  GET_RAISED_INVOICES,
} from './actionTypes';

export const getRaisedInvoicePayload = (workshop_id) => {
  const listRequest = {
    get: 'all',
    conditions: {
      'Invoices.workshop_id': workshop_id,
      invoice_status: 1,
    },
    contain: {
      'JobSchedules.Vehicles.Users.CustomersDetails': {
        conditions: {
          workshop_id: workshop_id,
        },
        fields: [
          'user_id',
          'workshop_id',
          'firstname',
          'lastname',
          'type',
          'company_name',
        ],
      },
      InvoicesPaymentsCredits: {
        fields: ['id', 'invoice_id'],
      },
      'Workshops.WorkshopOtherDetails': {
        fields: [
          'workshop_id',
          'payment_assist_API_url',
          'payment_assist_API_key',
        ],
      },
    },
    order: {
      'Invoices.id': 'desc',
    },
    fields: [
      'id',
      'workshop_id',
      'invoice_number',
      'created_on',
      'total',
      'comments',
      'tax_invoice',
      'remain_balance',
      'invoice_status',
      'claim_amount',
      'JobSchedules.id',
      'JobSchedules.job_number',
      'JobSchedules.total',
      'Vehicles.id',
      'Vehicles.rego',
      'Vehicles.make',
      'Vehicles.model',
      'Vehicles.service_due_date',
      'Vehicles.created_on',
      'Users.id',
      'Workshops.id',
    ],
  };

  const saleInvoiceRequest = {
    get: 'all',
    conditions: {
      'CashSaleInvoice.workshop_id': workshop_id,
      'CashSaleInvoice.status': 1,
      'CashSaleInvoice.invoice_status': 1,
    },
    contain: {
      WorkshopDepartmentsAccountsDetails: {
        fields: [
          'WorkshopDepartmentsAccountsDetails.id',
          'WorkshopDepartmentsAccountsDetails.name',
        ],
      },
      Vehicles: {
        fields: [
          'Vehicles.id',
          'Vehicles.user_id',
          'Vehicles.workshop_id',
          'Vehicles.rego',
          'Vehicles.make',
          'Vehicles.model',
          'Vehicles.created_on',
          'Vehicles.service_due_date',
        ],
      },
      'Users.customersDetails': {
        conditions: {
          workshop_id: workshop_id,
        },
        fields: [
          'id',
          'user_id',
          'firstname',
          'lastname',
          'mobile_number',
          'type',
          'company_name',
        ],
      },
    },
    order: {
      'CashSaleInvoice.id': 'desc',
    },
    fields: [
      'CashSaleInvoice.id',
      'CashSaleInvoice.cash_sale_no',
      'CashSaleInvoice.workshop_id',
      'CashSaleInvoice.invoice_dept_id',
      'CashSaleInvoice.total',
      'CashSaleInvoice.description',
      'Users.id',
      'Users.workshop_id',
      'Users.firstname',
      'Users.phone_number',
      'Users.email',
      'CashSaleInvoice.created_on',
    ],
  };
  
  return {
    type: GET_RAISED_INVOICES,
    payload: { invoiceListRequest: listRequest, saleInvoiceRequest },
  };
};

export const getCompletedInvoicePayload = (workshop_id) => {
  const listRequest = {
    get: 'all',
    // "limit": invoicePerPage,
    // "page": page,
    conditions: {
      'Invoices.workshop_id': workshop_id,
      invoice_status: 2,
    },
    contain: {
      'JobSchedules.Vehicles.Users.CustomersDetails': {
        conditions: {
          workshop_id: workshop_id,
        },
        fields: [
          'user_id',
          'workshop_id',
          'firstname',
          'lastname',
          'type',
          'company_name',
        ],
      },
      InvoicesPaymentsCredits: {
        fields: ['id', 'invoice_id'],
      },
      'Workshops.WorkshopOtherDetails': {
        fields: [
          'workshop_id',
          'payment_assist_API_url',
          'payment_assist_API_key',
        ],
      },
    },
    order: {
      'Invoices.id': 'desc',
    },
    fields: [
      'id',
      'workshop_id',
      'invoice_number',
      'created_on',
      'total',
      'comments',
      'tax_invoice',
      'remain_balance',
      'invoice_status',
      'claim_amount',
      'JobSchedules.id',
      'JobSchedules.job_number',
      'JobSchedules.total',
      'Vehicles.id',
      'Vehicles.rego',
      'Vehicles.make',
      'Vehicles.model',
      'Vehicles.service_due_date',
      'Vehicles.created_on',
      'Users.id',
      'Workshops.id',
    ],
  };

  const saleInvoiceRequest = {
    get: 'all',
    // "limit": saleInvoicePerPage,
    // "page": page,
    conditions: {
      'CashSaleInvoice.workshop_id': workshop_id,
      'CashSaleInvoice.status': 1,
      'CashSaleInvoice.invoice_status': 2,
    },
    contain: {
      WorkshopDepartmentsAccountsDetails: {
        fields: [
          'WorkshopDepartmentsAccountsDetails.id',
          'WorkshopDepartmentsAccountsDetails.name',
        ],
      },
      Vehicles: {
        fields: [
          'Vehicles.id',
          'Vehicles.user_id',
          'Vehicles.workshop_id',
          'Vehicles.rego',
          'Vehicles.make',
          'Vehicles.model',
          'Vehicles.created_on',
          'Vehicles.service_due_date',
        ],
      },
      'Users.customersDetails': {
        conditions: {
          workshop_id: workshop_id,
        },
        fields: [
          'id',
          'user_id',
          'firstname',
          'lastname',
          'mobile_number',
          'type',
          'company_name',
        ],
      },
    },
    order: {
      'CashSaleInvoice.id': 'desc',
    },
    fields: [
      'CashSaleInvoice.id',
      'CashSaleInvoice.cash_sale_no',
      'CashSaleInvoice.workshop_id',
      'CashSaleInvoice.invoice_dept_id',
      'CashSaleInvoice.total',
      'CashSaleInvoice.description',
      'Users.id',
      'Users.workshop_id',
      'Users.firstname',
      'Users.phone_number',
      'Users.email',
      'CashSaleInvoice.created_on',
    ],
  };

  return {
    type: GET_COMPLETED_INVOICES,
    payload: { invoiceListRequest: listRequest, saleInvoiceRequest },
  };
};

//Raise Invoices
export const getRaisedInvoiceAPI = (state) => (dispatch) => (workshop_id) => {
  dispatch(getRaisedInvoicePayload(workshop_id));
};

//Completed Invoices

export const getCompletedInvoiceAPI =
  (state) => (dispatch) => (workshop_id) => {
    dispatch(getCompletedInvoicePayload(workshop_id));
  };

export const getInvoicePaymentsAPI = (state) => (dispatch) => (workshop_id) => {
  const listRequest = {
    get: 'all',
    conditions: {
      'InvoicesPaymentsCredits.workshop_id': workshop_id,
    },
    contain: {
      Invoices: {
        fields: ['id', 'user_id', 'job_id', 'invoice_number', 'total'],
      },
      'Invoices.JobSchedules': {
        fields: [
          'JobSchedules.id',
          'JobSchedules.user_id',
          'JobSchedules.customer_id',
          'JobSchedules.total',
          'JobSchedules.job_number',
        ],
      },
      'Invoices.JobSchedules.Vehicles.Users.CustomersDetails': {
        conditions: {
          'CustomersDetails.workshop_id': workshop_id,
        },
        fields: [
          'CustomersDetails.user_id',
          'CustomersDetails.workshop_id',
          'CustomersDetails.firstname',
          'CustomersDetails.lastname',
          'CustomersDetails.type',
          'CustomersDetails.company_name',
        ],
      },
    },
    order: {
      'InvoicesPaymentsCredits.id': 'desc',
    },
    fields: [
      'id',
      'invoice_id',
      'workshop_id',
      'receipt_no',
      'payment_method',
      'amount_paid',
      'created_on',
      'balance_remaining',
      'Users.id',
      'Vehicles.id',
    ],
  };
  dispatch({ type: GET_INVOICE_PAYMENTS, payload: { listRequest } });
};

export const getInvoiceCreditsAPI = (state) => (dispatch) => (workshop_id) => {
  const listRequest = {
    get: 'all',
    conditions: {
      'InvoicesPaymentsCreditsOtherDetails.workshop_id': workshop_id,
    },
    contain: {
      InvoicesPaymentsCredits: {
        fields: ['receipt_no', 'id', 'invoice_id'],
      },
      'InvoicesPaymentsCredits.Invoices': {
        fields: [
          'Invoices.invoice_number',
          'Invoices.id',
          'Invoices.total',
          'Invoices.created_on',
        ],
      },
      'InvoicesPaymentsCredits.Invoices.JobSchedules': {
        fields: ['JobSchedules.id', 'JobSchedules.job_number'],
      },
      'InvoicesPaymentsCredits.Invoices.JobSchedules.Vehicles.Users.CustomersDetails':
        {
          conditions: {
            'CustomersDetails.workshop_id': workshop_id,
          },
          fields: [
            'CustomersDetails.user_id',
            'CustomersDetails.workshop_id',
            'CustomersDetails.firstname',
            'CustomersDetails.lastname',
            'CustomersDetails.type',
            'CustomersDetails.company_name',
          ],
        },
    },
    order: {
      'InvoicesPaymentsCreditsOtherDetails.id': 'desc',
    },
    fields: [
      'InvoicesPaymentsCreditsOtherDetails.id',
      'InvoicesPaymentsCreditsOtherDetails.workshop_id',
      'InvoicesPaymentsCreditsOtherDetails.invoice_id',
      'InvoicesPaymentsCreditsOtherDetails.invoice_payment_credit_id',
      'InvoicesPaymentsCreditsOtherDetails.description',
      'InvoicesPaymentsCreditsOtherDetails.amount_paid',
      'InvoicesPaymentsCreditsOtherDetails.amount_credit',
      'InvoicesPaymentsCreditsOtherDetails.credit_no',
      'JobSchedules.id',
      'JobSchedules.job_number',
      'JobSchedules.total',
      'Users.id',
      'Vehicles.id',
    ],
  };
  dispatch({ type: GET_INVOICE_CREDITS, payload: { listRequest } });
};

export const deleteArchiveRaisedInvoiceAPI = (dispatch) => (payload) => {
  dispatch({
    type: DELETE_RAISED_INVOICES,
    payload: payload,
  });
};

export const deleteArchiveCompletedInvoiceAPI = (dispatch) => (payload) => {
  dispatch({
    type: DELETE_COMPLETED_INVOICES,
    payload: payload,
  });
};

export const getInvoicePaymentAction = () => {
  const workshop_id = getWorkshopId();
  const listRequest = {
    get: 'all',
    conditions: {
      'InvoicesPaymentsCredits.workshop_id': workshop_id,
    },
    contain: {
      Invoices: {
        fields: ['id', 'user_id', 'job_id', 'invoice_number', 'total'],
      },
      'Invoices.JobSchedules': {
        fields: [
          'JobSchedules.id',
          'JobSchedules.user_id',
          'JobSchedules.customer_id',
          'JobSchedules.total',
          'JobSchedules.job_number',
        ],
      },
      'Invoices.JobSchedules.Vehicles.Users.CustomersDetails': {
        conditions: {
          'CustomersDetails.workshop_id': workshop_id,
        },
        fields: [
          'CustomersDetails.user_id',
          'CustomersDetails.workshop_id',
          'CustomersDetails.firstname',
          'CustomersDetails.lastname',
          'CustomersDetails.type',
          'CustomersDetails.company_name',
        ],
      },
    },
    order: {
      'InvoicesPaymentsCredits.id': 'desc',
    },
    fields: [
      'id',
      'invoice_id',
      'workshop_id',
      'receipt_no',
      'payment_method',
      'amount_paid',
      'created_on',
      'balance_remaining',
      'Users.id',
      'Vehicles.id',
    ],
  };
  return { type: GET_INVOICE_PAYMENTS, payload: { listRequest } };
};

export const getInvoiceCreditAction = () => {
  const workshop_id = getWorkshopId();
  const listRequest = {
    get: 'all',
    conditions: {
      'InvoicesPaymentsCreditsOtherDetails.workshop_id': workshop_id,
    },
    contain: {
      InvoicesPaymentsCredits: {
        fields: ['receipt_no', 'id', 'invoice_id'],
      },
      'InvoicesPaymentsCredits.Invoices': {
        fields: [
          'Invoices.invoice_number',
          'Invoices.id',
          'Invoices.total',
          'Invoices.created_on',
        ],
      },
      'InvoicesPaymentsCredits.Invoices.JobSchedules': {
        fields: ['JobSchedules.id', 'JobSchedules.job_number'],
      },
      'InvoicesPaymentsCredits.Invoices.JobSchedules.Vehicles.Users.CustomersDetails':
        {
          conditions: {
            'CustomersDetails.workshop_id': workshop_id,
          },
          fields: [
            'CustomersDetails.user_id',
            'CustomersDetails.workshop_id',
            'CustomersDetails.firstname',
            'CustomersDetails.lastname',
            'CustomersDetails.type',
            'CustomersDetails.company_name',
          ],
        },
    },
    order: {
      'InvoicesPaymentsCreditsOtherDetails.id': 'desc',
    },
    fields: [
      'InvoicesPaymentsCreditsOtherDetails.id',
      'InvoicesPaymentsCreditsOtherDetails.workshop_id',
      'InvoicesPaymentsCreditsOtherDetails.invoice_id',
      'InvoicesPaymentsCreditsOtherDetails.invoice_payment_credit_id',
      'InvoicesPaymentsCreditsOtherDetails.description',
      'InvoicesPaymentsCreditsOtherDetails.amount_paid',
      'InvoicesPaymentsCreditsOtherDetails.amount_credit',
      'InvoicesPaymentsCreditsOtherDetails.credit_no',
      'JobSchedules.id',
      'JobSchedules.job_number',
      'JobSchedules.total',
      'Users.id',
      'Vehicles.id',
    ],
  };
  return { type: GET_INVOICE_CREDITS, payload: { listRequest } };
};
