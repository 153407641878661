import React, { useEffect } from "react";
import Swal from "sweetalert2";

const DeleteConfirm = (props) => {
  useEffect(() => {
    Swal.fire({
      title: props.msg,
      text: "",
      icon: "error",
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
         props.deleteRecord();
      }
      else {
        props.handleDeleteClose();
      } 
    });
  }, []);

  return <></>;

};
export default DeleteConfirm;
