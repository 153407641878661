import { takeLatest, put, call } from "redux-saga/effects";
import { SAVE_PART, GET_PART_LIST, DELETE_PART } from "./actionTypes";
import {
  savePartSuccess,
  savePartFail,
  getPartListSuccess,
  getPartListFail,
  deletePartSuccess,
  deletePartFail
} from "./actions";

import { savePart, getPartList, deletePart } from "../../helpers/backendHelper";
import { showError } from "../../utils/alertMessage";

// save part
function* onGetSavePart(data) {
  try {
    const response = yield call(savePart, data);
    if (response) {
      if (response.code === 200) {
        yield put(savePartSuccess(response.data.Products));
      } else {
        yield put(savePartFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(savePartFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(savePartFail(error.response));
  }
}

// get part list or single part
function* onGetPartList(data) {
  try {
    const response = yield call(getPartList, data);
    if (response) {
      if (response.code === 200) {
        yield put(getPartListSuccess(response.data.Products));
      } else {
        yield put(getPartListFail(response.message));
      }
    } else {
      yield put(getPartListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getPartListFail(error.response));
  }
}

// delete part
function* onGetDeletePart(data) {
  try {
    const response = yield call(deletePart, data);
    if (response) {
      if (response.code === 200) {
        yield put(deletePartSuccess(response.data.Products));
      } else {
        yield put(deletePartFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(deletePartFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(deletePartFail(error.response));
  }
}

function* PartSaga() {
  yield takeLatest(SAVE_PART, onGetSavePart);
  yield takeLatest(GET_PART_LIST, onGetPartList);
  yield takeLatest(DELETE_PART, onGetDeletePart);
}

export default PartSaga;
