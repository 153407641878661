import Loadable from "react-loadable";

import { Loading } from "../common/navigation";

const Calender = Loadable({
  loader: () => import("./components/calender"),
  loading: Loading,
});

export const routes = [
  {
    path: "/calender-dialog",
    exact: true,
    name: "Calender",
    element: Calender,
  }
];

export * from "./components";
