import {
  SAVE_JOB_PART,
  SAVE_JOB_PART_SUCCESS,
  SAVE_JOB_PART_FAIL,
  GET_JOB_PART_LIST,
  GET_JOB_PART_LIST_SUCCESS,
  GET_JOB_PART_LIST_FAIL,
  DELETE_JOB_PART,
  DELETE_JOB_PART_SUCCESS,
  DELETE_JOB_PART_FAIL,
  UPDATE_JOB_PART,
  UPDATE_JOB_PART_SUCCESS,
  UPDATE_JOB_PART_FAIL,
  INLINE_UPDATE_JOB_PART,
  INLINE_UPDATE_JOB_PART_SUCCESS,
  INLINE_UPDATE_JOB_PART_FAIL,
  EDIT_JOB_PART_MANY,
  EDIT_JOB_PART_MANY_SUCCESS,
  EDIT_JOB_PART_MANY_FAIL,
  GET_JOB_KIT_PART,
  GET_JOB_KIT_PART_SUCCESS,
  GET_JOB_KIT_PART_FAIL,
  GET_JOB_KIT_LABOUR,
  GET_JOB_KIT_LABOUR_SUCCESS,
  GET_JOB_KIT_LABOUR_FAIL,
  SAVE_JOB_KIT_PART,
  SAVE_JOB_KIT_PART_SUCCESS,
  SAVE_JOB_KIT_PART_FAIL,
  DELETE_JOB_KIT_PART,
  DELETE_JOB_KIT_PART_SUCCESS,
  DELETE_JOB_KIT_PART_FAIL,
  INLINE_UPDATE_JOB_KIT_PART,
  INLINE_UPDATE_JOB_KIT_PART_SUCCESS,
  INLINE_UPDATE_JOB_KIT_PART_FAIL,
  EDIT_JOB_KIT_PART_MANY,
  EDIT_JOB_KIT_PART_MANY_SUCCESS,
  EDIT_JOB_KIT_PART_MANY_FAIL,
  EDIT_JOB_KIT_LABOUR_MANY,
  EDIT_JOB_KIT_LABOUR_MANY_SUCCESS,
  EDIT_JOB_KIT_LABOUR_MANY_FAIL,
  INLINE_UPDATE_JOB_KIT_LABOUR,
  INLINE_UPDATE_JOB_KIT_LABOUR_SUCCESS,
  INLINE_UPDATE_JOB_KIT_LABOUR_FAIL,
} from "./actionTypes";

const initialState = {
  data: [],
  partsData: null,
  error: {
    message: "",
  },
  jobPartsList: [],
  isDataLoad: 0,
  deletePart: null,
  isPartDeleted: false,
  updatePartData: null,
  inlinePartData: null,
  jobKitParts: null,
  jobKitLabours: null,
  saveKitParts: null,
  addNewKitPart : false,
  deleteKitParts: null,
  isDeleteKitParts: false,
  inlineKitPart: null,
  inlineKitLabour: null,
};

const JobPartReducer = (state = initialState, action) => {

  switch (action.type) {
    case SAVE_JOB_PART:
      state = { 
        ...state, 
        partsData: action.partsData,
      };
      break;
    case SAVE_JOB_PART_SUCCESS:
      state = { 
        ...state, 
        partsData: action.payload,
        jobPartsList: [...state.jobPartsList, action.payload],
      };
      break;
    case SAVE_JOB_PART_FAIL:
      state = {
        ...state,
        partsData: null,
        error: {
          message: action.payload,
        },
      };
      break;
    case GET_JOB_PART_LIST:
      state = { 
        ...state, 
        isDataLoad: 0,
      };
      break;
    case GET_JOB_PART_LIST_SUCCESS:
      state = { 
        ...state, 
        jobPartsList: action.payload,
        isDataLoad: 1,
      };
      break;
    case GET_JOB_PART_LIST_FAIL:
      state = {
        ...state,
        jobPartsList: [],
        error: {
          message: action.payload,
        },
        isDataLoad: 1,
      };
      break;
    case DELETE_JOB_PART:
      state = { 
        ...state, 
        deletePart: action.partsData,
        isPartDeleted: false,
      };
      break;
    case DELETE_JOB_PART_SUCCESS:
      state = { 
        ...state, 
        deletePart: action.payload,
        jobPartsList: [
          ...state.jobPartsList.filter((item) => item.id !== action.payload.id),
        ],

        isPartDeleted: true,
      };
      break;
    case DELETE_JOB_PART_FAIL:
      state = {
        ...state,
        deletePart: null,
        isPartDeleted: false,
        error: {
          message: action.payload,
        },
      };
      break;

    case UPDATE_JOB_PART:
      state = { 
        ...state, 
        updatePartData: action.partsData,
      };
      break;
    case UPDATE_JOB_PART_SUCCESS: 
      {
        const parts = [...state.jobPartsList];
        const indexPart = parts.findIndex((c) => c.id === action.payload.id);
        if (indexPart !== -1) {
          parts[indexPart] = {
            ...parts[indexPart],
            ...action.payload,
          };
          // parts[indexPart] = {
          //   retail_price:action.payload.retail
          // }
        }

        state = {
          ...state,
          updatePartData: action.payload,
          jobPartsList: parts,
        };
      }
      break;
    case UPDATE_JOB_PART_FAIL:
      state = {
        ...state,
        updatePartData: null,
        error: {
          message: action.payload,
        },
      };
      break;

    case INLINE_UPDATE_JOB_PART:
      state = { 
        ...state, 
        inlinePartData: action.partsData,
      };
    break;
    case INLINE_UPDATE_JOB_PART_SUCCESS: 
    {
      const parts = [...state.jobPartsList];
      const indexPart = parts.findIndex( c => c.id === action.payload.id);  
      if(indexPart !== -1){
          parts[indexPart] = {
              ...parts[indexPart],
              ...action.payload
          }
      }
      state = { 
        ...state, 
        inlinePartData: action.payload,
        jobPartsList : parts
      };

    }
    break;
    case INLINE_UPDATE_JOB_PART_FAIL:
      state = {
        ...state,
        inlinePartData: null,
        error: {
          message: action.payload,
        },
      };
    break;

    case EDIT_JOB_PART_MANY:
      state = { 
        ...state, 
      };
    break;
    case EDIT_JOB_PART_MANY_SUCCESS:
      state = { 
        ...state, 
      };
    break;
    case EDIT_JOB_PART_MANY_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
      };
    break;

    case GET_JOB_KIT_PART:
      state = { 
        ...state, 
        isDataLoad: 0
      };
    break;
    case GET_JOB_KIT_PART_SUCCESS:
      state = { 
        ...state, 
        jobKitParts: action.payload,
        isDataLoad: 1
      };
    break;
    case GET_JOB_KIT_PART_FAIL:
      state = {
        ...state,
        jobKitParts: [],
        error: {
          message: action.payload,
        },
        isDataLoad: 1
      };
    break;

    case GET_JOB_KIT_LABOUR:
      state = { 
        ...state, 
        isDataLoad: 0
      };
    break;
    case GET_JOB_KIT_LABOUR_SUCCESS:
      state = { 
        ...state, 
        jobKitLabours: action.payload,
        isDataLoad: 1
      };
    break;
    case GET_JOB_KIT_LABOUR_FAIL:
      state = {
        ...state,
        jobKitLabours: [],
        error: {
          message: action.payload,
        },
        isDataLoad: 1
      };
    break;

    case SAVE_JOB_KIT_PART:
      state = { 
        ...state, 
        isDataLoad: 0,
        addNewKitPart: false,
      };
    break;
    case SAVE_JOB_KIT_PART_SUCCESS:
      state = { 
        ...state, 
        saveKitParts: action.payload,
        jobKitParts: [...state.jobKitParts,action.payload],  
        isDataLoad: 1,
        addNewKitPart: true,
      };
    break;
    case SAVE_JOB_KIT_PART_FAIL:
      state = {
        ...state,
        saveKitParts: [],
        error: {
          message: action.payload,
        },
        isDataLoad: 1,
        addNewKitPart: false
      };
    break;

    case DELETE_JOB_KIT_PART:
      state = { 
        ...state, 
        isDataLoad: 0,
        isDeleteKitParts: false,
      };
    break;
    case DELETE_JOB_KIT_PART_SUCCESS:
      state = { 
        ...state, 
        deleteKitParts: action.payload,
        jobKitParts:  [
          ...state.jobKitParts.filter(item => (item.id !== action.payload.id)), 
        ],
        isDeleteKitParts: true,
        isDataLoad: 1
      };
    break;
    case DELETE_JOB_KIT_PART_FAIL:
      state = {
        ...state,
        deleteKitParts: [],
        error: {
          message: action.payload,
        },
        isDataLoad: 1,
        isDeleteKitParts: false,
      };
    break;

    case INLINE_UPDATE_JOB_KIT_PART:
      state = { 
        ...state, 
        inlineKitPart: action.partsData,
      };
    break;
    case INLINE_UPDATE_JOB_KIT_PART_SUCCESS: 
    {
      const parts = [...state.jobKitParts];
      const indexPart = parts.findIndex( c => c.id === action.payload.id);  
      if(indexPart !== -1){
          parts[indexPart] = {
              ...parts[indexPart],
              ...action.payload
          }
      }
      state = { 
        ...state, 
        inlineKitPart: action.payload,
        jobKitParts : parts
      };

    }
    break;
    case INLINE_UPDATE_JOB_KIT_PART_FAIL:
      state = {
        ...state,
        inlineKitPart: null,
        error: {
          message: action.payload,
        },
      };
    break;

    case EDIT_JOB_KIT_PART_MANY:
      state = { 
        ...state, 
      };
    break;
    case EDIT_JOB_KIT_PART_MANY_SUCCESS:
      state = { 
        ...state, 
      };
    break;
    case EDIT_JOB_KIT_PART_MANY_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
      };
    break;

    case EDIT_JOB_KIT_LABOUR_MANY:
      state = { 
        ...state, 
      };
    break;
    case EDIT_JOB_KIT_LABOUR_MANY_SUCCESS:
      state = { 
        ...state, 
      };
    break;
    case EDIT_JOB_KIT_LABOUR_MANY_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
      };
    break;

    case INLINE_UPDATE_JOB_KIT_LABOUR:
      state = { 
        ...state, 
        inlineKitLabour: action.partsData,
      };
    break;
    case INLINE_UPDATE_JOB_KIT_LABOUR_SUCCESS: 
    {
      const parts = [...state.jobKitLabours];
      const indexPart = parts.findIndex( c => c.id === action.payload.id);  
      if(indexPart !== -1){
          parts[indexPart] = {
              ...parts[indexPart],
              ...action.payload
          }
      }
      state = { 
        ...state, 
        inlineKitLabour: action.payload,
        jobKitLabours : parts
      };

    }
    break;
    case INLINE_UPDATE_JOB_KIT_LABOUR_FAIL:
      state = {
        ...state,
        inlineKitLabour: null,
        error: {
          message: action.payload,
        },
      };
    break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default JobPartReducer;
