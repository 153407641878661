import {
  GET_FORGOT_EMAIL,
  GET_FORGOT_EMAIL_SUCCESS,
  GET_FORGOT_EMAIL_FAIL
} from "./actionTypes";

export const getForgotEmail = () => {
  return {
    type: GET_FORGOT_EMAIL,
  };
};

export const getForgotEmailSuccess = (data) => {
  return {
    type: GET_FORGOT_EMAIL_SUCCESS,
    payload: data,
  };
};

export const getForgotEmailFail = (error) => {
  return {
    type: GET_FORGOT_EMAIL_FAIL,
    payload: error,
  };
};
