import Loadable from "react-loadable";

import { Loading } from "../common/navigation";

const BookingDiary = Loadable({
  loader: () => import("./components/BookingDiary"),
  loading: Loading,
});

const Dashboard = Loadable({
  loader: () => import("./components/Dashboard"),
  loading: Loading,
});

export const routes = [
  {
    path: "/booking-diary",
    exact: true,
    name: "home",
    element: BookingDiary,
  },
  {
    path: "/home",
    exact: true,
    name: "home",
    element: Dashboard,
  }
];

export * from "./components";
