import React from "react";
import Container from "react-bootstrap/Container";
import Header from "./header";
import Sidebar from "../../../layouts/sidebar";

const Wrapper = (props) => {
  return (
    <div className="main-wrapper">
    <Header />
    <Container fluid>
      <div className="page-wrapper">
        <Sidebar />
        
        
        {props.children}
      </div>
    </Container>
  </div>
  )
}

export default Wrapper