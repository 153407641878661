
export function getStateAbbreviations(name) {
    let stateName = '';
    switch (name) {
        case "New South Wales":
            stateName = 'NSW';
            break;
        case "Victoria":
            stateName = 'VIC';
            break;
        case "Queensland":
            stateName = 'QLD';
            break;
        case "Northern Territory":
            stateName = 'NT';
            break;
        case "Tasmania":
            stateName = 'TA';
            break;
        case "South Australia":
            stateName = 'SA';
            break;
        case "Western Australia":
            stateName = 'WA';
            break;
        case "Australian Capital Territory":
            stateName = 'ACT';
            break;
        case "Northland":
            stateName = 'NTL';
            break;
        case "Auckland":
            stateName = 'AKL';
            break;
        case "Waikato":
            stateName = 'WA';
            break;
        case "Taranaki":
            stateName = 'TA';
            break;
        case "Hawe’s Bay":
            stateName = 'HB';
            break;
        case "Wellington":
            stateName = 'WLN';
            break;
        case "Nelson":
            stateName = 'NL';
            break;
        case "Marlborough":
            stateName = 'ML';
            break;
        case "Westland":
            stateName = 'WL';
            break;
        case "Southland":
            stateName = 'SL';
            break;
        case "Otago":
            stateName = 'OT';
            break;
        case "Canterbury":
            stateName = 'CB';
            break;
        case "Gujarat":
            stateName = 'GUJ';
            break;
        case "Rajasthan":
            stateName = 'RJ';
            break;
        case "Maharashtra":
            stateName = 'MH';
            break;
        case "Califorina":
            stateName = 'CA';
            break;
        case "Texas":
            stateName = 'TX';
            break;
        case "Alabama":
            stateName = 'AL';
            break;
        case "Alaska":
            stateName = 'AK';
            break;
        case "Nevada":
            stateName = 'NV';
            break;
        case "Birmingham":
            stateName = 'BIR';
            break;
        case "Bristol":
            stateName = 'BRI';
            break;
        case "Cambridge":
            stateName = 'CAM';
            break;

        default:
            stateName = 'QLD';
        break;
    }
    return stateName;
}

const stateAbbreviations = {
    getStateAbbreviations
};

export default stateAbbreviations;
