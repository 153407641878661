import React from "react";
// import { useNavigate } from "react-router-dom";
import customer from "../../assets/images/customer.svg";
import bookJob from "../../assets/images/book-job.svg";
import cashSale from "../../assets/images/cash-sale.svg";
import inspections from "../../assets/images/inspections.svg";
import schedule from "../../assets/images/schedule.svg";
import materials from "../../assets/images/materials.svg";
import customerBlue from "../../assets/images/customer-blue.svg";
import bookJobBlue from "../../assets/images/book-job-blue.svg";
import cashSaleBlue from "../../assets/images/cash-sale-blue.svg";
import inspectionsBlue from "../../assets/images/inspections-blue.svg";
import scheduleBlue from "../../assets/images/schedule-blue.svg";
import materialsBlue from "../../assets/images/materials-blue.svg";
import customerWhite from "../../assets/images/customer-white.svg";
import bookJobWhite from "../../assets/images/book-job-white.svg";
import cashSaleWhite from "../../assets/images/cash-sale-white.svg";
import inspectionsWhite from "../../assets/images/inspections-white.svg";
import scheduleWhite from "../../assets/images/schedule-white.svg";
import materialsWhite from "../../assets/images/materials-white.svg";
import { Scrollbars } from "react-custom-scrollbars";
import { Link } from "react-router-dom";
import logo from "../../assets/images/5iQ_logo.png";
import logoBlack from "../../assets/images/5iQ_logo_black.png";
import { setActiveTab } from "../../store/Jobs/actions";
import { useDispatch } from "react-redux";

const Sidebar = ({ children }) => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
      <div className="sidebar-wrapper">
        <ul className="top-list">
          <li>
            <Link to="/home">
              <img className="normal-img" src={customer} alt=""></img>
              <img className="hover-img" src={customerBlue} alt=""></img>
              <img className="dark-theme-img" src={customerWhite} alt=""></img>
              <span>Contacts</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/home">Customer</Link>
              </li>
              <li>
                <Link to="/home">Supplier</Link>
              </li>
              <li>
                <Link to="/home">Customer</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/home">
              <img className="normal-img" src={bookJob} alt=""></img>
              <img className="hover-img" src={bookJobBlue} alt=""></img>
              <img className="dark-theme-img" src={bookJobWhite} alt=""></img>
              <span>Leads</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/home">Online Leads</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/quote-list">
              <img className="normal-img" src={cashSale} alt=""></img>
              <img className="hover-img" src={cashSaleBlue} alt=""></img>
              <img className="dark-theme-img" src={cashSaleWhite} alt=""></img>
              <span>Quotes</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/quote-list">Open Quotes</Link>
              </li>
              <li>
                <Link to="/quote-list">Won Quotes</Link>
              </li>
              <li>
                <Link to="/quote-list">Lost Quotes</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/job-list">
              <img className="normal-img" src={bookJob} alt=""></img>
              <img className="hover-img" src={bookJobBlue} alt=""></img>
              <img className="dark-theme-img" src={bookJobWhite} alt=""></img>
              <span>Jobs</span>
            </Link>
            <ul className="sub-menu">
              <li onClick={() => dispatch(setActiveTab("unassigned-job"))}>
                <Link to="/job-list">Unassigned Jobs</Link>
              </li>
              <li onClick={() => dispatch(setActiveTab("assigned-job"))}>
                <Link to="/job-list">Assigned Jobs</Link>
              </li>
              <li onClick={() => dispatch(setActiveTab("inProgress-job"))}>
                <Link to="/job-list">In Progress Jobs</Link>
              </li>
              <li onClick={() => dispatch(setActiveTab("complete-job"))}>
                <Link to="/job-list">Completed Jobs</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/home">
              <img className="normal-img" src={inspections} alt=""></img>
              <img className="hover-img" src={inspectionsBlue} alt=""></img>
              <img
                className="dark-theme-img"
                src={inspectionsWhite}
                alt=""
              ></img>
              <span>Inspections</span>
            </Link>
          </li>
          <li>
            <Link to="/scheduler">
              <img className="normal-img" src={schedule} alt=""></img>
              <img className="hover-img" src={scheduleBlue} alt=""></img>
              <img className="dark-theme-img" src={scheduleWhite} alt=""></img>
              <span>Scheduler</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/scheduler">Day</Link>
              </li>
              <li>
                <Link to="/home">Week</Link>
              </li>
              <li>
                <Link to="/home">Month</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/invoice">
              <i className="fa fa-files-o" aria-hidden="true"></i>
              <span>Invoices</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/home">Cash Sale</Link>
              </li>
              <li>
                <Link to="/invoice#raised-invoices">Raised Invoices</Link>
              </li>
              <li>
                <Link to="/invoice#completed-invoices">Completed Invoices</Link>
              </li>
              <li>
                <Link to="/invoice#invoice-payments">Invoice Payments</Link>
              </li>
              <li>
                <Link to="/invoice#invoice-credit">Invoice Credit</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/marketing/marketing-dashboard/">
              <i className="fa fa-bullhorn"></i>
              <span>Marketing</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/marketing/broadcast">Broadcast</Link>
              </li>
              <li>
                <Link to="/marketing/campaign">Campaign</Link>
              </li>
              <li>
                <Link to="/marketing/referral">Referral</Link>
              </li>
              <li>
                <Link to="/home">Feedback</Link>
              </li>
              <li>
                <Link to="/home">Slow Day</Link>
              </li>
              <li>
                <Link to="/home">Last Minute</Link>
              </li>
            </ul>
          </li>
        </ul>
        <ul className="bottom-list">
          <li>
            <Link to="/home">
              <img className="normal-img" src={materials} alt=""></img>
              <img className="hover-img" src={materialsBlue} alt=""></img>
              <img className="dark-theme-img" src={materialsWhite} alt=""></img>
              <span>Materials</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/home">Parts & Kits</Link>
              </li>
              <li>
                <Link to="/purchase-list">Purchase Orders</Link>
              </li>
              <li>
                <Link to="/home">Stock</Link>
              </li>
              <li>
                <Link to="/home">Stock Take</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/home">
              <i className="fa fa-list"></i>
              <span>List</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/home">Service Schedule</Link>
              </li>
              <li>
                <Link to="/home">Vehicle List</Link>
              </li>
              <li>
                <Link to="/home">Account Sync</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/home">
              <i className="fa fa-bar-chart"></i>
              <span>Reports</span>
            </Link>
            <ul className="sub-menu bottom-align">
              <Scrollbars
                autoHeight
                autoHeightMax={240}
                className="custom-scrollbar"
                style={{ width: "100%" }}
                renderThumbVertical={(props) => (
                  <div {...props} className="thumb-vertical black-thumb" />
                )}
              >
                <li>
                  <Link to="/invoice">Invoices</Link>
                </li>
                <li>
                  <Link to="/home">Jobs</Link>
                </li>
                <li>
                  <Link to="/home">Customer</Link>
                </li>
                <li>
                  <Link to="/home">Labour</Link>
                </li>
                <li>
                  <Link to="/home">Materials</Link>
                </li>
                <li>
                  <Link to="/home">Marketing</Link>
                </li>
                <li>
                  <Link to="/home">Notifications</Link>
                </li>
                <li>
                  <Link to="/home">Schedule Reports</Link>
                </li>
                <li>
                  <Link to="/home">Financials</Link>
                </li>
              </Scrollbars>
            </ul>
          </li>
          <li>
            <Link to="/settings">
              <i className="fa fa-gears"></i>
              <span>Settings</span>
            </Link>
            <ul className="sub-menu bottom-align">
              <Scrollbars
                autoHeight
                autoHeightMax={280}
                className="custom-scrollbar"
                style={{ width: "100%" }}
                renderThumbVertical={(props) => (
                  <div {...props} className="thumb-vertical black-thumb" />
                )}
              >
                <li>
                  <Link to="/settings_company_detail">Company Detail</Link>
                </li>
                <li>
                  <Link to="/settings_defaults">Defaults</Link>
                </li>
                <li>
                  <Link to="/settings_security_group">Security Group</Link>
                </li>
                <li>
                  <Link to="/home">Chart Of Accounts</Link>
                </li>
                <li>
                  <Link to="/home"> Utilities Import</Link>
                </li>
                <li>
                  <Link to="/statuses">Statuses</Link>
                </li>
                <li>
                  <Link to="/workshop_bays"> Workshop Bays</Link>
                </li>
                <li>
                  <Link to="/home">Canned Responses</Link>
                </li>
                <li>
                  <Link to="/home">Message Templates</Link>
                </li>
                <li>
                  <Link to="/home">Storage Devices</Link>
                </li>
                <li>
                  <Link to="/home">Supplier Integration</Link>
                </li>
                <li>
                  <Link to="/home">Payment Integration</Link>
                </li>
                <li>
                  <Link to="/home">3rd Party Company</Link>
                </li>
                <li>
                  <Link to="/home">Subscription</Link>
                </li>
                <li>
                  <Link to="/home">Subscription Transaction</Link>
                </li>
                <li>
                  <Link to="/home">Inspection Templates</Link>
                </li>
              </Scrollbars>
            </ul>
          </li>
          <li className="sidebar-logo">
            <Link to="/home">
              <img className="light-theme" src={logoBlack} alt=""></img>
              <img className="dark-theme" src={logo} alt=""></img>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
