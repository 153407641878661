import { takeLatest, put, call } from "redux-saga/effects";
import {
  GET_JOB_BOOKED_DETAILS,
  GET_JOB_BOOKED_KEY_TAGS,
  GET_REGO_LOOKUP_DATA,
  GET_TOTAL_TIMER,
  UPDATE_JOB_TIMER,
  UPDATE_VEHICLE,
  GET_VEHICLE_HISTORY,
  GET_JOB_NOTES,
  GET_MESSAGE,
  GET_INSPECTION_VIEW,
  GET_INTERNAL_NOTES,
  GET_INTERNAL_NOTES_SCRIPTS,
  ADD_JOB_NOTES,
  UPDATE_JOB_NOTES,
  GET_INSPECTIONS,
  GET_INSPECTION_TEMPLATES,
  GET_TECHNICIAN_LIST,
  APPROVE_MANUAL_INSPECTION,
  RESEND_INSPECTION,
  ADD_PARTS_MANUAL_INSPECTION_APPROVED,
  GET_INSPECTION_GROUP,
  SAVE_INSPECTION,
  GET_ATTACHMENT,
  DEFAULT_PRICE_SUMMARY,
} from "./actionTypes";
import {
  getJobBookedDetailsSuccess,
  getJobBookedDetailsFail,
  getJobBookedKeyTagsDetailsSuccess,
  getJobBookedKeyTagsDetailsFail,
  getRegoLookUpDataSuccess,
  getRegoLookUpDataFail,
  updateJobTimerSuccess,
  updateJobTimerFail,
  updateVehicleSuccess,
  updateVehicleFail,
  getTotalTimerSuccess,
  getTotalTimerFail,
  getVehicleHistorySuccess,
  getVehicleHistoryFail,
  getJobNotesSuccess,
  getJobNotesFail,
  getMessageSuccess,
  getMessageFail,
  getInspectionViewSuccess,
  getInspectionViewFail,
  getInternalNotesSuccess,
  getInternalNotesFail,
  getInternalNotesScriptsSuccess,
  getInternalNotesScriptsFail,
  addJobNotesSuccess,
  addJobNotesFail,
  updateJobNotesSuccess,
  updateJobNotesFail,
  getInspectionsSuccess,
  getInspectionsFail,
  getInspectionTemplatesSuccess,
  getInspectionTemplatesFail,
  getTechnicianListSuccess,
  getTechnicianListFail,
  approveManualInspectionFail,
  approveManualInspectionSuccess,
  resendInspectionSuccess,
  resendInspectionFail,
  addApprovedPartsInspectionFail,
  addApprovedPartsInspectionSuccess,
  getInspectionGroupFail,
  getInspectionGroupSuccess,
  saveInspectionSuccess,
  saveInspectionFail,
  getAttechmentSuccess,
  getAttechmentFail,
  defaultPriceSummarySuccess,
  defaultPriceSummaryFail,
} from "./actions";

import {
  getJobBookedDetails,
  getRegoLookUpDetails,
  updateJobTimer,
  updateVehicle,
  getVehicleHistoryDetails,
  getJobNotes,
  getNotification,
  getInspectionView,
  getInternalNotes,
  getInternalNotesScripts,
  addJobNotes,
  updateJobNotes,
  getInspections,
  getInspectionTemplates,
  getTechnician,
  getAttachment,
  setApproveManualInspection,
  resendInspection,
  addApprovedPartsInspection,
  getInspectionGroups,
} from "../../helpers/backendHelper";

import { showSuccess } from "utils/alertMessage";
import { saveInspection } from "./api/saveInspection";
import { toast } from "react-toastify";
import { getInspectionsData } from "views/job/components/jobDetails/getInspections";

function* onGetJobBookedDetails(data) {
  try {
    const response = yield call(getJobBookedDetails, data);
    if (response) {
      if (response.code === 200) {
        let job_details = response.data.JobSchedules
          ? response.data.JobSchedules
          : "";
        yield put(getJobBookedDetailsSuccess(job_details));
      } else {
        yield put(getJobBookedDetailsFail(response.message));
      }
    } else {
      yield put(getJobBookedDetailsFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getJobBookedDetailsFail(error.response));
  }
}

function* onGetJobBookedKeyTagDetails(data) {
  try {
    const response = yield call(getJobBookedDetails, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getJobBookedKeyTagsDetailsSuccess(response.data.JobSchedules)
        );
      } else {
        yield put(getJobBookedKeyTagsDetailsFail(response.message));
      }
    } else {
      yield put(getJobBookedKeyTagsDetailsFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getJobBookedDetailsFail(error.response));
  }
}

function* onGetRegoLookUpData(data) {
  try {
    const response = yield call(getRegoLookUpDetails, data);
    if (response) {
      if (response.code === 200) {
        yield put(getRegoLookUpDataSuccess(response));
      } else {
        yield put(getRegoLookUpDataFail(response));
      }
    } else {
      yield put(getRegoLookUpDataFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getRegoLookUpDataFail(error.response));
  }
}

function* onUpdateJobTimerData(data) {
  try {
    const response = yield call(updateJobTimer, data);
    if (response) {
      if (response.code === 200) {
        yield put(updateJobTimerSuccess(response.data));
      } else {
        yield put(updateJobTimerFail(response.message));
      }
    } else {
      yield put(updateJobTimerFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(updateJobTimerFail(error.response));
  }
}

function* onUpdateVehicleData(data) {
  try {
    const response = yield call(updateVehicle, data);
    if (response) {
      if (response.code === 200) {
        yield put(updateVehicleSuccess(response.data));
      } else {
        yield put(updateVehicleFail(response.message));
      }
    } else {
      yield put(updateVehicleFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(updateVehicleFail(error.response));
  }
}

function* onGetTotalTimerData(data) {
  try {
    const response = yield call(getJobBookedDetails, data);
    if (response) {
      if (response.code === 200) {
        yield put(getTotalTimerSuccess(response.data.JobSchedules));
      } else {
        yield put(getTotalTimerFail(response.message));
      }
    } else {
      yield put(getTotalTimerFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getTotalTimerFail(error.response));
  }
}

function* onGetVehicleHistoryData(data) {
  try {
    const response = yield call(getVehicleHistoryDetails, data);
    if (response) {
      if (response.code === 200) {
        yield put(getVehicleHistorySuccess(response.data.JobSchedules));
      } else {
        yield put(getVehicleHistoryFail(response.message));
      }
    } else {
      yield put(getVehicleHistoryFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getVehicleHistoryFail(error.response));
  }
}

function* onGetMessage(data) {
  try {
    const response = yield call(getNotification, data);
    if (response) {
      if (response.code === 200) {
        yield put(getMessageSuccess(response.data.NotificationsData));
      } else {
        yield put(getMessageFail(response.message));
      }
    } else {
      yield put(getMessageFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getMessageFail(error.response));
  }
}

function* onGetInspectionView(data) {
  try {
    const response = yield call(getInspectionView, data);
    if (response) {
      if (response.code === 200) {
        yield put(getInspectionViewSuccess(response.data.InspInspections));
      } else {
        yield put(getInspectionViewFail(response.message));
      }
    } else {
      yield put(getInspectionViewFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getInspectionViewFail(error.response));
  }
}

function* onGetJobNotes(data) {
  try {
    const response = yield call(getJobNotes, data);
    if (response) {
      if (response.code === 200) {
        yield put(getJobNotesSuccess(response.data.JobNotes[0]));
      } else {
        yield put(getJobNotesFail(response.message));
      }
    } else {
      yield put(getJobNotesFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getJobNotesFail(error.response));
  }
}

function* onGetInternalNotes(data) {
  try {
    const response = yield call(getInternalNotes, data);
    if (response) {
      if (response.code === 200) {
        yield put(getInternalNotesSuccess(response.data.InternalNotes));
      } else {
        yield put(getInternalNotesFail(response.message));
      }
    } else {
      yield put(getInternalNotesFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getInternalNotesFail(error.response));
  }
}

function* onGetInternalNotesScripts(data) {
  try {
    const response = yield call(getInternalNotesScripts, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getInternalNotesScriptsSuccess(response.data.WorkshopScriptsDetails)
        );
      } else {
        yield put(getInternalNotesScriptsFail(response.message));
      }
    } else {
      yield put(getInternalNotesScriptsFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getInternalNotesScriptsFail(error.response));
  }
}
function* onUpdateJobNotes(data) {
  try {
    const response = yield call(updateJobNotes, data);
    if (response) {
      if (response.code === 200) {
        yield put(updateJobNotesSuccess(response.data.JobNotes));
      } else {
        yield put(updateJobNotesFail(response.message));
      }
    } else {
      yield put(updateJobNotesFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(updateJobNotesFail(error.response));
  }
}

function* onGetInspections(data) {
  try {
    const response = yield call(getInspections, data);
    if (response) {
      if (response.code === 200) {
        const InspInspections = response.data.InspInspections;
        if (InspInspections && InspInspections.length) {
          InspInspections[0].insp_question_ans.forEach((queAns) => {
            queAns.answers = JSON.parse(queAns.answers);
          });
        }
        yield put(getInspectionsSuccess(InspInspections));
      } else {
        yield put(getInspectionsFail(response.message));
      }
    } else {
      yield put(getInspectionsFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getInspectionsFail(error.response));
  }
}

function* onGetInspectionTemplates(data) {
  try {
    const response = yield call(getInspectionTemplates, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getInspectionTemplatesSuccess(response?.data?.InspTemplates || [])
        );
      } else {
        yield put(getInspectionTemplatesFail(response.message));
      }
    } else {
      yield put(getInspectionTemplatesFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getInspectionTemplatesFail(error.response));
  }
}

function* onAddJobNotes(data) {
  try {
    const response = yield call(addJobNotes, data);
    if (response) {
      if (response.code === 200) {
        yield put(addJobNotesSuccess(response.data.JobNotes));
      } else {
        yield put(addJobNotesFail(response.message));
      }
    } else {
      yield put(addJobNotesFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(addJobNotesFail(error.response));
  }
}

function* onGetTechnician(data) {
  try {
    const response = yield call(getTechnician, data);
    if (response) {
      if (response.code === 200) {
        yield put(getTechnicianListSuccess(response));
      } else {
        yield put(getTechnicianListFail(response.message));
      }
    } else {
      yield put(getTechnicianListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getTechnicianListFail(error.response));
  }
}

function* onGetAttachment(data) {
  try {
    const response = yield call(getAttachment, data);
    if (response) {
      if (response.code === 200) {
        yield put(getAttechmentSuccess(response.data.JobAttachments));
      } else {
        yield put(getAttechmentFail(response.message));
      }
    } else {
      yield put(getAttechmentFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getAttechmentFail(error.response));
  }
}

function* onDefaultPriceSummary(data) {
  try {
    yield put(defaultPriceSummarySuccess(data.payload));
  } catch (error) {
    yield put(defaultPriceSummaryFail(error.response));
  }
}

function* onApprveInspectionManual(data) {
  try {
    const response = yield call(setApproveManualInspection, data);
    if (response) {
      if (response.code === 200) {
        yield put(approveManualInspectionSuccess(response));
        showSuccess("Inspection Approved");
      } else {
        yield put(approveManualInspectionFail(response.message));
      }
    } else {
      yield put(approveManualInspectionFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(approveManualInspectionFail(error.response));
  }
}

function* onResentInspection(data) {
  try {
    const response = yield call(resendInspection, data);
    if (response) {
      if (response.code === 200) {
        yield put(resendInspectionSuccess(response.data));
      } else {
        yield put(resendInspectionFail(response.message));
      }
    } else {
      yield put(resendInspectionFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(resendInspectionFail(error));
  }
}

function* onStoreApprovedInspectionParts(data) {
  try {
    const response = yield call(addApprovedPartsInspection, data);
    if (response) {
      if (response.code === 200) {
        yield put(addApprovedPartsInspectionSuccess(response.data));
      } else {
        yield put(addApprovedPartsInspectionFail(response.message));
      }
    } else {
      yield put(addApprovedPartsInspectionFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(addApprovedPartsInspectionFail(error));
  }
}

function* onGetInspectionGroups(data) {
  try {
    const response = yield call(getInspectionGroups, data);
    if (response) {
      if (response.code === 200) {
        yield put(getInspectionGroupSuccess(response?.data?.InspGroups || []));
      } else {
        yield put(getInspectionGroupFail(response.message));
      }
    } else {
      yield put(getInspectionGroupFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getInspectionGroupFail(error));
  }
}

function* onSaveInspection(action) {
  try {
    const payload = action.payload;
    const { inspections_id } = payload;
    delete payload.inspections_id;
    const data = yield call(saveInspection, payload);
    if (data?.code === 200) {
      toast.success("Inpsection Updated");
      yield put(saveInspectionSuccess(data));
      yield put(getInspectionsData(inspections_id));
    } else {
      toast.success("Inpsection Failed");
      yield put(saveInspectionFail());
    }
  } catch (error) {
    yield saveInspectionFail();
  }
}

function* JobDetails() {
  yield takeLatest(GET_JOB_BOOKED_DETAILS, onGetJobBookedDetails);
  yield takeLatest(GET_JOB_BOOKED_KEY_TAGS, onGetJobBookedKeyTagDetails);
  yield takeLatest(GET_REGO_LOOKUP_DATA, onGetRegoLookUpData);
  yield takeLatest(UPDATE_JOB_TIMER, onUpdateJobTimerData);
  yield takeLatest(UPDATE_VEHICLE, onUpdateVehicleData);
  yield takeLatest(GET_TOTAL_TIMER, onGetTotalTimerData);
  yield takeLatest(GET_VEHICLE_HISTORY, onGetVehicleHistoryData);
  yield takeLatest(GET_JOB_NOTES, onGetJobNotes);
  yield takeLatest(ADD_JOB_NOTES, onAddJobNotes);
  yield takeLatest(UPDATE_JOB_NOTES, onUpdateJobNotes);
  yield takeLatest(GET_MESSAGE, onGetMessage);
  yield takeLatest(GET_INSPECTION_VIEW, onGetInspectionView);
  yield takeLatest(GET_INTERNAL_NOTES, onGetInternalNotes);
  yield takeLatest(GET_INTERNAL_NOTES_SCRIPTS, onGetInternalNotesScripts);
  yield takeLatest(GET_INSPECTIONS, onGetInspections);
  yield takeLatest(GET_INSPECTION_TEMPLATES, onGetInspectionTemplates);
  yield takeLatest(GET_TECHNICIAN_LIST, onGetTechnician);
  yield takeLatest(GET_ATTACHMENT, onGetAttachment);
  yield takeLatest(DEFAULT_PRICE_SUMMARY, onDefaultPriceSummary);
  yield takeLatest(APPROVE_MANUAL_INSPECTION, onApprveInspectionManual);
  yield takeLatest(RESEND_INSPECTION, onResentInspection);
  yield takeLatest(
    ADD_PARTS_MANUAL_INSPECTION_APPROVED,
    onStoreApprovedInspectionParts
  );
  yield takeLatest(GET_INSPECTION_GROUP, onGetInspectionGroups);
  yield takeLatest(SAVE_INSPECTION, onSaveInspection);
}

export default JobDetails;
