import {
  GET_LOGIN,
  GET_LOGIN_SUCCESS,
  GET_LOGIN_FAIL,
  GET_LOGOUT,
} from "./actionTypes";

const initialState = {
  token: '',
  userData: null,
  loadingLogins: false,
  role_id: 0,
  error: {
    message: "",
  },
};

const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOGIN:
      state = { 
        ...state, 
        loadingLogins: true,
        token: '',
        userData: null,
        role_id:0
       };
      break;
    case GET_LOGIN_SUCCESS:
      state = { 
        ...state, 
        loadingLogins: false,
        token: action.payload.email+':'+action.payload.api_plainkey,
        userData: action.payload,
        role_id: action.payload.role_id
      };
      break;
    case GET_LOGIN_FAIL:
      state = {
        ...state,
        token: '',
        userData: null,
        role_id:0,
        error: {
          message: action.payload,
        },
        loadingLogins: false,
      };
      break;
    case GET_LOGOUT:
      state = {
        ...state,
        token: '',
        userData: null,
        role_id:0,
        loadingLogins: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default LoginReducer;
