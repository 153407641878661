export const GET_RAISED_INVOICES = "GET_RAISED_INVOICES";
export const GET_RAISED_INVOICES_COUNT = "GET_RAISED_INVOICES_COUNT";
export const GET_RAISED_INVOICES_COUNT_SUCCESS =
  "GET_RAISED_INVOICES_COUNT_SUCCESS";
export const GET_RAISED_INVOICES_COUNT_FAIL = "GET_RAISED_INVOICES_COUNT_FAIL";
export const GET_RAISED_INVOICES_SUCCESS = "GET_RAISED_INVOICES_SUCCESS";
export const GET_RAISED_INVOICES_FAIL = "GET_RAISED_INVOICES_FAIL";

export const GET_COMPLETED_INVOICES = "GET_COMPLETED_INVOICES";
export const GET_COMPLETED_INVOICES_SUCCESS = "GET_COMPLETED_INVOICES_SUCCESS";
export const GET_COMPLETED_INVOICES_FAIL = "GET_COMPLETED_INVOICES_FAIL";
export const GET_COMPLETED_INVOICES_COUNT = "GET_COMPLETED_INVOICES_COUNT";
export const GET_COMPLETED_INVOICES_COUNT_SUCCESS =
  "GET_COMPLETED_INVOICES_COUNT_SUCCESS";
export const GET_COMPLETED_INVOICES_COUNT_FAIL =
  "GET_COMPLETED_INVOICES_COUNT_FAIL";

export const GET_INVOICE_PAYMENTS = "GET_INVOICE_PAYMENTS";
export const GET_INVOICE_PAYMENTS_SUCCESS = "GET_INVOICE_PAYMENTS_SUCCESS";
export const GET_INVOICE_PAYMENTS_FAIL = "GET_INVOICE_PAYMENTS_FAIL";

export const GET_INVOICE_CREDITS = "GET_INVOICE_CREDITS";
export const GET_INVOICE_CREDITS_SUCCESS = "GET_INVOICE_CREDITS_SUCCESS";
export const GET_INVOICE_CREDITS_FAIL = "GET_INVOICE_CREDITS_FAIL";

export const DELETE_RAISED_INVOICES = "DELETE_RAISED_INVOICES";
export const DELETE_RAISED_INVOICES_SUCCESS = "DELETE_RAISED_INVOICES_SUCCESS";
export const DELETE_RAISED_INVOICES_FAIL = "DELETE_RAISED_INVOICES_FAIL";

export const DELETE_COMPLETED_INVOICES = "DELETE_COMPLETED_INVOICES";
export const DELETE_COMPLETED_INVOICES_SUCCESS =
  "DELETE_COMPLETED_INVOICES_SUCCESS";
export const DELETE_COMPLETED_INVOICES_FAIL = "DELETE_COMPLETED_INVOICES_FAIL";

export const GET_RECEIPT_DETAILS = "GET_RECEIPT_DETAILS";
export const GET_RECEIPT_DETAILS_SUCCESS = "GET_RECEIPT_DETAILS_SUCCESS";
export const GET_RECEIPT_DETAILS_FAIL = "GET_RECEIPT_DETAILS_FAIL";
export const CLEAR_RECEIPT_DETAILS = "CLEAR_RECEIPT_DETAILS";

export const DELETE_PAYMENT_INVOICES = "DELETE_PAYMENT_INVOICES";
export const DELETE_PAYMENT_INVOICES_SUCCESS =
  "DELETE_PAYMENT_INVOICES_SUCCESS";
export const DELETE_PAYMENT_INVOICES_FAIL = "DELETE_PAYMENT_INVOICES_FAIL";

export const DELETE_CREDIT_INVOICES = "DELETE_CREDIT_INVOICES";
export const DELETE_CREDIT_INVOICES_SUCCESS = "DELETE_CREDIT_INVOICES_SUCCESS";
export const DELETE_CREDIT_INVOICES_FAIL = "DELETE_CREDIT_INVOICES_FAIL";
