import {
  addInvoiceCreate,
  getInsurance,
  getInvoicesList,
  getJobCannedList,
  getJobNotes,
  getLabourChargesList,
  getUnassignedJobs,
  getWorkshopOtherDetailsView,
  sendEmailInvoice,
} from "helpers/backendHelper";
import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  createInvoiceCredit,
  createInvoiceCreditFail,
  createInvoiceCreditSuccess,
  createInvoiceFail,
  createInvoiceSuccess,
  getCreditEmailDetailFail,
  getCreditEmailDetailSuccess,
  getInsuranceDetailFail,
  getInsuranceDetailSuccess,
  getInvoiceCreateDetailFail,
  getInvoiceCreateDetailSuccess,
  getInvoiceCreditInfoFail,
  getInvoiceCreditInfoSuccess,
  getInvoiceCreditViewFail,
  getInvoiceCreditViewSuccess,
  getInvoiceEditDetailFail,
  getInvoiceEditDetailSuccess,
  getInvoiceRest,
  getInvoiceRestSuccess,
  getReceiptDetailFail,
  getReceiptDetailSuccess,
  getReceiptEmailDetailFail,
  getReceiptEmailDetailSuccess,
  sendEmailInvoiceFail,
  sendEmailInvoiceSuccess,
} from "./action";
import * as ACTIONS from "./actionType";
import { createInvoice } from "./api/createInvoice";
import { formatInvoiceDetail, restInvoiceData } from "./formatter";
import { getInsuranceDetails } from "./api/getInsuranceDetail";
import { getInvoiceCreditDetail } from "./api/getInvoiceCreditDetail";
import { getInvoiceCreditedViewDetail } from "./api/getInvoiceCreditedViewDetail";
import { getInvoiceEditDetail } from "./api/getInvoiceEditDetail";
import { getInvoiceReceiptDetail } from "./api/getInvoiceReceiptDetail";
import { getInvoiceReceiptEmail } from "./api/getInvoiceReceiptEmail";
import { getInvoiceCreditEmailDetail } from "./api/getInvoiceCreditEmailDetails";

function* onGetInvoiceCreateDetail(action) {
  // console.log("action called");
  try {
    const {
      getBookedJobRequest,
      getInvoiceListRequest,
      // getWorkShopOtherDetailRequest,
      // getJobNotesRequest,
      // getInsuranceRequest,
      // getJobCannedRequest,
      // getLabourChargeRequest,
    } = action.payload;
    const getBookedJobResponse = yield call(getUnassignedJobs, {
      payload: getBookedJobRequest,
    });
    const getInvoiceListResponse = yield call(getInvoicesList, {
      payload: getInvoiceListRequest,
    });

    const payload = formatInvoiceDetail({
      getBookedJobResponse,
      getInvoiceListResponse,
      // getWorkShopOtherDetailResponse,
      // getJobNotesResponse,
      // getInsuranceResponse,
      // getJobCannedResponse,
      // getLabourChargeResponse,
    });

    // console.log("sdasdasd", payload);

    yield put(getInvoiceCreateDetailSuccess(payload));
  } catch (err) {
    console.log(err)
    yield put(getInvoiceCreateDetailFail());
    toast.error("Some internal error occured");
  }
}

function* onGetInvoiceRestData(action) {
  try {
    const {
      // getBookedJobRequest,
      // getInvoiceListRequest,
      getWorkShopOtherDetailRequest,
      getJobNotesRequest,
      getInsuranceRequest,
      getJobCannedRequest,
      getLabourChargeRequest,
    } = action.payload;

    const getWorkShopOtherDetailResponse = yield call(
      getWorkshopOtherDetailsView,
      { payload: getWorkShopOtherDetailRequest }
    );
    const getJobNotesResponse = yield call(getJobNotes, {
      payload: getJobNotesRequest,
    });
    const getInsuranceResponse = yield call(getInsurance, {
      payload: getInsuranceRequest,
    });
    const getJobCannedResponse = yield call(getJobCannedList, {
      payload: getJobCannedRequest,
    });
    const getLabourChargeResponse = yield call(getLabourChargesList, {
      payload: getLabourChargeRequest,
    });

    const restData = restInvoiceData({
      getJobNotesResponse,
      getInsuranceResponse,
      getJobCannedResponse,
      getWorkShopOtherDetailResponse,
      getLabourChargeResponse,
    });
    yield put(getInvoiceRestSuccess(restData));
  } catch (err) {}

  // yield put(getInvoiceCreateDetailSuccess(payload));
}

function* onCreateInvoiceSaga(action) {
  try {
    const { payload } = action;
    const response = yield call(createInvoice, payload);
    yield put(createInvoiceSuccess(response));
  } catch (err) {
    console.log(err);
    yield put(createInvoiceFail("Error creating invoice"));
    toast.error("Error Creating Invoice");
  }
}

function* onGetInvoiceEditDetail(action) {
  try {
    const { id } = action.payload;
    const data = yield call(getInvoiceEditDetail, id);
    yield put(getInvoiceEditDetailSuccess(data));
  } catch (error) {
    console.log("Error", error);
    yield put(getInvoiceEditDetailFail(error));
  }
}

function* onSendInvoiceEmail(action) {
  try {
    const { request, navigate } = action.payload;
    const response = yield call(sendEmailInvoice, { payload: request });
    if (response?.code === 200) {
      yield put(sendEmailInvoiceSuccess());
      navigate("/invoice");
      toast.success("Email sent successfully");
    } else {
      yield put(sendEmailInvoiceFail());
      toast.error("Failed to send email");
    }
  } catch (error) {
    yield put(sendEmailInvoiceFail());
    toast.error("Failed to send email");
  }
}

function* onGetInvoiceCreditInfo(action) {
  try {
    const { id } = action.payload;
    const response = yield call(getInvoiceCreditDetail, id);
    yield put(getInvoiceCreditInfoSuccess(response));
  } catch (error) {
    yield put(
      getInvoiceCreditInfoFail("Fail to fetch invoice credit information")
    );
  }
}

function* onCreateInvoiceCredit(action) {
  try {
    const { data, navigate } = action.payload;
    const response = yield call(addInvoiceCreate, { payload: data });
    if (response?.code === 200) {
      yield put(createInvoiceCreditSuccess());
      navigate("/invoice");
    } else {
      yield put(createInvoiceCreditFail());
    }
  } catch (error) {
    yield put(createInvoiceCreditFail());
  }
}

function* onGetInvoiceCreditView(action) {
  try {
    const { id } = action.payload;
    const data = yield call(getInvoiceCreditedViewDetail, id);
    yield put(getInvoiceCreditViewSuccess(data));
  } catch (error) {
    yield put(getInvoiceCreditViewFail(error));
  }
}

function* onGetInsuranceDetails(action) {
  try {
    const { id } = action.payload;
    // console.log("Je;;pasd",action.payload);
    const data = yield call(getInsuranceDetails, id);
    yield put(getInsuranceDetailSuccess(data));
  } catch (err) {
    yield put(getInsuranceDetailFail());
  }
}

function* onGetReceiptDetail(action) {
  try {
    const { id } = action.payload;
    const data = yield call(getInvoiceReceiptDetail, id);
    yield put(getReceiptDetailSuccess(data));
  } catch (error) {
    yield put(getReceiptDetailFail("Failed to fetch receipt detail"));
  }
}

function* onGetReceiptEmailDetail(action) {
  try {
    const { id } = action.payload;
    const data = yield call(getInvoiceReceiptEmail, id);
    yield put(getReceiptEmailDetailSuccess(data));
  } catch (error) {
    yield put(getReceiptEmailDetailFail("Failed to fetch receipt detail"));
  }
}

function* onGetCreditEmailDetail(action) {
  try {
    const { id } = action.payload;
    const data = yield call(getInvoiceCreditEmailDetail, id);
    yield put(getCreditEmailDetailSuccess(data));
  } catch (error) {
    yield put(getCreditEmailDetailFail("Failed to fetch receipt detail"));
  }
}

function* invoiceDetailSaga() {
  yield takeLatest(
    ACTIONS.GET_DETAIL_FOR_CREATE_INVOICE,
    onGetInvoiceCreateDetail
  );
  yield takeLatest(ACTIONS.GET_INVOICE_REST_DATA, onGetInvoiceRestData);
  yield takeLatest(ACTIONS.CREATE_INVOICE, onCreateInvoiceSaga);
  yield takeLatest(ACTIONS.GET_DETAIL_FOR_EDIT_INVOICE, onGetInvoiceEditDetail);
  yield takeLatest(ACTIONS.SEND_EMAIL, onSendInvoiceEmail);
  yield takeLatest(ACTIONS.GET_INVOICE_CREDIT_INFO, onGetInvoiceCreditInfo);
  yield takeLatest(ACTIONS.ADD_INVOICE_CREDIT, onCreateInvoiceCredit);
  yield takeLatest(ACTIONS.GET_INVOCE_CREDIT_VIEW, onGetInvoiceCreditView);
  yield takeLatest(ACTIONS.GET_INSURANCE_DETAILS, onGetInsuranceDetails);
  yield takeLatest(ACTIONS.GET_INVOICE_RECEIPT, onGetReceiptDetail);
  yield takeLatest(ACTIONS.GET_INVOICE_RECEIPT_EMAIL, onGetReceiptEmailDetail);
  yield takeLatest(ACTIONS.GET_INVOICE_CREDIT_EMAIL, onGetCreditEmailDetail);
}

export default invoiceDetailSaga;
