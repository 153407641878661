import {
  GET_INPROGRESS_JOBS,
  GET_INPROGRESS_JOBS_SUCCESS,
  GET_INPROGRESS_JOBS_FAIL,
  GET_INPROGRESS_JOBS_COUNT,
  GET_INPROGRESS_JOBS_COUNT_SUCCESS,
  GET_INPROGRESS_JOBS_COUNT_FAIL,
} from "./actionTypes";

export const getinProgressJobs = () => {
  return {
    type: GET_INPROGRESS_JOBS,
  };
};

export const getinProgressJobsSuccess = (data) => {
  return {
    type: GET_INPROGRESS_JOBS_SUCCESS,
    payload: data,
  };
};

export const getinProgressJobsFail = (error) => {
  return {
    type: GET_INPROGRESS_JOBS_FAIL,
    payload: error,
  };
};

export const getinProgressJobsCount = () => {
  return {
    type: GET_INPROGRESS_JOBS_COUNT,
  };
};

export const getinProgressJobsCountSuccess = (data) => {
  return {
    type: GET_INPROGRESS_JOBS_COUNT_SUCCESS,
    payload: data,
  };
};

export const getinProgressJobsCountFail = (error) => {
  return {
    type: GET_INPROGRESS_JOBS_COUNT_FAIL,
    payload: error,
  };
};
