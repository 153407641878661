import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DeleteRecords from "../../../utils/deleteRecords";
import { deleteQuotes } from "../../../helpers/backendHelper";
import { showError, showSuccess } from "../../../utils/alertMessage";

const QuoteFooter = ({
  selectedRow,
  refreshData,
  jobSmsData,
  jobEmailData,
  setSendNotificationTo,
  setNotificationTemplate,
  sendNotification,
  activeTab,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const deleteMsg = "Records deleted successfully";

  const deletePayloadData = {
    payload: {
      action_type: "delete",
      ids: selectedRow,
    },
  };

  const archiveRecord = async () => {
    if (selectedRow.length !== 0) {
      let data = {
        payload: {
          action_type: "archive",
          ids: selectedRow,
        },
      };
      let response = await deleteQuotes(data);
      if (response.code === 200) {
        showSuccess("Job Archive Sucessfully");
        refreshData();
      } else {
        showError(response.message);
      }
    } else {
      showError("Select Atlest One Record");
    }
  };

  return (
    <>
      <div className="d-flex justify-space-between custom-table-footer align-center">
        <div className="d-flex">
          <Button
            variant="warning"
            className="rounded-btn d-flex align-center mr-10"
            onClick={() => archiveRecord()}
          >
            <i className="la la-archive font-size-18"></i> Archive
          </Button>
          <Button
            variant="danger"
            className="rounded-btn d-flex align-center mr-10"
            onClick={() => setShowDeleteModal(!showDeleteModal)}
          >
            <i className="la la-times-circle font-size-18"></i> Delete
          </Button>
        </div>
        <div className="d-flex align-center">
          <div className="custom-normal-select mr-10">
            <label>Send Bulk Notification</label>
            <select
              id="template_bulk_chkUnassigned"
              name="template"
              onChange={(e) => setNotificationTemplate(e.target.value)}
            >
              <option value="">Select Template</option>

              {jobSmsData &&
                jobEmailData &&
                [...jobSmsData, ...jobEmailData].map(({ id, name }, index) => (
                  <option value={id} key={index}>
                    {name}
                  </option>
                ))}
            </select>
          </div>
          <div className="radio-wrapper mr-10">
            <Form.Check
              className="custom-check radio-btn secondary-radio mb-2"
              type="radio"
              id={`${activeTab}1`}
            >
              <Form.Check.Input
                name="size"
                type="radio"
                isValid
                value="sms"
                onChange={(e) => setSendNotificationTo(e.target.value)}
              />
              <Form.Check.Label>
                SMS
                <span className="check"></span>
              </Form.Check.Label>
            </Form.Check>
            <Form.Check
              className="custom-check radio-btn secondary-radio mb-2"
              type="radio"
              id={`${activeTab}2`}
            >
              <Form.Check.Input
                name="size"
                type="radio"
                isValid
                value="email"
                onChange={(e) => setSendNotificationTo(e.target.value)}
              />
              <Form.Check.Label>
                Email
                <span className="check"></span>
              </Form.Check.Label>
            </Form.Check>
          </div>
          <Button
            className="btn-primary rounded-btn"
            onClick={() => sendNotification()}
          >
            Send
          </Button>
          {showDeleteModal && (
            <DeleteRecords
              showDeleteModal={setShowDeleteModal}
              refreshData={refreshData}
              api={deleteQuotes}
              selectedRow={selectedRow}
              message={deleteMsg}
              payloadData={deletePayloadData}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default QuoteFooter;
