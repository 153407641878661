import {
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_FAIL,
  GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST,
  GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST_SUCCESS,
  GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST_FAIL,
  GET_SUBSCRIPTION_PLANS,
  GET_SUBSCRIPTION_PLANS_SUCCESS,
  GET_SUBSCRIPTION_PLANS_FAIL
} from "./actionTypes";


const initialState = {
  loadingData: false,
  subscriptionPlans: [],
  subscribeData:null,
  isFetching: false,
  workshopSmsSubscribeData: null,
  error: {
    message: "",
  }
};

const SubscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION:
      state = {
        ...state,
        loadingData: true,
        subscribeData:null,
        isFetching: false,
      };
    break;
    case GET_SUBSCRIPTION_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        subscribeData:action.payload,
        isFetching: true,
      };
    break;
    case GET_SUBSCRIPTION_FAIL:
      state = {
        ...state,
        loadingData: false,
        subscribeData:null,
        error: {
          message: action.payload,
        },
        isFetching: true,
      };
    break;
    case GET_SUBSCRIPTION_PLANS:
      state = {
        ...state,
        loadingData: true,
      };
    break;
    case GET_SUBSCRIPTION_PLANS_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        subscriptionPlans:action.payload,
      };
    break;
    case GET_SUBSCRIPTION_PLANS_FAIL:
      state = {
        ...state,
        loadingData: false,
        subscriptionPlans: [],
        error: {
          message: action.payload,
        },
      };
    break;
    case GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST:
        state = {
            ...state,
            loadingData: true,
            isFetching: false
        };
        break;
      case GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST_SUCCESS:
          state = {
              ...state,
              loadingData: false,
              workshopSmsSubscribeData: action.payload
          };
          break;
      case GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST_FAIL:
          state = {
              ...state,
              loadingData: false,
              error: {
                  message: action.payload,
              }
          };
          break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default SubscriptionReducer;
