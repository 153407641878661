import {
  GET_JOB_BOOKED_DETAILS,
  GET_JOB_BOOKED_DETAILS_SUCCESS,
  GET_JOB_BOOKED_DETAILS_FAIL,
  GET_JOB_BOOKED_KEY_TAGS,
  GET_JOB_BOOKED_KEY_TAGS_SUCCESS,
  GET_JOB_BOOKED_KEY_TAGS_FAIL,
  GET_REGO_LOOKUP_DATA,
  GET_REGO_LOOKUP_DATA_SUCCESS,
  GET_REGO_LOOKUP_DATA_FAIL,
  UPDATE_JOB_TIMER,
  UPDATE_JOB_TIMER_SUCCESS,
  UPDATE_JOB_TIMER_FAIL,
  UPDATE_VEHICLE,
  UPDATE_VEHICLE_SUCCESS,
  UPDATE_VEHICLE_FAIL,
  GET_TOTAL_TIMER,
  GET_TOTAL_TIMER_SUCCESS,
  GET_TOTAL_TIMER_FAIL,
  GET_VEHICLE_HISTORY,
  GET_VEHICLE_HISTORY_SUCCESS,
  GET_VEHICLE_HISTORY_FAIL,
  GET_JOB_NOTES,
  GET_JOB_NOTES_SUCCESS,
  GET_JOB_NOTES_FAIL,
  ADD_JOB_NOTES,
  ADD_JOB_NOTES_SUCCESS,
  ADD_JOB_NOTES_FAIL,
  UPDATE_JOB_NOTES,
  UPDATE_JOB_NOTES_SUCCESS,
  UPDATE_JOB_NOTES_FAIL,
  GET_MESSAGE,
  GET_MESSAGE_SUCCESS,
  GET_MESSAGE_FAIL,
  GET_INSPECTION_VIEW,
  GET_INSPECTION_VIEW_SUCCESS,
  GET_INSPECTION_VIEW_FAIL,
  GET_INSPECTIONS,
  GET_INSPECTIONS_SUCCESS,
  GET_INSPECTIONS_FAIL,
  GET_INSPECTION_TEMPLATES,
  GET_INSPECTION_TEMPLATES_SUCCESS,
  GET_INSPECTION_TEMPLATES_FAIL,
  GET_TECHNICIAN_LIST,
  GET_TECHNICIAN_LIST_SUCCESS,
  GET_TECHNICIAN_LIST_FAIL,
  GET_INTERNAL_NOTES,
  GET_INTERNAL_NOTES_SUCCESS,
  GET_INTERNAL_NOTES_FAIL,
  GET_INTERNAL_NOTES_SCRIPTS,
  GET_INTERNAL_NOTES_SCRIPTS_SUCCESS,
  GET_INTERNAL_NOTES_SCRIPTS_FAIL,
  GET_INSPECTION_QUESTIONS_ANSWERS,
  UPDATE_INSPECTION_QUESTIONS_ANSWERS,
  APPROVE_MANUAL_INSPECTION,
  APPROVE_MANUAL_INSPECTION_SUCCESS,
  APPROVE_MANUAL_INSPECTION_FAIL,
  RESEND_INSPECTION,
  RESEND_INSPECTION_SUCCESS,
  RESEND_INSPECTION_FAIL,
  ADD_PARTS_MANUAL_INSPECTION_APPROVED,
  ADD_PARTS_MANUAL_INSPECTION_APPROVED_SUCCESS,
  ADD_PARTS_MANUAL_INSPECTION_APPROVED_FAIL,
  GET_INSPECTION_GROUP,
  GET_INSPECTION_GROUP_FAIL,
  GET_INSPECTION_GROUP_SUCCESS,
  SAVE_INSPECTION,
  SAVE_INSPECTION_SUCCESS,
  SAVE_INSPECTION_FAIL,
  CLEAR_SAVE_INSPECTION_STATUS,
  GET_ATTACHMENT,
  GET_ATTACHMENT_SUCCESS,
  GET_ATTACHMENT_FAIL,
  DEFAULT_PRICE_SUMMARY,
  DEFAULT_PRICE_SUMMARY_SUCCESS,
  DEFAULT_PRICE_SUMMARY_FAIL,
} from "./actionTypes";

const initialState = {
  saveInpection:"",
  token: "",
  jobBookedData: null,
  jobBookedKeyTagData: null,
  jobTimerData: null,
  vehicleData: null,
  vehicleHistoryData: null,
  totalTimerData: null,
  regoLookUpData: null,
  loadingData: false,
  jobNotesData: null,
  message: [],
  inspectionView: [],
  internalNotes: [],
  internalNotesScripts: [],
  error: {
    message: "",
  },
  attachment: [],
  inspTemplates: [], // inspection templates list
  inspInspections: [], // inspection list
  techniciansList: [], // technician list
  inspectionQuestionsAnswers: [], // default or not stored inspection questions
  approveManualInspectionResponse: [], // approved inspection response
  inspectionGroup: [], //get inspection groups
};

const JobDetails = (state = initialState, action) => {
  switch (action.type) {
    case GET_JOB_BOOKED_DETAILS:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case GET_JOB_BOOKED_DETAILS_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        jobBookedData: action.payload,
      };
      break;
    case GET_JOB_BOOKED_DETAILS_FAIL:
      state = {
        ...state,
        jobBookedData: null,
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;
    case GET_JOB_BOOKED_KEY_TAGS:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case GET_JOB_BOOKED_KEY_TAGS_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        jobBookedKeyTagData: action.payload,
      };
      break;
    case GET_JOB_BOOKED_KEY_TAGS_FAIL:
      state = {
        ...state,
        jobBookedKeyTagData: null,
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;
    case GET_REGO_LOOKUP_DATA:
      state = {
        ...state,
        loadingData: true,
        isFetchRegoData: false,
      };
      break;
    case GET_REGO_LOOKUP_DATA_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        regoLookUpData: action.payload,
        isFetchRegoData: true,
      };
      break;
    case GET_REGO_LOOKUP_DATA_FAIL:
      state = {
        ...state,
        regoLookUpData: action.payload,
        isFetchRegoData: false,
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;
    case UPDATE_JOB_TIMER:
      state = {
        ...state,
        loadingData: true,
        is_job_updated: false
      };
      break;
    case UPDATE_JOB_TIMER_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        jobTimerData: action.payload,
        is_job_updated: true
      };
      break;
    case UPDATE_JOB_TIMER_FAIL:
      state = {
        ...state,
        jobTimerData: null,
        error: {
          message: action.payload,
        },
        is_job_updated: false,
        loadingData: false,
      };
      break;
    case UPDATE_VEHICLE:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case UPDATE_VEHICLE_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        vehicleData: action.payload,
      };
      break;
    case UPDATE_VEHICLE_FAIL:
      state = {
        ...state,
        vehicleData: action.payload,
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;
    case GET_TOTAL_TIMER:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case GET_TOTAL_TIMER_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        totalTimerData: action.payload,
      };
      break;
    case GET_TOTAL_TIMER_FAIL:
      state = {
        ...state,
        totalTimerData: null,
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;
    case GET_VEHICLE_HISTORY:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case GET_VEHICLE_HISTORY_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        vehicleHistoryData: action.payload,
      };
      break;
    case GET_VEHICLE_HISTORY_FAIL:
      state = {
        ...state,
        vehicleHistoryData: null,
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;

    case GET_JOB_NOTES:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case GET_JOB_NOTES_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        jobNotesData: action.payload,
      };
      break;
    case GET_JOB_NOTES_FAIL:
      state = {
        ...state,
        jobNotesData: null,
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;

    case ADD_JOB_NOTES:
      state = {
        ...state,
      };
      break;
    case ADD_JOB_NOTES_SUCCESS:
      state = {
        ...state,
        jobNotesData: action.payload,
      };
      break;
    case ADD_JOB_NOTES_FAIL:
      state = {
        ...state,
        jobNotesData: null,
        error: {
          message: action.payload,
        },
      };
      break;

    case UPDATE_JOB_NOTES:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case UPDATE_JOB_NOTES_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        jobNotesData: action.payload,
      };
      break;
    case UPDATE_JOB_NOTES_FAIL:
      state = {
        ...state,
        jobNotesData: null,
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;

    case GET_MESSAGE:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case GET_MESSAGE_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        message: action.payload,
      };
      break;
    case GET_MESSAGE_FAIL:
      state = {
        ...state,
        message: [],
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;

    case GET_INSPECTION_VIEW:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case GET_INSPECTION_VIEW_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        inspectionView: action.payload,
      };
      break;
    case GET_INSPECTION_VIEW_FAIL:
      state = {
        ...state,
        inspectionView: [],
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;
    case GET_INSPECTIONS:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;
    case GET_INSPECTIONS_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        inspInspections: action.payload,
      };
      break;
    case GET_INSPECTIONS_FAIL:
      state = {
        ...state,
        inspInspections: [],
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;
    case GET_INSPECTION_TEMPLATES:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;
    case GET_INSPECTION_TEMPLATES_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        inspTemplates: action.payload,
      };
      break;
    case GET_INSPECTION_TEMPLATES_FAIL:
      state = {
        ...state,
        inspTemplates: [],
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;
    case GET_TECHNICIAN_LIST:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;
    case GET_TECHNICIAN_LIST_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        techniciansList: action.payload,
      };
      break;
    case GET_TECHNICIAN_LIST_FAIL:
      state = {
        ...state,
        // techniciansList: [],
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;

    case GET_INTERNAL_NOTES:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case GET_INTERNAL_NOTES_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        internalNotes: action.payload,
      };
      break;
    case GET_INTERNAL_NOTES_FAIL:
      state = {
        ...state,
        internalNotes: [],
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;

    case GET_INTERNAL_NOTES_SCRIPTS:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case GET_INTERNAL_NOTES_SCRIPTS_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        internalNotesScripts: action.payload,
      };
      break;
    case GET_INTERNAL_NOTES_SCRIPTS_FAIL:
      state = {
        ...state,
        internalNotesScripts: [],
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;

    case GET_ATTACHMENT:
      state = {
        ...state,
        loadingData: true,
      };
      break;

    case GET_ATTACHMENT_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        attachment: action.payload,
      };
      break;
    case GET_ATTACHMENT_FAIL:
      state = {
        ...state,
        attachment: [],
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;

      case DEFAULT_PRICE_SUMMARY:
      state = {
        ...state,
        loadingData: true,
      };
      break;

    case DEFAULT_PRICE_SUMMARY_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        psummaryData: action.payload,
      };
      break;
    case DEFAULT_PRICE_SUMMARY_FAIL:
      state = {
        ...state,
        psummaryData: [],
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;

    case GET_INSPECTION_QUESTIONS_ANSWERS:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case UPDATE_INSPECTION_QUESTIONS_ANSWERS:
      state = {
        ...state,
        loadingData: false,
        inspectionQuestionsAnswers: action.payload,
      };
      break;
    case APPROVE_MANUAL_INSPECTION:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case APPROVE_MANUAL_INSPECTION_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        approveManualInspectionResponse: [],
        loadingData: false,
      };
      break;
    case APPROVE_MANUAL_INSPECTION_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        approveManualInspectionResponse: action.payload,
      };
      break;
    case RESEND_INSPECTION:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case RESEND_INSPECTION_SUCCESS:
      state = {
        ...state,
        loadingData: false,
      };
      break;
    case RESEND_INSPECTION_FAIL:
      state = {
        ...state,
        loadingData: false,
        error: {
          message: action.payload,
        },
      };
      break;
    case ADD_PARTS_MANUAL_INSPECTION_APPROVED:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case ADD_PARTS_MANUAL_INSPECTION_APPROVED_SUCCESS:
      state = {
        ...state,
        loadingData: false,
      };
      break;
    case ADD_PARTS_MANUAL_INSPECTION_APPROVED_FAIL:
      state = {
        ...state,
        loadingData: false,
        error: {
          message: action.payload,
        },
      };
      break;
    case GET_INSPECTION_GROUP:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case GET_INSPECTION_GROUP_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        inspectionGroup: action.payload,
      };
      break;
    case GET_INSPECTION_GROUP_FAIL:
      state = {
        ...state,
        loadingData: false,
        error: {
          message: action.payload,
        },
        inspectionGroup: [],
      };
      break;
    case SAVE_INSPECTION:
      state = {
        ...state,
        saveInpection: "processing",
      };
      break;
    case SAVE_INSPECTION_SUCCESS:
      state = {
        ...state,
        saveInpection: "success",
      };
      break;
    case SAVE_INSPECTION_FAIL:
      state = {
        ...state,
        saveInpection: "failed",
      };
      break;
    case CLEAR_SAVE_INSPECTION_STATUS:
      state = {
        ...state,
        saveInpection: "",
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default JobDetails;
