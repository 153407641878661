import {
  GET_WON_QUOTES,
  GET_WON_QUOTES_SUCCESS,
  GET_WON_QUOTES_FAIL,
  GET_WON_QUOTES_COUNT,
  GET_WON_QUOTES_COUNT_SUCCESS,
  GET_WON_QUOTES_COUNT_FAIL,
} from "./actionTypes";

const initialState = {
  loading: false,
  message: "",
  error: "",
  wonQuotesData: null,
  wonQuotesCount: 0,
};

const WonQuotesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WON_QUOTES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_WON_QUOTES_SUCCESS:
      state = {
        ...state,
        loading: false,
        wonQuotesData: action.payload,
      };
      break;
    case GET_WON_QUOTES_FAIL:
      state = {
        ...state,
        loading: false,
        wonQuotesData: null,
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_WON_QUOTES_COUNT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_WON_QUOTES_COUNT_SUCCESS:
      state = {
        ...state,
        loading: false,
        wonQuotesCount: action.payload,
      };
      break;
    case GET_WON_QUOTES_COUNT_FAIL:
      state = {
        ...state,
        loading: false,
        wonQuotesCount: 0,
        error: {
          message: action.payload,
        },
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default WonQuotesReducer;
