import {
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_FAIL,
  GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST,
  GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST_SUCCESS,
  GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST_FAIL,
  GET_SUBSCRIPTION_PLANS,
  GET_SUBSCRIPTION_PLANS_SUCCESS,
  GET_SUBSCRIPTION_PLANS_FAIL
} from "./actionTypes";

export const getSubscription = () => {
  return {
    type: GET_SUBSCRIPTION,
  };
};

export const getSubscriptionSuccess = (data) => {
  return {
    type: GET_SUBSCRIPTION_SUCCESS,
    payload: data,
  };
};

export const getSubscriptionFail = (error) => {
  return {
    type: GET_SUBSCRIPTION_FAIL,
    payload: error,
  };
};

export const getWorkShopSmsSubscriptionDetailsList = () => {
    return {
        type: GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST,
    };
};

export const getWorkShopSmsSubscriptionDetailsListSuccess = (data) => {
    return {
        type: GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST_SUCCESS,
        payload: data,
    };
};

export const getWorkShopSmsSubscriptionDetailsListFail = (error) => {
    return {
        type: GET_WORKSHOP_SMS_SUBSCRIPTION_DETAILS_LIST_FAIL,
        payload: error,
    };
};

export const getSubscriptionPlans = (data) => {
  return {
    type: GET_SUBSCRIPTION_PLANS,
    payload: data
  };
};

export const getSubscriptionPlansSuccess = (data) => {
  return {
    type: GET_SUBSCRIPTION_PLANS_SUCCESS,
    payload: data,
  };
};

export const getSubscriptionPlansFail = (error) => {
  return {
    type: GET_SUBSCRIPTION_PLANS_FAIL,
    payload: error,
  };
};
