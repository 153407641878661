import {
  ADD_JOB_NOTES,
  ADD_JOB_NOTES_FAIL,
  ADD_JOB_NOTES_SUCCESS,
  ADD_PARTS_MANUAL_INSPECTION_APPROVED,
  ADD_PARTS_MANUAL_INSPECTION_APPROVED_FAIL,
  ADD_PARTS_MANUAL_INSPECTION_APPROVED_SUCCESS,
  APPROVE_MANUAL_INSPECTION,
  APPROVE_MANUAL_INSPECTION_FAIL,
  APPROVE_MANUAL_INSPECTION_SUCCESS,
  GET_INSPECTION_GROUP,
  GET_INSPECTION_GROUP_FAIL,
  GET_INSPECTION_GROUP_SUCCESS,
  GET_INSPECTION_QUESTIONS_ANSWERS,
  GET_INSPECTION_TEMPLATES,
  GET_INSPECTION_TEMPLATES_FAIL,
  GET_INSPECTION_TEMPLATES_SUCCESS,
  GET_INSPECTION_VIEW,
  GET_INSPECTION_VIEW_FAIL,
  GET_INSPECTION_VIEW_SUCCESS,
  GET_INTERNAL_NOTES,
  GET_INTERNAL_NOTES_FAIL,
  GET_INTERNAL_NOTES_SCRIPTS,
  GET_INTERNAL_NOTES_SCRIPTS_FAIL,
  GET_INTERNAL_NOTES_SCRIPTS_SUCCESS,
  GET_INTERNAL_NOTES_SUCCESS,
  GET_JOB_BOOKED_DETAILS,
  GET_JOB_BOOKED_DETAILS_FAIL,
  GET_JOB_BOOKED_DETAILS_SUCCESS,
  GET_JOB_BOOKED_KEY_TAGS,
  GET_JOB_BOOKED_KEY_TAGS_FAIL,
  GET_JOB_BOOKED_KEY_TAGS_SUCCESS,
  GET_JOB_NOTES,
  GET_JOB_NOTES_FAIL,
  GET_JOB_NOTES_SUCCESS,
  GET_MESSAGE,
  GET_MESSAGE_FAIL,
  GET_MESSAGE_SUCCESS,
  GET_REGO_LOOKUP_DATA,
  GET_REGO_LOOKUP_DATA_FAIL,
  GET_REGO_LOOKUP_DATA_SUCCESS,
  GET_TECHNICIAN_LIST,
  GET_TECHNICIAN_LIST_FAIL,
  GET_TECHNICIAN_LIST_SUCCESS,
  GET_TOTAL_TIMER,
  GET_TOTAL_TIMER_FAIL,
  GET_TOTAL_TIMER_SUCCESS,
  GET_VEHICLE_HISTORY,
  GET_VEHICLE_HISTORY_FAIL,
  GET_VEHICLE_HISTORY_SUCCESS,
  RESEND_INSPECTION,
  RESEND_INSPECTION_FAIL,
  RESEND_INSPECTION_SUCCESS,
  SAVE_INSPECTION,
  SAVE_INSPECTION_FAIL,
  SAVE_INSPECTION_SUCCESS,
  UPDATE_INSPECTION_QUESTIONS_ANSWERS,
  UPDATE_JOB_NOTES,
  UPDATE_JOB_NOTES_FAIL,
  UPDATE_JOB_NOTES_SUCCESS,
  UPDATE_JOB_TIMER,
  UPDATE_JOB_TIMER_FAIL,
  UPDATE_JOB_TIMER_SUCCESS,
  UPDATE_VEHICLE,
  UPDATE_VEHICLE_FAIL,
  UPDATE_VEHICLE_SUCCESS,
  GET_INSPECTIONS,
  GET_INSPECTIONS_SUCCESS,
  GET_INSPECTIONS_FAIL,
  GET_ATTACHMENT,
  GET_ATTACHMENT_SUCCESS,
  GET_ATTACHMENT_FAIL,
  DEFAULT_PRICE_SUMMARY,
  DEFAULT_PRICE_SUMMARY_SUCCESS,
  DEFAULT_PRICE_SUMMARY_FAIL,
} from "./actionTypes";

export const getJobBookedDetails = () => {
  return {
    type: GET_JOB_BOOKED_DETAILS,
  };
};

export const getJobBookedDetailsSuccess = (data) => {
  return {
    type: GET_JOB_BOOKED_DETAILS_SUCCESS,
    payload: data,
  };
};

export const getJobBookedDetailsFail = (error) => {
  return {
    type: GET_JOB_BOOKED_DETAILS_FAIL,
    payload: error,
  };
};

export const getJobBookedKeyTagsDetails = () => {
  return {
    type: GET_JOB_BOOKED_KEY_TAGS,
  };
};

export const getJobBookedKeyTagsDetailsSuccess = (data) => {
  return {
    type: GET_JOB_BOOKED_KEY_TAGS_SUCCESS,
    payload: data,
  };
};

export const getJobBookedKeyTagsDetailsFail = (error) => {
  return {
    type: GET_JOB_BOOKED_KEY_TAGS_FAIL,
    payload: error,
  };
};

export const getRegoLookUpData = () => {
  return {
    type: GET_REGO_LOOKUP_DATA,
  };
};

export const getRegoLookUpDataSuccess = (data) => {
  return {
    type: GET_REGO_LOOKUP_DATA_SUCCESS,
    payload: data,
  };
};

export const getRegoLookUpDataFail = (error) => {
  return {
    type: GET_REGO_LOOKUP_DATA_FAIL,
    payload: error,
  };
};

export const updateJobTimer = () => {
  return {
    type: UPDATE_JOB_TIMER,
  };
};

export const updateJobTimerSuccess = (data) => {
  return {
    type: UPDATE_JOB_TIMER_SUCCESS,
    payload: data,
  };
};

export const updateJobTimerFail = (error) => {
  return {
    type: UPDATE_JOB_TIMER_FAIL,
    payload: error,
  };
};

export const updateVehicle = () => {
  return {
    type: UPDATE_VEHICLE,
  };
};

export const updateVehicleSuccess = (data) => {
  return {
    type: UPDATE_VEHICLE_SUCCESS,
    payload: data,
  };
};

export const updateVehicleFail = (error) => {
  return {
    type: UPDATE_VEHICLE_FAIL,
    payload: error,
  };
};

export const getTotalTimer = () => {
  return {
    type: GET_TOTAL_TIMER,
  };
};

export const getTotalTimerSuccess = (data) => {
  return {
    type: GET_TOTAL_TIMER_SUCCESS,
    payload: data,
  };
};

export const getTotalTimerFail = (error) => {
  return {
    type: GET_TOTAL_TIMER_FAIL,
    payload: error,
  };
};

export const getVehicleHistory = () => {
  return {
    type: GET_VEHICLE_HISTORY,
  };
};

export const getVehicleHistorySuccess = (data) => {
  return {
    type: GET_VEHICLE_HISTORY_SUCCESS,
    payload: data,
  };
};

export const getVehicleHistoryFail = (error) => {
  return {
    type: GET_VEHICLE_HISTORY_FAIL,
    payload: error,
  };
};

export const getMessage = () => {
  return {
    type: GET_MESSAGE,
  };
};

export const getMessageSuccess = (data) => {
  return {
    type: GET_MESSAGE_SUCCESS,
    payload: data,
  };
};

export const getMessageFail = (error) => {
  return {
    type: GET_MESSAGE_FAIL,
    payload: error,
  };
};

export const getInspectionView = () => {
  return {
    type: GET_INSPECTION_VIEW,
  };
};

export const getInspectionViewSuccess = (data) => {
  return {
    type: GET_INSPECTION_VIEW_SUCCESS,
    payload: data,
  };
};

export const getInspectionViewFail = (error) => {
  return {
    type: GET_INSPECTION_VIEW_FAIL,
    payload: error,
  };
};

export const getJobNotes = () => {
  return {
    type: GET_JOB_NOTES,
  };
};

export const getJobNotesSuccess = (data) => {
  return {
    type: GET_JOB_NOTES_SUCCESS,
    payload: data,
  };
};

export const getJobNotesFail = (error) => {
  return {
    type: GET_JOB_NOTES_FAIL,
    payload: error,
  };
};

export const getInternalNotes = () => {
  return {
    type: GET_INTERNAL_NOTES,
  };
};

export const getInternalNotesSuccess = (data) => {
  return {
    type: GET_INTERNAL_NOTES_SUCCESS,
    payload: data,
  };
};

export const getInternalNotesFail = (error) => {
  return {
    type: GET_INTERNAL_NOTES_FAIL,
  };
};
export const addJobNotes = () => {
  return {
    type: ADD_JOB_NOTES,
  };
};

export const addJobNotesSuccess = (data) => {
  return {
    type: ADD_JOB_NOTES_SUCCESS,
    payload: data,
  };
};
/*░░░░░░░░░░░░ INSPECTION ░░░░░░░░░░░░*/

// Inspections
export const getInspections = () => {
  return {
    type: GET_INSPECTIONS,
  };
};

export const getInspectionsSuccess = (data) => {
  return {
    type: GET_INSPECTIONS_SUCCESS,
    payload: data,
  };
};

export const addJobNotesFail = (error) => {
  return {
    type: ADD_JOB_NOTES_FAIL,
    payload: error,
  };
};

export const getInspectionsFail = (error) => {
  return {
    type: GET_INSPECTIONS_FAIL,
    payload: error,
  };
};

export const getInternalNotesScripts = () => {
  return {
    type: GET_INTERNAL_NOTES_SCRIPTS,
  };
};

export const getInternalNotesScriptsSuccess = (data) => {
  return {
    type: GET_INTERNAL_NOTES_SCRIPTS_SUCCESS,
    payload: data,
  };
};

export const getInternalNotesScriptsFail = (error) => {
  return {
    type: GET_INTERNAL_NOTES_SCRIPTS_FAIL,
    payload: error,
  };
};

export const updateJobNotes = () => {
  return {
    type: UPDATE_JOB_NOTES,
  };
};

export const updateJobNotesSuccess = (data) => {
  return {
    type: UPDATE_JOB_NOTES_SUCCESS,
  };
};

// Inspection templates
export const getInspectionTemplates = () => {
  return {
    type: GET_INSPECTION_TEMPLATES,
  };
};

export const getInspectionTemplatesSuccess = (data) => {
  return {
    type: GET_INSPECTION_TEMPLATES_SUCCESS,
    payload: data,
  };
};

export const updateJobNotesFail = (error) => {
  return {
    type: UPDATE_JOB_NOTES_FAIL,
    payload: error,
  };
};

export const getInspectionTemplatesFail = (error) => {
  return {
    type: GET_INSPECTION_TEMPLATES_FAIL,
    payload: error,
  };
};

// Technicions
export const getTechnicianList = () => {
  return {
    type: GET_TECHNICIAN_LIST,
  };
};

export const getTechnicianListSuccess = (data) => {
  return {
    type: GET_TECHNICIAN_LIST_SUCCESS,
    payload: data.data.Users,
    message: data.message,
  };
};

export const getTechnicianListFail = (error) => {
  return {
    type: GET_TECHNICIAN_LIST_FAIL,
    payload: error,
  };
};

export const getAttechment = () => {
  return {
    type: GET_ATTACHMENT,
  };
};

export const getAttechmentSuccess = (data) => {
  return {
    type: GET_ATTACHMENT_SUCCESS,
    payload: data,
  };
};

export const getAttechmentFail = (error) => {
  return {
    type: GET_ATTACHMENT_FAIL,
    payload: error,
  };
};

export const defaultPriceSummary = () => {
  return {
    type: DEFAULT_PRICE_SUMMARY,
  };
};

export const defaultPriceSummarySuccess = (data) => {
  return {
    type: DEFAULT_PRICE_SUMMARY_SUCCESS,
    payload: data,
  };
};

export const defaultPriceSummaryFail = (error) => {
  return {
    type: DEFAULT_PRICE_SUMMARY_FAIL,
    payload: error,
  };
};
// Inspection questions answers
export const getInspectionQuestionsAnswers = () => {
  return {
    type: GET_INSPECTION_QUESTIONS_ANSWERS,
  };
};

export const updateInspectionQuestionsAnswers = (data) => {
  return {
    type: UPDATE_INSPECTION_QUESTIONS_ANSWERS,
    payload: data,
  };
};

export const approveManualInspection = (data) => {
  return {
    type: APPROVE_MANUAL_INSPECTION,
    payload: data,
  };
};
export const approveManualInspectionFail = (error) => {
  return {
    type: APPROVE_MANUAL_INSPECTION_FAIL,
    payload: error,
  };
};

export const approveManualInspectionSuccess = (data) => {
  return {
    type: APPROVE_MANUAL_INSPECTION_SUCCESS,
    payload: data,
  };
};

export const resendInspection = (data) => {
  return {
    type: RESEND_INSPECTION,
    payload: data,
  };
};

export const resendInspectionSuccess = (data) => {
  return {
    type: RESEND_INSPECTION_SUCCESS,
    payload: data,
  };
};

export const resendInspectionFail = (error) => {
  return {
    type: RESEND_INSPECTION_FAIL,
    payload: error,
  };
};

export const addApprovedPartsInspection = (data) => {
  return {
    type: ADD_PARTS_MANUAL_INSPECTION_APPROVED,
    payload: data,
  };
};

export const addApprovedPartsInspectionSuccess = (data) => {
  return {
    type: ADD_PARTS_MANUAL_INSPECTION_APPROVED_SUCCESS,
    payload: data,
  };
};

export const addApprovedPartsInspectionFail = (error) => {
  return {
    type: ADD_PARTS_MANUAL_INSPECTION_APPROVED_FAIL,
    payload: error,
  };
};

export const getInspectionGroup = (data) => {
  return {
    type: GET_INSPECTION_GROUP,
    payload: data,
  };
};

export const getInspectionGroupSuccess = (data) => {
  return {
    type: GET_INSPECTION_GROUP_SUCCESS,
    payload: data,
  };
};

export const getInspectionGroupFail = (error) => {
  return {
    type: GET_INSPECTION_GROUP_FAIL,
    payload: error,
  };
};

export const saveInspection = (payload) => {
  return {
    type: SAVE_INSPECTION,
    payload,
  };
};

export const saveInspectionSuccess = (payload) => {
  return {
    type: SAVE_INSPECTION_SUCCESS,
    payload,
  };
};

export const saveInspectionFail = (payload) => {
  return {
    type: SAVE_INSPECTION_FAIL,
    payload,
  };
};
