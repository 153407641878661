import * as ACTIONS from "./actionType";
const initialState = {};
const invoicePaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.START_PAYMENT_RECEIPT:
      return {
        ...state,
        creating: true,
        createdReceipt: null,
      };
    case ACTIONS.START_PAYMENT_RECEIPT_SUCCESS: {
      return {
        ...state,
        creating: false,
        createdReceipt: action.payload,
      };
    }

    case ACTIONS.START_PAYMENT_RECEIPT_FAIL:
      return {
        ...state,
        creating: false,
        createdReceipt: null,
      };
    case ACTIONS.CLEAR_PAYMENT_RECEIPT_SUCCESS:
      return {
        ...state,
        createdReceipt: null,
      };
    case ACTIONS.GET_RECEIPT_DETAILS:
      return {
        ...state,
        recieptDetail: {
          loading: true,
          data: null,
        },
      };
    case ACTIONS.GET_RECEIPT_DETAILS_SUCCESS:
      return {
        ...state,
        recieptDetail: {
          loading: false,
          data: action.payload,
        },
      };
    case ACTIONS.GET_RECEIPT_DETAILS_FAIL:
      return {
        ...state,
        recieptDetail: {
          loading: false,
          data: null,
        },
      };
    case ACTIONS.CLEAR_RECEIPT_DETAILS:
      return {
        ...state,
        recieptDetail: {
          loading: false,
          data: null,
        },
      };

    default:
      return state;
  }
};

export default invoicePaymentReducer;
