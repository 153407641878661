import React from "react";
import { useFormik } from "formik";
import Form from "react-bootstrap/Form";
import moment from "moment-timezone";
import Select from "react-select";
import TimePicker from "react-time-picker-input";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_COMPANY_OTHER_DETAIL } from "../../../../../store/companyDetails/actionTypes";
import LoaderImage from "../../../../../../src/assets/images/page-loader.svg";
import obj from "../../../../../utils/unserialize";
import {
  timeZoneToUTC,
  utcToTimezone,
} from "../../../../../utils/timeZoneConvert";

const DefaultForm = () => {
  const dispatch = useDispatch(); // Dispatch variable
  const { formLoading, workShopOtherDetails } = useSelector(
    (state) => state.CompanyDetailReducer
  ); // GET Form loader from Company Detail Reducer data

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      otherId: workShopOtherDetails ? workShopOtherDetails.id : "",
      jsDateFormat:
        workShopOtherDetails && workShopOtherDetails.js_date_format
          ? workShopOtherDetails.js_date_format
          : "DD/MM/YYYY",
      mobileTimeFormat:
        workShopOtherDetails && workShopOtherDetails.mobile_time_format
          ? workShopOtherDetails.mobile_time_format
          : "",
      dateFormat: workShopOtherDetails ? workShopOtherDetails.date_format : "",
      timeFormat: workShopOtherDetails
        ? workShopOtherDetails.time_format === "h:i a"
          ? "hh:mm:a"
          : workShopOtherDetails.time_format === "h:i"
          ? "hh:mm"
          : "hh:mm:a"
        : "hh:mm:a",
      quoteNumber: workShopOtherDetails
        ? workShopOtherDetails.quote_number
        : "",
      poNumber: workShopOtherDetails ? workShopOtherDetails.po_number : "",
      jobNumber: workShopOtherDetails ? workShopOtherDetails.job_number : "",
      invoiceNumber: workShopOtherDetails
        ? workShopOtherDetails.invoice_number
        : "",
      technicianTimeSlot: workShopOtherDetails
        ? workShopOtherDetails.technician_timeslot
        : "",
      customerTimeSlot: workShopOtherDetails
        ? workShopOtherDetails.customer_timeslot
        : "",
      scheduleData:
        workShopOtherDetails && workShopOtherDetails.schedule_details
          ? obj.unserializeArr(workShopOtherDetails.schedule_details)
          : [
              {
                day: 1,
                status: "1",
                open_time: "07:00",
                close_time: "17:00",
              },
              {
                day: 2,
                status: "1",
                open_time: "07:00",
                close_time: "17:00",
              },
              {
                day: 3,
                status: "1",
                open_time: "07:00",
                close_time: "17:00",
              },
              {
                day: 4,
                status: "1",
                open_time: "07:00",
                close_time: "17:00",
              },
              {
                day: 5,
                status: "1",
                open_time: "07:00",
                close_time: "17:00",
              },
              {
                day: 6,
                status: 0,
                open_time: "07:00",
                close_time: "17:00",
              },
              {
                day: 0,
                status: 0,
                open_time: "07:00",
                close_time: "17:00",
              },
            ],
    },
    onSubmit: (values) => {
      let editFormData = {
        id: values.otherId,
        js_date_format: values.jsDateFormat,
        mobile_time_format: values.mobileTimeFormat,
        date_format: values.dateFormat,
        time_format: values.timeFormat,
        quote_number: values.quoteNumber,
        po_number: values.poNumber,
        job_number: values.jobNumber,
        invoice_number: values.invoiceNumber,
        technician_timeslot: values.technicianTimeSlot,
        customer_timeslot: values.customerTimeSlot,
        schedule_details: values.scheduleData,
      };
      // update format of schedule data for serialize
      let data = values.scheduleData;
      values.scheduleData.map((detail, index) => {
        return (data[index] = {
          ...detail,
          status: detail.status,
          open_time: detail.status === 0 ? null : detail.open_time,
          close_time: detail.status === 0 ? null : detail.close_time,
        });
      });
      // serialize schedule details
      editFormData = {
        ...editFormData,
        schedule_details: obj.serializeArr(data),
        is_sunday: values.scheduleData[5].status,
        is_saturday: values.scheduleData[6].status,
      };

      dispatch({
        type: UPDATE_COMPANY_OTHER_DETAIL,
        payload: editFormData,
      });
    },
  });

  const format = "HH:mm";
  const timeFormat = "HH:mm";
  const dateOptions = [
    {
      value: "DD/MM/YYYY",
      label: `DD/MM/YYYY - ${moment().format("DD/MM/YYYY")}`,
    },
    {
      value: "MM/DD/YYYY",
      label: `MM/DD/YYYY - ${moment().format("MM/DD/YYYY")}`,
    },
    {
      value: "YYYY/MM/DD",
      label: `YYYY/MM/DD - ${moment().format("YYYY/MM/DD")}`,
    },
    { value: "DD/MM/YY", label: `DD/MM/YY - ${moment().format("DD/MM/YY")}` },
    { value: "MM/DD/YY", label: `MM/DD/YY - ${moment().format("MM/DD/YY")}` },
    { value: "YY/MM/DD", label: `YY/MM/DD - ${moment().format("YY/MM/DD")}` },
    {
      value: "DD/MMM/YYYY",
      label: `DD/Mon/YYYY - ${moment().format("DD/MMM/YYYY")}`,
    },
    {
      value: "MMM/DD/YYYY",
      label: `Mon/DD/YYYY - ${moment().format("MMM/DD/YYYY")}`,
    },
    {
      value: "DD/MMM/YY",
      label: `DD/Mon/YY - ${moment().format("DD/MMM/YY")}`,
    },
    {
      value: "MMM/DD/YY",
      label: `Mon/DD/YY - ${moment().format("MMM/DD/YY")}`,
    },
  ];

  const timeOptions = [
    { value: "hh:mm a", label: "hh:mm (12 Hours)" },
    { value: "HH:mm", label: "HH:MM (24 Hours)" },
  ];

  const technicianTimeOptions = [
    { value: "30", label: "30 Min" },
    { value: "60", label: "1 Hour" },
    { value: "90", label: "1:30 Hour" },
    { value: "120", label: "2 Hour" },
  ];

  const customerTimeOptions = [
    { value: "60", label: "1 Hour" },
    { value: "120", label: "2 Hour" },
  ];
  return (
    <>
      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h4 className="mb-0 text-uppercase">DEFAULT</h4>
          <div className="cta">
            <button
              type="button"
              className="btn btn-success px-3 btn-rounded"
              onClick={formik.handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
        <div className="card-body p-0">
          <div className="row m-0">
            <div className="col-md-12 col-xl-6 border-end p-4">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">
                      Date Format
                    </label>
                    <Select
                      className="custom-select"
                      classNamePrefix="custom-select"
                      options={dateOptions}
                      value={
                        formik.values.dateFormat
                          ? dateOptions.filter(
                              (date) => date.value === formik.values.dateFormat
                            )
                          : ""
                      }
                      tabSelectsValue={
                        formik.values.dateFormat
                          ? dateOptions.filter(
                              (date) => date.value === formik.values.dateFormat
                            )
                          : ""
                      }
                      onChange={(e) =>
                        formik.setFieldValue("dateFormat", e.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">
                      Time Format
                    </label>
                    <Select
                      className="custom-select"
                      classNamePrefix="custom-select"
                      options={timeOptions}
                      value={
                        formik.values.timeFormat
                          ? timeOptions.filter(
                              (date) => date.value === formik.values.timeFormat
                            )
                          : ""
                      }
                      onChange={(e) =>
                        formik.setFieldValue("timeFormat", e.value)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="font-size-13 text-uppercase border-bottom py-3 mb-3">
                Numbering
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">
                      Quote
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={formik.values.quoteNumber}
                      onChange={(e) =>
                        formik.setFieldValue("quoteNumber", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">
                      P/O
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={formik.values.poNumber}
                      onChange={(e) =>
                        formik.setFieldValue("poNumber", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">
                      Job
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={formik.values.jobNumber}
                      onChange={(e) =>
                        formik.setFieldValue("jobNumber", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">
                      Invoice
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={formik.values.invoiceNumber}
                      onChange={(e) =>
                        formik.setFieldValue("invoiceNumber", e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="font-size-13 text-uppercase border-bottom py-3 mb-3">
                SCHEDULE - THIS TIME SLOTS WILL DISPLAY IN SCHEDULE VIEW
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">
                      Technician Time Slot
                    </label>
                    <Select
                      className="custom-select"
                      classNamePrefix="custom-select"
                      options={technicianTimeOptions}
                      value={
                        formik.values.technicianTimeSlot
                          ? technicianTimeOptions.filter(
                              (date) =>
                                date.value === formik.values.technicianTimeSlot
                            )
                          : ""
                      }
                      onChange={(e) =>
                        formik.setFieldValue("technicianTimeSlot", e.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">
                      Customer Time Slot
                    </label>
                    <Select
                      className="custom-select"
                      classNamePrefix="custom-select"
                      options={customerTimeOptions}
                      value={
                        formik.values.customerTimeSlot
                          ? customerTimeOptions.filter(
                              (date) =>
                                date.value === formik.values.customerTimeSlot
                            )
                          : ""
                      }
                      onChange={(e) =>
                        formik.setFieldValue("customerTimeSlot", e.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-xl-6 p-4">
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">
                      Day
                    </label>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">
                      Status
                    </label>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">
                      Open Time
                    </label>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">
                      Close Time
                    </label>
                  </div>
                </div>
              </div>

              {formik.values.scheduleData.map((data, index) => {
                var day = "";
                switch (data.day) {
                  case 1:
                    day = "Monday";
                    break;
                  case 2:
                    day = "Tuesday";
                    break;
                  case 3:
                    day = "Wednesday";
                    break;
                  case 4:
                    day = "Thursday";
                    break;
                  case 5:
                    day = "Friday";
                    break;
                  case 6:
                    day = "Saturday";
                    break;

                  default:
                    day = "";
                    break;
                }

                if (day) {
                  return (
                    <div className="row" key={index.toString()}>
                      <div className="col-md-3">
                        <div className="form-group pb-3">
                          <input
                            type="text"
                            value={day}
                            className="form-control"
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group pb-3">
                          <ToggleButtonGroup
                            type="radio"
                            name={`options${index}`}
                            value={data.status}
                          >
                            <ToggleButton
                              value={"1"}
                              id={`options1-${index}`}
                              variant="outline-primary"
                              className="btn-rounded mb-0 font-size-12"
                              onChange={() => {
                                formik.setFieldValue(`scheduleData[${index}]`, {
                                  ...formik.values.scheduleData[index],
                                  status: "1",
                                  // Convert value of current user timezone
                                  open_time: moment()
                                    .tz(
                                      workShopOtherDetails &&
                                        workShopOtherDetails.timezone_label
                                        ? workShopOtherDetails.timezone_label
                                        : "Asia/Kolkata"
                                    )
                                    .format("HH:mm"),
                                  // Convert value of current user timezone
                                  close_time: moment()
                                    .tz(
                                      workShopOtherDetails &&
                                        workShopOtherDetails.timezone_label
                                        ? workShopOtherDetails.timezone_label
                                        : "Asia/Kolkata"
                                    )
                                    .format("HH:mm"),
                                });
                              }}
                            >
                              Open
                            </ToggleButton>
                            <ToggleButton
                              value={"0"}
                              id={`options2-${index}`}
                              variant="outline-primary"
                              className="btn-rounded mb-0 font-size-12"
                              onChange={() => {
                                formik.setFieldValue(`scheduleData[${index}]`, {
                                  ...formik.values.scheduleData[index],
                                  status: "0",
                                });
                              }}
                            >
                              Close
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group pb-3">
                          <Form.Group className="form-group width-33 time-input time-input-no-label">
                            <TimePicker
                              hour12Format={
                                // formik.values.timeFormat === "hh:mm a"
                                //   ? true
                                //   :
                                false
                              }
                              className="form-control"
                              disabled={data.status === "0"}
                              value={
                                data.status === "1"
                                  ? data.open_time // Convert value UTC to current user timezone
                                    ? utcToTimezone(
                                        data.open_time,
                                        workShopOtherDetails &&
                                          workShopOtherDetails.timezone_label
                                          ? workShopOtherDetails.timezone_label
                                          : "Asia/Kolkata",
                                        timeFormat,
                                        timeFormat
                                      )
                                    : "- - : - - "
                                  : "- - : - - "
                              }
                              onChange={(e) => {
                                formik.setFieldValue(`scheduleData[${index}]`, {
                                  ...formik.values.scheduleData[index],
                                  open_time: timeZoneToUTC(
                                    e,
                                    workShopOtherDetails &&
                                      workShopOtherDetails.timezone_label
                                      ? workShopOtherDetails.timezone_label
                                      : "Asia/Kolkata",
                                    timeFormat,
                                    timeFormat
                                  ), // Convert value convert into user timezone to UTC,
                                });
                              }}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group pb-3">
                          <Form.Group className="form-group width-33 time-input time-input-no-label">
                            <TimePicker
                              hour12Format={
                                // formik.values.timeFormat === "hh:mm a"
                                //   ? true
                                //   :
                                false
                              }
                              className="form-control"
                              disabled={data.status === "0"}
                              value={
                                data.status === "1"
                                  ? data.close_time // Convert value UTC to current user timezone
                                    ? utcToTimezone(
                                        data.close_time,
                                        workShopOtherDetails &&
                                          workShopOtherDetails.timezone_label
                                          ? workShopOtherDetails.timezone_label
                                          : "Asia/Kolkata",
                                        timeFormat,
                                        timeFormat
                                      )
                                    : "- - : - - "
                                  : "- - : - - "
                              }
                              onChange={(e) => {
                                formik.setFieldValue(`scheduleData[${index}]`, {
                                  ...formik.values.scheduleData[index],
                                  close_time: timeZoneToUTC(
                                    e,
                                    workShopOtherDetails &&
                                      workShopOtherDetails.timezone_label
                                      ? workShopOtherDetails.timezone_label
                                      : "Asia/Kolkata",
                                    timeFormat,
                                    timeFormat
                                  ), // Convert value convert into user timezone to UTC,
                                });
                              }}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
              {/* return ( */}
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group pb-3">
                    <input
                      type="text"
                      // value="Sunday"
                      defaultValue="Sunday"
                      className="form-control"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group pb-3">
                    <ToggleButtonGroup
                      type="radio"
                      name={`options -0`}
                      value={formik.values.scheduleData[0].status}
                    >
                      <ToggleButton
                        value={"1"}
                        id={`options1-${0}`}
                        variant="outline-primary"
                        className="btn-rounded mb-0 font-size-12"
                        onChange={() => {
                          formik.setFieldValue(`scheduleData[0]`, {
                            ...formik.values.scheduleData[0],
                            status: "1",
                            // Convert value of current user timezone
                            open_time: moment()
                              .tz(
                                workShopOtherDetails &&
                                  workShopOtherDetails.timezone_label
                                  ? workShopOtherDetails.timezone_label
                                  : "Asia/Kolkata"
                              )
                              .format(timeFormat),
                            // Convert value of current user timezone
                            close_time: moment()
                              .tz(
                                workShopOtherDetails &&
                                  workShopOtherDetails.timezone_label
                                  ? workShopOtherDetails.timezone_label
                                  : "Asia/Kolkata"
                              )
                              .format(timeFormat),
                          });
                        }}
                      >
                        Open
                      </ToggleButton>
                      <ToggleButton
                        value={"0"}
                        id={`options2-${0}`}
                        variant="outline-primary"
                        className="btn-rounded mb-0 font-size-12"
                        onChange={() => {
                          formik.setFieldValue(`scheduleData[${0}]`, {
                            ...formik.values.scheduleData[0],
                            status: 0,
                            // open_time: "",
                            // close_time: "",
                          });
                        }}
                      >
                        Close
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group pb-3">
                    <Form.Group className="form-group width-33 time-input time-input-no-label">
                      <TimePicker
                        hour12Format={
                          // formik.values.timeFormat === "hh:mm a" ? true :
                          false
                        }
                        disabled={formik.values.scheduleData[0].status === "0"}
                        value={
                          formik.values.scheduleData[0].status === "1"
                            ? formik.values.scheduleData[0].open_time && // Convert value UTC to current user timezone
                              moment
                                .utc(
                                  formik.values.scheduleData[0].open_time,
                                  timeFormat
                                )
                                .clone()
                                .tz(
                                  workShopOtherDetails &&
                                    workShopOtherDetails.timezone_label
                                    ? workShopOtherDetails.timezone_label
                                    : "Asia/Kolkata"
                                )
                                .format(timeFormat)
                            : "- - : - - "
                        }
                        onChange={(e) => {
                          const currentTimeZoneTime = moment(e, format).tz(
                            workShopOtherDetails &&
                              workShopOtherDetails.timezone_label
                              ? workShopOtherDetails.timezone_label
                              : "Asia/Kolkata"
                          );
                          // Convert value convert into user timezone to UTC
                          formik.setFieldValue(`scheduleData[${0}]`, {
                            ...formik.values.scheduleData[0],
                            open_time: moment(currentTimeZoneTime)
                              .utc()
                              .format(timeFormat),
                          });
                        }}
                      />

                      {/* <Form.Control type="time" />
                      <span>Hrs</span> */}
                    </Form.Group>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group pb-3">
                    <Form.Group className="form-group width-33 time-input time-input-no-label">
                      <TimePicker
                        hour12Format={
                          // formik.values.timeFormat === "hh:mm a" ? true :
                          false
                        }
                        disabled={formik.values.scheduleData[0].status === "0"}
                        value={
                          formik.values.scheduleData[0].status === "1"
                            ? formik.values.scheduleData[0].close_time && // Convert value UTC to current user timezone
                              moment
                                .utc(
                                  formik.values.scheduleData[0].close_time,
                                  timeFormat
                                )
                                .clone()
                                .tz(
                                  workShopOtherDetails &&
                                    workShopOtherDetails.timezone_label
                                    ? workShopOtherDetails.timezone_label
                                    : "Asia/Kolkata"
                                )
                                .format(timeFormat)
                            : "- - : - - "
                        }
                        onChange={(e) => {
                          const currentTimeZoneTime = moment(e, format).tz(
                            workShopOtherDetails &&
                              workShopOtherDetails.timezone_label
                              ? workShopOtherDetails.timezone_label
                              : "Asia/Kolkata"
                          );
                          // Convert value convert into user timezone to UTC
                          formik.setFieldValue(`scheduleData[${0}]`, {
                            ...formik.values.scheduleData[0],
                            close_time: moment(currentTimeZoneTime)
                              .utc()
                              .format(timeFormat),
                          });
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
              {/* ) */}
            </div>
          </div>
        </div>
      </div>
      {formLoading && (
        <div className="page-loader d-flex align-center justify-center">
          <img src={LoaderImage} alt="Loading"></img>
        </div>
      )}
    </>
  );
};

export default DefaultForm;
