import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import AllRoutes from "./routes/allRoutes";
import { ToastContainer } from "react-toastify";
import LoaderImage from "../src/assets/images/page-loader.svg";
import Scroller from './views/common/UI/Scroller/Scroller.js';

function App() {
  return (
    <>
      <div className="App">
        <Scroller/>
        <AllRoutes />
        <ToastContainer />
        <div
          className="page-loader align-center justify-center"
          id="page-loader"
          style={{ display: "none" }}
        >
          <img src={LoaderImage} alt="Loading"></img>
        </div>
      </div>
      <div className="print" id="print"></div>
    </>
  );
}

export default App;
