import {
  getInsurance,
  getInvoicesList,
  getJobCannedList,
  getJobNotes,
  getLabourChargesList,
  getUnassignedJobs,
  getWorkshopOtherDetailsView,
} from "helpers/backendHelper";
import { getWorkshopId } from "utils/getWorkshopId";
import {
  getBookedJobPayload,
  getInsurancePayload,
  getJobCannedListPayload,
  getJobNotesPayload,
  getLabourChargeListPayload,
  getWorkshopOtherDetailViewPayload,
} from "../api";

export async function getInvoiceEditDetail(id) {
  let address,
    customer_detail,
    jobs,
    job_parts,
    customerdetail,
    labourname,
    parts = "",
    part_gst,
    labour_gst,
    purchase_gst,
    vehicle,
    scripts,
    job_type,
    invoice_data,
    remain_balance = 0,
    claim_amount = 0,
    inv_type = "Full",
    inv_number,
    all_invoices,
    last_invoices,
    jobnotes,
    comp_logo,
    job_labours,
    otherdata,
    defaultLabour,
    insurance_company;

  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  const workshop_id = getWorkshopId();

  const request1 = {
    get: "all",
    conditions: { "Invoices.id": id },
    contain: {
      0: "JobSchedules.Users.EmployeesDetails",
      1: "JobSchedules.Vehicles.Users.Countries",
      2: "JobSchedules.Vehicles.Users.States",
      "JobSchedules.Vehicles.Users.CustomersDetails": {
        conditions: { workshop_id: getWorkshopId() },
      },
      "JobSchedules.JobsParts": {
        conditions: { "JobsParts.status": 1 },
        sort: { "JobsParts.job_part_priority": "asc" },
      },
      3: "JobSchedules.JobsParts.Products",
      4: "JobSchedules.InsuranceCompany.States",
      5: "JobSchedules.InsuranceCompany.Countries",
      6: "JobSchedules.JobBookingTypes",
      7: "JobSchedules.JobLabours.LabourCharges",
      "JobSchedules.JobAttachments": {
        conditions: { invoice_attachment: "1" },
      },
      8: "Workshops",
    },
  };

  // const invoice_data_payload = {
  //   get: "all",
  //   conditions: {
  //     "Invoices.id": id,
  //   },
  // };
  const invoice_data_response = await getInvoicesList({
    payload: request1,
  });
  // debugger;
  invoice_data = invoice_data_response?.data?.Invoices[0];

  inv_number = invoice_data?.invoice_number;
  let get_customer_data = await getUnassignedJobs({
    payload: getBookedJobPayload(invoice_data.job_id, workshop_id),
  });

  if (get_customer_data?.code === 200) {
    jobs = get_customer_data?.data?.JobSchedules[0];
    if (jobs?.vehicle?.user?.customers_details[0]) {
      customer_detail = jobs?.vehicle?.user?.customers_details[0];
    }
    job_parts = jobs?.jobs_parts;
    job_labours = jobs?.job_labours;
    customerdetail = jobs?.vehicle?.Users[0];

    if (jobs?.user?.employees_details[0]?.labour_charge?.type == 1) {
      labourname = "Technician";
    }
    if (jobs?.user?.employees_details[0]?.labour_charge?.type == 2) {
      labourname = "Senior Technician";
    }
    if (jobs?.user?.employees_details[0]?.labour_charge?.type == 3) {
      labourname = "Apprentice";
    }
    if (jobs?.user?.employees_details[0]?.labour_charge?.type == 1) {
      labourname = "Mobile Technician";
    }

    all_invoices = jobs?.invoices;
    last_invoices = jobs?.invoices[jobs?.invoices.length - 1];
    vehicle = jobs?.vehicle;

    job_type = jobs?.job_booking_type?.name;
    if (jobs?.user) {
      address = jobs?.user?.workshop;
    } else {
      address = jobs?.workshop;
    }
  }

  part_gst = window.atob(storedData.part_gst);
  labour_gst = window.atob(storedData.labour_gst);
  purchase_gst = window.atob(storedData.purchase_gst);

  const get_worknotes_scripts = await getJobCannedList({
    payload: getJobCannedListPayload(workshop_id),
  });

  if (get_worknotes_scripts?.code == 200) {
    scripts = get_worknotes_scripts?.data?.WorkshopScriptsDetails;
  }

  const get_jobnotes = await getJobNotes({
    payload: getJobNotesPayload(invoice_data?.job_id),
  });
  if (get_jobnotes?.code == 200) {
    jobnotes = get_jobnotes?.data?.JobNotes[0];
  }

  const getworkshopotherdata = await getWorkshopOtherDetailsView({
    payload: getWorkshopOtherDetailViewPayload(workshop_id),
  });
  if (getworkshopotherdata?.code == 200) {
    otherdata = getworkshopotherdata?.data?.WorkshopOtherDetails;
  }

  const labourCharges = await getLabourChargesList({
    payload: getLabourChargeListPayload(workshop_id),
  });
  if (labourCharges?.code == 200) {
    defaultLabour = labourCharges?.data?.LabourCharges[0];
  }

  const insurance = await getInsurance({
    payload: getInsurancePayload(workshop_id),
  });

  if (insurance?.code == 200) {
    insurance_company = insurance?.data?.InsuranceCompany;
  }

  customer_detail.email = customer_detail?.email || customerdetail.email;

  return {
    address, //done
    customer_detail, //done
    jobs, //done
    job_parts, //done
    customerdetail, //done
    labourname, //done
    parts, //done
    part_gst, //done
    labour_gst, //done
    purchase_gst, //done
    vehicle, //done
    scripts, //done
    job_type, //done
    invoice_data, //done
    remain_balance, //done
    claim_amount, //done
    inv_type, //done
    inv_number, //done
    all_invoices, //done
    last_invoices, //done
    jobnotes, //done
    // comp_logo,
    job_labours, //done
    otherdata, //done
    defaultLabour, //done
    insurance_company, //done
  };
}
