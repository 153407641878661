import React, { useState, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import { Scrollbars } from "react-custom-scrollbars";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import { unserializeArr } from "../../../utils/unserialize";
import moment from 'moment';

const MonthViewCreateJob = ({timeSlotsList, employeeList, setTimeSlot, timeSlot, employeeId, setEmployeeId, handleTechnicianSlot}) => {
    return ( 
        <div eventKey="Jobs" title="Jobs">
          <div className="tab-wrapper">
            <div className="schedular-blocks d-flex mb-4">
              <ul className="scheduler-person-list activity-list">
              <Scrollbars
                  autoHeight
                  autoHeightMax={160}
                  className="custom-scrollbar"
                  style={{ width: "100%" }}
                  renderThumbVertical={(props) => (
                    <div className="thumb-vertical grey-thumb" />
                  )}
                  renderTrackHorizontal={(props) => (
                    <div style={{ display: "none" }} />
                  )}
                >
                  {
                    employeeList.length !== 0 ? 
                    employeeList.map((emp,index) => {
                        return(
                            <li key={emp.id} > 
                                <Form.Check
                                    className="radio-btn mb-2"
                                    type="radio"
                                    id={emp.id}
                                >
                                    <Form.Check.Input
                                        checked={emp.id === employeeId ? true : false}
                                        name="tech"
                                        type="radio"
                                        data-id={emp.data_type}
                                        isValid
                                        value={emp.id}
                                        onChange={
                                            (e) => {setEmployeeId(emp.id); handleTechnicianSlot(e.target,emp)}
                                        }
                                        disabled={(emp.working_days === 0) ? true : false}
                                    />
                                    <Form.Check.Label>
                                        {emp.name}
                                        <span className="check"></span>
                                    </Form.Check.Label>
                                </Form.Check>
                            </li>
                        )
                    }): ""
                  }
                </Scrollbars>
              </ul>
              <ul className="schedular-list d-flex">
              {
                  timeSlotsList.length !== 0 ? 
                  timeSlotsList.map((time,index)=>{
                      return(
                          <li key={time.start_time}>
                              <Form.Check
                                  className="radio-btn mb-2"
                                  type="radio"
                                  id={time.slots}
                              >
                                  <Form.Check.Input
                                      checked={time.start_time === timeSlot ? true : false}
                                      name="slots"
                                      type="radio"
                                      data-time={time.start_time}
                                      isValid
                                      value={time.slots}
                                      data-available={time.available} 
                                      disabled={(time.available === 0) ? true : false}
                                      onChange={
                                          (e) => {
                                            setTimeSlot(time.start_time);

                                          }
                                      }
                                  />
                                  <Form.Check.Label>
                                      {time.slots}
                                      <span className="check"></span>
                                  </Form.Check.Label>
                              </Form.Check>
                          </li>
                      )
                  })
                  : <div style={{ textAlign: "center" }}> No Slots available . </div>
              }
              </ul>
            </div>
          </div>
        </div>
    )
};

export default MonthViewCreateJob;