import {
  GET_NOTIFICATION,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAIL,
} from "./actionTypes";

const initialState = {
  loadingLogins: false,
  message: "",
  error: "",
  notificationData: null,
  isFetching: false,
};

const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATION:
      state = { 
        ...state, 
        loadingLogins: true,
        isFetching: false,
      };
      break;
      case GET_NOTIFICATION_SUCCESS:
        state = { 
          ...state, 
          loadingLogins: false,
          notificationData: action.payload,
          isFetching: true,
        };
        break;
      case GET_NOTIFICATION_FAIL:
        state = {
          ...state,
          loadingLogins: false,
          error: {
            message: action.payload,
          },
          isFetching: true,
        };
        break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default NotificationReducer;
