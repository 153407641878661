export const GET_CUSTOM_FIELDS = "GET_CUSTOM_FIELDS";
export const GET_CUSTOM_FIELDS_SUCCESS = "GET_CUSTOM_FIELDS_SUCCESS";
export const GET_CUSTOM_FIELDS_FAIL = "GET_CUSTOM_FIELDS_FAIL";

export const CHECK_VIN_EXIST = "CHECK_VIN_EXIST";
export const CHECK_VIN_EXIST_SUCCESS = "CHECK_VIN_EXIST_SUCCESS";
export const CHECK_VIN_EXIST_FAIL = "CHECK_VIN_EXIST_FAIL";

export const CHECK_CHASIS_EXIST = "CHECK_CHASIS_EXIST";
export const CHECK_CHASIS_EXIST_SUCCESS = "CHECK_CHASIS_EXIST_SUCCESS";
export const CHECK_CHASIS_EXIST_FAIL = "CHECK_CHASIS_EXIST_FAIL";

export const CHECK_ENGINE_EXIST = "CHECK_ENGINE_EXIST";
export const CHECK_ENGINE_EXIST_SUCCESS = "CHECK_ENGINE_EXIST_SUCCESS";
export const CHECK_ENGINE_EXIST_FAIL = "CHECK_ENGINE_EXIST_FAIL";

export const CHECK_FLEET_EXIST = "CHECK_FLEET_EXIST";
export const CHECK_FLEET_EXIST_SUCCESS = "CHECK_FLEET_EXIST_SUCCESS";
export const CHECK_FLEET_EXIST_FAIL = "CHECK_FLEET_EXIST_FAIL";

export const GET_ASSETS_MASTER = "GET_ASSETS_MASTER";
export const GET_ASSETS_MASTER_SUCCESS = "GET_ASSETS_MASTER_SUCCESS";
export const GET_ASSETS_MASTER_FAIL = "GET_ASSETS_MASTER_FAIL";

export const GET_ASSETS_DETAILS = "GET_ASSETS_DETAILS";
export const GET_ASSETS_DETAILS_SUCCESS = "GET_ASSETS_DETAILS_SUCCESS";
export const GET_ASSETS_DETAILS_FAIL = "GET_ASSETS_DETAILS_FAIL";

export const ADD_ASSETS_DETAILS = "ADD_ASSETS_DETAILS";
export const ADD_ASSETS_DETAILS_SUCCESS = "ADD_ASSETS_DETAILS_SUCCESS";
export const ADD_ASSETS_DETAILS_FAIL = "ADD_ASSETS_DETAILS_FAIL";

export const ADD_ASSETS_MASTER = "ADD_ASSETS_MASTER";
export const ADD_ASSETS_MASTER_SUCCESS = "ADD_ASSETS_MASTER_SUCCESS";
export const ADD_ASSETS_MASTER_FAIL = "ADD_ASSETS_MASTER_FAIL";

export const ADD_ASSETS = "ADD_ASSETS";
export const ADD_ASSETS_SUCCESS = "ADD_ASSETS_SUCCESS";
export const ADD_ASSETS_FAIL = "ADD_ASSETS_FAIL";
