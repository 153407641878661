import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import HelpImg from "../../../../assets/images/help.svg";
import setup from "../../../../assets/images/setup.svg";
import video from "../../../../assets/images/video.svg";
import { Form } from "react-bootstrap";

import NoResult from "../../../../assets/images/no-result-found.jpeg";
import BookJob from "../../../../assets/images/book-job-red.svg";
import CashSale from "../../../../assets/images/cash-sale-red.svg";
import Customer from "../../../../assets/images/customer-red.svg";
import BookJobBlack from "../../../../assets/images/book-job.svg";
import CashSaleBlack from "../../../../assets/images/cash-sale.svg";
import Schedule from "../../../../assets/images/schedule.svg";
import Badge from "react-bootstrap/Badge";
import Notification from "./notification";
import GloablSearch from "./globalSearch";
import { useDispatch, useSelector } from "react-redux";
import { GET_NOTIFICATION } from "../../../../store/notification/actionTypes";
import {
  GET_SUBSCRIPTION_ADDON,
  GET_SUBSCRIPTION_LOOKUP_ADDON,
} from "../../../../store/subscriptionAddon/actionTypes";
import { GET_SUBSCRIPTION } from "../../../../store/subscription/actionTypes";
import { GET_GLOBAL_SEARCH } from "../../../../store/globalSearch/actionTypes";
import ChangePassword from "./changePassword";
import LookupBalance from "../../../modals/lookupBalance";
import SmsBalance from "../../../modals/smsBalance";
import { UPDATE_WORKSHOP_OTHER_DETAIL, GET_SUPPLIER_INTEGRATION } from "../../../../store/commonApiStore/actionTypes";
import Button from "react-bootstrap/Button";
import moment from "moment";
import {
  GET_WORKSHOP_MARKUP_DETAILS,
  GET_WORKSHOP_TAX_DETAILS,
} from "../../../../store/commonApiStore/actionTypes";
import { GET_PART_LIST } from "../../../../store/parts/actionTypes";

const Header = (props) => {
  const [isShown, setIsShown] = useState(false);
  const [isChecked, setChecked] = useState(true);
  const [isFilterOpen, setFilterOpen] = useState(false);
  // const [isSearchOpen, setSearchOpen] = useState(false);
  const [isNotificationOpen, setNotificationOPen] = useState(false);
  const [isHelpOpen, setHelpOpen] = useState(false);
  const [isProfileOpen, setProfileOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(0);
  const [userName, setUserName] = useState();
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showLookupBalanceModal, setShowLookupBalanceModal] = useState(false);
  const [showSmsBalanceModal, setShowSmsBalanceModal] = useState(false);
  const [balanceType, setBalanceType] = useState();
  const [isSubscription, setIsSubscription] = useState(0);
  const [searchType, setSearchType] = useState("customer");
  const [eventKey, setEventKey] = useState("customer");
  const [isPlanExpire, setIsPlanExpire] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState("Trial");
  const [isTrialMode, setIsTrialMode] = useState(false);
  const [expDayCount, setExpDayCount] = useState("-1");
  const [dashboardType, setDashboardType] = useState("Business Stats");

  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  // default plan
  storedData.is_inspection_business = 0;

  let user_id = window.atob(storedData.user_id);
  let workshop_id = window.atob(storedData.workshop_id);
  let workshop_other_id = window.atob(storedData.workshop_other_id);

  const ref = useRef();

  const handleChange = () => {
    setChecked(!isChecked);
  };

  const dispatch = useDispatch();

  // change theme mode
  const handleClick = (ev) => {
    document
      .querySelector("body")
      .classList[ev.target.checked ? "remove" : "add"]("dark-theme");
    var theme_value = 0;
    if (ev.target.checked === true) {
      storedData.theme_mode = "1";
      theme_value = 1;
    } else {
      storedData.theme_mode = "0";
    }
    sessionStorage.setItem("stored_data", JSON.stringify(storedData));

    dispatch({
      type: UPDATE_WORKSHOP_OTHER_DETAIL,
      payload: {
        workshop_id: workshop_id,
        is_light_mode: theme_value,
        id: workshop_other_id,
      },
    });
  };

  // header menu dropdown active / inactive
  useEffect(() => {
    function checkIfClickedOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        // onClickOutside();
        setActiveDropdown(0);
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, []);

  // set theme mode and set username
  useEffect(() => {
    if (storedData.theme_mode === "1") {
      document.body.classList.remove("dark-theme");
      setChecked(true);
    } else {
      document.body.classList.add("dark-theme");
      setChecked(false);
    }

    setUserName(window.atob(storedData.user_name));
  }, []);

  //Expirty DATA START

  //check current plan expire data
  var trial_expiry_date = moment(
    window.atob(storedData.trial_expiry_date)
  ).format("YYYY-MM-DD");
  var expiry_date = moment().format("YYYY-MM-DD");
  var current_date = moment().format("YYYY-MM-DD");

  // fetch current subscription detail
  useEffect(() => {
    dispatch({
      type: GET_SUBSCRIPTION,
      payload: {
        get: "all",
        conditions: {
          workshop_id: workshop_id,
        },
        contain: ["WorkshopSubscriptionDetails"],
        limit: 1,
        order: {
          plan_expiry_date: "desc",
        },
      },
    });
  }, []);

  // get subscription detail by reducer
  const Subscription = useSelector((state) => state.SubscriptionReducer);

  // lookup and sms modal show
  useEffect(() => {
    if (Subscription.isFetching === true) {
      if (Subscription.subscribeData != null) {
        setSubscriptionType("Subscription");

        expiry_date = moment(
          Subscription.subscribeData.plan_expiry_date
        ).format("YYYY-MM-DD");
        
        if (expiry_date >= current_date) {
          setIsPlanExpire(false);
          if (Subscription?.subscribeData?.plan_name === "Business Plan") {
            storedData.is_inspection_business = 1;
          }        
        } else {
          setIsPlanExpire(true);          
        }

      } else {
        if (trial_expiry_date) {
          var trial_date = moment(trial_expiry_date);
          var today_date = moment(current_date);
          var days_diff = trial_date.diff(today_date, "days"); // 1
          setExpDayCount(days_diff);
          if(current_date <= trial_expiry_date){
            setIsTrialMode(true);
            storedData.is_inspection_business = 1;
          }         
        }
        sessionStorage.setItem("stored_data", JSON.stringify(storedData));
      }
    }
  }, [Subscription]);

  //Expirty DATA END

  // get notification by reducer
  const notificationData = useSelector((state) => state.NotificationReducer);
  // all default dispatch
  useEffect(() => {
    // get notificaiton list
    dispatch({
      type: GET_NOTIFICATION,
      payload: {
        get: "all",
        conditions: {
          to_id: user_id,
          is_read: 0,
          "type !=": "inspection_verification_sms",
          workshop_id: workshop_id,
        },
        limit: 5,
        order: {
          id: "desc",
        },
        fields: ["id", "msg", "type"],
      },
    });

    // get sms balance
    if (typeof storedData.is_sms_subscribe === "undefined") {
      dispatch({
        type: GET_SUBSCRIPTION_ADDON,
        payload: {
          get: "all",
          conditions: {
            "WorkshopSubscriptionDetails.plan_type": "sms",
            "WorkshopSubscriptionDetails.current_sms_limit >": 0,
          },
          contain: {
            WorkshopSubscription: {
              conditions: {
                "WorkshopSubscription.workshop_id": workshop_id,
              },
            },
          },
          fields: [
            "id",
            "workshop_subscription_id",
            "subscription_plan_id",
            "sms_limit",
            "current_sms_limit",
          ],
        },
      });
    }

    if (typeof storedData.is_lookup_subscribe === "undefined") {
      // get rego lookup request balance
      dispatch({
        type: GET_SUBSCRIPTION_LOOKUP_ADDON,
        balancetype: "lookup",
        payload: {
          get: "all",
          conditions: {
            "WorkshopSubscriptionDetails.plan_type": "vin_lookup",
            "WorkshopSubscriptionDetails.current_vin_request >": 0,
          },
          contain: {
            WorkshopSubscription: {
              conditions: {
                "WorkshopSubscription.workshop_id": workshop_id,
              },
            },
          },
          fields: [
            "id",
            "workshop_subscription_id",
            "subscription_plan_id",
            "current_vin_request",
            "vin_request",
          ],
        },
      });
    }

    if (typeof storedData.part_gst === "undefined") {
      dispatch({
        type: GET_WORKSHOP_TAX_DETAILS,
        payload: {
          get: "all",
          conditions: {
            status: "1",
            workshop_id: workshop_id,
          },
        },
      });
    }

    if (
      typeof storedData.default_markup === "undefined" ||
      typeof storedData.default_margin === "undefined"
    ) {
      // fetch workshop markup detail
      dispatch({
        type: GET_WORKSHOP_MARKUP_DETAILS,
        payload: {
          get: "all",
          conditions: {
            is_default: "1",
            workshop_id: workshop_id,
          },
        },
      });
    }

    if (typeof storedData.misc_part_id === "undefined") {
      dispatch({
        type: GET_PART_LIST,
        payload: {
          get: "all",
          conditions: {
            workshop_id: 0,
            product_number: "Misc Part",
            supplier_id: 0,
            status: 1,
            is_deleted: 0,
          },
          limit: 1,
        },
      });
    }

    if (markupDetail.supplierIntegration.length === 0) {

      dispatch({
        type: GET_SUPPLIER_INTEGRATION,
        payload: {
          get: "all",
          conditions: {
            workshop_id: workshop_id
          }
        },
      });


    }


  }, []);

  // get subscription Addon data SMS and lookup data
  const SubscriptionAddon = useSelector(
    (state) => state.SubscriptionAddonReducer
  );

  // lookup and sms modal show
  useEffect(() => {
    // sms data store session
    if (typeof storedData.is_sms_subscribe === "undefined") {
      if (SubscriptionAddon.isSubscription === 1) {
        let is_sms_subscribe = window.btoa(SubscriptionAddon.isSubscription);
        storedData.is_sms_subscribe = is_sms_subscribe;
        let sms_limit = window.btoa(
          SubscriptionAddon.subscribeAddonData.sms_limit
        );
        storedData.sms_limit = sms_limit;
        let current_sms_limit = window.btoa(
          SubscriptionAddon.subscribeAddonData.current_sms_limit
        );
        storedData.current_sms_limit = current_sms_limit;
      }

      sessionStorage.setItem("stored_data", JSON.stringify(storedData));
    }

    // rego lookup data store session

    if (typeof storedData.is_lookup_subscribe === "undefined") {
      if (SubscriptionAddon.isLookupSubscription === 1) {
        let is_lookup_subscribe = window.btoa(
          SubscriptionAddon.isLookupSubscription
        );
        storedData.is_lookup_subscribe = is_lookup_subscribe;
        let vin_request = window.btoa(
          SubscriptionAddon.subscribeLookupAddonData.vin_request
        );
        storedData.vin_request = vin_request;
        let current_vin_request = window.btoa(
          SubscriptionAddon.subscribeLookupAddonData.current_vin_request
        );
        storedData.current_vin_request = current_vin_request;
      }

      sessionStorage.setItem("stored_data", JSON.stringify(storedData));
    }
  }, [SubscriptionAddon]);

  // markup and tax reducer
  const markupDetail = useSelector((state) => state.CommonApiStoreReducer);
  useEffect(() => {
    // set default margin and markup into session

    if (typeof storedData.default_margin === "undefined") {
      if (markupDetail.worskhopMarkupData !== null) {
        const markupData = markupDetail.worskhopMarkupData[0];
        var default_margin = 0;
        var default_markup = 0;
        if (markupData && markupData.is_markup === "1") {
          // data append to margin
          default_margin = window.btoa(markupData.percentage);
        }
        if (markupData && markupData.is_markup === "0") {
          // data append to markup
          default_markup = window.btoa(markupData.percentage);
        }
        storedData.default_markup = default_markup;
        storedData.default_margin = default_margin;
        sessionStorage.setItem("stored_data", JSON.stringify(storedData));
      }
    }

    // set parts, labour and products taxes into session
    if (typeof storedData.part_gst === "undefined") {
      if (markupDetail.worskhopTaxData !== null) {
        const worskhopTaxDatas = markupDetail.worskhopTaxData;

        worskhopTaxDatas.map((taxs) => {
          // part taxes
          if (taxs.application === "Part") {
            let part_gst = window.btoa(taxs.tax_rate);
            storedData.part_gst = part_gst;
            let part_expense_gst = window.btoa(taxs.expense_tax_rate);
            storedData.part_expense_gst = part_expense_gst;
          }

          // Labour taxes
          if (taxs.application === "Labour") {
            let labour_gst = window.btoa(taxs.tax_rate);
            storedData.labour_gst = labour_gst;
            let labour_expense_gst = window.btoa(taxs.expense_tax_rate);
            storedData.labour_expense_gst = labour_expense_gst;
          }

          // Purchase taxes
          if (taxs.application === "Purchase") {
            let purchase_gst = window.btoa(taxs.tax_rate);
            storedData.purchase_gst = purchase_gst;
            let purchase_expense_gst = window.btoa(taxs.expense_tax_rate);
            storedData.purchase_expense_gst = purchase_expense_gst;
          }
        });

        sessionStorage.setItem("stored_data", JSON.stringify(storedData));
      }
    }

    

    if (typeof markupDetail.supplierIntegration !== 'undefined' && markupDetail.supplierIntegration.length > 0) {
      
      const supplierIntegration = markupDetail.supplierIntegration;


      if(supplierIntegration.length > 0) {

        supplierIntegration.map((supplier) => {

          // Repce credential
          if (supplier.type === "1") {
            let repco_username = window.btoa(supplier.user_name);
            storedData.repco_username = repco_username;
            let repco_password = window.btoa(supplier.password);
            storedData.repco_password = repco_password;
            let repco_site_code = window.btoa(supplier.site_code);
            storedData.repco_site_code = repco_site_code;
          }

          // Burson credentail
          if (supplier.type === "2") {
            let burson_username = window.btoa(supplier.user_name);
            storedData.burson_username = burson_username;
            let burson_password = window.btoa(supplier.password);
            storedData.burson_password = burson_password;
            let burson_site_code = window.btoa(supplier.site_code);
            storedData.burson_site_code = burson_site_code;
          }


        });
        
        sessionStorage.setItem("stored_data", JSON.stringify(storedData));
      }

    }

  }, [markupDetail]);

  // part list reducer
  const PartReducerData = useSelector((state) => state.PartReducer);

  // default misc part is store into session
  useEffect(() => {
    if (typeof storedData.misc_part_id === "undefined") {
      if (PartReducerData.partListData !== null) {
        const partList = PartReducerData.partListData;

        let misc_part_id = window.btoa(partList[0].id);
        storedData.misc_part_id = misc_part_id;

        sessionStorage.setItem("stored_data", JSON.stringify(storedData));
      }
    }
  }, [PartReducerData]);

  const handleClosePasswordModal = () => setShowPasswordModal(false);
  const handleShowPasswordModal = () => setShowPasswordModal(true);

  const handleCloseLookupBalanceModal = () => setShowLookupBalanceModal(false);
  const handleShowLookupBalanceModal = () => setShowLookupBalanceModal(true);

  const handleCloseSmsBalanceModal = () => setShowSmsBalanceModal(false);
  const handleShowSmsBalanceModal = () => setShowSmsBalanceModal(true);

  // SMS and REGO Lookup popup START
  function getBalanceDetail(type) {
    setBalanceType(type);

    // document.getElementById('page-loader').style.display = 'flex';
    if (type === "lookup") {
      handleShowLookupBalanceModal();
    }

    if (type === "sms") {
      handleShowSmsBalanceModal();
    }
  }
  // SMS and REGO Lookup popup END

  // Global Search START
  function getGlobalSearchdata() {
    var search_value = document.getElementById("search_value").value;

    if (search_value.length > 2) {
      dispatch({
        type: GET_GLOBAL_SEARCH,
        payload: {
          workshop_id: workshop_id,
          keyword: search_value,
          type: searchType,
        },
      });

      setActiveDropdown(1);
    } else {
      // setActiveDropdown(0);
    }
  }

  //set search type
  const handleSearchType = (key) => {
    var search_type = key;
    setEventKey(key);
    if (key === "order") {
      search_type = "supplier";
    }

    var search_value = document.getElementById("search_value").value;

    dispatch({
      type: GET_GLOBAL_SEARCH,
      payload: {
        workshop_id: workshop_id,
        keyword: search_value,
        type: search_type,
      },
    });

    setActiveDropdown(1);
    setSearchType(search_type);
  };

  const globalSearchData = useSelector((state) => state.GlobalSearchReducer);

  // Global Search END

  return (
    <>
      <div className="header-outer">
        <Container fluid>
          {/* Trial period expire message */}
          {subscriptionType === "Trial" && isTrialMode === true && (
            <div className="subscribe-header d-flex align-center justify-space-between ">
              <div></div>
              <div className="center-align">
                <Button variant="secondary">Trial Mode </Button>
                {expDayCount > 0 ? (
                  <p>{expDayCount} Days Remaining Before Your Trial Expires</p>
                ) : (
                  <p>Your Trial Expired</p>
                )}
                <Link to="/subscription-plan"><Button variant="success">Subscribe Now</Button></Link>
              </div>
              <div className="right-align">
                <p>Sample data used during trial period</p>
              </div>
            </div>
          )}

          {/* Subscribe expire message */}
          {isPlanExpire === true && subscriptionType === "Subscription" && (
            <div className="subscribe-header d-flex align-center justify-center ">
              <div className="center-align">
                <p>Your Subscription Expired</p>
                <Button variant="success">Subscribe Now</Button>
              </div>
            </div>
          )}

          {/* main header */}
          <div className="header-wrapper">
            <div className="left-header">
              <Link to="/home" className="workshop-name">
                test business
              </Link>
              <DropdownButton
                className="business-dropdown custom-dropdown"
                onSelect={(evt) => setDashboardType(evt)}
                title={dashboardType}
              >
                <Dropdown.Item eventKey='Business Stats'>
                  <Link to="/home">Business Stats</Link>
                </Dropdown.Item>
                <Dropdown.Item eventKey='Booking Diary'>
                  <Link to="/booking-diary">Booking Diary</Link>
                </Dropdown.Item>
                <Dropdown.Item eventKey='Mobile Dashboard'>
                  <Link to="/">Mobile Dashboard</Link>
                </Dropdown.Item>
              </DropdownButton>
            </div>
            <div className="right-header">
              <ul ref={ref}>
                <li
                  className={`global-search ${
                    activeDropdown === 1 ? "active-menu" : ""
                  }`}
                >
                  <div
                    className={
                      "toggle-link search-input " + (isShown ? "active" : "")
                    }
                  >
                    <input
                      type="text"
                      placeholder="Search..."
                      id="search_value"
                      onKeyUp={(e) => getGlobalSearchdata()}
                    ></input>
                    <i
                      className="fa fa-search"
                      onMouseEnter={() => setIsShown(true)}
                    ></i>
                  </div>
                  {activeDropdown === 1 && (
                    <GloablSearch
                      searchType={searchType}
                      globalSearchData={globalSearchData}
                      handleSearchType={handleSearchType}
                      eventKey={eventKey}
                    />
                  )}
                </li>
                <li
                  className={`header-filter-wrapper ${
                    activeDropdown === 2 ? "active-menu" : ""
                  }`}
                  onClick={() =>
                    setActiveDropdown(
                      activeDropdown === 2 ? (isFilterOpen ? 2 : 0) : 2
                    )
                  }
                >
                  <div
                    className="toggle-link"
                    onClick={() => setFilterOpen((oldState) => !oldState)}
                  >
                    <i className="la la-plus plus-icon"></i>
                  </div>
                  {activeDropdown === 2 && (
                    <div className="header-filter custom-header-dropdown">
                      <div className="top-block">
                        <span className="title">New</span>
                        <ul>
                          <li>
                            <Link to="">
                              <i className="icon-block">
                                <img src={BookJob} alt="Book Job"></img>
                              </i>
                              <span>Book a Job</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              <i className="icon-block fa fa-car"></i>
                              <span>Customer &amp; Vehicle</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              <i className="icon-block">
                                <img src={Customer} alt="Customer"></img>
                              </i>
                              <span>Add a Customer</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              <i className="icon-block">
                                <img src={CashSale} alt="Cash Sale"></img>
                              </i>
                              <span>Cash Sale</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="bottom-block">
                        <span className="title">View</span>
                        <ul>
                          <li>
                            <Link to="">
                              <i className="icon-block">
                                <img src={BookJobBlack} alt="Book Job"></img>
                              </i>
                              <span>Jobs</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              <i className="icon-block">
                                <img src={CashSaleBlack} alt="Cash Sale"></img>
                              </i>
                              <span>Quotes</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              <i className="icon-block">
                                <img src={Schedule} alt="Schedule"></img>
                              </i>
                              <span>Schedule</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              <i
                                className="icon-block fa fa-files-o"
                                aria-hidden="true"
                              ></i>
                              <span>Invoice</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              <i className="icon-block fa fa-bullhorn font-size-18 m-l-5"></i>
                              <span>Marketing</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              <i className="icon-block fa fa-clock-o font-size-18 m-l-5"></i>
                              <span>Time Clock</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </li>
                <li
                  className={`header-notification-wrapper ${
                    activeDropdown === 3 ? "active-menu" : ""
                  }`}
                  onClick={() =>
                    setActiveDropdown(
                      activeDropdown === 3 ? (isNotificationOpen ? 3 : 0) : 3
                    )
                  }
                >
                  <div
                    className="toggle-link"
                    onClick={() => setNotificationOPen((oldState) => !oldState)}
                  >
                    <i className="fa fa-bell-o notification-icon">
                      <Badge pill bg="danger">
                        5
                      </Badge>
                    </i>
                  </div>
                  {activeDropdown === 3 && (
                    <Notification notificationData={notificationData} />
                  )}
                </li>
                <li
                  className={`help-notifications ${
                    activeDropdown === 4 ? "active-menu" : ""
                  }`}
                  onClick={() =>
                    setActiveDropdown(
                      activeDropdown === 4 ? (isHelpOpen ? 4 : 0) : 4
                    )
                  }
                >
                  <div
                    className="toggle-link"
                    onClick={() => setHelpOpen((oldState) => !oldState)}
                  >
                    <i>
                      <img src={HelpImg} alt="Help"></img>
                    </i>
                  </div>
                  {activeDropdown === 4 && (
                    <div className="help-notifications-inner custom-header-dropdown">
                      <ul className="help-notification-list">
                        <li>
                          <Link to="">
                            <span>Quick Setup</span>
                            <img src={setup} alt="Quick Setup"></img>
                          </Link>
                        </li>
                        <li>
                          <a
                            href="https://fiiviqcom.tawk.help"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <span>Knowledge Base</span>
                            <img src={setup} alt="Knowledge Base"></img>
                          </a>
                        </li>
                        <li>
                          <Link to="">
                            <span>How to Videos</span>
                            <img src={video} alt="How to Video"></img>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <span>Contact Us</span>
                            <i className="la la-phone"></i>
                          </Link>
                        </li>
                        <li onClick={() => getBalanceDetail("sms")}>
                          <Link to="">
                            <span>SMS Balance</span>
                            <i className="la la-envelope"></i>
                          </Link>
                        </li>
                        <li onClick={() => getBalanceDetail("lookup")}>
                          <Link to="">
                            <span>Rego Lookup</span>
                            <i className="la la-registered"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  )}
                </li>
                <li
                  className={`account-block ${
                    activeDropdown === 5 ? "active-menu" : ""
                  }`}
                  onClick={() =>
                    setActiveDropdown(
                      activeDropdown === 5 ? (isProfileOpen ? 5 : 0) : 5
                    )
                  }
                >
                  <div
                    className="profile-wrapper toggle-link"
                    onClick={() => setProfileOpen((oldState) => !oldState)}
                  >
                    <i className="profile-icon">
                      <img src={NoResult} alt="No Result"></img>
                    </i>
                    <div className="profile-name">
                      <span id="header_username">{userName}</span>
                      <i className="fa fa-angle-down"></i>
                    </div>
                  </div>
                  {activeDropdown === 5 && (
                    <ul className="profile-list custom-header-dropdown">
                      <li>
                        <Link to="/profile">
                          <span>Profile</span>
                        </Link>
                      </li>
                      <li onClick={handleShowPasswordModal}>
                        <Link to="">
                          <span>Change Password</span>
                        </Link>
                      </li>
                      <li className="switch-wrapper">
                        <span>Switch Light Mode</span>
                        <Form.Check
                          type="switch"
                          checked={isChecked}
                          onChange={handleChange}
                          onClick={handleClick}
                          className="custom-switch"
                          id="change-theme"
                        />
                      </li>
                      <li>
                        <Link to="/logout">
                          <span>Log Out</span>
                          <i className="fa fa-sign-out"></i>
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </div>

      {showPasswordModal && (
        <ChangePassword
          showPasswordModal={showPasswordModal}
          handleShowPasswordModal={handleShowPasswordModal}
          handleClosePasswordModal={handleClosePasswordModal}
        />
      )}

      {showLookupBalanceModal && (
        <LookupBalance
          showLookupBalanceModal={showLookupBalanceModal}
          handleShowLookupBalanceModal={handleShowLookupBalanceModal}
          handleCloseLookupBalanceModal={handleCloseLookupBalanceModal}
        />
      )}

      {showSmsBalanceModal && (
        <SmsBalance
          showSmsBalanceModal={showSmsBalanceModal}
          handleShowSmsBalanceModal={handleShowSmsBalanceModal}
          handleCloseSmsBalanceModal={handleCloseSmsBalanceModal}
        />
      )}
    </>
  );
};
export default Header;
