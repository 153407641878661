import {
  GET_OPEN_QUOTES,
  GET_OPEN_QUOTES_SUCCESS,
  GET_OPEN_QUOTES_FAIL,
  EDIT_QUOTES,
  EDIT_QUOTES_SUCCESS,
  EDIT_QUOTES_FAIL,
  GET_OPEN_QUOTES_COUNT,
  GET_OPEN_QUOTES_COUNT_SUCCESS,
  GET_OPEN_QUOTES_COUNT_FAIL,
} from "./actionTypes";

export const getOpenQuotes = () => {
  return {
    type: GET_OPEN_QUOTES,
  };
};

export const getOpenQuotesSuccess = (data) => {
  return {
    type: GET_OPEN_QUOTES_SUCCESS,
    payload: data,
  };
};

export const getOpenQuotesFail = (error) => {
  return {
    type: GET_OPEN_QUOTES_FAIL,
    payload: error,
  };
};

export const editQuotes = () => {
  return {
    type: EDIT_QUOTES,
  };
};

export const editQuotesSuccess = (data) => {
  return {
    type: EDIT_QUOTES_SUCCESS,
    payload: data,
  };
};

export const editQuotesFail = (error) => {
  return {
    type: EDIT_QUOTES_FAIL,
    payload: error,
  };
};

export const getOpenQuotesCount = () => {
  return {
    type: GET_OPEN_QUOTES_COUNT,
  };
};

export const getOpenQuotesCountSuccess = (data) => {
  return {
    type: GET_OPEN_QUOTES_COUNT_SUCCESS,
    payload: data,
  };
};

export const getOpenQuotesCountFail = (error) => {
  return {
    type: GET_OPEN_QUOTES_COUNT_FAIL,
    payload: error,
  };
};
