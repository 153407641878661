import {
  GET_UPDATE_VEHICLE,
  GET_UPDATE_VEHICLE_SUCCESS,
  GET_UPDATE_VEHICLE_FAIL,
} from "./actionTypes";


export const getUpdateVehicle = () => {
  return {
    type: GET_UPDATE_VEHICLE,
  };
};

export const getUpdateVehicleSuccess = (data) => {
  return {
    type: GET_UPDATE_VEHICLE_SUCCESS,
    payload: data,
  };
};

export const getUpdateVehicleFail = (error) => {
  return {
    type: GET_UPDATE_VEHICLE_FAIL,
    payload: error,
  };
};
