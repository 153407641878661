import React from "react";
import { carMakeData } from "./carMakeData";
import defaultMakeImage from "../../../../assets/images/default_make.png";
// Taken from here : https://github.com/avto-dev/vehicle-logotypes/blob/master/src/vehicle-logotypes.json

const CarMakeLogo = ({ make }) => {
  const makeURL =
    make && make !== "" && make.toLowerCase() in carMakeData
      ? carMakeData[make.toLowerCase()]?.logotype?.uri || ""
      : "";

  return (
    <div className="profile" style={{ verticalAlign: "middle" }}>
      <img
        src={`${makeURL || defaultMakeImage}`}
        style={{
          width: "100%",
          maxWidth: "36px",
        }}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = `${defaultMakeImage}`;
        }}
      />
    </div>
  );
};

export default CarMakeLogo;
