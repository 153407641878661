import React, { useState, useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { useDispatch, useSelector } from "react-redux";
import Resources from './Resources';
import {
    GET_TECHNICIAN_HOUR,
    GET_TECHNICIAN_SCHEDULE,
    GET_WORKSHOP_BAYS,
    CHECK_AVAILABLE_HOURS,
    GET_OPEN_REQUEST_JOB
} from "../../../store/scheduler/actionTypes";
import { GET_STATUS_COLOR, GET_LABOUR_CHARGES_LIST } from "../../../store/commonApiStore/actionTypes";
import { UPDATE_JOB_TIMER } from '../../../store/jobDetails/actionTypes';
import { GET_JOB_BOOKED_DETAILS } from "../../../store/jobDetails/actionTypes";
import { SAVE_JOB_LABOUR } from '../../../store/jobLabour/actionTypes';
import {
    utcToTimezone,
    convertToHours,
    timeDiffMinute,
    timeZoneToUTC,
    convertToMinute
} from "../../../utils/timeZoneConvert";
import { unserializeArr } from "../../../utils/unserialize";
import moment from 'moment';
import CreateJob from './CreateJob';
import JobTicket from './JobTicket';
import LoaderImage from "../../../assets/images/page-loader.svg";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Reschedule from './Reschedule';
const Calendar = ({ clickDate, monthView, handleUnassignedJobData, jobCreated }) => {

    let count = 0;
    const calendarRef = useRef(null);
    const weekendsVisible = true;
    const status = [{ status: "Pending", name: "Pending" }, { status: "Reschedule", name: "Rescheduled" }, { status: "In Progress", name: "InProgress" }, { status: "On Hold", name: "OnHold" }, { status: "Awaiting Parts", name: "WaitingForParts" }, { status: "Invoice Ready", name: "ReadyForInvoice" }];

    const storedData = JSON.parse(sessionStorage.getItem('stored_data'));
    let workshop_id = window.atob(storedData.workshop_id);
    let user_id = window.atob(storedData.user_id);
    let timezoneLable = window.atob(storedData.timezone_lable);
    let technician_timeslot = window.atob(storedData.technician_timeslot);
    let schedule_details = window.atob(storedData.schedule_details);
    const [monthJobCount, setMonthJobCount] = useState([]);
    const [countJob,setCountJob] = useState(0);
    const [billableHrs, setBillableHrs] = useState("00:00");
    const [availableHrs, setAvailableHrs] = useState("00:00");
    const [bookedHrs, setBookedHrs] = useState("00:00");
    const [technicianListData, setTechnicianListData] = useState([]);
    const [startTime, setStartTime] = useState("08:00:00");
    const [endTime, setEndTime] = useState("12:00:00");
    const [slotDuration, setSlotDuration] = useState("01:00:00");
    const [fromDate, setFromDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [toDate, setToDate] = useState();
    const [currentDay, setCurrentDay] = useState(new Date().getDay());
    const [workshopWorkingDay, setWorkshopWorkingDay] = useState([]);
    const [workshopOffDay, setWorkshopOffDay] = useState([]);
    const [dateRange, setDateRange] = useState([]);
    const [scheduleArr, setScheduleArr] = useState([]);
    const [currentJobData, setCurrentJobData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showMonthViewPopup, setMonthViewPopup] = useState(false)
    const setCloseModal = () => {
        setMonthViewPopup(false);
        setShowModal(false)
    };
    const [calendarView, setCalendarView] = useState("day");
    const [showTag, setShowTag] = useState(false);
    const [startJobTime, setStartJobTime] = useState();
    const [endJobTime, setEndJobTime] = useState();
    const [technicianName, setTechnicianName] = useState();
    const [technicianId, setTechnicianId] = useState();
    const [technicianType, setTechnicianType] = useState('technician');
    const [bayArray, setBayArray] = useState({});

    // const [countEvent, setCount] = useState(count)
    const [loading, setLoading] = useState(false)
    const [dispatchToCreate, setDispatchToCreate] = useState(false)
    const dispatch = useDispatch(); // Dispatch variable
    const [slotLimit, setSlotLimitShow] = useState(false);
    const handleSlotLimitClose = () => setSlotLimitShow(false);

    const [updateCalendar, setUpdateCalendar] = useState(false);
    const [updateSchedule, setUpdateSchedule] = useState(false);
    const [baysJobUpdate, setBaysJobUpdate] = useState(false);
    
    useEffect(() => {
        if(updateSchedule === true){
            setUpdateSchedule(false);
        }
    },[updateSchedule])

    useEffect(() => {
        if (updateCalendar === true) {
            setUpdateSchedule(true);
            if(calendarView==="month"){
                handleUnassignedJobData(!jobCreated)
            }
        }
    }, [updateCalendar])

    let workshopWorkingDayArr = [];
    let workshopOffDayArr = [];
    let jobDataArr = [];

    useEffect(() => {
        const fetchData = async () => {
            dispatch({
                type: GET_WORKSHOP_BAYS,
                payload: {
                    get: 'all',
                    conditions: {
                        workshop_id: workshop_id,
                        status: 1,
                        display_in_schedule: 1
                    },
                    fields: ['id', 'workshop_id', 'name', 'location']
                }
            });
        }
        fetchData()
            .catch(console.error)
    }, [dispatch, workshop_id]);

    useEffect(() => {
        const fetchData = async () => {
            dispatch({
                type: GET_STATUS_COLOR,
                payload: {
                    get: "all",
                    conditions: {
                        workshop_id: workshop_id,
                        status: 1,
                    },
                    fields: ["name", "color"],
                },
            })
            dispatch({
                type: GET_LABOUR_CHARGES_LIST,
                payload: {
                    "get": "all",
                    "conditions": {
                        "workshop_id": workshop_id,
                        "labour_code !=": "",
                        "is_default": "1",
                        "is_deleted": 0
                    }
                }
            })
        }
        fetchData().catch(console.error);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            // get technician list,bays list and technician and bays hours calculation
            dispatch({
                type: GET_TECHNICIAN_HOUR,
                payload: {
                    workshop_id: workshop_id,
                    from_date: fromDate + " " + startTime
                }
            });
        }
        fetchData()
            .catch(console.error)
    }, [fromDate, startTime, updateSchedule, jobCreated])

    useEffect(() => {
        const fetchData = async () => {
            dispatch({
                type: GET_JOB_BOOKED_DETAILS,
                payload: {
                    get: 'all',
                    conditions: {
                        'JobSchedules.workshop_id': workshop_id,
                        'JobSchedules.user_id': 0,
                        'JobSchedules.bay_id !=': 0,
                        'JobSchedules.from_date >=': fromDate,
                        'JobSchedules.to_date <': toDate
                    },
                    contain: {
                        Vehicles: {
                            fields: [
                                'id', 'workshop_id', 'user_id', 'make', 'model', 'year', 'rego', 'vin_number'
                            ]
                        },
                        WorkshopDepartmentsAccountsDetails: {
                            fields: [
                                'id', 'name', 'job_type_parent_id', 'workshop_id'
                            ]
                        },
                        JobBookingTypes: {
                            fields: [
                                'id', 'name', 'parent_id', 'workshop_id'
                            ]
                        }
                    },
                    fields: [
                        'id', 'user_id', 'workshop_id', 'vehicle_id', 'activity_schedule_id',
                        'activity_job_id', 'customer_quotes_id', 'job_number', 'customer_id',
                        'inspections_id', 'insurance_company_id', 'job_department_id',
                        'job_type_parent_id', 'job_stage', 'job_status', 'from_date',
                        'from_time', 'to_date', 'to_time', 'pickup_time', 'pickup_time_only',
                        'description', 'job_type', 'bay_id', 'job_number', 'timer_status',
                        'employees_details_id', 'created_by', 'is_copid_job', 'is_copy_job'
                    ]
                }
            })
        }
        fetchData()
            .catch(console.error)
    }, [fromDate, toDate, updateSchedule, jobCreated])

    useEffect(() => {
        const fetchData = async () => {
            dispatch({
                type: GET_TECHNICIAN_SCHEDULE,
                payload: {
                    get: 'all',
                    conditions: {
                        'Users.workshop_id': workshop_id,
                        'role_id IN': [3, 7, 8, 9],
                        is_deleted: 0
                    },
                    contain: {
                        EmployeesDetails: {
                            fields: [
                                'EmployeesDetails.id',
                                'EmployeesDetails.user_id',
                                'EmployeesDetails.shift_working_day'
                            ]
                        },
                        JobSchedules: {
                            conditions: {
                                'JobSchedules.from_date >=': fromDate,
                                'JobSchedules.to_date <': toDate
                            },
                            fields: [
                                'id',
                                'user_id',
                                'workshop_id',
                                'customer_id',
                                'job_status',
                                'job_stage',
                                'activity_schedule_id',
                                'activity_job_id',
                                'customer_quotes_id',
                                'vehicle_id',
                                'from_date',
                                'to_date',
                                'from_time',
                                'to_time',
                                'pickup_time',
                                'is_break_time',
                                'employees_details_id',
                                'job_department_id',
                                'job_number',
                                'job_type',
                                'pickup_time_only',
                                'is_copid_job',
                                'is_copy_job'
                            ]
                        },
                        'JobSchedules.WorkshopDepartmentsAccountsDetails': {
                            fields: ['id', 'workshop_id', 'name', 'job_type_parent_id']
                        },
                        'JobSchedules.VehiclesB': {
                            fields: ['id', 'rego', 'vin_number', 'make', 'model', 'year']
                        },
                        'JobSchedules.VehiclesB.Customers': {
                            fields: ['id']
                        },
                        'JobSchedules.VehiclesB.Customers.CustomersDetails': {
                            fields: [
                                'id',
                                'user_id',
                                'firstname',
                                'lastname',
                                'CustomersDetails.user_id',
                                'CustomersDetails.customer_app',
                                'CustomersDetails.phone_number',
                                'CustomersDetails.firstname',
                                'CustomersDetails.lastname',
                                'CustomersDetails.type',
                                'CustomersDetails.company_name'
                            ]
                        },
                        'JobSchedules.JobBookingTypes': {
                            fields: ['id', 'parent_id', 'name', 'workshop_id']
                        },
                        'JobSchedules.ActivitySchedule': {
                            fields: ['id', 'name', 'workshop_id']
                        },
                        'JobSchedules.ActivitySchedule.ActivityMaster': {
                            fields: ['id', 'name', 'type', 'workshop_id']
                        }
                    },
                    fields: [
                        'id', 'workshop_id', 'firstname', 'lastname', 'email', 'user_profile', 'phone_number'
                    ]
                }
            });
        }
        fetchData()
            .catch(console.error)
    }, [fromDate, toDate, jobCreated, updateSchedule])

    useEffect(() => {
        const fetchData = async () => {
            dispatch({
                type: GET_OPEN_REQUEST_JOB,
                payload: {
                    get: 'all',
                    conditions: {
                        'JobSchedules.workshop_id': workshop_id,
                        'JobSchedules.user_id': 0,
                        'JobSchedules.bay_id': 0,
                        'JobSchedules.from_date >=': fromDate,
                        'JobSchedules.to_date <': toDate,
                        'JobSchedules.job_status !=' : 'Unassigned'
                    },
                    contain: {
                        Vehicles: {
                            fields: [
                                'id', 'workshop_id', 'user_id', 'make', 'model', 'year', 'rego', 'vin_number'
                            ]
                        },
                        WorkshopDepartmentsAccountsDetails: {
                            fields: [
                                'id', 'name', 'job_type_parent_id', 'workshop_id'
                            ]
                        },
                        JobBookingTypes: {
                            fields: [
                                'id', 'name', 'parent_id', 'workshop_id'
                            ]
                        }
                    },
                    fields: [
                        'id', 'user_id', 'workshop_id', 'vehicle_id', 'activity_schedule_id',
                        'activity_job_id', 'customer_quotes_id', 'job_number', 'customer_id',
                        'inspections_id', 'insurance_company_id', 'job_department_id',
                        'job_type_parent_id', 'job_stage', 'job_status', 'from_date',
                        'from_time', 'to_date', 'to_time', 'pickup_time', 'pickup_time_only',
                        'description', 'job_type', 'bay_id', 'job_number', 'timer_status',
                        'employees_details_id', 'created_by', 'is_copid_job', 'is_copy_job'
                    ]
                }
            })
        }
        fetchData()
            .catch(console.error)
    }, [fromDate, toDate, updateSchedule, jobCreated])
    

    const schedulerData = useSelector((state) => state.SchedulerReducer);
    const bayJobData = useSelector((state) => state.JobDetails.jobBookedData);
    const jobUpdated = useSelector((state) => state.JobDetails.jobTimerData);
    const JobLabourData = useSelector((state) => state.JobLabourReducer.jobLabourList);
    const { statusColor, labourChargesList } = useSelector((state) => state.CommonApiStoreReducer);
    const workshopBaysDetails = schedulerData.workshopBaysDetails;
    const technicianHourData = schedulerData.technicianHourData;
    const jobsData = schedulerData.technicianData;
    const availableHours = schedulerData.checkAvailableHours;
    const openRequest = schedulerData.getOpenRequest;
    
    useEffect(() => {
        setCurrentDay(new Date(fromDate).getDay())
    }, [fromDate])

    useEffect(() => {
        if (schedule_details != null) {
            // TODO : Please fix this code (scheduleDetails declared 2 times)
            let scheduleDetails = unserializeArr(schedule_details);
            setScheduleArr(scheduleDetails);
            var timeSlot = technician_timeslot;
            scheduleDetails.map((schedule) => {
                if (schedule.status === "0" || schedule.status === 0) {
                    workshopOffDayArr.push(schedule.day);
                } else {
                    workshopWorkingDayArr.push(schedule.day);
                }
            })
            if (scheduleDetails[currentDay]['open_time'] !== '' && scheduleDetails[currentDay]['close_time'] !== '' && scheduleDetails[currentDay]['status'] !== '0') {
                setStartTime(utcToTimezone(scheduleDetails[currentDay]['open_time'], timezoneLable, "HH:mm:ss", 'HH:mm:ss'));
                setEndTime(utcToTimezone(scheduleDetails[currentDay]['close_time'], timezoneLable, "HH:mm:ss", 'HH:mm:ss'));
                if (endTime === "00:00:00") {
                    setEndTime("24:00:00");
                }
            }
            setSlotDuration(convertToHours(timeSlot));
            setWorkshopOffDay(workshopOffDayArr);
            setWorkshopWorkingDay(workshopWorkingDayArr);
        }
    }, [schedule_details, currentDay])

    useEffect(() => {
        if (technicianHourData != null) {
            setTechnicianListData(technicianHourData);
        }
    }, [technicianHourData])

    useEffect(() => {
        setLoading(true)
        var defaultKey = 0;
        let jobDetailsArr = [];
        var valueToPush = {};
        var valueToPushSec = {};
        var employeeWorkingDay = {};
        var totalAvailableEmployee = 0;
        var totalJobMinutes = 0;
        var assigneToPush = {};
        var openToPush = {};
        var defaultKeySec = 0;
        var workshopTotalMinutes = timeDiffMinute(startTime, endTime);
        var dateMonthArr = [];
        let monthViewCountArr = [];
        var countJob = 0;
        if (jobsData !== null && jobsData !== undefined) {
            setShowTag(true)
            setLoading(false)
            // setCount(0);
            // let workingOffDays = [];
            let workingDays = workshopWorkingDay;
            jobsData.map((job, index) => {
                let workingOffDays = [];
                workingOffDays.push(workshopOffDay);
                // workingOffDays = workshopWorkingDay;
                defaultKey = index + 1;
                employeeWorkingDay = {};
                if (job.employees_details[0] !== "" && job.employees_details[0] !== undefined && job.employees_details[0] !== null) {
                    if (job.employees_details[0].shift_working_day) {
                        employeeWorkingDay = unserializeArr(job.employees_details[0].shift_working_day);
                    }
                }

                for (var m = 0; m <= 6; m++) {
                    if (!employeeWorkingDay.includes(`${m}`) && workingDays.includes(m) && !workingOffDays.includes(m)) {
                        workingOffDays.push(m);
                    }
                }

                // For working days and changes timeslots every day in scheduler week view
                if (dateRange.length !== 2 && dateRange.length < 7) {
                    dateRange.map((dayDate, dateIndex) => {
                        if (dateIndex < 7) {
                            if (workshopWorkingDay.indexOf(new Date(dayDate).getDay()) > -1) {
                                if (employeeWorkingDay) {
                                    if (employeeWorkingDay.indexOf(`${new Date(dayDate).getDay()}`) > -1) {
                                        var scheduleData = scheduleArr[new Date(dayDate).getDay()]
                                        if (scheduleData['status'] == 1) {
                                            var startScheduleTime = utcToTimezone(scheduleData['open_time'], timezoneLable, "HH:mm:ss", 'HH:mm:ss');
                                            var endScheduleTime = utcToTimezone(scheduleData['close_time'], timezoneLable, "HH:mm:ss", 'HH:mm:ss');
                                            defaultKeySec = dateIndex + defaultKey + 1;
                                            valueToPushSec = {};
                                            valueToPushSec['id'] = 'off' + dateIndex + defaultKeySec;
                                            valueToPushSec['resourceId'] = job.id;
                                            valueToPushSec['start'] = dayDate + "T" + startScheduleTime;
                                            valueToPushSec['end'] = dayDate + "T" + endScheduleTime;
                                            valueToPushSec['daysOfWeek'] = workingOffDays; // workshop working days calculation left
                                            valueToPushSec['title'] = 'Off'
                                            valueToPushSec['tags'] = [];
                                            valueToPushSec['overlap'] = false;
                                            valueToPushSec['eventDisplay'] = 'background';
                                            valueToPushSec['allDaySlot'] = '2';
                                            valueToPushSec['color'] = '#e05151';
                                            valueToPushSec['className'] = ['noHover'];
                                            valueToPushSec['editable'] = false;
                                            jobDetailsArr.push(valueToPushSec);
                                            totalAvailableEmployee = totalAvailableEmployee + 1;
                                        }
                                    }
                                }
                            }
                        }
                    })
                } else {

                    valueToPush = {};
                    valueToPush['id'] = defaultKey;
                    valueToPush['resourceId'] = job.id;
                    valueToPush['eventColor'] = "#fd1010";
                    valueToPush['start'] = dateRange[0] + "T" + startTime;
                    valueToPush['end'] = dateRange[1] + "T" + endTime;
                    valueToPush['daysOfWeek'] = workingOffDays; // workshop working days calculation left
                    valueToPush['title'] = 'Off'
                    valueToPush['tags'] = [];
                    valueToPush['overlap'] = false;
                    valueToPush['eventDisplay'] = 'background';
                    valueToPush['allDaySlot'] = '2';
                    valueToPush['className'] = ['noHover'];
                    valueToPush['editable'] = false;
                    jobDetailsArr.push(valueToPush);
                    if (employeeWorkingDay) {
                        if (employeeWorkingDay.indexOf(`${new Date(fromDate).getDay()}`) > -1) {
                            totalAvailableEmployee = totalAvailableEmployee + 1;
                        }
                    }
                }

                // For job details array
                if (job.job_schedules.length !== 0) {
                    job.job_schedules.map((jobArr, jobIndex) => {
                        var jobStartDate = moment(new Date(jobArr.from_date)).format("YYYY-MM-DD");
                        var jobEndDate = moment(new Date(jobArr.to_date)).format("YYYY-MM-DD");
                        var jobStartTime = utcToTimezone(jobArr.from_time, timezoneLable, "", 'HH:mm:ss');
                        var jobEndTime = utcToTimezone(jobArr.to_time, timezoneLable, "", 'HH:mm:ss');
                        var minutes = timeDiffMinute(jobStartTime, jobEndTime);
                        var jobStartDateTime = moment(jobStartDate + " " + jobStartTime).format("YYYY-MM-DDTHH:mm:ss");
                        var jobEndDateTime = moment(jobEndDate + " " + jobEndTime).format("YYYY-MM-DDTHH:mm:ss");

                        totalJobMinutes = totalJobMinutes + minutes;

                        // if(dateMonthArr.includes(jobStartDate)){
                        //     var countNew = monthViewCountArr[jobStartDate]['count'] + 1;
                        //     monthViewCountArr[jobStartDate]['count'] = countNew;
                        // }else{
                        //     var monthViewCount = {};
                        //     monthViewCount['count'] = 1;
                        //     monthViewCount['day'] = jobStartDate;
                        //     monthViewCountArr[jobStartDate] = monthViewCount;
                        //     dateMonthArr.push(jobStartDate);
                        // }

                        if (jobArr.is_break_time !== 1) {
                            countJob = countJob+1;
                            if (jobArr.activity_schedule_id && jobArr.activity_schedule_id !== 0) {
                                // For jobs activity id
                                assigneToPush['editable'] = false;
                                if (jobArr.job_stage === "Bookin" && (jobArr.job_status === "Pending" || jobArr.job_status === "InProgress")) {
                                    assigneToPush['editable'] = true;
                                }
                                assigneToPush['id'] = `${jobArr.id}`;
                                assigneToPush['resourceId'] = jobArr.user_id;
                                assigneToPush['start'] = jobStartDateTime;
                                assigneToPush['strt_time'] = jobStartDateTime;
                                assigneToPush['end'] = jobEndDateTime;
                                assigneToPush['title'] = "";
                                var activityName = jobArr?.activity_schedule?.activity_master?.name || '';
                                if (activityName !== "") {
                                    assigneToPush['title'] = activityName;
                                }
                                assigneToPush['rendering'] = "";
                                assigneToPush['allDaySlot'] = 3;
                                assigneToPush['job'] = true;
                                assigneToPush['textColor'] = "#fff";
                                assigneToPush['background'] = "#000";
                                assigneToPush['overlap'] = false;
                                assigneToPush['statuses'] = '#47C9EA';
                                assigneToPush['tags'] = [jobArr?.activity_schedule?.name || ''];
                                assigneToPush['className'] = ["left-stripe-primary-black"];
                                jobDetailsArr.push(assigneToPush);
                                assigneToPush = {};
                            } else {

                                if (dateMonthArr.includes(jobStartDate)) {
                                    var countNew = monthViewCountArr[jobStartDate]['count'] + 1;
                                    monthViewCountArr[jobStartDate]['count'] = countNew;
                                } else {
                                    var monthViewCount = {};
                                    monthViewCount['count'] = 1;
                                    monthViewCount['day'] = jobStartDate;
                                    monthViewCountArr[jobStartDate] = monthViewCount;
                                    dateMonthArr.push(jobStartDate);
                                }

                                var pickupTime = utcToTimezone(new Date(jobArr.pickup_time), timezoneLable, "", 'HH:mm:ss');
                                var pickupTimeOnly = utcToTimezone(new Date(jobArr.pickup_time_only), timezoneLable, "", 'HH:mm:ss');

                                assigneToPush['id'] = `${jobArr.id}`;
                                assigneToPush['resourceId'] = jobArr.user_id;
                                assigneToPush['start'] = jobStartDateTime;
                                assigneToPush['strt_time'] = jobStartDateTime;
                                assigneToPush['end'] = jobEndDateTime;
                                assigneToPush['title'] = "";
                                if (jobArr.workshop_departments_accounts_detail) {
                                    assigneToPush['title'] = jobArr.workshop_departments_accounts_detail.name;
                                }
                                assigneToPush['rendering'] = "";
                                assigneToPush['allDaySlot'] = 0;
                                assigneToPush['job'] = true;
                                assigneToPush['textColor'] = "#fff";
                                assigneToPush['background'] = "#000";
                                assigneToPush['overlap'] = false;
                                assigneToPush['rego'] = jobArr.vehicles_b.rego;
                                assigneToPush['vin_no'] = "";
                                if (jobArr.vehicles_b.vin_number) {
                                    assigneToPush['vin_no'] = jobArr.vehicles_b.vin_number;
                                }
                                assigneToPush['make'] = jobArr.vehicles_b.make;
                                assigneToPush['model'] = jobArr.vehicles_b.model;
                                assigneToPush['year'] = jobArr.vehicles_b.year;
                                assigneToPush['customer_name'] = "";
                                if (jobArr?.vehicles_b?.customer?.customers_details?.[0]?.type || '' === "2") {
                                    assigneToPush['customer_name'] = jobArr.vehicles_b.customer.customers_details[0].firstname + " " + jobArr.vehicles_b.customer.customers_details[0].lastname;
                                } else if (jobArr?.vehicles_b?.customer?.customers_details?.[0]?.type || '' === "1") {
                                    assigneToPush['customer_name'] = jobArr.vehicles_b.customer.customers_details[0].company_name;
                                }
                                assigneToPush['customer_mobile'] = jobArr?.vehicles_b?.customer?.customers_details?.[0]?.mobile_number || '';
                                assigneToPush['job_number'] = jobArr.job_number;
                                assigneToPush['pickup_time'] = pickupTime;
                                assigneToPush['pickup_time_only'] = pickupTimeOnly;
                                assigneToPush['job_progress_status'] = jobArr.job_status;
                                assigneToPush['is_copid_job'] = jobArr.is_copid_job;
                                assigneToPush['is_copy_job'] = jobArr.is_copy_job;
                                assigneToPush['statuses'] = "";
                                if (jobArr.job_booking_type && jobArr.job_booking_type.name) {
                                    assigneToPush['tags'] = [jobArr.job_booking_type.name];
                                }
                                assigneToPush['className'] = ["0", "fc-none"];
                                assigneToPush['type'] = 0;
                                var customerApp = jobArr?.vehicles_b?.customer?.customers_details?.[0]?.customer_app || '';
                                if (customerApp && customerApp === 1) {
                                    assigneToPush['type'] = 1;
                                }
                                assigneToPush['editable'] = false;
                                if (jobArr.job_stage === "Bookin" && (jobArr.job_status === "Pending" || jobArr.job_status === "InProgress")) {
                                    assigneToPush['editable'] = true;
                                }
                                jobDetailsArr.push(assigneToPush);
                                assigneToPush = {};
                            }
                        }
                    })
                }
                // console.log(dateMonthArr,'dateMonthArr');
            })
        }
        if (workshopBaysDetails != null && workshopBaysDetails !== undefined) {
            workshopBaysDetails.map((bay, bayIndex) => {
                if (dateRange.length !== 2) {
                    dateRange.map((bayDayDate, bayDateIndex) => {
                        if (bayDateIndex < 7) {
                            var bayScheduleData = scheduleArr[new Date(bayDayDate).getDay()]
                            var defaultKeyBaySec = bayDateIndex + defaultKeySec + 1;
                            if (bayScheduleData['status'] == 1) {
                                var startBayScheduleTime = utcToTimezone(bayScheduleData['open_time'], timezoneLable, "HH:mm:ss", 'HH:mm:ss');
                                var endBayScheduleTime = utcToTimezone(bayScheduleData['close_time'], timezoneLable, "HH:mm:ss", 'HH:mm:ss');
                                valueToPushSec = {};
                                valueToPushSec['id'] = 'off' + bayDateIndex + defaultKeyBaySec;
                                valueToPushSec['resourceId'] = 'bay_' + bay.id;
                                valueToPushSec['start'] = bayDayDate + "T" + startBayScheduleTime;
                                valueToPushSec['end'] = bayDayDate + "T" + endBayScheduleTime;
                                valueToPushSec['daysOfWeek'] = ['[' + new Date(bayDayDate).getDay() + ']']; // workshop working days calculation left
                                valueToPushSec['title'] = 'Off'
                                valueToPushSec['tags'] = [];
                                valueToPushSec['overlap'] = false;
                                valueToPushSec['eventDisplay'] = 'background';
                                valueToPushSec['allDaySlot'] = '2';
                                valueToPushSec['color'] = '#e05151';
                                valueToPushSec['className'] = ['noHover'];
                                valueToPushSec['editable'] = false;
                                jobDetailsArr.push(valueToPushSec);
                            }
                        }
                    })
                }
            })
        }
        if (bayJobData !== null && bayJobData !== undefined) {
            bayJobData.map((bayjobArr, index) => {
                var bayJobStartDate = moment(new Date(bayjobArr.from_date)).format("YYYY-MM-DD");
                var bayJobEndDate = moment(new Date(bayjobArr.to_date)).format("YYYY-MM-DD");
                var bayJobStartTime = moment(bayjobArr.from_time).format("HH:mm:ss")
                var bayJobEndTime = moment(bayjobArr.to_time).format("HH:mm:ss");
                var bayMinutes = timeDiffMinute(bayJobStartTime, bayJobEndTime);
                var bayJobStartDateTime = moment(bayJobStartDate + " " + bayJobStartTime).format("YYYY-MM-DDTHH:mm:ss");
                var bayJobEndDateTime = moment(bayJobEndDate + " " + bayJobEndTime).format("YYYY-MM-DDTHH:mm:ss");

                var duration = convertToHours(bayMinutes);
                totalJobMinutes = totalJobMinutes + bayMinutes;

                var pickupTime = utcToTimezone(new Date(bayjobArr.pickup_time), timezoneLable, "", 'HH:mm:ss');
                var pickupTimeOnly = utcToTimezone(new Date(bayjobArr.pickup_time_only), timezoneLable, "", 'HH:mm:ss');

                if (dateMonthArr.includes(bayJobStartDate)) {
                    var countNew = monthViewCountArr[bayJobStartDate]['count'] + 1;
                    monthViewCountArr[bayJobStartDate]['count'] = countNew;
                } else {
                    var monthViewCount = {};
                    monthViewCount['count'] = 1;
                    monthViewCount['day'] = bayJobStartDate;
                    monthViewCountArr[bayJobStartDate] = monthViewCount;
                    dateMonthArr.push(bayJobStartDate);
                }

                assigneToPush['id'] = `${bayjobArr.id}`;
                assigneToPush['resourceId'] = bayjobArr.bay_id;
                assigneToPush['start'] = bayJobStartDateTime;
                assigneToPush['strt_time'] = bayJobStartDateTime;
                assigneToPush['end'] = bayJobEndDateTime;
                assigneToPush['title'] = "";
                if (bayjobArr.workshop_departments_accounts_detail) {
                    assigneToPush['title'] = bayjobArr.workshop_departments_accounts_detail.name;
                }
                assigneToPush['rendering'] = "";
                assigneToPush['allDaySlot'] = 4;
                assigneToPush['job_type'] = bayjobArr.job_type;
                assigneToPush['duration'] = duration;
                assigneToPush['description'] = bayjobArr.description;
                assigneToPush['req_type'] = "bay";
                assigneToPush['textColor'] = "#dbdbe1";
                assigneToPush['background'] = "#000";
                assigneToPush['overlap'] = false;
                assigneToPush['rego'] = bayjobArr.vehicle.rego;
                assigneToPush['vin_no'] = "";
                if (bayjobArr.vehicle.vin_number) {
                    assigneToPush['vin_no'] = bayjobArr.vehicle.vin_number;
                }
                assigneToPush['make'] = bayjobArr.vehicle.make;
                assigneToPush['model'] = bayjobArr.vehicle.model;
                assigneToPush['year'] = bayjobArr.vehicle.year;
                assigneToPush['customer_name'] = "";
                if (bayjobArr.vehicle.Users[0].customers_details[0]) {
                    assigneToPush['customer_mobile'] = bayjobArr.vehicle.Users[0].customers_details[0].mobile_number;
                    if (bayjobArr.vehicle.Users[0].customers_details[0].type === "2") {
                        assigneToPush['customer_name'] = bayjobArr.vehicle.Users[0].customers_details[0].firstname + " " + bayjobArr.vehicle.Users[0].customers_details[0].lastname;
                    } else if (bayjobArr.vehicle.Users[0].customers_details[0].type === "1") {
                        assigneToPush['customer_name'] = bayjobArr.vehicle.Users[0].customers_details[0].company_name;
                    }
                }
                assigneToPush['job_number'] = bayjobArr.job_number;
                assigneToPush['pickup_time'] = pickupTime;
                assigneToPush['pickup_time_only'] = pickupTimeOnly;
                assigneToPush['job_progress_status'] = bayjobArr.job_status;
                assigneToPush['is_copid_job'] = bayjobArr.is_copid_job;
                assigneToPush['is_copy_job'] = bayjobArr.is_copy_job;
                assigneToPush['statuses'] = "";
                if (bayjobArr.job_booking_type && bayjobArr.job_booking_type.name) {
                    assigneToPush['tags'] = [bayjobArr.job_booking_type.name];
                }
                assigneToPush['className'] = ["fc-custom-event","booked-job-event"];
                assigneToPush['editable'] = false;
                jobDetailsArr.push(assigneToPush);
                assigneToPush = {};
                countJob = countJob+1;
            })
            setBayArray(toFindDuplicates(jobDetailsArr))
        }

        if(openRequest !== null && openRequest !== undefined){
            openRequest.map((openArr,index) => {
                var openStartDate = moment(new Date(openArr.from_date)).format("YYYY-MM-DD");
                var openEndDate = moment(new Date(openArr.to_date)).format("YYYY-MM-DD");
                var openStartTime = moment(openArr.from_time).format("HH:mm:ss")
                var openEndTime = moment(openArr.to_time).format("HH:mm:ss");
                var openMinutes = timeDiffMinute(openStartTime, openEndTime);
                var openStartDateTime = moment(openStartDate + " " + openStartTime).format("YYYY-MM-DDTHH:mm:ss");
                var openEndDateTime = moment(openEndDate + " " + openEndTime).format("YYYY-MM-DDTHH:mm:ss");
                var duration = convertToHours(openMinutes);

                var pickupTime = utcToTimezone(new Date(openArr.pickup_time), timezoneLable, "", 'HH:mm:ss');
                var pickupTimeOnly = utcToTimezone(new Date(openArr.pickup_time_only), timezoneLable, "", 'HH:mm:ss');
                openToPush['id'] = `${openArr.id}`;
                openToPush['resourceId'] = openArr.bay_id;
                openToPush['start'] = openStartDateTime;
                openToPush['strt_time'] = openStartDateTime;
                openToPush['end'] = openEndDateTime;
                openToPush['title'] = "";
                if (openArr.workshop_departments_accounts_detail) {
                    openToPush['title'] = openArr.workshop_departments_accounts_detail.name;
                }
                openToPush['rendering'] = "";
                openToPush['allDaySlot'] = 4;
                openToPush['job_type'] = openArr.job_type;
                openToPush['duration'] = duration;
                openToPush['description'] = openArr.description;
                openToPush['req_type'] = "bay";
                openToPush['textColor'] = "#dbdbe1";
                openToPush['background'] = "#000";
                openToPush['overlap'] = false;
                openToPush['rego'] = openArr.vehicle.rego;
                openToPush['vin_no'] = "";
                if (openArr.vehicle.vin_number) {
                    openToPush['vin_no'] = openArr.vehicle.vin_number;
                }
                openToPush['make'] = openArr.vehicle.make;
                openToPush['model'] = openArr.vehicle.model;
                openToPush['year'] = openArr.vehicle.year;
                openToPush['customer_name'] = "";
                if (openArr.vehicle.Users[0].customers_details[0]) {
                    openToPush['customer_mobile'] = openArr.vehicle.Users[0].customers_details[0].mobile_number;
                    if (openArr.vehicle.Users[0].customers_details[0].type === "2") {
                        openToPush['customer_name'] = openArr.vehicle.Users[0].customers_details[0].firstname + " " + openArr.vehicle.Users[0].customers_details[0].lastname;
                    } else if (openArr.vehicle.Users[0].customers_details[0].type === "1") {
                        openToPush['customer_name'] = openArr.vehicle.Users[0].customers_details[0].company_name;
                    }
                }
                openToPush['job_number'] = openArr.job_number;
                openToPush['pickup_time'] = pickupTime;
                openToPush['pickup_time_only'] = pickupTimeOnly;
                openToPush['job_progress_status'] = openArr.job_status;
                openToPush['is_copid_job'] = openArr.is_copid_job;
                openToPush['is_copy_job'] = openArr.is_copy_job;
                openToPush['statuses'] = "";
                if (openArr.job_booking_type && openArr.job_booking_type.name) {
                    openToPush['tags'] = [openArr.job_booking_type.name];
                }
                openToPush['className'] = ["fc-custom-event"];
                openToPush['editable'] = false;
                jobDetailsArr.push(openToPush);
                openToPush = {};
                countJob = countJob+1;
            })
        }

        // For remove 1 hour default as a break time
        var techLunchBreak = totalAvailableEmployee * 60;

        //Total technician hours * Total Workshop hours = Available Workshop Hours
        var techTotalMin = totalAvailableEmployee * workshopTotalMinutes;

        //for workshop total minutes(Workshop Hours)
        var workshopMinutes = techTotalMin - techLunchBreak;

        //for Available workshop Hours(Available Hours)
        var availableMin = workshopMinutes - totalJobMinutes;

        var totalWorkshopHrs = convertToHours(workshopMinutes);
        var totalBookingHrs = convertToHours(totalJobMinutes);
        var totalAvailableHrs = convertToHours(availableMin);

        // console.log(jobDetailsArr, 'jobDetailsArr');
        setCountJob(countJob);
        setCurrentJobData(jobDetailsArr);
        setBillableHrs(totalWorkshopHrs);
        setAvailableHrs(totalAvailableHrs);
        setBookedHrs(totalBookingHrs)
        setMonthJobCount(monthViewCountArr);
        // console.log(monthViewCountArr, 'monthViewCountArr');
    }, [jobsData, bayJobData])

    //To Find Duplicate element
    function toFindDuplicates(arr) {
        const uniqObjs = [];
        let dupeObjs = [];
        let abcd = [];
        let eventArray = []
        arr.map(value => {
            if (value.req_type === "bay") {
                let ObjToPush = {};
                ObjToPush[`${moment(value.start).format('YYYY-MM-DD-HH:mm')}${value.resourceId}`] = value;
                dupeObjs.push(ObjToPush)
            }
        })

        eventArray = dupeObjs.reduce(function (acc, curr) {
            var key = Object.keys(curr)[0];
            var found = acc.filter(function (i) {
                return i[key];
            })[0];

            if (!found) {
                acc.push(curr);
            } else {
                found[key] = [].concat(found[key], curr[key]);
            }

            return acc;
        }, []);
        return {
            uniqueObj: uniqObjs,
            dupeObjs: abcd,
            newArray: eventArray
        }
    }

    useEffect(() => {
        setDateRange(getDaysArray(fromDate, toDate));
    }, [fromDate, toDate, updateSchedule, jobCreated])

    //Drag External Jobs 
    useEffect(() => {
        let draggableEl = document.getElementById("unassigned-list");
        console.log(draggableEl,'draggableEl');
        new Draggable(draggableEl, {
            itemSelector: ".job-tiles",
            eventData: function (eventEl) {
                let data = eventEl.getAttribute("data-value");
                let id = eventEl.getAttribute("data");
                return {
                    _id: id,
                    title: `Rego | ${JSON.parse(data).vehicle.rego}`,
                    data: JSON.parse(data)
                };
            }
        });

        let draggableElCarryOver = document.getElementById("carry-over-jobs");
        new Draggable(draggableElCarryOver, {
            itemSelector: ".job-tiles",
            eventData: function (eventEl) {
                let data = eventEl.getAttribute("data-value");
                let id = eventEl.getAttribute("data");
                return {
                    _id: id,
                    title: `Rego | ${JSON.parse(data).vehicle.rego}`,
                    data: JSON.parse(data)
                };
            }
        });
    }, [])
    

    const [rescheduleShow, setRescheduleShow] = useState(false);
    const handleRescheduleClose = () => {
        setRescheduleShow(false);
    }
    
    const [startUnassignDate,setStartUnassignDate] = useState();
    const [unassignJobNumber,setUnassignJobNumber] = useState();
    const [unassignJobId,setUnassignJobId] = useState();
    
    const eventReceive = ({ event }) => {
        
        let duration = convertToMinute(slotDuration);
        if(calendarView === "month"){
            var jobNumber = event?._def?.extendedProps?.data?.job_number || 0;
            var jobId = event?._def?.extendedProps?.data?.id || 0;
            var startDate = timeZoneToUTC(event.start, timezoneLable, "YYYY-MM-DD", "YYYY-MM-DD");
            setStartUnassignDate(startDate);
            setUnassignJobNumber(jobNumber);
            setUnassignJobId(jobId);
            setRescheduleShow(true);
        }else {
            let start_time = timeZoneToUTC(moment(event.startStr).format('HH:mm:ss'), timezoneLable, "HH:mm:ss", "HH:mm:ss");
            let end_time = moment(start_time, 'HH:mm:ss').add(duration, 'minutes').format('HH:mm:ss');
            var userId = 0;
            var bayId = 0;
            // var usersIdNew = event._def.extendedProps._id;
            var typeTech = event?.getResources()?.[0]?._resource?.extendedProps?.user_profile || 'user';
            if (typeTech === "bay") {
                userId = 0;
                bayId = event?.getResources()?.[0]?._resource?.id || 0;
            } else {
                userId = event?.getResources()?.[0]?._resource?.id || 0;
                bayId = 0;
            }
            if(event._def.extendedProps.data.req_type === "bay"){
                dispatch({
                    type: UPDATE_JOB_TIMER,
                    payload: {
                        "workshop_id": workshop_id,
                        "created_by":user_id,
                        "user_id": userId, 
                        "bay_id":bayId,
                        "from_date": timeZoneToUTC(event.start, timezoneLable, "YYYY-MM-DD", "YYYY-MM-DD"),
                        "to_date": timeZoneToUTC(event.start, timezoneLable, "YYYY-MM-DD", "YYYY-MM-DD"),
                        "from_time": start_time,
                        "to_time": end_time,
                        "duration": duration,
                        "id": event.extendedProps.data.id
                    }
                })
                setBaysJobUpdate(true);
            }else{
                dispatch({
                    type: UPDATE_JOB_TIMER,
                    payload: {
                        "workshop_id": workshop_id,
                        "created_by":user_id,
                        "user_id": userId, 
                        "bay_id":bayId,
                        "from_date": timeZoneToUTC(event.start, timezoneLable, "YYYY-MM-DD", "YYYY-MM-DD"),
                        "to_date": timeZoneToUTC(event.start, timezoneLable, "YYYY-MM-DD", "YYYY-MM-DD"),
                        "from_time": start_time,
                        "to_time": end_time,
                        "duration": duration,
                        "id": event.extendedProps.data.id,
                        "pickup_time_only": "17:00",
                        "pickup_time": timeZoneToUTC(new Date(), timezoneLable, "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm:ss"),
                        "tags_id": "[]",
                        "job_status": "Pending",
                        "job_stage": "Bookin",
                        "insp_reference_id": 0,
                        "bookin_source": 2,
                        "source_of_business": "Existing",
                        "labour_time": 0,
                        "parts_cost": 0,
                        "labour_cost": 0,
                        "parts_markup": 0,
                        "labour_markup": 0,
                        "parts_total_cost": 0,
                        "labour_total_cost": 0,
                        "sub_total": 0,
                        "gst": 0,
                        "total": 0,
                        "discount_rate": 0,
                    }
                })
                setDispatchToCreate(true)
            }
        }
    }
    // Add labour once  unassigned to assign job 
    useEffect(() => {
        if (jobUpdated && jobUpdated.JobSchedules && dispatchToCreate === true) {
            if(labourChargesList !== null && labourChargesList !== undefined ){
                let objToSend = {
                    labour_charges_id: labourChargesList[0].id,
                    job_schedules_id: jobUpdated.JobSchedules.id,
                    cost_price: labourChargesList[0].cost_price,
                    margin: labourChargesList[0].margin,
                    sell_price: labourChargesList[0].sell_price,
                    retail_price: labourChargesList[0].retail_price,
                    profit: labourChargesList[0].profit_price,
                    labour_description: labourChargesList[0].description,
                    total_price: 0
                }
                dispatch({
                    type: SAVE_JOB_LABOUR,
                    payload: objToSend
                })
                handleUnassignedJobData(!jobCreated)
                setDispatchToCreate(false)
            }
        }else{
            if (jobUpdated && jobUpdated.JobSchedules !== null && baysJobUpdate === true) {
                setUpdateSchedule(true);
                setBaysJobUpdate(false);
            }
        }
    }, [jobUpdated])

    // Get DateRange between two dates in (Y-m-d) format
    let getDaysArray = function (s, e) {
        for (var a = [], d = new Date(s); d <= new Date(e); d.setDate(d.getDate() + 1)) {
            a.push(moment(new Date(d)).format("YYYY-MM-DD"));
        }
        return a;
    };

    const eventAfterAllRender = (info) => {
        if (info.view.type === "resourceTimelineDay") {

        } else if (info.view.type === "resourceTimelineWeek") {
            // console.log((info.el))
        } else if (info.view.type === "dayGridMonth") {

        }
    };

    const handleDateSelect = (selectInfo) => {
        if (selectInfo.resource.id !== 'z') {
            setStartJobTime(selectInfo.startStr);
            setEndJobTime(selectInfo.endStr);
            setTechnicianName(selectInfo.resource.title);
            setTechnicianId(selectInfo.resource.id);
            if (selectInfo.resource.extendedProps.user_profile === "bay") {
                setTechnicianType("bay");
            } else {
                setTechnicianType("technician");
            }
            var avilableData = moment(new Date(selectInfo.startStr)).format("YYYY-MM-DD");

            dispatch({
                type: CHECK_AVAILABLE_HOURS,
                payload: {
                    date: avilableData,
                    workshop_id: workshop_id
                }
            });

            setShowModal(true);
            setSlotLimitShow(true)
        }
    }

    useEffect(() => {
        document.getElementsByClassName('fc-unassignedButton-button')[0].setAttribute('data-after-content', '→');
        document.getElementsByClassName('fc-unassignedButton-button')[0].setAttribute('data-before-content', '');
        if (showTag) {
            setTimeout(() => {
                let headerLength = document.getElementsByClassName('fc-timeline-header-row')[0] && document.getElementsByClassName('fc-timeline-header-row')[0].childNodes.length;
                let totalRows = document.getElementsByClassName('fc-timeline-events fc-scrollgrid-sync-inner')
                let width = document.getElementsByClassName('fc-timeline-slot-frame')[0] && document.getElementsByClassName('fc-timeline-slot-frame')[0].offsetWidth;
                let left = 0;
                for (var i = 0; i < headerLength; i++) {
                    let tag = document.createElement('a');
                    tag.classList.add('row-event');
                    tag.style.width = `${width}px`;
                    tag.style.height = "100%";
                    tag.style.left = `${left}px`;
                    left = left + width;
                    for (var j = 0; j < (totalRows.length - 1); j++) {
                        document.getElementsByClassName('fc-timeline-events fc-scrollgrid-sync-inner')[j].setAttribute('id', `${j}-row`)
                        document.getElementById(`${j}-row`).appendChild(tag.cloneNode());
                    }
                }
                setShowTag(false);
            }, 1000)
        }
    }, [showTag])

    const renderEventContent = (eventInfo) => {
        
        return (
            <>
                <JobTicket 
                    eventInfo={eventInfo}
                    dupeObjs={bayArray.dupeObjs} 
                    view={eventInfo.view.type} 
                    workshop_id={workshop_id} 
                    eventArray={bayArray.newArray} 
                    statusColor={statusColor} 
                    setUpdateCalendar={setUpdateCalendar} 
                    slotDuration={slotDuration}
                    technicianName={technicianName} 
                />
            </>
        )
    }
    const renderMonthEvent = (eventInfo) => {return (<></>)}

    //Called when event is dragged or changed
    const handleEventChange = (data) => {
        let workshopId = JSON.parse(localStorage.getItem('data')).workshop_id;
        let fromDate = timeZoneToUTC(data.event.start, timezoneLable, "YYYY-MM-DD", "YYYY-MM-DD");
        let toDate = timeZoneToUTC(data.event.end, timezoneLable, "YYYY-MM-DD", "YYYY-MM-DD");
        let fromTime = timeZoneToUTC(data.event.start, timezoneLable, "HH:mm:ss", "HH:mm:ss");
        let toTime = timeZoneToUTC(data.event.end, timezoneLable, "HH:mm:ss", "HH:mm:ss");
        let duration = new Date(data.event.start) - new Date(data.event.end);
        let userId = data.event["_def"].resourceIds[0];
        let status = data.event.extendedProps.job_progress_status;
        let jobId = data.event["_def"].publicId;
        let objToSend = {
            workshop_id: workshopId,
            from_date: fromDate,
            to_date: toDate,
            from_time: fromTime,
            to_time: toTime,
            activity_schedule_id: 0,
            duration: convertToMinute(Math.abs(duration)),
            job_status: status,
            id: jobId
        }
        // user_id: userId,
        if (data.event.extendedProps.req_type && data.event.extendedProps.req_type === "bay") {
            objToSend.bay_id = userId;
            objToSend.user_id = 0;
        } else {
            objToSend.user_id = userId;
            objToSend.bay_id = 0;
        }
        dispatch({
            type: UPDATE_JOB_TIMER,
            payload: objToSend
        });
        setDispatchToCreate(false);
    }

    const getJobList = (jobdate) => {
        clickDate(jobdate);
        monthView(calendarView);
        var elem = document.getElementById("list-table");
        if (elem !== null) {
            elem.scrollIntoView();
        }
    }

    const handleMonthViewPopup = (flag, date) => {
        setStartJobTime(date)
        setShowModal(true)
        setMonthViewPopup(true)
    }

    useEffect(() => {

    },[jobCreated])
    return (
        <>
            {
                loading ? <div className="page-loader d-flex align-center justify-center">
                    <img src={LoaderImage} alt="Loading"></img>
                </div> : ""
            }
            {
                (availableHours !== null || showMonthViewPopup) ?
                    ((showModal && availableHours === true) || showMonthViewPopup) ?
                        <CreateJob 
                            show={showModal} 
                            showMonthViewPopup={showMonthViewPopup} 
                            close={setCloseModal} 
                            slotDuration={slotDuration} 
                            technicianName={technicianName} 
                            startTimeCalendar={startJobTime} 
                            endTimeCalendar={endJobTime} 
                            technicianId={technicianId} 
                            technicianType={technicianType} 
                            setUpdateCalendar={setUpdateCalendar}
                        ></CreateJob>
                        :
                        (availableHours === false) ?
                            <Modal
                                className="info-dialog"
                                size="sm"
                                show={slotLimit}
                                onHide={handleSlotLimitClose}
                                centered
                            >
                                <div className="heading-block d-flex justify-center">
                                    <i className="la la-exclamation-circle info-icon" aria-hidden="true"></i>
                                </div>
                                <Modal.Body>
                                    <div>
                                        <h2 className="mb-4">Sorry..Daily Limit Exceed!</h2>
                                    </div>
                                    <div className="d-flex justify-center">
                                        <Button
                                            variant="primary"
                                            className="btn-primary rounded-btn"
                                            onClick={() => handleSlotLimitClose()}
                                        >
                                            Ok
                                        </Button>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            : ''
                    : ""
            }
            {
                (rescheduleShow === true) ? 
                    <Reschedule 
                        rescheduleShow={rescheduleShow}
                        handleRescheduleClose={handleRescheduleClose}
                        jobIdData={unassignJobId}
                        startEventTime={startUnassignDate}
                        technicianName=""
                        jobNumber={unassignJobNumber}
                        setUpdateCalendar={setUpdateCalendar}
                        jobType="Unassigned"
                    ></Reschedule> : ''
            }
            <FullCalendar
                // timeZone='UTC'
                ref={calendarRef}
                schedulerLicenseKey={'GPL-My-Project-Is-Open-Source'}
                plugins={[resourceTimelinePlugin, dayGridPlugin, timeGridPlugin, interactionPlugin]}
                customButtons={{
                    unassignedButton: {
                        text: 'Unassigned Jobs',
                        click: function () {
                            let html;
                            document.getElementsByClassName('fc-unassignedButton-button')[0].setAttribute('id', 'unassigned-job-btn');
                            if (document.querySelector(".schedular-outer").classList.toggle('display-right-col')) {
                                html = '<div style="border:1px solid white; border-radius:100%;padding:3px;">UN</div>';
                                document.getElementById('unassigned-job-btn').innerHTML = html;
                                document.getElementsByClassName('fc-unassignedButton-button')[0].setAttribute('data-after-content', '');
                                document.getElementsByClassName('fc-unassignedButton-button')[0].setAttribute('data-before-content', '←');
                            } else {
                                html = 'Unassigned Jobs'
                                document.getElementById('unassigned-job-btn').innerHTML = html;
                                document.getElementsByClassName('fc-unassignedButton-button')[0].setAttribute('data-after-content', '→');
                                document.getElementsByClassName('fc-unassignedButton-button')[0].setAttribute('data-before-content', '');
                            }
                        },
                    },
                }}
                // locale="fr"
                // loading = {loading}
                eventDidMount={eventAfterAllRender}
                headerToolbar={{
                    right: 'unassignedButton',
                    center: 'prev, title ,next',
                    left: 'resourceTimelineDay,resourceTimelineWeek,dayGridMonth'
                }}
                // date={this.state.selectedDay}
                // onNavigate={date => {
                //     this.setState({ selectedDate: date });
                //   }}
                viewClassNames={
                    (arg) => {
                        // console.log('viewClassNames', arg.view.type);
                    }
                }
                viewDidMount={
                    (arg) => {
                        if (arg.view.type === "resourceTimelineWeek") {
                            setCalendarView("week")
                            monthView("week")
                            document.getElementById('right-col').style.flex = '0 0 17%';
                            document.querySelector(".schedular-outer").classList.remove('display-right-col')
                            document.getElementsByClassName('fc-unassignedButton-button')[0].style.visibility = 'visible';
                        } else if (arg.view.type === "dayGridMonth") {
                            setCalendarView('month')
                            monthView("month")
                            document.getElementById('right-col').style.flex = '0 0 30%';
                            document.querySelector(".schedular-outer").classList.add('display-right-col');
                            document.getElementsByClassName('fc-unassignedButton-button')[0].style.visibility = 'hidden';
                        } else {
                            monthView("day")
                            document.getElementById('right-col').style.flex = '0 0 17%';
                            document.querySelector(".schedular-outer").classList.remove('display-right-col')
                            document.getElementsByClassName('fc-unassignedButton-button')[0].style.visibility = 'visible';
                        }
                    }
                }
                viewWillUnmount={
                    (arg) => {
                        // console.log('viewWillUnmount', arg.view.type);
                    }
                }
                dayCellContent={(info) => {
                    let highlight = false;
                    if (info.isToday === true) {
                        highlight = true
                    } else {
                        highlight = false;
                    }
                    var jobCounter = monthJobCount?.[moment(new Date(info.date)).format("YYYY-MM-DD")]?.['count'] || null;
                    return (
                        <>
                            <div className="main-content">
                                <span className={`${highlight ? "active-date" : ""}`}>{new Date(info.date).getDate()}</span>
                                <div className="inner-div">
                                    <span onClick={() => handleMonthViewPopup(true, moment(new Date(info.date)).format("YYYY-MM-DD"))}></span>
                                </div>
                                {
                                    (jobCounter !== null) ? <div className="inner-in-div" onClick={() => getJobList(moment(new Date(info.date)).format("YYYY-MM-DD"))}> <div>{jobCounter}</div></div> : ''
                                    // (jobCounter !== null) ? <div className="inner-in-div" onClick={getJobList(moment(new Date(info.date)).format("YYYY-MM-DD"))}> <div>{jobCounter}</div></div> : ''
                                }
                            </div>
                        </>
                    )
                }}
                // dayCellDidMount={
                //     (info) => {
                //         // let element = "<div style='position: absolute; left: 4px; top: 4px;'><a href='https://www.w3schools.com/'>TEST-"+info.dayNumberText+"</a></div>";
                //         // $('#fc-day-span-'+info.date.getDayOfYear()).parent().parent().prepend(element);
                //         // console.log(info,'infor');
                //     }
                // }
                // dayCellWillUnmount={
                //     (info) => {
                //         // console.log(info,'will ')
                //     }
                // }
                navLinkDayClick={(info) => {
                    // console.log(info,'infor');
                }
                }
                slotMinTime={startTime}
                slotMaxTime={endTime}
                slotDuration={slotDuration}
                displayEventTime={true}
                firstDay={1}
                nowIndicator={true}
                initialView="resourceTimelineDay"
                // defaultView="resourceTimelineDay"
                // aspectRatio={1.8}
                droppable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                weekends={weekendsVisible}
                // weekNumbersWithinDays = {true}
                // width="auto"
                height="auto"
                datesSet={(dateInfo) => {
                    setFromDate(moment(new Date(dateInfo.start)).format("YYYY-MM-DD"))//start of the range the calendar date
                    setToDate(moment(new Date(dateInfo.end)).format("YYYY-MM-DD"))//end of the range the calendar date
                }}
                resourceAreaHeaderContent={(info) => {
                    return (
                        <div className="d-flex align-center justify-center no-wrap">
                            <div className="time-zone d-flex align-center no-wrap">
                                <div className="time-slot">
                                    <p className="time">{billableHrs} Hrs</p>
                                    <p>Billable</p>
                                </div>
                                <div className="time-slot">
                                    <p className="time">{bookedHrs} Hrs</p>
                                    <p>Booked</p>
                                </div>
                                <div className="time-slot">
                                    <p className="time">{availableHrs} Hrs</p>
                                    <p>Available</p>
                                </div>
                            </div>
                            <span className="count d-flex align-center justify-center">{countJob}</span>
                        </div>
                    )
                }
                }
                views={{
                    day: {
                        titleFormat: { weekday: 'short', day: 'numeric', month: 'long', year: 'numeric' },
                        // dayMaxEvents: 1
                    },
                    month: {
                        // dayMaxEvents: 1
                    }
                }}
                initialEvents={[]} // alternatively, use the `events` setting to fetch from a feed
                navLinks={true} // can click day/week names to navigate views
                // businessHours={true} // display business hours
                events={currentJobData}
                eventMouseEnter={({ el }) => {
                    el.parentElement.style.zIndex = "9"
                }}
                eventMouseLeave={({ el }) => {
                    el.parentElement.style.zIndex = "2"
                }}
                resourceOrder={'sortOrder'}
                resources={technicianListData}
                resourceLabelContent={Resources}
                fixedWeekCount={false}
                selectOverlap={false}
                select={handleDateSelect}
                // selectConstraint={'businessHours'}
                // eventConstraint={'businessHours'}
                // eventContent={calendarView === "month" ? renderMonthEvent : renderEventContent} // custom render function
                eventContent={renderEventContent} // custom render function
                eventClick={[]}
                eventsSet={[]} // called after events are initialized/added/changed/removed
                eventAdd={function () { }}
                eventChange={handleEventChange}
                eventRemove={function () { }}
                eventReceive={eventReceive}
            />
            <div className="px-5 mt-3 d-flex justify-content-center">
                {status && status.map((value, index) => (
                    <div className="d-flex flex-row align-center me-3">
                        <div className="dot-status me-2 rounded-circle" style={{ background: statusColor && statusColor[value.name] }}></div>
                        <span>{value.status}</span>
                    </div>
                ))}
            </div>
        </>
    )
}

export default Calendar;