import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';

const InvoicePreview = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editorState) => {
    let draftToHtmlData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setEditorState(editorState)
};
  const jobOptions = [
    { value: "1", label: "Michelle Burch" },
    { value: "2", label: "Ricky Lee" },
    { value: "3", label: "Darrel Skinner" },
    { value: "4", label: "Donald Lee" },
    { value: "5", label: "Pujan Shah" },
  ];

  return (
    <>
      <div className="right-section-wrapper">
              <div className="invoice-wrapper">
                <div className="invoice-heading d-flex justify-space-between align-center">
                  <h3>Quote <span className="quote-job-number">#201</span></h3>
                  <div className="btn-wrapper">
                    <Button
                      variant="outline-secondary"
                      className="btn-outline rounded-btn"
                    >
                      Cancel
                    </Button>
                    <Button type="button" variant="danger" className="btn-danger rounded-btn height-34 font-size-14">Delete</Button>
                    <Button
                      className="rounded-btn btn-success"
                      variant="success">
                      Send Email
                    </Button>
                  </div>
                </div>
                <div className="invoice-content">
                  <div className="form-outer form-align-start">
                    <Form.Group className="form-group width-50 mb-3">
                      <label>Form Type</label>
                      <Form.Control type="text" />
                    </Form.Group>
                    <div className="form-group width-50">
                      <div className="multi-select-wrapper">
                        <label>To</label>
                        <CreatableSelect
                            defaultValue={[]}
                            isMulti
                            name="jobTags"
                            options={jobOptions}
                            className="basic-multi-select"
                            classNamePrefix="select"
                        />
                        <p>EX : For multiple user email test@fiiviq.com,test2@fiiviq.com</p>
                      </div>
                    </div>
                    <div className="select-wrapper width-50 mb-3">
                      <Form.Group className="form-group mb-3">
                        <label>Script</label>
                        <Select
                          className="form-control custom-select"
                          required
                          name="country"
                          id="country"
                          options={jobOptions}
                        />
                      </Form.Group>
                    </div>
                    <div className="form-group">
                      <label>Description</label>
                        <Editor
                            name="script_description"
                            id="script_description"
                            editorState={editorState}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                        />
                      </div>
                  </div>         
                </div>
              </div>
              <div className="quote-preview d-flex">
                <div className="left-block">
                  <h2>Quote Preview</h2>
                  <h4>Display Part Number</h4>
                    <div className="radio-wrapper tab-radio-btn yes-no-radio">
                    <Form.Check className="radio-btn mb-2" type="radio" id="no1">
                      <Form.Check.Input name="selectPart" type="radio" />
                      <Form.Check.Label>
                        No
                        <span className="check"></span>
                      </Form.Check.Label>
                    </Form.Check>
                    <Form.Check className="radio-btn mb-2" type="radio" id="yes1">
                      <Form.Check.Input
                        defaultChecked
                        name="selectPart"
                        type="radio"
                      />
                      <Form.Check.Label>
                        Yes
                        <span className="check"></span>
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                </div>
              </div>
            </div>
    </>
  );
};

export default InvoicePreview;
