export const SAVE_QUOTE_PART = "SAVE_QUOTE_PART";
export const SAVE_QUOTE_PART_SUCCESS = "SAVE_QUOTE_PART_SUCCESS";
export const SAVE_QUOTE_PART_FAIL = "SAVE_QUOTE_PART_FAIL";

export const GET_QUOTE_PART_LIST = "GET_QUOTE_PART_LIST";
export const GET_QUOTE_PART_LIST_SUCCESS = "GET_QUOTE_PART_LIST_SUCCESS";
export const GET_QUOTE_PART_LIST_FAIL = "GET_QUOTE_PART_LIST_FAIL";

export const DELETE_QUOTE_PART = "DELETE_QUOTE_PART";
export const DELETE_QUOTE_PART_SUCCESS = "DELETE_QUOTE_PART_SUCCESS";
export const DELETE_QUOTE_PART_FAIL = "DELETE_QUOTE_PART_FAIL";

export const UPDATE_QUOTE_PART = "UPDATE_QUOTE_PART";
export const UPDATE_QUOTE_PART_SUCCESS = "UPDATE_QUOTE_PART_SUCCESS";
export const UPDATE_QUOTE_PART_FAIL = "UPDATE_QUOTE_PART_FAIL";

export const INLINE_UPDATE_QUOTE_PART = "INLINE_UPDATE_QUOTE_PART";
export const INLINE_UPDATE_QUOTE_PART_SUCCESS = "INLINE_UPDATE_QUOTE_PART_SUCCESS";
export const INLINE_UPDATE_QUOTE_PART_FAIL = "INLINE_UPDATE_QUOTE_PART_FAIL";

export const EDIT_QUOTE_PART_MANY = "EDIT_QUOTE_PART_MANY";
export const EDIT_QUOTE_PART_MANY_SUCCESS = "EDIT_QUOTE_PART_MANY_SUCCESS";
export const EDIT_QUOTE_PART_MANY_FAIL = "EDIT_QUOTE_PART_MANY_FAIL";

export const GET_QUOTE_KIT_PART = "GET_QUOTE_KIT_PART";
export const GET_QUOTE_KIT_PART_SUCCESS = "GET_QUOTE_KIT_PART_SUCCESS";
export const GET_QUOTE_KIT_PART_FAIL = "GET_QUOTE_KIT_PART_FAIL";

export const GET_QUOTE_KIT_LABOUR = "GET_QUOTE_KIT_LABOUR";
export const GET_QUOTE_KIT_LABOUR_SUCCESS = "GET_QUOTE_KIT_LABOUR_SUCCESS";
export const GET_QUOTE_KIT_LABOUR_FAIL = "GET_QUOTE_KIT_LABOUR_FAIL";

export const SAVE_QUOTE_KIT_PART = "SAVE_QUOTE_KIT_PART";
export const SAVE_QUOTE_KIT_PART_SUCCESS = "SAVE_QUOTE_KIT_PART_SUCCESS";
export const SAVE_QUOTE_KIT_PART_FAIL = "SAVE_QUOTE_KIT_PART_FAIL";

export const DELETE_QUOTE_KIT_PART = "DELETE_QUOTE_KIT_PART";
export const DELETE_QUOTE_KIT_PART_SUCCESS = "DELETE_QUOTE_KIT_PART_SUCCESS";
export const DELETE_QUOTE_KIT_PART_FAIL = "DELETE_QUOTE_KIT_PART_FAIL";

export const INLINE_UPDATE_QUOTE_KIT_PART = "INLINE_UPDATE_QUOTE_KIT_PART";
export const INLINE_UPDATE_QUOTE_KIT_PART_SUCCESS = "INLINE_UPDATE_QUOTE_KIT_PART_SUCCESS";
export const INLINE_UPDATE_QUOTE_KIT_PART_FAIL = "INLINE_UPDATE_QUOTE_KIT_PART_FAIL";

export const EDIT_QUOTE_KIT_PART_MANY = "EDIT_QUOTE_KIT_PART_MANY";
export const EDIT_QUOTE_KIT_PART_MANY_SUCCESS = "EDIT_QUOTE_KIT_PART_MANY_SUCCESS";
export const EDIT_QUOTE_KIT_PART_MANY_FAIL = "EDIT_QUOTE_KIT_PART_MANY_FAIL";

export const EDIT_QUOTE_KIT_LABOUR_MANY = "EDIT_QUOTE_KIT_LABOUR_MANY";
export const EDIT_QUOTE_KIT_LABOUR_MANY_SUCCESS = "EDIT_QUOTE_KIT_LABOUR_MANY_SUCCESS";
export const EDIT_QUOTE_KIT_LABOUR_MANY_FAIL = "EDIT_QUOTE_KIT_LABOUR_MANY_FAIL";

export const INLINE_UPDATE_QUOTE_KIT_LABOUR = "INLINE_UPDATE_QUOTE_KIT_LABOUR";
export const INLINE_UPDATE_QUOTE_KIT_LABOUR_SUCCESS = "INLINE_UPDATE_QUOTE_KIT_LABOUR_SUCCESS";
export const INLINE_UPDATE_QUOTE_KIT_LABOUR_FAIL = "INLINE_UPDATE_QUOTE_KIT_LABOUR_FAIL";