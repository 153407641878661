import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
// import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
// import ToggleButton from "react-bootstrap/ToggleButton";
import { useDispatch, useSelector } from "react-redux";
import LoaderImage from "../../../../../../src/assets/images/page-loader.svg";
import {
  allowNumber,
  commaSeparateNumber,
  precise_round,
  textPercSuffix,
} from "../../../../../utils/numberFunction";
import {
  SAVE_LABOUR_CHARGE,
  UPDATE_LABOUR_CHARGE_DETAILS,
} from "../../../../../store/defaultSettings/actionTypes";
import { isNumber } from "underscore";
import { InputGroup } from "react-bootstrap";

const LabourChargeForm = () => {
  const dispatch = useDispatch(); // Dispatch variable
  const { formLoading, success, chargeDetails } = useSelector(
    (state) => state.defaultSettingsReducer
  ); // GET Form loader from Company Detail Reducer data

  const [isGstApply, setIsGstApply] = useState(1);

  const storedData = JSON.parse(sessionStorage.getItem("stored_data"));
  let part_gst = window.atob(storedData.part_gst);
  let default_margin =
    storedData.default_margin !== 0
      ? window.atob(storedData.default_margin)
      : 0; // workshop default_margin
  let default_markup =
    storedData.default_markup !== 0
      ? window.atob(storedData.default_markup)
      : 0; // workshop default_markup

  let labour_gst = window.atob(storedData.labour_gst); // workshop part gst percentage

  const formik = useFormik({
    initialValues: {
      id: chargeDetails ? chargeDetails[0].id : "",
      labour_code: chargeDetails ? chargeDetails[0].labour_code : "",
      description: chargeDetails ? chargeDetails[0].description : "",
      cost: chargeDetails ? chargeDetails[0].cost_price : "",
      labour_margin: chargeDetails ? chargeDetails[0].margin : "",
      sell: chargeDetails ? chargeDetails[0].sell_price : "",
      gst: chargeDetails ? chargeDetails[0].gst_price : "",
      profit: chargeDetails ? chargeDetails[0].profit_price : "",
      retail_price: chargeDetails ? chargeDetails[0].retail_price : "",
      selected_markup: chargeDetails ? chargeDetails[0].margin : "",
      selected_margin: chargeDetails ? chargeDetails[0].labour_margin : "",
      gst_percent: labour_gst,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const data = {
        workshop_id: window.atob(storedData.workshop_id),
        labour_code: values.labour_code,
        description: values.description,
        cost_price: values.cost,
        margin: values.selected_markup,
        sell_price: values.sell,
        profit_price: values.profit,
        retail_price: values.retail_price,
        gst_price: values.gst_price,
        labour_margin: values.selected_margin,
      };
      if (values.id) {
        dispatch({
          type: UPDATE_LABOUR_CHARGE_DETAILS,
          payload: { ...data, id: values.id },
        });
      } else {
        dispatch({
          type: SAVE_LABOUR_CHARGE,
          payload: data,
        });
      }
    },
    validate: (values) => {
      const error = {};
      if (!values.labour_code) {
        error.labour_code = "Please enter labour code";
      }
      if (!values.cost) {
        error.cost = "Please enter amount";
      }
      if (values.cost && !parseInt(values.cost)) {
        error.cost = "Please enter valid number";
      }
      if (!values.sell) {
        error.sell = "Please enter amount";
      }
      if (values.sell && !parseInt(values.sell)) {
        error.sell = "Please enter valid number";
      }

      if (!values.retail_price) {
        error.retail_price = "Please enter amount";
      }
      if (values.retail_price && !parseInt(values.retail_price)) {
        error.retail_price = "Please enter valid number";
      }

      return error;
    },
  });
  useEffect(() => {
    if (chargeDetails && chargeDetails[0] && chargeDetails[0].cost_price) {
      if (chargeDetails[0].labour_margin) {
        formik.setFieldValue("margin", chargeDetails[0].margin_markup);
      }

      if (chargeDetails[0].margin) {
        formik.setFieldValue("labour_margin", chargeDetails[0].margin);
      }

      if (chargeDetails[0].cost_price) {
        formik.setFieldValue("cost", chargeDetails[0].cost_price);
      }

      if (chargeDetails[0].sell_price) {
        formik.setFieldValue("sell", chargeDetails[0].sell_price);
      }

      if (chargeDetails[0].retail_price !== chargeDetails[0].cost_price) {
        var gst_price =
          (chargeDetails[0].sell_price * formik.values.gst_percent) / 100;
        setIsGstApply(1);
      } else {
        var gst_price = 0;
        setIsGstApply(0);
      }
      formik.setFieldValue("gst", gst_price);

      if (chargeDetails[0].sell_price) {
        var sell_price =
          parseFloat(chargeDetails[0].sell_price) -
          parseFloat(chargeDetails[0].cost_price);
        formik.setFieldValue("profit", sell_price);
      }
    }
  }, [chargeDetails]);
  useEffect(() => {
    formik.resetForm();
  }, [success]);

  // set product markup value
  function setProductMarkupValue(markupValue) {
    formik.setFieldValue("labour_margin", markupValue);
    formik.setFieldValue("selected_markup", markupValue);
    if (formik.values.cost) {
      getPriceCalculation(0, markupValue);
    }
  }

  // set product margin value
  function setProductMarginValue(marginValue) {
    formik.setFieldValue("selected_margin", marginValue);

    if (formik.values.cost) {
      getPriceCalculation(marginValue, 0);
    }
  }

  // change sell price calculation
  function sellPriceCalculation(sell_price) {
    if (formik.values.cost) {
      var cost = precise_round(formik.values.cost);
      var sell = precise_round(sell_price);
      formik.setFieldValue("sell", formik.values.sell);
      if (isNaN(sell)) {
        sell = 0;
      }
      if (isNaN(cost)) {
        cost = 0;
      }
      var total = sell - cost;
      if (cost !== 0) {
        var pecentage = (total * 100) / cost;
      } else {
        pecentage = 0;
      }
      // formik.setFieldValue("selected_markup", pecentage);
      getPriceCalculation(0, pecentage);
    }
  }

  // change retail price calculation
  function retailPriceCalculation(retail_price) {
    if (isNumber(parseFloat(retail_price))) {
      var sell = parseFloat(precise_round(formik.values.sell));
      if (isNaN(sell)) {
        sell = 0;
      }

      var retail = parseFloat(precise_round(retail_price));
      if (isNaN(retail)) {
        retail = 0;
      }

      var gst_percent = part_gst;
      var appliedGST = parseInt(gst_percent) + 100;
      var gst_price = parseFloat(retail) * gst_percent;
      gst_price = parseFloat(precise_round(gst_price)) / appliedGST;
      var new_sell = retail - parseFloat(gst_price);
      if (isNaN(new_sell)) {
        new_sell = 0.0;
      }
      formik.setFieldValue(
        "sell",
        precise_round(parseFloat(new_sell).toFixed(2))
      );
      if (!formik.values.cost) {
        formik.setFieldValue("selected_markup", 0);
      }
      sellPriceCalculation(parseFloat(new_sell).toFixed(2));
    }
  }

  // final calculation
  function getPriceCalculation(product_margin = 0, product_markup = 0) {
    if (formik.values.cost) {
      var cost_price = precise_round(formik.values.cost);
      var gst = 0;

      var sell_price = 0;
      var profit_price = 0;
      var markup = 0;

      var gst_price = 0;
      var product_retail_price = 0;
      var markup_percentage = 0;
      var markupNew = 0;
      var margin = 0;

      // calculate based on Margin
      if (product_markup === 0) {
        if (cost_price) {
          margin = product_margin;
          var gross_margin = product_margin / 100;
          if (1 - gross_margin === 0) {
            sell_price = cost_price;
          } else {
            sell_price = cost_price / (1 - gross_margin);
          }
          sell_price = parseFloat(precise_round(sell_price));

          profit_price = sell_price - cost_price;
          markup = (profit_price / cost_price) * 100;

          markupNew = parseFloat(precise_round(markup)) / 100;
          sell_price = cost_price * markupNew;
          sell_price = parseFloat(sell_price) + parseFloat(cost_price);
          sell_price = parseFloat(precise_round(sell_price));

          // var is_gst_apply = 10;
          // if (is_gst_apply === true) {
          gst = part_gst;
          gst_price = (sell_price * gst) / 100;
          // }

          profit_price = sell_price - cost_price;
          product_retail_price = parseFloat(sell_price) + parseFloat(gst_price);
        }
      }

      // calculate based on Markup

      if (product_margin === 0) {
        if (cost_price) {
          var gross_markup = product_markup / 100;

          sell_price = cost_price * gross_markup;
          sell_price = parseFloat(sell_price) + parseFloat(cost_price);
          sell_price = parseFloat(precise_round(sell_price));

          profit_price = sell_price - cost_price;

          gst = part_gst;
          gst_price = parseFloat((sell_price * gst) / 100);

          markup = parseFloat((profit_price / cost_price) * 100);
          product_retail_price = parseFloat(sell_price) + parseFloat(gst_price);
          markup_percentage = product_markup;
          margin = parseFloat(
            ((sell_price - cost_price) / sell_price) * 100
          ).toFixed(2);
        }
      }
      formik.setFieldValue(
        "cost",
        parseFloat(precise_round(cost_price)).toFixed(2)
      );
      formik.setFieldValue(
        "selected_markup",
        markup === 15 || markup === 25
          ? parseFloat(markup).toFixed(2)
          : parseFloat(precise_round(markup))
      );

      formik.setFieldValue(
        "selected_margin",
        margin === 15 || margin === 25 ? parseFloat(margin).toFixed(2) : margin
      );
      formik.setFieldValue(
        "sell",
        parseFloat(precise_round(sell_price)).toFixed(2)
      );
      formik.setFieldValue(
        "gst",
        parseFloat(precise_round(gst_price)).toFixed(2)
      );
      formik.setFieldValue(
        "profit",
        parseFloat(precise_round(profit_price)).toFixed(2)
      );
      formik.setFieldValue(
        "retail_price",
        parseFloat(precise_round(product_retail_price)).toFixed(2)
      );
    }
  }

  return (
    <>
      <div className="card-body">
        <div className="row pb-3">
          <div className="col-md-3">
            <Form.Group className="form-group pb-3">
              <Form.Label>Labour Code *</Form.Label>
              <Form.Control
                className={
                  formik.touched.labour_code && formik.errors.labour_code
                    ? "form-control is-invalid"
                    : "form-control"
                }
                type="text"
                value={formik.values.labour_code}
                onChange={(e) =>
                  formik.setFieldValue("labour_code", e.target.value)
                }
              />
              {formik.errors.labour_code && formik.touched.labour_code && (
                <div className="invalidFeedback">
                  {formik.errors.labour_code}
                </div>
              )}
            </Form.Group>
          </div>
          <div className="col-md-9">
            <Form.Group className="form-group pb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                className="form-control"
                type="text"
                value={formik.values.description}
                onChange={(e) =>
                  formik.setFieldValue("description", e.target.value)
                }
              />
              {formik.errors.description && formik.touched.description && (
                <div classlabour_code="invalidFeedback">
                  {formik.errors.description}
                </div>
              )}
            </Form.Group>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col-md-12 col-xl-9 border-end">
            <div className="row">
              <div className="col-md-4">
                <Form.Group className="form-group pb-3">
                  <Form.Label>Cost ({storedData.currency})*</Form.Label>
                  <Form.Control
                    type="text"
                    className={
                      formik.touched.cost && formik.errors.cost
                        ? " is-invalid"
                        : ""
                    }
                    value={
                      formik.values.cost
                        ? commaSeparateNumber(formik.values.cost)
                        : ""
                    }
                    onBlur={(e) => {
                      getPriceCalculation(
                        formik.values.selected_margin !== ""
                          ? formik.values.selected_margin
                          : default_margin,
                        formik.values.selected_markup !== ""
                          ? formik.values.selected_markup
                          : default_markup
                      );
                    }}
                    onChange={(e) =>
                      formik.setFieldValue("cost", allowNumber(e.target.value))
                    }
                  />

                  {formik.errors.cost && formik.touched.cost && (
                    <div className="invalidFeedback">{formik.errors.cost}</div>
                  )}
                </Form.Group>
              </div>
              <div className="col-md-4">
                <Form.Group className="form-group pb-3">
                  <Form.Label>Markup %</Form.Label>
                  <Form.Control
                    className={
                      formik.touched.labour_margin &&
                      formik.errors.labour_margin
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    value={
                      formik.values.selected_markup !== ""
                        ? parseFloat(formik.values.selected_markup).toFixed(2)
                        : ""
                    }
                    disabled={true}
                  />
                  {formik.errors.selected_markup &&
                    formik.touched.selected_markup && (
                      <div className="invalidFeedback">
                        {formik.errors.selected_markup}
                      </div>
                    )}
                </Form.Group>
              </div>
              <div className="col-md-4">
                <Form.Group className="form-group pb-3">
                  <Form.Label>Sell ({storedData.currency})*</Form.Label>
                  <Form.Control
                    className={
                      formik.touched.sell && formik.errors.sell
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    value={
                      formik.values.sell
                        ? commaSeparateNumber(formik.values.sell)
                        : ""
                    }
                    onChange={(e) =>
                      formik.setFieldValue("sell", e.target.value)
                    }
                    onBlur={(e) => {
                      sellPriceCalculation(e.target.value);
                    }}
                  />
                  {formik.errors.sell && formik.touched.sell && (
                    <div className="invalidFeedback">{formik.errors.sell}</div>
                  )}
                </Form.Group>
              </div>
              <div className="col-md-12 col-lg-6 col-xl-5 col-xxl-4">
                <Form.Group className="form-group pb-3">
                  <Form.Label>Markup % *</Form.Label>
                  <div className="custom-perc-group d-flex">
                    <span>
                      <Form.Check
                        className="radio-btn mb-2"
                        type="radio"
                        id="2"
                      >
                        <Form.Check.Input
                          name="rdoMarkup"
                          type="radio"
                          value="15.00"
                          onClick={(e) => {
                            setProductMarkupValue(parseFloat(15.0).toFixed(2));
                          }}
                        />
                        <Form.Check.Label>
                          15%
                          <span className="check"></span>
                        </Form.Check.Label>
                      </Form.Check>
                    </span>
                    <span>
                      <Form.Check
                        className="radio-btn mb-2"
                        type="radio"
                        id="2"
                      >
                        <Form.Check.Input
                          name="rdoMarkup"
                          type="radio"
                          value="25"
                          onClick={(e) =>
                            setProductMarkupValue(parseFloat(25.0).toFixed(2))
                          }
                        />
                        <Form.Check.Label>
                          25%
                          <span className="check"></span>
                        </Form.Check.Label>
                      </Form.Check>
                    </span>

                    <span>
                      <Form.Check className="radio-btn mb-2" type="radio">
                        <Form.Check.Input
                          name="rdoMarkup"
                          type="radio"
                          defaultValue={default_markup}
                          onClick={(e) => setProductMarkupValue(default_markup)}
                        />
                        <Form.Check.Label>
                          {parseFloat(default_markup)}%
                          <span className="check"></span>
                        </Form.Check.Label>
                      </Form.Check>
                    </span>

                    <span>
                      <InputGroup className="mb-3">
                        <Form.Control
                          className={
                            formik.touched.selected_markup &&
                            formik.errors.selected_markup
                              ? "form-control max-width-50 is-invalid"
                              : "form-control max-width-50"
                          }
                          type="number"
                          onChange={(e) => {
                            setProductMarkupValue(parseFloat(e.target.value));
                          }}
                          value={
                            formik.values.selected_markup !== ""
                              ? formik.values.selected_markup
                              : ""
                          }
                        />
                        <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                      </InputGroup>
                    </span>
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-12 col-lg-6 col-xl-5 col-xxl-4">
                <Form.Group className="form-group pb-3">
                  <Form.Label>Margin % *</Form.Label>
                  <div className="custom-perc-group d-flex">
                    <span>
                      <Form.Check
                        className="radio-btn mb-2"
                        type="radio"
                        id="2"
                      >
                        <Form.Check.Input
                          name="rdoMarkup"
                          type="radio"
                          value="15"
                          onClick={(e) => {
                            setProductMarginValue(15);
                          }}
                        />
                        <Form.Check.Label>
                          15%
                          <span className="check"></span>
                        </Form.Check.Label>
                      </Form.Check>
                    </span>
                    <span>
                      <Form.Check
                        className="radio-btn mb-2"
                        type="radio"
                        id="2"
                      >
                        <Form.Check.Input
                          name="rdoMarkup"
                          type="radio"
                          value="25"
                          onClick={(e) => setProductMarginValue(25)}
                        />
                        <Form.Check.Label>
                          25%
                          <span className="check"></span>
                        </Form.Check.Label>
                      </Form.Check>
                    </span>

                    <span>
                      <InputGroup className="mb-3">
                        <Form.Control
                          className={
                            formik.touched.selected_margin &&
                            formik.errors.selected_margin
                              ? "form-control max-width-50 is-invalid"
                              : "form-control max-width-50"
                          }
                          type="number"
                          onChange={(e) => {
                            setProductMarginValue(parseFloat(e.target.value));
                          }}
                          value={
                            formik.values.selected_margin !== ""
                              ? formik.values.selected_margin
                              : ""
                          }
                        />
                        <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                      </InputGroup>
                    </span>
                  </div>
                  {formik.errors.selected_margin &&
                    formik.touched.selected_margin && (
                      <div className="invalidFeedback">
                        {formik.errors.selected_margin}
                      </div>
                    )}
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-xl-3">
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="form-group pb-3">
                  <Form.Label>GST {formik.values.gst_percent}%</Form.Label>
                  <Form.Control
                    className={
                      formik.touched.gst && formik.errors.gst
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    value={formik.values.gst}
                    disabled={true}
                  />
                  {formik.errors.gst && formik.touched.gst && (
                    <div className="invalidFeedback">{formik.errors.gst}</div>
                  )}
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group pb-3">
                  <Form.Label>Profit</Form.Label>
                  <Form.Control
                    className={
                      formik.touched.profit && formik.errors.profit
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    value={formik.values.profit}
                    disabled={true}
                  />
                  {formik.errors.profit && formik.touched.profit && (
                    <div className="invalidFeedback">
                      {formik.errors.profit}
                    </div>
                  )}
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group pb-3">
                  <Form.Label>Retail Price *</Form.Label>
                  <Form.Control
                    className={
                      formik.touched.retail_price && formik.errors.retail_price
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    value={
                      formik.values.retail_price
                        ? commaSeparateNumber(formik.values.retail_price)
                        : ""
                    }
                    onChange={(e) =>
                      formik.setFieldValue("retail_price", e.target.value)
                    }
                    onBlur={(e) => retailPriceCalculation(e.target.value)}
                  />
                  {formik.errors.retail_price &&
                    formik.touched.retail_price && (
                      <div className="invalidFeedback">
                        {formik.errors.retail_price}
                      </div>
                    )}
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
        <div className="text-end pb-3 pt-2">
          <button
            type="button"
            className="btn btn-success px-4 btn-rounded"
            onClick={() => formik.handleSubmit()}
          >
            Add
          </button>
        </div>
      </div>
      {formLoading && (
        <div className="page-loader d-flex align-center justify-center">
          <img src={LoaderImage} alt="Loading"></img>
        </div>
      )}
    </>
  );
};

export default LabourChargeForm;
