import { takeLatest, put, call } from "redux-saga/effects";
import { GET_NOTIFICATION_LIST } from "./actionTypes";
import {
  getNotificationListSuccess,
  getNotificationListFail
} from "./actions";

import { getNotification } from "../../helpers/backendHelper";

function* onGetNotificationList(data) {
  try {
    const response = yield call(getNotification, data);
    if(response){
      if(response.code === 200){
        yield put(getNotificationListSuccess(response.data.NotificationsData));
      }else{
        yield put(getNotificationListFail(response.message));
      }
    }else{
      yield put(getNotificationListFail("Didn't get api response"));  
    }
  } catch (error) {
    yield put(getNotificationListFail(error.response));
  }
}

function* NotificationListSaga() {
  yield takeLatest(GET_NOTIFICATION_LIST, onGetNotificationList);
}

export default NotificationListSaga;
