import {
  GET_QUOTE_BOOKED_DETAILS,
  GET_QUOTE_BOOKED_DETAILS_SUCCESS,
  GET_QUOTE_BOOKED_DETAILS_FAIL,
  GET_MESSAGE,
  GET_MESSAGE_SUCCESS,
  GET_MESSAGE_FAIL,
  GET_INSPECTION_VIEW,
  GET_INSPECTION_VIEW_SUCCESS,
  GET_INSPECTION_VIEW_FAIL,
  GET_EMAIL_QUOTE,
  GET_EMAIL_QUOTE_SUCCESS,
  GET_EMAIL_QUOTE_FAIL,
  GET_QUOTE_INTERNAL_NOTES,
  GET_QUOTE_INTERNAL_NOTES_SUCCESS,
  GET_QUOTE_INTERNAL_NOTES_FAIL,
  GET_QUOTE_INTERNAL_NOTES_SCRIPTS,
  GET_QUOTE_INTERNAL_NOTES_SCRIPTS_SUCCESS,
  GET_QUOTE_INTERNAL_NOTES_SCRIPTS_FAIL,
  GET_SCRIPT_LIST,
  GET_SCRIPT_LIST_SUCCESS,
  GET_SCRIPT_LIST_FAIL,
  CREATE_QUOTE,
  CREATE_QUOTE_SUCCESS,
  CREATE_QUOTE_FAIL,
  GET_EMAIL_PREVIEW_QUOTE,
  GET_EMAIL_PREVIEW_QUOTE_SUCCESS,
  GET_EMAIL_PREVIEW_QUOTE_FAIL,
  QUOTE_UPDATED_DATA,
  QUOTE_UPDATED_DATA_SUCCESS,
  QUOTE_UPDATED_DATA_FAIL,
  UPDATE_QUOTE,
  UPDATE_QUOTE_SUCCESS,
  UPDATE_QUOTE_FAIL
} from "./actionTypes";

const initialState = {
  token: "",
  loadingData: false,
  message: [],
  inspectionView: [],
  quoteInternalNotes: [],
  quoteInternalNotesScripts: [],
  scriptList: [],
  emailQuote: [],
  emailPreviewQuote: [],
  createQuote: null,
  error: {
    message: "",
  },
};

const QuoteDetails = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUOTE_BOOKED_DETAILS:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case GET_QUOTE_BOOKED_DETAILS_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        jobBookedData: action.payload,
      };
      break;
    case GET_QUOTE_BOOKED_DETAILS_FAIL:
      state = {
        ...state,
        jobBookedData: null,
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;

    case GET_MESSAGE:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case GET_MESSAGE_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        message: action.payload,
      };
      break;
    case GET_MESSAGE_FAIL:
      state = {
        ...state,
        message: [],
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;

    case GET_INSPECTION_VIEW:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case GET_INSPECTION_VIEW_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        inspectionView: action.payload,
      };
      break;
    case GET_INSPECTION_VIEW_FAIL:
      state = {
        ...state,
        inspectionView: [],
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;

    case GET_QUOTE_INTERNAL_NOTES:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case GET_QUOTE_INTERNAL_NOTES_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        quoteInternalNotes: action.payload,
      };
      break;
    case GET_QUOTE_INTERNAL_NOTES_FAIL:
      state = {
        ...state,
        quoteInternalNotes: [],
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;

    case GET_QUOTE_INTERNAL_NOTES_SCRIPTS:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case GET_QUOTE_INTERNAL_NOTES_SCRIPTS_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        quoteInternalNotesScripts: action.payload,
      };
      break;
    case GET_QUOTE_INTERNAL_NOTES_SCRIPTS_FAIL:
      state = {
        ...state,
        quoteInternalNotesScripts: [],
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;

    case GET_EMAIL_QUOTE:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case GET_EMAIL_QUOTE_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        emailQuote: action.payload,
      };
      break;
    case GET_EMAIL_QUOTE_FAIL:
      state = {
        ...state,
        emailQuote: [],
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;

    case GET_SCRIPT_LIST:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case GET_SCRIPT_LIST_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        scriptList: action.payload,
      };
      break;
    case GET_SCRIPT_LIST_FAIL:
      state = {
        ...state,
        scriptList: [],
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;

    case CREATE_QUOTE:
      state = {
        ...state,
        loadingData: true,
      };
      break;
    case CREATE_QUOTE_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        createQuote: action.payload,
      };
      break;
    case CREATE_QUOTE_FAIL:
      state = {
        ...state,
        createQuote: null,
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;

      case GET_EMAIL_PREVIEW_QUOTE:
      state = {
        ...state,
        loadingData: true,
      };
      break;
      case GET_EMAIL_PREVIEW_QUOTE_SUCCESS:
        state = {
          ...state,
          loadingData: false,
          emailPreviewQuote: action.payload,
        };
      break;
      case GET_EMAIL_PREVIEW_QUOTE_FAIL:
        state = {
          ...state,
          emailPreviewQuote: [],
          error: {
            message: action.payload,
          },
          loadingData: false,
        };
      break;

      case QUOTE_UPDATED_DATA:
      state = {
        ...state,
        loadingData: true,
        allQuoteData: [],
      };
      break;

    case QUOTE_UPDATED_DATA_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        allQuoteData: action.payload,
      };
      break;
    case QUOTE_UPDATED_DATA_FAIL:
      state = {
        ...state,
        allQuoteData: [],
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;


      case UPDATE_QUOTE:
      state = {
        ...state,
        loadingData: true,
        updateQuoteData: [],
      };
      break;

    case UPDATE_QUOTE_SUCCESS:
      state = {
        ...state,
        loadingData: false,
        updateQuoteData: action.payload,
      };
      break;
    case UPDATE_QUOTE_FAIL:
      state = {
        ...state,
        updateQuoteData: [],
        error: {
          message: action.payload,
        },
        loadingData: false,
      };
      break;


    default:
      state = { ...state };
      break;
  }
  return state;
};

export default QuoteDetails;
