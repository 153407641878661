import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { PopoverPicker } from "./popOverPicker";
import { FilePond, File, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import MobilePreview from "./mobilePreview";
import { UploadFileToS3 } from "../../../../utils/uploadFileToS3";
import { UPDATE_COMPANY_OTHER_DETAIL } from "../../../../store/companyDetails/actionTypes";
import DefaultLogo from "../../../../assets/images/5iq-logo.png";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

const BrandedApp = ({ workShopOtherDetails }) => {
  const dispatch = useDispatch(); // Dispatch variable

  const [logo, setLogo] = useState("");
  const [file, setFile] = useState("");
  const [primaryColor, setPrimaryColor] = useState("#2b2b2c");
  const [secondaryColor, setSecondaryColor] = useState("#45e1f3");
  const [fontColor, setFontColor] = useState("#45e1f3");
  const [iconColor, setIconColor] = useState("#45e1f3");
  
  const filePondRef = useRef();

  useEffect(() => {
    if (workShopOtherDetails) {
      if (workShopOtherDetails.primary_colour !== "") {
        setPrimaryColor(workShopOtherDetails.primary_colour);
      }
      if (workShopOtherDetails.secondary_colour !== "") {
        setSecondaryColor(workShopOtherDetails.secondary_colour);
      }
      if (workShopOtherDetails.font_color !== "") {
        setFontColor(workShopOtherDetails.font_color);
      }
      if (workShopOtherDetails.icon_colour !== "") {
        setIconColor(workShopOtherDetails.icon_colour);
      }
      if (workShopOtherDetails.customer_app_logo !== "") {
        setLogo(workShopOtherDetails.customer_app_logo);
      }
    }
  }, [workShopOtherDetails]);

  const handleSubmit = () => {
    var filename = "";
    if (file) {
      const d = new Date();
      var time = d.getTime();
      filename = file.name.replace(/ /g,"_");
      var extenstion = file.type.split("/");
      filename = "logo/"+time+"_"+filename;
      UploadFileToS3(file,filename);
    }
    dispatch({
      type: UPDATE_COMPANY_OTHER_DETAIL,
      payload: {
        customer_app_logo: filename,
        primary_colour: primaryColor,
        secondary_colour: secondaryColor,
        font_color: fontColor,
        icon_colour: iconColor,
        workshop_id: workShopOtherDetails.workshop_id,
        id: workShopOtherDetails.id,
      },
    });
    filePondRef.current && filePondRef.current.removeFile();
  };

  return (
    <>
      <div className="card">
        <div className="card-header font-size-24 py-1">
          SET-UP YOUR BRANDED APP!
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12 col-xl-8">
              <p className="mb-2">
                <span className="numberCircle">1</span> Upload your logo
              </p>
              <div className="pb-5">
                <div className="row">
                  <div className="col-md-6">
                    <div className="pb-3">
                      <div className="file-upload-box">
                        <FilePond
                          ref={filePondRef}
                          id="file"
                          allowMultiple={false}
                          imageValidateSizeMinWidth={150}
                          imageValidateSizeMaxWidth={450}
                          imageValidateSizeMinHeight={150}
                          // allowProcess={true}
                          // server="/api"
                          instantUpload={false}
                          onaddfile={async (err, fileEx) => {
                            if (!err) {
                              setFile(fileEx.file);
                            }
                          }}
                          onremovefile={() => {
                            setFile("");
                          }}
                          onerror={() => {
                            // setFieldTouched("file", true);
                          }}
                          name="file"
                          acceptedFileTypes={["image/png", "image/jpeg"]}
                          maxFileSize="2MB"
                          labelIdle='<div class="dropzone-placeholder">
                              <div class="txt pb-1">
                                Drag and drop your file here
                                <br /> <strong>or</strorng>
                              </div>
                              <span class="btn btn-sm btn-primary btn-rounded px-3">Select File</span>
                              </div>'
                        />
                      </div>
                      {/* <input type="file" name="upload_image" id="upload_image" accept="image/*"/> */}
                      <p className="pt-3">
                        Note: We require a height of 150px and width 150-450 px
                        max.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="img pb-3">
                      {logo !== "" ? (
                           <img
                           src={logo}
                           width="150"
                           height="150"
                           alt=""
                           className="width-auto"
                         />
                      ) : (
                        <img
                          src={DefaultLogo}
                          width="150"
                          height="150"
                          alt=""
                          className="width-auto"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <p className="mb-3">
                <span className="numberCircle">2</span> Configure your Brand
                colours
              </p>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">
                      Primary Colour
                    </label>
                    <PopoverPicker
                      color={primaryColor}
                      setColor={setPrimaryColor}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">
                      Secondary Colour
                    </label>
                    <PopoverPicker
                      color={secondaryColor}
                      setColor={setSecondaryColor}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">
                      Font Colour
                    </label>
                    <PopoverPicker color={fontColor} setColor={setFontColor} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group pb-3">
                    <label htmlFor="" className="form-label">
                      Icon Colour
                    </label>
                    <PopoverPicker color={iconColor} setColor={setIconColor} />
                  </div>
                </div>
              </div>
              <div className="cta d-flex justify-content-center pb-4">
                <button
                  type="button"
                  className="btn btn-success px-4 btn-rounded me-2"
                  onClick={handleSubmit}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-success px-4 btn-rounded ms-2"
                >
                  Reset
                </button>
              </div>
              <p>
                <strong>Note:</strong> Reset will restore these settings back to
                the default setting of 5iQ Logo & colours.
              </p>
            </div>
            <div className="col-md-12 col-xl-4">
              <p className="mb-3">
                <span className="numberCircle">3</span> Preview your Branded App
              </p>
              <MobilePreview
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                fontColor={fontColor}
                iconColor={iconColor}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandedApp;
