import { getInvoicesPayments } from "helpers/backendHelper";
import { getWorkshopId } from "utils/getWorkshopId";

export async function getInvoiceReceiptDetail(id) {
  let invoice, customer, job_detail, invoices_payments_credit;
  const request = {
    get: "all",
    conditions: {
      "InvoicesPaymentsCredits.id": id,
    },
    contain: {
      0: "Invoices.JobSchedules.Vehicles.Users.States",
      1: "Invoices.JobSchedules.CreatedByUser",
      "Invoices.JobSchedules.Vehicles.Users.CustomersDetails": {
        conditions: {
          workshop_id: getWorkshopId(),
        },
      },
    },
  };
  const get_invoices = await getInvoicesPayments({ payload: request });

  if (get_invoices?.code === 200) {
    invoices_payments_credit = get_invoices?.data?.InvoicesPaymentsCredits[0];
    invoice = invoices_payments_credit?.invoice;
    job_detail = invoice?.job_schedule;
    customer = job_detail?.vehicle?.user;
  } else {
    throw new Error("Failed to fetch data");
  }

  return {
    invoice,
    customer,
    job_detail: job_detail,
    invoices_payments_credit,
  };
}
