import {
    CHECK_PHONE_EXIST,
    CHECK_PHONE_EXIST_SUCCESS,
    CHECK_PHONE_EXIST_FAIL,
    CHECK_MOBILE_EXIST,
    CHECK_MOBILE_EXIST_SUCCESS,
    CHECK_MOBILE_EXIST_FAIL,
    ADD_CUSTOMER,
    ADD_CUSTOMER_SUCCESS,
    ADD_CUSTOMER_FAIL
} from "./actionTypes";

const initialState = {
    data: [],
    error: {
        message: "",
        custMessage : null
    },
    isFetching: false,
    checkPhoneData: false,
    checkMobileData: false,
    customerDetails: null
};

const ContactsReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHECK_PHONE_EXIST:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case CHECK_PHONE_EXIST_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                checkPhoneData: action.payload,
            };
            break;
        case CHECK_PHONE_EXIST_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                checkPhoneData: action.payload,
                error: {
                    message: action.payload,
                },
            };
            break;
        case CHECK_MOBILE_EXIST:
            state = {
                ...state,
                loadingLogins: true,
            };
            break;
        case CHECK_MOBILE_EXIST_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                checkMobileData: action.payload,
            };
            break;
        case CHECK_MOBILE_EXIST_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                checkMobileData: action.payload,
                error: {
                    message: action.payload,
                },
            };
            break;
        case ADD_CUSTOMER:
            state = {
                ...state,
                loadingLogins: true,
                customerDetails: action.payload,
            };
            break;
        case ADD_CUSTOMER_SUCCESS:
            state = {
                ...state,
                loadingLogins: false,
                customerDetails: action.payload.Users
            };
            break;
        case ADD_CUSTOMER_FAIL:
            state = {
                ...state,
                loadingLogins: false,
                error: {
                    custMessage: action.payload,
                },
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default ContactsReducer;