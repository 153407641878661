import Loadable from "react-loadable";

import { Loading } from "../common/navigation";

const SubscriptionPlan = Loadable({
  loader: () => import("./components/subscriptionPlan"),
  loading: Loading,
});

const EditSubscription = Loadable({
  loader: () => import("./components/editSubscription"),
  loading: Loading,
});

export const routes = [
  {
    path: "/subscription-plan",
    exact: true,
    name: "subscription_plan",
    element: SubscriptionPlan,
  },
  {
    path: "/edit-subscription",
    exact: true,
    name: "edit_subscription",
    element: EditSubscription,
  }
];

export * from "./components";
