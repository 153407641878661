import {
  SAVE_QUOTE_LABOUR,
  SAVE_QUOTE_LABOUR_SUCCESS,
  SAVE_QUOTE_LABOUR_FAIL,
  GET_QUOTE_LABOUR_LIST,
  GET_QUOTE_LABOUR_LIST_SUCCESS,
  GET_QUOTE_LABOUR_LIST_FAIL,
  DELETE_QUOTE_LABOUR,
  DELETE_QUOTE_LABOUR_SUCCESS,
  DELETE_QUOTE_LABOUR_FAIL,
  UPDATE_QUOTE_LABOUR,
  UPDATE_QUOTE_LABOUR_SUCCESS,
  UPDATE_QUOTE_LABOUR_FAIL,
  EDIT_QUOTE_LABOUR_MANY,
  EDIT_QUOTE_LABOUR_MANY_SUCCESS,
  EDIT_QUOTE_LABOUR_MANY_FAIL,
  INLINE_UPDATE_LABOUR,
  INLINE_UPDATE_LABOUR_SUCCESS,
  INLINE_UPDATE_LABOUR_FAIL
} from "./actionTypes";

export const saveQuoteLabour = () => {
  return {
    type: SAVE_QUOTE_LABOUR,
  };
};

export const saveQuoteLabourSuccess = (data) => {
  return {
    type: SAVE_QUOTE_LABOUR_SUCCESS,
    payload: data,
  };
};

export const saveQuoteLabourFail = (error) => {
  return {
    type: SAVE_QUOTE_LABOUR_FAIL,
    payload: error,
  };
};

// Get Quote Labour START
export const getQuoteLabourList = () => {
  return {
    type: GET_QUOTE_LABOUR_LIST,
  };
};

export const getQuoteLabourListSuccess = (data) => {
  return {
    type: GET_QUOTE_LABOUR_LIST_SUCCESS,
    payload: data,
  };
};

export const getQuoteLabourListFail = (error) => {
  return {
    type: GET_QUOTE_LABOUR_LIST_FAIL,
    payload: error,
  };
};

// Get Quote Labour END


export const deleteQuoteLabour = () => {
  return {
    type: DELETE_QUOTE_LABOUR,
  };
};

export const deleteQuoteLabourSuccess = (data) => {
  return {
    type: DELETE_QUOTE_LABOUR_SUCCESS,
    payload: data,
  };
};

export const deleteQuoteLabourFail = (error) => {
  return {
    type: DELETE_QUOTE_LABOUR_FAIL,
    payload: error,
  };
};

export const updateQuoteLabour = () => {
  return {
    type: UPDATE_QUOTE_LABOUR,
  };
};

export const updateQuoteLabourSuccess = (data) => {
  return {
    type: UPDATE_QUOTE_LABOUR_SUCCESS,
    payload: data,
  };
};

export const updateQuoteLabourFail = (error) => {
  return {
    type: UPDATE_QUOTE_LABOUR_FAIL,
    payload: error,
  };
};

export const inlineUpdateLabour = () => {
  return {
    type: INLINE_UPDATE_LABOUR,
  };
};

export const inlineUpdateLabourSuccess = (data) => {
  return {
    type: INLINE_UPDATE_LABOUR_SUCCESS,
    payload: data,
  };
};

export const inlineUpdateLabourFail = (error) => {
  return {
    type: INLINE_UPDATE_LABOUR_FAIL,
    payload: error,
  };
};

export const editQuoteLabourMany = () => {
  return {
    type: EDIT_QUOTE_LABOUR_MANY,
  };
};

export const editQuoteLabourManySuccess = (data) => {
  return {
    type: EDIT_QUOTE_LABOUR_MANY_SUCCESS,
    payload: data,
  };
};

export const editQuoteLabourManyFail = (error) => {
  return {
    type: EDIT_QUOTE_LABOUR_MANY_FAIL,
    payload: error,
  };
};