import { takeLatest, put, call } from "redux-saga/effects";

import {
  GET_TIMEZONE,
  GET_WORKSHOP_DETAIL,
  GET_WORKSHOP_OTHER_DETAIL,
  UPDATE_COMPANY_DETAIL,
  UPDATE_COMPANY_OTHER_DETAIL,
  GET_WORKSHOP_SMS,
  UPDATE_WORKSHOP_SMS,
  UPDATE_WORKSHOP_MARKUP_DETAIL,
  GET_WORKSHOP_MARKUP_DETAIL,
  DELETE_WORKSHOP_MARKUP,
  UPDATE_DEFAULT_WORKSHOP_MARKUP_DETAIL,
} from "./actionTypes";

import {
  getTimeZoneSuccess,
  getTimeZoneFail,
  getWorkShopDetailSuccess,
  getWorkShopDetailFail,
  getWorkShopOtherDetailSuccess,
  getWorkShopOtherDetailFail,
  updateCompanyDetailSuccess,
  updateCompanyDetailFail,
  updateCompanyOtherDetailSuccess,
  updateCompanyOtherDetailFail,
  getWorkShopSMSSuccess,
  getWorkShopSMSFail,
  updateWorkShopSMSSuccess,
  updateWorkShopSMSFail,
  updateWorkshopMarkupDetailSuccess,
  updateWorkshopMarkupDetailFail,
  getWorkShopMarkupDetailSuccess,
  getWorkShopMarkupDetailFail,
  deleteWorkshopMarkupDetailSuccess,
  deleteWorkshopMarkupDetailFail,
  getWorkShopMarkupDetail,
  updateWorkshopMarkupDetail,
} from "./actions";

import {
  addWorkShopMarkup,
  deleteWorkshopMarkup,
  getTimeZone,
  updateDefaultWorkShopMarkup,
} from "../../helpers/backendHelper";
import { getWorkShopDetail } from "../../helpers/backendHelper";
import { getWorkShopOtherDetail } from "../../helpers/backendHelper";
import { updateCompanyDetail } from "../../helpers/backendHelper";
import { updateCompanyOtherDetail } from "../../helpers/backendHelper";
import { getWorkShopSMS } from "../../helpers/backendHelper";
import { updateWorkShopSMS } from "../../helpers/backendHelper";
import { updateWorkShopMarkup } from "../../helpers/backendHelper";
import { getWorkshopMarkupList } from "../../helpers/backendHelper";
import { showError, showSuccess } from "../../utils/alertMessage";
import { editWorkshopMarkupDetailSuccess } from "../commonApiStore/actions";

function* onGetTimeZone(data) {
  try {
    const response = yield call(getTimeZone, data);
    if (response) {
      if (response.code === 200) {
        yield put(getTimeZoneSuccess(response));
      } else {
        yield put(getTimeZoneFail(response.message));
      }
    } else {
      yield put(getTimeZoneFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getTimeZoneFail(error.response));
  }
}

function* onGetWorkShopDetails(data) {
  try {
    const response = yield call(getWorkShopDetail, data);
    if (response) {
      if (response.code === 200) {
        yield put(getWorkShopDetailSuccess(response));
      } else {
        yield put(getWorkShopDetailFail(response.message));
      }
    } else {
      yield put(getWorkShopDetailFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getWorkShopDetailFail(error.response));
  }
}

function* onGetWorkShopOtherDetails(data) {
  try {
    const response = yield call(getWorkShopOtherDetail, data);
    if (response) {
      if (response.code === 200) {
        yield put(getWorkShopOtherDetailSuccess(response));
      } else {
        yield put(getWorkShopOtherDetailFail(response.message));
      }
    } else {
      yield put(getWorkShopOtherDetailFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getWorkShopOtherDetailFail(error.response));
  }
}

function* onUpdateCompanyDetails(data) {
  try {
    const response = yield call(updateCompanyDetail, data);
    if (response) {
      if (response.code === 200) {
        yield put(updateCompanyDetailSuccess(response));
        showSuccess("Company details updated successfully.");
      } else {
        yield put(updateCompanyDetailFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(updateCompanyDetailFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(updateCompanyDetailFail(error.response));
  }
}

function* onUpdateCompanyOtherDetails(data) {
  try {
    const response = yield call(updateCompanyOtherDetail, data);
    if (response) {
      if (response.code === 200) {
        yield put(updateCompanyOtherDetailSuccess(response));
        showSuccess(response.message);
      } else {
        yield put(updateCompanyOtherDetailFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(updateCompanyOtherDetailFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(updateCompanyOtherDetailFail(error.response));
  }
}

function* onGetWorkShopSMS(data) {
  try {
    const response = yield call(getWorkShopSMS, data);
    if (response) {
      if (response.code === 200) {
        yield put(getWorkShopSMSSuccess(response));
      } else {
        yield put(getWorkShopSMSFail(response.message));
      }
    } else {
      yield put(getWorkShopSMSFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getWorkShopSMSFail(error.response));
  }
}

function* onUpdateWorkShopSMS(data) {
  try {
    const response = yield call(updateWorkShopSMS, data);
    if (response) {
      if (response.code === 200) {
        yield put(updateWorkShopSMSSuccess(response));
        showSuccess("Company details updated successfully.");
      } else {
        yield put(updateWorkShopSMSFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(updateWorkShopSMSFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(updateWorkShopSMSFail(error.response));
  }
}

// start workshop mark up detail
function* onGetWorkshopMarkupLists(data) {
  try {
    const response = yield call(getWorkshopMarkupList, data);
    if (response) {
      if (response.code === 200) {
        yield put(getWorkShopMarkupDetailSuccess(response));
      } else {
        yield put(getWorkShopMarkupDetailFail(response.message));
      }
    } else {
      yield put(getWorkShopMarkupDetailFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getWorkShopMarkupDetailFail(error.response));
  }
}

function* onUpdateWorkshopMarkupDetails(data) {
  try {
    let response = "";
    if (data.payload.id) {
      response = yield call(updateWorkShopMarkup, data);
    } else {
      response = yield call(addWorkShopMarkup, data);
    }

    if (response) {
      if (response.code === 200) {
        showSuccess(response.message);

        if (data.payload.id) {
          yield put(editWorkshopMarkupDetailSuccess());

          yield put(
            getWorkShopMarkupDetail({
              workshop_id: JSON.parse(localStorage.getItem("data")).workshop_id,
            })
          );
        } else {
          yield put(updateWorkshopMarkupDetailSuccess(response));
        }
      } else {
        yield put(updateWorkshopMarkupDetailFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(updateWorkshopMarkupDetailFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(updateWorkshopMarkupDetailFail(error.response));
  }
}

//updateDefaultWorkShopMarkup
function* onUpdateDefaultWorkshopMarkupDetail(data) {
  try {
    const editData = {
      type: data.type,
      payload: {
        is_default: "0",
        workshop_id: data.payload.workshop_id,
      },
    };
    const response = yield call(updateDefaultWorkShopMarkup, editData);
    if (response) {
      if (response.code === 200) {
        // showSuccess(response.message);
        yield put(
          updateWorkshopMarkupDetail({
            is_default: "1",
            id: data.payload.id,
          })
        );
      } else {
        yield put(updateWorkshopMarkupDetailFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(updateWorkshopMarkupDetailFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(updateWorkshopMarkupDetailFail(error.response));
  }
}

// DELETE workshop markup
function* onDeleteWorkshopMarkup(data) {
  try {
    const response = yield call(deleteWorkshopMarkup, data);
    if (response) {
      if (response.code === 200) {
        yield put(deleteWorkshopMarkupDetailSuccess(response));
        showSuccess(response.message);
      } else {
        yield put(deleteWorkshopMarkupDetailFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(deleteWorkshopMarkupDetailFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(deleteWorkshopMarkupDetailFail(error.response));
  }
}

// end workshop mark up detail

function* CompanyDetailSaga() {
  yield takeLatest(GET_TIMEZONE, onGetTimeZone);
  yield takeLatest(GET_WORKSHOP_DETAIL, onGetWorkShopDetails);
  yield takeLatest(GET_WORKSHOP_OTHER_DETAIL, onGetWorkShopOtherDetails);
  yield takeLatest(UPDATE_COMPANY_DETAIL, onUpdateCompanyDetails);
  yield takeLatest(UPDATE_COMPANY_OTHER_DETAIL, onUpdateCompanyOtherDetails);
  yield takeLatest(GET_WORKSHOP_MARKUP_DETAIL, onGetWorkshopMarkupLists);
  yield takeLatest(
    UPDATE_WORKSHOP_MARKUP_DETAIL,
    onUpdateWorkshopMarkupDetails
  );
  yield takeLatest(DELETE_WORKSHOP_MARKUP, onDeleteWorkshopMarkup);
  yield takeLatest(GET_WORKSHOP_SMS, onGetWorkShopSMS);
  yield takeLatest(UPDATE_WORKSHOP_SMS, onUpdateWorkShopSMS);
  yield takeLatest(
    UPDATE_DEFAULT_WORKSHOP_MARKUP_DETAIL,
    onUpdateDefaultWorkshopMarkupDetail
  );
}

export default CompanyDetailSaga;
