import {
  GET_QUOTE_BOOKED_DETAILS,
  GET_QUOTE_BOOKED_DETAILS_SUCCESS,
  GET_QUOTE_BOOKED_DETAILS_FAIL,
  GET_MESSAGE,
  GET_MESSAGE_SUCCESS,
  GET_MESSAGE_FAIL,
  GET_INSPECTION_VIEW,
  GET_INSPECTION_VIEW_SUCCESS,
  GET_INSPECTION_VIEW_FAIL,
  GET_QUOTE_INTERNAL_NOTES,
  GET_QUOTE_INTERNAL_NOTES_SUCCESS,
  GET_QUOTE_INTERNAL_NOTES_FAIL,
  GET_QUOTE_INTERNAL_NOTES_SCRIPTS,
  GET_QUOTE_INTERNAL_NOTES_SCRIPTS_SUCCESS,
  GET_QUOTE_INTERNAL_NOTES_SCRIPTS_FAIL,
  GET_EMAIL_QUOTE,
  GET_EMAIL_QUOTE_SUCCESS,
  GET_EMAIL_QUOTE_FAIL,
  GET_SCRIPT_LIST,
  GET_SCRIPT_LIST_SUCCESS,
  GET_SCRIPT_LIST_FAIL,
  CREATE_QUOTE,
  CREATE_QUOTE_SUCCESS,
  CREATE_QUOTE_FAIL,
  GET_EMAIL_PREVIEW_QUOTE,
  GET_EMAIL_PREVIEW_QUOTE_SUCCESS,
  GET_EMAIL_PREVIEW_QUOTE_FAIL,
  QUOTE_UPDATED_DATA,
  QUOTE_UPDATED_DATA_SUCCESS,
  QUOTE_UPDATED_DATA_FAIL,
  UPDATE_QUOTE,
  UPDATE_QUOTE_SUCCESS,
  UPDATE_QUOTE_FAIL
} from "./actionTypes";

export const getQuoteBookedDetails = () => {
  return {
    type: GET_QUOTE_BOOKED_DETAILS,
  };
};

export const getQuoteBookedDetailsSuccess = (data) => {
  return {
    type: GET_QUOTE_BOOKED_DETAILS_SUCCESS,
    payload: data,
  };
};

export const getQuoteBookedDetailsFail = (error) => {
  return {
    type: GET_QUOTE_BOOKED_DETAILS_FAIL,
    payload: error,
  };
};

export const getMessage = () => {
  return {
    type: GET_MESSAGE,
  };
};

export const getMessageSuccess = (data) => {
  return {
    type: GET_MESSAGE_SUCCESS,
    payload: data,
  };
};

export const getMessageFail = (error) => {
  return {
    type: GET_MESSAGE_FAIL,
    payload: error,
  };
};

export const getInspectionView = () => {
  return {
    type: GET_INSPECTION_VIEW,
  };
};

export const getInspectionViewSuccess = (data) => {
  return {
    type: GET_INSPECTION_VIEW_SUCCESS,
    payload: data,
  };
};

export const getInspectionViewFail = (error) => {
  return {
    type: GET_INSPECTION_VIEW_FAIL,
    payload: error,
  };
};

export const getQuoteInternalNotes = () => {
  return {
    type: GET_QUOTE_INTERNAL_NOTES,
  };
};

export const getQuoteInternalNotesSuccess = (data) => {
  return {
    type: GET_QUOTE_INTERNAL_NOTES_SUCCESS,
    payload: data,
  };
};

export const getQuoteInternalNotesFail = (error) => {
  return {
    type: GET_QUOTE_INTERNAL_NOTES_FAIL,
  };
};

export const getQuoteInternalNotesScripts = () => {
  return {
    type: GET_QUOTE_INTERNAL_NOTES_SCRIPTS,
  };
};

export const getQuoteInternalNotesScriptsSuccess = (data) => {
  return {
    type: GET_QUOTE_INTERNAL_NOTES_SCRIPTS_SUCCESS,
    payload: data,
  };
};

export const getQuoteInternalNotesScriptsFail = (error) => {
  return {
    type: GET_QUOTE_INTERNAL_NOTES_SCRIPTS_FAIL,
    payload: error,
  };
};

export const getEmailQuote = () => {
  return {
    type: GET_EMAIL_QUOTE,
  };
};

export const getEmailQuoteSuccess = (data) => {
  return {
    type: GET_EMAIL_QUOTE_SUCCESS,
    payload: data,
  };
};

export const getEmailQuoteFail = (error) => {
  return {
    type: GET_EMAIL_QUOTE_FAIL,
    payload: error,
  };
};

export const getScriptList = () => {
  return {
    type: GET_SCRIPT_LIST,
  };
};

export const getScriptListSuccess = (data) => {
  return {
    type: GET_SCRIPT_LIST_SUCCESS,
    payload: data,
  };
};

export const getScriptListFail = (error) => {
  return {
    type: GET_SCRIPT_LIST_FAIL,
    payload: error,
  };
};

export const createQuote = () => {
  return {
    type: CREATE_QUOTE,
  };
};

export const createQuoteSuccess = (data) => {
  return {
    type: CREATE_QUOTE_SUCCESS,
    payload: data,
  };
};

export const createQuoteFail = (error) => {
  return {
    type: CREATE_QUOTE_FAIL,
    payload: error,
  };
};

export const getEmailPreviewQuote = () => {
  return {
    type: GET_EMAIL_PREVIEW_QUOTE,
  };
};

export const getEmailPreviewQuoteSuccess = (data) => {
  return {
    type: GET_EMAIL_PREVIEW_QUOTE_SUCCESS,
    payload: data,
  };
};

export const getEmailPreviewQuoteFail = (error) => {
  return {
    type: GET_EMAIL_PREVIEW_QUOTE_FAIL,
    payload: error,
  };
};


export const quoteUpdatedData = () => {
  return {
    type: QUOTE_UPDATED_DATA,
  };
};

export const quoteUpdatedDataSuccess = (data) => {
  return {
    type: QUOTE_UPDATED_DATA_SUCCESS,
    payload: data,
  };
};

export const quoteUpdatedDataFail = (error) => {
  return {
    type: QUOTE_UPDATED_DATA_FAIL,
    payload: error,
  };
};



export const updatedQuote = () => {
  return {
    type: UPDATE_QUOTE,
  };
};

export const updatedQuoteSuccess = (data) => {
  return {
    type: UPDATE_QUOTE_SUCCESS,
    payload: data,
  };
};

export const updatedQuoteFail = (error) => {
  return {
    type: UPDATE_QUOTE_FAIL,
    payload: error,
  };
};