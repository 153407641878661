import { takeLatest, put, call } from "redux-saga/effects";
import {
  ADD_STATUSES_DETAILS,
  CHECK_NAME_STATUSES_DETAILS,
  DELETE_STATUSES_DETAILS,
  EDIT_STATUSES_DETAILS,
  GET_STATUSES_DETAILS,
  GET_STATUS_TECHNICIAN_DETAILS,
  UPDATE_STATUSES_DETAILS,
  GET_STATUSES_COUNT
} from "./actionTypes";
import {
  getStatusesSuccess,
  getStatusesFail,
  getStatusTechnicianSuccess,
  getStatusTechnicianFail,
  addStatusesSuccess,
  addStatusesFail,
  editStatusesSuccess,
  editStatusesFail,
  updateStatusesSuccess,
  updateStatusesFail,
  deleteStatusesSuccess,
  deleteStatusesFail,
  checkNameStatusesSuccess,
  checkNameStatusesFail,
  getStatusesDetail,
  getStatusesCountSuccess,
  getStatusesCountFail,
  getStatusTechnicianDetail
} from "./actions";

import {
  addStatus,
  getStatusesList,
  updateStatus,
  deleteStatus,
} from "../../helpers/backendHelper";
import { showError, showSuccess } from "../../utils/alertMessage";

// List workshop bays
function* onGetStatusesDetails(data) {
  try {
    const response = yield call(getStatusesList, data);
    if (response) {
      if (response.code === 200) {
        yield put(getStatusesSuccess(response));
      } else {
        yield put(getStatusesFail(response.message));
      }
    } else {
      yield put(getStatusesFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getStatusesFail(error.response));
  }
}

// Get data of technician statuses app
function* onGetStatusTechnicianDetails(data) {
  try {
    const response = yield call(getStatusesList, data);
    if (response) {
      if (response.code === 200) {
        yield put(getStatusTechnicianSuccess(response));
      } else {
        yield put(getStatusTechnicianFail(response.message));
      }
    } else {
      yield put(getStatusTechnicianFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getStatusTechnicianFail(error.response));
  }
}

// Check name avaiability in workshop bays
function* onCheckNameStatusesDetails(data) {
  try {
    const response = yield call(getStatusesList, data);
    if (response) {
      // if (response.code === 400) {
      yield put(checkNameStatusesSuccess(response.data.StatusesDetails));
      // } else {
      //   yield put(checkNameStatusesFail(response.message));
      // }
    } else {
      yield put(checkNameStatusesFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(checkNameStatusesFail(error.response));
  }
}

// ADD workshop bays
function* onAddStatusesDetails(data) {
  try {
    const response = yield call(addStatus, data);
    if (response) {
      if (response.code === 200) {
        showSuccess(response.message);
        const storeData = JSON.parse(sessionStorage.getItem("stored_data"));
        const workshop_id = window.atob(storeData.workshop_id);
        yield put(addStatusesSuccess(response));
        yield put(getStatusTechnicianDetail({
          get: "all",
          conditions: {
            workshop_id,
            status: 1,
            type: 2
          },
        }));
        yield put(
          getStatusesDetail({
            get: "all",
            conditions: {
              workshop_id,
              status: 1,
              type: 1
            },
          })
        );
      } else {
        yield put(addStatusesFail(response.message));
      }
    } else {
      yield put(addStatusesFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(addStatusesFail(error.response));
  }
}

// EDIT workshop bays
function* onEditStatusesDetails(data) {
  try {
    const response = yield call(getStatusesList, data);
    if (response) {
      if (response.code === 200) {
        yield put(editStatusesSuccess(response));
      } else {
        yield put(editStatusesFail(response.message));
      }
    } else {
      yield put(editStatusesFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(editStatusesFail(error.response));
  }
}

// UPDATE workshop bays
function* onUpdateStatusesDetails(data) {
  try {
    if (data.payload.order_by) {
      const response = yield call(getStatusesList, {
        type: "GET_STATUSES_DETAILS",
        payload: {
          get: "all",
          conditions: {
            workshop_id: data.payload.workshop_id,
            order_by: data.payload.order_by,
            is_deleted: 0,
            type: data.payload.type,
            "id !=": data.payload.id,
          },
        },
      });
      if (response) {
        if (response.data.Statuses.length === 0) {
          const response = yield call(updateStatus, data);
          if (response) {
            if (response.code === 200) {
              showSuccess(response.message);
              const storeData = JSON.parse(
                sessionStorage.getItem("stored_data")
              );
              const workshop_id = window.atob(storeData.workshop_id);
              yield put(
                getStatusesDetail({
                  get: "all",
                  conditions: {
                    workshop_id,
                    status: 1,
                  },
                })
              );
              yield put(updateStatusesSuccess(response));
            } else {
              yield put(updateStatusesFail(response.message));
            }
          } else {
            yield put(updateStatusesFail("Didn't get api response"));
          }
        } else {
          showError("Order already exist");
          yield put(updateStatusesFail(response.message));
        }
      } else {
        showError("Didn't get api response");
        yield put(updateStatusesFail("Didn't get api response"));
      }
    } else {
      const response = yield call(updateStatus, data);
      if (response) {
        if (response.code === 200) {
          const storeData = JSON.parse(sessionStorage.getItem("stored_data"));
          const workshop_id = window.atob(storeData.workshop_id);
          yield put(
            getStatusesDetail({
              get: "all",
              conditions: {
                workshop_id,
                status: 1,
                type: 1
              },
            })
          );
          yield put(
            getStatusTechnicianDetail({
              get: "all",
              conditions: {
                workshop_id,
                status: 1,
                type: 2
              },
            })
          );
          showSuccess(response.message);
          yield put(updateStatusesSuccess(response));
        } else {
          yield put(updateStatusesFail(response.message));
        }
      } else {
        yield put(updateStatusesFail("Didn't get api response"));
      }
    }
  } catch (error) {
    yield put(updateStatusesFail(error.response));
  }
}

// DELETE workshop bays
function* onDeleteStatusesDetails(data) {
  try {
    const response = yield call(deleteStatus, data);
    if (response) {
      if (response.code === 200) {
        const storeData = JSON.parse(sessionStorage.getItem("stored_data"));
        const workshop_id = window.atob(storeData.workshop_id);
        yield put(
          getStatusesDetail({
            get: "all",
            conditions: {
              workshop_id,
              status: 1,
              type:1
            },
          })
        );
        yield put(
          getStatusTechnicianDetail({
            get: "all",
            conditions: {
              workshop_id,
              status: 1,
              type:2
            },
          })
        );
        showSuccess(response.message);
        yield put(deleteStatusesSuccess(response));
      } else {
        yield put(deleteStatusesFail(response.message));
      }
    } else {
      yield put(deleteStatusesFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(deleteStatusesFail(error.response));
  }
}

function* onGetStatusCount(data) {
  try {
    const response = yield call(getStatusesList, data);
    if (response) {
      if (response.code === 200) {
        yield put(
          getStatusesCountSuccess(response.data.Statuses[0].COUNT)
        );
      } else {
        yield put(getStatusesCountFail(response.message));
      }
    } else {
      yield put(getStatusesCountFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getStatusesCountFail(error.response));
  }
}

// end workshop mark up detail
function* StatusesSaga() {
  yield takeLatest(GET_STATUSES_DETAILS, onGetStatusesDetails);
  yield takeLatest(GET_STATUS_TECHNICIAN_DETAILS, onGetStatusTechnicianDetails);
  yield takeLatest(CHECK_NAME_STATUSES_DETAILS, onCheckNameStatusesDetails);

  yield takeLatest(ADD_STATUSES_DETAILS, onAddStatusesDetails);
  yield takeLatest(EDIT_STATUSES_DETAILS, onEditStatusesDetails);
  yield takeLatest(UPDATE_STATUSES_DETAILS, onUpdateStatusesDetails);
  yield takeLatest(DELETE_STATUSES_DETAILS, onDeleteStatusesDetails);
  yield takeLatest(GET_STATUSES_COUNT, onGetStatusCount);
}

export default StatusesSaga;
