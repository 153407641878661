import { GET_INSPECTIONS } from "store/jobDetails/actionTypes";
import { getWorkshopId } from "utils/getWorkshopId";
export const getInspectionsData = (inspections_id) => {
    return  {
        type: GET_INSPECTIONS,
        payload: {
          get: "all",
          conditions: {
            "InspInspections.id": inspections_id,
            "InspInspections.workshop_id": Number(getWorkshopId()),
            "InspInspections.status": 1,
            "InspInspections.is_deleted": 0,
          },
          order: {
            "InspInspections.id": "desc",
          },
          contain: {
            0: "ApprovedBy",
            InspQuestionAns: {
              fields: [
                "InspQuestionAns.id",
                "InspQuestionAns.insp_inspection_id",
                "InspQuestionAns.insp_question_id",
                "InspQuestionAns.part_id",
                "InspQuestionAns.question_text",
                "InspQuestionAns.answers",
                "InspQuestionAns.qn_status",
              ],
            },
            "InspApprovedBy.CustomersDetails": {
              conditions: {
                "CustomersDetails.workshop_id =": Number(getWorkshopId()),
              },
            },
            Users: {
              fields: ["firstname", "lastname", "id"],
            },
            InspTemplates: {
              fields: [
                "InspTemplates.id",
                "InspTemplates.workshop_id",
                "InspTemplates.categories_id",
                "InspTemplates.name",
                "InspTemplates.description",
                "InspTemplates.group_data",
              ],
            },
            "InspTemplates.InspQuestion": {
              fields: [
                "InspQuestion.id",
                "InspQuestion.name",
                "InspQuestion.type",
                "InspQuestion.insp_group_id",
                "InspQuestion.insp_template_id",
              ],
            },
            JobSchedules: {
              fields: ["insp_technician_id", "id"],
            },
          },
        },
      }
}

