import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Header from "../../../common/navigation/components/header";
import Sidebar from "../../../layouts/sidebar";
import CompanyDetailForm from "./companyDetailForm";
import CompanyCommunication from "./companyCommunication";
import BrandedApp from "./brandedApp";
import LoaderImage from "../../../../../src/assets/images/page-loader.svg";
import {
  GET_WORKSHOP_DETAIL,
  GET_WORKSHOP_OTHER_DETAIL,
} from "../../../../store/companyDetails/actionTypes";

const CompanyDetailSetting = () => {
  const dispatch = useDispatch(); // Dispatch variable

  const [title] = useState("5iQ | Company Detail Settings");
  const [isLoading, setIsLoading] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);

  const userData = useSelector((state) => state.RegisterReducer); // Get Regiser Reducer data
  const companyDetailData = useSelector((state) => state.CompanyDetailReducer); // GET Company Detail Reducer data
  useEffect(() => {
    dispatch({
      type: GET_WORKSHOP_DETAIL,
      payload: {
        id: JSON.parse(localStorage.getItem("data")).workshop_id,
      },
    });

    dispatch({
      type: GET_WORKSHOP_OTHER_DETAIL,
      payload: {
        workshop_id: JSON.parse(localStorage.getItem("data")).workshop_id,
      },
    });
  }, []);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    setIsLoading(userData.loadingLogins);
  }, [userData]);

  useEffect(() => {
    setIsLoading(companyDetailData.loading);
    setIsFormLoading(companyDetailData.formLoading);
  }, [companyDetailData]);

  return (
    <>
      {(isLoading === true || isFormLoading === true) && (
        <div className="page-loader d-flex align-center justify-center">
          <img src={LoaderImage} alt="Loading"></img>
        </div>
      )}
      <div className="right-section-wrapper">
        <div className="table-header">
          <div className="top-block">
            <div className="table-title">
              <i className="fa fa-gears text-primary me-2 font-size-24"></i>
              <span>Company Details</span>
            </div>
            <div className="video-link-outer">
              <Link to="/settings" className="btn btn-primary btn-rounded">
                Back to Dashboard
              </Link>
            </div>
          </div>
          <div className="table-header-tabs profile-tabs">
            <Tabs defaultActiveKey="company-details">
              <Tab eventKey="company-details" title="Company Details">
                <CompanyDetailForm
                  userData={userData}
                  companyDetailData={companyDetailData}
                />
              </Tab>
              <Tab
                eventKey="company-communication"
                title="Company Communication"
              >
                <CompanyCommunication
                  userData={userData}
                  companyDetailData={companyDetailData}
                />
              </Tab>
              <Tab eventKey="branded-app" title="Branded App">
                <BrandedApp
                  workShopOtherDetails={companyDetailData.workShopOtherDetails}
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyDetailSetting;
