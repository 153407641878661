import Loadable from "react-loadable";

import { Loading } from "../common/navigation";

const InvoiceCreate = Loadable({
  loader: () => import("./components/InvoiceCreate/InvoiceCreate"),
  loading: Loading,
});


const InvoicePreview = () => <InvoiceCreate preview />


const InvoiceList = Loadable({
  loader: () => import("./components/InvoiceList/InvoiceList"),
  loading: Loading,
});

const InvoiceEdit = Loadable({
  loader: () => import("./components/InvoiceEdit/InvoiceEdit"),
  loading: Loading,
});

const InvoiceEmail = Loadable({
  loader: () => import("./components/InvoiceEmail/InvoiceEmail"),
  loading: Loading,
});
const InvoicePrint = Loadable({
  loader: () => import("./components/InvoicePrint/InvoicePrint"),
  loading: Loading,
});

const InvoiceView = Loadable({
  loader: () => import("./components/InvoiceView/InvoiceView"),
  loading: Loading,
});
const PaymentCredits = Loadable({
  loader: () => import("./components/PaymentCredits/PaymentCredits"),
  loading: Loading,
});
const ViewPaymentCredit = Loadable({
  loader: () => import("./components/PaymentCredits/ViewPaymentCredit"),
  loading: Loading,
});
const InvoiceReceipt = Loadable({
  loader: () => import("./components/PaymentCredits/InvoiceReceipt"),
  loading: Loading,
});
const ReceiptEmail = Loadable({
  loader: () => import("./components/PaymentCredits/ReceiptEmail"),
  loading: Loading,
});
const CreditEmail = Loadable({
  loader: () => import("./components/PaymentCredits/CreditEmail"),
  loading: Loading,
});

export const routes = [
  {
    path: "/invoice",
    exact: true,
    name: "Invoice",
    element: InvoiceList,
  },
  {
    path: "/invoice-create/:id",
    exact: true,
    name: "Invoice Create",
    element: InvoiceCreate,
  },
  {
    path: "/edit-invoice/:id",
    exact: true,
    name: "Edit Invoice",
    element: InvoiceEdit,
  },
  {
    path: "/invoice_email/:id",
    exact: true,
    name: "Mail Invoice",
    element: InvoiceEmail,
  },
  {
    path: "/invoice_print/:id",
    exact: true,
    name: "Print Invoice",
    element: InvoicePrint,
  },
  {
    path: "/invoice-view/:id",
    exact: true,
    name: "View Invoice",
    element: InvoiceView,
  },
  {
    path: "/email_cash_sale/:id",
    exact: true,
    name: "Email Cash Sale Invoice",
    element: InvoiceEmail,
  },
  {
    path: "/payment_credit/:id",
    exact: true,
    name: "Credit of Invoice",
    element: PaymentCredits,
  },
  {
    path: "/view-invoice-credit/:id",
    exact: true,
    name: "Credit of Invoice",
    element: ViewPaymentCredit,
  },
  {
    path: "/view-invoice-receipt/:id",
    exact: true,
    name: "Receipt of Invoice",
    element: InvoiceReceipt,
  },
  {
    path: "/invoice-receipt-email/:id",
    exact: true,
    name: "Receipt of Invoice",
    element: ReceiptEmail,
  },
  {
    path: "/invoice-credit-email/:id",
    exact: true,
    name: "Receipt of Invoice",
    element: CreditEmail,
  },
  {
    path: "/invoice-preview/:id",
    exact: true,
    name: "Preview of Invoice",
    element: InvoicePreview,
  },
];
