import {
  GET_COMPLETED_JOBS,
  GET_COMPLETED_JOBS_SUCCESS,
  GET_COMPLETED_JOBS_FAIL,
  GET_COMPLETED_JOBS_COUNT,
  GET_COMPLETED_JOBS_COUNT_SUCCESS,
  GET_COMPLETED_JOBS_COUNT_FAIL,
} from "./actionTypes";

export const getincompletedJobs = () => {
  return {
    type: GET_COMPLETED_JOBS,
  };
};

export const getincompletedJobsSuccess = (data) => {
  return {
    type: GET_COMPLETED_JOBS_SUCCESS,
    payload: data,
  };
};

export const getincompletedJobsFail = (error) => {
  return {
    type: GET_COMPLETED_JOBS_FAIL,
    payload: error,
  };
};

export const getincompletedJobsCount = () => {
  return {
    type: GET_COMPLETED_JOBS_COUNT,
  };
};

export const getincompletedJobsCountSuccess = (data) => {
  return {
    type: GET_COMPLETED_JOBS_COUNT_SUCCESS,
    payload: data,
  };
};

export const getincompletedJobsCountFail = (error) => {
  return {
    type: GET_COMPLETED_JOBS_COUNT_FAIL,
    payload: error,
  };
};
