import {
  GET_LOST_QUOTES,
  GET_LOST_QUOTES_SUCCESS,
  GET_LOST_QUOTES_FAIL,
  GET_LOST_QUOTES_COUNT,
  GET_LOST_QUOTES_COUNT_SUCCESS,
  GET_LOST_QUOTES_COUNT_FAIL,
} from "./actionTypes";

const initialState = {
  loading: false,
  message: "",
  error: "",
  lostQuotesData: null,
  lostQuotesCount: 0,
};

const LostQuotesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOST_QUOTES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_LOST_QUOTES_SUCCESS:
      state = {
        ...state,
        loading: false,
        lostQuotesData: action.payload,
      };
      break;
    case GET_LOST_QUOTES_FAIL:
      state = {
        ...state,
        loading: false,
        lostQuotesData: null,
        error: {
          message: action.payload,
        },
      };
      break;

    case GET_LOST_QUOTES_COUNT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_LOST_QUOTES_COUNT_SUCCESS:
      state = {
        ...state,
        loading: false,
        lostQuotesCount: action.payload,
      };
      break;
    case GET_LOST_QUOTES_COUNT_FAIL:
      state = {
        ...state,
        loading: false,
        lostQuotesCount: 0,
        error: {
          message: action.payload,
        },
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default LostQuotesReducer;
