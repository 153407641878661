import {
  SAVE_LABOUR_CHARGE,
  SAVE_LABOUR_CHARGE_SUCCESS,
  SAVE_LABOUR_CHARGE_FAIL,
  UPDATE_LABOUR_CHARGE_DETAILS,
  UPDATE_DEFAULT_LABOUR_CHARGE_DETAIL,
  UPDATE_LABOUR_CHARGE_DETAILS_SUCCESS,
  UPDATE_LABOUR_CHARGE_DETAILS_FAIL,
  DELETE_LABOUR_CHARGE_DETAILS,
  DELETE_LABOUR_CHARGE_DETAILS_SUCCESS,
  DELETE_LABOUR_CHARGE_DETAILS_FAIL,
  EDIT_LABOUR_CHARGES_DETAILS,
  EDIT_LABOUR_CHARGES_DETAILS_SUCCESS,
  EDIT_LABOUR_CHARGES_DETAILS_FAIl,
  SAVE_CUSTOM_VEHICLE,
  SAVE_CUSTOM_VEHICLE_SUCCESS,
  SAVE_CUSTOM_VEHICLE_FAIL,
  EDIT_CUSTOM_VEHICLE_DETAILS,
  EDIT_CUSTOM_VEHICLE_DETAILS_SUCCESS,
  EDIT_CUSTOM_VEHICLE_DETAILS_FAIL,
  UPDATE_CUSTOM_VEHICLE_DETAILS,
  UPDATE_CUSTOM_VEHICLE_DETAILS_SUCCESS,
  UPDATE_CUSTOM_VEHICLE_DETAILS_FAIL,
  DELETE_CUSTOM_VEHICLE_DETAILS,
  DELETE_CUSTOM_VEHICLE_DETAILS_SUCCESS,
  DELETE_CUSTOM_VEHICLE_DETAILS_FAIL,
  GET_CUSTOM_VEHICLE_LIST,
  GET_CUSTOM_VEHICLE_LIST_SUCCESS,
  GET_CUSTOM_VEHICLE_LIST_FAIL,
  UPDATE_CUSTOM_VEHICLE_ASSET_TYPE,
  UPDATE_CUSTOM_VEHICLE_ASSET_TYPE_SUCCESS,
  UPDATE_CUSTOM_VEHICLE_ASSET_TYPE_FAIL,
} from "./actionTypes";

const initialState = {
  loading: false,
  formLoading: false,
  chargesData: null,
  chargeDetails: null,
  customVehicles: null,
  customVehicleDetails: null,
  error: {
    message: "",
  },
  success: {
    message: "",
  },
};

const DefaultSettingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LABOUR_CHARGE:
      state = {
        ...state,
        formLoading: true,
      };
      break;
    case SAVE_LABOUR_CHARGE_SUCCESS:
      state = {
        ...state,
        formLoading: false,
        success: {
          message: action.message,
        },
      };
      break;
    case SAVE_LABOUR_CHARGE_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        formLoading: false,
      };
      break;

    case EDIT_LABOUR_CHARGES_DETAILS:
      state = {
        ...state,
        formLoading: true,
      };
      break;
    case EDIT_LABOUR_CHARGES_DETAILS_SUCCESS:
      state = {
        ...state,
        formLoading: false,
        success: {
          message: action.message,
        },
        chargeDetails: action.payload,
      };
      break;
    case EDIT_LABOUR_CHARGES_DETAILS_FAIl:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        formLoading: false,
      };
      break;

    case UPDATE_LABOUR_CHARGE_DETAILS:
      state = {
        ...state,
        formLoading: true,
      };
      break;
    case UPDATE_DEFAULT_LABOUR_CHARGE_DETAIL:
      state = {
        ...state,
        formLoading: true,
      };
      break;
    case UPDATE_LABOUR_CHARGE_DETAILS_SUCCESS:
      state = {
        ...state,
        formLoading: false,
        success: {
          message: action.message,
        },
        chargeDetails: null,
      };

      break;
    case UPDATE_LABOUR_CHARGE_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        formLoading: false,
      };

      break;

    case DELETE_LABOUR_CHARGE_DETAILS:
      state = {
        ...state,
        formLoading: true,
      };
      break;
    case DELETE_LABOUR_CHARGE_DETAILS_SUCCESS:
      state = {
        ...state,
        formLoading: false,
        success: {
          message: action.message,
        },
      };
      break;
    case DELETE_LABOUR_CHARGE_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        formLoading: false,
      };
      break;

    // START custom vehicle

    case GET_CUSTOM_VEHICLE_LIST:
      state = {
        ...state,
        formLoading: true,
      };
      break;
    case GET_CUSTOM_VEHICLE_LIST_SUCCESS:
      state = {
        ...state,
        formLoading: false,
        success: {
          message: action.message,
        },
        customVehicles: action.payload.VehiclesCustomFields,
      };
      break;
    case GET_CUSTOM_VEHICLE_LIST_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        formLoading: false,
      };
      break;

    case SAVE_CUSTOM_VEHICLE:
      state = {
        ...state,
        formLoading: true,
      };
      break;
    case SAVE_CUSTOM_VEHICLE_SUCCESS:
      state = {
        ...state,
        formLoading: false,
        success: {
          message: action.message,
        },
      };
      break;
    case SAVE_CUSTOM_VEHICLE_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        formLoading: false,
      };
      break;

    case EDIT_CUSTOM_VEHICLE_DETAILS:
      state = {
        ...state,
        formLoading: true,
      };
      break;
    case EDIT_CUSTOM_VEHICLE_DETAILS_SUCCESS:
      state = {
        ...state,
        formLoading: false,
        success: {
          message: action.message,
        },
        customVehicleDetails: action.payload,
      };
      break;
    case EDIT_CUSTOM_VEHICLE_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        formLoading: false,
      };
      break;

    case UPDATE_CUSTOM_VEHICLE_DETAILS:
      state = {
        ...state,
        formLoading: true,
      };
      break;

    case UPDATE_CUSTOM_VEHICLE_DETAILS_SUCCESS:
      state = {
        ...state,
        formLoading: false,
        success: {
          message: action.message,
        },
        customVehicleDetails: null,
      };

      break;
    case UPDATE_CUSTOM_VEHICLE_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        formLoading: false,
      };

      break;

    case DELETE_CUSTOM_VEHICLE_DETAILS:
      state = {
        ...state,
        formLoading: true,
      };
      break;
    case DELETE_CUSTOM_VEHICLE_DETAILS_SUCCESS:
      state = {
        ...state,
        formLoading: false,
        success: {
          message: action.message,
        },
      };
      break;
    case DELETE_CUSTOM_VEHICLE_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        formLoading: false,
      };
      break;

    case UPDATE_CUSTOM_VEHICLE_ASSET_TYPE:
      state = {
        ...state,
        formLoading: true,
      };
      break;

    case UPDATE_CUSTOM_VEHICLE_ASSET_TYPE_SUCCESS:
      state = {
        ...state,
        formLoading: false,
        success: {
          message: action.message,
        },
      };

      break;
    case UPDATE_CUSTOM_VEHICLE_ASSET_TYPE_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        formLoading: false,
      };

      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default DefaultSettingReducer;
