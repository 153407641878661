import Loadable from "react-loadable";

import { Loading } from "../common/navigation";

const MarketingList = Loadable({
    loader: () => import("./components/MarketingList/MarketingList"),
    loading: Loading,
  });
  const BroadcastList = Loadable({
    loader: () => import("./components/BroadcastList/BroadcastList"),
    loading: Loading,
  });
  const CampaignList = Loadable({
    loader: () => import("./components/CampaignList/CampaignList"),
    loading: Loading,
  });
  const ReferralList = Loadable({
    loader: () => import("./components/ReferralList/ReferralList"),
    loading: Loading,
  });
 

  export const routes = [
    {
      path: "/marketing/marketing-dashboard/",
      exact: true,
      name: "Invoice",
      element: MarketingList,
    }, 
    {
      path: "/marketing/marketing-dashboard/:data",
      exact: true,
      name: "Invoice",
      element: MarketingList,
    },

    {
      path: "/marketing/broadcast",
      exact: true,
      name: "Invoice",
      element: BroadcastList,
    },
    {
      path: "/marketing/campaign",
      exact: true,
      name: "Invoice",
      element: CampaignList,
    },
    {
      path: "/marketing/referral",
      exact: true,
      name: "Invoice",
      element: ReferralList,
    },
    // {
    //   path: "/broadcast",
    //   exact: true,
    //   name: "Invoice",
    //   element: BroadcastList,
    // },
]