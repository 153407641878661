import {
  SAVE_LABOUR_CHARGE,
  SAVE_LABOUR_CHARGE_SUCCESS,
  SAVE_LABOUR_CHARGE_FAIL,
  UPDATE_DEFAULT_LABOUR_CHARGE_DETAIL,
  UPDATE_LABOUR_CHARGE_DETAILS,
  UPDATE_LABOUR_CHARGE_DETAILS_SUCCESS,
  UPDATE_LABOUR_CHARGE_DETAILS_FAIL,
  DELETE_LABOUR_CHARGE_DETAILS,
  DELETE_LABOUR_CHARGE_DETAILS_SUCCESS,
  DELETE_LABOUR_CHARGE_DETAILS_FAIL,
  EDIT_LABOUR_CHARGES_DETAILS,
  EDIT_LABOUR_CHARGES_DETAILS_SUCCESS,
  EDIT_LABOUR_CHARGES_DETAILS_FAIl,
  GET_CUSTOM_VEHICLE_LIST,
  GET_CUSTOM_VEHICLE_LIST_SUCCESS,
  GET_CUSTOM_VEHICLE_LIST_FAIL,
  SAVE_CUSTOM_VEHICLE,
  SAVE_CUSTOM_VEHICLE_SUCCESS,
  SAVE_CUSTOM_VEHICLE_FAIL,
  EDIT_CUSTOM_VEHICLE_DETAILS,
  EDIT_CUSTOM_VEHICLE_DETAILS_SUCCESS,
  EDIT_CUSTOM_VEHICLE_DETAILS_FAIL,
  UPDATE_DEFAULT_CUSTOM_VEHICLE_DETAIL,
  UPDATE_CUSTOM_VEHICLE_DETAILS,
  UPDATE_CUSTOM_VEHICLE_DETAILS_SUCCESS,
  UPDATE_CUSTOM_VEHICLE_DETAILS_FAIL,
  DELETE_CUSTOM_VEHICLE_DETAILS,
  DELETE_CUSTOM_VEHICLE_DETAILS_SUCCESS,
  DELETE_CUSTOM_VEHICLE_DETAILS_FAIL,
  UPDATE_CUSTOM_VEHICLE_ASSET_TYPE,
  UPDATE_CUSTOM_VEHICLE_ASSET_TYPE_SUCCESS,
  UPDATE_CUSTOM_VEHICLE_ASSET_TYPE_FAIL,
} from "./actionTypes";

export const saveLabourCharge = (data) => {
  return {
    type: SAVE_LABOUR_CHARGE,
    payload: data,
  };
};

export const saveLabourChargeSuccess = (data) => {
  return {
    type: SAVE_LABOUR_CHARGE_SUCCESS,
    payload: data,
    message: data.message,
  };
};

export const saveLabourChargeFail = (error) => {
  return {
    type: SAVE_LABOUR_CHARGE_FAIL,
    payload: error,
  };
};

export const editLabourChargeDetails = (data) => {
  return {
    type: EDIT_LABOUR_CHARGES_DETAILS,
    payload: data,
  };
};

export const editLabourChargeSuccess = (data) => {
  return {
    type: EDIT_LABOUR_CHARGES_DETAILS_SUCCESS,
    payload: data.data.LabourCharges,
    message: data.message,
  };
};

export const editLabourChargeFail = (error) => {
  return {
    type: EDIT_LABOUR_CHARGES_DETAILS_FAIl,
    payload: error,
  };
};

export const updateDefaultLabourChargeDetail = () => {
  return {
    type: UPDATE_DEFAULT_LABOUR_CHARGE_DETAIL,
  };
};

export const updateLabourChargeDetails = (data) => {
  return {
    type: UPDATE_LABOUR_CHARGE_DETAILS,
    payload: data,
  };
};

export const updateLabourChargeDetailsSuccess = (data) => {
  return {
    type: UPDATE_LABOUR_CHARGE_DETAILS_SUCCESS,
    message: data.message,
    payload: data.data.LabourCharges,
  };
};

export const updateLabourChargeDetailsFail = (error) => {
  return {
    type: UPDATE_LABOUR_CHARGE_DETAILS_FAIL,
    payload: error,
  };
};

export const deleteLabourChargeDetail = () => {
  return {
    type: DELETE_LABOUR_CHARGE_DETAILS,
  };
};

export const deleteLabourChargeDetailSuccess = (data) => {
  return {
    type: DELETE_LABOUR_CHARGE_DETAILS_SUCCESS,
    message: data.message,
    payload: data,
  };
};

export const deleteLabourChargeDetailFail = (error) => {
  return {
    type: DELETE_LABOUR_CHARGE_DETAILS_FAIL,
    payload: error,
  };
};

// start custom vehicle (asset type tab)

export const getCustomVehiclesLists = (data) => {
  return {
    type: GET_CUSTOM_VEHICLE_LIST,
    payload: data,
  };
};

export const getCustomVehicleListSuccess = (data) => {
  return {
    type: GET_CUSTOM_VEHICLE_LIST_SUCCESS,
    payload: data,
  };
};

export const getCustomVehicleListFail = (error) => {
  return {
    type: GET_CUSTOM_VEHICLE_LIST_FAIL,
    payload: error,
  };
};

export const saveCustomVehicle = (data) => {
  return {
    type: SAVE_CUSTOM_VEHICLE,
    payload: data,
  };
};

export const saveCustomVehicleSuccess = (data) => {
  return {
    type: SAVE_CUSTOM_VEHICLE_SUCCESS,
    payload: data,
    message: data.message,
  };
};

export const saveCustomVehicleFail = (error) => {
  return {
    type: SAVE_CUSTOM_VEHICLE_FAIL,
    payload: error,
  };
};

export const editCustomVehicleDetails = (data) => {
  return {
    type: EDIT_CUSTOM_VEHICLE_DETAILS,
    payload: data,
  };
};

export const editCustomVehicleSuccess = (data) => {
  return {
    type: EDIT_CUSTOM_VEHICLE_DETAILS_SUCCESS,
    payload: data,
    message: data.message,
  };
};

export const editCustomVehicleFail = (error) => {
  return {
    type: EDIT_CUSTOM_VEHICLE_DETAILS_FAIL,
    payload: error,
  };
};

export const updateCustomVehicleDetails = (data) => {
  return {
    type: UPDATE_CUSTOM_VEHICLE_DETAILS,
    payload: data,
  };
};

export const updateCustomVehicleDetailsSuccess = (data) => {
  return {
    type: UPDATE_CUSTOM_VEHICLE_DETAILS_SUCCESS,
    message: data.message,
    payload: data.data.LabourCharges,
  };
};

export const updateCustomVehicleDetailsFail = (error) => {
  return {
    type: UPDATE_CUSTOM_VEHICLE_DETAILS_FAIL,
    payload: error,
  };
};

export const deleteCustomVehicleDetail = () => {
  return {
    type: DELETE_CUSTOM_VEHICLE_DETAILS,
  };
};

export const deleteCustomVehicleDetailSuccess = (data) => {
  return {
    type: DELETE_CUSTOM_VEHICLE_DETAILS_SUCCESS,
    message: data.message,
    payload: data,
  };
};

export const deleteCustomVehicleDetailFail = (error) => {
  return {
    type: DELETE_CUSTOM_VEHICLE_DETAILS_FAIL,
    payload: error,
  };
};

export const updateCustomVehicleAssetType = () => {
  return {
    type: UPDATE_CUSTOM_VEHICLE_ASSET_TYPE,
  };
};

export const updateCustomVehicleAssetTypeSuccess = (data) => {
  return {
    type: UPDATE_CUSTOM_VEHICLE_ASSET_TYPE_SUCCESS,
    message: data.message,
    payload: data,
  };
};

export const updateCustomVehicleAssetTypeFail = (error) => {
  return {
    type: UPDATE_CUSTOM_VEHICLE_ASSET_TYPE_FAIL,
    payload: error,
  };
};
